import React, { useEffect, useState } from "react";
import { RiCheckboxCircleFill, RiCheckLine, RiCloseLine, RiFile4Line } from "react-icons/ri";
import Button from "../Button";
import ProgressBar from "../ProgressBar";
import StaticRippleBg from "../StaticRippleBg";
import { BiTrash } from "react-icons/bi";

function FileInfoField({
  showIcon,
  icon,
  className,
  title,
  desc,
  showDownload,
  onDownloadClick,
  isUpload,
  progress,
  uploadResult,
  theme,
  onDeleteClick,
  onClose,
  labelDownload = 'Download',
  ...rest
}) {
  const [themed, setThemed] = useState({});
  
  useEffect(() => {
    let border = 'border-gray-200';
    let color;
    
    switch (theme) {
      case 'success' : {
        border = 'border-success-600'
        break;
      }
      case 'error' : {
        border = 'border-error-600'
        break;
      }
      default: break;
    }
    
    setThemed({
      border,
    })
  }, [theme])
  
  return (
    <div
      className={[
        "border p-4 rounded-2xl bg-white overflow-hidden flex flex-row items-start gap-4",
        themed.border,
        className
      ].join(" ")}
      {...rest}
    >
      {
        showIcon && (
          <StaticRippleBg
            className={"shrink-0"}
            theme={"primary-maincolor"}
          >
            {icon || <RiFile4Line className={"w-4 h-4"} />}
          </StaticRippleBg>
        )
      }
      
      <div className={"flex-1 overflow-hidden min-w-[0] space-y-2"}>
        <div className={"flex flex-row items-start"}>
          <div className={"flex-1 overflow-hidden min-w-[0]"}>
            <div className={"font-medium"}>{title}</div>
            <div className={"text-gray-600"}>{desc}</div>
            {
              showDownload && (
                <Button
                  type={"button"}
                  className={"text-primary-maincolor-600 font-semibold "}
                  label={labelDownload}
                  onClick={onDownloadClick}
                />
              )
            }
          </div>
          
          {
            uploadResult && (
              <div className={'shrink-0 py-1'}>
                {uploadResult === 'success' && (<RiCheckLine className={'w-4 h-4 text-white bg-primary-maincolor-600 rounded-full'}/>)}
                {uploadResult === 'error' && (<RiCloseLine className={'w-4 h-4 text-white bg-error-600 rounded-full'}/>)}
                {uploadResult === 'downloaded' && (<BiTrash className={'w-4 h-4 text-gray-500 cursor-pointer'} onClick={onDeleteClick}/>)}
              </div>
            )
          }
          {onClose && <RiCloseLine onClick={() => onClose()} />}
        </div>
        
        {
          isUpload && uploadResult !== 'downloaded' && (
            <ProgressBar theme={uploadResult === 'error' ? 'error' : "primary-maincolor"} progress={progress} />
          )
        }
      </div>
    </div>
  );
}

FileInfoField.propTypes = {};

export default FileInfoField;