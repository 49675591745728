import BreadCrumbs from "components/molecules/Breadcrumbs";
import React, { Fragment, useCallback, useState } from "react";

import { enqueueSnackbar } from "notistack";
import { Spinner } from "components";
import { useNavigate, useParams } from "react-router";

import { TabsList, TabsRoot, TabsTrigger } from "components/atoms/Tabs";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import RequestInformation from "./RequestInformation";
import Recommendation from "./Recommendaiton";
import {
  getDetailDeskAnalyst,
  postDetailDeskAnalyst,
} from "services/institutions/desk-analysts";
import { getErrorMessage } from "helpers";

const DetailDeksAnalyst = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [filter, setFilter] = useState({
    filter: {
      page_potention: 1,
      limit_potention: 10,
      page_collateral: 1,
      limit_collateral: 10,
    },
  });

  const [activeTab, setActiveTab] = useState("information");
  const [bodyToSend, setBodyToSend] = useState({
    information: {},
    recommendation: {},
  });

  const { data, isLoading } = useQuery({
    queryKey: [
      "detail-desk-analyst-lembaga",
      id,
      { filter: { ...filter.filter } },
    ],
    queryFn: getDetailDeskAnalyst,
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => data?.data?.data,
  });

  const postDetailDeskAnalystCB = useMutation((payload) => {
    const { is_draft, agency_desk_analyst_id, ...rest } = payload;
    return postDetailDeskAnalyst({ is_draft, agency_desk_analyst_id }, rest);
  });

  const handleBackButton = (values) => {
    if (activeTab === "information") {
      navigate(-1);
    }

    if (activeTab === "recommendation") {
      setBodyToSend((curr) => ({
        ...curr,
        recommendation: { ...curr.recommendation, ...values },
      }));
      setActiveTab("information");
    }
  };

  const handleSubmit = (payload) => {
    console.log(payload);
    if (activeTab === "information") {
      setBodyToSend((curr) => ({
        ...curr,
        information: { ...curr.information, ...payload },
      }));
      setActiveTab("recommendation");
      return;
    } else {
      setBodyToSend((curr) => ({
        ...curr,
        recommendation: { ...curr.recommendation, ...payload },
      }));
      const data = {
        ...bodyToSend?.information,
        ...payload,
        is_draft: false,
        agency_desk_analyst_id: id,
      };
      if (data?.file_temp) delete data?.file_temp;
      if (data?.format_potention_file_temp)
        delete data?.format_potention_file_temp;
      if (data?.collateral_meet_recruitment)
        delete data?.collateral_meet_recruitment;

      postDetailDeskAnalystCB.mutate(data, {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ fontWeight: "bold" }}>
                  Data berhasil disimpan!
                </span>
                <span>Data yang Anda masukkan berhasil dikirim</span>
              </div>
            ),
          });
          navigate(`/institutions/desk-analyst`);
        },
        onError: (error) => {
          enqueueSnackbar({
            message: getErrorMessage(error),
            variant: "error",
          });
        },
      });
    }
  };

  const changePagePotention = useCallback((page) => {
    setFilter((curr) => ({
      ...curr,
      filter: {
        ...curr.filter,
        page_potention: page,
      },
    }));
  }, []);

  const changeLimitPotention = useCallback((limit) => {
    setFilter((curr) => ({
      ...curr,
      filter: {
        ...curr.filter,
        limit_potention: limit,
      },
    }));
  }, []);

  const changePageCollateral = useCallback((page) => {
    setFilter((curr) => ({
      ...curr,
      filter: {
        ...curr.filter,
        page_collateral: page,
      },
    }));
  }, []);

  const changeLimitCollateral = useCallback((limit) => {
    setFilter((curr) => ({
      ...curr,
      filter: {
        ...curr.filter,
        limit_collateral: limit,
      },
    }));
  }, []);

  return (
    <div className="space-y-6 mb-40">
      <BreadCrumbs
        routes={[
          {
            label: "Permohonan Lembaga Penyalur",
            path: "/institutions/desk-analyst",
          },
          {
            label: "Detail",
            path: `/institutions/desk-analyst/${id}`,
          },
        ]}
      />
      {isLoading ? (
        <div className="flex justify-center p-4">
          <Spinner theme={"primary"} />
        </div>
      ) : (
        <Fragment>
          <TabsRoot value={activeTab} onValueChange={setActiveTab}>
            <TabsList>
              <TabsTrigger value="information">
                Informasi Umum & Permohonan Pembiayaan LP
              </TabsTrigger>
              <TabsTrigger value="recommendation">
                Rekomendasi Hasil Penilaian
              </TabsTrigger>
            </TabsList>
            <RequestInformation
              data={data}
              onBack={handleBackButton}
              onSubmit={handleSubmit}
              changePageCollateral={changePageCollateral}
              changeLimitCollateral={changeLimitCollateral}
              changePagePotention={changePagePotention}
              changeLimitPotention={changeLimitPotention}
              filterTable={filter?.filter}
            />
            <Recommendation
              data={data?.recomendation}
              onBack={handleBackButton}
              onSubmit={handleSubmit}
            />
          </TabsRoot>
        </Fragment>
      )}
    </div>
  );
};

export default DetailDeksAnalyst;
