import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getDebtor = (params) => {
  return axiosInstance.get(`${general_config_v2.BASE_URL}/v1/cms/dashboard/debtor`, {
    params,
  });
}

export const getAge = (params) => {
  return axiosInstance.get(`${general_config_v2.BASE_URL}/v1/cms/dashboard/age`, {
    params,
  });
}

export const getGender = (params) => {
  return axiosInstance.get(`${general_config_v2.BASE_URL}/v1/cms/dashboard/gender`, {
    params,
  });
}
export const getCharts = (params) => {
  return axiosInstance.get(`${general_config_v2.BASE_URL}/v1/cms/dashboard/charts`, {
    params,
  });
}