import React, { useEffect, useState } from "react";

function StaticRippleBg(props) {
  const { theme, children, className, wrapperClass, ...other } = props;
  const [colorMode, setColorMode] = useState({});
  
  useEffect(() => {
    let bg = "bg-gray-100";
    let color = "text-gray-600";
    let ripple = "border-gray-50";
    
    switch (theme) {
      case "success": {
        bg = "bg-success-100";
        color = "text-success-600";
        ripple = "border-success-50";
        break;
      }
      case "warning": {
        bg = "bg-warning-100";
        color = "text-warning-600";
        ripple = "border-warning-50";
        break;
      }
      case "error": {
        bg = "bg-error-100";
        color = "text-error-600";
        ripple = "border-error-50";
        break;
      }
      case "bluelight": {
        bg = "bg-bluelight-100";
        color = "text-bluelight-600";
        ripple = "border-bluelight-50";
        break;
      }
      case "purple": {
        bg = "bg-purple-100";
        color = "text-purple-600";
        ripple = "border-purple-50";
        break;
      }
      case "rose": {
        bg = "bg-rose-100";
        color = "text-rose-600";
        ripple = "border-rose-50";
        break;
      }
      case "blue": {
        bg = "bg-blue-100";
        color = "text-blue-600";
        ripple = "border-blue-50";
        break;
      }
      case "green": {
        bg = "bg-green-100";
        color = "text-green-600";
        ripple = "border-green-50";
        break;
      }
      case "orangedark": {
        bg = "bg-orangedark-100";
        color = "text-orangedark-600";
        ripple = "border-orangedark-50";
        break;
      }
      case "orange": {
        bg = "bg-orange-100";
        color = "text-orange-600";
        ripple = "border-orange-50";
        break;
      }
      case "primary-maincolor": {
        bg = "bg-primary-maincolor-100";
        color = "text-primary-maincolor-600";
        ripple = "border-primary-maincolor-50";
        break;
      }
      default:
        break;
    }
    
    setColorMode({
      bg, color, ripple
    });
  }, [theme]);
  
  return (
    <div
      className={["p-2 border-8 rounded-full inline-block", ...Object.values(colorMode), className].join(" ")}
      {...other}
    >
      {children}
    </div>
  );
}

StaticRippleBg.propTypes = {};

export default StaticRippleBg;