import { ModalBody, ModalHeader } from "components/atoms/Modal";
import Table, { TableWrapper } from "components/atoms/Table";

import { useMemo } from "react";

export const RisalahModalInstitution = ({ risalah }) => {
  const headers = useMemo(
    () => [
      {
        key: "index",
        title: "No",
        render: ({ index }) => {
          const no = index + 1;

          return no;
        },
      },
      {
        key: "risalah_number",
        title: "Nomor Risalah",
        render: ({ item }) => item?.risalah_number ?? item?.number ?? "-",
      },
      {
        key: "agency_name",
        title: "Nama Lembaga",
      },
    ],
    []
  );

  return (
    <>
      <ModalHeader hideClose>Daftar Risalah</ModalHeader>
      <ModalBody className="space-y-5">
        <TableWrapper>
          <Table headers={headers} items={risalah ?? []} />
        </TableWrapper>
      </ModalBody>
    </>
  );
};
