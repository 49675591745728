import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";
import { find } from "lodash";
import * as yup from "yup";
const validationSchema = () =>
  yup.object().shape({
    number: yup.string().required().label("Nomor Surat"),
    nature: yup.string().required().label("Sifat"),
    some: yup.string().required().label("Hal"),
    start_date: yup.string().required().label("Tanggal Pelaksanaan Penawaran"),
    end_date: yup.string().required().label("Tanggal Penawaran Berakhir"),
    // agency_name: yup.string().required().label("Lembaga"),
    // agency_committee_minute_id: yup
    //   .object()
    //   .shape({
    //     value: yup.string().required(),
    //     label: yup.string().required(),
    //   })
    //   .test("required", "BA Komite Direksi wajib diisi", (value, ctx) => {
    //     if (!value) return false;
    //     else if (value !== null && value.value && value.label)
    //       return !!value.value && !!value.label;
    //   })
    //   .nullable(),
    fileTemp: yup
      .mixed()
      .test(
        "cid_img",
        ({ label }) =>
          validationErrorMsg.mixed.required.replace("${path}", label),
        (value, context) => {
          const allowedTypes = [
            "application/pdf",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ];
          if (value) {
            const data = find(allowedTypes, (at) => value.type.includes(at));
            if (!data) {
              return context.createError({
                message: validationCustomErrorMsg.isFiletype("PDF, DOCX")({
                  label: "Dokumen Surat Penawaran",
                }),
              });
            }
            if (value.size > 10 * 1024 * 1024) {
              return context.createError({
                message: validationCustomErrorMsg.isFileSize("10MB")({
                  label: "Dokumen Surat Penawaran",
                }),
              });
            }
            return true;
          }
          // otherwise, return false to render the field required
          return false;
        }
      )
      .label("Dokumen Surat Penawaran"),
    fdb_syara_agreement: yup.string().required().label("Syarat Penandatanganan Perjanjian FDB"),
    cooperation_terms: yup.array().of(
      yup.object().shape({
        value: yup.string().required().label("Ketentuan Kerjasama"),
        // value: yup
        //   .object()
        //   .shape({
        //     value: yup.string().required(),
        //     label: yup.string().required(),
        //   })
        //   .test("required", "Ketentuan Kerjasama wajib diisi", (value, ctx) => {
        //     if (!value) return false;
        //     else if (value !== null && value.value && value.label)
        //       return !!value.value && !!value.label;
        //   })
        //   .nullable(),
        sub: yup.array().of(
          yup.object().shape({
            value: yup
              .string()
              .required()
              .label("Sub Ketentuan Kerjasama"),
            // value: yup
            //   .object()
            //   .shape({
            //     value: yup.string().required(),
            //     label: yup.string().required(),
            //   })
            //   .test(
            //     "required",
            //     "Sub Ketentuan Kerjasama wajib diisi",
            //     (value, ctx) => {
            //       if (!value) return false;
            //       else if (value !== null && value.value && value.label)
            //         return !!value.value && !!value.label;
            //     }
            //   )
            //   .nullable(),
            sub: yup.array().of(
              yup.object().shape({
                value: yup
                  .string()
                  .required()
                  .label("Sub Sub Ketentuan Kerjasama"),
                // value: yup
                //   .object()
                //   .shape({
                //     value: yup.string().required(),
                //     label: yup.string().required(),
                //   })
                //   .test(
                //     "required",
                //     "Sub Sub Ketentuan Kerjasama wajib diisi",
                //     (value, ctx) => {
                //       if (!value) return false;
                //       else if (value !== null && value.value && value.label)
                //         return !!value.value && !!value.label;
                //     }
                //   )
                //   .nullable(),
              })
            ),
          })
        ),
      })
    ),
    report: yup.array().of(
      yup.object().shape({
        value: yup.string().required().label("Pelaporan"),
        // value: yup
        //   .object()
        //   .shape({
        //     value: yup.string().required(),
        //     label: yup.string().required(),
        //   })
        //   .test("required", "Pelaporan wajib diisi", (value, ctx) => {
        //     if (!value) return false;
        //     else if (value !== null && value.value && value.label)
        //       return !!value.value && !!value.label;
        //   })
        //   .nullable(),
        sub: yup.array().of(
          yup.object().shape({
            value: yup.string().required().label("Sub Pelaporan"),
            // value: yup
            //   .object()
            //   .shape({
            //     value: yup.string().required(),
            //     label: yup.string().required(),
            //   })
            //   .test("required", "Sub Pelaporan wajib diisi", (value, ctx) => {
            //     if (!value) return false;
            //     else if (value !== null && value.value && value.label)
            //       return !!value.value && !!value.label;
            //   })
            //   .nullable(),
            sub: yup.array().of(
              yup.object().shape({
                value: yup
                  .string()
                  .required()
                  .label("Sub Sub Pelaporan"),
                // value: yup
                //   .object()
                //   .shape({
                //     value: yup.string().required(),
                //     label: yup.string().required(),
                //   })
                //   .test(
                //     "required",
                //     "Sub Sub Pelaporan wajib diisi",
                //     (value, ctx) => {
                //       if (!value) return false;
                //       else if (value !== null && value.value && value.label)
                //         return !!value.value && !!value.label;
                //     }
                //   )
                //   .nullable(),
              })
            ),
          })
        ),
      })
    ),
    disbursement_terms: yup
      .string()
      .required()
      .label("Ketentuan Pencairan dan Pengembalian FDB"),
      monitoring_terms: yup
      .string()
      .required()
      .label("Ketentuan Monitoring dan Evaluasi"),
      tax_terms: yup.string().required().label("Ketentuan Denda dan Wanprestasi"),
      end_agreement_terms: yup
      .string()
      .required()
      .label("Ketentuan Berakhirnya Perjanjian Kerjasama Penyaluran FDB"),
      settlement_terms: yup
      .string()
      .required()
      .label("Ketentuan Penyelamatan dan Penyelesaian FDB"),
  });

export default validationSchema;
