import CardForm from "components/molecules/CardForm";
import UploadedFileAsync from "components/molecules/UploadFileAsync";
import React from "react";

const LandTenureDocuments = ({ data }) => {
  return (
    <CardForm label="Dokumen Penguasaan Lahan">
      <div className="grid grid-cols-2 gap-4">
        {data?.map((document) => (
          <UploadedFileAsync id={document.file} />
        ))}
      </div>
    </CardForm>
  );
};

export default LandTenureDocuments;
