import React from "react";
import CardForm from "components/molecules/CardForm";
import UploadedFileAsync from "components/molecules/UploadFileAsync";

const CapacityAnalysist = ({ data }) => {
  return (
    <CardForm label="Analisis Kapasitas Teknologi Informasi yang Dimiliki">
      <div className="space-y-5">
        <div className="bg-[#E1F1D6] my-5 p-3 w-full">
          <p className="font-bold text-[#01A24A]">
            Ketersediaan Sistem IT Lembaga
          </p>
        </div>
        <UploadedFileAsync
          id={data?.available}
          name="Ketersediaan Sistem IT Lembaga"
        />
        <div className="bg-[#E1F1D6] my-5 p-3 w-full">
          <p className="font-bold text-[#01A24A]">
            Ketersediaan SOP Sistem IT Lembaga
          </p>
        </div>
        {data?.oprational.length > 0 &&
          data?.oprational?.map((el) => (
            <div className="grid grid-cols-3 gap-4">
              <div>
                <div className="font-semibold">SOP Sistem IT</div>
                <div>{el?.name}</div>
              </div>
              <div>
                <div className="font-semibold">Keterangan</div>
                <div>{el?.description}</div>
              </div>
              <UploadedFileAsync id={el?.file} name="SOP Sistem IT" />
            </div>
          ))}
      </div>
    </CardForm>
  );
};

export default CapacityAnalysist;
