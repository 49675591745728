export const CardPrimary = ({ label,noBg, children }) => {
  return (
    <div className="border rounded-lg overflow-hidden">
      {noBg ? (
        <h3 className=" text-primary-700 font-semibold px-4 py-3">
          {label}
        </h3>
      ) : (
        <h3 className="bg-primary-subgreen1-100 text-primary-700 font-semibold px-4 py-3">
          {label}
        </h3>
      )}

      <div className="px-4 py-3">{children}</div>
    </div>
  );
};
