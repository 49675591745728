import React from "react";
import { motion } from "framer-motion";

const Button = ({
  label = "",
  theme = "",
  outline = false,
  size = "",
  type = "",
  className = "",
  onClick,
  disabled = false,
}) => {
  const themeHandler = (val) => {
    switch (val) {
      case "primary":
        return `bg-[#00BD52] text-white`;
      case "secondary":
        return `border`;
      case "accent":
        return `bg-[#06AED4] text-white`;
      case "info":
        return `btn-info`;
      case "success":
        return `btn-success`;
      case "warning":
        return `btn-warning`;
      case "error":
        return `bg-[#D92D20] text-white`;
      case "active":
        return `btn-active`;
      case "ghost":
        return `btn-ghost`;
      case "link":
        return `btn-link`;
      default:
        return "";
    }
  };

  const sizeHandler = (val) => {
    switch (val) {
      case "xl":
        return `btn-xl`;
      case "lg":
        return `btn-lg`;
      case "md":
        return `btn-md`;
      case "sm":
        return `btn-sm`;
      case "xs":
        return `btn-xs`;
      default:
        return "";
    }
  };

  return (
    <motion.button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`btn ${themeHandler(theme)} py-2 px-4 rounded-md ${
        outline && "btn-outline"
      } ${sizeHandler(size)} ${className}
      ${disabled ? "text-[#D0D5DD]" : ""}
      `}
    >
      {label}
    </motion.button>
  );
};

export default Button;
