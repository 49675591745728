import Button from "components/atoms/Button";
import { RiArrowLeftLine, RiArrowRightLine, RiSave3Line } from "react-icons/ri";
import clsx from "clsx";

// import classNames

const BottomFormAction = ({
  backButtonAction = null,
  disableDrafButton = true,
  drafButtonAction = null,
  submitActionButton = null,
  disableButtonSubmit,
  lastStep = false,
  hideDraft = false,
  hideSubmit = false,
  submitButtonProps,
  backButtonProps,
  draftButtonProps,
  className = "",
}) => {
  return (
    <>
      <div
        className={[
          "fixed bottom-0 left-0 w-full p-3 flex justify-center items-center",
          className,
        ].join(" ")}
      >
        <div className="flex w-[80%] bg-white rounded-3xl p-6 gap-4 border">
          <div className="flex-1">
            <Button
              {...(backButtonProps || {})}
              type="button"
              className="p-4 border rounded-lg"
              label={
                <div className="flex items-center gap-2">
                  <RiArrowLeftLine />
                  Kembali
                </div>
              }
              onClick={backButtonAction}
            />
          </div>
          {!hideDraft && (
            <div className="">
              <Button
                // className={clsx(
                //   "p-4 border rounded-lg",
                //   draftButtonProps.className
                // )}
                className={clsx(
                  "p-4 border rounded-lg",
                  draftButtonProps?.className
                )}
                type="button"
                label={
                  draftButtonProps?.label || (
                    <div className="flex items-center gap-2">
                      <RiSave3Line />
                      Simpan Sebagai Draft
                    </div>
                  )
                }
                disabled={disableDrafButton}
                onClick={drafButtonAction}
                {...(draftButtonProps || {})}
              />
            </div>
          )}
          {!hideSubmit && (
            <div className="">
              <Button
                {...(submitButtonProps || {})}
                className={`p-4 border rounded-lg ${
                  disableButtonSubmit ? "" : "bg-[#00BD52] text-white"
                }`}
                label={
                  submitButtonProps?.label || (
                    <div className="flex items-center gap-2">
                      {lastStep ? "Submit" : "Selanjutnya"}
                      {!submitButtonProps?.hideIcon ? (
                        <RiArrowRightLine />
                      ) : null}
                    </div>
                  )
                }
                disabled={disableButtonSubmit}
                onClick={submitActionButton}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BottomFormAction;
