import React, { useEffect, useMemo, useState } from "react";
import CardForm from "components/molecules/CardForm";
import UploadedFileAsync from "components/molecules/UploadFileAsync";

import Table, { TableWrapper } from "components/atoms/Table";
import { Pagination } from "components/v2/shared/pagination";
import { InputFormUpload } from "components";
import { useFormContext } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { fileUpload, getFile } from "services/fileService";
import { formatCurrency } from "helpers";
import angkaTerbilangJs from "@develoka/angka-terbilang-js";
import { isEmpty, startCase, toLower } from "lodash";

const getFileFromServiceCB = async (id) => {
  const response = await getFile(id);
  return response.data.data;
};

const RequestDebtor = ({
  data,
  changePagePotention,
  changeLimitPotention,
  filterTable,
}) => {
  const methods = useFormContext();

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "debtor_name",
        title: "Nama Debitur",
      },
      {
        key: "debtor_identity_number",
        title: "NIK",
      },
      {
        key: "debtor_age",
        title: "Umur",
        render: ({ item }) => `${item.debtor_age} Tahun`,
      },
      {
        key: "debtor_address",
        title: "Alamat",
      },
      {
        key: "purpose_using_fdb",
        title: "Tujuan Penggunaan FDB",
      },
      {
        key: "name",
        title: "Nilai Pembiayaan",
        render: ({ item }) => {
          return item?.application_value
            ? `${formatCurrency(item?.application_value)}`
            : "-";
        },
      },
      {
        key: "loan_term",
        title: "Jangka Waktu Pinjaman",
      },
      {
        key: "tree_type",
        title: "Tipe Pohon",
      },
      {
        key: "tree_total",
        title: "Total Pohon",
      },
    ];
  }, []);

  const fileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      if (file?.controllerName === "format_potention_file_temp") {
        methods.register("format_potention_file");
        methods.register("format_potention_url");
        methods.setValue("format_potention_file", response.data.data.id);
        methods.setValue("format_potention_url", response.data.data.url);
      }
    } catch (error) {
      throw error;
    }
  });

  useEffect(() => {
    const promises = [];
    const { format_potention_file } = data || { format_potention_file: null };

    if (format_potention_file && format_potention_file !== "string") {
      promises.push(
        getFileFromServiceCB(format_potention_file).then((data) => {
          return {
            formId: "format_potention_file_temp",
            type: data?.mimeType,
            ...data,
          };
        })
      );
    } else {
      promises.push(Promise.resolve(null));
    }
    if (promises?.length > 0) {
      Promise.all(promises).then((values) => {
        values.map((x) => {
          if (!isEmpty(x)) methods.resetField(x.formId, { defaultValue: x });
        });
      });
    }
  }, []);

  return (
    <CardForm label="Permohonan Pembiayaan, Rencana Penyaluran & Potensi Penerima FDB">
      <div className="space-y-5">
        <div className="space-y-4">
          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">
              Permohonan Pembiayaan FDB
            </p>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="font-semibold">Nilai Permohonan</div>
              <div>
                {data?.request?.value
                  ? formatCurrency(data?.request?.value)
                  : "-"}
              </div>
            </div>
            <div>
              <div className="font-semibold">Terbilang</div>
              <div>
                {startCase(toLower(angkaTerbilangJs(data?.request?.value)))}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="font-semibold">
                Rencana Jangka Waktu Pembiayaan yang Akan Disalurkan
              </div>
              <div>{data?.request?.term_plan ?? "-"}</div>
            </div>
            <div>
              <div className="font-semibold">Grace Period</div>
              <div>
                {data?.request?.grace_period
                  ? data?.request?.grace_period.includes("null")
                    ? "-"
                    : data?.request?.grace_period
                  : "-"}
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-4">
          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">Rencana Penyaluran FDB</p>
          </div>
          <UploadedFileAsync
            id={data?.plan_file}
            name="Rencana Penyaluran FDB"
          />
        </div>
        <div className="space-y-4">
          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">Potensi Penerima FDB</p>
          </div>
          <TableWrapper>
            <Table
              headers={headers}
              items={data?.potention?.data || []}
              isLoading={false}
            />
            <Pagination
              page={Number(data?.potention?.meta?.current_page || 1)}
              limit={Number(filterTable?.limit_potention || 10)}
              total={Number(data?.potention?.meta?.total || 0)}
              totalPage={Number(data?.potention?.meta?.last_page || 1)}
              onLimitChange={(e) => changeLimitPotention(e)}
              onPageChange={(e) => changePagePotention(e)}
            />
          </TableWrapper>
          <UploadedFileAsync
            id={data?.potention_file}
            name="Potensi Penerima FDB File"
          />
          <InputFormUpload
            uploadFile={fileMutation}
            controllerName={`format_potention_file_temp`}
            label={"Upload Potensi Penerima FDB (Jika Ada Perubahan)"}
          />
        </div>
      </div>
    </CardForm>
  );
};

export default RequestDebtor;
