import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getListAssignOffers = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-offer/assign`,
    { params: queryKey[1] }
  );
};

export const getDetailAssignOffers = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-offer/assign/${queryKey[1]}`
  );
};

export const assignOffers = (id, data) => {
  return axiosInstance.patch(
    `${general_config_v2.BASE_URL}/v1/cms/agency-offer/assign/${id}`,
    data
  );
};

export const getListOffers = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-offer`,
    { params: queryKey[1] }
  );
};

export const getDetailNegotiation = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-offer/negotiation/${queryKey[1]}`,
    { params: queryKey[2] }
  );
};

export const submitNegotiation = (id, data, params) => {
  return axiosInstance.patch(
    `${general_config_v2.BASE_URL}/v1/cms/agency-offer/negotiation/${id}`,
    data,
    { params }
  );
};

export const getDetailOffers = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-offer/${queryKey[1]}`,
    { params: queryKey[2] }
  );
};

export const createOffer = (data) => {
  return axiosInstance.patch(
    `${general_config_v2.BASE_URL}/v1/cms/agency-offer`,
    data,
    { params: { is_draft: false } }
  );
};

export const getListCommitteeMinutes = () => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-offer-committee-minutes`
  );
};
