import React from "react";
import Select from "react-select";

import { Controller, useFormContext } from "react-hook-form";
import { RiCloseLine } from "react-icons/ri";

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "#01A24A" : "#EAECF0",
    boxShadow: "unset",
    "&:hover": {
      outline: "unset",
    },
    "&:focus": {
      border: "1px solid #01A24A",
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: "0.25rem 1rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#01A24A" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: state.isSelected ? "#01A24A" : "#F3F4F6",
      color: state.isSelected ? "white" : "black",
    },
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#01A24A",
    color: "white",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "white",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: "white",
    "&:hover": {
      backgroundColor: "#01A24A",
      color: "white",
    },
  }),
};

export const customErrorStyle = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "#F04438" : "#F04438 ",
    boxShadow: "unset",
    "&:hover": {
      outline: "unset",
    },
    "&:focus": {
      border: "1px solid #F04438",
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: "0.25rem 1rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#01A24A" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: state.isSelected ? "#01A24A" : "#F3F4F6",
      color: state.isSelected ? "white" : "black",
    },
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#01A24A",
    color: "white",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "white",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: "white",
    "&:hover": {
      backgroundColor: "#01A24A",
      color: "white",
    },
  }),
};

const AtomSelect = ({
  controllerName,
  options = [],
  className,
  isError = false,
  label = null,
  required = false,
  wrapperClass,
  returnObject = true,
  showErrorLabel = true,
  placeholder,
  asText,
  asTextClass,
  setValue = null,
  disabled,
  customOnChange,
  invalid = false,
  customOnDelete,
  ...props
}) => {
  const { control, setValue: setValueForm } = useFormContext();

  const handleChange = (callback) => (selected) => {
    if (setValue) {
      setValue({ name: controllerName, value: selected });
    }
    if (typeof callback === "function") {
      if (returnObject) {
        if (customOnChange) customOnChange(selected);
        callback(selected);
      } else {
        if (customOnChange) customOnChange(selected?.value);
        callback(selected?.value);
      }
    }
  };

  const getValue = (value) => {
    if (returnObject) {
      return value;
    } else {
      if (value === "") {
        return value;
      } else {
        const selected = options?.find((e) => e.value === value);
        return selected;
      }
    }
  };

  return (
    <Controller
      name={controllerName}
      control={control}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => {
        return (
          <div
            className={["form-control w-full", wrapperClass || ""].join(" ")}
          >
            {label && (
              <label className="label flex gap-1 font-semibold text-[14px] mb-2">
                <span className={`label-text`}>{label}</span>
                {required && <span className="text-[#F04438]">*</span>}
              </label>
            )}
            {asText ? (
              <div
                className={[
                  "min-h-[2.5rem] py-2 overflow-hidden text-ellipsis",
                  asTextClass,
                ].join(" ")}
              >
                {["string", "number"].includes(typeof value) || !value
                  ? value
                  : JSON.stringify(value)}
              </div>
            ) : (
              <div className="relative flex items-center">
                <Select
                  {...props}
                  value={getValue(value)}
                  onChange={handleChange(onChange)}
                  className={className}
                  options={options}
                  placeholder={placeholder}
                  styles={{
                    container: (provided, state) => ({
                      ...provided,
                      width: "100%",
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: invalid
                        ? "#F04438"
                        : state.isFocused
                        ? "#01A24A"
                        : "#EAECF0",
                      ":hover": {
                        ...provided[":hover"],
                        borderColor: invalid
                          ? "#F04438"
                          : state.isFocused
                          ? "#01A24A"
                          : "#EAECF0",
                      },
                      boxShadow: "unset",
                      "&:hover": {
                        outline: "unset",
                      },
                      "&:focus": {
                        border: "1px solid #01A24A",
                      },
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      padding: "0.25rem 1rem",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#01A24A" : "white",
                      color: state.isSelected ? "white" : "black",
                      "&:hover": {
                        backgroundColor: state.isSelected
                          ? "#01A24A"
                          : "#F3F4F6",
                        color: state.isSelected ? "white" : "black",
                      },
                      zIndex: 9999,
                    }),
                    multiValue: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#01A24A",
                      color: "white",
                    }),
                    multiValueLabel: (provided, state) => ({
                      ...provided,
                      color: "white",
                    }),
                    multiValueRemove: (provided, state) => ({
                      ...provided,
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#01A24A",
                        color: "white",
                      },
                    }),
                  }}
                  isDisabled={disabled}
                />
                {value && !disabled && (
                  <RiCloseLine
                    className="absolute end-12 text-gray-400"
                    onClick={() => {
                      if (customOnDelete) {
                        customOnDelete(value);
                      }
                      if (returnObject) {
                        setValueForm(controllerName, null);
                        return;
                      }
                      setValueForm(controllerName, "");
                    }}
                  />
                )}
              </div>
            )}

            {invalid && showErrorLabel && (
              <label className="label">
                <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
                  {error?.message}
                </div>
              </label>
            )}
          </div>
        );
      }}
    />
  );
};

export default AtomSelect;
