import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { debounce } from "lodash";
import dayjs from "dayjs";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import { RiCloseLine, RiEyeLine, RiSearchLine } from "react-icons/ri";

import BreadCrumbs from "components/molecules/Breadcrumbs";
import { Button, Input, Spinner } from "components";
import { customStyles } from "components/atoms/Select";
import Table, { TableWrapper } from "components/atoms/Table";
import { Pagination } from "components/v2/shared/pagination";
import Badge from "components/atoms/Badge";
import { getListVerificator } from "services/institutions/verification";

const stateOptions = [
  { value: "not_yet_review", label: "Belum Direview" },
  { value: "verified", label: "Terverifikasi" },
  { value: "revision", label: "Revisi" },
];

const stateColors = {
  "Not Yet Review": "warning",
  "On Progress": "bluelight",
  Draft: "default",
  Rejected: "error",
  Approved: "success",
  Terverifikasi: "success",
};

const VerificationLembaga = () => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    filter: {
      search: "",
      status: "",
      date: "",
    },
    page: 1,
    limit: 10,
  });

  const { data, isLoading } = useQuery({
    queryKey: [
      "verification-lembaga-list",
      {
        ...filter,
        filter: {
          ...filter.filter,
          date:
            filter.filter.date !== ""
              ? dayjs(filter.filter.date).format("YYYY-MM-DD")
              : "",
        },
      },
    ],
    queryFn: getListVerificator,
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => data?.data?.data,
  });

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => {
          const no =
            parseInt(data?.meta?.current_page) *
              parseInt(data?.meta?.per_page) -
            parseInt(data?.meta?.per_page) +
            index +
            1;

          return no;
        },
      },
      {
        key: "name",
        title: "Nama Lembaga",
      },
      {
        key: "contact_person_name",
        title: "Narahubung",
      },
      {
        key: "date",
        title: "Tanggal Registrasi",
        render: ({ item }) => {
          return dayjs(item.date).format("DD/MM/YYYY");
        },
      },
      {
        key: "state",
        title: "Status",
        render: ({ item }) => (
          <Badge color={stateColors[item.status_string]}>
            {item.status_string}
          </Badge>
        ),
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <>
            {["waiting", "unreviewed"].includes(item.state) ? (
              <Button
                color="primary"
                label="Verifikasi"
                onClick={() => {
                  navigate(`/verification/lembaga/${item.id}`);
                }}
              />
            ) : (
              <Button
                variant="icon"
                label={<RiEyeLine className="text-lg" />}
                onClick={() => {
                  navigate(`${item.id}?status=${item.status}`);
                }}
              />
            )}
          </>
        ),
      },
    ];
  }, [navigate, data?.meta]);

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: "Daftar Prospecting Lembaga Penyalur",
            path: "/institutions/verification",
          },
        ]}
      />

      <h1 className="text-gray-900 text-2xl font-semibold leading-loose">
        Daftar Prospecting Lembaga Penyalur
      </h1>

      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end">
          <div className="flex items-center overflow-hiDDen h-10 border rounded-md pl-3">
            <RiSearchLine className="text-gray-400 text-xl" />

            <Input
              className="w-full bg-white border-none"
              onChange={debounce(
                (e) =>
                  setFilter((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      search: e.target.value,
                    },
                  })),
                1000
              )}
              placeholder="Cari"
            />
          </div>
          <Select
            className="w-full rounded-md h-10 absolute"
            options={stateOptions}
            placeholder="Status"
            styles={customStyles}
            isClearable
            onChange={debounce(
              (e) =>
                setFilter((prev) => ({
                  ...prev,
                  page: 1,
                  filter: {
                    ...prev.filter,
                    status: e?.value ?? "",
                  },
                })),
              1000
            )}
          />
          <div className="relative">
            <ReactDatePicker
              className={
                "date_picker w-full !outline-none !focus:border-primary-700 border h-10 px-3 focus:border-primary-700 rounded-md"
              }
              selected={filter.date}
              onChange={debounce(
                (date) =>
                  setFilter((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      date: date,
                    },
                  })),
                1000
              )}
              placeholderText={"dd/mm/yyyy"}
              showYearDropdown={true}
            />
            {filter.filter.date !== "" && (
              <RiCloseLine
                className="absolute top-3 right-2 text-gray-400 hover:text-gray-600 cursor-pointer text-lg"
                onClick={() =>
                  setFilter((prev) => ({
                    ...prev,
                    page: 1,
                    filter: { ...prev.filter, date: "" },
                  }))
                }
              />
            )}
          </div>
        </div>
        {isLoading ? (
          <div className="flex items-center justify-center w-full h-full">
            <Spinner />
          </div>
        ) : (
          <TableWrapper className="mt-4">
            <Table
              headers={headers}
              items={data?.data || []}
              isLoading={isLoading}
            />
            <Pagination
              page={data?.meta?.current_page || 1}
              limit={filter.limit || 10}
              total={data?.meta?.total || 0}
              totalPage={data?.meta?.last_page || 1}
              onLimitChange={(e) =>
                setFilter((prev) => ({ ...prev, limit: e }))
              }
              onPageChange={(e) => setFilter((prev) => ({ ...prev, page: e }))}
            />
          </TableWrapper>
        )}
      </div>
    </div>
  );
};

export default VerificationLembaga;
