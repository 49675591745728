import angkaTerbilangJs from "@develoka/angka-terbilang-js";
import { InputForm } from "components";
import MultiSelect from "components/atoms/MultiSelect";
import CardForm from "components/molecules/CardForm";
import UploadedFileAsync from "components/molecules/UploadFileAsync";
import { formatCurrency } from "helpers";
import { startCase, toLower } from "lodash";
import React from "react";

const options1 = [
  { value: "Jawa Barat", label: "Jawa Barat" },
  { value: "Jawa Timur", label: "Jawa Timur" },
  { value: "Jawa Tengah", label: "Jawa Tengah" },
  { value: "Kalimantan Selatan", label: "Kalimantan Selatan" },
];
const options2 = [
  { value: "Surat Berharga (BPKB)", label: "Surat Berharga (BPKB)" },
  { value: "Emas", label: "Emas" },
  { value: "Kendaraan Bermotor", label: "Kendaraan Bermotor" },
  { value: "Rumah", label: "Rumah" },
];

const Requests = ({ data }) => {
  return (
    <CardForm label="Permohonan Pembiayaan Lembaga Penyalur">
      <div className="grid grid-cols-3 gap-4">
        <div>
          <div className="font-semibold">Nilai Permohonan</div>
          <div>{formatCurrency(data?.value)}</div>
        </div>
        <div>
          <div className="font-semibold">Terbilang</div>
          <div>
            {startCase(
              toLower(angkaTerbilangJs(data?.value))
            )}
          </div>
        </div>
        <div className="col-span-2">
          <div className="font-semibold">
            Rencana Jangka Waktu Pembiayaan yang Akan Disalurkan
          </div>
          <div>{data?.term_plan}</div>
        </div>
        <div>
          <div className="font-semibold">Garce Period</div>
          <div>{data?.grace_period}</div>
        </div>
      </div>
    </CardForm>
  );
};

export default Requests;
