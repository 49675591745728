import { useQuery } from "@tanstack/react-query";
import { Modal } from "components/atoms/Modal";
import Table, { TableWrapper } from "components/atoms/Table";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { RiFile3Line } from "react-icons/ri";
import { useParams } from "react-router";
import { getCommitteeMeetingAgendaDetail } from "services/fdb/committee";
import { RisalahModal } from "../Components/modal-risalah";
import clsx from "clsx";
import { getDetailMeetingAgenda } from "services/institutions/meeting-agenda";
import { RisalahModalInstitution } from "../Components/modal-risalah-institution";

const badgeAttendance = {
  true: "border-primary-600 bg-primary-50 text-primary-600",
  false: "border-error-600 bg-error-50 text-error-600",
};

const badgeAggrement = {
  true: "bg-primary-50 text-primary-600",
  false: "bg-error-50 text-error-600",
};

const MeetingAgendaDetail = ({ fdbType }) => {
  const breadcrumbs = useMemo(
    () => [
      {
        label: "Agenda Rapat",
        path: "/committee/meeting-agenda",
      },
      {
        label: "Detail",
        path: "",
      },
    ],
    []
  );

  const params = useParams();

  const { data } = useQuery(
    ["GET_DETAIL_MEETING_AGENDA", params.id],
    getCommitteeMeetingAgendaDetail,
    {
      enabled: !!params.id && fdbType === "perorangan",
      select: (data) => data?.data?.data,
    }
  );

  const { data: dataInstitution } = useQuery(
    ["GET_DETAIL_MEETING_AGENDA", params.id],
    getDetailMeetingAgenda,
    {
      enabled: !!params.id || fdbType === "lembaga",
      select: (data) => data?.data?.data,
    }
  );

  const headers = useMemo(
    () => [
      {
        key: "index",
        title: "No",
        render: ({ index }) => {
          // const no =
          //   parseInt(data?.meta?.current_page) *
          //     parseInt(data?.meta?.per_page) -
          //   parseInt(data?.meta?.per_page) +
          //   index +
          //   1;

          const no = index + 1;

          return no;
        },
      },
      {
        key: "name",
        title: "Nama",
        render: ({ item }) => <p>{item.name}</p>,
      },
      {
        key: "position",
        title: "Jabatan",
        render: ({ item }) => <p>{item.position}</p>,
      },
      {
        key: "committee_position",
        title: "Kedudukan Dalam Komite",
        render: ({ item }) => <p>{item?.committee_position}</p>,
      },
      {
        key: "name",
        title: "Status Kehadiran",
        render: ({ item }) =>
          item.is_attend === null ? (
            "-"
          ) : (
            <div
              className={clsx(
                "border-2 px-2 py-[2px] text-xs font-medium rounded-full",
                badgeAttendance[item.is_attend]
              )}
            >
              {item.is_attend ? "Hadir" : "Tidak Hadir"}
            </div>
          ),
      },
      {
        key: "name",
        title: "Persetujuan",
        render: ({ item }) =>
          item.is_approved === null ? (
            "-"
          ) : (
            <div
              className={clsx(
                " px-2 py-[2px] text-xs font-medium rounded-full",
                badgeAggrement[item.is_approved]
              )}
            >
              {item.is_approved ? "Setuju" : "Tidak Setuju"}
            </div>
          ),
      },
      {
        key: "name",
        title: "Catatan Persetujuan",
        render: ({ item }) => <p>{item?.approval_notes}</p>,
      },
    ],
    []
  );

  return (
    <div className="space-y-6">
      <BreadCrumbs routes={breadcrumbs} />

      {fdbType === "perorangan" && (
        <DetailCard data={data} fdbType="perorangan" />
      )}
      {fdbType === "lembaga" && (
        <DetailCard data={dataInstitution?.detail} fdbType="lembaga" />
      )}

      <CardForm label="Daftar Direktorat">
        <TableWrapper>
          <Table headers={headers} items={data?.directorates ?? []} />
        </TableWrapper>
      </CardForm>
    </div>
  );
};

const DetailCard = ({ data, fdbType }) => {
  const params = useParams();

  const [risalah, setRisalah] = useState();

  return (
    <CardForm label="Detail">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-5">
          <h3 className="text-lg font-semibold">Pembahasan</h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <div className="flex flex-col gap-2">
              <h5 className="text-sm font-semibold">Topik Pembahasan</h5>
              <p>{data?.topic_discussion}</p>
            </div>
            <Modal
              trigger={
                <div
                  className="text-sm font-medium flex items-center gap-3 group cursor-pointer shadow rounded-md p-2 w-max"
                  onClick={() => {
                    if (fdbType === "lembaga") {
                      setRisalah(data?.risalah);
                    }
                  }}
                >
                  <div className="p-[10px] bg-primary-100 rounded-full text-primary-700">
                    <RiFile3Line size={20} />
                  </div>
                  <p className="group-hover:underline">Lihat Daftar Risalah</p>
                </div>
              }
              contentClassName="!w-[1184px] !max-w-[1184px] !z-[51]"
            >
              {fdbType === "lembaga" ? (
                <RisalahModalInstitution risalah={risalah} />
              ) : (
                <RisalahModal meetingId={params.id} />
              )}
            </Modal>
          </div>
        </div>

        <div className="flex flex-col gap-5">
          <h3 className="text-lg font-semibold">Tanggal pelakasanaan</h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <div className="flex flex-col gap-2">
              <h5 className="text-sm font-semibold">Jadwal Rapat</h5>
              <p>
                {data?.implementation_time
                  ? dayjs(data?.implementation_time).subtract(7, 'hour').format(
                    "dddd, DD MMMM YYYY ; HH:mm A"
                  )
                  : "-"}
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <h5 className="text-sm font-semibold">Tempat</h5>
              <p>{data?.implementation_place}</p>
            </div>

            <div className="flex flex-col gap-2">
              <h5 className="text-sm font-semibold">Catatan</h5>
              <p>{data?.notes}</p>
            </div>
          </div>
        </div>
      </div>
    </CardForm>
  );
};

export default MeetingAgendaDetail;
