import CardForm from "components/molecules/CardForm";
import { formatCurrency } from "helpers";

const FundedBussinessProfile = ({ data }) => {
  return (
    <CardForm label="Profil Usaha Yang Dibiayai">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div>
          <div className="font-semibold">Jenis Usaha</div>
          <div>{data?.business_type ?? "-"}</div>
        </div>
        <div>
          <div className="font-semibold">Komoditas Usaha PS</div>
          <div>{data?.business_commodity ?? "-"}</div>
        </div>
        <div>
          <div className="font-semibold">Lama Usaha</div>
          <div>{data?.business_duration ?? "-"}</div>
        </div>
        <div>
          <div className="font-semibold">Sumber bahan produksi/jasa </div>
          <div>{data?.material ?? "-"}</div>
        </div>
        <div>
          <div className="font-semibold">Kapasitas usaha/produksi </div>
          <div>{data?.capacity ? formatCurrency(data?.capacity) : "-"}</div>
        </div>
        <div>
          <div className="font-semibold">
            Nilai Ekonomi usaha (Rp produksi/jasa)
          </div>
          <div>{data?.cost_price ? formatCurrency(data?.cost_price) : "-"}</div>
        </div>
        <div>
          <div className="font-semibold">Tujuan pemasaran</div>
          <div>{data?.market_target ?? "-"}</div>
        </div>
        <div>
          <div className="font-semibold">Mitra usaha (jika ada)</div>
          <div>{data?.business_partner ?? "-"}</div>
        </div>
        <div>
          <div className="font-semibold">Pendapatan Bersih</div>
          <div>{data?.net_income ? formatCurrency(data?.net_income) : "-"}</div>
        </div>
      </div>
    </CardForm>
  );
};

export default FundedBussinessProfile;
