import Spinner from "../Spinner";

const alignmentClass = {
  left: "justify-start",
  center: "justify-center",
  right: "justify-end",
};

export const TableHeader = ({ header, alignment }) => {
  return (
    <th
      scope="col"
      className={[
        "px-6 py-3 whitespace-nowrap",
        `${
          header.key === "action" &&
          "sticky right-0 bg-primary-800 drop-shadow-xl"
        }`,
      ].join(" ")}
    >
      <div
        className={["flex items-center", alignmentClass[alignment]].join(" ")}
      >
        {header.title}
        {!!header.sortable && (
          <button type="button" aria-label="Sort">
            <svg
              className="w-3 h-3 ml-1.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
            </svg>
          </button>
        )}
      </div>
    </th>
  );
};

export const TableDefinition = ({
  children,
  className,
  innerClass,
  alignment,
  ...restProps
}) => {
  return (
    <td
      className={[
        "px-6 py-4 whitespace-nowrap text-sm text-gray-600",
        className,
      ].join(" ")}
      {...restProps}
    >
      <div
        className={[
          "flex items-center",
          alignmentClass[alignment],
          innerClass,
        ].join(" ")}
      >
        {children}
      </div>
    </td>
  );
};

export const TableLoading = ({ headers }) => {
  return (
    <tr className="bg-white border-b">
      <TableDefinition
        alignment={"center"}
        colSpan={headers.length}
        innerClass={"gap-3"}
      >
        <Spinner theme="primary" />
        <span className="text-gray-500 text-sm">Loading...</span>
      </TableDefinition>
    </tr>
  );
};

const Table = ({ headers, items = [], renderHeader, isLoading, loadingSlot }) => {
  return (
    <div className="relative overflow-x-auto border">
      <table className="w-full text-sm text-left text-gray-500 ">
        <thead className="text-xs text-white uppercase bg-primary-800">
          <tr>
            {headers.map((header) =>
              renderHeader ? (
                renderHeader(header)
              ) : (
                <TableHeader header={header} alignment={header.alignment} />
              )
            )}
          </tr>
        </thead>
        <tbody>
          {isLoading
            ? loadingSlot ?? <TableLoading headers={headers} />
            : items.map((item, index) => {
                return (
                  <tr className={["bg-white border-b"].join(" ")}>
                    {headers.map((header) => (
                      <TableDefinition
                        alignment={item.alignment || header.alignment}
                        className={[item.className || header.className].join(
                          " "
                        )}
                        innerClass={item.innerClass || header.innerClass}
                      >
                        {header.render
                          ? header.render({
                              item,
                              header,
                              index,
                              key: header.key,
                            })
                          : item[header.key]}
                      </TableDefinition>
                    ))}
                  </tr>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};

export const TableWrapper = ({ children, className }) => {
  return (
    <div
      className={[
        "border rounded-2xl bg-white relative overflow-hidden",
        className,
      ].join(" ")}
    >
      {children}
    </div>
  );
};

export default Table;
