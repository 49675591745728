import { Button } from "components";
import Table, { TableWrapper } from "components/atoms/Table";
import CardForm from "components/molecules/CardForm"
import UploadedFileAsync from "components/molecules/UploadFileAsync";
import { Pagination } from "components/v2/shared/pagination";
import { useMemo } from "react";
import { RiEyeLine } from "react-icons/ri";

const DistributionInformation = ({ data, disbursmentType }) => {

  // ! Just example Data, please Remove before implemetnation API
  const dataTempTable = [
    {
      name: 'Asep',
      nik: 9313938000631,
      age: 40,
      address: "Jl. DR. Wahidin Sudirohusodo No.01, Ps. Baru, Kecamatan Sawah Besar, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10710",
      va_number: 9313938000631,
      purpose: 'Ternak Sapi',
      value: 10000000,
      range_time: '4 Tahun'
    }
  ]

  const dataTempTableCollateral = [
    {
      collateral: 'BPKB Motor 1',
      collateral_value: 'Rp. 10,000,000'
    }
  ]

  const headers = useMemo(() => {
    return [
      {
        key: 'index',
        title: 'no',
        render: ({ index }) => index + 1,
      },
      {
        key: 'name',
        title: 'Nama Debitur'
      },
      {
        key: 'nik',
        title: 'NIK'
      },
      {
        key: 'age',
        title: 'Umur'
      },
      {
        key: 'address',
        title: 'alamat',
        render: ({ item }) => (
          <div className="w-[200px] text-clip whitespace-normal">
            {item?.address}
          </div>
        )
      },
      {
        key: 'va_number',
        title: 'Nomor VA'
      },
      {
        key: 'purpose',
        title: 'Tujuan penggunaan fdb'
      },
      {
        key: 'value',
        title: 'Nilai Pembayaran'
      },
      {
        key: 'range_time',
        title: 'Jangka Waktu'
      },
    ]
  }, [])

  const headersCollateral = useMemo(() => {
    return [
      {
        key: 'index',
        title: 'no',
        render: ({ index }) => index + 1,
      },
      {
        key: 'collateral',
        title: 'Bentuk Jaminan'
      },
      {
        key: 'collateral_value',
        title: 'Nilai Jaminan'
      },
      {
        key: 'action',
        title: 'aksi',
        render: () => (
          <Button
            variant="icon"
            label={<RiEyeLine className="text-lg" />}
          // onClick={() => {
          //   navigate(`/institutions/disbursment/${item.id}`);
          // }}
          />
        )
      },
    ]
  }, [])

  return (
    <CardForm label="Data Lembaga Penyalur">
      <div className="space-y-5">
        <div className="space-y-4">

          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">Detail Permohonan</p>
          </div>

          <div className="grid grid-cols-4 gap-4">
            <div className="space-y-1">
              <div className="font-semibold">Nama Lembaga</div>
              <div>{data?.general?.profile?.name ?? '-'}</div>
            </div>
            <div className="space-y-1">
              <div className="font-semibold">Tanggal Permohonan Pencairan</div>
              <div>{data?.general?.profile?.name ?? '-'}</div>
            </div>
            <div className="space-y-1">
              <div className="font-semibold">Nomor Perjanjian</div>
              <div>{data?.general?.profile?.name ?? '-'}</div>
            </div>
            <div className="space-y-1">
              <div className="font-semibold">Tanggal Perjanjian</div>
              <div>{data?.general?.profile?.name ?? '-'}</div>
            </div>
          </div>

          <div className="grid grid-cols-5 gap-4">
            <div className="space-y-1">
              <div className="font-semibold">Total Nilai Permohonan</div>
              <div>{data?.general?.profile?.name ?? '-'}</div>
            </div>
            <div className="space-y-1">
              <div className="font-semibold">Tahap Penyaluran</div>
              <div>{data?.general?.profile?.name ?? '-'}</div>
            </div>
            <div className="space-y-1">
              <div className="font-semibold">Nilai Disalurakan Tahap ini</div>
              <div>{data?.general?.profile?.name ?? '-'}</div>
            </div>
            <div className="space-y-1">
              <div className="font-semibold">Total Yang dicairkan</div>
              <div>{data?.general?.profile?.name ?? '-'}</div>
            </div>
            <div className="space-y-1">
              <div className="font-semibold">Sisa Saldo Penyaluran</div>
              <div>{data?.general?.profile?.name ?? '-'}</div>
            </div>
          </div>

          <div className="grid grid-cols-4 gap-4">
            <UploadedFileAsync
              id={
                data?.general?.contact_person
                  ?.contact_person_identity_number_file
              }
              name="Dokumen Perjanjian"
            />
            <UploadedFileAsync
              id={data?.general?.contact_person?.contact_person_npwp_file}
              name="Dokumen fidusida"
            />
            <UploadedFileAsync
              id={data?.general?.contact_person?.contact_person_npwp_file}
              name="Akad Debitur"
            />
          </div>

          {
            disbursmentType === 'disburstment-agreement' && (
              <UploadedFileAsync
                id={
                  data?.general?.contact_person
                    ?.contact_person_identity_number_file
                }
                name="Sprint Pencairan"
              />
            )
          }

          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">Daftar Norminative</p>
          </div>

          <TableWrapper>
            <Table
              headers={headers}
              items={dataTempTable}
              isLoading={false}
            />

            <Pagination />
          </TableWrapper>

          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">Jaminan Tambahan</p>
          </div>

          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-semibold text-md text-[#01A24A]">Total Jaminan Tambahan Yang Diajukan</p>
            <p className="text-[#01A24A] text-sm">RP30.000.000,00</p>
          </div>

          <TableWrapper>
            <Table
              headers={headersCollateral}
              items={dataTempTableCollateral}
              isLoading={false}
            />

            <Pagination />
          </TableWrapper>


        </div>
      </div>
    </CardForm>
  )
}

export default DistributionInformation;