import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, InputForm, Spinner } from "components";
import {
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
} from "components/atoms/Modal";
import Table from "components/atoms/Table";
import CardForm from "components/molecules/CardForm";
import React, { Fragment, useEffect, useMemo } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import {
  RiArrowLeftLine,
  RiDeleteBin5Line,
  RiInformationLine,
} from "react-icons/ri";
import { useNavigate, useParams } from "react-router";
import { getAssignPelaksana } from "services/fdb/desk-analyst";
import Select from "react-select";
import { customStyles } from "components/atoms/Select";
import { BiPlus } from "react-icons/bi";
import { enqueueSnackbar } from "notistack";
import { isEmpty } from "lodash";

import {
  createAssignDisbursement,
  getAssignDisbursementDetail,
} from "services/fdb/disbursement";
import { useSearchParams } from "react-router-dom";

const AssignDisbursmentDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const isView = searchParams.get("status") !== "Baru";

  const { data: assignee } = useQuery({
    queryKey: ["GET DESK ANALYST"],
    queryFn: getAssignPelaksana,
    select: ({ data }) => {
      return data?.data?.map((x) => ({ ...x, id: x.id, name: x.name }));
    },
  });

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      disbursement_executors: [{ user_id: "", name: "", email: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "disbursement_executors",
  });

  const { data, isLoading } = useQuery(
    ["GET_ASSIGN_DISBURSEMENT_DETAIL", id],
    getAssignDisbursementDetail,
    {
      select: (data) => data?.data?.data,
    }
  );

  const disbursementExecutorsField = useWatch({
    control: methods.control,
    name: "disbursement_executors",
  });

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "name",
        title: "Nama Pelaksana",
        render: ({ item, index }) => {
          return (
            <Select
              styles={{
                ...customStyles,
                container: (provided, state) => ({
                  ...provided,
                  width: "100%",
                }),
              }}
              options={assignee?.map((data) => ({
                ...data,
                label: data.name,
                value: data.idUser,
              }))}
              value={{
                value: disbursementExecutorsField?.[index]?.user_id,
                label: disbursementExecutorsField?.[index]?.name,
              }}
              isClearable={false}
              menuPosition="fixed"
              onChange={(e) => {
                methods.setValue(
                  `disbursement_executors.${index}.user_id`,
                  e.idUser
                );
                methods.setValue(
                  `disbursement_executors.${index}.name`,
                  e.name
                );
                methods.setValue(
                  `disbursement_executors.${index}.email`,
                  e.emailPegawai
                );
              }}
              isDisabled={isView}
            />
          );
        },
      },
      {
        key: "email",
        title: "Email Pelaksana",
        render: ({ item, index }) => {
          return (
            <InputForm
              className="!max-w-full"
              controllerName={`disbursement_executors.${index}.email`}
              disabled
            />
          );
        },
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item, index }) =>
          index > 0 ? (
            <Button
              variant="icon"
              label={<RiDeleteBin5Line className="text-lg" />}
              onClick={() => remove(index)}
            />
          ) : null,
      },
    ];
  }, [methods, remove, assignee, disbursementExecutorsField, isView]);

  const onFormSubmit = (data) => {
    mutateAssignPelaksana.mutate({ id: id, payload: data });
  };

  const mutateAssignPelaksana = useMutation({
    mutationFn: createAssignDisbursement,
    onSuccess: (res) => {
      enqueueSnackbar({
        variant: "success",
        message: "Berhasil assign pelaksana",
      });
      navigate(-1);
    },
    onError: (err) => {
      enqueueSnackbar({ variant: "error", message: "Gagal assign pelaksana" });
    },
  });

  useEffect(() => {
    if (data) {
      methods.reset({
        disbursement_executors: data?.executor,
      });
    }
  }, [data, methods]);

  return (
    <Fragment>
      {isLoading ? (
        <div className="w-full flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="space-y-6">
          <CardForm label="Profile Kelompok">
            <div className="flex justify-between">
              <div>
                <p className="font-semibold">Nama Kelompok</p>
                <p>{data?.group_name}</p>
              </div>
              <div>
                <p className="font-semibold">Nama Ketua Kelompok</p>
                <p>{data?.leader_name}</p>
              </div>
              <div>
                <p className="font-semibold">NIK Ketua</p>
                <p>{data?.leader_identity}</p>
              </div>
              <div>
                <p className="font-semibold">No Telepon Ketua</p>
                <p>{data?.leader_phone}</p>
              </div>
            </div>
          </CardForm>
          <CardForm label="Assign Pelaksana">
            <div className="space-y-6">
              <FormProvider {...methods}>
                <div className="space-y-6">
                  <Table
                    headers={headers}
                    items={fields}
                    isLoading={isLoading}
                  />
                  {!isView && (
                    <div className="flex justify-end">
                      <Button
                        theme="primary"
                        label={
                          <div className="flex gap-3 items-center">
                            <BiPlus className="text-white" size={22} />
                            Tambah Pelaksana
                          </div>
                        }
                        className="!p-3.5"
                        onClick={() => {
                          append({
                            user_id: "",
                            name: "",
                            email: "",
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              </FormProvider>
            </div>
          </CardForm>
          <div className="sticky bottom-4 flex justify-between w-full p-5 bg-white border rounded-md">
            <Button
              className="p-4 border rounded-lg"
              label={
                <div className="flex items-center gap-2">
                  <RiArrowLeftLine />
                  {isView ? "Kembali" : "Batal"}
                </div>
              }
              onClick={() => navigate(-1)}
            />
            {!isView && (
              <ModalConfirm
                hasExecutors={disbursementExecutorsField?.every(
                  (x) => !isEmpty(x.user_id)
                )}
                onSubmit={() => methods.handleSubmit(onFormSubmit)()}
              />
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

const ModalConfirm = ({ onSubmit, hasExecutors }) => {
  return (
    <Modal trigger={<Button label="Submit" theme="primary" />}>
      <ModalHeader
        noBorder
        hideClose
        title={
          <div className="w-12 h-12 rounded-full flex items-center justify-center text-orange-700 bg-orange-100 border-4 border-orange-50">
            <RiInformationLine className="text-base" size={24} />
          </div>
        }
      />
      {hasExecutors ? (
        <ModalBody className="space-y-4">
          <p className="text-lg font-semibold">
            Anda yakin akan mengirimkan Assignment Ke PIC Terpilih?
          </p>
          <small>Setelah data Terkirim Anda masih Bisa Mengubah PIC</small>
          <div className="grid grid-cols-2 gap-4">
            <ModalClose>
              <Button
                label="Tidak"
                theme="secondary"
                block
                className="w-full"
              />
            </ModalClose>
            <Button
              label={"Ya"}
              theme="primary"
              block
              className="w-full"
              onClick={onSubmit}
            />
          </div>
        </ModalBody>
      ) : (
        <ModalBody className="space-y-4">
          <p className="text-lg font-semibold text-center">
            Anda wajib memasukkan minimal satu pelaksana
          </p>
          <ModalClose>
            <Button label="Tutup" theme="secondary" block className="w-full" />
          </ModalClose>
        </ModalBody>
      )}
    </Modal>
  );
};

export default AssignDisbursmentDetail;
