import { Button, Input } from "components";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { debounce } from "lodash";
import React, { useMemo, useState } from "react";
import {
  RiCloseLine,
  RiEyeLine,
  RiFolder2Line,
  RiSearchLine,
} from "react-icons/ri";
import ReactDatePicker from "react-datepicker";
import Table, { TableWrapper } from "components/atoms/Table";
import { useNavigate } from "react-router";
import Badge from "components/atoms/Badge";
import dayjs from "dayjs";
import { Pagination } from "components/v2/shared/pagination";
import { useQuery } from "@tanstack/react-query";

import { downloadFile } from "helpers";
import { getAgencyDisbursementAssignList } from "services/institutions/disbursement";

const stateColors = {
  1: "bluelight",
  2: "warning",
  3: "success",
};

const AssignDisburmentInstitution = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    filter: {
      search: undefined,
      date: undefined,
    },
    page: 1,
    limit: 10,
  });

  const { data, isLoading } = useQuery({
    queryKey: [
      "agency-disbursement-assign",
      {
        ...filters,
        filter: {
          ...filters.filter,
          date: filters?.filter?.date
            ? dayjs(filters?.filter?.date).format("YYYY-MM-DD")
            : "",
        },
      },
    ],
    queryFn: getAgencyDisbursementAssignList,
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => data?.data?.data,
  });

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "agency_name",
        title: "Nama Lembaga",
      },
      {
        key: "offer_number",
        title: "No Penawaran",
        render: ({ item }) => item?.offer_number ?? "-",
      },
      {
        key: "agency_type",
        title: "Tipe Lembaga",
      },
      {
        key: "agency_potention",
        title: "Jumlah Pengajuan",
      },
      {
        key: "status",
        title: "Status",
        render: ({ item }) => (
          <Badge color={stateColors[item.status]}>{item.status_string}</Badge>
        ),
      },
      {
        key: "news",
        title: "Berita Acara Pencairan",
        render: ({ item }) => (
          <div className="flex justify-center items-center w-full">
            <RiFolder2Line
              className="text-xl cursor-pointer"
              onClick={() => {
                downloadFile(item.file, "Berita Acara Desk Analyst");
              }}
            />
          </div>
        ),
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) =>
          item?.status === "1" ? (
            <Button
              theme="primary"
              label="Assign"
              onClick={() => {
                navigate(`/institutions/assign-disbursment/${item.id}`);
              }}
            />
          ) : (
            <Button
              variant="icon"
              label={<RiEyeLine className="text-lg" />}
              onClick={() => {
                navigate(
                  `/institutions/assign-disbursment/${item.id}?status=${item?.status}`
                );
              }}
            />
          ),
      },
    ];
  }, [navigate]);

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: "Assign Pelaksana Penawaran",
            path: "/institutions/assign-disbursment",
          },
        ]}
      />

      <h1 className="text-gray-900 text-2xl font-semibold leading-loose">
        Assign Pelaksana Penawaran
      </h1>

      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-end">
          <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
            <RiSearchLine className="text-gray-400 text-xl" />
            <Input
              className="w-full bg-white border-none"
              onChange={debounce(
                (e) =>
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      search: e.target.value,
                    },
                  })),
                1000
              )}
              placeholder="Cari"
            />
          </div>
          <div className="relative">
            <ReactDatePicker
              className={
                "date_picker w-full !outline-none !focus:border-primary-700 border h-10 px-3 focus:border-primary-700 rounded-md"
              }
              selected={filters?.filter?.date ?? null}
              onChange={debounce(
                (date) =>
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      date,
                    },
                  })),
                1000
              )}
              placeholderText={"dd/mm/yyyy"}
              showYearDropdown={true}
            />
            {filters?.filter?.date && (
              <RiCloseLine
                className="absolute top-3 right-2 text-gray-400 hover:text-gray-600 cursor-pointer text-lg"
                onClick={() =>
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      date: undefined,
                    },
                  }))
                }
              />
            )}
          </div>
        </div>
        <TableWrapper className="mt-4">
          <Table
            headers={headers}
            items={data?.data || []}
            isLoading={isLoading}
          />
          <Pagination
            page={data?.meta?.current_page || 1}
            limit={filters.limit || 10}
            total={data?.meta?.total || 0}
            totalPage={data?.meta?.last_page || 1}
            onLimitChange={(e) => setFilters((prev) => ({ ...prev, limit: e }))}
            onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
          />
        </TableWrapper>
      </div>
    </div>
  );
};

export default AssignDisburmentInstitution;
