import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { RiCloseLine } from "react-icons/ri";

export const Modal = ({
  children,
  footer,
  trigger,
  contentClassName = "",
  isOpen = false,
  onOpenChange,
  manual = false,
}) => {
  const defaultClassName =
    "data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none z-[99]";

  return manual ? (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-black/50 data-[state=open]:animate-overlayShow fixed inset-0 z-50" />
        <Dialog.Content
          className={[
            ...defaultClassName?.split(" "),
            ...contentClassName?.split(" "),
          ].join(" ")}
        >
          {children}
          {footer}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  ) : (
    <Dialog.Root>
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-black/50 data-[state=open]:animate-overlayShow fixed inset-0 z-50" />
        <Dialog.Content
          className={[
            ...defaultClassName?.split(" "),
            ...contentClassName?.split(" "),
          ].join(" ")}
        >
          {children}
          {footer}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export const ModalHeader = ({
  title,
  children,
  buttonSlot,
  hideClose,
  noBorder,
}) => {
  return (
    <div
      className={`flex justify-between items-center gap-4 px-4 py-3 ${
        !noBorder && "border-b"
      }`}
    >
      <Dialog.Title className="text-lg font-semibold">
        {title || children}
      </Dialog.Title>
      <Dialog.Close asChild>
        {buttonSlot
          ? buttonSlot
          : !hideClose && (
              <button type="button" aria-label="Close">
                <RiCloseLine className="text-2xl text-gray-500 hover:text-gray-600" />
              </button>
            )}
      </Dialog.Close>
    </div>
  );
};

export const ModalDescription = ({ children, className = "" }) => {
  return (
    <Dialog.Description
      className={`text-mauve11 mt-[10px] mb-5 text-[15px] leading-normal ${className}`}
    >
      {children}
    </Dialog.Description>
  );
};

export const ModalBody = ({ children, className = "" }) => {
  return <div className={`px-4 py-3 ${className}`}>{children}</div>;
};

export const ModalClose = ({ children }) => {
  return <Dialog.Close asChild>{children}</Dialog.Close>;
};
