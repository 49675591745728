import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, InputForm, InputFormRadio, InputFormUpload } from "components";
import {
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
} from "components/atoms/Modal";
import { getErrorMessage } from "helpers";
import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";
import { find, isEmpty } from "lodash";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RiEyeLine, RiMapPin2Fill } from "react-icons/ri";
import { useParams } from "react-router";
import { fileUpload } from "services/fileService";
import { postVerificationTallySheet } from "services/fdb/field-analis";
import * as Yup from "yup";
import withFileService from "hocs/with-file-service";
import { getWoodsType } from "services/masterData/woods";
import AtomSelect from "components/atoms/Select";
import GeotagLocationButton from "components/atoms/Button/GeotagLocationButton";

const UploadSamplingModal = withFileService(({ status, data, serviceType, ...rest }) => {
  const { getFileFromService } = rest;

  const queryClient = useQueryClient();
  const { applicantId } = useParams();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const ButtonRendered = useMemo(() => {
    if (status === "1") {
      return (
        <Button type="button" label="Ambil Sampling Pohon" theme="primary" />
      );
    }
    return (
      <Button
        type="button"
        variant="icon"
        label={<RiEyeLine className="text-lg" />}
      />
    );
  }, [status]);

  const typeOfService = useMemo(() => {
    let result = "deferred";
    if (serviceType === "non_timber_forest") {
      result = "timber";
    } else if (serviceType === "non_forest_commodities") {
      result = "commodity";
    }
    return result;
  }, [serviceType]);

  const propToGet = useMemo(() => {
    let object = "deferred_field_analyst_tally_sheet";
    if (typeOfService === "timber") {
      object = "timber_field_analyst_tally_sheet";
    } else if (typeOfService === "commodity") {
      object = "commodity_field_analyst_tally_sheet";
    }
    return object;
  }, [typeOfService]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        tree_number: Yup.string().required().label("Nomor Pohon"),
        area: Yup.string().required().label("Keliling"),
        type: Yup.string().required().label("Jenis"),
        consistency_data: Yup.bool()
          .transform((originalValue) =>
            originalValue === "" ? undefined : originalValue
          )
          .required()
          .label("Kesesuaian Data"),
        fileTemp: Yup.mixed()
          .test(
            "cid_img",
            ({ label }) =>
              validationErrorMsg.mixed.required.replace("${path}", label),
            (value, context) => {
              const allowedTypes = ["jpg", "png", "jpeg"];

              if (status === "1") {
                // if has value, validate the file
                if (value) {
                  const data = find(allowedTypes, (at) =>
                    value.type.includes(at)
                  );
                  if (!data) {
                    return context.createError({
                      message: validationCustomErrorMsg.isFiletype(
                        allowedTypes.join(", ")
                      )({ label: "Foto" }),
                    });
                  }
                  if (value.size > 10 * 1024 * 1024) {
                    return context.createError({
                      message: validationCustomErrorMsg.isFileSize("10MB")({
                        label: "Foto",
                      }),
                    });
                  }

                  return true;
                }

                // otherwise, return false to render the field required
                return false;
              }
              return true;
            }
          )
          .when([], ([value], schema) => {
            if (status === "1") {
              return schema.required().label("Foto");
            }
            return schema.notRequired();
          }),
        fileRevisionTemp: Yup.mixed()
          .test(
            "cid_img",
            ({ label }) =>
              validationErrorMsg.mixed.required.replace("${path}", label),
            (value, context) => {
              const allowedTypes = ["jpg", "png", "jpeg"];
              if (status === "3") {
                // if has value, validate the file
                if (value) {
                  const data = find(allowedTypes, (at) =>
                    value.type.includes(at)
                  );
                  if (!data) {
                    return context.createError({
                      message: validationCustomErrorMsg.isFiletype(
                        allowedTypes.join(", ")
                      )({ label: "Foto" }),
                    });
                  }
                  if (value.size > 10 * 1024 * 1024) {
                    return context.createError({
                      message: validationCustomErrorMsg.isFileSize("10MB")({
                        label: "Foto",
                      }),
                    });
                  }

                  return true;
                } else {
                  // otherwise, return false to render the field required
                  return false;
                }
              }
              return true;
            }
          )
          .when([], ([value], schema) => {
            if (status === "3") {
              return schema.required().label("Foto");
            }
            return schema.notRequired();
          }),
      }),
    [status]
  );
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const onSubmit = (payload) => {
    const dataToSend = {
      ...payload,
      revision_area: payload?.revision_area || 0,
      revision_size_difference: payload?.revision_size_difference || 0,
      type: payload?.type,
      file: payload?.file || data?.[`${propToGet}`]?.file,
      revision_type: payload?.revision_type || payload?.type,
      longitude: payload?.longitude?.toString() || "",
      latitude: payload?.latitude?.toString() || "",
    };
    delete dataToSend.fileTemp;
    delete dataToSend.fileRevisionTemp;
    delete dataToSend.type_temp;
    postVerification.mutate(dataToSend);
  };

  const fileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      methods.register("file");
      methods.setValue("file", response.data.data.id);
    } catch (error) {
      throw error;
    }
  });

  const revisionFileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      methods.register("revision_file");
      methods.setValue("revision_file", response.data.data.id);
    } catch (error) {
      throw error;
    }
  });

  const postVerification = useMutation({
    mutationKey: ["post-verification-tally-sheet", data.id],
    mutationFn: (payload) => {
      const res = postVerificationTallySheet(data.id, typeOfService, payload);
      return res;
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries([
        "field-analyst-applicant-tally-sheet",
        applicantId,
      ]);
      enqueueSnackbar("Data berhasil disimpan", { variant: "success" });
      methods.reset();
      setOpen(false);
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });

  useQuery({
    queryKey: ["get-wood-type"],
    queryFn: async () => {
      const { data } = (await getWoodsType()) || { data: {} };
      return data;
    },
    onSuccess: ({ data }) => {
      setOptions(
        data.map((x) => ({
          value: x.nama,
          label: x.nama,
        }))
      );
    },
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!isEmpty(data)) {
      const temp = {};
      temp["area_temp"] = data?.area;
      temp["type_temp"] = data?.type;
      if (!isEmpty(data?.[`${propToGet}`])) {
        temp.tree_number =
          data?.[`${propToGet}`]?.tree_number;
        temp.area = data?.[`${propToGet}`]?.area;
        temp.type = data?.[`${propToGet}`]?.type;
        temp.consistency_data =
          data?.[`${propToGet}`]?.consistency_data;
        temp.longitude = data?.[`${propToGet}`]?.longitude;
        temp.latitude = data?.[`${propToGet}`]?.latitude;
        temp.size_difference =
          data?.[`${propToGet}`]?.size_difference;
        temp.revision_size_difference =
          data?.[`${propToGet}`]?.revision_size_difference;
        temp.revision_type =
          data?.[`${propToGet}`]?.revision_type;
        temp.revision_area =
          data?.[`${propToGet}`]?.revision_area;
      }
      methods.reset(temp);
    }
  }, [data, methods, propToGet]);

  useEffect(() => {
    if (
      data?.[`${propToGet}`]?.file &&
      data?.[`${propToGet}`]?.file !== "string"
    ) {
      getFileFromService(data?.[`${propToGet}`]?.file, {
        onSuccess: (data) => {
          methods.setValue("fileTemp", {
            type: data?.mimeType,
            ...data,
          });
        },
        onError: (error) => {
          console.log(error);
        },
      });
    }
    if (
      data?.[`${propToGet}`]?.revision_file &&
      data?.[`${propToGet}`]?.revision_file !== "string"
    ) {
      getFileFromService(
        data?.[`${propToGet}`]?.revision_file,
        {
          onSuccess: (data) => {
            methods.setValue("fileRevisionTemp", {
              type: data?.mimeType,
              ...data,
            });
          },
          onError: (error) => {
            console.log(error);
          },
        }
      );
    }
  }, [
    data?.[`${propToGet}`]?.file,
    data?.[`${propToGet}`]?.revision_file,
    methods,
    getFileFromService,
    propToGet,
  ]);

  const modalCallback = (val) => {
    if (val === false) {
      methods.reset();
    }
    setOpen(val);
  };

  const areaValue = methods.watch("area");
  const tempAreaValue = methods.watch("area_temp");
  const consistency_data = methods.watch("consistency_data");

  useEffect(() => {
    if (Number(tempAreaValue) > 0 && Number(areaValue) > 0) {
      const difference = Number(tempAreaValue) - Number(areaValue);
      methods.setValue("size_difference", difference);
    }
  }, [areaValue, tempAreaValue]);

  return (
    <Modal
      trigger={ButtonRendered}
      contentClassName="!max-w-5xl z-[99] !overflow-y-scroll"
      isOpen={open}
      onOpenChange={modalCallback}
      manual
    >
      <ModalHeader
        noBorder
        hideClose={false}
        title={
          <div className="text-lg font-semibold text-[#101828]">
            Ambil Sampling
          </div>
        }
      />
      <ModalBody>
        <FormProvider {...methods}>
          <div className="space-y-4 mb-6">
            <p className="font-bold text-[#01A24A]">Tally Sheet Data Desk</p>
            <InputForm
              controllerName={`tree_number`}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label="Nomor Pohon"
              placeholder="Nomor Pohon"
              required
              disabled={Number(status) === 2}
            />
            <div className="flex space-x-5">
              <div className="w-1/2 space-y-3">
                <InputForm
                  label="Jenis"
                  controllerName="type_temp"
                  className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
                  disabled
                />
              </div>
              <div className="w-1/2 space-y-3">
                <InputForm
                  label="Keliling (cm)"
                  controllerName="area_temp"
                  className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
                  disabled
                />
              </div>
            </div>
            <p className="font-bold text-[#01A24A]">{`Hasil Sampling ${
              Number(status) !== 1 ? "Pengusul" : ""
            }`}</p>
            <div className="flex space-x-5">
              <div className="w-1/2 space-y-3">
                <AtomSelect
                  label="Jenis"
                  options={options}
                  returnObject={false}
                  controllerName="type"
                  required
                  className={"basic-single flex-1"}
                  placeholder="Jenis"
                />
              </div>
              <div className="w-1/2 space-y-3">
                <InputForm
                  label="Keliling (cm)"
                  required
                  controllerName="area"
                  className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
                  isNumber={true}
                  onKeyPress={(event) => {
                    const ASCIICode = event.which ? event.which : event.keyCode;
                    if (
                      ASCIICode === 44 ||
                      (ASCIICode >= 48 && ASCIICode <= 57)
                    )
                      return;
                    event.preventDefault();
                  }}
                />
              </div>
            </div>
            <div className="flex space-x-5">
              <div className="w-1/2 space-y-3">
                <InputForm
                  label="Selisih Ukuran (cm)"
                  controllerName="size_difference"
                  className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
                  disabled
                />
              </div>
              <div className="w-1/2 space-y-3">
                <div>
                  <div className="flex gap-4">
                    <InputFormRadio
                      label="Kesesuaian Data"
                      controllerName={"consistency_data"}
                      required
                      values={[
                        {
                          label: "Sesuai",
                          value: true,
                        },
                        {
                          label: "Tidak Sesuai",
                          value: false,
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <InputFormUpload
              controllerName={`fileTemp`}
              label={"Unggah Foto"}
              uploadFile={fileMutation}
            />
            {consistency_data === false ? (
              <div className="border rounded-md px-4 py-3 space-y-5">
                <p className="font-bold text-[#01A24A]">
                  Revisi Hasil Sampling
                </p>
                <div className="flex space-x-5">
                  <div className="w-1/2 space-y-3">
                    <AtomSelect
                      label="Jenis"
                      options={options}
                      returnObject={false}
                      controllerName="revision_type"
                      required
                      className={"basic-single flex-1"}
                      placeholder="Jenis"
                    />
                  </div>
                  <div className="w-1/2 space-y-3">
                    <InputForm
                      label="Keliling (cm)"
                      required
                      controllerName="revision_area"
                      className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
                      isNumber={true}
                      onKeyPress={(event) => {
                        const ASCIICode = event.which
                          ? event.which
                          : event.keyCode;
                        if (
                          ASCIICode === 44 ||
                          (ASCIICode >= 48 && ASCIICode <= 57)
                        )
                          return;
                        event.preventDefault();
                      }}
                    />
                  </div>
                </div>
                <InputForm
                  label="Selisih Ukuran (cm)"
                  controllerName="revision_size_difference"
                  className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
                />
                <InputFormUpload
                  controllerName={`fileRevisionTemp`}
                  label={"Unggah Foto"}
                  uploadFile={revisionFileMutation}
                />
                <p className="font-bold text-[#01A24A]">Koordinat</p>
                <div className="flex space-x-5">
                  <div className="w-1/2 space-y-3">
                    <InputForm
                      label="Longitude"
                      controllerName="longitude"
                      className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
                    />
                  </div>
                  <div className="w-1/2 space-y-3">
                    <InputForm
                      label="Latitude"
                      controllerName="latitude"
                      className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
                    />
                  </div>
                </div>
                <div className="flex justify-start w-1/3">
                  <GeotagLocationButton
                    label="Ambil Geolocation"
                    icon={<RiMapPin2Fill className={"w-5 h-5 shrink-0"} />}
                    className={"!bg-primary-700 !text-white"}
                    onSuccess={(val) => {
                      methods.setValue("longitude", val.longitude);
                      methods.setValue("latitude", val.latitude);
                    }}
                  />
                </div>
              </div>
            ) : (
              <>
                <p className="font-bold text-[#01A24A]">Koordinat</p>
                <div className="flex space-x-5">
                  <div className="w-1/2 space-y-3">
                    <InputForm
                      label="Longitude"
                      controllerName="longitude"
                      className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
                    />
                  </div>
                  <div className="w-1/2 space-y-3">
                    <InputForm
                      label="Latitude"
                      controllerName="latitude"
                      className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
                    />
                  </div>
                </div>
                <div className="flex justify-start w-1/3">
                  <GeotagLocationButton
                    label="Ambil Geolocation"
                    icon={<RiMapPin2Fill className={"w-5 h-5 shrink-0"} />}
                    className={"!bg-primary-700 !text-white"}
                    onSuccess={(val) => {
                      methods.setValue("longitude", val.longitude);
                      methods.setValue("latitude", val.latitude);
                    }}
                  />
                </div>
              </>
            )}
            {/* <>
              <div className="flex space-x-5">
                {fileURL ? (
                  <div className="w-1/2">
                    <img
                      src={fileURL}
                      alt="file-url"
                      className="max-w-[250px] max-h-[250px]"
                    />
                  </div>
                ) : null}
                {fileRevisionURL ? (
                  <div className="w-1/2">
                    <img
                      src={fileRevisionURL}
                      alt="file-revision-url"
                      className="max-w-[250px] max-h-[250px]"
                    />
                  </div>
                ) : null}
              </div>
              <div className="grid grid-cols-3 gap-5">
                <div className="space-y-3">
                  <div className="font-semibold text-gray-800">Nama Pohon</div>
                  <div className="text-gray-900">{data?.name ?? "-"}</div>
                </div>
                <div className="space-y-3">
                  <div className="font-semibold text-gray-800">Jenis Pohon</div>
                  <div className="text-gray-900">
                    {data?.deferred_field_analyst_tally_sheet?.revision_type ??
                      data?.deferred_field_analyst_tally_sheet?.type ??
                      "-"}
                  </div>
                </div>
                <div className="space-y-3">
                  <div className="font-semibold text-gray-800">Keliling</div>
                  <div className="text-gray-900">
                    {data?.deferred_field_analyst_tally_sheet?.area ??
                      data?.deferred_field_analyst_tally_sheet?.revision_area ??
                      "-"}
                  </div>
                </div>
                <div className="space-y-3">
                  <div className="font-semibold text-gray-800">Longitude</div>
                  <div className="text-gray-900">
                    {data?.deferred_field_analyst_tally_sheet?.longitude ?? "-"}
                  </div>
                </div>
                <div className="space-y-3">
                  <div className="font-semibold text-gray-800">Latitude</div>
                  <div className="text-gray-900">
                    {data?.deferred_field_analyst_tally_sheet?.latitude ?? "-"}
                  </div>
                </div>
                <div>
                  <div className="font-semibold">Geo Location</div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-1">
                    <Button
                      type={"button"}
                      className="bg-[#00BD52] text-white py-4 mt-3 rounded-lg font-semibold"
                      label={
                        <div className="flex items-center gap-1 text-md">
                          <RiMapPin2Fill size={24} />
                          Google Maps
                        </div>
                      }
                      // onClick={onContinueClick(entry)}
                    />
                  </div>
                </div>
              </div>
            </> */}
            {/* {Number(status) !== 2 ? ( */}
            <div className="grid grid-cols-2 gap-4 pt-6">
              <ModalClose>
                <Button
                  label="Batal"
                  theme="secondary"
                  block
                  className="w-full"
                />
              </ModalClose>
              <Button
                label={"Submit"}
                theme="primary"
                block
                className="w-full"
                onClick={() => methods.handleSubmit(onSubmit)()}
              />
            </div>
            {/* ) : null} */}
          </div>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
});

export default UploadSamplingModal;
