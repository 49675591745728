import { yupResolver } from "@hookform/resolvers/yup";
import { BottomFormAction, InputForm } from "components";
import AtomSelect from "components/atoms/Select";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import RichEditorForm from "components/molecules/RichEditorForm";
import UploadedFile from "components/molecules/UploadedFile";
import { useCallback, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RiCheckLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import * as yup from "yup";

const OfferNegotiationTemplate = () => {
  const navigate = useNavigate();
  const title = "Daftar Penawaran - Perorangan dalam Kelompok";
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      search: yup.string(),
      state: yup.object(),
      date: yup.string(),
    });
  }, []);
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      search: "",
      state: "",
      date: "",
    },
  });
  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onFormSubmit = useCallback(() => {
    //
    console.log(methods.getValues());
  }, []);
  const isEdit = false;
  const onDraft = useCallback(() => {
    //
  }, []);
  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: "/offer-negotiation",
          },
          {
            label: "Template Penawaran Dengan Kelompok",
            path: "/offer-negotiation/template",
          },
        ]}
      />
      <FormProvider {...methods}>
        <CardForm label="Penawaran">
          <div className="flex justify-between gap-5">
            <RichEditorForm
              key={`reference_number`}
              name={`reference_number`}
              label={"Nomor Surat"}
              placeholder={"Nomor Surat"}
              required
              theme={"snow"}
            />
          </div>
        </CardForm>

        <BottomFormAction
          backButtonAction={onBack}
          submitActionButton={onFormSubmit}
          disableDrafButton={false}
          drafButtonAction={onDraft}
          disableButtonSubmit={false}
          hideDraft={true}
          lastStep={true}
          className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
        />
      </FormProvider>
    </div>
  );
};

export default OfferNegotiationTemplate;
