import React, { useState, useMemo, useCallback } from "react";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { TabsRoot, TabsList, TabsTrigger } from "components/atoms/Tabs";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams } from "react-router";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import { Spinner } from "components";
import SelfAssesmentContent from "../Detail/SelfAssesmentContent";
import PotentialDebtorContent from "../Detail/PotentialDebtorContent";
import VerificationRecapContent from "../Detail/VerificationRecapContent";
import ApplicationFormContent from "../Detail/ApplicationFormContent";
import {
  getDebtorDetail,
  verifiedFieldAnalystForestry,
} from "services/fdb/field-analis";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetValuePS,
  setValueDebtorPS,
  setValueRequestPS,
  setValueVerificationPS,
} from "redux/fieldAnalystSlice";

const title = "Daftar Registrasi Perorangan dalam Kelompok";

const FinancingDetail = () => {
  const dispatch = useDispatch();
  const { defaultValuePS } = useSelector(({ fieldAnalyst }) => fieldAnalyst);

  const { debtor, request: requestValue, verification } = defaultValuePS;
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("potential-debtor");
  const { applicantId, memberId } = useParams();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");

  const location = useLocation();
  const { pathname } = location;
  const segments = pathname.split("/");

  const { data, isLoading } = useQuery({
    queryKey: ["field-analyst-applicant-detail", applicantId],
    key: ["field-analyst-applicant-detail", applicantId],
    queryFn: async () => {
      const { data } = await getDebtorDetail(applicantId);
      return data;
    },
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    retry: false,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  const business_infos = useMemo(() => {
    let business_infos = [
      {
        type: "info",
        net_income_one_siclus: "",
        financed_province_id: "",
        financed_city_id: "",
        financed_district_id: "",
        financed_village_id: "",
        financed_address: "",
        financed_longitude: "",
        financed_latitude: "",
        debtor_house_file: "",
      },
      {
        type: "recommendation",
        net_income_one_siclus: "",
        financed_province_id: "",
        financed_city_id: "",
        financed_district_id: "",
        financed_village_id: "",
        financed_address: "",
        financed_longitude: "",
        financed_latitude: "",
        debtor_house_file: "",
      },
    ];
    if (data?.request?.field_analysts?.business_infos?.length > 0)
      business_infos = data?.request?.field_analysts?.business_infos;
    return business_infos;
  }, [data]);

  const debtorData = useMemo(() => {
    const { group_profile_member, request } = data || {
      group_profile_member: {},
      request: {},
    };
    const {
      debtor_verification,
      debtor_verification_signature_file,
      debtor_verification_identity_file,
      debtor_verification_identity_family_file,
      domicile_verification_latlong_file,
      domicile_verification,
      domicile_verification_desk,
      couple_verification,
      couple_verification_desk,
      income_info_verification,
      income_info_verification_desk,
      credit_history_status_social_assistance,
      credit_history_status_sikp,
      credit_history_status_bi_checking,
      domicile_verification_longitude,
      domicile_verification_latitude,
    } = request?.field_analysts || {};
    return {
      group_profile_member,
      request,
      defaultValues: {
        debtor_verification,
        debtor_verification_signature_file,
        debtor_verification_identity_file,
        debtor_verification_identity_family_file,
        domicile_verification_latlong_file,
        domicile_verification,
        domicile_verification_desk,
        couple_verification,
        couple_verification_desk,
        income_info_verification,
        income_info_verification_desk,
        credit_history_status_social_assistance,
        credit_history_status_sikp,
        credit_history_status_bi_checking,
        domicile_verification_longitude,
        domicile_verification_latitude,
        ...debtor,
      },
    };
  }, [data, debtor]);

  const applicationFormData = useMemo(() => {
    const { group_profile_member, request, submission_application } = data || {
      group_profile_member: {},
      request: {},
      submission_application: {},
    };

    const {
      business_info_verification,
      business_info_verification_desk,
      prospect_verification,
      prospect_verification_desk,
      prospect_income_adjusted,
      prospect_loan_ability_payment,
      prospect_maintenance_pattern,
      prospect_purchased_price,
      prospect_maintenance_price,
      prospect_maintenance_income,
      prospect_ready_purchased,
      prospect_sales_pattern,
      prospect_sales_capital,
      prospect_sales_cost,
      prospect_selling_price,
      prospect_selling_income,
      prospect_percentage_revenue,
      prospect_normal_income,
      prospect_make_profit,
    } = request?.field_analysts || {};
    return {
      group_profile_member,
      request,
      submission_application,
      defaultValues: {
        business_info_verification,
        business_info_verification_desk,
        prospect_verification,
        prospect_verification_desk,
        prospect_income_adjusted,
        prospect_loan_ability_payment,
        prospect_maintenance_pattern,
        prospect_purchased_price,
        prospect_maintenance_price,
        prospect_maintenance_income,
        prospect_ready_purchased,
        prospect_sales_pattern,
        prospect_sales_capital,
        prospect_sales_cost,
        prospect_selling_price,
        prospect_selling_income,
        prospect_percentage_revenue,
        prospect_normal_income,
        prospect_make_profit,
        business_infos: business_infos.filter((x) => x.type === "info"),
        ...requestValue,
      },
    };
  }, [data, requestValue, business_infos]);

  const verificationData = useMemo(() => {
    const { request } = data || {
      request: {},
    };

    const {
      v_result_identity,
      v_result_financed_business,
      v_result_financed_business_prospect,
      v_result_collateral_tree,
      v_result_collateral_tree_percentage,
      v_result_ownership_land,
    } = request?.field_analysts || {};

    const { application_details, ...rest } = request?.field_analysts || {};
    return {
      status: request?.field_status,
      defaultValues: {
        v_result_identity,
        v_result_financed_business,
        v_result_financed_business_prospect,
        v_result_collateral_tree,
        v_result_collateral_tree_percentage,
        v_result_ownership_land,
        business_infos: business_infos.filter(
          (x) => x.type === "recommendation"
        ),
        ...verification,
      },
    };
  }, [data, verification, business_infos]);

  const verifiedFieldAnalystCB = useMutation((payload) =>
    verifiedFieldAnalystForestry(applicantId, payload)
  );

  const onNextDetorData = useCallback(
    (value) => {
      dispatch(setValueDebtorPS(value));
      setActiveTab("self-assesment");
    },
    [dispatch]
  );

  const onNextRequestForm = useCallback(
    (value) => {
      dispatch(setValueRequestPS(value));
      setActiveTab("verification-recap");
    },
    [dispatch]
  );

  const onBackRequestForm = useCallback(
    (value) => {
      dispatch(setValueRequestPS(value));
      setActiveTab("self-assesment");
    },
    [dispatch]
  );

  const onBackVerification = useCallback(
    (value) => {
      dispatch(setValueVerificationPS(value));
      setActiveTab("application-form");
    },
    [dispatch]
  );

  const onFinalSubmit = (value) => {
    let business_infos = [
      {
        type: "info",
        net_income_one_siclus: "",
        financed_province_id: "",
        financed_city_id: "",
        financed_district_id: "",
        financed_village_id: "",
        financed_address: "",
        financed_longitude: "",
        financed_latitude: "",
        debtor_house_file: "",
      },
      {
        type: "recommendation",
        net_income_one_siclus: "",
        financed_province_id: "",
        financed_city_id: "",
        financed_district_id: "",
        financed_village_id: "",
        financed_address: "",
        financed_longitude: "",
        financed_latitude: "",
        debtor_house_file: "",
      },
    ];
    if (requestValue?.business_infos?.length > 0) {
      business_infos[0] = {
        ...business_infos[0],
        ...requestValue?.business_infos[0],
      };
      business_infos[0].net_income_one_siclus = Number(
        business_infos[0].net_income_one_siclus
      );
      business_infos[0].financed_latitude =
        business_infos?.[0]?.financed_latitude?.toString() || "";
      business_infos[0].financed_longitude =
        business_infos?.[0]?.financed_longitude?.toString() || "";
      if (business_infos[0].debtor_house_file_temp)
        delete business_infos[0].debtor_house_file_temp;
    }
    if (value?.business_infos?.length > 0) {
      business_infos[1] = {
        ...business_infos[1],
        ...value?.business_infos[0],
      };
      business_infos[1].net_income_one_siclus = Number(
        business_infos[1].net_income_one_siclus
      );
      business_infos[1].financed_latitude =
        business_infos?.[1]?.financed_latitude?.toString() || "";
      business_infos[1].financed_longitude =
        business_infos?.[1]?.financed_longitude?.toString() || "";
      if (business_infos[1].debtor_house_file_temp)
        delete business_infos[1].debtor_house_file_temp;
    }

    const tempAppDetail =
      requestValue?.application_details?.field_analyst || null;
    const {
      application_value,
      application_spell,
      period_type,
      period_value,
      installment_value,
      installment_spell,
    } = tempAppDetail || {};

    const tempPayload = {
      ...verification,
      ...requestValue,
      ...debtor,
      ...value,
      business_infos,
      is_draft: false,
      domicile_verification_latitude: value?.domicile_verification_latitude
        ? `${value?.domicile_verification_latitude}`
        : `${debtorData?.group_profile_member?.latitude}`,
      domicile_verification_longitude: value?.domicile_verification_longitude
        ? `${value?.domicile_verification_longitude}`
        : `${debtorData?.group_profile_member?.longitude}`,
      tabulation_land_longitude:
        value?.tabulation_land_longitude?.toString() || null,
      tabulation_land_latitude:
        value?.tabulation_land_latitude?.toString() || null,
      prospect_financed_longitude:
        value?.prospect_financed_longitude?.toString() || null,
      prospect_financed_latitude:
        value?.prospect_financed_latitude?.toString() || null,
      application_details: tempAppDetail
        ? {
            type: tempAppDetail?.category,
            application_value: Number(application_value),
            application_spell,
            period_type,
            period_value,
            installment_value: Number(installment_value),
            installment_spell,
          }
        : null,
    };
    verifiedFieldAnalystCB.mutate(tempPayload, {
      onSuccess: (res) => {
        dispatch(resetValuePS());
        enqueueSnackbar({
          variant: "success",
          message: (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontWeight: "bold" }}>
                Data berhasil disimpan!
              </span>
              <span>Data yang Anda masukkan berhasil dikirim</span>
            </div>
          ),
        });
        navigate(`/field-analyst/list-member/${memberId}`);
      },
      onError: (error) => {
        enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        });
      },
    });
  };

  const onSaveDraft = (value) => {
    let business_infos = [
      {
        type: "info",
        net_income_one_siclus: "",
        financed_province_id: "",
        financed_city_id: "",
        financed_district_id: "",
        financed_village_id: "",
        financed_address: "",
        financed_longitude: "",
        financed_latitude: "",
        debtor_house_file: "",
      },
      {
        type: "recommendation",
        net_income_one_siclus: "",
        financed_province_id: "",
        financed_city_id: "",
        financed_district_id: "",
        financed_village_id: "",
        financed_address: "",
        financed_longitude: "",
        financed_latitude: "",
        debtor_house_file: "",
      },
    ];
    if (requestValue?.business_infos?.length > 0) {
      business_infos[0] = {
        ...business_infos[0],
        ...requestValue?.business_infos[0],
      };
      business_infos[0].net_income_one_siclus = Number(
        business_infos[0].net_income_one_siclus
      );
    }
    if (value?.business_infos?.length > 0) {
      business_infos[1] = {
        ...business_infos[1],
        ...value?.business_infos[0],
      };
      business_infos[1].net_income_one_siclus = Number(
        business_infos[1].net_income_one_siclus
      );
    }

    const tempAppDetail =
      requestValue?.application_details?.field_analyst || null;
    const {
      application_value,
      application_spell,
      period_type,
      period_value,
      installment_value,
      installment_spell,
    } = tempAppDetail || {};

    const tempPayload = {
      ...verification,
      ...requestValue,
      ...debtor,
      ...value,
      is_draft: true,
      business_infos,
      domicile_verification_latitude: `${value?.domicile_verification_latitude}`,
      domicile_verification_longitude: `${value?.domicile_verification_longitude}`,
      tabulation_land_longitude:
        value?.tabulation_land_longitude?.toString() || null,
      tabulation_land_latitude:
        value?.tabulation_land_latitude?.toString() || null,
      prospect_financed_longitude:
        value?.prospect_financed_longitude?.toString() || null,
      prospect_financed_latitude:
        value?.prospect_financed_latitude?.toString() || null,
      application_details: tempAppDetail
        ? {
            type: tempAppDetail?.category,
            application_value: Number(application_value),
            application_spell,
            period_type,
            period_value,
            installment_value: Number(installment_value),
            installment_spell,
          }
        : null,
    };
    verifiedFieldAnalystCB.mutate(tempPayload, {
      onSuccess: (res) => {
        dispatch(resetValuePS());
        enqueueSnackbar({
          variant: "success",
          message: (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontWeight: "bold" }}>
                Data berhasil disimpan!
              </span>
              <span>Data yang Anda masukkan berhasil dikirim</span>
            </div>
          ),
        });
        navigate(`/field-analyst/list-member/${memberId}`);
      },
      onError: (error) => {
        enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        });
      },
    });
  };

  const selfAssesmentData = useMemo(() => {
    const { request } = data ?? {};

    if (typeof request === "undefined" || !request.self_assesment) return null;

    return {
      id: request?.self_assesment_id,
      questions1: [
        {
          id: 1,
          judulPertanyaan:
            "Apa usaha utama penghidupan sehari-hari dari calon pemohon?",
          key: request?.self_assesment?.financed_livelihood_prospective,
          verification:
            request?.self_assesment
              ?.financed_livelihood_prospective_verification,
          verification_key: "financed_livelihood_prospective_verification",
        },
        {
          id: 2,
          judulPertanyaan: "Apa tujuan dari penggunaan dana pinjaman tersebut?",
          key: request?.self_assesment?.financed_purpose_loan_funds,
          verification:
            request?.self_assesment?.financed_purpose_loan_funds_verification,
          verification_key: "financed_purpose_loan_funds_verification",
        },
        {
          id: 3,
          judulPertanyaan:
            "Sudah berapa lama usaha yang akan dibiayai sampai dengan saat ini? ",
          key: request?.self_assesment?.financed_business_funded,
          verification:
            request?.self_assesment?.financed_business_funded_verification,
          verification_key: "financed_business_funded_verification",
        },
        {
          id: 4,
          judulPertanyaan:
            "Berapa total perkiraan asset/modal yang sudah dimiliki saat ini?",
          key: request?.self_assesment?.financed_assest_currently,
          verification:
            request?.self_assesment?.financed_assest_currently_verification,
          verification_key: "financed_assest_currently_verification",
        },
        {
          id: 5,
          judulPertanyaan:
            "Apakah usahanya dikerjakan sendiri atau dengan orang lain?",
          key: request?.self_assesment?.financed_is_self_business,
          verification:
            request?.self_assesment?.financed_is_self_business_verification,
          verification_key: "financed_is_self_business_verification",
        },
        {
          id: 6,
          judulPertanyaan:
            "apabila dengan orang lain bagaimana mekanisme kerjanya?",
          key: request?.self_assesment?.financed_mechanism_work,
          verification:
            request?.self_assesment?.financed_mechanism_work_verification,
          verification_key: "financed_mechanism_work_verification",
        },
        {
          id: 7,
          judulPertanyaan: "Berapa penjualan/penghasilan dari usaha saat ini? ",
          key: request?.self_assesment?.financed_selling_income,
          verification:
            request?.self_assesment?.financed_selling_income_verification,
          verification_key: "financed_selling_income_verification",
        },
        {
          id: 8,
          judulPertanyaan:
            "Berapa harga beli/HPP/Modal yang dibutuhkan dari usaha saat ini?",
          key: request?.self_assesment?.financed_capital_price,
          verification:
            request?.self_assesment?.financed_capital_price_verification,
          verification_key: "financed_capital_price_verification",
        },
        {
          id: 9,
          judulPertanyaan:
            "Dimana saja cakupan wilayah usaha yang dijalankan saat ini?",
          key: request?.self_assesment?.financed_current_business_areas,
          verification:
            request?.self_assesment
              ?.financed_current_business_areas_verification,
          verification_key: "financed_current_business_areas_verification",
        },
        {
          id: 10,
          judulPertanyaan: "Bagaimana siklus usaha dari penjualan pemohon? ",
          key: request?.self_assesment?.financed_sales_business_cycle,
          verification:
            request?.self_assesment?.financed_sales_business_cycle_verification,
          verification_key: "financed_sales_business_cycle_verification",
        },
      ],
      questions2: [
        {
          id: 11,
          judulPertanyaan:
            "Apa jenis usaha lain yang menjadi sumber pendapatan lain (termasuk usaha pasangan)?",
          key: request?.self_assesment?.other_sources_income,
          verification:
            request?.self_assesment?.other_sources_income_verification,
          verification_key: "other_sources_income_verification",
        },
        {
          id: 12,
          judulPertanyaan:
            "Penghasilan didapatkan per bulan / sesuai dengan siklus usaha? Berapa nominalnya?",
          key: request?.self_assesment?.other_obtained_income,
          verification:
            request?.self_assesment?.other_obtained_income_verification,
          verification_key: "other_obtained_income_verification",
        },
        {
          id: 13,
          judulPertanyaan: "Berapa penjualan/penghasilan dari usaha saat ini?",
          key: request?.self_assesment?.other_selling_income,
          verification:
            request?.self_assesment?.other_selling_income_verification,
          verification_key: "other_selling_income_verification",
        },
        {
          id: 14,
          judulPertanyaan:
            "Berapa harga beli/HPP/Modal yang dibutuhkan dari usaha saat ini?",
          key: request?.self_assesment?.other_capital_price,
          verification:
            request?.self_assesment?.other_capital_price_verification,
          verification_key: "other_capital_price_verification",
        },
        {
          id: 15,
          judulPertanyaan: "Bagaimana siklus usaha dari penjualan pemohon?",
          key: request?.self_assesment?.other_sales_business_cycle,
          verification:
            request?.self_assesment?.other_sales_business_cycle_verification,
          verification_key: "other_sales_business_cycle_verification",
        },
        {
          id: 16,
          judulPertanyaan:
            "Berapa perkiraan pengeluaran rutin setiap bulannya?",
          key: request?.self_assesment?.other_outcome_per_month,
          verification:
            request?.self_assesment?.other_outcome_per_month_verification,
          verification_key: "other_outcome_per_month_verification",
        },
      ],
    };
  }, [data]);

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: `/${segments[1]}`,
          },
          {
            label: "Daftar Anggota",
            path: `/${segments[1]}/list-member/${segments[3]}`,
            search: {
              ...Object.fromEntries(searchParams.entries()),
              tab: "application-detail",
            },
          },
          {
            label: "Detail",
            path: `/${segments[1]}/${segments[2]}/${segments[3]}/${applicantId}`,
          },
        ]}
      />

      {isLoading ? (
        <div className="flex justify-center p-4">
          <Spinner theme={"primary"} />
        </div>
      ) : (
        <TabsRoot value={activeTab} onValueChange={setActiveTab}>
          <TabsList>
            <TabsTrigger disabled={true} value="potential-debtor">
              Calon Debitur
            </TabsTrigger>
            <TabsTrigger disabled={true} value="self-assesment">
              Self Assesment
            </TabsTrigger>
            <TabsTrigger disabled={true} value="application-form">
              Formulir Permohonan
            </TabsTrigger>
            <TabsTrigger disabled={true} value="verification-recap">
              Rekap Hasil Verifikasi
            </TabsTrigger>
          </TabsList>
          <PotentialDebtorContent
            initialValues={debtorData}
            onNext={onNextDetorData}
            onSaveDraft={onSaveDraft}
            hideDraft={!!status}
            type="persos"
          />
          <SelfAssesmentContent
            initialValues={selfAssesmentData}
            onBack={() => setActiveTab("potential-debtor")}
            onNext={() => setActiveTab("application-form")}
            hideDraft={!!status}
          />
          <ApplicationFormContent
            initialValues={applicationFormData}
            onBack={onBackRequestForm}
            onNext={onNextRequestForm}
            onSaveDraft={onSaveDraft}
            hideDraft={!!status}
          />
          <VerificationRecapContent
            initialValues={verificationData}
            onBack={onBackVerification}
            onNext={onFinalSubmit}
            onSaveDraft={onSaveDraft}
            hideDraft={!!status}
            type="persos"
          />
        </TabsRoot>
      )}
    </div>
  );
};

export default FinancingDetail;
