import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  defaultValueForm: {
    debtor: {},
    request: {},
    verification: {},
  },
  defaultValuePS: {
    debtor: {},
    request: {},
    verification: {},
  },
};

export const fieldAnalystSlice = createSlice({
  name: "fieldAnalyst",
  initialState,
  reducers: {
    resetValuePS: (state, action) => {
      state.defaultValuePS = {
        debtor: {},
        request: {},
        verification: {},
      };
    },
    resetValue: (state, action) => {
      state.defaultValueForm = {
        debtor: {},
        request: {},
        verification: {},
      };
    },
    setValueDebtor: (state, action) => {
      state.defaultValueForm = {
        ...state.defaultValueForm,
        debtor: {
          ...state.defaultValueForm.debtor,
          ...action?.payload,
        },
      };
    },
    setValueRequest: (state, action) => {
      state.defaultValueForm = {
        ...state.defaultValueForm,
        request: {
          ...state.defaultValueForm.request,
          ...action?.payload,
        },
      };
    },
    setValueVerification: (state, action) => {
      state.defaultValueForm = {
        ...state.defaultValueForm,
        verification: {
          ...state.defaultValueForm.verification,
          ...action?.payload,
        },
      };
    },
    setValueDebtorPS: (state, action) => {
      state.defaultValuePS = {
        ...state.defaultValuePS,
        debtor: {
          ...state.defaultValuePS.debtor,
          ...action?.payload,
        },
      };
    },
    setValueRequestPS: (state, action) => {
      state.defaultValuePS = {
        ...state.defaultValuePS,
        request: {
          ...state.defaultValuePS.request,
          ...action?.payload,
        },
      };
    },
    setValueVerificationPS: (state, action) => {
      state.defaultValuePS = {
        ...state.defaultValuePS,
        verification: {
          ...state.defaultValuePS.verification,
          ...action?.payload,
        },
      };
    },
  },
});

export const {
  setValueDebtor,
  setValueRequest,
  setValueVerification,
  setValueDebtorPS,
  setValueRequestPS,
  setValueVerificationPS,
  resetValue,
  resetValuePS,
} = fieldAnalystSlice.actions;

export default fieldAnalystSlice.reducer;
