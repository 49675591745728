import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";
import dayjs from "dayjs";

export const getDeskAnalystList = (
  page,
  limit,
  search = "",
  status = "",
  date = ""
) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/assign-desk-analyst?paginate=true&page=${page}&limit=${limit}&search=${search}&status=${status}&date=${date}`
  );
};

export const getDeskAnalystDetail = (id) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/assign-desk-analyst/${id}`
  );
};

export const putDeskAnalystDetail = (id, payload) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/assign-desk-analyst/${id}`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getAssignPelaksana = () => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL_USER_MANAGEMENT}/v1/Employee/get-user-by-divisi-hierarchy?idDivisi=${general_config_v2.ID_DIVISI}&idHierarcy=${general_config_v2.ID_HIERARCY}`
  );
};

// ! REQUEST LIST

export const getDeskAnalystPelaksana = (parameters) => {
  const date =
    parameters.date !== "" ? dayjs(parameters.date).format("YYYY-MM-DD") : "";

  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/desk-analyst`,
    {
      params: {
        ...parameters,
        date: date,
      },
    }
  );
};

export const getDeskAnalystPelaksanaDetail = ({ id, params }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/desk-analyst/${id}`,
    {
      params,
    }
  );
};

export const getDeskAnalystPelaksanaDetailMember = (id) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/desk-analyst/member/${id}`
  );
};

export const postDeskAnalystPelaksanaDetail = (id, payload, params) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/desk-analyst/member/verification/${id}`,
    payload,
    { params }
  );
};
