import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";

import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import Table, { TableWrapper } from "components/atoms/Table";
import { Pagination } from "components/v2/shared/pagination";
import { RiEyeLine, RiFile3Line } from "react-icons/ri";
import { Button, InputForm } from "components";
import {
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
} from "components/atoms/Modal";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAgreementDetail, putAgreement } from "services/fdb/agreement";
import dayjs from "dayjs";
import UploadedFileAsync from "components/molecules/UploadFileAsync";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar } from "notistack";
import { formatCurrency } from "helpers";

const feedbackSchema = yup.object({
  notes: yup.string().required().label("Feedback"),
});

const AgreementDetail = () => {
  const params = useParams();

  const breadcrumbs = useMemo(
    () => [
      {
        label: "Perjanjian",
        path: "/agreement",
      },
      {
        label: "Review Perjanjian",
        path: `/agreement/${params.id}/review`,
      },
    ],
    [params]
  );

  const { data } = useQuery(
    ["GET_AGREEMENT_DETAIL", params.id],
    getAgreementDetail,
    { select: (data) => data?.data?.data }
  );

  const navigate = useNavigate();

  const mutateAgreement = useMutation({ mutationFn: putAgreement });

  const methods = useForm({
    resolver: yupResolver(feedbackSchema),
    mode: "onChange",
  });

  const onFeedback = ({ notes }) => {
    mutateAgreement.mutate(
      {
        id: params?.id,
        data: {
          notes: notes,
          status: "feedback",
        },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Berhasil feedback perjanjian",
          });
          navigate(-1);
        },
      }
    );
  };

  return (
    <div className="space-y-6">
      <BreadCrumbs routes={breadcrumbs} />

      <GroupInfo data={data?.group_information} />
      <GroupManagementInfo data={data?.group_administrator} />
      <Address data={data?.group_address} />
      <GroupMemberApplication data={data?.group_members_application} />
      <AgreementDocument data={data?.agreement_docs} />

      <div className="sticky bottom-4 flex justify-between w-full p-5 bg-white border rounded-md">
        <Button
          className="p-4 border rounded-lg"
          type="button"
          label={
            <div className="flex items-center gap-2 text-sm font-medium">
              Kembali
            </div>
          }
        />
        <div className="flex gap-3">
          <Modal
            trigger={
              <Button
                className="p-4 bg-blue-600 hover:bg-blue-700 text-white"
                type="button"
                label={
                  <div className="flex items-center gap-2 text-sm font-medium">
                    Beri Feedback
                  </div>
                }
              />
            }
            contentClassName="!w-[686px] !max-w-[686px] !z-[51]"
          >
            <ModalHeader>Feedback</ModalHeader>
            <ModalBody className="space-y-5">
              <FormProvider {...methods}>
                <InputForm
                  className="w-full p-3 border"
                  controllerName="notes"
                  label="Alasan"
                  required
                  textArea
                />

                <hr />
                <div className="flex gap-3">
                  <ModalClose>
                    <Button
                      label="Batal"
                      className="flex flex-grow border justify-center font-semibold text-sm"
                    />
                  </ModalClose>
                  <Button
                    label="Submit"
                    className="flex flex-grow bg-primary-700 hover:bg-primary-600 text-white justify-center font-semibold text-sm"
                    onClick={methods.handleSubmit(onFeedback)}
                  />
                </div>
              </FormProvider>
            </ModalBody>
          </Modal>

          <Button
            className="p-4 border rounded-lg bg-primary-600 text-white hover:bg-primary-700"
            type="button"
            label={
              <div className="flex items-center gap-2 text-sm font-medium">
                Terima
              </div>
            }
            onClick={() => {
              mutateAgreement.mutate(
                {
                  id: params?.id,
                  data: {
                    notes: "",
                    status: "terima",
                  },
                },
                {
                  onSuccess: (res) => {
                    enqueueSnackbar({
                      variant: "success",
                      message: "Berhasil terima perjanjian",
                    });
                    navigate(-1);
                  },
                }
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

const GroupInfo = ({ data }) => {
  return (
    <CardForm label="Informasi kelompok">
      <div className="grid grid-cols-3 gap-x-5 gap-y-6">
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Nama KPS</h5>
          <p>{data?.name}</p>
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Nama KUPS</h5>
          <p>{data?.kups_name ?? "-"}</p>
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Tanggal dan tahun berdiri</h5>
          <p>
            {data?.founding_date
              ? dayjs(data?.founding_date).format("DD MMMM YYYY")
              : "-"}
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">
            Dasar Pembentukan / No Izin KUPS
          </h5>
          <p>{data?.kups_number}</p>
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Fungsi Kawasan & Skema PS</h5>
          <p>{data?.area_function_name}</p>
        </div>
        <div className="flex flex-col gap-2 col-span-3">
          <h5 className="text-sm font-semibold">
            Dasar persetujuan/ penetapan/ perjanjian akses kawasan
          </h5>
          <p>{data?.sk_number}</p>
        </div>
      </div>
    </CardForm>
  );
};

const GroupManagementInfo = ({ data }) => {
  return (
    <CardForm label="Informasi kepengurusan kelompok">
      <div className="flex flex-col gap-6">
        <div className="grid grid-cols-4 gap-x-5 gap-y-6">
          <div className="flex flex-col gap-2">
            <h5 className="text-sm font-semibold">Nama Ketua KPS</h5>
            <p>{data?.leader_name}</p>
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="text-sm font-semibold">Nomer Telepon Ketua KPS</h5>
            <p>{data?.leader_phone}</p>
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="text-sm font-semibold">Nama Bendahara KPS</h5>
            <p>{data?.treasure_name}</p>
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="text-sm font-semibold">
              Nomer Telepon Bendahara KPS
            </h5>
            <p>{data?.treasure_phone}</p>
          </div>

          <div className="flex flex-col gap-2">
            <h5 className="text-sm font-semibold">Nama Pendamping PS</h5>
            <p>{data?.companion_name}</p>
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="text-sm font-semibold">Afiliasi Pendamping PS</h5>
            <p>{data?.companion_affiliate}</p>
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="text-sm font-semibold">
              Nomer Telepon Pendamping PS
            </h5>
            <p>{data?.companion_phone}</p>
          </div>
        </div>

        <hr />

        <div className="grid grid-cols-4 gap-x-5 gap-y-6">
          <div className="flex flex-col gap-2 col-span-4">
            <h5 className="text-sm font-semibold">
              Jumlah anggota KPS/KUPS (Termasuk yang tidak mengajukan pinjaman)
            </h5>
            <p>{data?.total_member_not_apply}</p>
          </div>

          <div className="flex flex-col gap-2 col-span-2">
            <h5 className="text-sm font-semibold">
              Jumlah anggota KPS/KUPS yang mengajukan permohonan
            </h5>
            <p>{data?.total_member_apply}</p>
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="text-sm font-semibold">Jumlah Andil Garapan</h5>
            <p>{data?.total_member_apply_persil}</p>
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="text-sm font-semibold">Total Luas Lahan</h5>
            <p>{data?.total_member_apply_area}</p>
          </div>
        </div>
      </div>

      <hr />

      <div className="flex flex-col gap-6">
        <h5 className="text-sm font-semibold">
          Kegiatan - Kegiatan Kelompok yang telah dilaksanakan
        </h5>

        {data?.activities?.map((e, idx) => (
          <div className="flex gap-5" key={idx}>
            <div className="w-[234px] flex flex-col gap-2">
              <h5 className="text-sm font-semibold">Kategori</h5>
              <p>{e?.activity_category}</p>
            </div>
            <div className="flex flex-grow flex-col gap-2">
              <h5 className="text-sm font-semibold">Deskripsi</h5>
              <p className="line-clamp-1">{e?.activity_description}</p>
            </div>
          </div>
        ))}
      </div>

      <hr />

      <div className="flex flex-col gap-2">
        <h5>Mitra Usaha</h5>

        <ul>
          {data?.partners?.map((e, idx) => (
            <li key={idx}>{e?.name}</li>
          ))}
        </ul>
      </div>

      <hr />

      <div className="flex flex-col gap-6">
        <h5 className="text-sm font-semibold">
          Gambaran umum jenis - jenis usaha anggota kelompok yang dibiayai
        </h5>

        {data?.finance_businesses?.map((e, idx) => (
          <div className="flex gap-5" key={idx}>
            <div className="w-[234px] flex flex-col gap-2">
              <h5 className="text-sm font-semibold">Kategori</h5>
              <p>{e?.finance_businesses_category}</p>
            </div>
            <div className="flex flex-grow flex-col gap-2">
              <h5 className="text-sm font-semibold">Deskripsi</h5>
              <p className="line-clamp-1">
                {e?.finance_businesses_description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </CardForm>
  );
};

const Address = ({ data }) => {
  return (
    <CardForm label="Alamat">
      <div className="grid grid-cols-4 gap-x-5 gap-y-6">
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Provinsi</h5>
          <p>{data?.province}</p>
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Kota/Kabupaten</h5>
          <p>{data?.regency}</p>
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Kecamatan</h5>
          <p>{data?.subdistrict}</p>
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Kelurahan/Desa</h5>
          <p>{data?.subdistrict}</p>
        </div>

        <div className="flex flex-col gap-2 col-span-4">
          <h5 className="text-sm font-semibold">Alamat Kelompok</h5>
          <p>{data?.address}</p>
        </div>
      </div>
    </CardForm>
  );
};

const GroupMemberApplication = ({ data }) => {
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });

  const headers = useMemo(
    () => [
      {
        key: "index",
        title: "No",
        render: ({ index }) => {
          // const no =
          //   parseInt(data?.meta?.current_page) *
          //     parseInt(data?.meta?.per_page) -
          //   parseInt(data?.meta?.per_page) +
          //   index +
          //   1;

          const no = index + 1;

          return no;
        },
      },
      // {
      //   key: "group_name",
      //   title: "KTHR",
      // },
      {
        key: "applicant_name",
        title: "Nama Pemohon",
      },
      {
        key: "finance_business",
        title: "Jenis Usaha Yang dibiayai",
        // render: ({ item }) => {
        //   return (
        //     <div
        //       className={clsx(
        //         "flex items-center text-xs gap-1 px-[6px] py-0.5 rounded-full font-medium",
        //         badgeStyle[item.status]
        //       )}
        //     >
        //       <RiCircleFill size={8} />
        //       {capitalize(item.status)}
        //     </div>
        //   );
        // },
      },
      {
        key: "loan_value",
        title: "Nilai Pembiayaan",
        render: ({ item }) => formatCurrency(item?.loan_value),
      },
      {
        key: "distribution_scheme",
        title: "Penyaluran Pembiayaan Pinjaman",
        render: ({ item }) => {
          if (item.distribution_scheme === "at_once") {
            return "Sekaligus";
          }

          return "Bertahap";
        },
      },
      // {
      //   key: "grace_periode",
      //   title: "Grace Period",
      //   render: ({ item }) => {
      //     if (item.grace_periode.includes("null")) {
      //       return "-";
      //     }

      //     return item.grace_periode;
      //   },
      // },
      {
        key: "return_scheme",
        title: "Skema Pengembalian",
      },
      // {
      //   key: "action",
      //   title: "Aksi",
      //   alignment: "center",
      //   className: "sticky right-0 bg-white",
      //   render: ({ item }) => (
      //     <Button
      //       variant="icon"
      //       label={<RiEyeLine className="text-lg" />}
      //       onClick={() => {
      //         // navigate(`${item.id}`);
      //       }}
      //     />
      //   ),
      // },
    ],
    []
  );

  return (
    <CardForm label="Permohonan Anggota Kelompok">
      <TableWrapper className="mt-4">
        <Table
          headers={headers}
          // items={data?.data ?? []}
          items={data ?? []}
          // isLoading={isLoading}
        />
        {/* <Pagination
          page={1}
          limit={filters.limit}
          total={0}
          totalPage={1}
          onLimitChange={(e) => setFilters((prev) => ({ ...prev, limit: e }))}
          onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
        /> */}
      </TableWrapper>
    </CardForm>
  );
};

const AgreementDocument = ({ data }) => {
  return (
    <CardForm label="Dokumen Perjanjian">
      <div className="flex flex-col gap-2">
        {data?.map(
          (e, index) =>
            data[index]?.doc_agreement_draft_template[0]?.id && (
              <UploadedFileAsync
                key={e.id}
                id={data[index]?.doc_agreement_draft_template[0]?.id}
              />
            )
        )}
        {/* <div className="w-[400px] p-4 flex border rounded-xl gap-4">
          <div className="p-[6px] bg-primary-50 rounded-full text-primary-700">
            <RiFile3Line size={16} />
          </div>
          <div className="flex flex-col gap-1">
            <h5 className="text-sm font-medium line-clamp-1">
              Dokumen Perjanjian Tunda Tebang.xlsx (2MB)
            </h5>
            <button className="text-sm font-semibold text-primary-600 w-max hover:underline">
              Download File
            </button>
          </div>
        </div>
        <div className="w-[400px] p-4 flex border rounded-xl gap-4">
          <div className="p-[6px] bg-primary-50 rounded-full text-primary-700">
            <RiFile3Line size={16} />
          </div>
          <div className="flex flex-col gap-1">
            <h5 className="text-sm font-medium line-clamp-1">
              Dokumen Perjanjian Tunda Tebang.xlsx (2MB)
            </h5>
            <button className="text-sm font-semibold text-primary-600 w-max hover:underline">
              Download File
            </button>
          </div>
        </div> */}
      </div>
    </CardForm>
  );
};

export default AgreementDetail;
