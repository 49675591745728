import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage } from "../../helpers";

// util func
const doGetProfile = () => {
  // TODO: Replace this with actual api call
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const data = {
        data: {
          "_id": "64829033e9e5e25aa9715693",
          "email": "fikrisabriansyah@gmail.com",
          "role": "Lembaga Penyalur"
        }
      };
      
      resolve(data);
    }, 1500);
  })
}

// thunks
export const getProfileFromLogin = createAsyncThunk(
  'profileLogin',
  async (token, thunkAPI) => {
    const response = await doGetProfile();
    
    return response.data
  }
)

const initialState = {
  // TODO: Replace `data` object with actual user profile data
  data: {
    userId: "64829033e9e5e25aa9715693",
    email: "fikrisabriansyah@gmail.com",
    role: "Lembaga Penyalur"
  },
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: null
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearData: (state, action) => {
      state.data = action.payload;
    },
    profileRequest: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
    profileSuccess: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.errorMessage = null;
      state.data = action?.payload;
    },
    profileFailed: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = true;
      state.errorMessage = getErrorMessage(action?.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileFromLogin.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.errorMessage = null;
    })
      .addCase(getProfileFromLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.errorMessage = null;
        state.data = action?.payload;
    })
      .addCase(getProfileFromLogin.rejected, (state, action) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = true;
        state.errorMessage = getErrorMessage(action?.payload);
    })
  }
});

// this is for dispatch
export const { profileRequest, profileFailed, profileSuccess } =
  profileSlice.actions;

export const getProfile = (params) => {
  return async (dispatch, getState) => {
    dispatch(profileRequest());
    
    try {
      const data = await doGetProfile();
  
      dispatch(profileSuccess(data));
    } catch (error) {
      dispatch(profileFailed(error.response));
    }
  };
};

// this is for configureStore
export default profileSlice.reducer;
