import BreadCrumbs from "components/molecules/Breadcrumbs";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BottomFormAction, InputFormRadio, InputFormUpload, InputValidation, Spinner } from "components";
import ResultVerification from "./ResultVerification";
import CardForm from "components/molecules/CardForm";
import DistributionInformation from "./DistributionInformation";
import { useLocation } from "react-router";

const DetailReviewDisbursment = () => {
  const location = useLocation()
  const path = location.pathname.split('/');
  const disbursmentType = path[2]

  const [isLoading, setIsLoading] = useState(false)

  const methods = useForm({})

  return (
    <div className="space-y-6 mb-40">
      <BreadCrumbs
        routes={[
          {
            label: "Permohonan Lembaga Penyalur",
            path: "/institutions/disbursment-review",
          },
          {
            label: "Detail Lembaga",
            path: `/institutions/disbursment-review`,
          },
        ]}
      />

      {
        isLoading ? (
          <div className="flex justify-center p-4">
            <Spinner theme={"primary"} />
          </div>
        ) : (
          <FormProvider {...methods}>
            <DistributionInformation disbursmentType={disbursmentType} />
            <ResultVerification />
            <CardForm label="Simpulan Pelaksana">
              <div className="space-y-5">
                <InputFormRadio
                  controllerName={"is_grace_period"}
                  values={[
                    {
                      label: "Bisa dilanjutkan untuk proses Penyaluran",
                      value: true,
                    },
                    {
                      label: "Tidak bisa dilanjutkan untuk proses penyaluran",
                      value: false,
                    },
                  ]}
                />

                <InputValidation
                  label="Catatan Tambahan"
                  className={""}
                />
              </div>
            </CardForm>

            <CardForm label="Hasil Verifikasi Anda">
              <div className="space-y-5">
                <InputFormRadio
                  controllerName={"is_grace_period"}
                  values={[
                    {
                      label: "Bisa dilanjutkan untuk proses Penyaluran",
                      value: true,
                    },
                    {
                      label: "Tidak bisa dilanjutkan untuk proses penyaluran",
                      value: false,
                    },
                  ]}
                />

                <InputValidation
                  label="Catatan Tambahan"
                  className={""}
                />
              </div>
            </CardForm>

            <CardForm label="Upload Sprint Yang Sudah Di Tandatangani">
              <div className="space-y-5">
                <InputFormUpload
                  controllerName={`sprint_file`}
                  label={"Upload Sprint yang Sudah di Tandatangani"}
                // uploadFile={sprintFileMutation}
                />
              </div>

            </CardForm>
            <BottomFormAction
              // backButtonAction={handleBack}
              // submitActionButton={() => methods.handleSubmit(handleSubmit)()}
              disableDrafButton={false}
              hideSubmit={false}
              hideDraft={false}
              submitButtonProps={{
                type: "submit",
                label: "Submit",
              }}
              // drafButtonAction={handleDraft}
              disableButtonSubmit={false}
              // hideDraft={true}
              className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
            />
          </FormProvider>
        )
      }
    </div>
  )
}

export default DetailReviewDisbursment;