import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getListAssignDeskAnalyst = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-desk-analyst/assign`,
    { params: queryKey[1] }
  );
};

export const getDetailAssignDeskAnalyst = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-desk-analyst/assign/${queryKey[1]}`
  );
};

export const assignDeskAnalyst = (id, data) => {
  return axiosInstance.patch(
    `${general_config_v2.BASE_URL}/v1/cms/agency-desk-analyst/assign`,
    data,
    {
      params: {
        agency_desk_analyst_id: id,
      },
    }
  );
};

export const getListDeskAnalyst = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-desk-analyst`,
    { params: queryKey[1] }
  );
};

export const getDetailDeskAnalyst = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-desk-analyst/${queryKey[1]}`,
    { params: queryKey[2] }
  );
};

export const postDetailDeskAnalyst = (params, data) => {
  return axiosInstance.patch(
    `${general_config_v2.BASE_URL}/v1/cms/agency-desk-analyst/`,
    data,
    { params }
  );
};
