import { InputForm } from "components";
import CardForm from "components/molecules/CardForm";
import React from "react";

const AdditionalNotes = () => {
  return (
    <CardForm label="Catatan Tambahan">
      <InputForm
        label="Berikan Catatan Tambahan Jika diperlukan"
        textArea={true}
        className="w-full border rounded-md p-3"
        controllerName="notes"
      />
    </CardForm>
  );
};

export default AdditionalNotes;
