import React, { useMemo, useState, useCallback, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AtomSelect from "components/atoms/Select";
import CardForm from "components/molecules/CardForm";
import { useNavigate } from "react-router";
import { TabsContent } from "components/atoms/Tabs";
import { RiEyeLine, RiSearchLine } from "react-icons/ri";
import { InputForm, Button } from "components";
import { FormProvider, useForm } from "react-hook-form";
import Table, { TableWrapper } from "components/atoms/Table";
import TablePagination from "components/atoms/TablePagination";
import Badge from "components/atoms/Badge";
import dayjs from "dayjs";
import AtomDatePicker from "components/atoms/Datepicker";
import { debounce } from "lodash";
import { formatCurrency, formattedDate } from "helpers";
import { useDispatch } from "react-redux";
import { resetValue, resetValuePS } from "redux/fieldAnalystSlice";

const ApplicationDetailContent = ({
  initialValues,
  type,
  memberId,
  onBack,
  setPage,
  setLimit,
  totalItems,
  totalPage,
  setFilter
}) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);

  const data = useMemo(() => {
    return initialValues?.applicant ?? [];
  }, [initialValues]);

  const groupType = useMemo(() => {
    return initialValues?.groupType || {};
  }, [initialValues])

  // End For Group Table
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      search: yup.string(),
      // state: yup.string(),
      date: yup.string(),
    });
  }, []);
  // For Group Filter

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      search: "",
      // state: "",
      date: "",
    },
  });
  const handleSubmitFilter = debounce(() => {
    setFilter({
      search:
        methods.getValues("search") !== ""
          ? methods.getValues("search")
          : undefined,
      date:
        methods.getValues("date") !== ""
          ? methods.getValues("date")
          : undefined,
      status:
        methods.getValues("state") !== ""
          ? methods.getValues("state")
          : undefined,
    });
  }, 1000);
  // End For Group Filter

  useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(handleSubmitFilter)
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  useEffect(() => {
    dispatch(resetValuePS());
    dispatch(resetValue());
  }, []);

  return (
    <FormProvider {...methods}>
      <TabsContent value="application-detail" className="mb-40">
        <CardForm label="Detail Permohonan">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
            <div>
              <div className="font-semibold">Nama Kelompok</div>
              <div>{initialValues?.group_profile?.name ?? "-"}</div>
            </div>
            <div>
              <div className="font-semibold">Tanggal pengajuan anggota</div>
              <div>
                {initialValues?.group_profile?.created_at
                  ? formattedDate(initialValues?.group_profile?.created_at)
                  : "-"}
              </div>
            </div>
            <div>
              <div className="font-semibold">Total Anggota Diajukan</div>
              <div>{initialValues?.total_member ?? "-"}</div>
            </div>
            <div>
              <div className="font-semibold">Total Nilai Permohonan</div>
              <div>
                {" "}
                <p className="p-2 bg-[#EEFFF4] border-[#01A24A] border rounded-md text-[#01A24A] font-bold">
                  {initialValues?.total_application_value
                    ? formatCurrency(initialValues?.total_application_value)
                    : "-"}
                </p>
              </div>
            </div>
          </div>
        </CardForm>
        <CardForm>
          <GroupsFilter methods={methods} />
          <GroupsTable
            groupType={groupType}
            data={data}
            isLoading={false}
            totalItems={totalItems}
            totalPage={totalPage}
            setLimit={setLimit}
            setPage={setPage}
            memberId={memberId}
          />
        </CardForm>
      </TabsContent>
    </FormProvider>
  );
};

const GroupsFilter = ({ methods }) => {
  const stateOptions = useMemo(
    () => [
      { value: "Baru", label: "Baru" },
      { value: "Sedang Dikerjakan", label: "Sedang Dikerjakan" },
      { value: "Selesai ", label: "Selesai " },
    ],
    []
  );

  return (
    <FormProvider {...methods}>
      <form className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end">
        <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
          <RiSearchLine className="text-gray-400 text-xl" />
          <InputForm
            controllerName="search"
            placeholder="Cari"
            className={`!pl-0 w-full rounded-md border-none`}
          />
        </div>
        <AtomSelect
          controllerName={`state`}
          className={`w-full rounded-md h-10 absolute`}
          options={stateOptions}
          placeholder={"Status"}
          required={true}
          returnObject={false}
        />
        <AtomDatePicker
          controllerName={"date"}
          label=""
          placeholder="dd/mm/yyyy"
          datepickerOptions={{
            dateFormat: "dd/MM/yyyy",
          }}
          showErrorLabel
        />
      </form>
    </FormProvider>
  );
};

const stateColors = {
  1: "warning",
  2: "success",
  3: "error",
};

const GroupsTable = ({
  groupType,
  data,
  isLoading,
  totalPage,
  totalItems,
  setPage,
  setLimit,
  memberId,
}) => {
  const navigate = useNavigate();

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "name_debtor",
        title: "Nama",
      },
      {
        key: "date",
        title: "Tgl Permohonan",
        render: ({ item }) => {
          return dayjs(item.date).format("DD MMMM YYYY");
        },
      },
      {
        key: "state",
        title: "Status",
        render: ({ item }) =>
          item?.group_profile_member?.request?.[0]?.field_status ? (
            <Badge
              color={stateColors[Number(item?.group_profile_member?.request?.[0]?.field_status || 1)]}
            >
              {item?.group_profile_member?.request?.[0]?.field_status_string ?? "-"}
            </Badge>
          ) : (
            "-"
          ),
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        render: ({ item }) => {
          if (Number(item?.group_profile_member?.request?.[0]?.field_status || 1) > 1) {
            return (
              <Button
                variant="icon"
                label={<RiEyeLine className="text-lg" />}
                onClick={() => {
                  if (groupType?.toLowerCase() === "perhutanan sosial") {
                    navigate(
                      `/field-analyst/perhutanan-sosial/${memberId}/${
                        item.id
                      }?status=${encodeURIComponent(
                        item?.group_profile_member?.request?.[0]?.field_status || 1
                      )}`
                    );
                    return;
                  }
                  navigate(
                    `/field-analyst/list-member/${memberId}/${
                      item.id
                    }?status=${encodeURIComponent(
                      item?.group_profile_member?.request?.[0]?.field_status || 1
                    )}`
                  );
                }}
              />
            );
          } else {
            return (
              <Button
                theme="primary"
                label="Verifikasi"
                onClick={() => {
                  if (groupType?.toLowerCase() === "perhutanan sosial") {
                    navigate(
                      `/field-analyst/perhutanan-sosial/${memberId}/${
                        item.id
                      }`
                    );
                    return;
                  }
                  navigate(`/field-analyst/list-member/${memberId}/${item.id}`);
                }}
              />
            );
          }
        },
      },
    ];
  }, [navigate, memberId, groupType]);

  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={data} isLoading={isLoading} />
      <TablePagination
        totalPage={totalPage}
        totalItems={totalItems}
        onChange={setPage}
        onItemsPerPageChange={setLimit}
      />
    </TableWrapper>
  );
};
export default ApplicationDetailContent;
