/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { isEmpty } from "lodash";
import store from "./store";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "ngrok-skip-browser-warning": true,
  },
});

const generateErrorMessage = (error) => {
  let message = "Something went wrong";
  console.log(error);
  if (error?.response?.data?.message) {
    message = error?.response?.data?.message;
  } else if (error?.response?.data?.error?.message) {
    message = error?.response?.error?.message;
  } else if (error?.data?.message) {
    message = error?.data?.message;
  } else if (error?.data?.error?.message) {
    message = error?.data?.error?.message;
  }
  return message;
};

const requestInterceptor = (config) => {
  let userOidc = store.getState().oidc.oidc_user;
  if (userOidc?.access_token) {
    config.headers["Authorization"] = `Bearer ${userOidc.access_token}`;
  }
  if (config.url.includes("storage.groot.id")) {
    config.headers["Authorization"] = undefined;
  }
  return config;
};

const responseInterceptor = (response) => {
  if ("error" in response?.data && !isEmpty(response?.data?.error)) {
    enqueueSnackbar(generateErrorMessage(response), {
      autoHideDuration: 2500,
      variant: "error",
    });
    return Promise.reject(response);
  }
  return response;
};

const errorInterceptor = (error) => {
  if (error?.response?.status === 401) {
    window.location.replace(`${window.location.origin}/logout`);
  }
  if (axios.isCancel(error)) {
    return;
  }
  enqueueSnackbar(generateErrorMessage(error), {
    autoHideDuration: 2500,
    variant: "error",
  });
  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);
axiosInstance.interceptors.request.use(requestInterceptor);

export default axiosInstance;
