import { InputForm, InputFormRadio, Button } from "components";
import AccordionButton from "components/atoms/AccordionButton";
import CardForm from "components/molecules/CardForm";
import RichEditorForm from "components/molecules/RichEditorForm";
import { AnimatePresence, motion } from "framer-motion";
import { isEmpty } from "lodash";
import React, { Fragment, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import {
  RiAddLine,
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiDeleteBin2Line,
} from "react-icons/ri";
import { useSearchParams } from "react-router-dom";

const AccordionForm = ({ data }) => {
  const methods = useFormContext();
  const { errors } = methods.formState;

  const [accordionState, setAccordionState] = useState(true);

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "mandatory",
  });

  return (
    <Fragment>
      <AccordionButton
        key={"request-fdb"}
        label={"Hak dan Tanggung Jawab Lembaga"}
        isOpen={accordionState}
        onClick={() => setAccordionState((curr) => !curr)}
      />
      {accordionState && (
        <AnimatePresence mode="wait">
          <motion.div
            layout
            initial={{ opacity: 0, y: -15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="w-full bg-slate-100 p-5 rounded-md flex flex-col gap-4"
          >
            {fields.map((field, index) => {
              return (
                <div className="flex gap-4 items-end" key={field.id}>
                  {/* <AtomSelect
                    label={"Hak dan Tanggung Jawab Lembaga"}
                    wrapperClass={"flex flex-col justify-end"}
                    controllerName={`mandatory[${index}]`}
                    options={[
                      { value: "Tahun", label: "Tahun" },
                      { value: "Bulan", label: "Bulan" },
                      { value: "DAY", label: "Hari" },
                    ]}
                    className="basic-single w-full"
                    required={true}
                    returnObject={false}
                  /> */}
                  <InputForm
                    label="Hak dan Tanggung Jawab Lembaga"
                    wrapperClass=""
                    className="w-full"
                    controllerName={`mandatory[${index}]`}
                    required
                  />
                  {index > 0 ? (
                    <Button
                      className={
                        !isEmpty(errors) && errors?.rights?.[index]
                          ? "mb-[26px]"
                          : ""
                      }
                      variant="icon"
                      label={<RiDeleteBin2Line size={24} />}
                      theme="error"
                      onClick={() => {
                        remove(index);
                      }}
                    />
                  ) : null}
                </div>
              );
            })}
            <Button
              theme="primary"
              variant="icon"
              label={
                <div className="flex items-center justify-center gap-2 whitespace-nowrap">
                  <RiAddLine />
                  Hak dan Tanggung Jawab
                </div>
              }
              onClick={() => append()}
            />
          </motion.div>
        </AnimatePresence>
      )}
    </Fragment>
  );
};

const FinancingCondition = ({ data }) => {
  const methods = useFormContext();
  const [searchParams] = useSearchParams();

  const isNego = Number(searchParams?.get("status") || 0) === 3;

  const isVerified = useWatch({
    name: "condition_verify",
    control: methods.control,
  });

  return (
    <CardForm label="Kondisi Pembiayaan">
      <div className="space-y-5">
        <div className="bg-[#E1F1D6] p-3 w-full">
          <p className="font-bold text-[#01A24A]">Negosiasi yang Diajukan</p>
        </div>
        <div>
          <div className="font-semibold">Tujuan Pengunaan</div>
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{
              __html: data?.filed?.disbursement_purpose ?? "-",
            }}
          ></div>
        </div>
        <div>
          <div className="font-semibold">Jaminan</div>
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{
              __html: data?.filed?.collateral ?? "-",
            }}
          ></div>
        </div>
        <div>
          <div className="font-semibold">Perjanjian</div>
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{
              __html: data?.filed?.agreement ?? "-",
            }}
          ></div>
        </div>
        <div>
          <div className="font-semibold">Pencairan</div>
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{
              __html: data?.filed?.disbursement ?? "-",
            }}
          ></div>
        </div>
        <div>
          <div className="font-semibold">Hak dan Tanggung Jawab Lembaga</div>
          {data?.filed?.mandatory?.length > 0 ? (
            <ul className="list-decimal list-inside">
              {data?.filed?.mandatory?.map((x, key) => (
                <li key={x.id}>{x.mandatory}</li>
              ))}
            </ul>
          ) : (
            <div>-</div>
          )}
        </div>
        <div>
          <div className="font-semibold">Pengembalian</div>
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{
              __html: data?.filed?.installment ?? "-",
            }}
          ></div>
        </div>
        <div className="bg-[#E1F1D6] p-3 w-full">
          <p className="font-bold text-[#01A24A]">Negosiasi yang Ditawarkan</p>
        </div>
        {isNego ? (
          <Fragment>
            <RichEditorForm
              className="quill-6"
              name={`disbursement_purpose`}
              label="Tujuan Penggunaan"
              required={true}
            />
            <RichEditorForm
              className="quill-6"
              name={`collateral`}
              label="Jaminan"
              required={true}
            />
            <RichEditorForm
              className="quill-6"
              name={`agreement`}
              label="Perjanjian"
              required={true}
            />
            <RichEditorForm
              className="quill-6"
              name={`disbursement`}
              label="Pencairan"
              required={true}
            />
            <AccordionForm />
            <RichEditorForm
              className="quill-6"
              name={`installment`}
              label="Pengembalian"
              required={true}
            />
            <div className="border rounded-md px-4 py-3 col-span-3">
              <InputFormRadio
                label={"Hasil Verifikasi"}
                controllerName={`condition_verify`}
                className="flex items-center justify-between gap-4"
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Data Terverifikasi
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Data Belum Sesuai/ Ada Kesalahan
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
              {typeof isVerified !== "undefined" && isVerified === false && (
                <InputForm
                  controllerName="condition_verify_desc"
                  label="jelaskan kesalahan/ ketidak sesuaian datanya"
                  placeholder="jelaskan kesalahan/ ketidak sesuaian datanya"
                  required={true}
                  wrapperClass="col-span-2 mt-3"
                  className="rounded-md w-full px-4 py-3 border"
                  textArea
                />
              )}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div>
              <div className="font-semibold">Tujuan Pengunaan</div>
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: data?.offered?.disbursement_purpose ?? "-",
                }}
              ></div>
            </div>
            <div>
              <div className="font-semibold">Jaminan</div>
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: data?.offered?.collateral ?? "-",
                }}
              ></div>
            </div>
            <div>
              <div className="font-semibold">Perjanjian</div>
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: data?.offered?.agreement ?? "-",
                }}
              ></div>
            </div>
            <div>
              <div className="font-semibold">Pencairan</div>
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: data?.offered?.disbursement ?? "-",
                }}
              ></div>
            </div>
            <div>
              <div className="font-semibold">
                Hak dan Tanggung Jawab Lembaga
              </div>
              {data?.offered?.mandatory?.length > 0 ? (
                <ul className="list-decimal list-inside">
                  {data?.offered?.mandatory?.map((x, key) => (
                    <li key={x.id}>{x.mandatory}</li>
                  ))}
                </ul>
              ) : (
                <div>-</div>
              )}
            </div>
            <div>
              <div className="font-semibold">Pengembalian</div>
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: data?.offered?.installment ?? "-",
                }}
              ></div>
            </div>
          </Fragment>
        )}
      </div>
    </CardForm>
  );
};

export default FinancingCondition;
