import UploadedFileAsync from 'components/molecules/UploadFileAsync'
import React from 'react'

const GeneralInfo = ({ data }) => {
  return (
    <div className='space-y-5'>
        <div className="space-y-4">
          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">Profil Lembaga</p>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <div className="font-semibold">Nama Lembaga</div>
              <div>{data?.profile?.name}</div>
            </div>
            <div>
              <div className="font-semibold">Jenis Lembaga</div>
              <div>{data?.profile?.agency_type}</div>
            </div>
            <div>
              <div className="font-semibold">Jenis Layanan</div>
              <div>{data?.profile?.service_type}</div>
            </div>
            <div>
              <div className="font-semibold">Negara</div>
              <div>{data?.profile?.country}</div>
            </div>
            <div>
              <div className="font-semibold">Provinsi</div>
              <div>{data?.profile?.province}</div>
            </div>
            <div>
              <div className="font-semibold">Kabupaten / Kota</div>
              <div>{data?.profile?.city}</div>
            </div>
            <div>
              <div className="font-semibold">Kecamatan</div>
              <div>{data?.profile?.district}</div>
            </div>
            <div>
              <div className="font-semibold">Kelurahan</div>
              <div>{data?.profile?.village}</div>
            </div>
            <div>
              <div className="font-semibold">Kode Pos</div>
              <div>{data?.profile?.zip_code}</div>
            </div>
            <div className="col-span-2">
              <div className="font-semibold">Alamat Lengkap</div>
              <div>{data?.profile?.address}</div>
            </div>
            <div className="grid col-span-2 grid-cols-2 gap-4">
              <div>
                <div className="font-semibold">Email</div>
                <div>{data?.profile?.email}</div>
              </div>
              <div>
                <div className="font-semibold">No Telepon</div>
                <div>{data?.profile?.phone ?? "-"}</div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="space-y-4">
          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">Legalitas Lembaga</p>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="font-semibold">Akta Pendirian</div>
              <div>-</div>
            </div>
            <div>
              <div className="font-semibold">Pengesahan Akta Pendirian</div>
              <div>-</div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="font-semibold">Akta Perubahan</div>
              <div>-</div>
            </div>
            <div>
              <div className="font-semibold">Pengesahan Akta Perubahan</div>
              <div>-</div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="font-semibold">Nomor Induk Koperasi</div>
              <div>-</div>
            </div>
            <div>
              <div className="font-semibold">NPWP</div>
              <div>-</div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="font-semibold">Nomor Induk Berusaha / TDP</div>
              <div>-</div>
            </div>
            <div>
              <div className="font-semibold">Nomor Izin Usaha</div>
              <div>-</div>
            </div>
          </div>
        </div> */}
        <div className="space-y-4">
          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">Narahubung Lembaga</p>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <div className="font-semibold">Nama Narahubung</div>
              <div>{data?.contact_person?.contact_person_name}</div>
            </div>
            <div>
              <div className="font-semibold">Jabatan</div>
              <div>{data?.contact_person?.contact_person_position}</div>
            </div>
            <div>
              <div className="font-semibold">Email</div>
              <div>{data?.contact_person?.contact_person_email}</div>
            </div>
            <div>
              <div className="font-semibold">No Telepon</div>
              <div>{data?.contact_person?.contact_person_phone}</div>
            </div>
            <div>
              <div className="font-semibold">NIK</div>
              <div>{data?.contact_person?.contact_person_identity_number}</div>
            </div>
            <div>
              <div className="font-semibold">No NPWP</div>
              <div>{data?.contact_person?.contact_person_npwp}</div>
            </div>
            <div className="grid col-span-3 grid-cols-2 gap-4">
              <UploadedFileAsync
                id={data?.contact_person?.contact_person_identity_number_file}
                name="KTP Narahubung"
              />
              <UploadedFileAsync
                id={data?.contact_person?.contact_person_npwp_file}
                name="NPWP Narahubung"
              />
            </div>
          </div>
        </div>
        <div className="space-y-4">
          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">
              Latar Belakang Menjadi Penyalur
            </p>
          </div>
          <UploadedFileAsync
            id={data?.background_file}
            name="Surat Menjadi Lembaga Penyalur"
          />
        </div>
    </div>
  )
}

export default GeneralInfo