import React, { Fragment, useEffect, useState } from "react";

import CardForm from "components/molecules/CardForm";
import AtomSelect from "components/atoms/Select";
import RichEditorForm from "components/molecules/RichEditorForm";
import { Button, InputForm, InputFormRadio } from "components";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { isEmpty, startCase, toLower } from "lodash";
import {
  RiAddLine,
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiDeleteBin2Line,
} from "react-icons/ri";
import AccordionButton from "components/atoms/AccordionButton";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation, useSearchParams } from "react-router-dom";
import InputCurrencyForm from "components/v2/shared/input-currency-form";
import angkaTerbilangJs from "@develoka/angka-terbilang-js";

const service = true;

const AccordionForm = ({ data }) => {
  const methods = useFormContext();
  const { errors } = methods.formState;

  const location = useLocation();
  const [searchParams] = useSearchParams();

  const isView =
    searchParams.get("mode") !== null || location?.state?.status > 1;
  const [accordionState, setAccordionState] = useState(true);

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "mandatory",
  });

  return (
    <Fragment>
      <AccordionButton
        key={"request-fdb"}
        label={"Hak dan Tanggung Jawab Lembaga"}
        isOpen={accordionState}
        onClick={() => setAccordionState((curr) => !curr)}
      />
      {accordionState && (
        <AnimatePresence mode="wait">
          <motion.div
            layout
            initial={{ opacity: 0, y: -15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="w-full bg-slate-100 p-5 rounded-md flex flex-col gap-4"
          >
            {fields.map((field, index) => {
              return (
                <div className="flex gap-4 items-end" key={field.id}>
                  {/* <AtomSelect
                    label={"Hak dan Tanggung Jawab Lembaga"}
                    wrapperClass={"flex flex-col justify-end"}
                    controllerName={`mandatory[${index}]`}
                    options={[
                      { value: "Tahun", label: "Tahun" },
                      { value: "Bulan", label: "Bulan" },
                      { value: "DAY", label: "Hari" },
                    ]}
                    className="basic-single w-full"
                    required={true}
                    returnObject={false}
                  /> */}
                  <InputForm
                    label="Hak dan Tanggung Jawab Lembaga"
                    wrapperClass=""
                    className="w-full"
                    controllerName={`mandatory[${index}]`}
                    required
                    asText={isView}
                  />
                  {index > 0 ? (
                    <Button
                      className={
                        !isEmpty(errors) && errors?.rights?.[index]
                          ? "mb-[26px]"
                          : ""
                      }
                      variant="icon"
                      label={<RiDeleteBin2Line size={24} />}
                      theme="error"
                      onClick={() => {
                        remove(index);
                      }}
                    />
                  ) : null}
                </div>
              );
            })}
            {!isView && (
              <Button
                theme="primary"
                variant="icon"
                label={
                  <div className="flex items-center justify-center gap-2 whitespace-nowrap">
                    <RiAddLine />
                    Hak dan Tanggung Jawab
                  </div>
                }
                onClick={() => append()}
              />
            )}
          </motion.div>
        </AnimatePresence>
      )}
    </Fragment>
  );
};

const Recommendation = ({ data }) => {
  const { setValue, formState, control } = useFormContext();
  const { errors } = formState;

  const location = useLocation();
  const [searchParams] = useSearchParams();

  const isView =
    searchParams.get("mode") !== null || location?.state?.status > 1;

  const with_grace_period = useWatch({
    control,
    name: "is_grace_period",
  });

  const extend_time = useWatch({
    control,
    name: "is_extend",
  });

  const extend_time_both = useWatch({
    control,
    name: "extend_time_both",
  });

  const totalFdbField = useWatch({ control, name: "total_fdb" });

  useEffect(() => {
    if (totalFdbField) {
      setValue(
        "fdb_loan_spell",
        startCase(toLower(angkaTerbilangJs(totalFdbField)))
      );
    }
  }, [totalFdbField, setValue]);

  return (
    <CardForm label="Rekomendasi Ketentuan dan Kondisi Pembiayaan">
      <div className="space-y-5">
        <AtomSelect
          label="Skema Penyaluran FDB"
          wrapperClass={"flex flex-col justify-end"}
          controllerName={"disbursement_scheme"}
          options={
            service
              ? [
                  { value: "Langsung", label: "Langsung" },
                  { value: "Tidak Langsung", label: "Tidak Langsung" },
                ]
              : [{ value: "Langsung", label: "Langsung" }]
          }
          className="basic-single w-full"
          placeholder="Skema Penyaluran FDB"
          required={true}
          returnObject={false}
          asText={isView}
        />
        <RichEditorForm
          className="quill-6"
          name={`disbursement_purpose`}
          label="Tujuan Penggunaan"
          required={true}
          readOnly={isView}
        />
        <div className="flex gap-4">
          {/* <InputForm
            required={true}
            label="Jumlah FDB"
            controllerName="total_fdb"
            isNumber={true}
            prefix="Rp"
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
            className="w-full"
            onChangeInput={(event) => {
              setValue(
                "fdb_loan_spell",
                `${startCase(
                  camelCase(angkaTerbilangJs(Number(event.target.value)))
                )} Rupiah`
              );
            }}
            // asText={isView}
          /> */}
          <InputCurrencyForm
            label="Jumlah FDB"
            prefix="Rp"
            required
            name="total_fdb"
            asText={isView}
          />
          <InputForm
            label="Terbilang"
            controllerName="fdb_loan_spell"
            disabled
            className="w-full"
            asText={isView}
          />
        </div>
        <div className="grid grid-cols-3 gap-4">
          <p className="font-semibold col-span-3">Tarif Layanan</p>
          {service && (
            <InputForm
              label={"Persentase Pertahun"}
              required={true}
              controllerName={"percentage_per_year"}
              className="w-full rounded-md border p-3 "
              rightIcon="%"
              onKeyPress={(event) => {
                const ASCIICode = event.which ? event.which : event.keyCode;
                if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                  return;
                event.preventDefault();
              }}
              asText={isView}
            />
          )}
          <AtomSelect
            label={"Metode Pemberlakuan"}
            wrapperClass={"flex flex-col justify-end"}
            controllerName={`enforcement_method`}
            options={[
              { value: "fixed", label: "Fixed" },
              { value: "floating", label: "Floating" },
            ]}
            className="basic-single w-full"
            required={true}
            placeholder="Metode Pemberlakuan"
            returnObject={false}
            asText={isView}
          />
          <AtomSelect
            label={"Metode Perhitungan Tarif"}
            wrapperClass={"flex flex-col justify-end"}
            controllerName={`calculation_method`}
            options={[
              { value: "efective", label: "Efektif" },
              { value: "flat", label: "Flat" },
            ]}
            className="basic-single w-full"
            required={true}
            placeholder="Metode Perhitungan Tarif"
            returnObject={false}
            asText={isView}
          />
        </div>
        <div className="w-full grid grid-cols-2 gap-4">
          <p className="font-semibold col-span-3">Jangka Waktu</p>
          <InputForm
            label={"Jangka Waktu"}
            required={true}
            controllerName={"term_value"}
            className="w-full rounded-md border p-3 "
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
            asText={isView}
          />
          <AtomSelect
            label={"Satuan Waktu"}
            wrapperClass={"flex flex-col justify-end"}
            controllerName={`term_type`}
            options={[
              { value: "Tahun", label: "Tahun" },
              { value: "Bulan", label: "Bulan" },
              { value: "Hari", label: "Hari" },
            ]}
            className="basic-single w-full"
            required={true}
            returnObject={false}
            asText={isView}
          />
        </div>
        <InputFormRadio
          label={"Dengan Grace Period?"}
          controllerName={"is_grace_period"}
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Ya
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Tidak
                </>
              ),
              value: false,
            },
          ]}
          disabled={isView}
        />
        {with_grace_period === true && (
          <div className="flex gap-4">
            <InputForm
              label={"Berapa Jangka Waktu Grace Period"}
              required={true}
              controllerName={"grace_period_value"}
              className="w-full rounded-md border p-3 "
              onKeyPress={(event) => {
                const ASCIICode = event.which ? event.which : event.keyCode;
                if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                  return;
                event.preventDefault();
              }}
              asText={isView}
            />
            <AtomSelect
              label={"Berapa Satuan Waktu Grace Period"}
              wrapperClass={"flex flex-col justify-end"}
              controllerName={`grace_period_type`}
              options={[
                { value: "Tahun", label: "Tahun" },
                { value: "Bulan", label: "Bulan" },
                { value: "Hari", label: "Hari" },
              ]}
              className="basic-single w-full"
              required={true}
              returnObject={false}
              asText={isView}
            />
          </div>
        )}
        <InputFormRadio
          label={"Jangka Waktu Diperpanjang?"}
          controllerName={"is_extend"}
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Ya
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Tidak
                </>
              ),
              value: false,
            },
          ]}
          disabled={isView}
        />
        {extend_time === true && (
          <>
            {/* <div className="flex gap-4">
              <InputForm
                label={"Waktu Perpanjangan"}
                required={true}
                controllerName={"extend_value"}
                className="w-full rounded-md border p-3 "
                onKeyPress={(event) => {
                  const ASCIICode = event.which ? event.which : event.keyCode;
                  if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                    return;
                  event.preventDefault();
                }}
                asText={isView}
              />
              <AtomSelect
                label={"Satuan Waktu"}
                wrapperClass={"flex flex-col justify-end"}
                controllerName={`extend_type`}
                options={[
                  { value: "Tahun", label: "Tahun" },
                  { value: "Bulan", label: "Bulan" },
                  { value: "Hari", label: "Hari" },
                ]}
                className="basic-single w-full"
                required={true}
                returnObject={false}
                asText={isView}
              />
            </div> */}
            <InputFormRadio
              label={
                "Jangka Waktu Dapat Diperpanjang Sesuai Dengan Kesepakatan Dua Belah Pihak?"
              }
              controllerName={"extend_time_both"}
              values={[
                {
                  label: (
                    <>
                      <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                      Ya
                    </>
                  ),
                  value: true,
                },
                {
                  label: (
                    <>
                      <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                      Tidak
                    </>
                  ),
                  value: false,
                },
              ]}
              disabled={isView}
            />
          </>
        )}

        <RichEditorForm
          className="quill-6"
          name={`collateral`}
          label="Jaminan"
          required={true}
          readOnly={isView}
        />
        <RichEditorForm
          className="quill-6"
          name={`agreement`}
          label="Perjanjian"
          required={true}
          readOnly={isView}
        />
        <RichEditorForm
          className="quill-6"
          name={`disbursement`}
          label="Pencairan"
          required={true}
          readOnly={isView}
        />
        <AccordionForm />
        <RichEditorForm
          className="quill-6"
          name={`installment`}
          label="Pembayaran Angsuran"
          required={true}
          readOnly={isView}
        />
      </div>
    </CardForm>
  );
};

export default Recommendation;
