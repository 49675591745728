import React from "react";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import { Spinner } from "components";

import NegotiationDetail from "./NegotiationDetail";
import { getOfferNegotiation } from "services/fdb/offers";

const title = "Daftar Penawaran";

const FinancingDetail = () => {
  const { applicantId } = useParams();

  const location = useLocation();
  const { pathname } = location;
  const segments = pathname.split("/");

  const { data, status } = useQuery({
    queryKey: ["offer-negotiation-detail", applicantId],
    queryFn: async () => {
      return (await getOfferNegotiation(applicantId)).data;
    },
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    refetchOnWindowFocus: false,
  });

  return (
    <div className="space-y-6 mb-40">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: `/${segments[1]}`,
          },
          {
            label: "Daftar Anggota",
            path: `/${segments[1]}/${segments[2]}/${segments[3]}`,
            useSearch: true,
          },
          {
            label: "Detail",
            path: `/${segments[1]}/${segments[2]}/${segments[3]}/${applicantId}`,
            useSearch: true,
          },
        ]}
      />

      {status === "loading" ? (
        <div className="flex justify-center p-4">
          <Spinner theme={"primary"} />
        </div>
      ) : (
        <NegotiationDetail initialValues={data?.data} />
      )}
    </div>
  );
};

export default FinancingDetail;
