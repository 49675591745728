import React, { useEffect, useCallback, useState, useContext } from "react";
import { RiMenuFill, RiNotification2Line } from "react-icons/ri";
import { AuthContext } from "providers/authProvider";
import Button from "components/atoms/Button/Button";
import { useNavigate } from "react-router";

const Header = ({ open, onMenuClick }) => {
  return (
    <header className="flex justify-between items-center gap-4 px-8 py-3 shadow">
      <div className="flex items-center gap-3">
        {!open && (
          <Button
            label={<RiMenuFill className="text-lg" />}
            onClick={onMenuClick}
          />
        )}
        <Button label="Portal Menu E-Office" />
      </div>
      <div className="flex gap-2 items-center">
        <Button
          variant="icon"
          label={<RiNotification2Line className="text-lg" />}
        />
        <UserInfo />
      </div>
    </header>
  );
};

const UserInfo = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [userData, setUserData] = useState();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const user = await authContext.getUser();
        setUserData(user);
      } catch (e) {
        console.log(e);
      }
    };
    getUserData();
  }, [authContext]);

  const handleLogout = useCallback(() => {
    if (window.confirm("Logout?")) {
      navigate("/logout");
    }
  }, [navigate]);

  return (
    <>
      <button className="flex items-center gap-2" onClick={handleLogout}>
        <img
          className="rounded-full w-8 h-8"
          src="https://picsum.photos/id/1005/200/200"
          alt=""
        />
        <label className="font-semibold text-[16px] text-black">
          {userData?.profile?.Name}
        </label>
      </button>
    </>
  );
};

export default Header;
