import React, { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router";
import { enqueueSnackbar } from "notistack";

import { getCommitteeMinuteDetail } from "services/fdb/committee";
import CardForm from "components/molecules/CardForm";
import { formatCurrency, getErrorMessage } from "helpers";
import DataDefinition from "components/atoms/DataDefinition";
import Table, { TableWrapper } from "components/atoms/Table";
import { Pagination } from "components/v2/shared/pagination";
import { Button } from "components";
import { RiEyeLine } from "react-icons/ri";
import { isEmpty } from "lodash";

const GRACE_PERIOD = {
  ADJUSTED_TO_THE_BUSINESS_CYCLE: "Disesuaikan Dengan Siklus Usaha",
  GRACE_PERIOD_CUSTOMIZATION: "Kustomisasi Grace Period",
};

const APPLICATION_METHODS = {
  FIXED: "Fixed",
  FLOATING: "Floating",
};

const RATE_METHODS = {
  EFFECTIVE: "Efektif",
  FLAT: "Flat",
};

const TIME_UNIT = {
  YEAR: "Tahun",
  MONTH: "Bulan",
  DAY: "Hari",
};

const DISTRIBUTION_SCHEME = {
  DIRECT: "Langsung",
  INDIRECT: "Tidak Langsung",
};

const DetailBeritaAcara = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });

  const { data: committeeMinute, isLoading } = useQuery({
    queryKey: ["GET DETAIL COMMITTEE MINUTE", id, filters],
    queryFn: async () => {
      const { data } = await getCommitteeMinuteDetail(id, { ...filters });
      return data?.data;
    },
    onError: (error) => {
      console.log("error: ", error);
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "group_name",
        title: "KTHR",
      },
      {
        key: "name",
        title: "Nama Permohonan",
      },
      {
        key: "business_type",
        title: "Jenis Usaha Yang dibiayai",
      },
      {
        key: "application_value",
        title: "Nilai Pembiayaan",
        render: ({ item }) => {
          return formatCurrency(item?.application_value);
        },
      },
      // {
      //   key: "action",
      //   title: "Aksi",
      //   alignment: "center",
      //   className: "sticky right-0 bg-white",
      //   render: ({ item }) => (
      //     <>
      //       <Button
      //         variant="icon"
      //         label={<RiEyeLine className="text-lg" />}
      //         onClick={() => {
      //           navigate(`/member-in-group-ba/${item.id}`);
      //         }}
      //       />
      //     </>
      //   ),
      // },
    ];
  }, [navigate]);

  return (
    <div className="space-y-5">
      <CardForm label="Informasi Kelompok">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <DataDefinition
            label="Nomor Berita Acara"
            value={committeeMinute?.committeeMinutesNumber ?? "-"}
          />
          <DataDefinition
            label="Nomor Surat Edaran Rapat Risalah"
            value={committeeMinute?.meetingCircularMinutesNumber ?? "-"}
          />
          <DataDefinition
            label="Nomor Risalah"
            value={committeeMinute?.risalah_number ?? "-"}
          />
          <DataDefinition
            label="Nama Kelompok"
            value={committeeMinute?.group_name ?? "-"}
          />
        </div>
      </CardForm>
      <CardForm label="Berita Acara Komite">
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
          <div className="flex flex-col gap-1">
            <p className="font-semibold">Dasar Hukum</p>
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: committeeMinute?.legalBasis ?? "-",
              }}
            ></div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-semibold">Materi Pembahasan Komite</p>
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: committeeMinute?.materialsOfCommitteeDiscussions ?? "-",
              }}
            ></div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-semibold">Hasil Pembahasan Komite</p>
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: committeeMinute?.resultsOfCommitteeDiscussions ?? "-",
              }}
            ></div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-semibold">Rekomendasi</p>
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: committeeMinute?.recommendation ?? "-",
              }}
            ></div>
          </div>
        </div>
      </CardForm>
      <CardForm label="Ketentuan">
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
          <DataDefinition
            label="Jenis Layanan"
            value={committeeMinute?.provisionService ?? "-"}
          />
          <DataDefinition
            label="Skema Penyaluran FDB"
            value={
              committeeMinute?.fdbDistributionScheme
                ? DISTRIBUTION_SCHEME[
                    `${committeeMinute?.fdbDistributionScheme}`
                  ]
                : "-"
            }
          />
          {committeeMinute?.gracePeriod === "GRACE_PERIOD_CUSTOMIZATION" && (
            <DataDefinition
              label="Grace Period"
              value={`${committeeMinute?.gracePeriodTimeUnitValue ?? ""} ${
                committeeMinute?.gracePeriodTimeUnit
                  ? TIME_UNIT[`${committeeMinute?.gracePeriodTimeUnit}`]
                  : ""
              }`}
            />
          )}
          {committeeMinute?.gracePeriod ===
            "ADJUSTED_TO_THE_BUSINESS_CYCLE" && (
            <DataDefinition
              label="Grace Period"
              value={
                committeeMinute?.gracePeriod
                  ? `${GRACE_PERIOD[`ADJUSTED_TO_THE_BUSINESS_CYCLE`]}`
                  : "-"
              }
            />
          )}
          <div className="flex flex-col gap-1">
            <p className="font-semibold">Tujuan FDB</p>
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: committeeMinute?.fdbObjectives ?? "-",
              }}
            ></div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-semibold">Jangka Waktu Pinjaman FDB</p>
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: committeeMinute?.fdbLoanTerm ?? "-",
              }}
            ></div>
          </div>
          <DataDefinition
            label="Jangka Waktu Pembiayaan FDB"
            value={`${committeeMinute?.fdbFinancingTermValue ?? ""} ${
              committeeMinute?.fdbFinancingTermPeriod
                ? TIME_UNIT[`${committeeMinute?.fdbFinancingTermPeriod}`]
                : ""
            }`}
          />
          <div className="flex flex-col gap-1">
            <p className="font-semibold">Jaminan</p>
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: committeeMinute?.guarantee ?? "-",
              }}
            ></div>
          </div>
          <hr />
          <p className="font-semibold">Tarif Layanan</p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <DataDefinition
              label="Persentase Pertahun"
              value={`${
                committeeMinute?.percentageServiceRatePerYear
                  ? `${committeeMinute?.percentageServiceRatePerYear}%`
                  : ""
              }`}
            />
            <DataDefinition
              label="Metode Pemberlakuan"
              value={`${
                committeeMinute?.serviceRatesApplicationMethod
                  ? APPLICATION_METHODS[
                      `${committeeMinute?.serviceRatesApplicationMethod}`
                    ]
                  : ""
              }`}
            />
            <DataDefinition
              label="Metode Perhitungan Tarif"
              value={`${
                committeeMinute?.serviceRateCalculationMethod
                  ? RATE_METHODS[
                      `${committeeMinute?.serviceRateCalculationMethod}`
                    ]
                  : ""
              }`}
            />
          </div>
          <hr />
          <div className="flex flex-col gap-2">
            <div className="font-semibold">Penyaluran FDB</div>
            {committeeMinute?.fdb_distribution?.length > 0 ? (
              <ul className="list-decimal list-inside">
                {committeeMinute?.fdb_distribution
                  ?.sort((a, b) => {
                    return a.orderNumber - b.orderNumber;
                  })
                  .map((x, key) => {
                    return (
                      <>
                        <li key={key}>{x.value}</li>
                        {x?.sub?.length > 0 ? (
                          <ul className="list-[lower-alpha] list-inside pl-3">
                            {x?.sub
                              ?.sort((a, b) => {
                                return a.orderNumber - b.orderNumber;
                              })
                              .map((sub, idx) => (
                                <>
                                  <li key={`sub-${idx}`}>{sub.value}</li>
                                  {sub?.sub_sub?.length > 0 ? (
                                    <ul className="list-[lower-alpha] list-inside pl-3">
                                      {sub?.sub_sub
                                        ?.sort((a, b) => {
                                          return a.orderNumber - b.orderNumber;
                                        })
                                        .map((ss, i) => (
                                          <li key={`sub_sub-${i}`}>
                                            {ss?.value}
                                          </li>
                                        ))}
                                    </ul>
                                  ) : null}
                                </>
                              ))}
                          </ul>
                        ) : null}
                      </>
                    );
                  })}
              </ul>
            ) : (
              <p>-</p>
            )}
          </div>
          <hr />
          <div className="flex flex-col gap-2">
            <div className="font-semibold">Pengembalian FDB</div>
            {committeeMinute?.fdb_return?.length > 0 ? (
              <ul className="list-decimal list-inside">
                {committeeMinute?.fdb_return
                  ?.sort((a, b) => {
                    return a.orderNumber - b.orderNumber;
                  })
                  .map((x, key) => {
                    return (
                      <>
                        <li key={key}>{x.value}</li>
                        {x?.sub?.length > 0 ? (
                          <ul className="list-[lower-alpha] list-inside pl-3">
                            {x?.sub
                              ?.sort((a, b) => {
                                return a.orderNumber - b.orderNumber;
                              })
                              .map((sub, idx) => (
                                <li key={`sub-${idx}`}>{sub.value}</li>
                              ))}
                          </ul>
                        ) : null}
                      </>
                    );
                  })}
              </ul>
            ) : (
              <p>-</p>
            )}
          </div>
          <hr />
          <div className="flex flex-col gap-2">
            <div className="font-semibold">Objek yang dibiayai</div>
            {committeeMinute?.the_object_being_financed ? (
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: committeeMinute?.the_object_being_financed,
                }}
              ></div>
            ) : (
              <p>-</p>
            )}
          </div>
          <hr />
          <div className="flex flex-col gap-2">
            <div className="font-semibold">
              Hal-hal yang harus dilakukan (Positive Covenants)
            </div>
            {committeeMinute?.positive_covenants?.length > 0 ? (
              <ul className="list-decimal list-inside">
                {committeeMinute?.positive_covenants
                  ?.sort((a, b) => {
                    return a.orderNumber - b.orderNumber;
                  })
                  .map((x, key) => (
                    <li key={key}>{x.value}</li>
                  ))}
              </ul>
            ) : (
              <p>-</p>
            )}
          </div>
          <hr />
          <div className="flex flex-col gap-2">
            <div className="font-semibold">
              Hal-hal yang tidak boleh dilaksanakan (Negative Covenants)
            </div>
            {committeeMinute?.negative_covenants?.length > 0 ? (
              <ul className="list-decimal list-inside">
                {committeeMinute?.negative_covenants
                  ?.sort((a, b) => {
                    return a.orderNumber - b.orderNumber;
                  })
                  .map((x, key) => (
                    <li key={key}>{x.value}</li>
                  ))}
              </ul>
            ) : (
              <p>-</p>
            )}
          </div>
        </div>
      </CardForm>
      {console.log("committeeMinute?.applicant", committeeMinute?.applicant)}
      <CardForm label="Permohonan FDB">
        <div className="w-full bg-slate-100 p-5 rounded-md flex flex-col">
          <TableWrapper className="mt-4">
            <Table
              headers={headers}
              items={committeeMinute?.applicant?.data}
              isLoading={isLoading}
            />
            {!isEmpty(committeeMinute?.applicant?.meta) && (
              <Pagination
                page={committeeMinute?.applicant?.meta?.current_page ?? 1}
                limit={committeeMinute?.applicant?.meta?.per_page ?? 10}
                total={committeeMinute?.applicant?.meta?.total ?? 1}
                totalPage={committeeMinute?.applicant?.meta?.last_page ?? 1}
                onLimitChange={(e) =>
                  setFilters((prev) => ({
                    page: 1,
                    limit: e,
                  }))
                }
                onPageChange={(e) =>
                  setFilters((prev) => ({ ...prev, page: e }))
                }
              />
            )}
          </TableWrapper>
        </div>
      </CardForm>
    </div>
  );
};

export default DetailBeritaAcara;
