import { yupResolver } from "@hookform/resolvers/yup";
import {
  BottomFormAction,
  InputForm,
  InputFormRadio,
  InputFormUpload,
} from "components";
import Button from "components/atoms/Button/Button";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { validationSchemeTech } from "./validation-schema";
import { useNavigate, useParams } from "react-router";
import CardForm from "components/molecules/CardForm";
import AtomDatePicker from "components/atoms/Datepicker";
// import SelectAgenda from "pages/BeritaAcara/CreateForm/SelectAgenda";
// import SelectMinutes from "pages/BeritaAcara/CreateForm/SelectMinutes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fileUpload, getFile } from "services/fileService";
import AtomSelect from "components/atoms/Select";
import RichEditorForm from "components/molecules/RichEditorForm";
import { camelCase, filter, isEmpty, startCase } from "lodash";
import angkaTerbilangJs from "@develoka/angka-terbilang-js";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";
import { BiPlus, BiTrash } from "react-icons/bi";
import InputCurrencyForm from "components/v2/shared/input-currency-form";
import { getListMeetingAgenda } from "services/institutions/meeting-agenda";
import {
  getDetailAgencyCommitteeMinutesTech,
  postDetailAgencyCommitteeMinutesTech,
} from "services/institutions/ba-committee";
import { enqueueSnackbar } from "notistack";

const Information = ({ agencyInfo, isView }) => {
  const methods = useFormContext();

  const { data } = useQuery(
    ["GET_MEETING_AGENDA_LIST_TECH", { page: 1, limit: 1000, filter: {is_committee:true} }],
    getListMeetingAgenda,
    {
      select: (data) => data?.data?.data?.data,
    }
  );

  const [listMinutes, setListMinutes] = useState([]);

  const handleSetRisalah = useCallback(() => {
    if (!agencyInfo) return;
    const agendaMeeting = data?.find(
      (el) => el.id === agencyInfo?.commitee_meeting_agenda?.id
    );

    const listMinutes = agendaMeeting?.risalah?.map((el) => ({
      label: el.risalah_number,
      value: el,
    }));



    const selectedRisalah = agencyInfo?.commitee_meeting_agenda?.risalah[0]
    // const selectedRisalah = agendaMeeting?.risalah.find(
    //   (el) => el.risalah_number === agencyInfo?.risalah_number
    // );

    setListMinutes(listMinutes);

    methods.setValue("risalah_id", selectedRisalah?.id);

    methods.setValue("meeting_agenda", {
      value: agencyInfo?.commitee_meeting_agenda,
      label: `${agencyInfo?.commitee_meeting_agenda?.meeting_circular_number_minutes} ${agencyInfo?.commitee_meeting_agenda?.topic_discussion}`,
      // label: `${agendaMeeting?.meeting_circular_number_minutes} ${agendaMeeting?.topic_discussion}`,
    });
    methods.setValue("risalah", {
      label: selectedRisalah?.risalah_number,
      value: selectedRisalah,
    });
    methods.setValue("group_name", selectedRisalah?.agency_name);
  }, [agencyInfo, methods, data]);

  useEffect(() => handleSetRisalah, [handleSetRisalah]);

  return (
    <CardForm label="Informasi Lembaga">
      <div className="flex gap-5">
        <InputForm
          label="No Berita Acara Komite Teknis"
          required={true}
          controllerName={"committee_minutes_number"}
          className={"rounded-md w-full"}
          disabled={isView}
        />
        <AtomDatePicker
          controllerName={"committee_minutes_date"}
          label="Tanggal Terbit Berita Acara"
          placeholder="dd/mm/yyyy"
          datepickerOptions={{
            dateFormat: "dd/MM/yyyy",
          }}
          showErrorLabel
          disabled={isView}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 items-baseline">
        <div className="form-control w-full flex flex-col justify-end">
          <label className="label flex gap-1 font-semibold text-[14px] mb-2">
            <span className={`label-text`}>No Surat Edaran Rapat Risalah</span>
            <span className="text-[#F04438]">*</span>
          </label>
          <AtomSelect
            controllerName="meeting_agenda"
            options={
              data?.length > 0
                ? data?.map((el) => ({
                    value: el,
                    label: `${el.meeting_circular_number_minutes} (${el.topic_discussion})`,
                  }))
                : []
            }
            customOnChange={(e) => {
              const listMinutes = e?.value?.risalah.map((el) => ({
                label: el.risalah_number,
                value: el,
              }));

              setListMinutes(listMinutes);
            }}
            disabled={isView}
          />
          {/* <SelectAgenda name="meeting_circular_minutes_number" /> */}
        </div>
        <div className="form-control w-full flex flex-col justify-end">
          <label className="label flex gap-1 font-semibold text-[14px] mb-2">
            <span className={`label-text`}>No Risalah</span>
            <span className="text-[#F04438]">*</span>
          </label>
          {/* <SelectMinutes name="minutes_id" /> */}
          <AtomSelect
            controllerName="risalah"
            options={listMinutes}
            customOnChange={(e) => {
              methods.setValue("risalah_id", e.value.risalah_id);
              methods.setValue("group_name", e.value.agency_name);
            }}
            disabled={isView}
          />
        </div>
      </div>
      <div className="flex">
        <InputForm
          label="Nama Lembaga"
          required={true}
          controllerName={"group_name"}
          className={"rounded-md w-full"}
          disabled={true}
        />
      </div>
    </CardForm>
  );
};

const Provisions = ({ isView }) => {
  const { setValue, control, formState } = useFormContext();
  const { errors } = formState;

  const withGracePeriodField = useWatch({
    control,
    name: "with_grace_period",
  });
  const canBeExtended = useWatch({
    control,
    name: "can_be_extended",
  });
  const disbursementStage = useWatch({
    control,
    name: "distribution_stage",
  });

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "distribution",
  });

  return (
    <CardForm label="Ketentuan Pembiayaan Yang Disetujui Komite Teknis">
      <div className="space-y-5">
        {/* <AtomSelect
          label="Jenis Layanan FDB"
          wrapperClass={"flex flex-col justify-end"}
          controllerName={"feb_distributor_institution"}
          options={[]}
          className="basic-single w-full"
          placeholder="Jenis Layanan FDB"
          required={true}
          returnObject={false}
        /> */}
        <InputForm
          label="Jenis Layanan FDB"
          required
          controllerName="feb_distributor_institution"
          className="w-full"
          disabled={isView}
        />
        <AtomSelect
          label="Skema Penyaluran FDB"
          wrapperClass={"flex flex-col justify-end"}
          controllerName={"fdb_distribution_scheme"}
          options={[
            { value: "DIRECT", label: "Langsung" },
            { value: "INDIRECT", label: "Tidak Langsung" },
          ]}
          className="basic-single w-full"
          placeholder="Skema Penyaluran FDB"
          required={true}
          returnObject={false}
          disabled={isView}
        />
        <RichEditorForm
          className="quill-6"
          name={`fdb_objectives`}
          label="Tujuan Penggunaan"
          required={true}
          readOnly={isView}
        />
        <div className="flex gap-4">
          {/* <InputForm
            required={true}
            label="Jumlah FDB"
            controllerName="fdb_value"
            isNumber={true}
            prefix="Rp"
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
            className="w-full"
            onChangeInput={(event) => {
              setValue(
                "fdb_loan_spell",
                `${startCase(
                  camelCase(angkaTerbilangJs(Number(event.target.value)))
                )} Rupiah`
              );
            }}
            disabled={isView}
          /> */}
          <InputCurrencyForm
            name="fdb_value"
            label="Jumlah FDB"
            required
            prefix="Rp "
            onValueChange={(event) => {
              setValue(
                "fdb_loan_spell",
                `${startCase(
                  camelCase(angkaTerbilangJs(Number(event)))
                )} Rupiah`
              );
            }}
            disabled={isView}
          />
          <InputForm
            label="Terbilang"
            controllerName="fdb_loan_spell"
            disabled
            className="w-full"
          />
        </div>
        <div className="grid grid-cols-3 gap-4">
          <p className="font-semibold col-span-3">Tarif Layanan</p>
          <InputForm
            label={"Persentase Pertahun"}
            required={true}
            controllerName={"percentage_service_rate_per_year"}
            className="w-full rounded-md border p-3 "
            rightIcon="%"
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
            disabled={isView}
          />
          <AtomSelect
            label={"Metode Pemberlakuan"}
            wrapperClass={"flex flex-col justify-end"}
            controllerName={`service_rates_application_method`}
            options={[
              { value: "FIXED", label: "Fixed" },
              { value: "FLOATING", label: "Floating" },
            ]}
            className="basic-single w-full"
            required={true}
            placeholder="Metode Pemberlakuan"
            returnObject={false}
            disabled={isView}
          />
          <AtomSelect
            label={"Metode Perhitungan Tarif"}
            wrapperClass={"flex flex-col justify-end"}
            controllerName={`service_rate_calculation_method`}
            options={[
              { value: "EFFECTIVE", label: "Efektif" },
              { value: "FLAT", label: "Flat" },
            ]}
            className="basic-single w-full"
            required={true}
            placeholder="Metode Perhitungan Tarif"
            returnObject={false}
            disabled={isView}
          />
        </div>
        <div className="w-full grid grid-cols-2 gap-4">
          <p className="font-semibold col-span-3">Jangka Waktu</p>
          <InputForm
            label={"Jangka Waktu"}
            required={true}
            controllerName={"term_value"}
            className="w-full rounded-md border p-3 "
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
            disabled={isView}
          />
          <AtomSelect
            label={"Satuan Waktu"}
            wrapperClass={"flex flex-col justify-end"}
            controllerName={`term_type`}
            options={[
              { value: "Tahun", label: "Tahun" },
              { value: "Bulan", label: "Bulan" },
              { value: "Hari", label: "Hari" },
            ]}
            className="basic-single w-full"
            required={true}
            returnObject={false}
            disabled={isView}
          />
        </div>
        <InputFormRadio
          label={"Dengan Grace Period?"}
          controllerName={"with_grace_period"}
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Ya
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Tidak
                </>
              ),
              value: false,
            },
          ]}
          disabled={isView}
        />
        {withGracePeriodField === true && (
          <div className="flex gap-4">
            <InputForm
              label={"Jangka Waktu Grace Period"}
              required={true}
              controllerName={"grace_period_term_value"}
              className="w-full rounded-md border p-3 "
              onKeyPress={(event) => {
                const ASCIICode = event.which ? event.which : event.keyCode;
                if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                  return;
                event.preventDefault();
              }}
              disabled={isView}
            />
            <AtomSelect
              label={"Satuan Waktu Grace Period"}
              wrapperClass={"flex flex-col justify-end"}
              controllerName={`grace_period_term_type`}
              options={[
                { value: "Tahun", label: "Tahun" },
                { value: "Bulan", label: "Bulan" },
                { value: "Hari", label: "Hari" },
              ]}
              className="basic-single w-full"
              required={true}
              returnObject={false}
              disabled={isView}
            />
          </div>
        )}
        <InputFormRadio
          label={"Jangka Waktu Diperpanjang?"}
          controllerName={"can_be_extended"}
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Ya
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Tidak
                </>
              ),
              value: false,
            },
          ]}
          disabled={isView}
        />
        {canBeExtended === true && (
          <>
            {/* <div className="flex gap-4">
              <InputForm
                label={"Waktu Perpanjangan"}
                required={true}
                controllerName={"extend_value"}
                className="w-full rounded-md border p-3 "
                onKeyPress={(event) => {
                  const ASCIICode = event.which ? event.which : event.keyCode;
                  if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                    return;
                  event.preventDefault();
                }}
              />
              <AtomSelect
                label={"Satuan Waktu"}
                wrapperClass={"flex flex-col justify-end"}
                controllerName={`extend_type`}
                options={[
                  { value: "Tahun", label: "Tahun" },
                  { value: "Bulan", label: "Bulan" },
                  { value: "Hari", label: "Hari" },
                ]}
                className="basic-single w-full"
                required={true}
                returnObject={false}
              />
            </div> */}
            <InputFormRadio
              label={
                "Jangka Waktu Dapat Diperpanjang Sesuai Dengan Kesepakatan Dua Belah Pihak?"
              }
              controllerName={"agreed_by_both"}
              values={[
                {
                  label: (
                    <>
                      <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                      Ya
                    </>
                  ),
                  value: true,
                },
                {
                  label: (
                    <>
                      <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                      Tidak
                    </>
                  ),
                  value: false,
                },
              ]}
              disabled={isView}
            />
          </>
        )}

        <InputFormRadio
          label={"Tahapan Penyaluran"}
          controllerName={"distribution_stage"}
          values={[
            {
              label: "Sekaligus",
              value: "once",
            },
            {
              label: "Secara Bertahap",
              value: "phase",
            },
          ]}
          onChange={(e) => {
            setValue(`distribution.0.phase`, 1);
            setValue(`distribution.0.value`, undefined);
          }}
          disabled={isView}
        />
        {disbursementStage === "phase" && (
          <Fragment>
            {fields.map((field, idx) => (
              <div className="flex space-x-4 items-end" key={field.id}>
                {/* <InputForm
                  label={`Total Dana yang Disalurkan pada Tahap Ke-${idx + 1}`}
                  required={true}
                  controllerName={`distribution.${idx}.value`}
                  className="w-full rounded-md border p-3"
                  isNumber={true}
                  prefix="Rp"
                  onKeyPress={(event) => {
                    const ASCIICode = event.which ? event.which : event.keyCode;
                    if (
                      ASCIICode === 44 ||
                      (ASCIICode >= 48 && ASCIICode <= 57)
                    )
                      return;
                    event.preventDefault();
                  }}
                /> */}
                <InputCurrencyForm
                  label={`Total Dana yang Disalurkan pada Tahap Ke-${idx + 1}`}
                  required
                  name={`distribution.${idx}.value`}
                  prefix="Rp "
                  disabled={isView}
                />
                {idx > 0 && (
                  <Button
                    type="button"
                    onClick={() => remove(idx)}
                    label={<BiTrash className="text-white" />}
                    className={`!p-3 rounded-lg !bg-error-600 flex-none ${
                      !isEmpty(errors) &&
                      !isEmpty(
                        errors?.["disburstment_phase_items"]?.[idx]?.value
                      )
                        ? "!mb-[26px]"
                        : ""
                    }`}
                  />
                )}
              </div>
            ))}
            <hr />
            <div className="flex justify-end">
              <Button
                type="button"
                onClick={() => {
                  append({
                    phase: fields.length + 1,
                    value: undefined,
                  });
                  setValue("distribution_phase", fields.length + 1);
                }}
                className="p-3 rounded-lg flex-none"
                label={
                  <div className="flex space-x-2 items-center">
                    <BiPlus className="text-black" />
                    Tahap Penyaluran
                  </div>
                }
              />
            </div>
          </Fragment>
        )}
        <RichEditorForm
          className="quill-6"
          name={`collateral`}
          label="Jaminan"
          required={true}
          readOnly={isView}
        />
      </div>
    </CardForm>
  );
};

const getFileFromServiceCB = async (id) => {
  const response = await getFile(id);
  return response.data.data;
};

const CreateTechnicalCommittee = () => {
  const navigate = useNavigate();
  const params = useParams();

  const isView = !!params?.id;

  const methods = useForm({
    resolver: yupResolver(validationSchemeTech),
    defaultValues: {
      //   minutes_id: null,
      //   meeting_circular_minutes_number: null,
      distribution: [{ phase: 1, value: undefined }],
    },
  });

  const queryClient = useQueryClient();
  const { data } = useQuery(
    ["GET_DETAIL_BA_TECH", params.id],
    getDetailAgencyCommitteeMinutesTech,
    {
      enabled: !!params.id,
      select: (data) => data?.data?.data,
      onSuccess: (data) => {},
    }
  );
  const mutateCreateBATech = useMutation({
    mutationFn: postDetailAgencyCommitteeMinutesTech,
  });

  const revisionFileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      methods.register("tech_file");
      methods.setValue("tech_file", response.data.data.id);
    } catch (error) {
      throw error;
    }
  });

  const onDraft = () => {
    const values = methods.getValues();
    console.log("values: ", values);
  };

  const onBack = () => {
    navigate(-1);
  };

  const onFormSubmit = () => {
    const data = methods.getValues();

    if (data?.distribution_stage === "once") {
      methods.setValue(`distribution.0.phase`, 1);
      methods.setValue(`distribution.0.value`, data?.fdb_value);
      methods.setValue("distribution_phase", 1);
    }

    methods.handleSubmit((values) => {
      const mapPayload = {
        ...values,
        committee_minutes_date: new Date(values?.committee_minutes_date),
        distribution_stage: data?.distribution_stage,
        percentage_service_rate_per_year: Number(
          values.percentage_service_rate_per_year.replace(",", ".")
        ),
      };

      mutateCreateBATech.mutate(
        { data: mapPayload, params: { is_draft: false } },
        {
          onSuccess: () => {
            enqueueSnackbar({
              variant: "success",
              message: "Berhasil Buat Berita Acara Komite Teknis",
            });
            queryClient.invalidateQueries();
            navigate(-1);
          },
          onError: () => {
            enqueueSnackbar({
              variant: "error",
              message: "Gagal Buat Berita Acara Komite Teknis",
            });
          },
        }
      );
    })();
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      const promises = [Promise.resolve()];
      const { tech_file, agency_info, tech_committee_terms } = data || {
        tech_file: null,
      };

      const { committee_minutes_date, ...rest } = agency_info;

      if (tech_file && tech_file !== "string") {
        promises.push(
          getFileFromServiceCB(tech_file).then((data) => {
            return {
              formId: "tech_file_temp",
              type: data?.mimeType,
              ...data,
            };
          })
        );
      }

      if (promises?.length > 0) {
        Promise.all(promises).then((values) => {
          const obj = {
            ...rest,
            ...tech_committee_terms,
            committee_minutes_date: new Date(committee_minutes_date),
            fdb_loan_spell: tech_committee_terms.fdb_value
              ? startCase(
                  startCase(angkaTerbilangJs(tech_committee_terms.fdb_value))
                )
              : null,
          };

          values.map((x) => {
            if (!isEmpty(x)) {
              obj[x.formId] = x;
            }
          });

          methods.reset(obj);
        });
      }
    }
  }, [data, methods]);

  return (
    <div className="mb-40">
      <FormProvider {...methods}>
        <BreadCrumbs
          routes={[
            {
              label: "Berita Acara Lembaga Penyalur Komite Teknis",
              path: "/institutions/technical-committee",
            },
            {
              label: "Buat Berita Acara Komite Teknis",
              path: "/institutions/technical-committee/create",
            },
          ]}
        />
        <Information agencyInfo={data?.agency_info} isView={isView} />
        <CardForm label="Berita Acara Komite Teknis">
          <InputFormUpload
            controllerName={`tech_file_temp`}
            fileType="PDF, Docx"
            label={"Upload Berita Acara Komite Teknis"}
            uploadFile={revisionFileMutation}
            maxSize={10 * 1024 * 1024}
            disabled={isView}
          />
        </CardForm>
        <Provisions isView={isView} />
        <BottomFormAction
          backButtonAction={onBack}
          submitActionButton={onFormSubmit}
          disableDrafButton={false}
          drafButtonAction={onDraft}
          disableButtonSubmit={false}
          hideDraft={true}
          hideSubmit={isView}
          lastStep={true}
          className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
          submitButtonProps={{ type: "submit" }}
        />
      </FormProvider>
    </div>
  );
};

export default CreateTechnicalCommittee;
