import React, { useCallback, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import {
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiEyeLine,
} from "react-icons/ri";

import { Button, InputForm, InputFormRadio } from "components";
import CardForm from "components/molecules/CardForm";
import Table, { TableWrapper } from "components/atoms/Table";
import { getFile } from "services/fileService";
import dayjs from "dayjs";

const headers = [
  {
    key: "index",
    title: "No",
    render: ({ index }) => index + 1,
  },
  {
    key: "name",
    title: "Nama Dokumen",
  },
  {
    key: "created",
    title: "Tanggal Terbit",
    render: ({ item }) => {
      return item?.created === "-"
        ? "-"
        : dayjs(item?.created).format("DD MMMM YYYY");
    },
  },
  {
    key: "action",
    title: "Aksi",
    alignment: "center",
    className: "sticky right-0 bg-white",
    render: ({ item }) => {
      return (
        <>
          <Button
            theme="primary"
            variant="icon"
            className="flex items-center"
            label={
              <>
                <RiEyeLine className="text-lg mr-3" />
                Lihat Dokumen
              </>
            }
            onClick={() => {
              window.open(item.selected_file.url, "_blank").focus();
            }}
          />
        </>
      );
    },
  },
];

const LegalityDocument = ({ data, showVerification = true }) => {
  const methods = useFormContext();

  const getAllFile = useCallback(async () => {
    if (!data?.documents || data?.documents.length === 0) return;

    for (let document of data?.documents) {
      const response = await getFile(document.file);

      document.selected_file = response?.data?.data;
    }
  }, [data?.documents]);

  useEffect(() => {
    getAllFile();
  }, [getAllFile]);

  const isVerified = useWatch({
    name: "legality_document",
    control: methods.control,
  });

  return (
    <CardForm label="Dokumen Legalitas">
      <TableWrapper className="w-full">
        <Table headers={headers} items={data?.documents} isLoading={false} />
      </TableWrapper>
      {showVerification && (
        <div className="border rounded-md px-4 py-3">
          <InputFormRadio
            label={"Hasil Verifikasi"}
            controllerName={`legality_document`}
            className="flex items-center justify-between gap-4"
            values={[
              {
                label: (
                  <>
                    <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                    Data Terverifikasi
                  </>
                ),
                value: true,
              },
              {
                label: (
                  <>
                    <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                    Data Belum Sesuai/ Ada Kesalahan
                  </>
                ),
                value: false,
              },
            ]}
          />
          {typeof isVerified !== "undefined" && isVerified === false && (
            <InputForm
              controllerName="legality_document_desc"
              label="jelaskan kesalahan/ ketidak sesuaian datanya"
              placeholder="jelaskan kesalahan/ ketidak sesuaian datanya"
              required={true}
              wrapperClass="col-span-2 mt-3"
              className="rounded-md w-full px-4 py-3 border"
              textArea
            />
          )}
        </div>
      )}
    </CardForm>
  );
};

export default LegalityDocument;
