import { yupResolver } from "@hookform/resolvers/yup";
import { Button, InputFormRadio } from "components";
import Badge from "components/atoms/Badge";
import Table, { TableWrapper } from "components/atoms/Table";
import CardForm from "components/molecules/CardForm";
import { Pagination } from "components/v2/shared/pagination";
import React, { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RiEyeLine } from "react-icons/ri";
import { useLocation, useNavigate, useParams } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import * as yup from "yup";
import { updateBulkRequestMemberRisalah } from "services/fdb/committee";
import { enqueueSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";

const stateColors = {
  "Belum Direview": "warning",
  Baru: "bluelight",
  Draft: "default",
  Ditolak: "error",
  Disetujui: "success",
  approved: "success",
};

const reviewModeSchema = yup.object({
  request_id: yup.array().of(yup.string()),
});

const getStatusString = (expressed_status) => {
  return expressed_status === null
    ? "Belum Direview"
    : expressed_status === false
    ? "Ditolak"
    : expressed_status === true
    ? "Disetujui"
    : "-";
};

const DebiturScore = ({
  id,
  isLoading,
  data,
  filters,
  setFilters,
  risalah,
  allData,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const isPendapatKomite = location.pathname.includes("pendapat-komite");

  const methods = useForm({
    resetOptions: yupResolver(reviewModeSchema),
    defaultValues: { mode: "at_once" },
  });

  const headerDebtorScore = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ item, index }) =>
          isPendapatKomite ? (
            <div className="flex gap-2 items-center">
              {methods.watch("mode") === "at_once" && (
                <input
                  type="checkbox"
                  className="w-5 h-5 accent-primary-700"
                  onChange={(e) => {
                    if (e.target.checked) {
                      methods.setValue(`request_id.${index}`, item.request_id);
                    } else {
                      methods.setValue(`request_id.${index}`, null);
                    }
                  }}
                />
              )}
              {index + 1}
            </div>
          ) : (
            <>{index + 1}</>
          ),
      },
      {
        key: "name",
        title: "Nama Pemohon",
      },
      {
        key: "application_value",
        title: "Nilai Pinjaman",
        render: ({ item }) => (
          <p>RP. {Math.floor(item?.application_value).toLocaleString()}</p>
        ),
      },
      {
        key: "payment_period",
        title: "Jangka Waktu Pinjaman",
      },
      {
        key: "status",
        title: "Status",
        render: ({ item }) => (
          <Badge color={stateColors[getStatusString(item?.expressed_status)]}>
            {getStatusString(item?.expressed_status)}
          </Badge>
        ),
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <>
            <Button
              variant="icon"
              label={<RiEyeLine className="text-lg" />}
              onClick={() => {
                isPendapatKomite
                  ? navigate({
                      pathname: `debitor/${item.id}`,
                      search: `?group_type=${searchParams.get(
                        "group_type"
                      )}&review=risalah&mode=${methods.watch("mode")}`,
                    })
                  : navigate(
                      `/risalah/${id}/debitor/${
                        item.id
                      }?group_type=${searchParams.get("group_type")}`
                    );
              }}
            />
          </>
        ),
      },
    ];
  }, [id, navigate, isPendapatKomite, methods, searchParams]);

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ item, index }) =>
          isPendapatKomite ? (
            <div className="flex gap-2 items-center">
              {methods.watch("mode") === "at_once" && (
                <input
                  type="checkbox"
                  className="w-5 h-5 accent-primary-700"
                  onChange={(e) => {
                    if (e.target.checked) {
                      methods.setValue(`request_id.${index}`, item.request_id);
                    } else {
                      methods.setValue(`request_id.${index}`, null);
                    }
                  }}
                />
              )}
              {index + 1}
            </div>
          ) : (
            <>{index + 1}</>
          ),
      },
      {
        key: "name",
        title: "Nama Pemohon",
      },
      {
        key: "application_value",
        title: "Nilai Pinjaman",
        render: ({ item }) => (
          <p>RP. {Math.floor(item?.application_value).toLocaleString()}</p>
        ),
      },
      {
        key: "payment_period",
        title: "Jangka Waktu Pinjaman",
      },
      // {
      //   key: "status",
      //   title: "Status",
      //   render: ({ item }) => (
      //     <Badge color={stateColors[getStatusString(item?.expressed_status)]}>
      //       {getStatusString(item?.expressed_status)}
      //     </Badge>
      //   ),
      // },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <>
            <Button
              variant="icon"
              label={<RiEyeLine className="text-lg" />}
              onClick={() => {
                isPendapatKomite
                  ? navigate({
                      pathname: `debitor/${item.id}`,
                      search: `?group_type=${searchParams.get(
                        "group_type"
                      )}&review=risalah&mode=${methods.watch("mode")}`,
                    })
                  : navigate(
                      `/risalah/${id}/debitor/${
                        item.id
                      }?group_type=${searchParams.get("group_type")}`
                    );
              }}
            />
          </>
        ),
      },
    ];
  }, [id, navigate, isPendapatKomite, methods, searchParams]);

  const queryClient = useQueryClient();

  const mutateUpdateRisalahBulk = useMutation({
    mutationFn: (data) =>
      updateBulkRequestMemberRisalah(params.idRisalah, data),
  });

  const onSubmit = (data) => {
    const payload = {
      request_id: (data?.request_id ?? [])?.filter((id) => id !== null),
    };
    mutateUpdateRisalahBulk.mutate(payload, {
      onSuccess: (res) => {
        enqueueSnackbar({
          variant: "success",
          message: "Pendapat komite debitur berhasil disubmit",
        });
        queryClient.invalidateQueries();
      },
      onError: (err) => {
        enqueueSnackbar({
          variant: "success",
          message: "Pendapat komite debitur gagal disubmit",
        });
      },
    });
  };

  return (
    <CardForm
      label={isPendapatKomite ? "Permohonan Debitur" : "Penilaian Debitur"}
    >
      <FormProvider {...methods}>
        {isPendapatKomite && (
          <>
            <div className="bg-[#E1F1D6] px-6 py-[11px] text-lg font-semibold text-[#01A24A] rounded-md">
              <p>Silahkan Pilih Metode Review Debitur</p>
            </div>

            <InputFormRadio
              controllerName={`mode`}
              values={[
                {
                  label: <>Review Dalam Sekaligus</>,
                  value: "at_once",
                },
                {
                  label: <>Review Satuan</>,
                  value: "unit",
                },
              ]}
            />
          </>
        )}
        <TableWrapper className="mt-4">
          <Table
            headers={searchParams.get("review") ? headerDebtorScore : headers}
            items={data?.data}
            isLoading={isLoading}
          />
          <Pagination
            page={data?.meta?.meta?.current_page ?? 1}
            limit={filters?.filter?.limit_applicant}
            total={data?.meta?.meta?.total ?? 0}
            totalPage={data?.meta?.meta?.last_page ?? 1}
            onLimitChange={(e) =>
              setFilters((prev) => ({
                filter: { ...prev, limit_applicant: e },
              }))
            }
            onPageChange={(e) =>
              setFilters((prev) => ({ filter: { ...prev, page_applicant: e } }))
            }
          />
        </TableWrapper>

        {isPendapatKomite && methods.watch("mode") === "at_once" && (
          <div className="flex justify-center">
            {!allData?.all_expressed_status && searchParams.get("review") && (
              <Button
                label="Setuju yang dipilih"
                className="bg-primary-700 hover:bg-primary-600 text-white text-sm font-medium"
                onClick={methods.handleSubmit(onSubmit)}
              />
            )}
          </div>
        )}
      </FormProvider>
    </CardForm>
  );
};

export default DebiturScore;
