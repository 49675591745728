export const SAVE_DRAFT_DATA = "SAVE_DRAFT_DATA";
export const RESET_DRAFT_DATA = "RESET_DRAFT_DATA";
export const UPDATE_DRAFT_DATA = "UPDATE_DRAFT_DATA";
export const SAVE_ORGANIZATION_DRAFT_DATA = "SAVE_ORGANIZATION_DRAFT_DATA";
export const RESET_ORGANIZATION_DRAFT_DATA = "RESET_ORGANIZATION_DRAFT_DATA";
export const UPDATE_ORGANIZATION_DRAFT_DATA = "UPDATE_ORGANIZATION_DRAFT_DATA";
export const SAVE_RESOURCES_DRAFT_DATA = "SAVE_RESOURCES_DRAFT_DATA";
export const RESET_RESOURCES_DRAFT_DATA = "RESET_RESOURCES_DRAFT_DATA";
export const UPDATE_RESOURCES_DRAFT_DATA = "UPDATE_RESOURCES_DRAFT_DATA";
export const RESET_COMPLETED_STATUS = "RESET_COMPLETED_STATUS";
export const UPDATE_COMPLETED_STATUS = "UPDATE_COMPLETED_STATUS";
export const SAVE_FINANCIAL_DRAFT_DATA = "SAVE_FINANCIAL_DRAFT_DATA";
export const RESET_FINANCIAL_DRAFT_DATA = "RESET_FINANCIAL_DRAFT_DATA";
export const UPDATE_FINANCIAL_DRAFT_DATA = "UPDATE_FINANCIAL_DRAFT_DATA";
export const SAVE_REGION_DRAFT_DATA = "SAVE_REGION_DRAFT_DATA";
export const RESET_REGION_DRAFT_DATA = "RESET_REGION_DRAFT_DATA";
export const UPDATE_REGION_DRAFT_DATA = "UPDATE_REGION_DRAFT_DATA";

export const saveDraftData = (data) => ({
  type: SAVE_DRAFT_DATA,
  payload: data,
});

export const updateDraftData = (updatedData) => ({
  type: UPDATE_DRAFT_DATA,
  payload: updatedData,
});

export const resetDraftData = () => ({
  type: RESET_DRAFT_DATA,
});

export const saveOrganizationDraftData = (data) => ({
  type: SAVE_ORGANIZATION_DRAFT_DATA,
  payload: data,
});

export const updateOrganizationDraftData = (data) => ({
  type: UPDATE_ORGANIZATION_DRAFT_DATA,
  payload: data,
});

export const resetOrganizationDraftData = () => ({
  type: RESET_ORGANIZATION_DRAFT_DATA,
});

export const saveResourcesDraftData = (data) => ({
  type: SAVE_RESOURCES_DRAFT_DATA,
  payload: data,
});

export const updateResourcesDraftData = (updatedData) => ({
  type: UPDATE_RESOURCES_DRAFT_DATA,
  payload: updatedData,
});

export const resetResourcesDraftData = () => ({
  type: RESET_RESOURCES_DRAFT_DATA,
});

export const saveFinancialDraftData = (data) => ({
  type: SAVE_FINANCIAL_DRAFT_DATA,
  payload: data,
});

export const updateFinancialDraftData = (updatedData) => ({
  type: UPDATE_FINANCIAL_DRAFT_DATA,
  payload: updatedData,
});

export const resetFinancialDraftData = () => ({
  type: RESET_FINANCIAL_DRAFT_DATA,
});

export const saveRegionDraftData = (data) => ({
  type: SAVE_REGION_DRAFT_DATA,
  payload: data,
});

export const updateRegionDraftData = (updatedData) => ({
  type: UPDATE_REGION_DRAFT_DATA,
  payload: updatedData,
});

export const resetRegionDraftData = () => ({
  type: RESET_REGION_DRAFT_DATA,
});

export const updateCompletedStatus = (status, callback) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_COMPLETED_STATUS, payload: status });
    if (callback && typeof callback === "function") {
      callback();
    }
  };
};

export const resetCompletedStatus = () => ({
  type: RESET_ORGANIZATION_DRAFT_DATA,
});
