import { useQuery } from "@tanstack/react-query";
import { Button, InputForm } from "components";
import Badge from "components/atoms/Badge";
import AtomDatePicker from "components/atoms/Datepicker";
import Table, { TableWrapper } from "components/atoms/Table";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { Pagination } from "components/v2/shared/pagination";
import dayjs from "dayjs";
import { debounce } from "lodash";
import React, { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RiAddLine, RiEyeLine, RiSearchLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { getListAgencyCommitteeMinutes } from "services/institutions/ba-committee";

const stateColors = {
  1: {
    color: "default",
    text: "Draft",
  },
  2: {
    color: "warning",
    text: "Approval Komite Direksi",
  },
  3: {
    color: "success",
    text: "Permohonan Diterima",
  },
};

const TechnicalCommittee = ({ accessRight }) => {
  const title = "Berita Acara Lembaga Penyalur Komite Teknis";
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    paginate: true,
    page: 1,
    limit: 10,
    filter: {
      search: "",
      date: "",
      is_direction: false,
    },
  });

  const { data, isLoading } = useQuery({
    queryKey: ["technical-committee-minutes-list", { ...filters }],
    queryFn: getListAgencyCommitteeMinutes,
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => data?.data?.data,
  });

  const methods = useForm({
    // resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      search: "",
      committee_meeting_date: "",
    },
  });

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: "/institutions/technical-committee",
          },
        ]}
      />
      <div className="w-full flex justify-between">
        <h1 className="text-gray-900 text-2xl font-semibold leading-loose">
          {title}
        </h1>
        {accessRight?.canCreate && (
          <Button
            theme="primary"
            variant="icon"
            label={
              <div className="flex items-center gap-2">
                <RiAddLine className="text-lg" />
                Berita Acara Komite Teknis
              </div>
            }
            onClick={() => {
              navigate("create");
            }}
          />
        )}
      </div>

      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <ListFilter methods={methods} setFilters={setFilters} />
        <ListTable
          data={data}
          isLoading={isLoading}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
    </div>
  );
};

const ListFilter = ({ methods, setFilters }) => {
  return (
    <FormProvider {...methods}>
      <form className="grid grid-cols-1 md:grid-cols-2 gap-4 items-end">
        <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
          <RiSearchLine className="text-gray-400 text-xl" />
          <InputForm
            controllerName="search"
            placeholder="Cari"
            className={`!pl-0 w-full rounded-md border-none`}
            onChangeInput={debounce(
              (e) =>
                setFilters((prev) => ({
                  ...prev,
                  page: 1,
                  filter: {
                    ...prev.filter,
                    search: e.target.value,
                  },
                })),
              1000
            )}
          />
        </div>
        <AtomDatePicker
          controllerName={"committee_meeting_date"}
          label=""
          placeholder="Tanggal Rapat Komite"
          datepickerOptions={{
            dateFormat: "yyyy-MM-dd",
          }}
          onChangeDate={debounce(
            (date) =>
              setFilters((prev) => ({
                ...prev,
                page: 1,
                filter: {
                  ...prev.filter,
                  date: dayjs(date).format("YYYY-MM-DD"),
                },
              })),
            1000
          )}
          showErrorLabel
        />
      </form>
    </FormProvider>
  );
};

const ListTable = ({ data, isLoading, filters, setFilters }) => {
  const navigate = useNavigate();

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => {
          const no =
            parseInt(data?.meta?.current_page) *
              parseInt(data?.meta?.per_page) -
            parseInt(data?.meta?.per_page) +
            index +
            1;

          return no;
        },
      },
      {
        key: "committee_minutes_number",
        title: "Nomor Berita Acara",
      },
      {
        key: "agency_name",
        title: "Nama Lembaga",
        render: ({ item }) => item?.agency_name ?? "-",
      },
      {
        key: "implementation_date",
        title: "Tanggal Rapat Komite",
        render: ({ item }) => {
          return item?.implementation_date
            ? dayjs(item.implementation_date).format("DD MMMM YYYY")
            : "-";
        },
      },
      {
        key: "total_debtor",
        title: "Jumlah Debitur",
      },
      {
        key: "status",
        title: "Status",
        alignment: "center",
        render: ({ item }) => {
          return (
            <Badge color={stateColors[item?.status]?.color}>
              {stateColors[item?.status]?.text}
            </Badge>
          );
        },
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <>
            <Button
              variant="icon"
              label={<RiEyeLine className="text-lg" />}
              onClick={() => {
                navigate(`${item.id}`);
              }}
            />
          </>
        ),
      },
    ];
  }, [data?.meta, navigate]);

  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={data?.data} isLoading={isLoading} />
      <Pagination
        page={data?.meta?.current_page || 1}
        limit={filters.limit || 10}
        total={data?.meta?.total || 0}
        totalPage={data?.meta?.last_page || 1}
        onLimitChange={(e) => setFilters((prev) => ({ ...prev, limit: e }))}
        onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
      />
    </TableWrapper>
  );
};

export default TechnicalCommittee;
