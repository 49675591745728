import { Fragment } from "react";
import { useNavigate } from "react-router";
import { createSearchParams, useSearchParams } from "react-router-dom";

const { RiHome6Line, RiArrowRightSLine } = require("react-icons/ri");

const BreadCrumbs = ({ routes }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const redirect = (data) => {
    const path = {
      pathname: data.path,
      search: data?.search
        ? createSearchParams(data?.search).toString()
        : data?.useSearch
        ? searchParams.toString()
        : "",
    };
    navigate(path);
  };
  return (
    <div className="flex gap-2 items-center">
      <button onClick={() => navigate("/penilaian-lemtara")}>
        <RiHome6Line className="text-[#667085]" />
      </button>
      {routes.length > 0 &&
        routes.map((data, index) => {
          return (
            <Fragment key={index}>
              <RiArrowRightSLine className="text-[#D0D5DD]" />
              <button
                type="button"
                onClick={() => {
                  redirect(data);
                }}
                className={`
                                ${
                                  index === routes.length - 1
                                    ? "text-[#01A24A]"
                                    : "text-[#667085]"
                                }
                                ${
                                  index === routes.length - 1
                                    ? "bg-[#EEFFF4]"
                                    : ""
                                }
                                text-[16px]  px-2 py-1 rounded-md`}
              >
                {data.label}
              </button>
            </Fragment>
          );
        })}
    </div>
  );
};

export default BreadCrumbs;
