import { humanizeFileSize } from "helpers";
import withFileService from "hocs/with-file-service";
import { useEffect, useState } from "react";
import { RiFileLine } from "react-icons/ri";
import { useNavigate } from "react-router";

const UploadedFileAsync = withFileService(({ id, name, size, ...rest }) => {
  const navigate = useNavigate();
  const [fileObject, setFileObject] = useState();

  const { getFileFromService } = rest;

  useEffect(() => {
    if (id && id !== "string") {
      getFileFromService(id, {
        onSuccess: (data) => {
          setFileObject(data);
        },
        onError: (error) => {
          console.log(error);
        },
      });
    }
  }, [id]);

  return (
    <div className="rounded-lg px-3 py-2 flex items-start gap-4 border bg-white">
      <div className="w-8 h-8 rounded-full flex items-center justify-center text-primary-700 bg-primary-100 border-4 border-primary-50">
        <RiFileLine className="text-base" />
      </div>
      <div>
        <p className="font-medium text-sm mb-2">{name ? name : fileObject?.name}</p>
        <p className="font-medium text-sm">{fileObject?.size ? humanizeFileSize(fileObject?.size) : humanizeFileSize(size || 0)}</p>
        <a
          href={fileObject?.url}
          target="_blank"
          rel="noreferrer"
          className="hover:underline !px-0 text-primary-600 hover:text-primary-700 focus:ring-primary-600 focus:ring-offset-primary-600 py-3 h-[44px] text-sm font-semibold"
        >
          Lihat Dokumen
        </a>
      </div>
    </div>
  );
});

export default UploadedFileAsync;
