import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { signal } from "@preact/signals-react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Spinner, BottomFormAction } from "components";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";

import InstitutionProfile from "../../Components/InstitutionProfile";
import OrganisationStructure from "../../Components/OrganisationStructure";
import RichEditorForm from "components/molecules/RichEditorForm";
import FinanceDocument from "../../Components/FInanceDocument";
import LegalityDocument from "../../Components/LegalityDocument";
import {
  getDetailVerificator,
  updateVerificator,
} from "services/institutions/verification";
import { enqueueSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";

const allData = signal({});

const validationSchema = yup.object().shape({
  general_info: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Hasil Verifikasi"),
  general_info_desc: yup
    .string()
    .notRequired()
    .label("Kesalahan/Ketidak sesuaian data")
    .when("general_info", ([value], schema) => {
      if (value === false) {
        return schema.required();
      }
      return schema;
    }),

  organization: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Hasil Verifikasi"),
  organization_desc: yup
    .string()
    .notRequired()
    .label("Kesalahan/Ketidak sesuaian data")
    .when("organization", ([value], schema) => {
      if (value === false) {
        return schema.required();
      }
      return schema;
    }),

  legality_document: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Hasil Verifikasi"),
  legality_document_desc: yup
    .string()
    .notRequired()
    .label("Kesalahan/Ketidak sesuaian data")
    .when("legality_document", ([value], schema) => {
      if (value === false) {
        return schema.required();
      }
      return schema;
    }),

  financing_document: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Hasil Verifikasi"),
  financing_document_desc: yup
    .string()
    .notRequired()
    .label("Kesalahan/Ketidak sesuaian data")
    .when("financing_document", ([value], schema) => {
      if (value === false) {
        return schema.required();
      }
      return schema;
    }),
  notes: yup
    .string()
    .required()
    .label("Rekomendasi / Hasil Penilaian Pelaksana"),
});

const DetailVerificationLembaga = ({ accessRight }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const verificationStatus = searchParams.get("status");

  const mutateVerificator = useMutation({
    mutationFn: updateVerificator,
    onSuccess: (res) => {
      enqueueSnackbar({
        variant: "success",
        message: "Lembaga Berhasil Diverifikasi",
      });
      queryClient.invalidateQueries();
      navigate(-1);
    },
    onError: (err) => {
      enqueueSnackbar({
        variant: "error",
        message: "Lembaga Gagal Diverifikasi",
      });
    },
  });
  const { data, isLoading } = useQuery({
    queryKey: ["verification-institution-detail", id],
    queryFn: getDetailVerificator,

    // onSuccess: (data) => {
    //   allData.value = data?.data;
    // },
    select: (data) => data?.data?.data,
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const onBack = () => navigate("/institutions/verification");

  const onFormSubmit = (payload) => {
    mutateVerificator.mutate({ id, params: { is_draft: false }, data: payload });
  };

  const onDraft = () => {
    const payload = methods.getValues();

    mutateVerificator.mutate({
      id,
      params: { is_draft: true },
      data: payload,
    });
  };

  useEffect(() => {
    if (data) {
      methods.reset({
        general_info: data?.general_info?.verification,
        general_info_desc: data?.general_info?.verification_desc,
        organization: data?.organization_structure?.verification,
        organization_desc: data?.organization_structure?.verification_desc,
        legality_document: data?.legality_documents?.verification,
        legality_document_desc: data?.legality_documents?.verification_desc,
        financing_document: data?.financing_documents?.verification,
        financing_document_desc: data?.financing_documents?.verification_desc,
        notes: data?.notes,
      });
    }
  }, [data, methods]);

  return (
    <div className="space-y-6 mb-40">
      <BreadCrumbs
        routes={[
          {
            label: "Daftar Prospecting Lembaga Penyalur",
            path: "/institutions/verification",
          },
          {
            label: "Detail",
            path: `/institutions/verification/${id}`,
          },
        ]}
      />
      {isLoading ? (
        <div className="flex justify-center p-4">
          <Spinner theme={"primary"} />
        </div>
      ) : (
        <FormProvider {...methods}>
          <InstitutionProfile data={data?.general_info} />
          <OrganisationStructure data={data?.financing_documents} />
          <LegalityDocument data={data?.legality_documents} />
          <FinanceDocument data={data?.financing_documents} />
          <CardForm label="Rekomendasi / Hasil Penilaian Pelaksana">
            <RichEditorForm className="quill-6" name={`notes`} label="&nbsp;" />
          </CardForm>
          <BottomFormAction
            className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
            lastStep
            disableButtonSubmit={false}
            disableDrafButton={false}
            hideSubmit={Number(verificationStatus) > 1 && !accessRight?.canUpdate}
            hideDraft={Number(verificationStatus) > 1 && !accessRight?.canUpdate}
            submitButtonProps={{
              type: "submit",
            }}
            backButtonAction={onBack}
            submitActionButton={methods.handleSubmit(onFormSubmit)}
            drafButtonAction={onDraft}
          />
        </FormProvider>
      )}
    </div>
  );
};

export default DetailVerificationLembaga;
