import Table, { TableWrapper } from "components/atoms/Table";
import CardForm from "components/molecules/CardForm";
import { Pagination } from "components/v2/shared/pagination";
import React, { useMemo } from "react";

const Executors = ({ isLoading, data, filters, setFilters }) => {
  const headers = useMemo(() => {
    return [
      {
        key: "pic_name",
        title: "Desk Analyst",
      },
    ];
  }, []);

  const fieldHeaders = useMemo(() => {
    return [
      {
        key: "pic_name",
        title: "Field Analyst",
      },
    ];
  }, []);

  return (
    <CardForm label="Pelaksana">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <TableWrapper className="mt-4">
          <Table
            headers={headers}
            items={data?.desk_analyst?.data}
            isLoading={isLoading}
          />
          <Pagination
            page={data?.desk_analyst?.meta?.current_page ?? 1}
            limit={filters?.filter?.limit_desk}
            total={data?.desk_analyst?.meta?.total ?? 0}
            totalPage={data?.desk_analyst?.meta?.last_page ?? 1}
            onLimitChange={(e) =>
              setFilters((prev) => ({ filter: { ...prev, limit_desk: e } }))
            }
            onPageChange={(e) =>
              setFilters((prev) => ({ filter: { ...prev, page_desk: e } }))
            }
          />
        </TableWrapper>
        <TableWrapper className="mt-4">
          <Table
            headers={fieldHeaders}
            items={data?.field_analyst?.data}
            isLoading={isLoading}
          />
          <Pagination
            page={data?.field_analyst?.meta?.current_page ?? 1}
            limit={filters?.filter?.limit_desk}
            total={data?.field_analyst?.meta?.total ?? 0}
            totalPage={data?.field_analyst?.meta?.last_page ?? 1}
            onLimitChange={(e) =>
              setFilters((prev) => ({ filter: { ...prev, limit_field: e } }))
            }
            onPageChange={(e) =>
              setFilters((prev) => ({ filter: { ...prev, page_field: e } }))
            }
          />
        </TableWrapper>
      </div>
    </CardForm>
  );
};

export default Executors;
