/* eslint-disable eqeqeq */
import React, { Fragment, useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";

import Badge from "components/atoms/Badge";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import Button from "components/atoms/Button";

import GeneralInfo from "./GeneralInfo";
import FDBPotention from "./Potention";
import Request from "./Request";
import FinancingStructure from "./FinancingStructure";
import FinancingCondition from "./FinancingCondition";
import OfferAttachment from "./OfferAttachment";
import { BottomFormAction, Spinner } from "components";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getDetailNegotiation,
  submitNegotiation,
} from "services/institutions/offers";
import { useSearchParams } from "react-router-dom";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";

const InstitutionOffersDetail = () => {
  const { offerId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [filter, setFilter] = useState({
    filter: {
      page_potention: 1,
      limit_potention: 10,
      page_collateral: 1,
      limit_collateral: 10,
    },
  });

  const isNego = Number(searchParams?.get("status") || 0) === 3;

  const validationSchema = yup.object().shape({
    disbursement_purpose: yup
      .string()
      .label("Tujuan Pengunaan")
      .when([], ([value], schema) => {
        if (isNego) return schema.required();
        return schema.notRequired();
      }),
    collateral: yup
      .string()
      .label("Jaminan")
      .when([], ([value], schema) => {
        if (isNego) return schema.required();
        return schema.notRequired();
      }),
    agreement: yup
      .string()
      .label("Perjanjian")
      .when([], ([value], schema) => {
        if (isNego) return schema.required();
        return schema.notRequired();
      }),
    disbursement: yup
      .string()
      .label("Pencairan")
      .when([], ([value], schema) => {
        if (isNego) return schema.required();
        return schema.notRequired();
      }),
    installment: yup
      .string()
      .label("Pengembalian")
      .when([], ([value], schema) => {
        if (isNego) return schema.required();
        return schema.notRequired();
      }),
    mandatory: yup.array().of(
      yup
        .string()
        .label("Hak dan Tanggung Jawab Lembaga")
        .when([], ([value], schema) => {
          if (isNego) return schema.required();
          return schema.notRequired();
        })
    ),
    condition_verify: yup
      .bool()
      .transform((originalValue) =>
        originalValue === "" ? undefined : originalValue
      )
      .label("Opsi Ini")
      .when([], ([value], schema) => {
        if (isNego) return schema.required();
        return schema.notRequired();
      }),
    condition_verify_desc: yup
      .string()
      .when("condition_verify", ([value], schema) => {
        if (value === false && isNego) {
          return schema.required().label("Kesalahan/Ketidak sesuaian data");
        }
        return schema.notRequired();
      }),
    structure_verify: yup
      .bool()
      .transform((originalValue) =>
        originalValue === "" ? undefined : originalValue
      )
      .label("Opsi Ini")
      .when([], ([value], schema) => {
        if (isNego) return schema.required();
        return schema.notRequired();
      }),
    structure_verify_desc: yup
      .string()
      .when("structure_verify", ([value], schema) => {
        if (value === false && isNego) {
          return schema.required().label("Kesalahan/Ketidak sesuaian data");
        }
        return schema.notRequired();
      }),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { data, isLoading } = useQuery({
    queryKey: [
      "offers-detail-lembaga",
      offerId,
      {
        ...filter,
        filter: {
          ...filter.filter,
        },
      },
    ],
    queryFn: getDetailNegotiation,
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => data?.data?.data,
    onSuccess: (data) => {
      if (isNego) {
        const { condition, structure } = data || {
          structure: {},
          condition: {},
        };
        const { condition_verify, condition_verify_desc, offered } =
          condition || {
            condition_verify: null,
            condition_verify_desc: null,
            offered: {},
          };
        const { structure_verify, structure_verify_desc } = structure || {
          structure_verify: null,
          structure_verify_desc: null,
        };
        const obj = {
          condition_verify,
          condition_verify_desc,
          structure_verify,
          structure_verify_desc,
          ...offered,
          mandatory:
            offered?.mandatory?.length > 0
              ? offered?.mandatory?.map((x) => x.mandatory)
              : [],
        };
        setTimeout(() => methods.reset(obj), 0);
      }
    },
  });

  const submitOfferCB = useMutation({
    mutationKey: ["submit-offers-institution"],
    mutationFn: (payload) => {
      const { is_approve, ...body } = payload;
      const res = submitNegotiation(offerId, body, { is_approve });
      return res;
    },
    onSuccess: (res) => {
      enqueueSnackbar("Data berhasil disimpan", { variant: "success" });
      navigate("/institutions/offers");
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });

  const changePagePotention = useCallback((page) => {
    setFilter((curr) => ({
      ...curr,
      filter: {
        ...curr.filter,
        page_potention: page,
      },
    }));
  }, []);

  const changeLimitPotention = useCallback((limit) => {
    setFilter((curr) => ({
      ...curr,
      filter: {
        ...curr.filter,
        limit_potention: limit,
      },
    }));
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const rejectOffer = (payload) => {
    submitOfferCB.mutate({ ...payload, is_approve: false });
  };

  const submitOffer = (payload) => {
    submitOfferCB.mutate({ ...payload, is_approve: true });
  };

  return (
    <div className="space-y-5 mb-40 relative">
      <BreadCrumbs
        routes={[
          {
            label: "Daftar Penawaran - Lembaga Penyalur",
            path: "/institutions/offers",
          },
          {
            label: "Detail Penawaran",
            path: `/institutions/offers/${offerId}`,
          },
        ]}
      />
      {isLoading ? (
        <div className="w-full flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <FormProvider {...methods}>
          <CardForm label="Informasi Umum">
            <div className="space-y-5">
              <GeneralInfo data={data?.general} />
              <Request data={data?.application?.request} />
              <FDBPotention
                data={data?.application}
                changePagePotention={changePagePotention}
                changeLimitPotention={changeLimitPotention}
                filterTable={filter?.filter}
              />
              <FinancingStructure data={data?.structure} />
              <FinancingCondition data={data?.condition} />
              <OfferAttachment data={data?.offer} />
            </div>
            {console.log(searchParams.get("direction_submit"))}
          </CardForm>
          {Number(searchParams?.get("status") || 0) > 1 &&
            Number(searchParams?.get("status") || 0) < 6 &&
            Number(searchParams?.get("status") || 0) !== 2 && (
              <BottomFormAction
                backButtonAction={handleBack}
                submitActionButton={() => methods.handleSubmit(submitOffer)()}
                disableDrafButton={false}
                submitButtonProps={{
                  type: "submit",
                  label: "Terima",
                }}
                draftButtonProps={{
                  label: "Tolak",
                  className: "bg-red-500 text-white",
                  type: "submit",
                }}
                drafButtonAction={() => rejectOffer(methods.getValues())}
                disableButtonSubmit={false}
                hideSubmit={searchParams.get("direction_submit") === "true"}
                hideDraft={searchParams.get("direction_submit") === "true"}
                className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
              />
            )}
        </FormProvider>
      )}
    </div>
  );
};

export default InstitutionOffersDetail;
