import Risalah from "pages/Risalah";
import RisalahDetail from "pages/Risalah/Detail";
import RisalahDebtorDetail from "pages/Risalah/Detail/Debtor";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    routeType: "private",
    path: "/risalah",
    element: Risalah,
    exact: true,
    sidebar: true,
    key: "risalah",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/risalah/:id",
    element: RisalahDetail,
    exact: true,
    sidebar: true,
    key: "risalah",
    type: "canUpdate"
  },
  {
    routeType: "public",
    path: "/risalah/:id/debitor/:id",
    element: RisalahDebtorDetail,
    exact: true,
    sidebar: true,
    key: "risalah",
    type: "canUpdate"
  },
];
