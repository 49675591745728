import {
  SAVE_RESOURCES_DRAFT_DATA,
  RESET_RESOURCES_DRAFT_DATA,
  UPDATE_RESOURCES_DRAFT_DATA,
} from "../actions";

const initialState = {
  data: { human_resources: {}, equipment_resources: {} },
};

export const resourcesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_RESOURCES_DRAFT_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case UPDATE_RESOURCES_DRAFT_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    case RESET_RESOURCES_DRAFT_DATA:
      return {
        ...state,
        data: { human_resources: {}, equipment_resources: {} },
      };
    default:
      return state;
  }
};
