// verifikator
import VerificationInstitution from "pages/FDB/Institution/Verification";
import DetailVerificationInstitution from "pages/FDB/Institution/Verification/Detail";

// assign desk analyst
import AssignDeskAnalystInstitution from "pages/FDB/Institution/Assign/DeskAnalyst";
import AssignDeskAnalystInstitutionDetail from "pages/FDB/Institution/Assign/DeskAnalyst/detail";

// assign field analyst
import AssignFieldAnalysttInstitution from "pages/FDB/Institution/Assign/FieldAnalyst";
import AssignFieldAnalysttInstitutionDetail from "pages/FDB/Institution/Assign/FieldAnalyst/detail";

// desk analyst
import DeskAnalystInstitution from "pages/FDB/Institution/DeskAnalyst";
import DeskAnalystInstitutionDetail from "pages/FDB/Institution/DeskAnalyst/Detail";

// field analyst
import FieldAnalystInstitution from "pages/FDB/Institution/FieldAnalyst";
import FieldAnalystInstitutionDetail from "pages/FDB/Institution/FieldAnalyst/Detail";

// risalah
import MinutesInstitution from "pages/FDB/Institution/Minutes";
import MinutesInstitutionDetail from "pages/FDB/Institution/Minutes/Detail";

// meeting agenda
import MeetingAgenda from "pages/FDB/Committe/MeetingAgenda";
import MeetingAgendaForm from "pages/FDB/Committe/MeetingAgenda/Form";
import MeetingAgendaDetail from "pages/FDB/Committe/MeetingAgenda/Detail";

// pendapat komite
import PendapatKomite from "pages/FDB/Committe/PendapatKomite";
import PendapatKomiteDetail from "pages/FDB/Committe/PendapatKomite/Detail";
import RisalahDetail from "pages/Risalah/Detail";
import RisalahDebtorDetail from "pages/Risalah/Detail/Debtor";

// komite teknis
import TechnicalCommittee from "pages/FDB/Institution/TechnicalCommittee";
import CreateTechnicalCommittee from "pages/FDB/Institution/TechnicalCommittee/create";

// komite direksi
import DirectorCommittee from "pages/FDB/Institution/DirectorCommittee";
import CreateDirectorCommittee from "pages/FDB/Institution/DirectorCommittee/create";

// Assign Offer
import AssignOfferInstitution from "pages/FDB/Institution/Assign/Offer";
import AssignOfferDetail from "pages/FDB/Institution/Assign/Offer/detail";

// Assign Disbursment
import AssignDisburmentInstitution from "pages/FDB/Institution/Assign/Disbursment";
import AssignDisbursmentDetail from "pages/FDB/Institution/Assign/Disbursment/detail";

// Disbursment
import InstitutionsDisbursment from "pages/FDB/Institution/Disbursment";
import DetailDisbursment from "pages/FDB/Institution/Disbursment/Detail";

// Offers
import InstitutionOffers from "pages/FDB/Institution/Offers";
import InstitutionOffersDetail from "pages/FDB/Institution/Offers/Detail";
import InstitutionCreateOffers from "pages/FDB/Institution/Offers/Create";
import ApprovalMinutes from "pages/FDB/Institution/ApprovalMinutes";
import ApprovalMinutesDetail from "pages/FDB/Institution/ApprovalMinutes/Detail";
import AgreementInstitution from "pages/FDB/Institution/Agreement";
import AgreementInstitutionDetail from "pages/FDB/Institution/Agreement/Detail";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  // prospecting
  {
    routeType: "private",
    path: "/institutions/verification",
    element: VerificationInstitution,
    exact: true,
    sidebar: true,
    key: "institutions_verification",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions/verification/:id",
    element: DetailVerificationInstitution,
    exact: true,
    sidebar: true,
    key: "institutions_verification",
    type: "canUpdate"
  },

  // assign desk analyst
  {
    routeType: "private",
    path: "/institutions/assign-desk-analyst",
    element: AssignDeskAnalystInstitution,
    exact: true,
    sidebar: true,
    key: "institutions_assign_desk_analyst",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions/assign-desk-analyst/:id",
    element: AssignDeskAnalystInstitutionDetail,
    exact: true,
    sidebar: true,
    key: "institutions_assign_desk_analyst",
    type: "canUpdate"
  },
  {
    routeType: "private",
    path: "/institutions/assign-desk-analyst/view/:id",
    element: AssignDeskAnalystInstitutionDetail,
    exact: true,
    sidebar: true,
    key: "institutions_assign_desk_analyst",
    type: "canUpdate"
  },

  // assign field analyst
  {
    routeType: "private",
    path: "/institutions/assign-field-analyst",
    element: AssignFieldAnalysttInstitution,
    exact: true,
    sidebar: true,
    key: "institutions_assign_field_analyst",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions/assign-field-analyst/:id",
    element: AssignFieldAnalysttInstitutionDetail,
    exact: true,
    sidebar: true,
    key: "institutions_assign_field_analyst",
    type: "canUpdate"
  },
  {
    routeType: "private",
    path: "/institutions/assign-field-analyst/view/:id",
    element: AssignFieldAnalysttInstitutionDetail,
    exact: true,
    sidebar: true,
    key: "institutions_assign_field_analyst",
    type: "canUpdate"
  },

  // assign Offer
  {
    routeType: "private",
    path: "/institutions/assign-offer",
    element: AssignOfferInstitution,
    exact: true,
    sidebar: true,
    key: "institutions_assign_offer",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions/assign-offer/:id",
    element: AssignOfferDetail,
    exact: true,
    sidebar: true,
    key: "institutions_assign_offer",
    type: "canUpdate"
  },
  {
    routeType: "private",
    path: "/institutions/assign-offer/view/:id",
    element: AssignOfferDetail,
    exact: true,
    sidebar: true,
    key: "institutions_assign_offer",
    type: "canUpdate"
  },

  //  assign disbursment
  {
    routeType: "private",
    path: "/institutions/assign-disbursment",
    element: AssignDisburmentInstitution,
    exact: true,
    sidebar: true,
    key: "institutions_assign_disbursment",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions/assign-disbursment/:id",
    element: AssignDisbursmentDetail,
    exact: true,
    sidebar: true,
    key: "institutions_assign_disbursment",
    type: "canUpdate"
  },

  // desk analyst
  {
    routeType: "private",
    path: "/institutions/desk-analyst",
    element: DeskAnalystInstitution,
    exact: true,
    sidebar: true,
    key: "institutions_desk_analyst",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions/desk-analyst/:id",
    element: DeskAnalystInstitutionDetail,
    exact: true,
    sidebar: true,
    key: "institutions_desk_analyst",
    type: "canUpdate"
  },

  // field analyst
  {
    routeType: "private",
    path: "/institutions/field-analyst",
    element: FieldAnalystInstitution,
    exact: true,
    sidebar: true,
    key: "institutions_field_analyst",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions/field-analyst/:id",
    element: FieldAnalystInstitutionDetail,
    exact: true,
    sidebar: true,
    key: "institutions_field_analyst",
    type: "canUpdate"
  },

  // risalah
  {
    routeType: "private",
    path: "/institutions/minutes",
    element: MinutesInstitution,
    exact: true,
    sidebar: true,
    key: "institutions_minutes",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions/minutes/:id",
    element: MinutesInstitutionDetail,
    exact: true,
    sidebar: true,
    key: "institutions_minutes",
    type: "canUpdate"
  },

  // Disbursment
  {
    routeType: "private",
    path: "/institutions-disbursement/:disbursementType",
    element: InstitutionsDisbursment,
    exact: true,
    sidebar: true,
    key: "institutions_disbursement",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions-disbursement/:disbursementType/:id",
    element: DetailDisbursment,
    exact: true,
    sidebar: true,
    key: "institutions_disbursement",
    type: "canUpdate"
  },

  // meeting agenda
  {
    routeType: "private",
    path: "/institutions/meeting-agenda",
    element: (props) => <MeetingAgenda {...props} fdbType="lembaga" />,
    exact: true,
    sidebar: true,
    key: "institutions_meeting_agenda",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions/meeting-agenda/form",
    element: (props) => <MeetingAgendaForm {...props} fdbType="lembaga" />,
    exact: true,
    sidebar: true,
    key: "institutions_meeting_agenda",
    type: "canCreate"
  },
  {
    routeType: "private",
    path: "/institutions/meeting-agenda/:id",
    element: (props) => <MeetingAgendaDetail {...props} fdbType="lembaga" />,
    exact: true,
    sidebar: true,
    key: "institutions_meeting_agenda",
    type: "canUpdate"
  },

  // pendapat komite
  {
    routeType: "private",
    path: "/institutions/pendapat-komite",
    element: (props) => <PendapatKomite {...props} fdbType="lembaga" />,
    exact: true,
    sidebar: true,
    key: "institutions_pendapat_komite",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions/pendapat-komite/:id",
    element: (props) => <PendapatKomiteDetail {...props} fdbType="lembaga" />,
    exact: true,
    sidebar: true,
    key: "institutions_pendapat_komite",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions/pendapat-komite/:id/risalah/:idRisalah",
    element: (props) => <RisalahDetail {...props} fdbType="lembaga" />,
    exact: true,
    sidebar: true,
    key: "institutions_pendapat_komite",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions/pendapat-komite/:id/risalah/:idRisalah/debitor/:idDebtor",
    element: (props) => <RisalahDebtorDetail {...props} fdbType="lembaga" />,
    exact: true,
    sidebar: true,
    key: "institutions_pendapat_komite",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions/pendapat-komite/risalah/:idRisalah",
    element: (props) => <RisalahDetail {...props} fdbType="lembaga" />,
    exact: true,
    sidebar: true,
    key: "institutions_pendapat_komite",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions/pendapat-komite/risalah/:idRisalah/debitor/:idDebtor",
    element: (props) => <RisalahDebtorDetail {...props} fdbType="lembaga" />,
    exact: true,
    sidebar: true,
    key: "institutions_pendapat_komite",
    type: "canView"
  },

  // technical committee
  {
    routeType: "private",
    path: "/institutions/technical-committee",
    element: TechnicalCommittee,
    exact: true,
    sidebar: true,
    key: "institutions_technical_committee",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions/technical-committee/create",
    element: CreateTechnicalCommittee,
    exact: true,
    sidebar: true,
    key: "institutions_technical_committee",
    type: "canCreate"
  },
  {
    routeType: "private",
    path: "/institutions/technical-committee/:id",
    element: CreateTechnicalCommittee,
    exact: true,
    sidebar: true,
    key: "institutions_technical_committee",
    type: "canUpdate"
  },

  // director committee
  {
    routeType: "private",
    path: "/institutions/director-committee",
    element: DirectorCommittee,
    exact: true,
    sidebar: true,
    key: "institutions_director_committee",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions/director-committee/create",
    element: CreateDirectorCommittee,
    exact: true,
    sidebar: true,
    key: "institutions_director_committee",
    type: "canCreate"
  },
  {
    routeType: "private",
    path: "/institutions/director-committee/:id",
    element: CreateDirectorCommittee,
    exact: true,
    sidebar: true,
    key: "institutions_director_committee",
    type: "canUpdate"
  },

  // offers
  {
    routeType: "private",
    path: "/institutions/offers",
    element: InstitutionOffers,
    exact: true,
    sidebar: true,
    key: "institutions_offers",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions/offers/:offerId",
    element: InstitutionOffersDetail,
    exact: true,
    sidebar: true,
    key: "institutions_offers",
    type: "canUpdate"
  },
  {
    routeType: "private",
    path: "/institutions/offers/create",
    element: InstitutionCreateOffers,
    exact: true,
    sidebar: true,
    key: "institutions_offers",
    type: "canCreate"
  },

  // approval
  {
    routeType: "private",
    path: "/institutions/approval",
    element: ApprovalMinutes,
    exact: true,
    sidebar: true,
    key: "institutions_approval",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions/approval/:id",
    element: ApprovalMinutesDetail,
    exact: true,
    sidebar: true,
    key: "institutions_approval",
    type: "canUpdate"
  },

  // AGREEMENT
  {
    routeType: "private",
    path: "/institutions/agreement",
    element: AgreementInstitution,
    exact: true,
    sidebar: true,
    key: "institutions_aggreement",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/institutions/agreement/:id",
    element: AgreementInstitutionDetail,
    exact: true,
    sidebar: true,
    key: "institutions_aggreement",
    type: "canUpdate"
  },
];
