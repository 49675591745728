import authSlice from "redux/authSlice";
import profileSlice from "../features/auth/profileSlice";
import {
  profileUmumReducer,
  organizationProfileReducer,
  resourcesReducer,
  completedStatusReducer,
  financialProfileReducer,
  regionReducer,
} from "./reducers";
import oidcSlice from "redux/oidcSlice";
import fieldAnalystSlice from "redux/fieldAnalystSlice";

const rootReducer = {
  auth: authSlice,
  oidc: oidcSlice,
  fieldAnalyst: fieldAnalystSlice,
  profile: profileSlice,
  draftDataGeneralProfile: profileUmumReducer,
  organizationProfileDraftData: organizationProfileReducer,
  resourcesDraftData: resourcesReducer,
  financialDraftData: financialProfileReducer,
  completedStatus: completedStatusReducer,
  regionDraftData: regionReducer,
};

export default rootReducer;
