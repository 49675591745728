import React from "react";
import { RiLoaderLine } from "react-icons/ri";

const Spinner = ({ theme }) => {
  const themeHandler = (val) => {
    switch (val) {
      case "primary":
        return `border-primary-600`;
      case "secondary":
        return `border-secondary-600`;
      case "accent":
        return `border-accent-600`;
      case "info":
        return `border-info-600`;
      case "success":
        return `border-success-600`;
      case "warning":
        return `border-warning-600`;
      case "error":
        return `border-error-600`;
      case "active":
        return `border-active`;
      case "ghost":
        return `border-ghost`;
      case "link":
        return `border-link`;
      default:
        return "border-white";
    }
  };
  return (
    // <div className="rounded-full animate-spin w-5 h-5 overflow-hidden">
    //   <div className="w-full h-full border-2" />
    // </div>
    <RiLoaderLine className="text-green-500 animate-spin text-4xl" />
  );
};

export default Spinner;
