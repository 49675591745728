import { useMutation } from "@tanstack/react-query";
import { InputForm, InputFormRadio, InputFormUpload } from "components";
import GeotagLocationButton from "components/atoms/Button/GeotagLocationButton";
import CardForm from "components/molecules/CardForm";
import SelectFDBCity from "components/organisms/inputs/SelectFDBCity";
import SelectFDBDistrict from "components/organisms/inputs/SelectFDBDistrict";
import SelectFDBProvince from "components/organisms/inputs/SelectFDBProvince";
import SelectFDBVillage from "components/organisms/inputs/SelectFDBVillage";
import { formatCurrency } from "helpers";
import React, { useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import {
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiMapPin2Fill,
} from "react-icons/ri";
import { fileUpload, getFile } from "services/fileService";

const getFileFromServiceCB = async (id) => {
  const response = await getFile(id);
  return response.data.data;
};

const Bussiness = ({ data }) => {
  const methods = useFormContext();

  const { business_infos } = data?.request?.field_analysts || {
    business_infos: [],
  };

  const temp_business_infos = useMemo(() => {
    return business_infos?.length > 0
      ? business_infos?.find((x) => x.type === "info")
      : {};
  }, [business_infos]);

  useEffect(() => {
    const promises = [];
    if (
      temp_business_infos?.debtor_house_file &&
      temp_business_infos?.debtor_house_file !== "string"
    ) {
      promises.push(
        getFileFromServiceCB(temp_business_infos?.debtor_house_file).then(
          (data) => {
            return {
              formId: "business_infos[0].debtor_house_file_temp",
              type: data?.mimeType,
              ...data,
            };
          }
        )
      );
    }
    if (promises?.length > 0) {
      Promise.all(promises).then((values) => {
        values.map((x) => {
          methods.resetField(x.formId, { defaultValue: x });
        });
      });
    }
  }, [temp_business_infos]);

  const provinceId = useWatch({
    control: methods.control,
    name: "business_infos[0].financed_province_id",
  });
  const cityId = useWatch({
    control: methods.control,
    name: "business_infos[0].financed_city_id",
  });
  const districtId = useWatch({
    control: methods.control,
    name: "business_infos[0].financed_district_id",
  });

  const business_info_verification = useWatch({
    control: methods.control,
    name: "business_info_verification",
  });

  const fileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      if (file?.controllerName === "business_infos[0].debtor_house_file_temp") {
        methods.register("business_infos[0].debtor_house_file");
        methods.setValue(
          "business_infos[0].debtor_house_file",
          response.data.data.id
        );
      }
    } catch (error) {
      throw error;
    }
  });

  const { group_profile_member } = data || {
    deferred: {},
    group_profile_member: {},
  };

  return (
    <CardForm label="Profil Usaha Yang Dibiayai">
      <div className="space-y-5">
        <div className="flex gap-4 w-full">
          <div className="w-full">
            <div className="font-semibold">Jenis Usaha</div>
            <div>{group_profile_member?.business_type?.name ?? "-"}</div>
          </div>
          <div className="w-full">
            <div className="font-semibold">Komoditas Usaha PS</div>
            <div>{group_profile_member?.business_commodity ?? "-"}</div>
          </div>
          <div className="w-full">
            <div className="font-semibold">Lama Usaha</div>
            <div>{`${group_profile_member?.business_since ?? "-"}`}</div>
          </div>
        </div>
        <div className="flex gap-4 w-full">
          <div className="w-full">
            <div className="font-semibold">Sumber Bahan Produksi/Jasa </div>
            <div>{group_profile_member?.business_material ?? "-"}</div>
          </div>
          <div className="w-full">
            <div className="font-semibold">Kapasitas Usaha/Produksi </div>
            <div>
              {group_profile_member?.business_capacity
                ? group_profile_member?.business_capacity
                : "-"}
            </div>
          </div>
          <div className="w-full">
            <div className="font-semibold">
              Nilai Ekonomi Usaha (Rp Produksi/Jasa)
            </div>
            <div>
              {group_profile_member?.business_economic_values
                ? formatCurrency(group_profile_member?.business_economic_values)
                : "-"}
            </div>
          </div>
        </div>
        <div className="flex gap-4 w-full">
          <div className="w-full">
            <div className="font-semibold">Tujuan Pemasaran </div>
            <div>{group_profile_member?.business_target ?? "-"}</div>
          </div>
          <div className="w-full">
            <div className="font-semibold">Mitra Usaha (Jika Ada)</div>
            <div>
              {group_profile_member?.business_partner ? (
                JSON.parse(group_profile_member.business_partner)?.length >
                0 ? (
                  <ul className="list-disc py-2 space-y-2 ">
                    {JSON.parse(group_profile_member.business_partner)?.map(
                      (x, key) => (
                        <li key={key}>{x}</li>
                      )
                    )}
                  </ul>
                ) : (
                  "-"
                )
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className="w-full">
            <div className="font-semibold">Pendapatan Bersih Per Bulan</div>
            <div>
              {group_profile_member?.business_net_income
                ? formatCurrency(group_profile_member?.business_net_income)
                : "-"}
            </div>
          </div>
        </div>
        <p className="font-semibold text-lg text-primary-maincolor-700">
          Informasi Usaha
        </p>
        <InputForm
          controllerName={"business_infos[0].net_income_one_siclus"}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          label={"Kisaran Pendapatan Nett Dalam Satu Siklus Usaha"}
          placeholder={"Kisaran Pendapatan Nett Dalam Satu Siklus Usaha"}
          isNumber={true}
          prefix="Rp"
          onKeyPress={(event) => {
            const ASCIICode = event.which ? event.which : event.keyCode;
            if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
              return;
            event.preventDefault();
          }}
        />
        <div className="grid grid-cols-2 gap-4">
          <SelectFDBProvince
            controllerName={"business_infos[0].financed_province_id"}
            className="basic-single mt-2 flex-1"
            label="Provinsi"
            required={true}
            returnObject={false}
          />
          <SelectFDBCity
            controllerName={"business_infos[0].financed_city_id"}
            className="basic-single mt-2 flex-1"
            label="Kota/Kabupaten"
            required={true}
            provinceId={provinceId}
            returnObject={false}
          />
          <SelectFDBDistrict
            controllerName={"business_infos[0].financed_district_id"}
            className="basic-single mt-2 flex-1"
            label="Kecamatan"
            required={true}
            cityId={cityId}
            returnObject={false}
          />
          <SelectFDBVillage
            controllerName={"business_infos[0].financed_village_id"}
            className="basic-single mt-2 flex-1"
            label="Kelurahan/Desa"
            required={true}
            districtId={districtId}
            returnObject={false}
          />
        </div>
        <InputForm
          controllerName="business_infos[0].financed_address"
          label="Alamat Lengkap Usaha"
          placeholder="Alamat Lengkap Usaha"
          required={true}
          wrapperClass="col-span-2"
          className="rounded-md w-full px-4 py-3 border"
          textArea
        />
        <div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <span className="font-medium">Geo Location</span>
              <GeotagLocationButton
                label="Geotag Usaha"
                icon={<RiMapPin2Fill className={"w-5 h-5 shrink-0"} />}
                className={"!bg-primary-700 !text-white w-fit"}
                onSuccess={(val) => {
                  methods.setValue(
                    "business_infos[0].financed_longitude",
                    val.longitude
                  );
                  methods.setValue(
                    "business_infos[0].financed_latitude",
                    val.latitude
                  );
                }}
              />
            </div>
            <div className="mb-4">
              <InputFormUpload
                uploadFile={fileMutation}
                controllerName={`business_infos[0].debtor_house_file_temp`}
                label={"Unggah Foto Usaha Debitur"}
              />
            </div>
            <InputForm
              controllerName={"business_infos[0].financed_longitude"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Longitude"}
              placeholder={"Longitude"}
            />
            <InputForm
              controllerName={"business_infos[0].financed_latitude"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Latitude"}
              placeholder={"Latitude"}
            />
          </div>
        </div>
        <div className="border rounded-md px-4 py-3">
          <InputFormRadio
            label={"Hasil Verifikasi"}
            controllerName={`business_info_verification`}
            className="flex items-center justify-between gap-4"
            values={[
              {
                label: (
                  <>
                    <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                    Kesimpulan Verivikasi
                  </>
                ),
                value: true,
              },
              {
                label: (
                  <>
                    <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                    Data Belum Sesuai/ Ada Kesalahan
                  </>
                ),
                value: false,
              },
            ]}
          />
          {business_info_verification !== "" &&
            business_info_verification === false && (
              <InputForm
                controllerName={"business_info_verification_desk"}
                textArea={true}
                label={"Jelaskan kesalahan/ ketidak sesuaian datanya"}
                className={"w-full rounded-md border p-3"}
                wrapperClass={"mt-5"}
              />
            )}
        </div>
      </div>
    </CardForm>
  );
};

export default Bussiness;
