import { useQuery } from "@tanstack/react-query";
import { Button, InputForm } from "components";
import Badge from "components/atoms/Badge";
import AtomDatePicker from "components/atoms/Datepicker";
import Table, { TableWrapper } from "components/atoms/Table";
import TablePagination from "components/atoms/TablePagination";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import dayjs from "dayjs";
import { debounce } from "lodash";
import React, { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RiAddLine, RiEyeLine, RiSearchLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { getCommitteeMinutes } from "services/fdb/committee";

const stateColors = {
  APPLICATION_REJECTED: {
    color: "error",
    text: "Permohonan Ditolak",
  },
  APPLICATION_ACCEPTED: {
    color: "success",
    text: "Permohonan Diterima",
  },
  APPLICATION_SUBMITTED: {
    color: "default",
    text: "Sedang Penawaran",
  },
};

const BeritaAcara = ({ accessRight }) => {
  const title = "Berita Acara - Perorangan Dalam Kelompok";
  const navigate = useNavigate();

  // For List Table
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [filters, setFilters] = useState({
    filter: {
      search: undefined,
      committee_meeting_agenda_date: undefined,
      is_offer: false,
    },
  });

  const { data, isLoading } = useQuery({
    queryKey: ["committee-minutes-list", { page, limit, ...filters }],
    queryFn: ({ queryKey }) => {
      return getCommitteeMinutes(queryKey[1]);
    },
    onSuccess: ({ data }) => {
      setTotalPage(data?.data?.meta?.last_page ?? 0);
      setTotalItems(data?.data?.meta?.total ?? 0);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
  const methods = useForm({
    // resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      search: "",
      committee_meeting_date: "",
    },
  });

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: "/member-in-group-ba",
          },
        ]}
      />
      <div className="w-full flex justify-between">
        <h1 className="text-gray-900 text-2xl font-semibold leading-loose">
          {title}
        </h1>
        {accessRight?.canCreate && (
          <Button
            theme="primary"
            variant="icon"
            label={
              <div className="flex items-center gap-2">
                <RiAddLine className="text-lg" />
                Tambah Berita Acara
              </div>
            }
            onClick={() => {
              navigate("/create-ba");
            }}
          />
        )}
      </div>

      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <ListFilter methods={methods} setFilters={setFilters} />
        <ListTable
          totalItems={totalItems}
          totalPage={totalPage}
          setLimit={setLimit}
          setPage={setPage}
          isLoading={isLoading}
          items={data?.data?.data?.data ?? []}
        />
      </div>
    </div>
  );
};

const ListFilter = ({ methods, setFilters }) => {
  return (
    <FormProvider {...methods}>
      <form className="grid grid-cols-1 md:grid-cols-2 gap-4 items-end">
        <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
          <RiSearchLine className="text-gray-400 text-xl" />
          <InputForm
            controllerName="search"
            placeholder="Cari"
            className={`!pl-0 w-full rounded-md border-none`}
            onChangeInput={debounce(
              (e) =>
                setFilters((prev) => ({
                  ...prev,
                  filter: { ...prev.filter, search: e.target.value },
                })),
              1000
            )}
          />
        </div>
        <AtomDatePicker
          controllerName={"committee_meeting_date"}
          label=""
          placeholder="Tanggal Rapat Komite"
          datepickerOptions={{
            dateFormat: "yyyy-MM-dd",
          }}
          onChangeDate={debounce(
            (date) =>
              setFilters((prev) => ({
                ...prev,
                filter: {
                  ...prev.filter,
                  committee_meeting_date: dayjs(date).format("YYYY-MM-DD"),
                },
              })),
            1000
          )}
          showErrorLabel
        />
      </form>
    </FormProvider>
  );
};

const ListTable = ({
  totalPage,
  totalItems,
  setPage,
  setLimit,
  isLoading,
  items,
}) => {
  const navigate = useNavigate();

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "committee_minutes_number",
        title: "Nomor Berita Acara",
      },
      {
        key: "name",
        title: "Nama Kelompok",
        render: ({ item }) => item?.group_profile?.name ?? "-",
      },
      {
        key: "service_type",
        title: "Layanan",
        render: ({ item }) =>
          item?.service_type !== "null" ? item.service_type : "-",
      },
      {
        key: "date",
        title: "Tanggal Rapat Komite",
        render: ({ item }) => {
          return item?.committee_meeting_agenda_date
            ? dayjs(item.committee_meeting_agenda_date).format("DD MMMM YYYY")
            : "-";
        },
      },
      {
        key: "number_of_applications",
        title: "Jumlah Permohonan",
      },
      {
        key: "number_of_members_accepted",
        title: "Anggota Diterima",
      },
      {
        key: "number_of_members_rejected",
        title: "Anggota Ditolak",
      },
      // {
      //   key: "state",
      //   title: "Status",
      //   alignment: "center",
      //   render: ({ item }) => {
      //     const status = item?.status || "APPLICATION_SUBMITTED";
      //     return (
      //       <Badge color={stateColors[status]?.color}>
      //         {stateColors[status]?.text}
      //       </Badge>
      //     );
      //   },
      // },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <>
            <Button
              variant="icon"
              label={<RiEyeLine className="text-lg" />}
              onClick={() => {
                navigate(`/member-in-group-ba/${item.id}`);
              }}
            />
          </>
        ),
      },
    ];
  }, [navigate]);

  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={items} isLoading={isLoading} />
      <TablePagination
        totalPage={totalPage}
        totalItems={totalItems}
        isLoading={isLoading}
        onChange={setPage}
        onItemsPerPageChange={setLimit}
      />
    </TableWrapper>
  );
};

export default BeritaAcara;
