import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { Button, Input } from "components";
import { customStyles } from "components/atoms/Select";
import Table, { TableWrapper } from "components/atoms/Table";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { Pagination } from "components/v2/shared/pagination";
import { capitalize, debounce } from "lodash";
import { useMemo, useState } from "react";
import { RiEyeLine, RiSearchLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import Select from "react-select";
import { getListApprovalOffers } from "services/fdb/offers";

const STATUS_ENUM = {
  NEW: "Belum Review",
  APPROVED: "Disetujui",
};

const badgeStyle = {
  NEW: "bg-[#FFFAEB] text-[#B54708]",
  APPROVED: "bg-primary-50 text-primary-600",
};

const CommitteeMinutesApprovalDD = () => {
  const breadcrumbs = useMemo(
    () => [
      {
        label: "Approval Permohonan Pembiayaan",
        path: "/committee/approval",
      },
    ],
    []
  );

  // ======================

  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    paginate: true,
    filter: {
      search: undefined,
      date: undefined,
    },
  });

  const { data: approvalDatas, isLoading } = useQuery({
    queryKey: ["offer-application-approval-list", { ...filters }],
    queryFn: async ({ queryKey }) => {
      const { data } = await getListApprovalOffers(queryKey[1]);
      if (data?.data?.data?.length > 0) {
        return data?.data;
      }
      return [];
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  // =====================

  const headers = useMemo(
    () => [
      {
        key: "committee_minutes_number",
        title: "No Berita Acara",
      },
      {
        key: "group_profil_name",
        title: "Nama Kelompok",
      },
      {
        key: "results_of_committee_discussions",
        title: "Keputusan Komite",
        render: ({ item }) => (
          <div
            className="line-clamp-1"
            dangerouslySetInnerHTML={{
              __html: item?.results_of_committee_discussions,
            }}
          />
        ),
      },
      {
        key: "status",
        title: "Status",
        render: ({ item }) => {
          return (
            <div
              className={clsx(
                "flex items-center text-xs gap-1 px-[6px] py-0.5 rounded-full font-medium",
                badgeStyle[item.status]
              )}
            >
              {STATUS_ENUM[item.status]}
            </div>
          );
        },
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) =>
          item.status === "NEW" ? (
            <div className="flex items-center gap-[10px]">
              <Button
                label="Verifikasi"
                size="xs"
                className="bg-primary-600 hover:bg-primary-700 text-white text-xs"
                onClick={() => navigate(`${item.id}?type=verifikasi`)}
              />
            </div>
          ) : (
            <Button
              variant="icon"
              label={<RiEyeLine className="text-lg" />}
              onClick={() => {
                navigate(`${item.id}?type=detail`);
              }}
            />
          ),
      },
    ],
    [navigate]
  );

  return (
    <div className="space-y-6">
      <BreadCrumbs routes={breadcrumbs} />

      <div className="flex justify-between">
        <h1 className="text-gray-900 text-lg font-semibold leading-loose">
          Approval Permohonan Pembiayaan
        </h1>
      </div>

      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-end">
          <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
            <RiSearchLine className="text-gray-400 text-xl" />

            <Input
              className="w-full bg-white border-none"
              onChange={debounce(
                (e) =>
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      search: e.target.value,
                    },
                  })),
                1000
              )}
              placeholder="Cari"
            />
          </div>
          <Select
            className="w-full rounded-md h-10 absolute"
            options={Object.keys(STATUS_ENUM).map((x) => ({
              value: x,
              label: STATUS_ENUM[x],
            }))}
            placeholder="Status"
            styles={customStyles}
            isClearable
            onChange={debounce(
              (e) =>
                setFilters((prev) => ({
                  ...prev,
                  page: 1,
                  status: e?.value ?? "",
                })),
              1000
            )}
          />
        </div>
        <TableWrapper className="mt-4">
          <Table
            headers={headers}
            items={approvalDatas?.data ?? []}
            isLoading={isLoading}
          />
          <Pagination
            page={approvalDatas?.meta?.current_page ?? 1}
            limit={filters.limit}
            total={approvalDatas?.meta?.total ?? 0}
            totalPage={approvalDatas?.meta?.last_page ?? 1}
            onLimitChange={(e) => setFilters((prev) => ({ ...prev, limit: e }))}
            onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
          />
        </TableWrapper>
      </div>
    </div>
  );
};

export default CommitteeMinutesApprovalDD;
