import React, { useMemo, useState, useCallback, useEffect } from "react";
import * as yup from "yup";
import AtomSelect from "components/atoms/Select";
import AtomDatePicker from "components/atoms/Datepicker";
import Button from "components/atoms/Button/Button";
import CardForm from "components/molecules/CardForm";
import { useNavigate, useParams } from "react-router";
import { TabsContent } from "components/atoms/Tabs";
import {
  RiEditLine,
  RiCheckboxCircleFill,
  RiCloseCircleFill,
} from "react-icons/ri";
import { InputForm, InputFormRadio, BottomFormAction } from "components";
import {
  FormProvider,
  useWatch,
  useFormContext,
  useForm,
} from "react-hook-form";
import SelectFDBProvince from "components/organisms/inputs/SelectFDBProvince";
import SelectFDBCity from "components/organisms/inputs/SelectFDBCity";
import SelectFDBDistrict from "components/organisms/inputs/SelectFDBDistrict";
import SelectFDBVillage from "components/organisms/inputs/SelectFDBVillage";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getMasterSocialArea } from "services/masterData/socialArea";
import { getCompanionAffiliates } from "services/masterData/companionAffiliates";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import UploadedFileAsync from "components/molecules/UploadFileAsync";
import { yupResolver } from "@hookform/resolvers/yup";
import { putVerificator } from "services/fdb/verificator";
import { getCompanionStatuses } from "services/masterData/companion-statusses";
import { enqueueSnackbar } from "notistack";
// import { enqueueSnackbar } from "notistack";

export const ProfileTabContent = ({
  initialValues,
  type,
  data,
  verificationData,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const userId = useSelector((state) => state?.oidc?.oidc_user?.access_token);
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: socialArea } = useQuery({
    queryKey: ["master-social-area-fdb"],
    queryFn: async () => {
      const res = await getMasterSocialArea(userId);
      const activities = res?.data?.data?.data || [];
      const formattedData = activities.map((activity) => ({
        value: activity.id,
        label: activity.name,
      }));
      if (formattedData.length <= 0) {
        formattedData.push({
          value: null,
          label: null,
        });
      }
      return formattedData;
    },
    retry: 1,
  });

  const { data: companionAffiliates } = useQuery({
    queryKey: ["master-companion-affiliates-fdb"],
    queryFn: async () => {
      const res = await getCompanionAffiliates(userId);
      const activities = res?.data?.data?.data || [];
      const formattedData = activities.map((activity) => ({
        value: activity.id,
        label: activity.name,
      }));
      if (formattedData.length <= 0) {
        formattedData.push({
          value: null,
          label: null,
        });
      }
      return formattedData;
    },
    retry: 1,
  });

  const { data: companionStatuses } = useQuery(
    ["GET_COMPANION_STATUSES"],
    getCompanionStatuses,
    { select: (data) => data?.data?.data?.data }
  );

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      is_verified_group: yup
        .bool()
        .transform((originalValue) =>
          originalValue === "" ? undefined : originalValue
        )
        .required()
        .label("Kesimpulan Verifikasi"),
      verified_group_desc: yup
        .string()
        .when("is_verified_group", ([value], schema) => {
          if (value === false) {
            return schema.required().label("Kesalahan/Ketidak sesuaian data");
          }
          return schema.notRequired();
        }),
      is_verified_address: yup
        .bool()
        .transform((originalValue) =>
          originalValue === "" ? undefined : originalValue
        )
        .required()
        .label("Kesimpulan Verifikasi"),
      verified_address_desc: yup
        .string()
        .when("is_verified_address", ([value], schema) => {
          if (value === false) {
            return schema.required().label("Kesalahan/Ketidak sesuaian data");
          }
          return schema.notRequired();
        }),
      is_verified_group_manage: yup
        .bool()
        .transform((originalValue) =>
          originalValue === "" ? undefined : originalValue
        )
        .required()
        .label("Kesimpulan Verifikasi"),
      verified_group_manage_desc: yup
        .string()
        .when("is_verified_group_manage", ([value], schema) => {
          if (value === false) {
            return schema.required().label("Kesalahan/Ketidak sesuaian data");
          }
          return schema.notRequired();
        }),
    });
  }, []);

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const postVerification = useMutation({
    mutationFn: (payload) => {
      const res = putVerificator(
        payload,
        data?.id,
        data?.group_profile_id,
        false
      );
      return res;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([
        "group-application-verification-detail",
        id,
      ]);
    },
  });

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onFormSubmit = useCallback(
    (values) => {
      const { data_updates, ...rest } = values;
      const payload = {
        verified_status: {
          ...rest,
          data_updates: [
            ...data_updates,
            ...verificationData.activities,
            ...verificationData.partners,
            ...verificationData.finance_businesses,
          ],
        },
      };

      // postVerification.mutate(payload, {
      //   onSuccess: (res) => {
      //     enqueueSnackbar({
      //       variant: "success",
      //       message: (
      //         <div style={{ display: "flex", flexDirection: "column" }}>
      //           <span style={{ fontWeight: "bold" }}>Data berhasil disimpan!</span>
      //           <span>Data yang Anda masukkan berhasil dikirim</span>
      //         </div>
      //       ),
      //     });
      //     setSearchParams({ tab: "portfolio" });
      //   },
      // })
      //     setSearchParams({ tab: "portfolio" });
      setSearchParams({ tab: "portfolio" });
    },
    [
      verificationData, 
      setSearchParams, 
      // postVerification
    ]
  );

  const onDraft = useCallback(() => {
    //
  }, []);

  useEffect(() => {
    if (data) {
      const profile = data?.group_profile;
      methods.reset({
        is_verified_group: profile?.is_verified_group,
        is_verified_address: profile?.is_verified_address,
        is_verified_group_manage: profile?.is_verified_group_manage,
        verified_group_desc: profile?.verified_group_desc,
        verified_address_desc: profile?.verified_address_desc,
        verified_group_manage_desc: profile?.verified_group_manage_desc,
        data_updates: [
          {
            group_profile_id: profile?.id,
            data: {
              name: profile?.name,
              sk_number: profile?.sk_number,
              kups_name: profile?.kups_name,
              kups_number: profile?.kups_number,
              founding_date: new Date(profile?.founding_date),
              social_area_function_id: profile?.social_area_function_id,
              agreement_access: profile?.agreement_access,
              end_year_of_agreement_access: new Date(
                profile?.end_year_of_agreement_access
              ),
              // profile?.end_year_of_agreement_access
              province_id: profile?.province_id,
              city_id: profile?.city_id,
              district_id: profile?.district_id,
              village_id: profile?.village_id,
              address: profile?.address,
              leader_name: profile?.leader_name,
              leader_identity: profile?.leader_identity,
              leader_phone: profile?.leader_phone,
              leader_gender: profile?.leader_gender,
              secretary_name: profile?.secretary_name,
              secretary_phone: profile?.secretary_phone,
              secretary_gender: profile?.secretary_gender,
              treasurer_name: profile?.treasurer_name,
              treasurer_phone: profile?.treasurer_phone,
              treasurer_gender: profile?.treasurer_gender,
              companion_affiliate_id: profile?.companion_affiliate_id,
              companion_status_id: profile?.companion_status_id,
              companion_name: profile?.companion_name,
              companion_phone: profile?.companion_phone,
              companion_gender: profile?.companion_gender,
              total_member_apply: profile?.total_member_apply,
              total_member_apply_area: profile?.total_member_apply_area,
              total_member_apply_persil: profile?.total_member_apply_persil,
              total_member_not_apply: profile?.total_member_not_apply,
              total_member_not_apply_area: profile?.total_member_not_apply_area,
              total_member_not_apply_persil:
                profile?.total_member_not_apply_persil,
            },
          },
        ],
      });
    }
  }, [data, methods]);

  return (
    <FormProvider {...methods}>
      <TabsContent value="profile" className="mb-40">
        <InformationCard type={type} items={socialArea} data={data} />
        <AddressCard data={data} />
        <ManagementInformationCard
          type={type}
          items={companionAffiliates}
          data={data}
          companionStatuses={companionStatuses}
        />
        {type === "non-persos" && (
          <ExtraDocs data={data?.group_profile?.documents} />
        )}

        <BottomFormAction
          backButtonAction={onBack}
          submitActionButton={() => methods.handleSubmit(onFormSubmit)()}
          submitButtonProps={{
            type: "submit",
          }}
          disableDrafButton={false}
          drafButtonAction={onDraft}
          disableButtonSubmit={false}
          className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
          hideDraft={true}
        />
      </TabsContent>
    </FormProvider>
  );
};

const InformationCard = ({ type, items, data }) => {
  const [isEdit, setIsEdit] = useState(false);
  const isVerified = useWatch({ name: "is_verified_group" });

  const areaOptions = [
    {
      label: "Kawasan Hutan",
      value: "Kawasan Hutan",
    },
  ];

  return (
    <CardForm
      label="Informasi Kelompok"
      rightSlot={() =>
        data?.status !== "verified" && (
          <Button
            label={
              <>
                <RiEditLine />
                Ubah
              </>
            }
            onClick={() => setIsEdit(!isEdit)}
          />
        )
      }
    >
      {type === "persos" ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <InputForm
            controllerName="data_updates[0].data.name"
            label="Nama KPS"
            placeholder="Nama KPS"
            required={true}
            className="rounded-md"
            disabled={!isEdit}
          />
          <InputForm
            controllerName="data_updates[0].data.sk_number"
            label="Dasar Persetujuan/penetapan/perjanjian akses kawasan"
            placeholder="Dasar Persetujuan/penetapan/perjanjian akses kawasan"
            required={true}
            className="rounded-md"
            disabled={!isEdit}
          />
          <InputForm
            controllerName="data_updates[0].data.kups_name"
            label="Nama KUPS"
            placeholder="Nama KUPS"
            required={true}
            className="rounded-md"
            disabled={!isEdit}
          />
          <InputForm
            controllerName="data_updates[0].data.kups_number"
            label="Dasar Pembentukan/No Izin KUPS"
            placeholder="Dasar Pembentukan/No Izin KUPS"
            required={true}
            className="rounded-md"
            disabled={!isEdit}
          />
          <AtomDatePicker
            controllerName={"data_updates[0].data.founding_date"}
            label="Tanggal Dan Tahun Berdiri"
            placeholder="Tanggal Dan Tahun Berdiri"
            required={true}
            format={"dd/MM/yyyy"}
            disabled={!isEdit}
          />
          <AtomSelect
            controllerName={"data_updates[0].data.social_area_function_id"}
            wrapperClass={"w-full min-w-[100px]"}
            className="basic-single w-full"
            options={items}
            label="Fungsi Kawasan & Skema PS "
            placeholder="Fungsi Kawasan & Skema PS "
            disabled={!isEdit}
            returnObject={false}
          />
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <InputForm
            controllerName="data_updates[0].data.name"
            label="Nama Kelompok"
            placeholder="Nama Kelompok"
            required={true}
            className="rounded-md"
            disabled={!isEdit}
          />
          <InputForm
            controllerName="data_updates[0].data.sk_number"
            label="SK Pembentukan atau Berita Acara Pembentukan"
            placeholder="SK Pembentukan atau Berita Acara Pembentukan"
            required={true}
            className="rounded-md"
            disabled={!isEdit}
          />
          <AtomDatePicker
            controllerName={"data_updates[0].data.founding_date"}
            label="Tanggal Dan Tahun Berdiri"
            placeholder="Tanggal Dan Tahun Berdiri"
            required={true}
            format={"dd/MM/yyyy"}
            disabled={!isEdit}
          />
          {/* <InputForm
            controllerName=""
            label="Kawasan"
            placeholder={data?.group_profile?.social_area_function?.name}
            required={true}
            className="rounded-md"
            disabled={!isEdit}
          />
          <InputForm
            controllerName="data_updates[0].data.agreement_access"
            label="Dasar Perizinan/Perjanjian Akses Kawasan"
            placeholder="Dasar Perizinan/Perjanjian Akses Kawasan"
            required={true}
            className="rounded-md"
            disabled={!isEdit}
          />
          <AtomDatePicker
            controllerName="data_updates[0].data.end_year_of_agreement_access"
            label="Tahun Berakhirnya Izin Akses/Perjanjian"
            placeholder="Tahun Berakhirnya Izin Akses/Perjanjian"
            required={true}
            format={"dd/MM/yyyy"}
            disabled={!isEdit}
          /> */}
        </div>
      )}
      <div className="border rounded-md px-4 py-3">
        <InputFormRadio
          label={"Hasil Verifikasi"}
          controllerName={`is_verified_group`}
          className="flex items-center justify-between gap-4"
          disabled={data?.verificator_status !== "not_yet_review"}
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Data Terverifikasi
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Data Belum Sesuai/ Ada Kesalahan
                </>
              ),
              value: false,
            },
          ]}
        />
        {typeof isVerified !== "undefined" && isVerified === false && (
          <InputForm
            controllerName="verified_group_desc"
            label="jelaskan kesalahan/ ketidak sesuaian datanya"
            placeholder="jelaskan kesalahan/ ketidak sesuaian datanya"
            required={true}
            wrapperClass="col-span-2 mt-3"
            className="rounded-md w-full px-4 py-3 border"
            disabled={data?.verificator_status !== "not_yet_review"}
            textArea
          />
        )}
      </div>
    </CardForm>
  );
};

const AddressCard = ({ data }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { control } = useFormContext();

  const provinceId = useWatch({
    control,
    name: "data_updates[0].data.province_id",
  });
  const cityId = useWatch({
    control,
    name: "data_updates[0].data.city_id",
  });
  const districtId = useWatch({
    control,
    name: "data_updates[0].data.district_id",
  });
  const isVerified = useWatch({ name: "is_verified_address" });

  return (
    <CardForm
      label="Alamat"
      rightSlot={() =>
        data?.status !== "verified" && (
          <Button
            label={
              <>
                <RiEditLine />
                Ubah
              </>
            }
            onClick={() => setIsEdit(!isEdit)}
          />
        )
      }
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <SelectFDBProvince
          controllerName={"data_updates[0].data.province_id"}
          label={"Provinsi"}
          required
          returnObject={false}
          disabled={!isEdit}
        />
        <SelectFDBCity
          controllerName={"data_updates[0].data.city_id"}
          className="basic-single mt-2 flex-1"
          label="Kota/Kabupaten"
          required={true}
          returnObject={false}
          provinceId={provinceId}
          disabled={!isEdit}
        />
        <SelectFDBDistrict
          controllerName={"data_updates[0].data.district_id"}
          className="basic-single mt-2 flex-1"
          label="Kecamatan"
          required={true}
          returnObject={false}
          cityId={cityId}
          disabled={!isEdit}
        />
        <SelectFDBVillage
          controllerName={"data_updates[0].data.village_id"}
          className="basic-single mt-2 flex-1"
          label="Kelurahan/Desa"
          required={true}
          returnObject={false}
          districtId={districtId}
          disabled={!isEdit}
        />
        <InputForm
          controllerName="data_updates[0].data.address"
          label="Alamat"
          placeholder="Alamat"
          required={true}
          wrapperClass="col-span-2"
          className="rounded-md w-full px-4 py-3 border"
          textArea
          disabled={!isEdit}
        />
      </div>
      <div className="border rounded-md px-4 py-3">
        <InputFormRadio
          label={"Hasil Verifikasi"}
          controllerName={`is_verified_address`}
          disabled={data?.verificator_status !== "not_yet_review"}
          className="flex items-center justify-between gap-4"
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Data Terverifikasi
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Data Belum Sesuai/ Ada Kesalahan
                </>
              ),
              value: false,
            },
          ]}
        />

        {typeof isVerified !== "undefined" && isVerified === false && (
          <InputForm
            controllerName="verified_address_desc"
            label="jelaskan kesalahan/ ketidak sesuaian datanya"
            placeholder="jelaskan kesalahan/ ketidak sesuaian datanya"
            required={true}
            wrapperClass="col-span-2 mt-3"
            className="rounded-md w-full px-4 py-3 border"
            disabled={data?.verificator_status !== "not_yet_review"}
            textArea
          />
        )}
      </div>
    </CardForm>
  );
};

const ManagementInformationCard = ({
  type,
  items,
  data,
  companionStatuses,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const isVerified = useWatch({ name: "is_verified_group_manage" });

  const methods = useFormContext();

  console.log(methods.getValues("data_updates[0].data.companion_status_id"));
  console.log(companionStatuses);

  return (
    <CardForm
      label="Informasi Kepengurusan Kelompok"
      rightSlot={() =>
        data?.status !== "verified" && (
          <Button
            label={
              <>
                <RiEditLine />
                Ubah
              </>
            }
            onClick={() => setIsEdit(!isEdit)}
          />
        )
      }
    >
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        <InputForm
          controllerName="data_updates[0].data.leader_name"
          label={`Nama Ketua ${type === "persos" ? "KPS" : "Kelompok"}`}
          placeholder={`Nama Ketua ${type === "persos" ? "KPS" : "Kelompok"}`}
          required={true}
          className="rounded-md w-full px-4 py-3 border"
          disabled={!isEdit}
        />
        <InputForm
          controllerName="data_updates[0].data.leader_phone"
          label={`Nomor Telepon Ketua ${
            type === "persos" ? "KPS" : "Kelompok"
          }`}
          placeholder={`Nomor Telepon Ketua ${
            type === "persos" ? "KPS" : "Kelompok"
          }`}
          required={true}
          className="rounded-md w-full px-4 py-3 border"
          disabled={!isEdit}
        />
        <InputForm
          controllerName="data_updates[0].data.leader_identity"
          label={`NIK KTP Ketua ${type === "persos" ? "KPS" : "Kelompok"}`}
          placeholder={`NIK KTP Ketua ${
            type === "persos" ? "KPS" : "Kelompok"
          }`}
          required={true}
          className="rounded-md w-full px-4 py-3 border"
          disabled={!isEdit}
        />
        <AtomSelect
          controllerName="data_updates[0].data.leader_gender"
          label={`Gender Ketua ${type === "persos" ? "KPS" : ""}`}
          placeholder={`Gender Ketua ${type === "persos" ? "KPS" : ""}`}
          required={true}
          returnObject={false}
          className={"w-full"}
          options={[
            {
              value: "m",
              label: "Male",
            },
            {
              value: "f",
              label: "Female",
            },
          ]}
          disabled={!isEdit}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <InputForm
          controllerName="data_updates[0].data.secretary_name"
          label={`Nama Sekretaris ${type === "persos" ? "KPS" : ""}`}
          placeholder={`Nama Sekretaris ${type === "persos" ? "KPS" : ""}`}
          required={true}
          className="rounded-md w-full px-4 py-3 border"
          disabled={!isEdit}
        />
        <InputForm
          controllerName="data_updates[0].data.secretary_phone"
          label={`Nomor Telepon Sekretaris ${type === "persos" ? "KPS" : ""}`}
          placeholder={`Nomor Telepon Sekretaris ${
            type === "persos" ? "KPS" : ""
          }`}
          required={true}
          className="rounded-md w-full px-4 py-3 border"
          disabled={!isEdit}
        />
        <AtomSelect
          controllerName="data_updates[0].data.secretary_gender"
          label={`Gender Sekretaris ${type === "persos" ? "KPS" : ""}`}
          placeholder={`Gender Sekretaris ${type === "persos" ? "KPS" : ""}`}
          required={true}
          returnObject={false}
          className={"w-full"}
          options={[
            {
              value: "m",
              label: "Male",
            },
            {
              value: "f",
              label: "Female",
            },
          ]}
          disabled={!isEdit}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <InputForm
          controllerName="data_updates[0].data.treasurer_name"
          label={`Nama Bendahara ${type === "persos" ? "KPS" : ""}`}
          placeholder={`Nama Bendahara ${type === "persos" ? "KPS" : ""}`}
          required={true}
          className="rounded-md w-full px-4 py-3 border"
          disabled={!isEdit}
        />
        <InputForm
          controllerName="data_updates[0].data.treasurer_phone"
          label={`Nomor Telepon Bendahara ${type === "persos" ? "KPS" : ""}`}
          placeholder={`Nomor Telepon Bendahara ${
            type === "persos" ? "KPS" : ""
          }`}
          required={true}
          className="rounded-md w-full px-4 py-3 border"
          disabled={!isEdit}
        />
        <AtomSelect
          controllerName="data_updates[0].data.treasurer_gender"
          label={`Gender Bendahara ${type === "persos" ? "KPS" : ""}`}
          placeholder={`Gender Bendahara ${type === "persos" ? "KPS" : ""}`}
          required={true}
          returnObject={false}
          className={"w-full"}
          options={[
            {
              value: "m",
              label: "Male",
            },
            {
              value: "f",
              label: "Female",
            },
          ]}
          disabled={!isEdit}
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        {type === "persos" ? (
          <>
            <AtomSelect
              controllerName="data_updates[0].data.companion_affiliate_id"
              label={`Afiliasi Pendamping ${type === "persos" ? "KPS" : ""}`}
              placeholder={`Afiliasi Pendamping ${
                type === "persos" ? "KPS" : ""
              }`}
              required={true}
              options={items}
              className="w-full"
              returnObject={false}
              disabled={!isEdit}
            />
            <InputForm
              controllerName="data_updates[0].data.companion_name"
              label={`Nama Pendamping ${type === "persos" ? "KPS" : ""}`}
              placeholder={`Nama Pendamping ${type === "persos" ? "KPS" : ""}`}
              required={true}
              className="rounded-md w-full px-4 py-3 border"
              disabled={!isEdit}
            />
          </>
        ) : (
          <>
            <InputForm
              controllerName="data_updates[0].data.companion_name"
              label={`Nama Pendamping ${type === "persos" ? "KPS" : ""}`}
              placeholder={`Nama Pendamping ${type === "persos" ? "KPS" : ""}`}
              required={true}
              className="rounded-md w-full px-4 py-3 border"
              disabled={!isEdit}
            />

            <AtomSelect
              controllerName="data_updates[0].data.companion_status_id"
              label={`Status Pendamping`}
              placeholder={`Status Pendamping`}
              required={true}
              options={companionStatuses?.map((e) => ({
                label: e.name,
                value: e.id,
              }))}
              className="w-full"
              returnObject={false}
              disabled={!isEdit}
            />
          </>
        )}
        {/* <AtomSelect
          controllerName="data_updates[0].data.companion_affiliate_id"
          label={`Afiliasi Pendamping ${type === "persos" ? "KPS" : ""}`}
          placeholder={`Afiliasi Pendamping ${type === "persos" ? "KPS" : ""}`}
          required={true}
          options={items}
          className="w-full"
          returnObject={false}
          disabled={!isEdit}
        />
        <InputForm
          controllerName="data_updates[0].data.companion_name"
          label={`Nama Pendamping ${type === "persos" ? "KPS" : ""}`}
          placeholder={`Nama Pendamping ${type === "persos" ? "KPS" : ""}`}
          required={true}
          className="rounded-md w-full px-4 py-3 border"
          disabled={!isEdit}
        /> */}
        <InputForm
          controllerName="data_updates[0].data.companion_phone"
          label={`Nomor Telepon Pendamping ${type === "persos" ? "KPS" : ""}`}
          placeholder={`Nomor Telepon Pendamping ${
            type === "persos" ? "KPS" : ""
          }`}
          required={true}
          className="rounded-md w-full px-4 py-3 border"
          disabled={!isEdit}
        />
        <AtomSelect
          controllerName="data_updates[0].data.companion_gender"
          label={`Gender Pendamping ${type === "persos" ? "KPS" : ""}`}
          placeholder={`Gender Pendamping ${type === "persos" ? "KPS" : ""}`}
          required={true}
          returnObject={false}
          className={"w-full"}
          options={[
            {
              value: "m",
              label: "Male",
            },
            {
              value: "f",
              label: "Female",
            },
          ]}
          disabled={!isEdit}
        />
      </div>
      {type === "non-persos" && (
        <div className="border-t-2 py-5">
          <div className="flex gap-6 mb-4">
            <div className="flex-grow">
              <InputForm
                controllerName="data_updates[0].data.total_member_not_apply"
                label={`Jumlah anggota (Termasuk yang tidak mengajukan pinjaman)`}
                placeholder={``}
                required={true}
                className="rounded-md w-full px-4 py-3 border"
                disabled={!isEdit}
              />
            </div>
            <div>
              <InputForm
                controllerName="data_updates[0].data.total_member_not_apply_persil"
                label={`Jumlah Persil`}
                placeholder={``}
                required={true}
                className="rounded-md px-4 py-3 border"
                disabled={!isEdit}
              />
            </div>
            <div>
              <InputForm
                controllerName="data_updates[0].data.total_member_not_apply_area"
                label={`Total Luas Lahan`}
                placeholder={``}
                required={true}
                className="rounded-md px-4 py-3 border"
                disabled={!isEdit}
              />
            </div>
          </div>
          <div className="flex gap-6">
            <div className="flex-grow">
              <InputForm
                controllerName="data_updates[0].data.total_member_apply"
                label={`Jumlah anggota yang mengajukan permohonan`}
                placeholder={``}
                required={true}
                className="rounded-md w-full px-4 py-3 border"
                disabled={!isEdit}
              />
            </div>
            <div>
              <InputForm
                controllerName="data_updates[0].data.total_member_apply_persil"
                label={`Jumlah Persil`}
                placeholder={``}
                required={true}
                className="rounded-md px-4 py-3 border"
                disabled={!isEdit}
              />
            </div>
            <div>
              <InputForm
                controllerName="data_updates[0].data.total_member_apply_area"
                label={`Total Luas Lahan`}
                placeholder={``}
                required={true}
                className="rounded-md px-4 py-3 border"
                disabled={!isEdit}
              />
            </div>
          </div>
        </div>
      )}
      <div className="border rounded-md px-4 py-3">
        <InputFormRadio
          label={"Hasil Verifikasi"}
          controllerName={`is_verified_group_manage`}
          disabled={data?.verificator_status !== "not_yet_review"}
          className="flex items-center justify-between gap-4"
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Data Terverifikasi
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Data Belum Sesuai/ Ada Kesalahan
                </>
              ),
              value: false,
            },
          ]}
        />
        {typeof isVerified !== "undefined" && isVerified === false && (
          <InputForm
            controllerName="verified_group_manage_desc"
            label="jelaskan kesalahan/ ketidak sesuaian datanya"
            placeholder="jelaskan kesalahan/ ketidak sesuaian datanya"
            required={true}
            wrapperClass="col-span-2 mt-3"
            className="rounded-md w-full px-4 py-3 border"
            disabled={data?.verificator_status !== "not_yet_review"}
            textArea
          />
        )}
      </div>
    </CardForm>
  );
};

const ExtraDocs = ({ data }) => {
  return (
    <CardForm label="Lampiran Dokumen Pendukung">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {data
          ? data.map((item) => {
              let doc_name = "";
              switch (item?.type) {
                case "sk":
                  doc_name = "Dokumen SK Pembentukan / Berita Acara";
                  break;
                case "ad":
                  doc_name = "Dokumen AD/ART";
                  break;
                case "rekomendation":
                  doc_name = "Surat Rekomendasi Pendamping";
                  break;
                default:
                  break;
              }
              return <UploadedFileAsync id={item?.doc_id} name={doc_name} />;
            })
          : null}
      </div>
    </CardForm>
  );
};

export default ProfileTabContent;
