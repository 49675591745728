import React, { useMemo, useState } from "react";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { RiEyeLine, RiSearchLine } from "react-icons/ri";
import { Input } from "components";
import { customStyles } from "components/atoms/Select";
import Table, { TableWrapper } from "components/atoms/Table";
import Button from "components/atoms/Button/Button";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { debounce } from "lodash";
import Select from "react-select";
import { getAllRisalah } from "services/fdb/risalah";
import dayjs from "dayjs";
import { Pagination } from "components/v2/shared/pagination";

const stateOptions = [
  { value: "Tunda Tebang", label: "Tunda Tebang" },
  { value: "Hasil Hutan Bukan Kayu", label: "HHBK" },
  {
    value: "Komoditas Non Kehutanan ",
    label: "Komoditas Non Perhutanan ",
  },
];
const title = "Risalah";

const Risalah = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    filter: {
      search: undefined,
      service_type: undefined,
    },
  });

  const { data: minutes, status } = useQuery({
    queryKey: ["minutes", filters],
    queryFn: async () => {
      const { data } = await getAllRisalah({ ...filters });
      if (data?.data?.data?.length > 0) {
        return data?.data;
      }
      return [];
    },
    select: (data) => data,
    refetchOnWindowFocus: false,
  });

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "number",
        title: "Nomor Risalah",
      },
      {
        key: "created_at",
        title: "Tanggal Terbit Risalah",
        render: ({ item }) => (
          <p>
            {item.created_at
              ? dayjs(item.created_at).format("DD/MM/YYYY")
              : "-"}
          </p>
        ),
      },
      {
        key: "service",
        title: "Layanan",
        render: ({ item }) => (
          <p>{item.service !== "null" ? item.service : "Perhutanan Sosial"}</p>
        ),
      },
      {
        key: "group_profile_name",
        title: "Nama Kelompok",
        render: ({ item }) => <p>{item.meta.name ?? "-"}</p>,
      },
      {
        key: "date_of_application",
        title: "Tanggal Permohonan",
        render: ({ item }) => (
          <p>
            {item.meta.registration_date
              ? dayjs(item.meta.registration_date).format("DD/MM/YYYY")
              : "-"}
          </p>
        ),
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <>
            <Button
              variant="icon"
              label={<RiEyeLine className="text-lg" />}
              onClick={() => {
                const groupType =
                  item.service === "null" || item.service === "forestry"
                    ? "Perhutanan Sosial"
                    : "Non Perhutanan Sosial";
                navigate(`/risalah/${item.id}?group_type=${groupType}`);
              }}
            />
          </>
        ),
      },
    ];
  }, [navigate]);

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: "/risalah",
          },
        ]}
      />

      <h1 className="text-gray-900 text-2xl font-semibold leading-loose">
        Daftar Risalah
      </h1>

      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-end">
          <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
            <RiSearchLine className="text-gray-400 text-xl" />

            <Input
              className="w-full bg-white border-none"
              onChange={debounce(
                (e) =>
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      search: e.target.value,
                    },
                  })),
                1000
              )}
              placeholder="Cari"
            />
          </div>
          <Select
            className="w-full rounded-md h-10 absolute"
            options={stateOptions}
            placeholder="Layanan"
            styles={customStyles}
            isClearable
            onChange={debounce(
              (e) =>
                setFilters((prev) => ({
                  ...prev,
                  page: 1,
                  filter: {
                    ...prev.filter,
                    service_type: e?.value ?? "",
                  },
                })),
              1000
            )}
          />
        </div>
        <TableWrapper className="mt-4">
          <Table
            headers={headers}
            items={minutes?.data ?? []}
            isLoading={status === "loading"}
          />
          <Pagination
            page={minutes?.meta?.current_page ?? 1}
            limit={filters.limit}
            total={minutes?.meta?.total ?? 0}
            totalPage={minutes?.meta?.last_page ?? 1}
            onLimitChange={(e) => setFilters((prev) => ({ ...prev, limit: e }))}
            onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
          />
        </TableWrapper>
      </div>
    </div>
  );
};

export default Risalah;
