import BreadCrumbs from "components/molecules/Breadcrumbs";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import DistributionInformation from "./DistributionInfo";
import {
  BottomFormAction,
  Button,
  InputForm,
  InputFormRadio,
  InputFormUpload,
  Spinner,
} from "components";
import ResultVerification from "./ResultVerification";
import CardForm from "components/molecules/CardForm";
import { useNavigate, useParams } from "react-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getAgencyDisbursementDirectionDetail,
  getAgencyDisbursementExecutorDetail,
  putAgencyDisbursementDirection,
  putAgencyDisbursementExecutor,
} from "services/institutions/disbursement";
import { fileUpload, getFile } from "services/fileService";
import { validationSchemaExecutor } from "./validation-schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";
import {
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
} from "components/atoms/Modal";
import { enqueueSnackbar } from "notistack";
import { RiArrowLeftLine, RiInformationLine } from "react-icons/ri";
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
} from "react-icons/io";
import { useSearchParams } from "react-router-dom";

const getFileFromServiceCB = async (id) => {
  const response = await getFile(id);
  return response.data.data;
};

const DetailDisbursment = () => {
  const navigate = useNavigate();
  const { disbursementType, id } = useParams();
  const [searchParams] = useSearchParams();

  const isView =
    disbursementType === "agreement" ? true :
    disbursementType === "review"
      ? searchParams.get("status") > 1
      : searchParams.get("status") > 2;

  const methods = useForm({ resolver: yupResolver(validationSchemaExecutor) });

  const [filters, setFilters] = useState({
    filter: {
      page_norminative: 1,
      limit_norminative: 5,
      page_collateral: 1,
      limit_collateral: 5,
    },
  });

  const handleChangePageNorminative = (page) => {
    setFilters((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        page_norminative: page,
      },
    }));
  };

  const handleChangeLimitNorminative = (limit) => {
    setFilters((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        page_norminative: 1,
        limit_norminative: limit,
      },
    }));
  };

  const handleChangePageCollateral = (page) => {
    setFilters((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        page_collateral: page,
      },
    }));
  };

  const handleChangeLimitColloateral = (limit) => {
    setFilters((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        page_collateral: 1,
        limit_collateral: limit,
      },
    }));
  };

  const queryClient = useQueryClient();

  const mutateSubmitExecutor = useMutation({
    mutationFn: putAgencyDisbursementExecutor,
  });
  const { data: executorDetail, isLoading: executorIsLoading } = useQuery(
    ["AGENCY_DISBURSEMENT_EXECUTOR_DETAIL", id, filters],
    getAgencyDisbursementExecutorDetail,
    {
      select: (data) => data?.data?.data,
      enabled: disbursementType === "review",
    }
  );

  const mutateSubmitDirection = useMutation({
    mutationFn: putAgencyDisbursementDirection,
  });
  const { data: directionDetail, isLoading: directionIsLoading } = useQuery(
    ["AGENCY_DISBURSEMENT_DIRECTION_DETAIL", id, filters],
    getAgencyDisbursementDirectionDetail,
    {
      select: (data) => data?.data?.data,
      enabled: disbursementType === "agreement",
    }
  );

  const isLoading =
    disbursementType === "review" ? executorIsLoading : directionIsLoading;

  const fileMutation = useMutation(async (file) => {
    const validFileTypes = [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
    ];
    try {
      if (validFileTypes.includes(file.file.type)) {
        const response = await fileUpload(file);
        methods.clearErrors("sprint_file_temp");
        if (file?.controllerName === "sprint_file_temp") {
          methods.register("sprint_file");
          methods.setValue("sprint_file", response.data.data.id);
        }
      } else {
        methods.setError("sprint_file_temp", {
          type: "custom",
          message: "File harus berupa dokumen Word atau PDF",
        });
      }
    } catch (error) {
      throw error;
    }
  });

  useEffect(() => {
    if (!isEmpty(executorDetail)) {
      const promises = [Promise.resolve()];
      const {
        verification_result,
        sprint_file: sprintFile,
        is_continue,
        notes,
      } = executorDetail || {
        sprint_file: null,
      };

      if (sprintFile && sprintFile !== "string") {
        promises.push(
          getFileFromServiceCB(sprintFile).then((data) => {
            return {
              formId: "sprint_file_temp",
              type: data?.mimeType,
              ...data,
            };
          })
        );
      }

      if (promises?.length > 0) {
        Promise.all(promises).then((values) => {
          const obj = {
            ...verification_result,
            sprintFile,
            is_continue,
            notes,
          };

          values.map((x) => {
            if (!isEmpty(x)) {
              obj[x.formId] = x;
            }
          });

          methods.reset(obj);
        });
      }
    }
  }, [executorDetail, methods]);

  useEffect(() => {
    if (!isEmpty(directionDetail)) {
      const promises = [Promise.resolve()];
      const {
        verification_result,
        sprint_file: sprintFile,
        is_continue,
        notes,
      } = directionDetail || {
        sprint_file: null,
      };

      if (sprintFile && sprintFile !== "string") {
        promises.push(
          getFileFromServiceCB(sprintFile).then((data) => {
            return {
              formId: "sprint_file_temp",
              type: data?.mimeType,
              ...data,
            };
          })
        );
      }

      if (promises?.length > 0) {
        Promise.all(promises).then((values) => {
          const obj = {
            ...verification_result,
            sprintFile,
            is_continue,
            notes,
          };

          values.map((x) => {
            if (!isEmpty(x)) {
              obj[x.formId] = x;
            }
          });

          methods.reset(obj);
        });
      }
    }
  }, [directionDetail, methods]);

  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

  const handleClickSubmit = methods.handleSubmit((values) => {
    console.log(values, "HERE");
    setIsOpenConfirmationModal((prev) => !prev);
  });

  const onSubmit = () => {
    const values = methods.getValues();

    delete values.sprint_file_temp;

    mutateSubmitExecutor.mutate(
      {
        id,
        data: values,
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Data berhasil dikirim!",
          });
          queryClient.invalidateQueries();
          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({
            variant: "success",
            message: "Data gagal dikirim!",
          });
        },
      }
    );
  };

  const onSubmitDirection = (status) => {
    mutateSubmitDirection.mutate(
      {
        id,
        params: { is_approve: status },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar({ variant: "success", message: "Berhasil Disubmit" });
          queryClient.invalidateQueries();
          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({ variant: "error", message: "Gagal Disubmit" });
        },
      }
    );
  };

  return (
    <div className="space-y-6 mb-40">
      <BreadCrumbs
        routes={[
          {
            label: "Permohonan Lembaga Penyalur",
            path: "/institutions/disbursment",
          },
          {
            label: "Detail Lembaga",
            path: `/institutions/disbursment/`,
          },
        ]}
      />

      {isLoading ? (
        <div className="flex justify-center p-4">
          <Spinner theme={"primary"} />
        </div>
      ) : (
        <FormProvider {...methods}>
          <DistributionInformation
            data={executorDetail ?? directionDetail}
            handleChangePageNorminative={handleChangePageNorminative}
            handleChangeLimitNorminative={handleChangeLimitNorminative}
            handleChangePageCollateral={handleChangePageCollateral}
            handleChangeLimitColloateral={handleChangeLimitColloateral}
          />
          <ResultVerification isView={isView} />
          <CardForm label="Upload Sprint yang Sudah di Tandatangani">
            <div className="w-full">
              <InputFormUpload
                controllerName={`sprint_file_temp`}
                label={"Upload Sprint yang Sudah di Tandatangani"}
                uploadFile={fileMutation}
                disabled={isView}
                fileType="PDF, Docx"
              />
            </div>
          </CardForm>
          <CardForm label="Simpulan">
            <div className="space-y-5">
              <InputFormRadio
                controllerName={"is_continue"}
                values={[
                  {
                    label: "Bisa dilanjutkan untuk proses Penyaluran",
                    value: true,
                  },
                  {
                    label: "Tidak bisa dilanjutkan untuk proses penyaluran",
                    value: false,
                  },
                ]}
                disabled={isView}
              />

              <InputForm
                label="&nbsp;"
                controllerName={"notes"}
                className={"rounded-md w-full p-3 border"}
                textArea={true}
                rows={5}
                disabled={isView}
              />
            </div>
          </CardForm>
          {disbursementType === "review" ? (
            <BottomFormAction
              // backButtonAction={handleBack}
              submitActionButton={handleClickSubmit}
              disableDrafButton={false}
              hideSubmit={isView}
              hideDraft={true}
              submitButtonProps={{
                type: "submit",
                label: "Submit",
              }}
              // drafButtonAction={handleDraft}
              disableButtonSubmit={false}
              // hideDraft={true}
              className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
            />
          ) : (
            <div className="sticky bottom-4 flex justify-between w-full p-5 bg-white border rounded-md">
              <Button
                className="p-4 border rounded-lg"
                type="button"
                label={
                  <div className="flex items-center gap-2 text-sm font-medium">
                    <RiArrowLeftLine />
                    Kembali
                  </div>
                }
                onClick={() => navigate(-1)}
              />

              {((disbursementType === "agreement" && searchParams.get("status") === "2") || (disbursementType !== "agreement" && searchParams.get("status") !== "2" && !isView)) ? (
                <div className="flex gap-2">
                  <ModalConfirm
                    type="reject"
                    onSubmit={() => onSubmitDirection(false)}
                  />
                  <ModalConfirm
                    type="approve"
                    onSubmit={() => onSubmitDirection(true)}
                  />
                </div>
              ) : null}

              {/* {!(disbursementType === "agreement" && searchParams.get("status") > 2 ) ? !isView ? (
                <div className="flex gap-2">
                  <ModalConfirm
                    type="reject"
                    onSubmit={() => onSubmitDirection(false)}
                  />
                  <ModalConfirm
                    type="approve"
                    onSubmit={() => onSubmitDirection(true)}
                  />
                </div>
              ) : null : null} */}
            </div>
          )}
        </FormProvider>
      )}

      <ConfirmationModal
        isOpen={isOpenConfirmationModal}
        setOpen={setIsOpenConfirmationModal}
        // isLoading={mutation.status === "loading"}
        onSubmit={onSubmit}
      />
    </div>
  );
};

const ConfirmationModal = ({
  isOpen,
  setOpen,
  isLoading = false,
  onSubmit,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      setOpen={setOpen}
      manual
      onOpenChange={() => setOpen(false)}
    >
      <ModalHeader
        noBorder
        hideClose
        title={
          <div className="flex items-center gap-4">
            <div className="w-12 h-12 rounded-full flex items-center justify-center text-orange-700 bg-orange-100 border-4 border-orange-50">
              <RiInformationLine className="text-base" size={24} />
            </div>
            Kirim Persetujuan
          </div>
        }
      />

      <ModalBody className="space-y-4">
        <p className="text text-[#475467]">
          Anda yakin akan mengirimkan persetujuan verifikasi data Negosiasi ini?
        </p>
        <div className="flex items-center gap-4">
          <ModalClose>
            <Button
              label="Tidak"
              block
              disabled={isLoading}
              className="w-full border hover:bg-gray-50"
            />
          </ModalClose>
          <Button
            type="button"
            onClick={onSubmit}
            label={
              isLoading ? (
                <>
                  <Spinner /> Loading...
                </>
              ) : (
                "Ya"
              )
            }
            color="primary"
            block
            className="w-full bg-primary-600 hover:bg-primary-700 text-white"
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

const ModalConfirm = ({ type, onSubmit }) => {
  const params = useParams();

  return (
    <Modal
      trigger={
        type === "approve" ? (
          <Button
            theme="primary"
            className="p-4 border rounded-lg"
            label={
              params.disbursmentType === "review" ? (
                <div className="flex items-center gap-2">Submit</div>
              ) : (
                <div className="flex items-center gap-2">
                  <IoMdCheckmarkCircleOutline />
                  Terima Verifkasi
                </div>
              )
            }
          />
        ) : (
          <Button
            theme={"error"}
            className="p-4 border rounded-lg"
            label={
              <div className="flex items-center gap-2">
                <IoMdCloseCircleOutline />
                Tolak Verifkasi
              </div>
            }
          />
        )
      }
    >
      <ModalHeader
        noBorder
        hideClose
        title={
          <div className="flex items-center gap-4">
            <div className="w-12 h-12 rounded-full flex items-center justify-center text-orange-700 bg-orange-100 border-4 border-orange-50">
              <RiInformationLine className="text-base" size={24} />
            </div>
            <p className="text-lg font-semibold">
              {type === "approve" ? "Kirim Persetujuan" : "Tolak Persetujuan"}
            </p>
          </div>
        }
      />
      <ModalBody className="space-y-4">
        {type === "approve" ? (
          <div className="text-primary-25">
            Anda yakin akan mengirimkan persetujuan verifikasi data untuk
            Pencairan Ini?, Data Ini Akan Dikirimkan Ke Direktur Penyaluran
            Untuk Di Review Setelah Anda Menyetujuinya
          </div>
        ) : (
          <div className="text-primary-25">
            Anda yakin akan menolak persetujuan verifikasi data untuk Pencairan
            Ini?, Data Ini Tidak Akan Dikirimkan Ke Direktur Penyaluran Untuk Di
            Review Setelah Anda Menyetujuinya
          </div>
        )}
        <div className="grid grid-cols-2 gap-4 !mt-6">
          <ModalClose>
            <Button label="Tidak" theme="secondary" block className="w-full" />
          </ModalClose>
          <Button
            label={"Ya"}
            theme="primary"
            block
            className="w-full"
            onClick={onSubmit}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DetailDisbursment;
