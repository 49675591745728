import { useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";

import { formatCurrency, getErrorMessage } from "helpers";
import Badge from "components/atoms/Badge";
import CardForm from "components/molecules/CardForm";
import { InputForm, InputFormRadio } from "components";
import Checkbox from "components/atoms/Checkbox";
import Table, { TableWrapper } from "components/atoms/Table";
import { CardPrimary } from "components/atoms/Card";

import VerificationCollateralModal from "../../VerificationCollateralModal";
import UploadSamplingModal from "../../UploadSamplingModal";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { enqueueSnackbar } from "notistack";
import {
  getApplicantCollateral,
  getApplicantTallySheet,
} from "services/fdb/field-analis";
import { isArray } from "lodash";

const statusEnumTally = {
  3: "error",
  2: "success",
  1: "warning",
};

const statusEnumCollateral = {
  3: "error",
  2: "success",
  1: "warning",
};

const DataAssurance = ({ type, data, serviceType }) => {
  const methods = useFormContext();
  const { applicantId } = useParams();

  const { data: collateralData, isLoading: isLoadingCollateral } = useQuery({
    queryKey: ["field-analyst-applicant-collateral", applicantId],
    queryFn: async () => {
      const { data } = await getApplicantCollateral(applicantId);
      return data;
    },
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    retry: false,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  const { data: tallySheetData, isLoading: isLoadingTallySheet } = useQuery({
    queryKey: ["field-analyst-applicant-tally-sheet", applicantId],
    queryFn: async () => {
      const { data } = await getApplicantTallySheet(applicantId);
      return data;
    },
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    retry: false,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "type",
        title: "Jenis Pohon",
      },
      {
        key: "area",
        title: "Keliling (CM)",
      },
      {
        key: "condition",
        title: "Kondisi",
      },
      {
        key: "price",
        title: "Harga",
        render: ({ item }) => {
          return formatCurrency(item.price);
        },
      },
      {
        key: "status",
        title: "Status",
        alignment: "center",
        render: ({ item }) => {
          return (
            <Badge color={statusEnumTally[item?.tabulation_status || "1"]}>
              {item?.tabulation_status_string}
            </Badge>
          );
        },
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => {
          return (
            <UploadSamplingModal
              data={item}
              status={item?.tabulation_status || "1"}
              serviceType={serviceType}
            />
          );
        },
      },
    ];
  }, [serviceType]);

  const headersAdditional = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "collateral_type",
        title: "Bentuk Jaminan",
      },
      {
        key: "collateral_fee",
        title: "Nilai Jaminan",
        render: ({ item, index, values }) => {
          return <p>{formatCurrency(item.collateral_fee)}</p>;
        },
      },
      {
        key: "status",
        title: "Status Jaminan",
        alignment: "center",
        render: ({ item }) => {
          return (
            <Badge color={statusEnumCollateral[item?.tabulation_status || "1"]}>
              {item?.tabulation_status_string}
            </Badge>
          );
        },
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => {
          return (
            <VerificationCollateralModal
              data={item}
              status={item?.tabulation_status || "1"}
              serviceType={serviceType}
            />
          );
        },
      },
    ];
  }, [serviceType]);

  const verificationResultAssurance = methods.watch(
    "collateral_tabulation_conclusion_verification"
  );

  const collateralTabulationNotesVerificatorField = useWatch({
    control: methods.control,
    name: "collateral_tabulation_notes_verificator",
  });

  const estimatedCollateral = useMemo(() => {
    if (collateralData?.length > 0) {
      return collateralData.reduce(
        (accumulator, current) => accumulator + current.collateral_fee,
        0
      );
    }
    return 0;
  }, [collateralData]);

  const estimatedTallySheet = useMemo(() => {
    if (tallySheetData?.length > 0) {
      return tallySheetData.reduce(
        (accumulator, current) => accumulator + current.price,
        0
      );
    }
    return 0;
  }, [tallySheetData]);

  useEffect(() => {
    const totalValues = estimatedCollateral + estimatedTallySheet;
    methods.setValue("total_jaminan", formatCurrency(totalValues));
  }, [estimatedCollateral, estimatedTallySheet]);

  return (
    <CardForm label="Data Jaminan">
      <div className="bg-[#E1F1D6] my-2 p-3 w-full">
        <p className="font-bold text-[#01A24A]">Jaminan Usaha Kehutanan</p>
      </div>
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div>
            <p className="font-semibold">Nama</p>
            <p>{data?.tally_sheet_name || "-"}</p>
          </div>
          <div>
            <p className="font-semibold">Blok</p>
            <p>{data?.tally_sheet_block || "-"}</p>
          </div>
          <div>
            <p className="font-semibold">Luas</p>
            <p>
              {data?.tally_sheet_wide ? `${data?.tally_sheet_wide} m2` : "-"}
            </p>
          </div>
          <div>
            <p className="font-semibold">Jumlah Pohon</p>
            <p>{data?.tally_sheet_total_tree || "-"}</p>
          </div>
          <div>
            <p className="font-semibold">No SPPT</p>
            <p>{data?.tally_sheet_sppt || "-"}</p>
          </div>
          <div>
            <p className="font-semibold">Atas Nama</p>
            <p>{data?.tally_sheet_owner || "-"}</p>
          </div>
          <div>
            <InputForm
              controllerName={"total_jaminan"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Total Jaminan"}
              placeholder={""}
              disabled
            />
          </div>
          <div>
            <div className="font-semibold">Memenuhi Persyaratan Jaminan</div>
            <Checkbox
              controllerName={`collateral_meet_recruitment`}
              label={() => <p>{"Terpenuhi sebanyak 125%"}</p>}
              inputWrapperClass={
                "bg-[#EEFFF4] py-3 flex gap-4 items-start rounded-lg"
              }
            />
          </div>
        </div>
        <div className=" my-1 py-2 w-full">
          <p className="font-bold text-[#01A24A]">Survei Jaminan</p>
        </div>
        <InputForm
          controllerName={"collateral_condition_tree"}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          label={"Kondisi Umum Pohon"}
          placeholder={"Kondisi Umum Pohon"}
        />
        <InputForm
          controllerName={"collateral_numbering_tree"}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          label={"Kondisi Penomoran Pohon"}
          placeholder={"Kondisi Penomoran Pohon"}
        />
        <InputForm
          controllerName={"collateral_street_access"}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          label={"Akses Jalan"}
          placeholder={"Akses Jalan"}
        />
        <InputForm
          controllerName={"collateral_minimum_height"}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          label={"Tinggi Minimum"}
          placeholder={"Tinggi Minimum"}
          onKeyPress={(event) => {
            const ASCIICode = event.which ? event.which : event.keyCode;
            if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
              return;
            event.preventDefault();
          }}
          isNumber={true}
          rightIcon="Meter"
        />
        <div className=" my-1 py-2 w-full">
          <p className="font-bold text-[#01A24A]">Daftar Pohon</p>
        </div>
        <TableWrapper className="w-full">
          <Table
            headers={headers}
            items={isArray(tallySheetData) ? tallySheetData : []}
            isLoading={isLoadingTallySheet}
          />
        </TableWrapper>
      </div>
      <div className="bg-[#E1F1D6] my-5 p-3 w-full">
        <p className="font-bold text-[#01A24A]">Jaminan Tambahan</p>
      </div>
      <TableWrapper className="w-full">
        <Table
          headers={headersAdditional}
          items={isArray(collateralData) ? collateralData : []}
          isLoading={isLoadingCollateral}
        />
      </TableWrapper>
      <CardPrimary label={"Tabulasi Jaminan"} noBg>
        <div className="grid grid-cols-1 lg:grid-cols-1 gap-6">
          <div>
            <div className="flex gap-4">
              <InputFormRadio
                required
                label={"Apakah Perlu Simpulan dan Catatan Verifikator?"}
                controllerName={"collateral_tabulation_notes_verificator"}
                values={[
                  {
                    label: "Ya",
                    value: true,
                  },
                  {
                    label: "Tidak",
                    value: false,
                  },
                ]}
              />
            </div>
          </div>
          {collateralTabulationNotesVerificatorField !== "" &&
            collateralTabulationNotesVerificatorField === true && (
              <InputForm
                controllerName="collateral_tabulation_notes_verificator_desc"
                label="Simpulan Data Catatan Verifikator"
                placeholder="Simpulan Data Catatan Verifikator"
                wrapperClass="col-span-2"
                className="rounded-md w-full px-4 py-3 border"
                textArea
              />
            )}
          <div className="border rounded-md px-4 py-3">
            <InputFormRadio
              required
              label={"Kesimpulan Verifikasi"}
              controllerName={`collateral_tabulation_conclusion_verification`}
              className="flex items-center justify-between gap-4"
              values={[
                {
                  label: (
                    <>
                      <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                      Data Terverifikasi
                    </>
                  ),
                  value: true,
                },
                {
                  label: (
                    <>
                      <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                      Data Belum Sesuai/ Ada Kesalahan
                    </>
                  ),
                  value: false,
                },
              ]}
            />
            {verificationResultAssurance !== "" &&
              verificationResultAssurance === false && (
                <InputForm
                  controllerName={
                    "collateral_tabulation_conclusion_verification_desc"
                  }
                  textArea={true}
                  label={"Jelaskan kesalahan/ ketidak sesuaian datanya"}
                  className={"w-full rounded-md border p-3"}
                  wrapperClass={"mt-5"}
                />
              )}
          </div>
        </div>
      </CardPrimary>
    </CardForm>
  );
};

export default DataAssurance;
