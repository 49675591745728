import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getListVerificator = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-verificator`,
    { params: queryKey[1] }
  );
};

export const getDetailVerificator = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-verificator/${queryKey[1]}`
  );
};

export const updateVerificator = ({ id, params, data }) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/agency-verificator/${id}`,
    data,
    { params }
  );
};
