import CardForm from "components/molecules/CardForm";
import UploadedFileAsync from "components/molecules/UploadFileAsync";
import dayjs from "dayjs";
import React from "react";

const RequestDetail = ({ data }) => {
  return (
    <CardForm label="Detail Permohonan">
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4">
        <div className="flex flex-col gap-2">
          <p className="font-semibold">Nomor Risalah</p>
          {/* <p>ABC/RisalahFDB/BPDLH.3/20xx</p> */}
          <p>{data?.number}</p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-semibold">Tanggal terbit risalah</p>
          {/* <p>10 Februari 2023</p> */}
          <p>{dayjs(data?.created_at).format("DD MMMM YYYY")}</p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-semibold">Layanan</p>
          {/* <p>Layanan A</p> */}
          <p>{data?.service === "null" ? "-" : data?.service}</p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-semibold">Tanggal Permohonan</p>
          <p>10 Februari 2023</p>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2">
        {data?.assessment_file && (
          <UploadedFileAsync id={data?.assessment_file} />
        )}
      </div>
    </CardForm>
  );
};

export default RequestDetail;
