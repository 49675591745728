import MemberInGroup from "pages/RequestList/DeskAnalyst";
import CandidateDebiturDetail from "pages/RequestList/DeskAnalyst/DebtorDetail";
import MemberInGroupDetail from "pages/RequestList/DeskAnalyst/Detail";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    routeType: "private",
    path: "/member-in-group",
    element: MemberInGroup,
    exact: true,
    sidebar: true,
    key: "desk_analyst",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/member-in-group/:id",
    element: MemberInGroupDetail,
    exact: true,
    sidebar: true,
    key: "desk_analyst",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/member-in-group/:id/debitor/:debitorId",
    element: CandidateDebiturDetail,
    exact: true,
    sidebar: true,
    key: "desk_analyst",
    type: "canUpdate"
  },
];
