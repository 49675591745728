import { RESET_COMPLETED_STATUS, UPDATE_COMPLETED_STATUS } from "../actions";

const initialState = {
  data: {
    profil_umum: false,
    profil_organisasi: false,
    sumber_daya: false,
    profil_keuangan: false,
    pengalaman_program: false,
    safeguards: false,
  },
};

export const completedStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COMPLETED_STATUS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    case RESET_COMPLETED_STATUS:
      return {
        ...state,
        data: {
          profil_umum: false,
          profil_organisasi: false,
          sumber_daya: false,
          profil_keuangan: false,
          pengalaman_program: false,
          safeguards: false,
        },
      };
    default:
      return state;
  }
};
