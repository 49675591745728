import CardForm from "components/molecules/CardForm";
import dayjs from "dayjs";
import React from "react";

const DetailGroup = ({ data }) => {
  return (
    <CardForm label="Detail Kelompok">
      <div className="grid grid-cols-3">
        <div>
          <p className="font-semibold">Nama Kelompok</p>
          <p>{data?.name}</p>
        </div>
        <div>
          <p className="font-semibold">Tanggal Berdiri Kelompok</p>
          <p>
            {data?.founding_date
              ? dayjs(data?.founding_date).format("DD/MM/YYYY")
              : "-"}
          </p>
        </div>
        <div>
          <p className="font-semibold">Batas akhir izin/perjanjian akses</p>

          <p>-</p>
        </div>
      </div>
      <div>
        <p className="font-semibold">Alamat Kelompok</p>
        <p>{data?.address}</p>
      </div>
      <hr />
      <div className="grid grid-cols-4 gap-4">
        <div>
          <p className="font-semibold">Ketua</p>
          <p>{data?.leader}</p>
        </div>
        <div>
          <p className="font-semibold">Bendahara</p>
          <p>{data?.treasurer}</p>
        </div>
        <div>
          <p className="font-semibold">Sekretaris</p>
          <p>{data?.secretary}</p>
        </div>
        <div>
          <p className="font-semibold">Batas Akhir Kepengurusan</p>
          <p>
            {data?.end_date_management
              ? dayjs(data?.end_date_management).format("DD/MM/YYYY")
              : "-"}
          </p>
        </div>
        <div>
          <p className="font-semibold">Nomor kontak Pengurus</p>
          <p>{data?.phone}</p>
        </div>
        <div>
          <p className="font-semibold">Jumlah anggota</p>
          <p>{data?.total_member}</p>
        </div>
        <div>
          <p className="font-semibold">Jumlah anggota Pemohon</p>
          <p>{data?.total_applicant}</p>
        </div>
        <div>
          <p className="font-semibold">Total nilai permohonan</p>
          <p>Rp {Math.floor(data?.total_value).toLocaleString()}</p>
        </div>
        <div>
          <p className="font-semibold">Nama Pendamping</p>
          <p>{data?.monitoring_institution}</p>
        </div>
        <div>
          <p className="font-semibold">Afiliasi pendamping</p>
          <p>{data?.affiliate}</p>
        </div>
      </div>
    </CardForm>
  );
};

export default DetailGroup;
