/* eslint-disable import/no-anonymous-default-export */
import CommitteeMinutesApprovalDD from "pages/FDB/Committe/CommitteeMeetingApproval/DirectDistribution";
import CommitteeMinutesApprovalDDDetail from "pages/FDB/Committe/CommitteeMeetingApproval/DirectDistribution/Detail";
import MeetingAgenda from "pages/FDB/Committe/MeetingAgenda";
import MeetingAgendaDetail from "pages/FDB/Committe/MeetingAgenda/Detail";
import MeetingAgendaForm from "pages/FDB/Committe/MeetingAgenda/Form";
import PendapatKomite from "pages/FDB/Committe/PendapatKomite";
import PendapatKomiteDetail from "pages/FDB/Committe/PendapatKomite/Detail";
import RisalahDetail from "pages/Risalah/Detail";
import RisalahDebtorDetail from "pages/Risalah/Detail/Debtor";
// import { lazy } from "react";

// const MeetingAgenda = lazy(() => import("pages/FDB/Committe/MeetingAgenda"));

export default [
  {
    routeType: "private",
    path: "/committee/meeting-agenda",
    element: (props) => <MeetingAgenda {...props} fdbType="perorangan" />,
    exact: true,
    sidebar: true,
    key: "committee_meeting_agenda",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/committee/meeting-agenda/form",
    element: (props) => <MeetingAgendaForm {...props} fdbType="perorangan" />,
    exact: true,
    sidebar: true,
    key: "committee_meeting_agenda",
    type: "canCreate"
  },
  {
    routeType: "private",
    path: "/committee/meeting-agenda/:id",
    element: (props) => <MeetingAgendaDetail {...props} fdbType="perorangan" />,
    exact: true,
    sidebar: true,
    key: "committee_meeting_agenda",
    type: "canUpdate"
  },
  {
    routeType: "private",
    path: "/committee/pendapat-komite",
    element: (props) => <PendapatKomite {...props} fdbType="perorangan" />,
    exact: true,
    sidebar: true,
    key: "pendapat_komite",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/committee/pendapat-komite/:id",
    element: (props) => <PendapatKomiteDetail {...props} fdbType="perorangan" />,
    exact: true,
    sidebar: true,
    key: "pendapat_komite",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/committee/pendapat-komite/:id/risalah/:idRisalah",
    element: (props) => <RisalahDetail {...props} fdbType="perorangan" />,
    exact: true,
    sidebar: true,
    key: "pendapat_komite",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/committee/pendapat-komite/:id/risalah/:idRisalah/debitor/:idDebtor",
    element: (props) => <RisalahDebtorDetail {...props} fdbType="perorangan" />,
    exact: true,
    sidebar: true,
    key: "pendapat_komite",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/committee/pendapat-komite/risalah/:idRisalah",
    element: (props) => <RisalahDetail {...props} fdbType="perorangan" />,
    exact: true,
    sidebar: true,
    key: "pendapat_komite",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/committee/pendapat-komite/risalah/:idRisalah/debitor/:idDebtor",
    element: (props) => <RisalahDebtorDetail {...props} fdbType="perorangan" />,
    exact: true,
    sidebar: true,
    key: "pendapat_komite",
    type: "canView"
  },

  {
    routeType: "private",
    path: "/committee/approval",
    element: CommitteeMinutesApprovalDD,
    exact: true,
    sidebar: true,
    key: "committee_approval",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/committee/approval/:id",
    element: CommitteeMinutesApprovalDDDetail,
    exact: true,
    sidebar: true,
    key: "committee_approval",
    type: "canUpdate"
  },
];
