import BeritaAcara from "pages/BeritaAcara";
import CreateBeritaAcara from "pages/BeritaAcara/CreateForm";
import DetailBeritaAcara from "pages/BeritaAcara/detail";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    routeType: "private",
    path: "/member-in-group-ba",
    element: BeritaAcara,
    exact: true,
    sidebar: true,
    key: "member_in_group_ba",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/create-ba",
    element: CreateBeritaAcara,
    exact: true,
    sidebar: true,
    key: "member_in_group_ba",
    type: "canCreate"
  },
  {
    routeType: "private",
    path: "/member-in-group-ba/:id",
    element: DetailBeritaAcara,
    exact: true,
    sidebar: true,
    key: "member_in_group_ba",
    type: "canView"
  },
  // {
  //   routeType: "private",
  //   path: "/member-in-group-ba/:id/debitor/:debitorId",
  //   element: CandidateDebiturDetail,
  //   exact: true,
  //   sidebar: true,
  // },
];
