import React, { useMemo, useState, useCallback, useEffect } from "react";
import Button from "components/atoms/Button/Button";
import CardForm from "components/molecules/CardForm";
import { TabsContent } from "components/atoms/Tabs";
import {
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiEyeLine,
} from "react-icons/ri";
import { InputForm, InputFormRadio } from "components";
import { useFormContext, useFieldArray } from "react-hook-form";
import UploadedFile from "components/molecules/UploadedFile";
import Table, { TableWrapper } from "components/atoms/Table";
import { customStyles } from "components/atoms/Select";
import RichEditorForm from "components/molecules/RichEditorForm";
import { formatCurrency } from "helpers";
import { isEmpty, startCase, toLower } from "lodash";
import withFileService from "hocs/with-file-service";
import Select from "react-select";
import UploadedFileAsync from "components/molecules/UploadFileAsync";
import { BiPlus, BiTrash } from "react-icons/bi";
import { useSearchParams } from "react-router-dom";
import InputCurrencyForm from "components/v2/shared/input-currency-form";
import { getFile } from "services/fileService";

const UnitOptions = [
  { value: "Minggu", label: "Minggu" },
  { value: "Bulan", label: "Bulan" },
  { value: "Tahun", label: "Tahun" },
];

const SchemeTypes = [
  { value: "Mingguan", label: "Mingguan" },
  { value: "Bulanan", label: "Bulanan" },
  { value: "Tahunan", label: "Tahunan" },
  { value: "Panen Bayar", label: "Panen Bayar" },
];

export const RequestForm = ({ type, data }) => {
  const [searchParams] = useSearchParams();

  const isView = searchParams.get("status");

  return (
    <TabsContent value="form" className="mb-40">
      <FundedProspect
        type={type}
        data={data?.group_profile_member}
        isView={isView}
      />
      <AbleToPay
        type={type}
        data={data?.group_profile_member}
        isView={isView}
      />
      <DataAssurance
        type={type}
        data={
          data?.group_profile_member?.applicant_member?.request?.deferred ??
          data?.group_profile_member?.applicant_member?.request
            ?.non_timber_forest ??
          data?.group_profile_member?.applicant_member?.request
            ?.non_forest_commodities
        }
        isView={isView}
      />
      <LandDocuments
        type={type}
        data={
          data?.group_profile_member?.applicant_member?.request?.deferred ??
          data?.group_profile_member?.applicant_member?.request
            ?.non_timber_forest ??
          data?.group_profile_member?.applicant_member?.request
            ?.non_forest_commodities
        }
        isView={isView}
      />
      <DeskAnalystRecomendation type={type} isView={isView} />
    </TabsContent>
  );
};

const FundedProspect = ({ type, data, isView }) => {
  const [isEdit, setIsEdit] = useState(false);
  const methods = useFormContext();

  // const areaOptions = [
  //   {
  //     label: "Kawasan Hutan",
  //     value: "Kawasan Hutan",
  //   },
  // ];

  const lendingsFromProspect = methods.watch("tabulation_loan_value_adjusment");
  const verificationResultProspect = methods.watch("tabulation_verification");

  return (
    <CardForm label="Prospek Usaha Yang Dibiayai">
      {data?.applicant_member?.service_type === "Tunda Tebang" && (
        <>
          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">
              Permohonan Usaha Yang Dibiayai
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div>
              <p className="font-semibold">Jenis Usaha</p>
              <p>
                {data?.applicant_member?.request?.deferred?.business_type?.name}
              </p>
            </div>
            <div>
              <p className="font-semibold">Komoditas Usaha</p>
              <p>
                {
                  data?.applicant_member?.request?.deferred
                    ?.bussiness_commodities
                }
              </p>
            </div>
            <div>
              <p className="font-semibold">Lama Usaha</p>
              <p>
                {data?.applicant_member?.request?.deferred?.bussiness_duration}{" "}
                {
                  data?.applicant_member?.request?.deferred
                    ?.bussiness_duration_type
                }
              </p>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div>
              <p className="font-semibold">Nominal Permohonan</p>
              <p>
                {formatCurrency(
                  data?.applicant_member?.request?.deferred?.application_fee
                )}
              </p>
            </div>
            <div>
              <p className="font-semibold">Terbilang</p>
              <p>
                {
                  data?.applicant_member?.request?.deferred
                    ?.application_fee_spel
                }
              </p>
            </div>
            <div>
              <p className="font-semibold">Jangka Waktu</p>
              <p>
                {data?.applicant_member?.request?.deferred?.year_duration
                  ? `${data?.applicant_member?.request?.deferred?.year_duration} Tahun`
                  : "-"}
              </p>
            </div>
            <div>
              <p className="font-semibold">Skema Pembiayaan</p>
              <p>{data?.applicant_member?.request?.deferred?.finance_scheme}</p>
            </div>
            {/* <div className="col-span-4">
              <p className="font-semibold">Rencana jaminan</p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore Ut enim ad minim veniam,
                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo con Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatu
              </p>
            </div> */}
          </div>
          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">
              Riwayat pendapatan dari usaha yang dibiayai
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div>
              <p className="font-semibold">
                Produktivitas/Hasil Panen Terakhir
              </p>
              <p>
                {data?.applicant_member?.request?.deferred?.last_productivity}
              </p>
            </div>
            <div>
              <p className="font-semibold">Harga (Penjualan Terakhir)</p>
              <p>
                {formatCurrency(
                  data?.applicant_member?.request?.deferred?.last_price
                )}
              </p>
            </div>
            <div>
              <p className="font-semibold">Luas Lahan Yang digarap (m2)</p>
              <p>{data?.group_profile?.total_member_apply_area}</p>
            </div>
            <div>
              <p className="font-semibold">Omzet Usaha</p>
              <p>
                {formatCurrency(
                  data?.applicant_member?.request?.deferred?.revenue
                )}
              </p>
            </div>
            <div>
              <p className="font-semibold">HPP</p>
              <p>
                {formatCurrency(
                  data?.applicant_member?.request?.deferred?.cost_price
                )}
              </p>
            </div>
            <div>
              <p className="font-semibold">Pendapatan Bersih</p>
              <p>
                {formatCurrency(
                  data?.applicant_member?.request?.deferred?.net_income
                )}
              </p>
            </div>
            <div>
              <p className="font-semibold">Tujuan Pemasaran</p>
              <p>{data?.applicant_member?.request?.deferred?.market_target}</p>
            </div>
            <div>
              <p className="font-semibold">Mitra Usaha (jika ada)</p>
              <p>
                {data?.applicant_member?.request?.deferred
                  ?.bussiness_partner ? (
                  JSON.parse(
                    data?.applicant_member?.request?.deferred.bussiness_partner
                  )?.length > 0 ? (
                    <ul className="list-disc py-2 space-y-2 ">
                      {JSON.parse(
                        data?.applicant_member?.request?.deferred
                          .bussiness_partner
                      )?.map((x, key) => (
                        <li key={key}>{x}</li>
                      ))}
                    </ul>
                  ) : (
                    "-"
                  )
                ) : (
                  "-"
                )}
              </p>
            </div>
            <div>
              <p className="font-semibold">Tipe Usaha</p>
              <p>
                {data?.applicant_member?.request?.deferred
                  ?.bussiness_manage_type ?? "-"}
              </p>
            </div>
            <div>
              <p className="font-semibold">Siklus Usaha</p>
              <p>{`${
                data?.applicant_member?.request?.deferred?.bussiness_cycle ?? ""
              } ${
                data?.applicant_member?.request?.deferred
                  ?.bussiness_cycle_unit ?? ""
              }`}</p>
            </div>
            <div>
              <p className="font-semibold">Kuantitas Komoditas yg sudah ada</p>
              <p>-</p>
            </div>
            <div className="col-span-3">
              <p className="font-semibold">Tujuan Pengajuan Pinjaman </p>
              <p>
                {
                  data?.applicant_member?.request?.deferred
                    ?.purpose_loan_application
                }
              </p>
            </div>
            <div className="col-span-3">
              <p className="font-semibold">
                Penjelasan Tujuan Pengajuan Pinjaman
              </p>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    data?.applicant_member?.request?.deferred
                      ?.purpose_loan_application_desc,
                }}
              ></p>
            </div>
          </div>
          {data?.applicant_member?.request?.deferred?.income_statement_file ? (
            <div className="grid grid-cols-2 gap-4">
              <UploadedFileAsync
                id={
                  data?.applicant_member?.request?.deferred
                    ?.income_statement_file
                }
                name="Laporan Laba Rugi"
              />
            </div>
          ) : null}
        </>
      )}

      {data?.applicant_member?.service_type === "Hasil Hutan Bukan Kayu" && (
        <>
          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">
              Permohonan Usaha Yang Dibiayai
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div>
              <p className="font-semibold">Jenis Usaha</p>
              <p>
                {
                  data?.applicant_member?.request?.non_timber_forest
                    ?.hhbk_commodities
                }
              </p>
            </div>
            <div>
              <p className="font-semibold">Komoditas Usaha</p>
              <p>
                {
                  data?.applicant_member?.request?.non_timber_forest
                    ?.manage_activity_type
                }
              </p>
            </div>
            <div>
              <p className="font-semibold">Lama Usaha</p>
              <p>
                {
                  data?.applicant_member?.request?.non_timber_forest
                    ?.bussiness_duration
                }{" "}
                {
                  data?.applicant_member?.request?.non_timber_forest
                    ?.bussiness_duration_type
                }
              </p>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div>
              <p className="font-semibold">Nominal Permohonan</p>
              <p>
                {formatCurrency(
                  data?.applicant_member?.request?.non_timber_forest
                    ?.application_fee
                )}
              </p>
            </div>
            <div>
              <p className="font-semibold">Terbilang</p>
              <p>
                {startCase(
                  toLower(
                    data?.applicant_member?.request?.non_timber_forest
                      ?.application_fee_spel
                  )
                )}
              </p>
            </div>
            <div>
              <p className="font-semibold">Jangka Waktu</p>
              <p>
                {data?.applicant_member?.request?.non_timber_forest
                  ?.year_duration
                  ? `${data?.applicant_member?.request?.non_timber_forest?.year_duration} Tahun`
                  : "-"}
              </p>
            </div>
            <div>
              <p className="font-semibold">Skema Pembiayaan</p>
              <p>
                {
                  data?.applicant_member?.request?.non_timber_forest
                    ?.finance_scheme
                }
              </p>
            </div>
            {/* <div className="col-span-4">
              <p className="font-semibold">Rencana jaminan</p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore Ut enim ad minim veniam,
                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo con Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatu
              </p>
            </div> */}
          </div>
          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">
              Riwayat pendapatan dari usaha yang dibiayai
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div>
              <p className="font-semibold">
                Sumber Bahan Baku Hhbk Dan Lokasi On Farm/Off Farm
              </p>
              <p>
                {data?.applicant_member?.request?.non_timber_forest?.material}
              </p>
            </div>
            <div>
              <p className="font-semibold">Luas Lahan Yang digarap (m2)</p>
              <p>{data?.group_profile?.total_member_apply_area}</p>
            </div>
            <div>
              <p className="font-semibold">Harga jual terakhir</p>
              <p>
                {formatCurrency(
                  data?.applicant_member?.request?.non_timber_forest?.last_price
                )}
              </p>
            </div>

            <div className="grid grid-cols-2 col-span-1 lg:col-span-3 gap-6">
              <div>
                <p className="font-semibold">
                  Perkiraan omzet/harga jual/pendapatan kotor
                </p>
                <p>
                  {formatCurrency(
                    data?.applicant_member?.request?.non_timber_forest?.revenue
                  )}
                </p>
              </div>
              <div>
                <p className="font-semibold">
                  Perkiraan Biaya Produksi / HPP / Modal Yang Telah Dikeluarkan
                </p>
                <p>
                  {formatCurrency(
                    data?.applicant_member?.request?.non_timber_forest
                      ?.cost_price
                  )}
                </p>
              </div>

              <div>
                <p className="font-semibold">Perkiraan pendapatan bersih</p>
                <p>
                  {formatCurrency(
                    data?.applicant_member?.request?.non_timber_forest
                      ?.net_income
                  )}
                </p>
              </div>
              <div>
                <p className="font-semibold">Siklus Usaha Produksi</p>
                <p>{`${
                  data?.applicant_member?.request?.non_timber_forest
                    ?.bussiness_cycle ?? ""
                } ${
                  data?.applicant_member?.request?.non_timber_forest
                    ?.bussiness_cycle_unit ?? ""
                }`}</p>
              </div>
            </div>

            <div>
              <p className="font-semibold">Tujuan Pemasaran</p>
              <p>
                {
                  data?.applicant_member?.request?.non_timber_forest
                    ?.market_target
                }
              </p>
            </div>
            <div>
              <p className="font-semibold">Mitra Usaha (jika ada)</p>
              <p>
                {data?.applicant_member?.request?.non_timber_forest
                  ?.bussiness_partner ? (
                  JSON.parse(
                    data?.applicant_member?.request?.non_timber_forest
                      .bussiness_partner
                  )?.length > 0 ? (
                    <ul className="list-disc py-2 space-y-2 ">
                      {JSON.parse(
                        data?.applicant_member?.request?.non_timber_forest
                          .bussiness_partner
                      )?.map((x, key) => (
                        <li key={key}>{x}</li>
                      ))}
                    </ul>
                  ) : (
                    "-"
                  )
                ) : (
                  "-"
                )}
              </p>
            </div>

            <div className="col-span-3">
              <p className="font-semibold">Tujuan Pengajuan Pinjaman </p>
              <p>
                {
                  data?.applicant_member?.request?.non_timber_forest
                    ?.purpose_loan_application
                }
              </p>
            </div>
            <div className="col-span-3">
              <p className="font-semibold">
                Penjelasan Tujuan Pengajuan Pinjaman
              </p>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    data?.applicant_member?.request?.non_timber_forest
                      ?.purpose_loan_application_desc,
                }}
              ></p>
            </div>
          </div>
          {data?.applicant_member?.request?.non_timber_forest
            ?.income_statement_file ? (
            <div className="grid grid-cols-2 gap-4">
              <UploadedFileAsync
                id={
                  data?.applicant_member?.request?.non_timber_forest
                    ?.income_statement_file
                }
                name="Laporan Laba Rugi"
              />
            </div>
          ) : null}
        </>
      )}

      {data?.applicant_member?.service_type === "Komoditas Non Kehutanan" && (
        <>
          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">
              Permohonan Usaha Yang Dibiayai
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div>
              <p className="font-semibold">Jenis Usaha</p>
              <p>
                {
                  data?.applicant_member?.request?.non_forest_commodities
                    ?.bussiness_type
                }
              </p>
            </div>
            <div>
              <p className="font-semibold">Komoditas Usaha</p>
              <p>
                {
                  data?.applicant_member?.request?.non_forest_commodities
                    ?.bussiness_commodities
                }
              </p>
            </div>
            <div>
              <p className="font-semibold">Lama Usaha</p>
              <p>
                {
                  data?.applicant_member?.request?.non_forest_commodities
                    ?.bussiness_duration
                }{" "}
                {
                  data?.applicant_member?.request?.non_forest_commodities
                    ?.bussiness_duration_type
                }
              </p>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div>
              <p className="font-semibold">Nominal Permohonan</p>
              <p>
                {formatCurrency(
                  data?.applicant_member?.request?.non_forest_commodities
                    ?.application_fee
                )}
              </p>
            </div>
            <div>
              <p className="font-semibold">Terbilang</p>
              <p>
                {startCase(
                  toLower(
                    data?.applicant_member?.request?.non_forest_commodities
                      ?.application_fee_spel
                  )
                )}
              </p>
            </div>
            <div>
              <p className="font-semibold">Jangka Waktu</p>
              <p>
                {data?.applicant_member?.request?.non_forest_commodities
                  ?.year_duration
                  ? `${data?.applicant_member?.request?.non_forest_commodities?.year_duration} Tahun`
                  : "-"}
              </p>
            </div>
            <div>
              <p className="font-semibold">Skema Pembiayaan</p>
              <p>
                {
                  data?.applicant_member?.request?.non_forest_commodities
                    ?.finance_scheme
                }
              </p>
            </div>
            {/* <div className="col-span-4">
              <p className="font-semibold">Rencana jaminan</p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore Ut enim ad minim veniam,
                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo con Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatu
              </p>
            </div> */}
          </div>
          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">
              Riwayat pendapatan dari usaha yang dibiayai
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div>
              <p className="font-semibold">
                Sumber Bahan Baku Hhbk Dan Lokasi On Farm/Off Farm
              </p>
              <p>
                {
                  data?.applicant_member?.request?.non_forest_commodities
                    ?.material
                }
              </p>
            </div>
            <div>
              <p className="font-semibold">Luas Lahan Yang digarap (m2)</p>
              <p>{data?.group_profile?.total_member_apply_area}</p>
            </div>
            <div>
              <p className="font-semibold">Harga jual terakhir</p>
              <p>
                {formatCurrency(
                  data?.applicant_member?.request?.non_forest_commodities
                    ?.last_price
                )}
              </p>
            </div>

            <div className="grid grid-cols-2 col-span-1 lg:col-span-3 gap-6">
              <div>
                <p className="font-semibold">
                  Perkiraan omzet/harga jual/pendapatan kotor
                </p>
                <p>
                  {formatCurrency(
                    data?.applicant_member?.request?.non_forest_commodities
                      ?.revenue
                  )}
                </p>
              </div>
              <div>
                <p className="font-semibold">
                  Perkiraan Biaya Produksi / HPP / Modal Yang Telah Dikeluarkan
                </p>
                <p>
                  {formatCurrency(
                    data?.applicant_member?.request?.non_forest_commodities
                      ?.cost_price
                  )}
                </p>
              </div>

              <div>
                <p className="font-semibold">Perkiraan pendapatan bersih</p>
                <p>
                  {formatCurrency(
                    data?.applicant_member?.request?.non_forest_commodities
                      ?.net_income
                  )}
                </p>
              </div>
              <div>
                <p className="font-semibold">Siklus Usaha Produksi</p>
                <p>{`${
                  data?.applicant_member?.request?.non_forest_commodities
                    ?.bussiness_cycle ?? ""
                } ${
                  data?.applicant_member?.request?.non_forest_commodities
                    ?.bussiness_cycle_unit ?? ""
                }`}</p>
              </div>
            </div>

            <div>
              <p className="font-semibold">Tujuan Pemasaran</p>
              <p>
                {
                  data?.applicant_member?.request?.non_forest_commodities
                    ?.market_target
                }
              </p>
            </div>
            <div>
              <p className="font-semibold">Mitra Usaha (jika ada)</p>
              <p>
                {data?.applicant_member?.request?.non_forest_commodities
                  ?.bussiness_partner ? (
                  JSON.parse(
                    data?.applicant_member?.request?.non_forest_commodities
                      .bussiness_partner
                  )?.length > 0 ? (
                    <ul className="list-disc py-2 space-y-2 ">
                      {JSON.parse(
                        data?.applicant_member?.request?.non_forest_commodities
                          .bussiness_partner
                      )?.map((x, key) => (
                        <li key={key}>{x}</li>
                      ))}
                    </ul>
                  ) : (
                    "-"
                  )
                ) : (
                  "-"
                )}
              </p>
            </div>

            <div className="col-span-3">
              <p className="font-semibold">Tujuan Pengajuan Pinjaman </p>
              <p>
                {
                  data?.applicant_member?.request?.non_forest_commodities
                    ?.purpose_loan_application
                }
              </p>
            </div>
            <div className="col-span-3">
              <p className="font-semibold">
                Penjelasan Tujuan Pengajuan Pinjaman
              </p>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    data?.applicant_member?.request?.non_forest_commodities
                      ?.purpose_loan_application_desc,
                }}
              ></p>
            </div>
          </div>
          {data?.applicant_member?.request?.non_forest_commodities
            ?.income_statement_file ? (
            <div className="grid grid-cols-2 gap-4">
              <UploadedFileAsync
                id={
                  data?.applicant_member?.request?.non_forest_commodities
                    ?.income_statement_file
                }
                name="Laporan Laba Rugi"
              />
            </div>
          ) : null}
        </>
      )}

      <div className="border rounded-md p-5">
        <p className="font-bold text-xl mb-5">
          Tabulasi Prospek Usaha Yang Dibiayai
        </p>
        <div className="flex flex-col gap-3">
          <InputFormRadio
            label={"1. Usaha yang dibiayai bukan merupakan pencaharian utama"}
            labelClass={"w-full"}
            controllerName={`tabulation_financed_bussiness`}
            wrapperFieldClass="w-full"
            usingGrid={true}
            gridClassName="grid grid-cols-2 gap-4 w-full"
            className="flex items-center justify-between flex-wrap gap-2"
            required={true}
            disabled={isView}
            values={[
              {
                label: "Iya",
                value: true,
              },
              {
                label: "Tidak",
                value: false,
              },
            ]}
          />
          <InputFormRadio
            label={"2. Lama usaha melebihi minimal 2 tahun?"}
            labelClass={"w-full"}
            wrapperFieldClass="w-full"
            controllerName={`tabulation_bussiness_min_2years`}
            usingGrid={true}
            gridClassName="grid grid-cols-2 gap-4 w-full"
            className="flex items-center justify-between flex-wrap gap-2"
            disabled={isView}
            required={true}
            values={[
              {
                label: "Iya",
                value: true,
              },
              {
                label: "Tidak",
                value: false,
              },
            ]}
          />
          <InputFormRadio
            label={"3. Nilai Pendapatan dari usaha wajar?"}
            labelClass={"w-full"}
            wrapperFieldClass="w-full"
            controllerName={`tabulation_income_value`}
            usingGrid={true}
            gridClassName="grid grid-cols-2 gap-4 w-full"
            className="flex items-center justify-between flex-wrap gap-2"
            disabled={isView}
            required={true}
            values={[
              {
                label: "Iya",
                value: true,
              },
              {
                label: "Tidak",
                value: false,
              },
            ]}
          />
          <InputFormRadio
            label={
              "4. Apakah Penyesuaian nilai pinjaman berdasarkan prospek usaha Diperlukan?"
            }
            labelClass={"w-full"}
            controllerName={`tabulation_loan_value_adjusment`}
            wrapperFieldClass="w-full"
            usingGrid={true}
            gridClassName="grid grid-cols-2 gap-4 w-full"
            className="flex items-center justify-between flex-wrap gap-2"
            disabled={isView}
            required={true}
            values={[
              {
                label: "Iya",
                value: true,
              },
              {
                label: "Tidak",
                value: false,
              },
            ]}
          />
          {lendingsFromProspect !== "" && lendingsFromProspect === true && (
            // <InputForm
            //   label={"Berapa Nilai Penyesuaiannya"}
            //   required={true}
            //   controllerName={"tabulation_adjusment_value"}
            //   className="w-full rounded-md border p-3"
            //   disabled={isView}
            //   onKeyPress={(event) => {
            //     const ASCIICode = event.which ? event.which : event.keyCode;
            //     if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
            //       return;
            //     event.preventDefault();
            //   }}
            // />
            <InputCurrencyForm
              name="tabulation_adjusment_value"
              label="Berapa Nilai Penyesuaiannya"
              required
              prefix="Rp "
              disabled={isView}
            />
          )}
        </div>
      </div>
      <div className="border rounded-md px-4 py-3">
        <InputFormRadio
          label={"Kesimpulan Verifikasi"}
          controllerName={`tabulation_verification`}
          disabled={isView}
          className="flex items-center justify-between gap-4"
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Data Terverifikasi
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Data Belum Sesuai/ Ada Kesalahan
                </>
              ),
              value: false,
            },
          ]}
        />
        {verificationResultProspect !== "" &&
          verificationResultProspect === false && (
            <InputForm
              controllerName={"tabulation_verification_reason"}
              textArea={true}
              label={"Jelaskan kesalahan/ ketidak sesuaian datanya"}
              className={"w-full rounded-md border p-3"}
              wrapperClass={"mt-5"}
              disabled={isView}
            />
          )}
      </div>
    </CardForm>
  );
};

const AbleToPay = withFileService(
  ({ type, data, getFileFromService, isView }) => {
    const methods = useFormContext();
    const headers = useMemo(() => {
      return [
        {
          key: "index",
          title: "No",
          render: ({ index }) => index + 1,
        },
        {
          key: "type",
          title: "Jenis Usaha/Pekerjaan",
          render: ({ item }) => {
            return item?.other_business_type?.name;
          },
        },
        {
          key: "income",
          title: "Perkiraan Pendapatan",
          render: ({ item }) => {
            return formatCurrency(item.income);
          },
        },
        {
          key: "cycle_income",
          title: "Siklus Pendapatan",
          render: ({ item }) => {
            return <p>{`${item.income_cycle} ${item.income_cycle_unit}`}</p>;
          },
        },
      ];
    }, []);

    const estimatedIncome = data?.income_info.reduce(
      (accumulator, current) => accumulator + current.income,
      0
    );

    const lendingsFromAble = methods.watch("household_loan_value_adjusment");
    const verificationResultAble = methods.watch("household_verification");

    const [slipSalary, setSlipSalary] = useState();

    const getSalarySlipFile = useCallback(() => {
      if (slipSalary) return;
      if (!data?.salary_slip_file) return;
      getFileFromService(data?.salary_slip_file, {
        onSuccess: (res) => setSlipSalary(res),
        onError: (err) => console.log(err),
      });
    }, [data, getFileFromService, slipSalary]);

    // const getFile

    useEffect(() => {
      getSalarySlipFile();
    }, [getSalarySlipFile]);

    return (
      <CardForm label="Kemampuan Bayar">
        <div className="bg-[#E1F1D6] my-2 p-3 w-full">
          <p className="font-bold text-[#01A24A]">
            Pendapatan Selain Dari Usaha/Pekerjaan Lainnya
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <TableWrapper className="w-full">
            <Table
              headers={headers}
              items={data?.income_info ?? []}
              isLoading={false}
            />
          </TableWrapper>
          <div className="w-full bg-[#F2F9EC] p-4 rounded-md border-l-4 border-[#6CB745]">
            <p className="font-bold">Estimasi Total Pendapatan yang diterima</p>
            <p>{formatCurrency(estimatedIncome)}</p>
          </div>
          <div className="grid grid-cols-2">
            {slipSalary && (
              <UploadedFile
                size={`${(slipSalary?.size / 1024).toFixed(1)} KB`}
                filename={
                  "Dokumen Pendukung Pendapatan (Slip Gaji/Laporan Keuangan Usaha)"
                }
                routes={slipSalary.url}
              />
            )}
          </div>
        </div>
        <div className="bg-[#E1F1D6] my-5 p-3 w-full">
          <p className="font-bold text-[#01A24A]">
            Pengeluaran Rutin Rumah Tangga
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div>
            <p className="font-semibold">Pengeluaran Rutin Per Bulan</p>
            <p>{formatCurrency(data?.routine_outcome_per_month)}</p>
          </div>
          <div>
            <p className="font-semibold">Pengeluaran Terbesar</p>
            <p>{formatCurrency(data?.biggest_outcome)}</p>
          </div>
          <div>
            <p className="font-semibold">Penggunaan Pengeluaran Terbesar</p>
            <p>{data?.use_largest_outcome}</p>
          </div>
        </div>
        <div className="border rounded-md p-5">
          <p className="font-bold text-xl mb-5">
            Tabulasi Prospek Usaha Yang Dibiayai
          </p>
          <div className="flex flex-col gap-3">
            {/* <InputForm
              label={"1. Estimasi Besaran Kemampuan Bayar per bulan"}
              required={true}
              controllerName={"household_estimated_payment"}
              className="w-full rounded-md border p-3"
              wrapperFieldClass="w-full"
              onKeyPress={(event) => {
                const ASCIICode = event.which ? event.which : event.keyCode;
                if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                  return;
                event.preventDefault();
              }}
              disabled={isView}
            /> */}
            <InputCurrencyForm
              name="household_estimated_payment"
              label="1. Estimasi Besaran Kemampuan Bayar per bulan"
              required
              prefix="Rp "
              disabled={isView}
            />
            <InputFormRadio
              label={"2. Nilai Pendapatan dari usaha wajar?"}
              labelClass={"w-full"}
              controllerName={`household_income`}
              usingGrid={true}
              gridClassName="grid grid-cols-2 gap-4 w-full"
              className="flex items-center justify-between flex-wrap gap-2"
              wrapperFieldClass="w-full"
              required={true}
              values={[
                {
                  label: "Iya",
                  value: true,
                },
                {
                  label: "Tidak",
                  value: false,
                },
              ]}
              disabled={isView}
            />
            <InputFormRadio
              label={"3. Punya kemampuan bayar sesuai nilai permohonan?"}
              labelClass={"w-full"}
              controllerName={`household_payment_capabiliyt`}
              usingGrid={true}
              gridClassName="grid grid-cols-2 gap-4 w-full"
              className="flex items-center justify-between flex-wrap gap-2"
              wrapperFieldClass="w-full"
              required={true}
              values={[
                {
                  label: "Iya",
                  value: true,
                },
                {
                  label: "Tidak",
                  value: false,
                },
              ]}
              disabled={isView}
            />
            <InputFormRadio
              label={
                "4. Apakah Penyesuaian nilai pinjaman berdasarkan kemampuan bayar Diperlukan?"
              }
              labelClass={"w-full"}
              controllerName={`household_loan_value_adjusment`}
              usingGrid={true}
              gridClassName="grid grid-cols-2 gap-4 w-full"
              wrapperFieldClass="w-full"
              className="flex items-center justify-between flex-wrap gap-2"
              required={true}
              values={[
                {
                  label: "Iya",
                  value: true,
                },
                {
                  label: "Tidak",
                  value: false,
                },
              ]}
              disabled={isView}
            />
            {lendingsFromAble !== "" && lendingsFromAble && (
              // <InputForm
              //   label={"Berapa Nilai Penyesuaiannya"}
              //   required={true}
              //   controllerName={"household_adjusment_value"}
              //   className="w-full rounded-md border p-3"
              //   onKeyPress={(event) => {
              //     const ASCIICode = event.which ? event.which : event.keyCode;
              //     if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
              //       return;
              //     event.preventDefault();
              //   }}
              //   disabled={isView}
              // />
              <InputCurrencyForm
                name="household_adjusment_value"
                label="Berapa Nilai Penyesuaiannya"
                required
                prefix="Rp "
                disabled={isView}
              />
            )}
          </div>
        </div>
        <div className="border rounded-md px-4 py-3">
          <InputFormRadio
            label={"Kesimpulan Verifikasi"}
            controllerName={`household_verification`}
            className="flex items-center justify-between gap-4"
            values={[
              {
                label: (
                  <>
                    <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                    Data Terverifikasi
                  </>
                ),
                value: true,
              },
              {
                label: (
                  <>
                    <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                    Data Belum Sesuai/ Ada Kesalahan
                  </>
                ),
                value: false,
              },
            ]}
            disabled={isView}
          />
          {verificationResultAble !== "" &&
            verificationResultAble === false && (
              <InputForm
                controllerName={"household_verification_reason"}
                textArea={true}
                label={"Jelaskan kesalahan/ ketidak sesuaian datanya"}
                className={"w-full rounded-md border p-3"}
                wrapperClass={"mt-5"}
                disabled={isView}
              />
            )}
        </div>
      </CardForm>
    );
  }
);

const DataAssurance = ({ type, data, isView }) => {
  const methods = useFormContext();

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "type",
        title: "Jenis Pohon",
      },
      {
        key: "area",
        title: "Keliling (CM)",
      },
      {
        key: "condition",
        title: "Kondisi",
      },
      {
        key: "price",
        title: "Harga",
        render: ({ item }) => {
          return formatCurrency(item.price);
        },
      },
    ];
  }, []);

  const headersAdditional = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "collateral_type",
        title: "Bentuk Jaminan",
      },
      {
        key: "collateral_fee",
        title: "Nilai Jaminan",
        render: ({ item, index, values }) => {
          return <p>{formatCurrency(item.collateral_fee)}</p>;
        },
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <>
            <Button
              variant="icon"
              label={<RiEyeLine className="text-lg" />}
              onClick={() => {
                window.open(item?.selected_file?.url, "_blank");
              }}
            />
          </>
        ),
      },
    ];
  }, []);

  const lendingFromTree = methods.watch(
    "collateral_tabulation_loan_value_adjusment"
  );
  const verificationResultAssurance = methods.watch(
    "collateral_tabulation_verification"
  );

  const estimatedCollaterals = data?.colleterals.reduce(
    (accumulator, current) => accumulator + current.collateral_fee,
    0
  );

  const estimatedIncome = data?.tally_sheet.reduce(
    (accumulator, current) => accumulator + current.price,
    0
  );

  const handleMountingFile = useCallback(async () => {
    if (!data?.colleterals) return;

    for (let collateral of data?.colleterals) {
      const response = await getFile(collateral.collateral_file);

      collateral.selected_file = response.data.data;
    }
  }, [data?.colleterals]);

  useEffect(() => {
    handleMountingFile();
  }, [handleMountingFile]);

  return (
    <CardForm label="Data Jaminan">
      <div className="bg-[#E1F1D6] my-2 p-3 w-full">
        <p className="font-bold text-[#01A24A]">Jaminan Usaha Kehutanan</p>
      </div>
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div>
            <p className="font-semibold">Nama</p>
            <p>{data?.tally_sheet_name}</p>
          </div>
          <div>
            <p className="font-semibold">Blok</p>
            <p>{data?.tally_sheet_block}</p>
          </div>
          <div>
            <p className="font-semibold">Luas</p>
            <p>{data?.tally_sheet_wide} m2</p>
          </div>
          <div>
            <p className="font-semibold">Jumlah Pohon</p>
            <p>{data?.tally_sheet_total_tree}</p>
          </div>
          <div>
            <p className="font-semibold">No SPPT</p>
            <p>{data?.tally_sheet_sppt}</p>
          </div>
          <div>
            <p className="font-semibold">Atas Nama</p>
            <p>{data?.tally_sheet_owner}</p>
          </div>
        </div>
        <div className="w-full bg-[#F2F9EC] p-4 rounded-md border-l-4 border-[#6CB745]">
          <p className="font-bold">Estimasi Jaminan Asset Kehutanan</p>
          <p>{formatCurrency(estimatedIncome)}</p>
        </div>
        <TableWrapper className="w-full">
          <Table
            headers={headers}
            items={data?.tally_sheet ?? []}
            isLoading={false}
          />
        </TableWrapper>
        {data?.tally_sheet_file && (
          <UploadedFileAsync id={data?.tally_sheet_file} />
        )}
      </div>
      <div className="bg-[#E1F1D6] my-5 p-3 w-full">
        <p className="font-bold text-[#01A24A]">Jaminan Tambahan</p>
      </div>
      <TableWrapper className="w-full">
        <Table
          headers={headersAdditional}
          items={data?.colleterals ?? []}
          isLoading={false}
        />
      </TableWrapper>
      <div className="w-full bg-[#F2F9EC] p-4 rounded-md border-l-4 border-[#6CB745]">
        <p className="font-bold">Total Jaminan Yang Diajukan</p>
        <p>{formatCurrency(estimatedCollaterals + estimatedIncome)}</p>
      </div>
      <div className="border rounded-md p-5">
        <p className="font-bold text-xl mb-5">
          Tabulasi Prospek Usaha Yang Dibiayai
        </p>
        <div className="flex flex-col gap-3">
          <InputFormRadio
            label={"1. Harga yang digunakan wajar?"}
            labelClass={"w-full"}
            controllerName={`collateral_tabulation_price_used`}
            wrapperFieldClass="w-full"
            usingGrid={true}
            gridClassName="grid grid-cols-2 gap-4 w-full"
            className="flex items-center justify-between flex-wrap gap-2"
            required={true}
            values={[
              {
                label: "Iya",
                value: true,
              },
              {
                label: "Tidak",
                value: false,
              },
            ]}
            disabled={isView}
          />
          <InputFormRadio
            label={
              "2. Apakah Nilai pohon memenuhi persyaratan nilai jaminan min. 125% dari nilai pinjaman?"
            }
            labelClass={"w-full"}
            wrapperFieldClass="w-full"
            controllerName={`collateral_tabulation_tree_value_criteria`}
            usingGrid={true}
            gridClassName="grid grid-cols-2 gap-4 w-full"
            className="flex items-center justify-between flex-wrap gap-2"
            required={true}
            values={[
              {
                label: "Iya",
                value: true,
              },
              {
                label: "Tidak",
                value: false,
              },
            ]}
            disabled={isView}
          />
          <InputFormRadio
            label={
              "3. Apakah Penyesuaian nilai pinjaman berdasarkan nilai pohon Diperlukan?"
            }
            labelClass={"w-full"}
            controllerName={`collateral_tabulation_loan_value_adjusment`}
            usingGrid={true}
            wrapperFieldClass="w-full"
            gridClassName="grid grid-cols-2 gap-4 w-full"
            className="flex items-center justify-between flex-wrap gap-2"
            required={true}
            values={[
              {
                label: "Iya",
                value: true,
              },
              {
                label: "Tidak",
                value: false,
              },
            ]}
            disabled={isView}
          />
          {lendingFromTree !== "" && lendingFromTree === true && (
            // <InputForm
            //   label={"Berapa Nilai Penyesuaiannya"}
            //   required={true}
            //   controllerName={"collateral_tabulation_adjusment_value"}
            //   className="w-full rounded-md border p-3"
            //   onKeyPress={(event) => {
            //     const ASCIICode = event.which ? event.which : event.keyCode;
            //     if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
            //       return;
            //     event.preventDefault();
            //   }}
            //   disabled={isView}
            // />
            <InputCurrencyForm
              name="collateral_tabulation_adjusment_value"
              label="Berapa Nilai Penyesuaiannya"
              required
              prefix="Rp "
              disabled={isView}
            />
          )}
        </div>
      </div>
      <div className="border rounded-md px-4 py-3">
        <InputFormRadio
          label={"Kesimpulan Verifikasi"}
          controllerName={`collateral_tabulation_verification`}
          className="flex items-center justify-between gap-4"
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Data Terverifikasi
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Data Belum Sesuai/ Ada Kesalahan
                </>
              ),
              value: false,
            },
          ]}
          disabled={isView}
        />
        {verificationResultAssurance !== "" &&
          verificationResultAssurance === false && (
            <InputForm
              controllerName={"collateral_tabulation_verification_reason"}
              textArea={true}
              label={"Jelaskan kesalahan/ ketidak sesuaian datanya"}
              className={"w-full rounded-md border p-3"}
              wrapperClass={"mt-5"}
              disabled={isView}
            />
          )}
      </div>
    </CardForm>
  );
};

const LandDocuments = withFileService(
  ({ type, data, getFileFromService, isView }) => {
    const methods = useFormContext();

    const headers = useMemo(() => {
      return [
        {
          key: "index",
          title: "No",
          render: ({ index }) => index + 1,
        },
        {
          key: "name",
          title: "Nama Dokumen",
        },
        {
          key: "desc",
          title: "Deskripsi",
        },
        {
          key: "action",
          title: "Aksi",
          alignment: "center",
          className: "sticky right-0 bg-white",
          render: ({ item }) => {
            return (
              <>
                <Button
                  theme="primary"
                  variant="icon"
                  label={
                    <>
                      <RiEyeLine className="text-lg" />
                      Lihat Dokumen
                    </>
                  }
                  onClick={() => {
                    window.open(item.selected_file.url, "_blank").focus();
                  }}
                />
              </>
            );
          },
        },
      ];
    }, []);

    const getAllFile = useCallback(() => {
      if (!data?.document || data?.document?.length === 0) return;

      for (let doc of data?.document) {
        if (
          doc.file === "" ||
          typeof doc.file === "undefined" ||
          doc.selected_file
        ) {
          continue;
        }

        getFileFromService(doc.file, {
          onSuccess: (res) => {
            doc.selected_file = res;
          },
          onError: (err) => console.log(err),
        });
      }
    }, [data, getFileFromService]);

    useEffect(() => {
      getAllFile();
    }, [getAllFile]);

    const verificationAdditionalDocsData = methods.watch(
      "land_ownership_doc_verification"
    );

    return (
      <CardForm label="Dokumen Penguasaan Lahan">
        <div>
          <TableWrapper className="w-full">
            <Table
              headers={headers}
              items={data?.document ?? []}
              isLoading={false}
            />
          </TableWrapper>
        </div>
        <div className="border rounded-md px-4 py-3">
          <InputFormRadio
            label={"Kesimpulan Verifikasi"}
            controllerName={`land_ownership_doc_verification`}
            className="flex items-center justify-between gap-4"
            disabled={isView}
            values={[
              {
                label: (
                  <>
                    <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                    Data Terverifikasi
                  </>
                ),
                value: true,
              },
              {
                label: (
                  <>
                    <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                    Data Belum Sesuai/ Ada Kesalahan
                  </>
                ),
                value: false,
              },
            ]}
          />
          {verificationAdditionalDocsData !== "" &&
            verificationAdditionalDocsData === false && (
              <InputForm
                controllerName={"land_ownership_doc_verification_reason"}
                textArea={true}
                label={"Jelaskan kesalahan/ ketidak sesuaian datanya"}
                className={"w-full rounded-md border p-3"}
                wrapperClass={"mt-5"}
                disabled={isView}
              />
            )}
        </div>
      </CardForm>
    );
  }
);

const DeskAnalystRecomendation = ({ type, isView }) => {
  const methods = useFormContext();

  const paymentValue = methods.watch("disburstment");
  const returnField = methods.watch("return");

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "disburstment_phase_items",
  });

  const loanValueRecomendation = methods.watch("loan_value_recomendation");

  useEffect(() => {
    methods.setValue(
      "fixed_loan_value_recomendation",
      loanValueRecomendation
        ? Math.round(loanValueRecomendation / 10000) * 10000
        : null
    );
  }, [loanValueRecomendation, methods]);

  const time_recomendation_periode_type = methods.watch(
    "time_recomendation_periode_type"
  );
  const installment_scheme_type = methods.watch("installment_scheme_type");
  const time_return_periode_type = methods.watch("time_return_periode_type");

  const errors = methods?.formState?.errors;
  return (
    <CardForm label="Rekomendasi Sementara Desk Analyst">
      <div className="grid grid-cols-2 gap-4">
        <InputCurrencyForm
          name="loan_value_recomendation"
          label="1. Rekomendasi Nilai Pinjaman"
          required
          prefix="Rp "
          disabled={isView}
        />
        {/* <InputForm
          label={"1. Rekomendasi Nilai Pinjaman"}
          required={true}
          controllerName={"loan_value_recomendation"}
          className="w-full rounded-md border p-3"
          onKeyPress={(event) => {
            const ASCIICode = event.which ? event.which : event.keyCode;
            if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
              return;
            event.preventDefault();
          }}
          disabled={isView}
        /> */}
        {/* <InputForm
          label={"2. Rekomendasi Nilai Pinjaman (Pembulatan)"}
          controllerName={"fixed_loan_value_recomendation"}
          className="w-full rounded-md border p-3"
          disabled={true}
        /> */}
        <InputCurrencyForm
          name="fixed_loan_value_recomendation"
          label="2. Rekomendasi Nilai Pinjaman (Pembulatan)"
          prefix="Rp "
          disabled
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <InputForm
          label={"3. Rekomendasi Jangka Waktu"}
          required={true}
          controllerName={"time_recomendation_periode_value"}
          className="w-full rounded-md border p-3 "
          onKeyPress={(event) => {
            const ASCIICode = event.which ? event.which : event.keyCode;
            if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
              return;
            event.preventDefault();
          }}
          disabled={isView}
        />
        <div className="flex flex-col gap-1 items-center w-full">
          <span className="text-transparent">-</span>
          <Select
            styles={customStyles}
            className="w-full"
            options={UnitOptions}
            value={UnitOptions.find(
              (x) => x.value === time_recomendation_periode_type
            )}
            onChange={(e) => {
              methods.setValue("time_recomendation_periode_type", e.value);
            }}
            isDisabled={isView}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <InputForm
          label={"4. Skema Angsuran"}
          required={true}
          controllerName={"installment_scheme"}
          className="w-full rounded-md border p-3 "
          onKeyPress={(event) => {
            const ASCIICode = event.which ? event.which : event.keyCode;
            if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
              return;
            event.preventDefault();
          }}
          disabled={isView}
        />
        <div className="flex flex-col gap-1 items-center w-full">
          <span className="text-transparent">-</span>
          <Select
            styles={customStyles}
            className="w-full"
            options={SchemeTypes}
            value={SchemeTypes.find((x) => x.value === installment_scheme_type)}
            onChange={(e) => {
              methods.setValue("installment_scheme_type", e.value);
            }}
            isDisabled={isView}
          />
        </div>
      </div>
      <InputFormRadio
        label={"5. Pencairan"}
        labelClass="w-full"
        controllerName={`disburstment`}
        usingGrid={true}
        wrapperFieldClass="w-full"
        gridClassName="grid grid-cols-2 gap-4 w-full"
        className="flex items-center justify-between flex-wrap gap-2"
        required={true}
        values={[
          {
            label: "Bertahap",
            value: "stage",
          },
          {
            label: "Sekaligus",
            value: "at_once",
          },
        ]}
        disabled={isView}
      />
      {paymentValue === "stage" && (
        <>
          <label className="flex gap-1 label font-semibold text-[14px]">
            <span className={`label-text`}>5.1 Tahap Pencairan</span>
            <span className="text-[#F04438]">*</span>
          </label>
          {fields.map((field, idx) => (
            <div className="flex space-x-4 items-end" key={field.id}>
              <InputForm
                controllerName={`disburstment_phase_items.${idx}.id`}
                hidden
              />
              {/* <InputForm
                label={`Tahap Pencairan Ke-${idx + 1}`}
                required={true}
                controllerName={`disburstment_phase_items.${idx}.value`}
                className="w-full rounded-md border p-3"
                onKeyPress={(event) => {
                  const ASCIICode = event.which ? event.which : event.keyCode;
                  if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                    return;
                  event.preventDefault();
                }}
                disabled={isView}
              /> */}
              <InputCurrencyForm
                name={`disburstment_phase_items.${idx}.value`}
                label={`Tahap Pencairan Ke-${idx + 1}`}
                prefix="Rp "
                required
                disabled={isView}
              />
              {idx > 0 && !isView && (
                <Button
                  type="button"
                  onClick={() => remove(idx)}
                  label={<BiTrash className="text-white" />}
                  className={`!p-3 rounded-lg !bg-error-600 flex-none ${
                    !isEmpty(errors) &&
                    !isEmpty(errors?.["disburstment_phase_items"]?.[idx]?.value)
                      ? "!mb-[26px]"
                      : ""
                  }`}
                />
              )}
            </div>
          ))}
          <hr />
          {!isView && (
            <div className="flex justify-end">
              <Button
                type="button"
                onClick={() =>
                  append({
                    id: null,
                    value: "",
                  })
                }
                className="p-3 rounded-lg flex-none"
                label={
                  <div className="flex space-x-2 items-center">
                    <BiPlus className="text-black" />
                    Tahap Pencairan
                  </div>
                }
              />
            </div>
          )}
        </>
      )}

      <InputFormRadio
        label={"6. Pengembalian"}
        labelClass={"w-full"}
        controllerName={`return`}
        usingGrid={true}
        wrapperFieldClass="w-full"
        gridClassName="grid grid-cols-2 gap-4 w-full"
        className="flex items-center justify-between flex-wrap gap-2"
        required={true}
        values={[
          {
            label: "Atur Jangka Waktu Pengembalian",
            value: "duration",
          },
          {
            label: "Sesuai Dengan Siklus Usaha",
            value: "business_cycle",
          },
        ]}
        disabled={isView}
      />
      {returnField === "duration" && (
        <div className="grid grid-cols-2 gap-4">
          <InputForm
            label={"Jangka Waktu Pengembalian"}
            required={true}
            controllerName={"time_return_periode_value"}
            className="w-full rounded-md border p-3"
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
            disabled={isView}
          />
          <div className="flex flex-col gap-1 items-center w-full">
            <span className="text-transparent">-</span>
            <Select
              styles={customStyles}
              className="w-full"
              options={UnitOptions}
              value={UnitOptions.find(
                (x) => x.value === time_return_periode_type
              )}
              onChange={(e) => {
                methods.setValue("time_return_periode_type", e.value);
              }}
              isDisabled={isView}
            />
          </div>
        </div>
      )}
      <RichEditorForm
        name={`notes_analyst`}
        label={"7. Catatan Analis"}
        readOnly={isView}
      />
    </CardForm>
  );
};

export default RequestForm;
