import { useQuery } from "@tanstack/react-query";
import { Button, Input } from "components";
import Badge from "components/atoms/Badge";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import dayjs from "dayjs";
import { debounce, filter } from "lodash";
import React, { useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import {
  RiCloseLine,
  RiEyeLine,
  RiFileExcel2Line,
  RiFolder2Line,
  RiSearchLine,
} from "react-icons/ri";
import { useNavigate, useParams } from "react-router";
import Table, { TableWrapper } from "components/atoms/Table";
import { customStyles } from "components/atoms/Select";
import Select from "react-select";
import { Pagination } from "components/v2/shared/pagination";
import { downloadFile } from "helpers";
import {
  getAgencyDisbursementDirectionList,
  getAgencyDisbursementExecutorList,
} from "services/institutions/disbursement";
import { getFile } from "services/fileService";

const stateOptions = [
  { value: "new", label: "Baru" },
  { value: "on_progress", label: "Sedang Dikerjakan" },
  { value: "done", label: "Selesai" },
];

const stateColors = {
  1: "bluelight",
  2: "error",
  3: "success",
};

const InstitutionsDisbursment = () => {
  const navigate = useNavigate();
  const { disbursementType } = useParams();

  const [filters, setFilters] = useState({
    filter: {
      search: undefined,
      status: undefined,
      date: undefined,
    },
    page: 1,
    limit: 10,
  });

  const { data: executorList, isLoading: executorIsLoading } = useQuery(
    ["GET_DISBURSEMENT_EXECUTOR_LIST", filters],
    getAgencyDisbursementExecutorList,
    {
      select: (data) => data?.data?.data,
      enabled: disbursementType === "review",
    }
  );

  const { data: directionList, isLoading: directionIsLoading } = useQuery(
    ["GET_DISBURSEMENT_DIRECTION_LIST", filters],
    getAgencyDisbursementDirectionList,
    {
      select: (data) => data?.data?.data,
      enabled: disbursementType === "agreement",
    }
  );

  const handleFile = async (id) => {
    const { data } = await getFile(id)

    if (data.data) {
      window.open(data.data.url, "_blank")
    }
  }

  // const { data, isLoading } = useQuery({
  //   queryKey: [
  //     "desk-analyst-lembaga",
  //     {
  //       ...filter,
  //       filter: {
  //         ...filter.filter,
  //       },
  //     },
  //   ],
  //   queryFn: getListDeskAnalyst,
  //   retry: false,
  //   refetchOnWindowFocus: false,
  //   select: (data) => data?.data?.data,
  // });

  const headers = useMemo(() => {
    return [
      {
        key: "agreement_number",
        title: "No Perjanjian",
        // render: ({ index }) => index + 1,
      },
      {
        key: "agency_name",
        title: "Nama Lembaga",
      },
      {
        key: "date",
        title: "Tanggal Pengajuan",
        render: ({ item }) => {
          return dayjs(item.request_date).format("DD MMMM YYYY");
        },
      },
      {
        key: "phase",
        title: "Tahap",
        render: ({ item }) => `Tahap ${item?.phase}`,
      },
      {
        key: "status",
        title: "Status",
        render: ({ item }) => (
          <Badge color={stateColors[item.status]}>{item.status_string}</Badge>
        ),
      },
      // {
      //   key: "status",
      //   title: "Surat Pemberitahuan Cair",
      //   render: ({ item }) => (
      //     <div className="flex justify-center items-center w-full">
      //       <RiFolder2Line
      //         className="text-xl cursor-pointer"
      //         onClick={() => {
      //           downloadFile(item.file, "Berita Acara Desk Analyst");
      //         }}
      //       />
      //     </div>
      //   ),
      // },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) =>
          item?.status === "1" ? (
            <Button
              theme="primary"
              label="Verifikasi"
              onClick={() => {
                navigate(`${item.id}`);
              }}
            />
          ) : (
            <Button
              variant="icon"
              label={<RiEyeLine className="text-lg" />}
              onClick={() => {
                navigate(`${item.id}?status=${item?.status}`);
              }}
            />
          ),
      },
    ];
  }, [navigate]);

  const headerDisbursment = useMemo(() => {
    return [
      {
        key: "agreement_number",
        title: "No Perjanjian",
        // render: ({ index }) => index + 1,
      },
      {
        key: "agency_name",
        title: "Nama Lembaga",
      },
      {
        key: "date",
        title: "Tanggal Pengajuan",
        render: ({ item }) => {
          return dayjs(item.request_date).format("DD MMMM YYYY");
        },
      },
      {
        key: "phase",
        title: "Tahap",
        render: ({ item }) => `Tahap ${item?.phase}`,
      },
      {
        key: "status",
        title: "Status",
        render: ({ item }) => (
          <Badge color={stateColors[item.status]}>{item.status_string}</Badge>
        ),
      },
      {
        key: "news",
        title: "File Maker",
        render: ({ item }) => {
          console.log('item', item)
          return (
            <React.Fragment>
              {item?.file_maker ? (
                <RiFileExcel2Line
                  size="18"
                  onClick={() => {
                    // Your logic here
                    // window.open(item.file?., "_blank")
                    handleFile(item.file_maker);
                  }}
                />
                
              ) : '-'}
            </React.Fragment>
          );
        },
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) =>
          item?.status === "1" ? (
            <Button
              theme="primary"
              label="Verifikasi"
              onClick={() => {
                navigate(`${item.id}`);
              }}
            />
          ) : (
            <Button
              variant="icon"
              label={<RiEyeLine className="text-lg" />}
              onClick={() => {
                navigate(`${item.id}?status=${item?.status}`);
              }}
            />
          ),
      },
    ];
  }, [navigate]);

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: "Permohonan Lembaga Penyalur",
            path: "/institutions/disbursment",
          },
        ]}
      />

      <h1 className="text-gray-900 text-2xl font-semibold leading-loose">
        Permohonan Lembaga Penyalur
      </h1>

      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end">
          <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
            <RiSearchLine className="text-gray-400 text-xl" />
            <Input
              className="w-full bg-white border-none"
              onChange={debounce(
                (e) =>
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      search: e.target.value,
                    },
                  })),
                1000
              )}
              placeholder="Cari"
            />
          </div>
          <Select
            className="w-full rounded-md h-10 absolute"
            options={stateOptions}
            placeholder="Status"
            styles={customStyles}
            isClearable
            onChange={debounce(
              (e) =>
                setFilters((prev) => ({
                  ...prev,
                  page: 1,
                  filter: {
                    ...prev.filter,
                    status: e?.value ?? undefined,
                  },
                })),
              1000
            )}
          />
          <div className="relative">
            <ReactDatePicker
              className={
                "date_picker w-full !outline-none !focus:border-primary-700 border h-10 px-3 focus:border-primary-700 rounded-md"
              }
              selected={filter.filter?.date ?? null}
              onChange={debounce(
                (date) =>
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      date,
                    },
                  })),
                1000
              )}
              placeholderText={"dd/mm/yyyy"}
              showYearDropdown={true}
            />
            {filters?.filter?.date && (
              <RiCloseLine
                className="absolute top-3 right-2 text-gray-400 hover:text-gray-600 cursor-pointer text-lg"
                onClick={() =>
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      date: undefined,
                    },
                  }))
                }
              />
            )}
          </div>
        </div>
        <TableWrapper className="mt-4">
          <Table
            headers={disbursementType === 'review' ? headerDisbursment : headers}
            items={
              disbursementType === "review"
                ? executorList?.data ?? []
                : directionList?.data ?? []
            }
            isLoading={
              disbursementType === "review"
                ? executorIsLoading
                : directionIsLoading
            }
          />
          <Pagination
            page={
              disbursementType === "review"
                ? executorList?.meta?.current_page ?? 1
                : directionList?.meta?.current_page ?? 1
            }
            limit={filters.limit ?? 10}
            total={
              disbursementType === "review"
                ? executorList?.meta?.total ?? 0
                : directionList?.meta?.total ?? 0
            }
            totalPage={
              disbursementType === "review"
                ? executorList?.meta?.last_page ?? 1
                : directionList?.meta?.last_page ?? 1
            }
            onLimitChange={(e) => setFilters((prev) => ({ ...prev, limit: e }))}
            onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
          />
        </TableWrapper>
      </div>
    </div>
  );
};

export default InstitutionsDisbursment;
