import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { Button, Input } from "components";
import { customStyles } from "components/atoms/Select";
import Table, { TableWrapper } from "components/atoms/Table";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { Pagination } from "components/v2/shared/pagination";
import { capitalize, debounce, isEmpty } from "lodash";
import { useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import {
  RiCircleFill,
  RiCloseLine,
  RiEyeLine,
  RiFile3Line,
  RiSearchLine,
} from "react-icons/ri";
import { useNavigate } from "react-router";
import Select from "react-select";
import { getFile } from "services/fileService";
import { getListAgreement } from "services/institutions/agreement";

const badgeStyle = {
  baru: "bg-[#ECFDF3] text-primary-700",
  terima: "bg-[#EFF8FF] text-[#175CD3]",
  ditolak: "bg-red-50 text-red-700",
  feedback: "bg-orange-50 text-orange-700",
  review: "bg-gray-50 text-gray-700",
};

const statusOptions = [
  // {
  //   value: "baru",
  //   label: "Baru",
  // },
  {
    value: "Direview",
    label: "Direview",
  },
  {
    value: "Feedback",
    label: "Feedback",
  },

  {
    value: "Disetujui",
    label: "Disetujui",
  },
];

const AgreementInstitution = () => {
  const navigate = useNavigate();

  const [agreements, setAgreements] = useState();

  const [filters, setFilters] = useState({
    paginate: true,
    page: 1,
    limit: 10,
    filter: {
      search: "",
      status: "",
      date: "",
    },
  });

  const { isLoading } = useQuery(
    ["GET_LIST_AGREEMENT", filters],
    getListAgreement,
    {
      select: (data) => data?.data?.data,
      onSuccess: async (res) => {
        if (res?.data?.length < 1) {
          setAgreements(res?.data);
          return;
        }
        for (let agreement of res?.data) {
          if (isEmpty(agreement?.doc_agreement_draft_template)) continue;
          const response = await getFile(
            agreement?.doc_agreement_draft_template
          );

          agreement.selected_file = response.data.data;
        }

        setAgreements(res);
      },
    }
  );

  const headers = useMemo(
    () => [
      // {
      //   key: "index",
      //   title: "No",
      //   render: ({ index }) => {
      //     const no =
      //       // parseInt(data?.meta?.current_page) *
      //       //   parseInt(data?.meta?.per_page) -
      //       // parseInt(data?.meta?.per_page) +
      //       index + 1;

      //     // const no = index + 1;

      //     return no;
      //   },
      // },
      // {
      //   key: "agreement_number",
      //   title: "Nomor Perjanjian",
      // },
      {
        key: "agreement_number",
        title: "No Perjanjian",
        render: ({ item }) =>
          item?.agreement_number || !isEmpty(item?.agreement_number)
            ? item?.agreement_number
            : "-",
      },
      {
        key: "name",
        title: "Nama Lembaga",
        render: ({ item }) => item?.name ?? "-",
      },
      {
        key: "agreement_status",
        title: "Status",
        render: ({ item }) => {
          return (
            <div
              className={clsx(
                "flex items-center text-xs gap-1 px-[6px] py-0.5 rounded-full font-medium",
                badgeStyle[item?.status ?? "baru"]
              )}
            >
              <RiCircleFill size={8} />
              {capitalize(item?.agreement_status ?? "baru")}
            </div>
          );
        },
      },
      {
        key: "agreement_doc",
        title: "Dokumen Perjanjian",
        render: ({ item }) => {
          return item?.selected_file ? (
            <a href={item?.selected_file?.url} target="_blank" rel="noreferrer">
              <RiFile3Line />
            </a>
          ) : (
            "-"
          );
        },
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <Button
            variant="icon"
            label={<RiEyeLine className="text-lg" />}
            onClick={() => {
              navigate(`${item.id}`);
            }}
          />
        ),
      },
    ],
    [navigate]
  );

  return (
    <section className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: "Daftar Perjanjian",
            path: "",
          },
        ]}
      />

      <h1 className="text-gray-900 text-2xl font-semibold leading-loose">
        Daftar Perjanjian
      </h1>

      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end">
          <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
            <RiSearchLine className="text-gray-400 text-xl" />
            <Input
              className="w-full bg-white border-none"
              onChange={debounce(
                (e) =>
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      search: e.target.value,
                    },
                  })),
                1000
              )}
              placeholder="Cari"
            />
          </div>
          <Select
            className="w-full rounded-md h-10 absolute"
            options={statusOptions}
            placeholder="Status"
            styles={customStyles}
            isClearable
            onChange={debounce(
              (e) =>
                setFilters((prev) => ({
                  ...prev,
                  page: 1,
                  filter: {
                    ...prev.filter,
                    status: e?.value ?? "",
                  },
                })),
              1000
            )}
          />
          <div className="relative">
            <ReactDatePicker
              className={
                "date_picker w-full !outline-none !focus:border-primary-700 border h-10 px-3 focus:border-primary-700 rounded-md"
              }
              selected={filters.filter.date}
              onChange={debounce(
                (date) =>
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      date: date,
                    },
                  })),
                1000
              )}
              placeholderText={"dd/mm/yyyy"}
              showYearDropdown={true}
            />
            {filters.filter.date !== "" && (
              <RiCloseLine
                className="absolute top-3 right-2 text-gray-400 hover:text-gray-600 cursor-pointer text-lg"
                onClick={() =>
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      date: "",
                    },
                  }))
                }
              />
            )}
          </div>
        </div>
        <TableWrapper className="mt-4">
          <Table
            headers={headers}
            items={agreements?.data}
            isLoading={isLoading}
          />
          <Pagination
            // page={data?.meta?.current_page || 1}
            // limit={filter.limit || 10}
            // total={data?.meta?.total || 0}
            // totalPage={data?.meta?.last_page || 1}
            page={1}
            limit={10}
            total={0}
            totalPage={1}
            onLimitChange={(e) => setFilters((prev) => ({ ...prev, limit: e }))}
            onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
          />
        </TableWrapper>
      </div>
    </section>
  );
};

export default AgreementInstitution;
