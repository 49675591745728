import React, { useMemo, useState, useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/atoms/Button/Button";
import CardForm from "components/molecules/CardForm";
import { useNavigate } from "react-router";
import { RiEyeLine } from "react-icons/ri";
import { InputForm, InputFormRadio, BottomFormAction } from "components";
import {
  FormProvider,
  useForm,
  useWatch,
  useFormContext,
} from "react-hook-form";
import AccordionButton from "components/atoms/AccordionButton";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import Table, { TableWrapper } from "components/atoms/Table";
import AtomSelect from "components/atoms/Select";
import RichEditorForm from "components/molecules/RichEditorForm";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import Badge from "components/atoms/Badge";
import { isEmpty } from "lodash";

import { useMutation, useQuery } from "@tanstack/react-query";
import { postCommitteeMinutes } from "services/fdb/committee";
import SelectMinutes from "./SelectMinutes";
import { enqueueSnackbar } from "notistack";
import { formatCurrency, getErrorMessage } from "helpers";
import SelectAgenda from "./SelectAgenda";
import AccordionForms from "./AccordionForm";
import validationSchema from "./validation-schema";
import { getDetailRisalah } from "services/fdb/risalah";
import { Pagination } from "components/v2/shared/pagination";

const stateColors = {
  "Non - Revolving": "error",
  Revolving: "success",
};

const defaultValues = {
  committee_minutes_number: "",
  meeting_circular_minutes_number: null,
  minutes_id: null,
  materials_of_committee_discussions: null,
  results_of_committee_discussions: null,
  recommendation: null,
  legal_basis: null,
  provision_service: null,
  fdb_distribution_scheme: null,
  fdb_objectives: null,
  grace_period: "",
  grace_period_time_unit_value: "",
  grace_period_time_unit: { value: "YEAR", label: "Tahun" },
  fdb_financing_term_period: { value: "YEAR", label: "Tahun" },
  fdb_loan_term: null,
  fdb_financing_term_value: null,
  guarantee: null,
  percentage_service_rate_per_year: "",
  service_rates_application_method: null,
  service_rate_calculation_method: null,
  the_object_being_financed: null,
  fdb_distribution: [
    {
      provision_id: null,
    },
  ],
  fdb_return: [
    {
      provision_id: null,
    },
  ],
  positive_covenants: [
    {
      provision_id: null,
    },
  ],
  negative_covenants: [
    {
      provision_id: null,
    },
  ],
};

const generateProvisions = (array) => {
  if (Array.isArray(array) && array.length > 0) {
    const provisions = array?.map((x, index) => {
      const temp = {
        order_number: index + 1,
        provision_id: x?.provision_id?.value,
      };
      if (x.sub_provisions?.length > 0) {
        temp["sub_provision"] = x.sub_provisions.map((sub, idx) => {
          const subprovision = {
            order_number: idx + 1,
            sub_provision_id: sub?.sub_provision?.value,
          };
          if (sub?.sub_sub_provisions?.length > 0) {
            subprovision["sub_sub_provision"] = sub?.sub_sub_provisions?.map(
              (subsub, idx) => ({
                order_number: idx + 1,
                sub_sub_provision_id: subsub?.sub_sub_provision?.value,
              })
            );
          }
          return subprovision;
        });
      }
      return temp;
    });
    return provisions;
  }
  return [];
};

export const CreateBeritaAcara = ({ type }) => {
  const navigate = useNavigate();

  const [service, setService] = useState();
  const [risalahId, setRisalahId] = useState();

  const methods = useForm({
    resolver: yupResolver(validationSchema(service)),
    defaultValues,
  });
  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const mutation = useMutation({
    mutationFn: (data) => {
      return postCommitteeMinutes(data);
    },
  });

  const onFormSubmit = useCallback(
    (payload) => {
      const formData = {
        ...payload,
        meeting_circular_minutes_number:
          payload?.meeting_circular_minutes_number?.value,
        minutes_id: payload?.minutes_id?.value,
        fdb_distribution: generateProvisions(payload?.fdb_distribution),
        fdb_return: generateProvisions(payload?.fdb_return),
        positive_covenants: generateProvisions(payload?.positive_covenants),
        negative_covenants: generateProvisions(payload?.negative_covenants),
        fdb_distribution_scheme: payload?.fdb_distribution_scheme?.value,
        fdb_financing_term_period: payload?.fdb_financing_term_period?.value,
        grace_period_time_unit: payload?.grace_period_time_unit?.value,
        provision_service: payload?.provision_service?.value ?? null,
        service_rate_calculation_method:
          payload?.service_rate_calculation_method?.value,
        service_rates_application_method:
          payload?.service_rates_application_method?.value,
        legal_basis: payload?.legal_basis ?? null,
        materials_of_committee_discussions:
          payload?.materials_of_committee_discussions ?? null,
        results_of_committee_discussions:
          payload?.results_of_committee_discussions ?? null,
        guarantee: payload?.guarantee ?? null,
        the_object_being_financed: payload?.the_object_being_financed ?? null,
        fdb_financing_term_value: payload?.fdb_financing_term_value ?? null,
        fdb_objectives: payload?.fdb_objectives ?? null,
        fdb_loan_term: payload?.fdb_loan_term ?? null,
        percentage_service_rate_per_year: Number(
          payload?.percentage_service_rate_per_year.replace(",", ".")
        ),
      };

      mutation.mutate(
        { data: formData, params: { is_non_forestry: service ? true : false } },
        {
          onSuccess: () => {
            enqueueSnackbar("Data berhasil disimpan", { variant: "success" });
            navigate("/member-in-group-ba");
          },
          onError: (error) => {
            enqueueSnackbar({
              message: getErrorMessage(error),
              variant: "error",
            });
          },
        }
      );
    },
    [mutation, navigate, service]
  );

  const onDraft = useCallback(() => {
    //
  }, []);

  return (
    <div className="mb-40">
      <FormProvider {...methods}>
        <BreadCrumbs
          routes={[
            {
              label: "Berita Acara - Perorangan dalam Kelompok",
              path: "/member-in-group-ba",
            },
            {
              label: "Buat Berita Acara",
              path: "/create-ba",
            },
          ]}
        />
        <GroupInformation
          type={type}
          setService={setService}
          setRisalahId={setRisalahId}
        />
        <BeritaAcaraComittee type={type} />
        <Clause
          type={type}
          methods={methods}
          service={service}
          risalahId={risalahId}
        />
        {/* <ManagementInformationCard type={type} /> */}
        {/* {type === "non-persos" && <ExtraDocs />} */}
        <BottomFormAction
          backButtonAction={onBack}
          submitActionButton={() => methods.handleSubmit(onFormSubmit)()}
          disableDrafButton={false}
          drafButtonAction={onDraft}
          disableButtonSubmit={false}
          hideDraft={true}
          lastStep={true}
          className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
          submitButtonProps={{ type: "submit" }}
        />
      </FormProvider>
    </div>
  );
};

const GroupInformation = ({ type, setService, setRisalahId }) => {
  const methods = useFormContext();
  const meeting_circular_minutes_number = useWatch({
    control: methods.control,
    name: "meeting_circular_minutes_number",
  });

  return (
    <CardForm label="Informasi Kelompok">
      <div className="flex">
        <InputForm
          label="No Berita Acara"
          required={true}
          controllerName={"committee_minutes_number"}
          className={"rounded-md w-full"}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 items-baseline">
        <div className="form-control w-full flex flex-col justify-end">
          <label className="label flex gap-1 font-semibold text-[14px] mb-2">
            <span className={`label-text`}>No Surat Edaran Rapat Risalah</span>
            <span className="text-[#F04438]">*</span>
          </label>
          <SelectAgenda name="meeting_circular_minutes_number" />
        </div>
        <div className="form-control w-full flex flex-col justify-end">
          <label className="label flex gap-1 font-semibold text-[14px] mb-2">
            <span className={`label-text`}>No Risalah</span>
            <span className="text-[#F04438]">*</span>
          </label>
          <SelectMinutes
            name="minutes_id"
            meeting_circular_minutes_number={meeting_circular_minutes_number}
            customOnChange={(value) => {
              methods.setValue("group_name", value?.group_profil_name, {
                shouldValidate: true,
              });
              setService(value.service === "null" ? null : value.service);
              setRisalahId(value.risalah_id);
            }}
          />
        </div>
      </div>
      <div className="flex">
        <InputForm
          label="Nama Kelompok"
          required={true}
          controllerName={"group_name"}
          className={"rounded-md w-full"}
          disabled={true}
        />
      </div>
    </CardForm>
  );
};

const BeritaAcaraComittee = ({ type }) => {
  return (
    <CardForm label="Berita Acara Komite">
      <div className="flex flex-col gap-5">
        {/* <InputForm
          label="Dasar Hukum"
          required={true}
          controllerName={"legal_basis"}
          className={"rounded-md w-full"}
        /> */}
        <RichEditorForm name={`legal_basis`} label={"Dasar Hukum"} />
        <RichEditorForm
          name={`materials_of_committee_discussions`}
          label={"Materi Pembahasan Komite"}
        />
        <RichEditorForm
          name={`results_of_committee_discussions`}
          label={"Hasil Pembahasan Komite"}
        />
        <RichEditorForm name={`recommendation`} label={"Rekomendasi"} />
      </div>
    </CardForm>
  );
};

const Clause = ({ type, methods, service, risalahId }) => {
  const gracePeriod = useWatch({
    name: "grace_period",
    control: methods.control,
  });
  const errors = methods.formState.errors;

  return (
    <LayoutGroup>
      <CardForm label="Ketentuan">
        <motion.div layout className="flex flex-col gap-5">
          {service && (
            <AtomSelect
              label="Jenis Layanan"
              wrapperClass={"flex flex-col justify-end"}
              controllerName={"provision_service"}
              options={[
                { value: "Tunda Tebang", label: "Tunda Tebang" },
                {
                  value: "Hasil Hutan Bukan Kayu",
                  label: "Hasil Hutan Bukan Kayu",
                },
                {
                  value: "Komoditas Non Kehutanan",
                  label: "Komoditas Non Kehutanan",
                },
              ]}
              className="basic-single w-full"
              placeholder="Jenis Layanan"
              required={true}
            />
          )}
          <AtomSelect
            label="Skema Penyaluran FDB"
            wrapperClass={"flex flex-col justify-end"}
            controllerName={"fdb_distribution_scheme"}
            options={
              service
                ? [
                    { value: "DIRECT", label: "Langsung" },
                    { value: "INDIRECT", label: "Tidak Langsung" },
                  ]
                : [{ value: "DIRECT", label: "Langsung" }]
            }
            className="basic-single w-full"
            placeholder="Skema Penyaluran FDB"
            required={true}
          />
          <RichEditorForm name={`fdb_objectives`} label={"Tujuan FDB"} />
          <InputFormRadio
            label={"Grace Period"}
            labelClass={"w-full"}
            controllerName={`grace_period`}
            usingGrid={true}
            gridClassName="grid grid-cols-2 gap-4 w-full"
            className="flex items-center justify-between flex-wrap gap-2"
            required={true}
            values={[
              {
                label: "Disesuaikan Dengan Siklus Usaha",
                value: "ADJUSTED_TO_THE_BUSINESS_CYCLE",
              },
              {
                label: "Kustomisasi Grace Period",
                value: "GRACE_PERIOD_CUSTOMIZATION",
              },
            ]}
          />
          {gracePeriod === "GRACE_PERIOD_CUSTOMIZATION" && (
            <div className="w-full grid grid-cols-2 gap-4 items-end">
              <InputForm
                label={"Satuan Waktu Grace Period"}
                required={true}
                controllerName={"grace_period_time_unit_value"}
                className="w-full rounded-md border p-3 "
                onKeyPress={(event) => {
                  const ASCIICode = event.which ? event.which : event.keyCode;
                  if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                    return;
                  event.preventDefault();
                }}
              />
              <AtomSelect
                wrapperClass={"flex flex-col justify-end"}
                controllerName={`grace_period_time_unit`}
                options={[
                  { value: "YEAR", label: "Tahun" },
                  { value: "MONTH", label: "Bulan" },
                  { value: "DAY", label: "Hari" },
                ]}
                className={`basic-single w-full ${
                  !isEmpty(errors) &&
                  !isEmpty(errors?.grace_period_time_unit_value)
                    ? "mb-[26px]"
                    : ""
                }`}
                required={true}
              />
            </div>
          )}
          <RichEditorForm
            name={`fdb_loan_term`}
            label={"Jangka Waktu Pinjaman FDB"}
          />
          <div className="w-full grid grid-cols-2 gap-4">
            <InputForm
              label={"Jangka Waktu Pembiayaan FDB"}
              required={true}
              controllerName={"fdb_financing_term_value"}
              className="w-full rounded-md border p-3 "
              onKeyPress={(event) => {
                const ASCIICode = event.which ? event.which : event.keyCode;
                if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                  return;
                event.preventDefault();
              }}
            />
            <AtomSelect
              wrapperClass={"flex flex-col justify-end"}
              controllerName={`fdb_financing_term_period`}
              options={[
                { value: "YEAR", label: "Tahun" },
                { value: "MONTH", label: "Bulan" },
                { value: "DAY", label: "Hari" },
              ]}
              className={`basic-single w-full ${
                !isEmpty(errors) && !isEmpty(errors?.fdb_financing_term_value)
                  ? "mb-[26px]"
                  : ""
              }`}
              required={true}
            />
          </div>
          <RichEditorForm name={`guarantee`} label={"Jaminan"} />
          <p className="font-semibold">Tarif Layanan</p>
          <div className="grid grid-cols-3 gap-4">
            {service && (
              <InputForm
                label={"Persentase Pertahun"}
                required={true}
                controllerName={"percentage_service_rate_per_year"}
                className="w-full rounded-md border p-3 "
                rightIcon="%"
                onKeyPress={(event) => {
                  const ASCIICode = event.which ? event.which : event.keyCode;
                  if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                    return;
                  event.preventDefault();
                }}
              />
            )}
            <AtomSelect
              label={"Metode Pemberlakuan"}
              wrapperClass={"flex flex-col justify-end"}
              controllerName={`service_rates_application_method`}
              options={[
                { value: "FIXED", label: "Fixed" },
                { value: "FLOATING", label: "Floating" },
              ]}
              className="basic-single w-full"
              required={true}
              placeholder="Metode Pemberlakuan"
            />
            <AtomSelect
              label={"Metode Perhitungan Tarif"}
              wrapperClass={"flex flex-col justify-end"}
              controllerName={`service_rate_calculation_method`}
              options={[
                { value: "EFFECTIVE", label: "Efektif" },
                { value: "FLAT", label: "Flat" },
              ]}
              className="basic-single w-full"
              required={true}
              placeholder="Metode Perhitungan Tarif"
            />
          </div>
        </motion.div>
        <AccordionForms type="FDB_DISTRIBUTION" name="fdb_distribution" />
        <AccordionForms type="FDB_RETURN" name="fdb_return" />
        <RichEditorForm
          name={"the_object_being_financed"}
          label="Objek yang dibiayai"
        />
        <AccordionForms type="POSITIVE_COVENANTS" name="positive_covenants" />
        <AccordionForms type="NEGATIVE_COVENANTS" name="negative_covenants" />
        <RequestFdb risalahId={risalahId} />
      </CardForm>
    </LayoutGroup>
  );
};

const RequestFdb = ({ risalahId }) => {
  const [accordion, setAccordion] = useState(false);

  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    filter: {
      page_applicant: 1,
      limit_applicant: 10,
      is_committee_minutes: true
    },
  });

  const { data } = useQuery(
    ["DETAIL_RISALAH", risalahId, filters],
    getDetailRisalah,
    {
      enabled: !!risalahId,
      select: (data) => data?.data?.data?.data?.applicant,
    }
  );

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "group_name",
        title: "KTHR",
      },
      {
        key: "name",
        title: "Nama Permohonan",
      },
      {
        key: "business_type",
        title: "Jenis Usaha Yang dibiayai",
      },
      {
        key: "application_value",
        title: "Nilai Pembiayaan",
        render: ({ item }) => {
          return formatCurrency(item?.application_value);
        },
      },
      // {
      //   key: "lendDistribution",
      //   title: "Penyaluran Pembiayaan Pinjaman",
      // },
      // {
      //   key: "grace_period",
      //   title: "Grace Period",
      // },
      // {
      //   key: "returnSchema",
      //   title: "Skema Pengembalian",
      //   render: ({ item }) => (
      //     <Badge color={stateColors[item.state]}>{item.state}</Badge>
      //   ),
      // },
      // {
      //   key: "action",
      //   title: "Aksi",
      //   alignment: "center",
      //   className: "sticky right-0 bg-white",
      //   render: ({ item }) => (
      //     <>
      //       <Button
      //         variant="icon"
      //         label={<RiEyeLine className="text-lg" />}
      //         onClick={() => {
      //           navigate(`/member-in-group-ba/${item.id}`);
      //         }}
      //       />
      //     </>
      //   ),
      // },
    ];
  }, [navigate]);

  const items = useMemo(() => {
    return [
      {
        id: 1,
        kthr: "Tani Cinta Damai",
        name: "John Doe",
        type: "Usaha A",
        totalFunds: "Rp 10.000.000",
        lendDistribution: "Sekaligus",
        gracePeriod: "1 Tahun",
        state: "Revolving",
      },
      {
        id: 2,
        kthr: "Tani Cinta Damai",
        name: "John Doe",
        type: "Usaha B",
        totalFunds: "Rp 10.000.000",
        lendDistribution: "Sekaligus",
        gracePeriod: "1 Tahun",
        state: "Non - Revolving",
      },
    ];
  }, []);

  return (
    <>
      <AccordionButton
        key={"request-fdb"}
        label={"Permohonan FDB"}
        isOpen={accordion}
        onClick={() => setAccordion(!accordion)}
      />
      <AnimatePresence mode="wait">
        {accordion && (
          <motion.div
            layout
            initial={{ opacity: 0, y: -15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="w-full bg-slate-100 p-5 rounded-md flex flex-col"
          >
            <TableWrapper className="mt-4">
              <Table headers={headers} items={data?.data} isLoading={false} />
              <Pagination
                page={data?.meta?.current_page}
                limit={data?.meta?.per_page}
                total={data?.meta?.total}
                totalPage={data?.meta?.last_page}
                onLimitChange={(e) =>
                  setFilters((prev) => ({
                    page_applicant: 1,
                    limit_applicant: e,
                  }))
                }
                onPageChange={(e) =>
                  setFilters((prev) => ({ ...prev, page_applicant: e }))
                }
              />
              {/* <TablePagination
                totalPage={data?.meta?.last_page}
                totalItems={data?.meta?.total}
                onChange={(e) =>
                  setFilters((prev) => ({ ...prev, page_applicant: e }))
                }
                onItemsPerPageChange={(e) =>
                  setFilters((prev) => ({ ...prev, limit_applicant: e }))
                }
              /> */}
            </TableWrapper>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default CreateBeritaAcara;
