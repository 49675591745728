import React, { useCallback, useState } from "react";
import ReactSelect from "react-select";
import { useFormContext, Controller } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { getListMeetingAgenda } from "services/institutions/meeting-agenda";
import { useLocation } from "react-router";
import { getCommitteeMeetingAgenda } from "services/fdb/committee";

let NEXT_PAGE = null;

const SelectMinutes = ({
  placeholder = "No Surat Edaran Rapat Risalah",
  ...props
}) => {
  const location = useLocation();

  const { name, customOnChange } = props;
  const { control } = useFormContext();

  const [isOpenAgenda, setOpenAgenda] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [agendaOptions, setAgendaOptions] = useState([]);

  const { status: statusInstitution } = useQuery({
    queryKey: [
      "meeting_circular_minutes_number",
      { page: currPage, limit: 100 },
    ],
    // queryFn: async (props) => {
    //   const { data } = await getListMeetingAgenda(props);
    //   if (data?.data?.data?.length > 0) {
    //     return data?.data;
    //   }
    //   return [];
    // },
    queryFn: getListMeetingAgenda,
    select: (data) => data?.data?.data,
    enabled: isOpenAgenda && location.pathname.includes("institutions"),
    onSuccess: (datas) => {
      if (datas?.meta?.next_page_url) {
        NEXT_PAGE = currPage + 1;
      } else NEXT_PAGE = null;
      if (datas?.data?.length > 0) {
        setAgendaOptions((curr) => {
          const newDatas = datas.data.map((x) => ({
            ...x,
            value: x.id,
            label: `${x.topic_discussion}`,
          }));
          return [...curr, ...newDatas];
        });
      }
    },
  });

  const { status } = useQuery({
    queryKey: [
      "meeting_circular_minutes_number",
      { page: currPage, limit: 100 },
    ],
    queryFn: async () => {
      const { data } = await getCommitteeMeetingAgenda({
        page: currPage,
        limit: 100,
        filter: {
          is_committee: true,
        },
      });
      if (data?.data?.data?.length > 0) {
        return data?.data;
      }
      return [];
    },

    // select: (data) => data?.data?.data,
    enabled: isOpenAgenda && !location.pathname.includes("institutions"),
    onSuccess: (datas) => {
      if (datas?.meta?.next_page_url) {
        NEXT_PAGE = currPage + 1;
      } else NEXT_PAGE = null;
      if (datas?.data?.length > 0) {
        setAgendaOptions((curr) => {
          const newDatas = datas.data.map((x) => ({
            ...x,
            value: x.meeting_circular_number_minutes,
            label: `${x.meeting_circular_number_minutes} (${x.topic_discussion})`,
          }));
          return [...curr, ...newDatas];
        });
      }
    },
  });

  const onMenuOpen = useCallback(() => setOpenAgenda(true), []);

  const onMenuClose = useCallback(() => {
    setOpenAgenda(false);
    setAgendaOptions([]);
    setCurrPage(1);
    NEXT_PAGE = null;
  }, []);

  const getMoreOptions = useCallback(() => {
    if (NEXT_PAGE) setCurrPage(NEXT_PAGE);
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => (
        <div className="w-full">
          <ReactSelect
            {...props}
            options={agendaOptions}
            isLoading={(status ?? statusInstitution) === "loading"}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            onMenuScrollToBottom={getMoreOptions}
            placeholder={placeholder}
            value={value}
            onChange={(val) => {
              onChange(val);
              if (customOnChange) customOnChange(val);
            }}
            onBlur={onBlur}
            styles={{
              control: (provided, state) => ({
                ...provided,
                borderColor: invalid
                  ? "#F04438"
                  : state.isFocused
                  ? "#01A24A"
                  : "#EAECF0",
                ":hover": {
                  ...provided[":hover"],
                  borderColor: invalid
                    ? "#F04438"
                    : state.isFocused
                    ? "#01A24A"
                    : "#EAECF0",
                },
                boxShadow: "unset",
                "&:hover": {
                  outline: "unset",
                },
                "&:focus": {
                  border: "1px solid #01A24A",
                },
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                padding: "0.25rem 1rem",
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? "#01A24A" : "white",
                color: state.isSelected ? "white" : "black",
                "&:hover": {
                  backgroundColor: state.isSelected ? "#01A24A" : "#F3F4F6",
                  color: state.isSelected ? "white" : "black",
                },
                zIndex: 9999,
              }),
              multiValue: (provided, state) => ({
                ...provided,
                backgroundColor: "#01A24A",
                color: "white",
              }),
              multiValueLabel: (provided, state) => ({
                ...provided,
                color: "white",
              }),
              multiValueRemove: (provided, state) => ({
                ...provided,
                color: "white",
                "&:hover": {
                  backgroundColor: "#01A24A",
                  color: "white",
                },
              }),
            }}
            menuPortalTarget={document.body}
          />
          {invalid && (
            <div className="text-xs text-error-600 mt-2">{error?.message}</div>
          )}
        </div>
      )}
    />
  );
};

export default React.memo(SelectMinutes);
