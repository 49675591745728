import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getAgencyDisbursementAssignList = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-disbursement-assign`,
    {
      params: queryKey[1],
    }
  );
};

export const getAgencyDisbursementAssignDetail = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-disbursement-assign/${queryKey[1]}`
  );
};

export const createAgencyDisbursementAssign = (id, payload) => {
  return axiosInstance.patch(
    `${general_config_v2.BASE_URL}/v1/cms/agency-disbursement-assign/${id}`,
    payload
  );
};

export const getAgencyDisbursementExecutorList = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-disbursement-executor`,
    { params: queryKey[1] }
  );
};

export const getAgencyDisbursementExecutorDetail = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-disbursement-executor/${queryKey[1]}`,
    { params: queryKey[2] }
  );
};

export const putAgencyDisbursementExecutor = ({ id, data }) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/agency-disbursement-executor/${id}`,
    data
  );
};

export const getAgencyDisbursementDirectionList = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-disbursement-direction`,
    { params: queryKey[1] }
  );
};

export const getAgencyDisbursementDirectionDetail = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-disbursement-direction/${queryKey[1]}`,
    { params: queryKey[2] }
  );
};

export const putAgencyDisbursementDirection = ({ id, data, params }) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/agency-disbursement-direction/${id}`,
    data,
    { params }
  );
};

// export const getDisburstmentsDirectorateDetail = ({ queryKey }) => {
//   return axiosInstance.get(
//     `${general_config_v2.BASE_URL}/v1/cms/disbursement-directorate/${queryKey[1]}`,
//     { params: queryKey[2] }
//   );
// };

// export const putDisburstmentsDirectorate = ({ id, params }) => {
//   return axiosInstance.put(
//     `${general_config_v2.BASE_URL}/v1/cms/disbursement-directorate/${id}`,
//     {},
//     { params }
//   );
// };
