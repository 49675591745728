import React, { Fragment } from "react";
import { InputForm } from "components";
import { customStyles } from "components/atoms/Select";
import Select from "react-select";
import { useFormContext, useWatch } from "react-hook-form";

const UnitOptions = [
  { value: "Minggu", label: "Minggu" },
  { value: "Bulan", label: "Bulan" },
  { value: "Tahun", label: "Tahun" },
];

const StoreFormSection = () => {
  const methods = useFormContext();
  const bussiness_cycle_unit = useWatch({
    control: methods.control,
    name: "bussiness_cycle_unit",
  });
  return (
    <Fragment>
      <p className="font-bold text-[#01A24A]">Penjualan</p>
      <div className="flex space-x-5">
        <InputForm
          controllerName={`selling_price`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          label="Harga Penjualan"
          placeholder="Harga Penjualan"
          prefix="Rp"
          required
          isNumber={true}
          onKeyPress={(event) => {
            const ASCIICode = event.which ? event.which : event.keyCode;
            if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
              return;
            event.preventDefault();
          }}
        />
        <InputForm
          controllerName={`hpp`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          label="HPP"
          placeholder="HPP"
          prefix="Rp"
          required
          isNumber={true}
          onKeyPress={(event) => {
            const ASCIICode = event.which ? event.which : event.keyCode;
            if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
              return;
            event.preventDefault();
          }}
        />
      </div>
      <InputForm
        controllerName={`income`}
        className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
        label="Pendapatan"
        placeholder="Pendapatan"
        prefix="Rp"
        required
        isNumber={true}
        onKeyPress={(event) => {
          const ASCIICode = event.which ? event.which : event.keyCode;
          if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57)) return;
          event.preventDefault();
        }}
      />
      <div className="grid grid-cols-2 gap-4">
        <InputForm
          label={"Siklus Usaha"}
          required={true}
          controllerName={"bussiness_cycle"}
          className="w-full rounded-md border p-3"
          placeholder="Siklus Usaha"
          isNumber={true}
          onKeyPress={(event) => {
            const ASCIICode = event.which ? event.which : event.keyCode;
            if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
              return;
            event.preventDefault();
          }}
        />
        <div className="flex flex-col gap-1 items-center w-full">
          <span className="text-transparent">-</span>
          <Select
            styles={customStyles}
            className="w-full"
            options={UnitOptions}
            value={UnitOptions.find((x) => x.value === bussiness_cycle_unit)}
            onChange={(e) => {
              methods.setValue("bussiness_cycle_unit", e.value);
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default StoreFormSection;
