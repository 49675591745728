import React from "react";
import { useFormContext } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";

import { fileUpload } from "services/fileService";

import { InputForm, InputFormUpload } from "components";
import AtomDatePicker from "components/atoms/Datepicker";
import CardForm from "components/molecules/CardForm";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";

const Minutes = ({ data }) => {
  const methods = useFormContext();

  const location = useLocation();
  const [searchParams] = useSearchParams();

  const isView =
    searchParams.get("mode") !== null || location?.state?.status > 1;

  const fileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      if (file?.controllerName === "minutes_file_temp") {
        methods.register("agency_file");
        methods.setValue("agency_file", response.data.data.id);
      }
    } catch (error) {
      throw error;
    }
  });

  return (
    <CardForm label="Risalah Lembaga Penyalur">
      <div className="space-y-5">
        <div className="flex gap-4">
          <InputForm
            label="Nomor Risalah"
            required={true}
            controllerName={"number"}
            className={"rounded-md w-full"}
            defaultValue={data?.risalah_agency?.number}
            disabled
          />
          <AtomDatePicker
            controllerName={"created"}
            label="Tanggal Terbit Risalah"
            placeholder="dd/mm/yyyy"
            datepickerOptions={{
              dateFormat: "dd/MM/yyyy",
            }}
            showErrorLabel
            required
            defaultValue={new Date(data?.risalah_agency?.created)}
            disabled
          />
        </div>
        <InputFormUpload
          uploadFile={fileMutation}
          controllerName={`minutes_file_temp`}
          label={"Upload Risalah Lembaga Penyalur"}
          fileType="PDF, Docx"
          disabled={isView}
        />
      </div>
    </CardForm>
  );
};

export default Minutes;
