import { useMemo } from "react";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";

import { InputForm, InputFormRadio } from "components";
import { CardPrimary } from "components/atoms/Card";
import CardForm from "components/molecules/CardForm";
import UploadedFileAsync from "components/molecules/UploadFileAsync";
import Table, { TableWrapper } from "components/atoms/Table";

import { formatCurrency, getErrorMessage } from "helpers";

import VerificationIncomeInfoModal from "../../VerificationIncomeInfo";
import { useFormContext } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { getApplicantIncomeInfo } from "services/fdb/field-analis";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router";
import { isArray } from "lodash";

const NonFundedOtherBusinessesTable = ({ data, isLoading }) => {
  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "groupName",
        title: "Jenis Usaha/Pekerjaan",
        render: ({ item }) => {
          return item?.other_business_type?.name;
        },
      },
      {
        key: "income",
        title: "Perkiraan Pendapatan",
        render: ({ item }) => {
          return item?.income ? formatCurrency(item?.income) : "-";
        },
      },
      {
        key: "cycle_income",
        title: "Siklus Pendapatan",
        render: ({ item }) => {
          return `${item?.income_cycle ?? ""} ${item?.income_cycle_unit ?? ""}`;
        },
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        render: ({ item }) => (
          <VerificationIncomeInfoModal
            status={item?.is_verified}
            data={item}
          />
        ),
      },
    ];
  }, []);

  const items = useMemo(() => {
    return data ?? [];
  }, [data]);

  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={items} isLoading={isLoading} />
    </TableWrapper>
  );
};

const OtherBusiness = ({ data }) => {
  const methods = useFormContext();
  const { applicantId } = useParams();

  const { data: incomeInfoData, isLoading } = useQuery({
    queryKey: ["field-analyst-applicant-income-info", applicantId],
    queryFn: async () => {
      const { data } = await getApplicantIncomeInfo(applicantId);
      return data;
    },
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    retry: false,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  const estimatedIncome = useMemo(() => {
    if (incomeInfoData?.length > 0) {
      return incomeInfoData.reduce(
        (accumulator, current) => accumulator + current.income,
        0
      );
    }
    return 0;
  }, [incomeInfoData]);

  const tabulation_payability_verification = methods.watch("tabulation_payability_verification");

  return (
    <CardForm label="Usaha Lainnya Selain yang Dibiayai">
      <div className="bg-[#E1F1D6] my-5 p-3 w-full">
        <p className="font-bold text-[#01A24A]">
          Pendapatan Selain Dari Usaha/Pekerjaan Lainnya
        </p>
      </div>
      <NonFundedOtherBusinessesTable data={isArray(incomeInfoData) ? incomeInfoData : []} isLoading={isLoading} />
      <div className="bg-[#E1F1D6] my-5 p-3 w-full">
        <p className="text-sm">Total Estimasi Pendapatan Diterima</p>
        <p className="font-bold text-[#01A24A]">
          {formatCurrency(estimatedIncome)}
        </p>
      </div>
      {data?.salary_slip_file && (
        <div className="grid grid-cols-2 gap-4">
          <UploadedFileAsync
            id={data?.salary_slip_file}
            name="Dokumen Pendukung Pendapatan (Slip Gaji/Laporan Keuangan Usaha)"
          />
        </div>
      )}

      <div className="bg-[#E1F1D6] my-5 p-3 w-full">
        <p className="font-bold text-[#01A24A]">Pengeluaran Rumah Tangga</p>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div>
          <div className="font-semibold">Pengeluaran Rutin Per Bulan</div>
          <div>
            {data?.group_profile_member?.routine_outcome_per_month
              ? formatCurrency(
                  data?.group_profile_member?.routine_outcome_per_month
                )
              : "-"}
          </div>
        </div>
        <div>
          <div className="font-semibold">Pengeluaran Terbesar</div>
          <div>
            {data?.group_profile_member?.biggest_outcome
              ? formatCurrency(data?.group_profile_member?.biggest_outcome)
              : "-"}
          </div>
        </div>
        <div>
          <div className="font-semibold">Penggunaan Pengeluaran Terbesar</div>
          <div>
            {data?.group_profile_member?.use_largest_outcome
              ? formatCurrency(data?.group_profile_member?.use_largest_outcome)
              : "-"}
          </div>
        </div>
      </div>
      <CardPrimary label={"Tabulasi Kemampuan Bayar"} noBg>
        <div className="grid grid-cols-1 lg:grid-cols-1 gap-6">
          <InputForm
            required
            controllerName={"tabulation_payability_monthly_income"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={
              "1. Berapa Perkiraan Penghasilan dari Usaha Harian Per Bulan?"
            }
            placeholder={"Perkiraan Penghasilan dari Usaha Harian Per Bulan"}
            isNumber={true}
            prefix="Rp"
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
          />
          <InputForm
            required
            controllerName={"tabulation_payability_monthly_outcome"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={"2. Berapa Perkiraan Pengeluaran Harian Per Bulan?"}
            placeholder={"Perkiraan Pengeluaran Harian Per Bulan"}
            isNumber={true}
            prefix="Rp"
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
          />
          <InputForm
            required
            controllerName={"tabulation_payability_monthly_net_income"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={"3. Berapa Pendapatan Net Per Bulan?"}
            placeholder={"Pendapatan Net Per Bulan"}
            isNumber={true}
            prefix="Rp"
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
          />
          <div className="border rounded-md px-4 py-3">
            <InputFormRadio
              required
              label={"Hasil Verifikasi"}
              controllerName={`tabulation_payability_verification`}
              className="flex items-center justify-between gap-4"
              values={[
                {
                  label: (
                    <>
                      <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                      Kesimpulan Verivikasi
                    </>
                  ),
                  value: true,
                },
                {
                  label: (
                    <>
                      <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                      Data Belum Sesuai/ Ada Kesalahan
                    </>
                  ),
                  value: false,
                },
              ]}
            />
            {tabulation_payability_verification !== "" &&
              tabulation_payability_verification === false && (
                <InputForm
                  controllerName={"tabulation_payability_verification_desk"}
                  textArea={true}
                  label={"Jelaskan kesalahan/ ketidak sesuaian datanya"}
                  className={"w-full rounded-md border p-3"}
                  wrapperClass={"mt-5"}
                />
              )}
          </div>
        </div>
      </CardPrimary>
    </CardForm>
  );
};

export default OtherBusiness;
