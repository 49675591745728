import React from "react";
import { AuthConsumer } from "providers/authProvider";
import { Spinner } from "components";

const Logout = () => (
  <AuthConsumer>
    {({ logout }) => {
      logout();
      return (
        <div className="relative min-h-screen">
          <Spinner />
        </div>
      );
    }}
  </AuthConsumer>
);

export default Logout;
