import React, { useMemo } from "react";
import CardForm from "components/molecules/CardForm";

import Table, { TableWrapper } from "components/atoms/Table";
import { Pagination } from "components/v2/shared/pagination";
import { formatCurrency } from "helpers";

const FDBPotention = ({
  data,
  changePagePotention,
  changeLimitPotention,
  filterTable,
}) => {
  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "debtor_name",
        title: "Nama Debitur",
      },
      {
        key: "debtor_identity_number",
        title: "NIK",
      },
      {
        key: "debtor_age",
        title: "Umur",
        render: ({ item }) => `${item.debtor_age} Tahun`,
      },
      {
        key: "debtor_address",
        title: "Alamat",
      },
      {
        key: "purpose_using_fdb",
        title: "Tujuan Penggunaan FDB",
      },
      {
        key: "name",
        title: "Nilai Pembiayaan",
        render: ({ item }) => {
          return item?.application_value
            ? `${formatCurrency(item?.application_value)}`
            : "-";
        },
      },
      {
        key: "loan_term",
        title: "Jangka Waktu Pinjaman",
      },
      {
        key: "tree_type",
        title: "Tipe Pohon",
      },
      {
        key: "tree_total",
        title: "Total Pohon",
      },
    ];
  }, []);
  return (
    <CardForm label="Potensi Penerima FDB">
      <div className="space-y-4">
        <TableWrapper>
          <Table
            headers={headers}
            items={data?.potention?.data || []}
            isLoading={false}
          />
          <Pagination
            page={Number(data?.potention?.meta?.current_page || 1)}
            limit={Number(filterTable?.limit_potention || 10)}
            total={Number(data?.potention?.meta?.total || 0)}
            totalPage={Number(data?.potention?.meta?.last_page || 1)}
            onLimitChange={(e) => changeLimitPotention(e)}
            onPageChange={(e) => changePagePotention(e)}
          />
        </TableWrapper>
      </div>
    </CardForm>
  );
};

export default FDBPotention;
