/* eslint-disable import/no-anonymous-default-export */
import Agreement from "pages/FDB/FinancingApplication/Agreement";
import AgreementDetail from "pages/FDB/FinancingApplication/Agreement/Detail";

export default [
  {
    routeType: "private",
    path: "/agreement",
    element: Agreement,
    exact: true,
    sidebar: true,
    key: "agreement",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/agreement/:id/review",
    element: AgreementDetail,
    exact: true,
    sidebar: true,
    key: "agreement",
    type: "canUpdate"
  },
];
