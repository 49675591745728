import React, { useCallback, useEffect, useRef } from "react";

const Input = React.forwardRef((props, passedRef) => {
  const {
    className = "",
    hookForm = {},
    wrapperClass,
    prepend,
    rightIcon,
    prefix,
    ...restProps
  } = props;

  const ref = useRef(passedRef?.current);
  const unmasked = useRef();

  const onMaska = useCallback((event) => {
    // store the unmasked value;
    unmasked.current = event?.detail?.unmasked;
  }, []);
  const onInput = useCallback((e) => {
    // trigger the passed onChanged handler
    if (props.onChange) {
      props.onChange(e);
    }
  }, []);

  useEffect(() => {
    const currRef = passedRef?.current || ref?.current;

    if (props["data-maska"]) {
      currRef?.addEventListener("maska", onMaska);

      // we need to register input event here to trigger onChanged handler
      // as react hook form does not save the value if maska is used
      // so when validation is run, the field will be rendered empty
      currRef?.addEventListener("input", onInput);
    }

    return () => {
      const currRef = passedRef?.current || ref?.current;

      if (props["data-maska"]) {
        currRef?.removeEventListener("maska", onMaska);
        currRef?.removeEventListener("maska", onInput);
      }
    };
  }, []);

  return (
    <div
      className={`relative border ${
        props.disabled ? "bg-[#F4F5F2]" : "bg-white"
      } ${props.className || ""}`}
      style={{ padding: "0.25rem" }}
    >
      {prefix && (
        <span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none -z-0">
          {prefix}
        </span>
      )}
      <input
        ref={ref}
        {...props}
        className={`w-full focus:outline-none py-1 focus:border-primary-700 disabled:text-gray-400 ${
          prefix ? "pl-9" : "pl-3"
        }`}
        style={{ paddingRight: props?.rightIcon ? "2.75rem" : "0", zIndex: 2 }}
      />
      {props.rightIcon && (
        <div className="absolute right-0 inset-y-0 flex items-center pr-3">
          {props.rightIcon}
        </div>
      )}
    </div>
  );
});

export default Input;
