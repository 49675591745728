import { motion } from "framer-motion";
import React from "react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

const AccordionButton = React.forwardRef(
  ({ className, isOpen, label, ...rest }, ref) => {
    return (
      <motion.button
        ref={ref}
        layout
        type={"button"}
        className={[
          "w-full text-left flex px-6 py-5 bg-primary-subgreen1-100 rounded-lg font-semibold text-primary-maincolor-700",
          className,
        ].join(" ")}
        {...rest}
      >
        <div className={"flex-1 overflow-hidden text-ellipsis min-w-[0]"}>
          {label}
        </div>

        <div className={"shrink-0 space-x-2"}>
          {isOpen && (
            <>
              Tutup <RiArrowUpSLine className={"w-5 h-5 inline-block"} />
            </>
          )}
          {!isOpen && (
            <>
              Buka <RiArrowDownSLine className={"w-5 h-5 inline-block"} />
            </>
          )}
        </div>
      </motion.button>
    );
  }
);

AccordionButton.propTypes = {};

export default AccordionButton;
