import OfferNegotiation from "pages/OfferNegotiation";
import OfferNegotiationAdd from "pages/OfferNegotiation/Create";
import ListMember from "pages/OfferNegotiation/ListMember";
import FinancingDetail from "pages/OfferNegotiation/ListMember/Detail";
import OfferNegotiationTemplate from "pages/OfferNegotiation/Template";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    routeType: "private",
    path: "/offer-negotiation",
    element: OfferNegotiation,
    exact: true,
    sidebar: true,
    key: "offer_negotiation",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/offer-negotiation/add",
    element: OfferNegotiationAdd,
    exact: true,
    sidebar: true,
    key: "offer_negotiation",
    type: "canCreate"
  },
  {
    routeType: "private",
    path: "/offer-negotiation/template",
    element: OfferNegotiationTemplate,
    exact: true,
    sidebar: true,
    key: "offer_negotiation",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/offer-negotiation/list-member/:id",
    element: ListMember,
    exact: true,
    sidebar: true,
    key: "offer_negotiation",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/offer-negotiation/list-member/:id/:applicantId",
    element: FinancingDetail,
    exact: true,
    sidebar: true,
    key: "offer_negotiation",
    type: "canUpdate"
  },
];
