import CardForm from "components/molecules/CardForm";
import UploadedFileAsync from "components/molecules/UploadFileAsync";
import { groupBy } from "lodash";
import React from "react";

const FinanceDocument = ({ data }) => {
  const renderOpinion = (val) => {
    switch (val) {
      case "reasonable_without_exceptions":
        return "Wajar Tanpa Pengecualian";
      case "unqualified_with_paragraph":
        return "Wajar Tanpa Pengecualian dengan Paragraf";
      case "reasonable_with_exceptions":
        return "Wajar Dengan Pengecualian";
      case "unreasonable":
        return "Tidak Wajar";
      default:
        return "Tidak Memberikan Pendapat";
    }
  };

  const quarters = groupBy(data?.quarters, "year");
  return (
    <CardForm label="Dokumen Keuangan">
      <div className="space-y-4">
        {data?.reports
          ?.sort((a, b) => a.year - b.year)
          .map((report, index) => (
            <div key={index} className="space-y-3">
              <div className="bg-[#E1F1D6] my-5 p-3 w-full">
                <p className="font-bold text-[#01A24A]">{`Laporan Keuangan Lembaga - Tahun ${report.year}`}</p>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <div className="font-semibold">Auditor</div>
                  <div>{report?.name ?? "-"}</div>
                </div>
                <div>
                  <div className="font-semibold">Opini Auditor</div>
                  <div>{renderOpinion(report?.opinion) ?? "-"}</div>
                </div>
                <UploadedFileAsync
                  id={report?.file}
                  name={`Laporan Keuangan ${report.year}`}
                />
              </div>
            </div>
          ))}
        {Object.keys(quarters).map((quarter, index) => (
          <div key={index} className="space-y-3">
            <div className="bg-[#E1F1D6] my-5 p-3 w-full">
              <p className="font-bold text-[#01A24A]">{`Laporan Keuangan Lembaga - Tahun ${quarter}`}</p>
            </div>
            {quarters[quarter]?.length > 0 &&
              quarters[quarter]
                ?.sort((a, b) => a.report - b.report)
                .map((q, idx) => {
                  return (
                    <div className="grid grid-cols-2 gap-4" key={idx}>
                      <div>
                        <div className="font-semibold">Laporan Keuangan</div>
                        <div>{`Kuartal ${q?.report}`}</div>
                      </div>
                      <UploadedFileAsync
                        id={q?.report_file}
                        name={`Laporan Keuangan ${q.year} Q${q?.report}`}
                      />
                    </div>
                  );
                })}
          </div>
        ))}
      </div>
    </CardForm>
  );
};

export default FinanceDocument;
