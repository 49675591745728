import * as yup from "yup";

export const schemaStep1 = yup.object({
  debitur_verification: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .label("Hasil Verifikasi Detail Calon Debitur"),
  debitur_verification_reason: yup
    .string()
    .when("debitur_verification", ([value], schema) => {
      if (!value)
        return schema.required().label("Kesalahan/Ketidak sesuaian data");

      return schema.notRequired();
    }),
  address_domicile_verification: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Hasil Verifikasi Alamat"),
  address_domicile_verification_reason: yup
    .string()
    .when("address_domicile_verification", ([value], schema) => {
      if (!value)
        return schema.required().label("Kesalahan/Ketidak sesuaian data");

      return schema.notRequired();
    }),
  couple_verification: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Hasil Verifikasi Pasangan"),
  couple_verification_reason: yup
    .string()
    .when("couple_verification", ([value], schema) => {
      if (!value)
        return schema.required().label("Kesalahan/Ketidak sesuaian data");

      return schema.notRequired();
    }),
  credit_history_status_social_assistance: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Bukan penerima Bansos (Kemensos)"),
  credit_history_status_sikp: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Pengecekan SIKP tidak memiliki pinjaman"),
  credit_history_status_bi_checking: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label(
      "Pengecekan BI Checking/SUK/Perindo tidak memiliki catatan kredit buruk"
    ),
});

export const schemaStep2 = yup.object({
  tabulation_financed_bussiness: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Usaha yang dibiayai bukan merupakan pencaharian utama"),
  tabulation_bussiness_min_2years: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Lama usaha melebihi minimal 2 tahun"),
  tabulation_income_value: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Nilai Pendapatan dari usaha wajar"),
  tabulation_loan_value_adjusment: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label(
      "Apakah Penyesuaian nilai pinjaman berdasarkan prospek usaha Diperlukan"
    ),
  tabulation_adjusment_value: yup
    .string()
    .when("tabulation_loan_value_adjusment", ([value], schema) => {
      if (value === true) {
        return schema.required().label("Nilai Penyesuaiannya");
      }
      return schema.notRequired();
    }),
  tabulation_verification: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Kesimpulan Verifikasi"),
  tabulation_verification_reason: yup
    .string()
    .when("tabulation_verification", ([value], schema) => {
      if (!value) {
        return schema.required().label("Kesalahan/ketidak sesuaian data");
      }

      return schema.notRequired();
    }),
  household_estimated_payment: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required()
    .label("Estimasi Besaran Kemampuan Bayar per bulan"),
  household_income: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Nilai Pendapatan dari usaha wajar"),
  household_payment_capabiliyt: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Punya kemampuan bayar sesuai nilai permohonan"),
  household_loan_value_adjusment: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label(
      "Apakah Penyesuaian nilai pinjaman berdasarkan kemampuan bayar Diperlukan"
    ),
  household_adjusment_value: yup
    .string()
    .when("household_loan_value_adjusment", ([value], schema) => {
      if (value === true) {
        return schema.required().label("Nilai Penyesuaiannya");
      }

      return schema.notRequired();
    }),
  household_verification: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Kesimpulan Verifikasi"),
  household_verification_reason: yup
    .string()
    .when("household_verification", ([value], schema) => {
      if (!value) {
        return schema.required().label("Kesalahan/Ketidak sesuaian data");
      }

      return schema.notRequired();
    }),
  collateral_tabulation_price_used: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Harga yang digunakan wajar"),
  collateral_tabulation_tree_value_criteria: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label(
      "Apakah Nilai pohon memenuhi persyaratan nilai jaminan min. 125% dari nilai pinjaman"
    ),
  collateral_tabulation_loan_value_adjusment: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label(
      "Apakah Penyesuaian nilai pinjaman berdasarkan nilai pohon Diperlukan"
    ),
  collateral_tabulation_adjusment_value: yup
    .string()
    .when("collateral_tabulation_loan_value_adjusment", ([value], schema) => {
      if (value === true) {
        return schema.required().label("Nilai Penyesuaiannya");
      }

      return schema.notRequired();
    }),
  collateral_tabulation_verification: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Kesimpulan Verifikasi"),
  collateral_tabulation_verification_reason: yup
    .string()
    .when("collateral_tabulation_verification", ([value], schema) => {
      if (!value) {
        return schema.required().label("Kesalahan/Ketidak sesuaian data");
      }

      return schema.notRequired();
    }),
  land_ownership_doc_verification: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Kesimpulan Verifikasi"),
  land_ownership_doc_verification_reason: yup
    .string()
    .when("land_ownership_doc_verification", ([value], schema) => {
      if (!value) {
        return schema.required().label("Kesalahan/Ketidak sesuaian datanya");
      }

      return schema.notRequired();
    }),
  loan_value_recomendation: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required()
    .label("Rekomendasi Nilai Pinjaman"),
  fixed_loan_value_recomendation: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .label("Rekomendasi Nilai Pinjaman"),
  time_recomendation_periode_value: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required()
    .label("Rekomendasi Jangka Waktu"),
  time_recomendation_periode_type: yup
    .string()
    .required()
    .label("Rekomendasi Jangka Waktu"),
  disburstment: yup
    .string()
    .when("type_length_of_time_recomendation", ([value], schema) => {
      return schema.required().label("Tahap Pencairan");
    }),
  disburstment_phase_items: yup.array(
    yup
      .object({
        value: yup
          .number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .label("Tahap Pencairan"),
      })
      .when("disburstment", ([value], schema) => {
        if (value === "stage") {
          return schema.required();
        }
        return schema.notRequired();
      })
  ),
  return: yup.string().required().label("Pengembalian"),
  time_return_periode_value: yup.string().when("return", ([value], schema) => {
    if (value === "duration") {
      return schema.required().label("Jangka Waktu Pengembalian");
    }
    return schema.notRequired();
  }),
  time_return_periode_type: yup.string().when("return", ([value], schema) => {
    if (value === "duration") {
      schema.required().label("Jangka Waktu Pengembalian");
    }
    return schema.notRequired();
  }),
  installment_scheme: yup.string().required("Skema Angsuran wajib diisi"),
  notes_analyst: yup.string().required().label("Catatan Analis"),
  // TODO:
  // verification_status: yup.bool(),
});

export const schemaStep1PS = yup.object({
  debitur_verification: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .label("Hasil Verifikasi Detail Calon Debitur"),
  debitur_verification_reason: yup
    .string()
    .when("debitur_verification", ([value], schema) => {
      if (!value)
        return schema.required().label("Kesalahan/Ketidak sesuaian data");

      return schema.notRequired();
    }),
  address_domicile_verification: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Hasil Verifikasi Alamat"),
  address_domicile_verification_reason: yup
    .string()
    .when("address_domicile_verification", ([value], schema) => {
      if (!value)
        return schema.required().label("Kesalahan/Ketidak sesuaian data");

      return schema.notRequired();
    }),
  couple_verification: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Hasil Verifikasi Pasangan"),
  couple_verification_reason: yup
    .string()
    .when("couple_verification", ([value], schema) => {
      if (!value)
        return schema.required().label("Kesalahan/Ketidak sesuaian data");

      return schema.notRequired();
    }),
  credit_history_status_social_assistance: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Bukan penerima Bansos (Kemensos)"),
  credit_history_status_sikp: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Pengecekan SIKP tidak memiliki pinjaman"),
  credit_history_status_bi_checking: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label(
      "Pengecekan BI Checking/SUK/Perindo tidak memiliki catatan kredit buruk"
    ),
});

export const schemaStep2PS = yup.object({
  tabulation_verification: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Hasil Verifikasi Detail Permohonan"),
  tabulation_verification_reason: yup
    .string()
    .when("tabulation_verification", ([value], schema) => {
      if (!value)
        return schema.required().label("Kesalahan/Ketidak sesuaian data");

      return schema.notRequired();
    }),
  household_verification: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Hasil Verifikasi Usaha yang Dibiayai"),
  household_verification_reason: yup
    .string()
    .when("household_verification", ([value], schema) => {
      if (!value)
        return schema.required().label("Kesalahan/Ketidak sesuaian data");

      return schema.notRequired();
    }),
  collateral_tabulation_verification: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Hasil Verifikasi Jaminan"),
  collateral_tabulation_verification_reason: yup
    .string()
    .when("collateral_tabulation_verification", ([value], schema) => {
      if (!value)
        return schema.required().label("Kesalahan/Ketidak sesuaian data");

      return schema.notRequired();
    }),
  loan_value_recomendation: yup
    .number()
    .transform((val) => (isNaN(val) ? null : val))
    .label("Rekomendasi Nilai Pinjaman"),
  loan_value_recomendation_spell: yup.string().notRequired().nullable(),
  time_recomendation_periode_value: yup
    .number()
    .transform((val) => (isNaN(val) ? null : val))
    .label("Jangka Waktu"),
  time_recomendation_periode_type: yup
    .string()
    .required()
    .label("Jangka Waktu"),
  return: yup.string().required().label("Pengembalian"),
  installment_value: yup
    .number()
    .nullable()
    .notRequired()
    .transform((val) => (isNaN(val) ? null : val))
    .label("Nominal Angsuran"),
  installment_spell: yup.string().label("Terbilang"),
  installment_scheme: yup
    .number()
    .transform((val) => (isNaN(val) ? null : val))
    .label("Skema Pengembalian"),
  installment_scheme_type: yup.string().required().label("Skema Pengembalian"),
  time_return_periode_value: yup
    .number()
    .notRequired()
    .nullable()
    .transform((val) => (isNaN(val) ? null : val))
    .when("return", ([val], schema) => {
      if (val === "duration") {
        return schema.required().label("Jangka Waktu Pengembalian");
      }

      return schema;
    }),
  time_return_periode_type: yup
    .string()
    .notRequired()
    .when("return", ([val], schema) => {
      if (val === "duration") {
        return schema.required().label("Jangka Waktu Pengembalian");
      }

      return schema;
    }),
  notes_analyst: yup.string().required().label("Catatan Analis"),
  verification_status: yup.bool().notRequired().nullable(),
});

const schemaStep1PSDummy = yup.object({
  debitur_verification: yup.bool(),
  debitur_verification_reason: yup.bool(),
  couple_verification: yup.bool(),
  couple_verification_reason: yup.bool(),
  address_domicile_verification: yup.bool(),
  address_domicile_verification_reason: yup.bool(),
  credit_history_status_social_assistance: yup.bool(),
  credit_history_status_sikp: yup.bool(),
  credit_history_status_bi_checking: yup.bool(),

  // ! HEHE
  tabulation_verification: yup.bool(),
  tabulation_verification_reason: yup.bool(),
  household_verification: yup.bool(),
  household_verification_reason: yup.bool(),
  collateral_tabulation_verification: yup.bool(),
  collateral_tabulation_verification_reason: yup.bool(),
  loan_value_recomendation: yup.bool(),
  loan_value_recomendation_spell: yup.bool(),
  time_recomendation_periode_value: yup.bool(),
  time_recomendation_periode_type: yup.bool(),
  installment_value: yup.bool(),
  installment_spell: yup.bool(),
  installment_scheme: yup.bool(),
  installment_scheme_type: yup.bool(),
  return: yup.bool(),
  time_return_periode_value: yup.bool(),
  time_return_periode_type: yup.bool(),
  notes_analyst: yup.bool(),
  verification_status: yup.bool(),
});

// debitur_verification: schema.boolean(),
// debitur_verification_reason: schema.string.optional({ trim: true }),

// couple_verification: schema.boolean(),
// couple_verification_reason: schema.string.optional({ trim: true }),

// address_domicile_verification: schema.boolean(),
// address_domicile_verification_reason: schema.string.optional({ trim: true }),

// credit_history_status_social_assistance: schema.boolean(),
// credit_history_status_sikp: schema.boolean(),
// credit_history_status_bi_checking: schema.boolean(),

// tabulation_verification: schema.boolean(),
// tabulation_verification_reason: schema.string.optional({ trim: true }),

// household_verification: schema.boolean(),
// household_verification_reason: schema.string.optional({ trim: true }),

// collateral_tabulation_verification: schema.boolean(),
// collateral_tabulation_verification_reason: schema.string.optional({ trim: true }),

// loan_value_recomendation: schema.number(),
// loan_value_recomendation_spell: schema.string({ trim: true }),
// time_recomendation_periode_value: schema.number(),
// time_recomendation_periode_type: schema.enum([
//   new DeskAnalyst().recomendationType.week,
//   new DeskAnalyst().recomendationType.month,
//   new DeskAnalyst().recomendationType.year,
// ]),
// installment_value: schema.number(),
// installment_spell: schema.string({ trim: true }),
// installment_scheme: schema.string.optional(),
// installment_scheme_type: schema.enum.optional([
//   new DeskAnalyst().installmentSchemeType.week,
//   new DeskAnalyst().installmentSchemeType.month,
//   new DeskAnalyst().installmentSchemeType.year,
//   new DeskAnalyst().installmentSchemeType.harvest,
// ]),
// return: schema.enum(['duration', 'business_cycle']),
// time_return_periode_value: schema.number.optional([
//   rules.requiredWhen('return', '=', 'duration'),
// ]),
// time_return_periode_type: schema.enum.optional([
//   new DeskAnalyst().recomendationType.week,
//   new DeskAnalyst().recomendationType.month,
//   new DeskAnalyst().recomendationType.year,
// ]),
// notes_analyst: schema.string({ trim: true }),
// verification_status: schema.boolean(),
