import {
  SAVE_FINANCIAL_DRAFT_DATA,
  RESET_FINANCIAL_DRAFT_DATA,
  UPDATE_FINANCIAL_DRAFT_DATA,
} from "../actions";

const initialState = {
  data: {
    account_information: {},
    financial_information: {},
    tax: {},
    procurement: {},
  },
};

export const financialProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_FINANCIAL_DRAFT_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case UPDATE_FINANCIAL_DRAFT_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    case RESET_FINANCIAL_DRAFT_DATA:
      return {
        ...state,
        data: {
          account_information: {},
          financial_information: {},
          tax: {},
          procurement: {},
        },
      };
    default:
      return state;
  }
};
