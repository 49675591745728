import React, { Fragment, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { AnimatePresence, motion } from "framer-motion";

import AccordionButton from "components/atoms/AccordionButton";
import { Button, InputForm } from "components";
import { RiAddLine, RiDeleteBin2Line } from "react-icons/ri";
import { isEmpty } from "lodash";
import AtomSelect from "components/atoms/Select";

const Reporting = () => {
  const methods = useFormContext();

  const [accordionState, setAccordionState] = useState(false);

  const { control } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "report",
  });

  return (
    <Fragment>
      <AccordionButton
        label="Pelaporan"
        isOpen={accordionState}
        onClick={() => setAccordionState((curr) => !curr)}
      />
      <AnimatePresence>
        {accordionState && (
          <motion.div
            initial={{ opacity: 0, y: -15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -15 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="w-full bg-slate-100 p-5 rounded-md flex flex-col"
          >
            {fields.map((item, idx) => {
              return (
                <SubReporting
                  key={item.id}
                  item={item}
                  name={`report.${idx}`}
                  originalName="report"
                  index={idx}
                  //   options={provisionsData}
                  options={[]}
                  removeDistributions={remove}
                />
              );
            })}
            <Button
              className={"mt-2 bg-white"}
              theme="secondary"
              variant="icon"
              label={
                <div className="flex items-center gap-2 whitespace-nowrap justify-center">
                  <RiAddLine />
                  {`Tambah Pelaporan`}
                </div>
              }
              onClick={() => {
                append({
                  value: null,
                });
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Fragment>
  );
};

const SubReporting = ({ item, name, index, originalName }) => {
  const { control, watch, formState, setValue } = useFormContext();
  const {
    fields: subReporting,
    append: appendSubReporting,
    remove: removeSubReporting,
  } = useFieldArray({
    control,
    name: `${name}.sub`,
  });

  const reporting = watch("report");
  const formErrors = formState?.errors;

  return (
    <motion.div layout key={item.id} className="flex flex-col gap-4 flex-1">
      <motion.div layout="position" className="flex w-full items-end gap-4">
        <InputForm
          required={true}
          controllerName={`${name}.value`}
          label="Pelaporan"
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
          placeholder="Pelaporan"
        />
        {/* <AtomSelect
          label={label}
          wrapperClass={"flex flex-col justify-end overflow-hidden"}
          controllerName={`${name}.reporting`}
          options={options.map((x) => ({
            ...x,
            value: x.id,
            label: x.provision,
          }))}
          className="basic-single w-full"
          required={true}
          placeholder={label}
          customOnChange={(value) => {
            setValue(`${name}.sub_reportings`, []);
            if (value?.sub_reporting?.length) {
              value?.sub_reporting?.map((x) => {
                appendSubReporting({
                  sub_reporting: null,
                });
              });
            }
          }}
          menuPortalTarget={document.body}
        /> */}
        <Button
          className={
            !isEmpty(formErrors) &&
            formErrors?.[`${originalName}`]?.[index]?.value &&
            !isEmpty(formErrors?.[`${originalName}`]?.[index]?.value)
              ? "mb-[26px]"
              : ""
          }
          theme="primary"
          variant="icon"
          onClick={() => {
            // if (
            //   subReporting.length <
            //   reporting[index]?.reporting?.sub_reportings?.length
            // ) {
            //   appendSubReporting({
            //     sub_reporting: null,
            //   });
            // }
            appendSubReporting({
              value: null,
            });
          }}
          label={
            <div className="flex items-center gap-2 whitespace-nowrap">
              <RiAddLine />
              Sub Pelaporan
            </div>
          }
        />
      </motion.div>
      {subReporting?.length > 0 && (
        <div className="w-full bg-white flex flex-col gap-4 p-5 border rounded-md">
          {subReporting.map((sub_item, idx) => {
            return (
              <>
                <div className="flex w-full items-end gap-4">
                  <InputForm
                    required={true}
                    controllerName={`${name}.sub.${idx}.value`}
                    label="Sub Pelaporan"
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                    placeholder="Sub Pelaporan"
                  />
                  {/* <AtomSelect
                    key={sub_item.id}
                    label="Sub Reporting"
                    wrapperClass={"flex flex-col justify-end overflow-hidden"}
                    controllerName={`${name}.sub_reportings.${idx}.sub_reporting`}
                    options={reporting?.[
                      index
                    ]?.reporting?.sub_reporting?.map((x) => ({
                      ...x,
                      value: x.id,
                      label: x.sub_reporting,
                    }))}
                    className="basic-single w-full"
                    required={true}
                    placeholder="Sub Reporting"
                    menuPortalTarget={document.body}
                    customOnChange={(value) => {
                      if (value?.sub_sub_reporting?.length > 0) {
                        setValue(
                          `${name}.sub_reportings.${idx}.sub_sub_reportings`,
                          value.sub_sub_reporting.map((x) => ({
                            sub_sub_reporting_id: null,
                            options: value.sub_sub_reporting.map((x) => ({
                              ...x,
                              value: x.id,
                              label: x.sub_sub_reporting,
                            })),
                          }))
                        );
                      }
                    }}
                  /> */}
                  <Button
                    className={`h-[43px] ${
                      !isEmpty(formErrors) &&
                      !isEmpty(
                        formErrors?.[`${originalName}`]?.[index]
                          ?.sub?.[idx]?.value
                      )
                        ? "mb-[26px]"
                        : ""
                    }`}
                    variant="icon"
                    label={<RiDeleteBin2Line />}
                    theme="error"
                    onClick={() => {
                      removeSubReporting(idx);
                    }}
                  />
                </div>
                {/* <SubSubAccordionForm
                  name={`${name}.sub_provisions.${idx}`}
                  parentIndex={index}
                  index={idx}
                  originalName={originalName}
                  label={label}
                /> */}
              </>
            );
          })}
        </div>
      )}
      <hr />
    </motion.div>
  );
};

const SubSubReporting = ({ name, parentIndex, index, originalName, label }) => {
  const { control, formState, watch } = useFormContext();
  const { fields: subSubFields, remove: removeSubSubFields } = useFieldArray({
    control,
    name: `${name}.sub_sub_reportings`,
  });

  const provisions = watch(originalName);
  const formErrors = formState?.errors;

  return subSubFields?.length > 0 ? (
    <div className="pl-3 space-y-4">
      {subSubFields.map((sub_item, idx) => {
        return (
          <div className="flex w-full items-end gap-4" key={sub_item.id}>
            <AtomSelect
              key={sub_item.id}
              label={`Sub Sub Pelaporan`}
              wrapperClass={"flex flex-col justify-end overflow-hidden"}
              controllerName={`${name}.sub_sub_reportings.${idx}.sub_sub_reporting`}
              options={
                provisions?.[parentIndex]?.sub_reportings?.[index]
                  ?.sub_sub_reportings?.[idx]?.options ?? []
              }
              className="basic-single w-full"
              required={true}
              placeholder={`Sub Sub Pelaporan`}
              menuPortalTarget={document.body}
            />
            <Button
              className={`h-[43px] ${
                !isEmpty(formErrors) &&
                !isEmpty(
                  formErrors?.[`${originalName}`]?.[parentIndex]
                    ?.sub_reportings?.[index]?.sub_sub_reportings?.[idx]
                    ?.sub_sub_reporting
                )
                  ? "mb-[26px]"
                  : ""
              }`}
              variant="icon"
              label={<RiDeleteBin2Line />}
              theme="error"
              onClick={() => {
                removeSubSubFields(idx);
              }}
            />
          </div>
        );
      })}
    </div>
  ) : null;
};

export default Reporting;
