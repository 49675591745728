import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import AtomSelect from "../../../atoms/Select";
import { getDistrictFDBService } from "services/fdb/region";

let HAS_MORE = false;

const SelectFDBDistrict = React.forwardRef((props, ref) => {
  const { className, cityId, ...restProps } = props;

  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(1);

  useQuery({
    queryKey: ["district-fdb", cityId, page],
    queryFn: async () => {
      // TODO: Replace this with actual API call
      const res = await getDistrictFDBService({
        [`filter[city]`]: cityId,
        order_by: "name",
        order_dir: "asc",
        page,
        limit: 100,
      });
      if (res?.data?.data?.meta?.next_page_url) HAS_MORE = true;
      else HAS_MORE = false;

      return res?.data?.data?.data || [];
    },
    enabled: !!cityId,
    retry: false,
    onSuccess: (data) => {
      setOptions((curr) => [...curr, ...data.map(x => ({ value: x.id, label: x.name }))]);
    },
  });

  useEffect(() => {
    setOptions([]);
    setPage(1);
  }, [cityId]);

  const fetchMore = () => {
    if (HAS_MORE) {
      setPage((curr) => curr + 1);
    }
  }

  return (
    <AtomSelect
      options={options}
      className={["basic-single mt-2 flex-1", className].join(" ")}
      onMenuScrollToBottom={fetchMore}
      {...restProps}
    />
  );
});

export default SelectFDBDistrict;
