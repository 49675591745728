import React, { useMemo } from "react";
import Table, { TableWrapper } from "components/atoms/Table";
import CardForm from "components/molecules/CardForm";
import { formatCurrency } from "helpers";

const Products = ({ data, serviceType }) => {
  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "type",
        title: "Jenis Produk",
      },
      {
        key: "name",
        title: "Nama Produk",
      },
      {
        key: "name",
        title: "Range Nominal",
        render: ({ item }) => {
          const low = item?.nominal_low;
          const high = item?.nominal_high;
          if (low && high)
            return `${formatCurrency(low)} - ${formatCurrency(high)}`;
          return "-";
        },
      },
      {
        key: "average_plafond",
        title: "Rata-rata Plafond",
        render: ({ item }) =>
          item?.average_plafond
            ? `${formatCurrency(item?.average_plafond)}`
            : "-",
      },
      {
        key: "average_tenor",
        title: "Rata-rata Tenor",
        render: ({ item }) => item?.average_tenor ?? "-",
      },
      {
        key: "margin",
        title: "Suku Bunga / Marjin",
        render: ({ item }) => (item?.margin ? `${item?.margin}%` : "-"),
      },
      {
        key: "notes",
        title: "Keterangan",
      },
    ];
  }, []);

  const headersBumdes = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "type",
        title: "Jenis Produk",
      },
      {
        key: "name",
        title: "Nama Produk",
      },
      {
        key: "income",
        title: "Pendapatan",
        render: ({ item }) => formatCurrency(item?.income),
      },
      // {
      //   key: "name",
      //   title: "Range Nominal",
      //   render: ({ item }) => {
      //     const low = item?.nominal_low;
      //     const high = item?.nominal_high;
      //     if (low && high)
      //       return `${formatCurrency(low)} - ${formatCurrency(high)}`;
      //     return "-";
      //   },
      // },
      // {
      //   key: "average_plafond",
      //   title: "Rata-rata Plafond",
      //   render: ({ item }) =>
      //     item?.average_plafond
      //       ? `${formatCurrency(item?.average_plafond)}`
      //       : "-",
      // },
      // {
      //   key: "average_tenor",
      //   title: "Rata-rata Tenor",
      //   render: ({ item }) => item?.average_tenor ?? "-",
      // },
      // {
      //   key: "margin",
      //   title: "Suku Bunga / Marjin",
      //   render: ({ item }) => (item?.margin ? `${item?.margin}%` : "-"),
      // },
      {
        key: "notes",
        title: "Keterangan",
      },
    ];
  }, []);

  return (
    <CardForm label="Produk / Layanan yang Dimiliki Lembaga Penyalur">
      <TableWrapper>
        <Table
          headers={
            serviceType === "BUMDES" || serviceType === "BUMD"
              ? headersBumdes
              : headers
          }
          items={data || []}
          isLoading={false}
        />
      </TableWrapper>
    </CardForm>
  );
};

export default Products;
