import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Spinner } from "components";
import Badge from "components/atoms/Badge";
import {
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
} from "components/atoms/Modal";
import AtomSelect, { customStyles } from "components/atoms/Select";
import Table, { TableWrapper } from "components/atoms/Table";
import TablePagination from "components/atoms/TablePagination";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import dayjs from "dayjs";
import { getErrorMessage, paginate } from "helpers";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { RiArrowLeftLine, RiInformationLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router";
import Select from "react-select";
import {
  getAssignPelaksana,
  getDeskAnalystDetail,
  putDeskAnalystDetail,
} from "services/fdb/desk-analyst";

const DeskAnalystDetail = () => {
  const { id } = useParams();
  const [items, setItems] = useState([]);

  const { data, isLoading } = useQuery({
    queryKey: ["application-desk-analyst-detail"],
    queryFn: async () => {
      const res = await getDeskAnalystDetail(id);
      return res.data.data;
    },
    onSuccess: (res) => {
      const items = res?.applicant.map((item) => {
        return {
          id: item?.id,
          pic: item?.pic,
          pic_name: item?.pic_name,
          name: item?.name_debtor,
          nik: item?.identity_number,
          date: item?.date_of_birth,
          email: item?.email,
          phone_number: item?.phone,
          state: item?.is_old ? "Permohonan Lama" : "Permohonan Baru",
        };
      });
      setItems(items);
    },
    retry: 1,
  });

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: "Daftar Desk Analyst",
            path: "/desk-analyst",
          },
          {
            label: "Detail",
            path: `/desk-analyst/${id}`,
          },
        ]}
      />
      {isLoading ? (
        <div className="w-full flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <ProfileSection data={data} />
          <RequestSection data={data} items={items} />
        </>
      )}
    </div>
  );
};

const stateColors = {
  "Permohonan Lama": "warning",
  "Permohonan Baru": "bluelight",
  Draft: "default",
  Ditolak: "error",
  Disetujui: "success",
  Terverifikasi: "success",
};

const ProfileSection = ({ data }) => {
  return (
    <CardForm label="Profile Kelompok">
      <div className="flex justify-between">
        <div>
          <p className="font-semibold">Nama Kelompok</p>
          <p>{data.group_profile.name}</p>
        </div>
        <div>
          <p className="font-semibold">Nama Ketua Kelompok</p>
          <p>{data.group_profile.leader_name}</p>
        </div>
        <div>
          <p className="font-semibold">NIK Ketua</p>
          <p>{data.group_profile.leader_identity}</p>
        </div>
        <div>
          <p className="font-semibold">No Telepon Ketua</p>
          <p>{data.group_profile.leader_phone}</p>
        </div>
      </div>
    </CardForm>
  );
};

const RequestSection = ({ data, items }) => {
  const { id } = useParams();
  const [singlePic, setSinglePic] = useState(false);
  const navigate = useNavigate();

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      type: "1",
      pic: "",
      pic_name: "",
      applicants: [],
    },
  });

  const { data: deskAnalyst } = useQuery({
    queryKey: ["GET DESK ANALYST"],
    queryFn: getAssignPelaksana,
    select: ({ data }) => {
      return data?.data?.map((x) => ({ pic: x.idUser, pic_name: x.name }));
    },
  });

  useEffect(() => {
    const applicants = items.map((item) => {
      return {
        id: item?.id,
        selected_pic: {
          value: item?.pic || "",
          label: item?.pic_name || "",
          pic: item?.pic || "",
          pic_name: item?.pic_name || "",
        },
      };
    });
    methods.setValue("applicants", applicants);
  }, [items, methods]);

  const applicantsValue = methods.watch("applicants");
  const picValue = methods.watch("pic");
  const picNameValue = methods.watch("pic_name");

  const onFormSubmit = () => {
    // methods.getValues("applicants").map((item, idx) => {
    //   methods.setValue(`applicants.${idx}.pic`, item?.selected_pic?.value);
    //   methods.setValue(`applicants.${idx}.pic_name`, item?.selected_pic?.label);
    // });
    let payload;

    if (singlePic) {
      const applicantArr = applicantsValue.map((item) => {
        return {
          id: item.id,
        };
      });
      payload = {
        type: singlePic ? "1" : "2",
        pic: picValue,
        pic_name: picNameValue,
        applicants: applicantArr,
      };
    } else {
      payload = {
        type: singlePic ? "1" : "2",
        applicants: applicantsValue.map((data) => ({
          id: data?.id,
          pic: data?.selected_pic?.value,
          pic_name: data?.selected_pic?.label,
        })),
      };
    }
    postDeskAnalyst.mutate(payload);
  };

  const postDeskAnalyst = useMutation({
    mutationKey: ["application-put-desk-analyst"],
    mutationFn: (payload) => {
      const res = putDeskAnalystDetail(id, payload);
      return res;
    },
    onSuccess: (res) => {
      enqueueSnackbar("Data berhasil disimpan", { variant: "success" });
      navigate("/desk-analyst");
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });

  return (
    <CardForm label="Permohonan Kelompok">
      <div className="grid grid-cols-2">
        <div>
          <p className="font-semibold">Tanggal Pengajuan Anggota</p>
          <p>{dayjs(data?.registration_date).format("D MMMM YYYY")}</p>
        </div>
        <div>
          <p className="font-semibold">Total Anggota Diajukan</p>
          <p>{data?.total_member} Orang</p>
        </div>
      </div>
      <div className="bg-[#E1F1D6] my-5 p-3 w-full">
        <p className="font-bold text-[#01A24A]">
          Silahkan pilih metode PIC untuk Permohonan Kelompok Ini
        </p>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div
          className={`py-2 px-4 rounded-md border flex items-center ${
            !singlePic && "bg-[#ECFDF3] border-[#039855]"
          }`}
          onClick={() => {
            if (Number(data?.desk_status || 1) === 1) {
              setSinglePic(false);
            }
          }}
        >
          <input
            id="pic-1"
            type="radio"
            className="accent-[#039855]"
            name="pic"
            disabled={Number(data?.desk_status || 1) > 1}
            checked={!singlePic}
          />
          <label htmlFor="pic-1" className="ml-5">
            PIC Lebih dari 1 Orang
          </label>
        </div>
        <div
          className={`py-2 px-4 rounded-md border flex items-center ${
            singlePic && "bg-[#ECFDF3] border-[#039855]"
          }`}
          onClick={() => {
            if (Number(data?.desk_status || 1) === 1) {
              setSinglePic(true);
            }
          }}
        >
          <input
            id="pic-2"
            type="radio"
            className="accent-[#039855]"
            name="pic"
            disabled={Number(data?.desk_status || 1) > 1}
            checked={singlePic}
          />
          <label htmlFor="pic-2" className="ml-5">
            PIC Hanya 1 Orang
          </label>
        </div>
      </div>
      <div className="my-5">
        <FormProvider {...methods}>
          {singlePic ? (
            <Only1PIC
              items={items}
              isView={Number(data?.desk_status || 1) > 1}
              deskAnalyst={deskAnalyst}
            />
          ) : (
            <MoreThan1PIC
              items={items}
              isView={Number(data?.desk_status || 1) > 1}
              deskAnalyst={deskAnalyst}
            />
          )}
        </FormProvider>
      </div>
      {Number(data?.desk_status || 1) === 1 && (
        <div className="sticky bottom-4 flex justify-between w-full p-5 bg-white border rounded-md">
          <Button
            className="p-4 border rounded-lg"
            label={
              <div className="flex items-center gap-2">
                <RiArrowLeftLine />
                Batal
              </div>
            }
            onClick={() => navigate(-1)}
          />
          <ModalConfirm
            type={"approve"}
            onSubmit={onFormSubmit}
            hasDatas={
              singlePic
                ? picNameValue && picValue
                : applicantsValue.every((x) => x?.selected_pic?.value)
            }
          />
        </div>
      )}
    </CardForm>
  );
};

const MoreThan1PIC = ({ items, isView, deskAnalyst }) => {
  const methods = useFormContext();
  const { watch } = methods;

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "name",
        title: "Nama Debitur",
      },
      {
        key: "nik",
        title: "NIK",
      },
      {
        key: "date",
        title: "Tgl Lahir",
        render: ({ item }) => {
          return dayjs(item.date).format("DD/MM/YYYY");
        },
      },
      {
        key: "email",
        title: "Email",
      },
      {
        key: "phone_number",
        title: "No Telepon",
      },
      {
        key: "state",
        title: "Status",
        render: ({ item }) => (
          <Badge color={stateColors[item.state]}>{item.state}</Badge>
        ),
      },
      {
        key: "analyst",
        title: "Desk Analyst",
        render: ({ item, index }) => {
          const selectedPIC = watch(`applicants.${index}.selected_pic`);
          return (
            <div className="w-[220px]">
              <Select
                styles={customStyles}
                options={deskAnalyst?.map((data) => ({
                  ...data,
                  label: data.pic_name,
                  value: data.pic,
                }))}
                isClearable
                isDisabled={isView}
                menuPosition="fixed"
                value={{
                  value: selectedPIC?.pic,
                  label: selectedPIC?.pic_name,
                }}
                onChange={(e) =>
                  methods.setValue(
                    `applicants.${index}.selected_pic`,
                    e ?? null
                  )
                }
                disabled={isView}
              />
            </div>
          );
        },
      },
    ];
  }, [deskAnalyst, methods, isView, watch]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const pageCount =
    items.length > 0 && limit > 0 ? Math.ceil(items.length / limit) : 1;
  const dataToShow = paginate(items, limit, page);
  return (
    <>
      <TableWrapper className="mt-4">
        <Table headers={headers} items={dataToShow} isLoading={false} />
        <TablePagination
          totalPage={pageCount}
          totalItems={items.length}
          onChange={setPage}
          onItemsPerPageChange={setLimit}
        />
      </TableWrapper>
    </>
  );
};

const Only1PIC = ({ items, isView, deskAnalyst }) => {
  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "name",
        title: "Nama Debitur",
      },
      {
        key: "nik",
        title: "NIK",
      },
      {
        key: "date",
        title: "Tgl Lahir",
        render: ({ item }) => {
          return dayjs(item.date).format("DD/MM/YYYY");
        },
      },
      {
        key: "email",
        title: "Email",
      },
      {
        key: "phone_number",
        title: "No Telepon",
      },
      {
        key: "state",
        title: "Status",
        render: ({ item }) => (
          <Badge color={stateColors[item.state]}>{item.state}</Badge>
        ),
      },
    ];
  }, []);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { setValue } = useFormContext();
  const selectedPic = useWatch({ name: "selected_pic" });

  useEffect(() => {
    setValue("pic", selectedPic?.value);
    setValue("pic_name", selectedPic?.label);
  }, [selectedPic, setValue]);

  const pageCount =
    items.length > 0 && limit > 0 ? Math.ceil(items.length / limit) : 1;
  const dataToShow = paginate(items, limit, page);

  return (
    <>
      <AtomSelect
        controllerName={"selected_pic"}
        wrapperClass={"w-full min-w-[100px]"}
        className="basic-single w-full"
        options={deskAnalyst?.map((analyst) => ({
          value: analyst?.pic,
          label: analyst?.pic_name,
        }))}
        label="Assign Pelaksana"
        placeholder="Assign Pelaksana"
        disabled={isView}
        required={true}
      />
      <TableWrapper className="mt-4">
        <Table headers={headers} items={dataToShow} isLoading={false} />
        <TablePagination
          totalPage={pageCount}
          totalItems={items.length}
          onChange={setPage}
          onItemsPerPageChange={setLimit}
        />
      </TableWrapper>
    </>
  );
};

const ModalConfirm = ({ id, onSubmit, hasDatas }) => {
  return (
    <Modal trigger={<Button label="Submit" theme="primary" />}>
      <ModalHeader
        noBorder
        hideClose
        title={
          <div className="w-12 h-12 rounded-full flex items-center justify-center text-orange-700 bg-orange-100 border-4 border-orange-50">
            <RiInformationLine className="text-base" size={24} />
          </div>
        }
      />
      {!hasDatas ? (
        <ModalBody className="space-y-4">
          <p className="text-lg font-semibold text-center">
            Anda wajib melengkapi data pelaksana
          </p>
          <ModalClose>
            <Button label="Tutup" theme="secondary" block className="w-full" />
          </ModalClose>
        </ModalBody>
      ) : (
        <ModalBody className="space-y-4">
          <p className="text-lg font-semibold">
            Anda yakin akan mengirimkan Assignment Ke PIC Terpilih? ?
          </p>
          <small>Setelah data Terkirim Anda masih Bisa Mengubah PIC</small>
          <div className="grid grid-cols-2 gap-4">
            <ModalClose>
              <Button
                label="Tidak"
                theme="secondary"
                block
                className="w-full"
              />
            </ModalClose>
            <Button
              label={"Ya"}
              theme="primary"
              block
              className="w-full"
              onClick={onSubmit}
            />
          </div>
        </ModalBody>
      )}
    </Modal>
  );
};

export default DeskAnalystDetail;
