import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { Button, InputForm, InputFormUpload } from "components";
import AtomDatePicker from "components/atoms/Datepicker";
import Table, { TableWrapper } from "components/atoms/Table";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import { Pagination } from "components/v2/shared/pagination";
import { formatCurrency } from "helpers";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { fileUpload, getFile } from "services/fileService";
import * as Yup from "yup";
import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";
import { find, isEmpty } from "lodash";
import { enqueueSnackbar } from "notistack";
import {
  getAgencyOfferApprovalDetail,
  submitAgencyOfferApproval,
} from "services/institutions/offer-approvals";
import { useSearchParams } from "react-router-dom";

const validattionScheme = Yup.object().shape({
  committee_minutes_number: Yup.string().required().label("No Berita Acara"),
  agency_name: Yup.string().required().label("Nama Lembaga"),
  decision_number: Yup.string().required().label("No Keputusan Direktur Utama"),
  // decision_file: Yup.string().required().label('Upload Berita Acara Komite Direksi'),
  about: Yup.string().required().label("Tentang"),
  decision_date: Yup.string()
    .required()
    .label("Tanggal Terbit Keputusan Direktur Utama"),
  decision_file_temp: Yup.mixed().test(
    "cid_img",
    ({ label }) => validationErrorMsg.mixed.required.replace("${path}", label),
    (value, context) => {
      const allowedTypes = [
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (value) {
        const data = find(allowedTypes, (at) => value.type.includes(at));
        if (!data) {
          return context.createError({
            message: validationCustomErrorMsg.isFiletype("PDF, DOCX")({
              label: "Upload Berita Acara Komite Direksi",
            }),
          });
        }
        if (value.size > 10 * 1024 * 1024) {
          return context.createError({
            message: validationCustomErrorMsg.isFileSize("10MB")({
              label: "Upload Berita Acara Komite Direksi",
            }),
          });
        }
        return true;
      }
      // otherwise, return false to render the field required
      return false;
    }
  ),
});

const getFileFromServiceCB = async (id) => {
  const response = await getFile(id);
  return response.data.data;
};

const ApprovalMinutesDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const methods = useForm({
    resolver: yupResolver(validattionScheme),
  });

  const queryClient = useQueryClient();
  const { data } = useQuery(
    ["GET_AGENCY_OFFER_APPROVAL_DETAIL", id],
    getAgencyOfferApprovalDetail,
    {
      select: (data) => data?.data?.data,
    }
  );
  const mutateAgencyOfferApproval = useMutation({
    mutationFn: submitAgencyOfferApproval,
  });

  const handleReject = () => {
    const payload = methods.getValues();

    mutateAgencyOfferApproval.mutate(
      {
        data: payload,
        params: {
          id,
          approval: true,
        },
      },
      {
        onSuccess: () => {
          enqueueSnackbar({
            variant: "success",
            message: "Berhasil Reject",
          });
          navigate(-1);
          queryClient.invalidateQueries();
        },
        onError: () => {
          enqueueSnackbar({
            variant: "error",
            message: "Gagal Reject",
          });
        },
      }
    );
  };

  const handleApproved = () => {
    const payload = methods.getValues();

    mutateAgencyOfferApproval.mutate(
      {
        data: payload,
        params: {
          id,
          approval: true,
        },
      },
      {
        onSuccess: () => {
          enqueueSnackbar({
            variant: "success",
            message: "Berhasil Approve",
          });
          navigate(-1);
          queryClient.invalidateQueries();
        },
        onError: () => {
          enqueueSnackbar({
            variant: "error",
            message: "Gagal Approve",
          });
        },
      }
    );
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      const promises = [Promise.resolve()];
      const {
        decision_file: decisionFile,
        decision_date,
        ...rest
      } = data || {
        decisionFile: null,
      };

      if (decisionFile && decisionFile !== "string") {
        promises.push(
          getFileFromServiceCB(decisionFile).then((data) => {
            return {
              formId: "decision_file_temp",
              type: data?.mimeType,
              ...data,
            };
          })
        );
      }

      if (promises?.length > 0) {
        Promise.all(promises).then((values) => {
          const obj = {
            decision_date: decision_date ? new Date(decision_date) : null,
            decisionFile,
            ...rest,
          };

          values.map((x) => {
            if (!isEmpty(x)) {
              obj[x.formId] = x;
            }
          });

          methods.reset(obj);
        });
      }
    }
  }, [data, methods]);

  return (
    <section className="space-y-5">
      <BreadCrumbs
        routes={[
          {
            label: "Approval Berita Acara",
            path: "/institutions/approval",
          },
          {
            label: "Detail",
            path: "",
          },
        ]}
      />
      {/* <CommitteeDecision />
      <FinancingStructure />
      <FinancingCondition />
      <CooperationOfferAttachment />
      <PotentialFDBRecipients /> */}

      <FormProvider {...methods}>
        <UploadDecisionLetter />
      </FormProvider>
      {Number(searchParams.get("status")) > 1 ? (
        <div className="sticky bottom-4 flex justify-between w-full p-5 bg-white border rounded-md gap-5">
          <Button
            label="Kembali"
            className="border border-gray-200 hover:bg-gray-50"
            onClick={() => navigate(-1)}
          />
        </div>
      ) : (
        <div className="sticky bottom-4 flex justify-between w-full p-5 bg-white border rounded-md gap-5">
          <Button
            theme="error"
            label="Tolak"
            className="flex-grow"
            onClick={methods.handleSubmit(handleReject)}
          />
          <Button
            theme="primary"
            label="Terima"
            className="flex-grow"
            onClick={methods.handleSubmit(handleApproved)}
          />
        </div>
      )}
    </section>
  );
};

const CommitteeDecision = () => {
  const headers = useMemo(
    () => [
      {
        key: "index",
        title: "No",
        render: ({ index }) => {
          // const no =
          //   parseIntage) *
          //     parseInt(data?.meta?.per_page) -
          //   parseInt(data?.meta?.per_page) +
          //   index +
          //   1;

          const no = index + 1;

          return no;
        },
      },
      {
        key: "name",
        title: "Nama",
        render: ({ item }) => <p>{item.name}</p>,
      },
      {
        key: "position",
        title: "Jabatan",
        render: ({ item }) => <p>{item.position}</p>,
      },
      {
        key: "committee_position",
        title: "Kedudukan Dalam Komite",
        render: ({ item }) => <p>{item?.committee_position}</p>,
      },
      {
        key: "name",
        title: "Status Kehadiran",
        render: ({ item }) =>
          item.is_attend === null ? (
            "-"
          ) : (
            <div
              className={clsx(
                "border-2 px-2 py-[2px] text-xs font-medium rounded-full"
                // badgeAttendance[item.is_attend]
              )}
            >
              {item.is_attend ? "Hadir" : "Tidak Hadir"}
            </div>
          ),
      },
      {
        key: "name",
        title: "Persetujuan",
        render: ({ item }) =>
          item.is_approved === null ? (
            "-"
          ) : (
            <div
              className={clsx(
                " px-2 py-[2px] text-xs font-medium rounded-full"
                // badgeAggrement[item.is_approved]
              )}
            >
              {item.is_approved ? "Setuju" : "Tidak Setuju"}
            </div>
          ),
      },
      {
        key: "name",
        title: "Catatan Persetujuan",
        render: ({ item }) => <p>{item?.approval_notes}</p>,
      },
    ],
    []
  );

  return (
    <CardForm label="Keputusan Komite">
      <TableWrapper>
        <Table headers={headers} items={[]} />
      </TableWrapper>
    </CardForm>
  );
};

const FinancingStructure = () => {
  return (
    <CardForm label="Struktur Pembiayaan">
      <div className="grid grid-cols-4 gap-4">
        <div className="grid col-span-4 grid-cols-2">
          <div>
            <div className="font-semibold">Jenis Layanan</div>
            <div>-</div>
          </div>
          <div>
            <div className="font-semibold">Skema Penyaluran</div>
            <div>-</div>
          </div>
        </div>
        <div className="col-span-4">
          <div>
            <div className="font-semibold">Penggunaan / Tujuan FDB</div>
            <div>-</div>
          </div>
        </div>
        <div className="grid col-span-4 grid-cols-2">
          <div>
            <div className="font-semibold">Jumlah FDB</div>
            <div>-</div>
          </div>
          <div>
            <div className="font-semibold">Terbilang</div>
            <div>-</div>
          </div>
        </div>
        <div className="grid col-span-4 grid-cols-3">
          <div>
            <div className="font-semibold">Tarif Layanan Pertahun</div>
            <div>-</div>
          </div>
          <div>
            <div className="font-semibold">Metode Pemberlakuan</div>
            <div>-</div>
          </div>
          <div>
            <div className="font-semibold">Metode Perhitungan Tarif</div>
            <div>-</div>
          </div>
        </div>
        <div>
          <div className="font-semibold">Jangka Waktu</div>
          <div>-</div>
        </div>
        <div>
          <div className="font-semibold">Grace Period</div>
          <div>-</div>
        </div>
        <div>
          <div className="font-semibold">Jangka Waktu Perpanjangan</div>
          <div>-</div>
        </div>
        <div>
          <div className="font-semibold">Kesepakatan Kedua Belah Pihak?</div>
          <div>-</div>
        </div>
        <div>
          <div className="font-semibold">Tahap Pencairan</div>
          <div>-</div>
        </div>
        <div>
          <div className="font-semibold">Tahap 1</div>
          <div>-</div>
        </div>
        <div>
          <div className="font-semibold">Tahap 2</div>
          <div>-</div>
        </div>
        <div>
          <div className="font-semibold">Tahap 3</div>
          <div>-</div>
        </div>

        <div className="col-span-4">
          <div>
            <div className="font-semibold">Jaminan</div>
            <div>-</div>
          </div>
        </div>
      </div>
    </CardForm>
  );
};

const FinancingCondition = () => {
  return (
    <CardForm label="Kondisi Pembiayaan">
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-3">
          <h5 className="text-base font-semibold">Tujuan Penggunaan</h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Odio eget accumsan tincidunt
            tortor. Sed tempor a blandit viverra a vulputate tristique nunc nec.
            Facilisi id amet lacus iaculis purus porttitor amet libero vel. Quam
            volutpat volutpat tellus aliquet a egestas ac ut.
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <h5 className="text-base font-semibold">jaminan</h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Odio eget accumsan tincidunt
            tortor. Sed tempor a blandit viverra a vulputate tristique nunc nec.
            Facilisi id amet lacus iaculis purus porttitor amet libero vel. Quam
            volutpat volutpat tellus aliquet a egestas ac ut.
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <h5 className="text-base font-semibold">Perjanjian</h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Odio eget accumsan tincidunt
            tortor. Sed tempor a blandit viverra a vulputate tristique nunc nec.
            Facilisi id amet lacus iaculis purus porttitor amet libero vel. Quam
            volutpat volutpat tellus aliquet a egestas ac ut.
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <h5 className="text-base font-semibold">Pencairan</h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Odio eget accumsan tincidunt
            tortor. Sed tempor a blandit viverra a vulputate tristique nunc nec.
            Facilisi id amet lacus iaculis purus porttitor amet libero vel. Quam
            volutpat volutpat tellus aliquet a egestas ac ut.
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <h5 className="text-base font-semibold">
            Hak dan Tanggung Jawab Lembaga
          </h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Odio eget accumsan tincidunt
            tortor. Sed tempor a blandit viverra a vulputate tristique nunc nec.
            Facilisi id amet lacus iaculis purus porttitor amet libero vel. Quam
            volutpat volutpat tellus aliquet a egestas ac ut.
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <h5 className="text-base font-semibold">Pengembalian</h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Odio eget accumsan tincidunt
            tortor. Sed tempor a blandit viverra a vulputate tristique nunc nec.
            Facilisi id amet lacus iaculis purus porttitor amet libero vel. Quam
            volutpat volutpat tellus aliquet a egestas ac ut.
          </p>
        </div>
      </div>
    </CardForm>
  );
};

const CooperationOfferAttachment = () => {
  return (
    <CardForm label="Lampiran Penawaran Kerjasama">
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-3">
          <h5 className="text-base font-semibold">
            Syarat penandatanganan perjanjian FDB
          </h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Odio eget accumsan tincidunt
            tortor. Sed tempor a blandit viverra a vulputate tristique nunc nec.
            Facilisi id amet lacus iaculis purus porttitor amet libero vel. Quam
            volutpat volutpat tellus aliquet a egestas ac ut.
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <h5 className="text-base font-semibold">Ketentuan Kerja Sama</h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Odio eget accumsan tincidunt
            tortor. Sed tempor a blandit viverra a vulputate tristique nunc nec.
            Facilisi id amet lacus iaculis purus porttitor amet libero vel. Quam
            volutpat volutpat tellus aliquet a egestas ac ut.
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <h5 className="text-base font-semibold">
            Ketentuan pencairan dan pengembalian FDB
          </h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Odio eget accumsan tincidunt
            tortor. Sed tempor a blandit viverra a vulputate tristique nunc nec.
            Facilisi id amet lacus iaculis purus porttitor amet libero vel. Quam
            volutpat volutpat tellus aliquet a egestas ac ut.
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <h5 className="text-base font-semibold">Laporan</h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Odio eget accumsan tincidunt
            tortor. Sed tempor a blandit viverra a vulputate tristique nunc nec.
            Facilisi id amet lacus iaculis purus porttitor amet libero vel. Quam
            volutpat volutpat tellus aliquet a egestas ac ut.
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <h5 className="text-base font-semibold">
            Ketentuan monitoring dan evaluasi
          </h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Odio eget accumsan tincidunt
            tortor. Sed tempor a blandit viverra a vulputate tristique nunc nec.
            Facilisi id amet lacus iaculis purus porttitor amet libero vel. Quam
            volutpat volutpat tellus aliquet a egestas ac ut.
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <h5 className="text-base font-semibold">
            Ketentuan denda dan wanprestasi
          </h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Odio eget accumsan tincidunt
            tortor. Sed tempor a blandit viverra a vulputate tristique nunc nec.
            Facilisi id amet lacus iaculis purus porttitor amet libero vel. Quam
            volutpat volutpat tellus aliquet a egestas ac ut.
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <h5 className="text-base font-semibold">
            Ketentuan berakhirnya perjanjian kerja sama penyaluran FDB
          </h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Odio eget accumsan tincidunt
            tortor. Sed tempor a blandit viverra a vulputate tristique nunc nec.
            Facilisi id amet lacus iaculis purus porttitor amet libero vel. Quam
            volutpat volutpat tellus aliquet a egestas ac ut.
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <h5 className="text-base font-semibold">
            Ketentuan penyelamatan dan penyelesaian FDB
          </h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Odio eget accumsan tincidunt
            tortor. Sed tempor a blandit viverra a vulputate tristique nunc nec.
            Facilisi id amet lacus iaculis purus porttitor amet libero vel. Quam
            volutpat volutpat tellus aliquet a egestas ac ut.
          </p>
        </div>
      </div>
    </CardForm>
  );
};

const PotentialFDBRecipients = () => {
  const headers = useMemo(
    () => [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "debtor_name",
        title: "Nama Debitur",
      },
      {
        key: "debtor_identity_number",
        title: "NIK",
      },
      {
        key: "debtor_age",
        title: "Umur",
        render: ({ item }) => `${item.debtor_age} Tahun`,
      },
      {
        key: "debtor_address",
        title: "Alamat",
      },
      {
        key: "purpose_using_fdb",
        title: "Tujuan Penggunaan FDB",
      },
      {
        key: "name",
        title: "Nilai Pembiayaan",
        render: ({ item }) => {
          return item?.application_value
            ? `${formatCurrency(item?.application_value)}`
            : "-";
        },
      },
      {
        key: "loan_term",
        title: "Jangka Waktu Pinjaman",
      },
      {
        key: "tree_type",
        title: "Tipe Pohon",
      },
      {
        key: "tree_total",
        title: "Total Pohon",
      },
    ],
    []
  );

  return (
    <CardForm label="Potensi Penerima FDB">
      <TableWrapper>
        <Table headers={headers} items={[]} isLoading={false} />
        <Pagination
          page={1}
          limit={10}
          total={0}
          totalPage={1}
          // onLimitChange={(e) => changeLimitPotention(e)}
          // onPageChange={(e) => changePagePotention(e)}
        />
      </TableWrapper>
    </CardForm>
  );
};

const UploadDecisionLetter = () => {
  const methods = useFormContext();

  const fileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      if (file?.controllerName === "decision_file_temp") {
        methods.register("decision_file");
        methods.setValue("decision_file", response.data.data.id);
      }
    } catch (error) {
      throw error;
    }
  });

  return (
    <CardForm label="Upload Surat Keputusan">
      <div className="grid grid-cols-2 gap-5">
        <InputForm
          controllerName="committee_minutes_number"
          label="No Berita Acara"
          required
          className="w-full"
          disabled
        />

        <InputForm
          controllerName="agency_name"
          label="Nama Lembaga"
          required
          className="w-full"
          disabled
        />

        <InputForm
          controllerName="decision_number"
          label="No Keputusan Direktur Utama"
          required
          className="w-full"
        />

        <AtomDatePicker
          controllerName="decision_date"
          label="Tanggal Terbit Keputusan Direktur Utama"
          required
        />

        <div className="col-span-2">
          <InputForm
            controllerName="about"
            label="Tentang"
            required
            className="w-full col-span-2 p-3 border border-gray-200 focus:border-gray-200"
            textArea
          />
        </div>

        <div className="col-span-2">
          <InputFormUpload
            controllerName="decision_file_temp"
            fileType="PDF, Docx"
            label="Upload Berita Acara Komite Direksi"
            required
            uploadFile={fileMutation}
            maxSize={10 * 1024 * 1024}
          />
        </div>
      </div>
    </CardForm>
  );
};

export default ApprovalMinutesDetail;
