/* eslint-disable eqeqeq */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { TabsContent } from "components/atoms/Tabs";
import CardForm from "components/molecules/CardForm";
import {
  RiCheckboxCircleFill,
  RiCircleFill,
  RiCloseCircleFill,
  RiEyeLine,
} from "react-icons/ri";
import { useSearchParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import AccordionButton from "components/atoms/AccordionButton";
import { formatCurrency } from "helpers";
import { camelCase, debounce, lowerCase, startCase, toLower } from "lodash";
import { Button, InputForm, InputFormRadio } from "components";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import Table, { TableWrapper } from "components/atoms/Table";
import withFileService from "hocs/with-file-service";
import AtomSelect from "components/atoms/Select";
import RichEditorForm from "components/molecules/RichEditorForm";
import angkaTerbilangJs from "@develoka/angka-terbilang-js";
import InputCurrencyForm from "components/v2/shared/input-currency-form";

export const RequestForm = ({ type, data }) => {
  const [searchParams] = useSearchParams();

  const isView = searchParams.get("status");

  return (
    <TabsContent value="form" className="mb-40">
      <ApplicationDetails
        loan={
          data?.group_profile_member?.applicant_member?.request
            ?.social_forestry_applications
        }
        finance={data?.group_profile_member}
        isView={isView}
      />
      <FinancedBusiness
        data={data}
        debtor={data?.group_profile_member}
        isView={isView}
      />
      <Collaterals
        data={data?.group_profile_member?.colleterals}
        isView={isView}
      />
      <DeskAnalystTemporaryRecommendation isView={isView} />
    </TabsContent>
  );
};

const ApplicationDetails = ({ loan, finance, isView }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [accordionLoan, setAccordionLoan] = useState(true);
  const [accordionFinance, setAccordionFinance] = useState(true);

  const methods = useFormContext();

  const verificationField = useWatch({
    control: methods.control,
    name: "tabulation_verification",
  });

  return (
    <CardForm
      label={
        <div className="flex gap-2">
          <p>Detail Permohonan</p>
          <div className="flex items-center gap-1 text-xs font-normal px-2 py-0.5 bg-primary-50 text-primary-700 rounded-full">
            <RiCircleFill size={6} />
            <p>{searchParams.get("group_type")}</p>
          </div>
        </div>
      }
    >
      <AnimatePresence mode="popLayout">
        <div className="border rounded-lg bg-white">
          <AccordionButton
            key={"loan-application-btn"}
            label={"Informasi Permohonan Pinjaman"}
            isOpen={accordionLoan}
            onClick={() => setAccordionLoan((prev) => !prev)}
          />
          {accordionLoan && (
            <motion.div
              key={"loan-application-content"}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              exit={{ opacity: 0 }}
            >
              <div className="grid grid-cols-3 gap-6 px-6 py-5">
                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">Nominal Permohonan</p>
                  <p className="">{formatCurrency(loan?.application_value)}</p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">Terbilang</p>
                  <p className="">
                    {startCase(camelCase(loan?.application_spell))}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">Jangka Waktu</p>
                  <p className="">
                    {`${loan?.period_value} ${loan?.period_type}`}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">Nominal Angsuran</p>
                  <p className="">{formatCurrency(loan?.installment_value)}</p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">Terbilang</p>
                  <p className="">
                    {startCase(camelCase(loan?.installment_spell))}
                  </p>
                </div>
              </div>
            </motion.div>
          )}
        </div>
      </AnimatePresence>

      <AnimatePresence mode="popLayout">
        <div className="border rounded-lg bg-white">
          <AccordionButton
            key={"finance-btn"}
            label={"Informasi Keuangan"}
            isOpen={accordionFinance}
            onClick={() => setAccordionFinance((prev) => !prev)}
          />
          {accordionFinance && (
            <motion.div
              key={"finance-content"}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              exit={{ opacity: 0 }}
            >
              <div className="grid grid-cols-3 gap-6 px-6 py-5">
                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">Perkiraan Pendapatan </p>
                  <p className="">
                    {formatCurrency(finance?.income_info[0].income)}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">
                    Pengeluaran Rutin Per Bulan
                  </p>
                  <p className="">
                    {formatCurrency(finance?.routine_outcome_per_month)}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">
                    Penggunaan Pengeluaran Terbesar
                  </p>
                  <p className="">{formatCurrency(finance?.biggest_outcome)}</p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">Berkemampuan Bayar</p>
                  <p className="">
                    {formatCurrency(
                      Math.floor(
                        0.7 *
                          (finance?.business_net_income * 12 +
                            finance?.income_info[0].income * 12 -
                            finance?.routine_outcome_per_month * 12)
                      )
                    )}
                  </p>
                </div>
              </div>
            </motion.div>
          )}
        </div>
      </AnimatePresence>

      <div className="border rounded-md px-4 py-3">
        <InputFormRadio
          label={"Hasil Verifikasi"}
          controllerName={`tabulation_verification`}
          className="flex items-center justify-between gap-4"
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Data Terverifikasi
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Data Belum Sesuai/ Ada Kesalahan
                </>
              ),
              value: false,
            },
          ]}
          disabled={isView}
        />
        {verificationField !== "" && verificationField === false && (
          <InputForm
            controllerName={"tabulation_verification_reason"}
            textArea={true}
            label={"Jelaskan kesalahan/ ketidak sesuaian datanya"}
            className={"w-full rounded-md border p-3"}
            wrapperClass={"mt-5"}
            disabled={isView}
          />
        )}
      </div>
    </CardForm>
  );
};

const FinancedBusiness = ({ data, debtor, isView }) => {
  const [accordion, setAccordion] = useState(true);

  const business_partners = JSON.parse(debtor?.business_partner);

  const methods = useFormContext();

  const verificationField = useWatch({
    control: methods.control,
    name: "household_verification",
  });

  return (
    <CardForm label="Usaha yang Dibiayai">
      <AnimatePresence mode="popLayout">
        <div className="border rounded-lg bg-white">
          <AccordionButton
            key={"present-condition-btn"}
            label={"Kondisi Usaha Saat Ini"}
            isOpen={accordion}
            onClick={() => setAccordion((prev) => !prev)}
          />
          {accordion && (
            <motion.div
              key={"present-condition-content"}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              exit={{ opacity: 0 }}
            >
              <div className="grid grid-cols-3 gap-6 px-6 py-5">
                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">Jenis Usaha</p>
                  <p className="">{debtor?.business_type?.name}</p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">Komoditas Usaha PS</p>
                  <p className="">{debtor?.business_commodity}</p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">Lama Usaha</p>
                  <p className="">{debtor?.business_since}</p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">
                    Sumber Bahan Produksi/Jasa
                  </p>
                  <p className="">{debtor?.business_material}</p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">
                    Kapasitas usaha/produksi
                  </p>
                  <p className="">{debtor?.business_capacity}</p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">
                    Nilai Ekonomi usaha (Rp produksi/jasa)
                  </p>
                  <p className="">
                    {formatCurrency(debtor?.business_economic_values)}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">Tujuan pemasaran</p>
                  <p className="">{debtor?.business_target}</p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">
                    Mitra Usaha (jika ada)
                  </p>
                  <p className="">
                    {business_partners?.length > 0
                      ? business_partners?.join(", ")
                      : "-"}
                  </p>
                </div>
              </div>
            </motion.div>
          )}
          <div className="px-6 py-5">
            <div className="border rounded-md px-4 py-3">
              <InputFormRadio
                label={"Hasil Verifikasi"}
                controllerName={`household_verification`}
                className="flex items-center justify-between gap-4"
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Data Terverifikasi
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Data Belum Sesuai/ Ada Kesalahan
                      </>
                    ),
                    value: false,
                  },
                ]}
                disabled={isView}
              />
              {verificationField !== "" && verificationField === false && (
                <InputForm
                  controllerName={"household_verification_reason"}
                  textArea={true}
                  label={"Jelaskan kesalahan/ ketidak sesuaian datanya"}
                  className={"w-full rounded-md border p-3"}
                  wrapperClass={"mt-5"}
                  disabled={isView}
                />
              )}
            </div>
          </div>
        </div>
      </AnimatePresence>

      <AnimatePresence mode="popLayout">
        <div className="border rounded-lg bg-white">
          <AccordionButton
            key={"present-condition-btn"}
            label={"Prospek Usaha"}
            isOpen={accordion}
            onClick={() => setAccordion((prev) => !prev)}
          />
          {accordion && (
            <motion.div
              key={"present-condition-content"}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              exit={{ opacity: 0 }}
            >
              <div className="grid grid-cols-2 gap-6 px-6 py-5">
                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">Tujuan Pinjaman</p>
                  <p className="">{data?.objective}</p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">
                    Rencana penggunaan modal pinjaman
                  </p>
                  <p className="">{data?.objective_desc}</p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">Lama Usaha</p>
                  <p className="">{debtor?.business_since}</p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">
                    Perkiraan omzet/harga jual/pendapatan kotor
                  </p>
                  <p className="">{formatCurrency(debtor?.business_revenue)}</p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">
                    Perkiraan Biaya Produksi / HPP / Modal Yang Telah
                    Dikeluarkan
                  </p>
                  <p className="">
                    {formatCurrency(debtor?.business_cost_price)}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">
                    Perkiraan pendapatan bersih
                  </p>
                  <p className="">
                    {formatCurrency(debtor?.business_net_income)}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-sm">Siklus Usaha Produksi</p>
                  <p className="">{`${debtor?.business_cycle} ${debtor?.business_cycle_unit}`}</p>
                </div>
              </div>
            </motion.div>
          )}
        </div>
      </AnimatePresence>
    </CardForm>
  );
};

const Collaterals = withFileService(({ data, getFileFromService, isView }) => {
  const methods = useFormContext();

  const verificationField = useWatch({
    control: methods.control,
    name: "collateral_tabulation_verification",
  });

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "collateral_type",
        title: "Bentuk Jaminan",
      },
      {
        key: "collateral_fee",
        title: "Nilai Jaminan",
        render: ({ item }) => {
          return formatCurrency(item.collateral_fee);
        },
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <Button
            variant="icon"
            label={<RiEyeLine className="text-lg" />}
            onClick={() => {
              window.open(item.collateral_file.url, "_blank");
            }}
          />
        ),
      },
    ];
  }, []);

  const getFile = useCallback(() => {
    for (let collateral of data) {
      if (
        collateral.collateral_file === "" ||
        typeof collateral.collateral_file === "object"
      ) {
        continue;
      }

      getFileFromService(collateral.collateral_file, {
        onSuccess: (res) => (collateral.collateral_file = res),
        onError: (err) => console.log(err),
      });
    }
  }, [data, getFileFromService]);

  useEffect(() => {
    getFile();
  }, [getFile]);

  return (
    <CardForm label="Jaminan">
      <TableWrapper className="w-full">
        <Table headers={headers} items={data ?? []} isLoading={false} />
      </TableWrapper>

      <div className="border rounded-md px-4 py-3">
        <InputFormRadio
          label={"Hasil Verifikasi"}
          controllerName={`collateral_tabulation_verification`}
          className="flex items-center justify-between gap-4"
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Data Terverifikasi
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Data Belum Sesuai/ Ada Kesalahan
                </>
              ),
              value: false,
            },
          ]}
          disabled={isView}
        />
        {verificationField !== "" && verificationField === false && (
          <InputForm
            controllerName={"collateral_tabulation_verification_reason"}
            textArea={true}
            label={"Jelaskan kesalahan/ ketidak sesuaian datanya"}
            className={"w-full rounded-md border p-3"}
            wrapperClass={"mt-5"}
            disabled={isView}
          />
        )}
      </div>
    </CardForm>
  );
});

const DeskAnalystTemporaryRecommendation = ({ isView }) => {
  const methods = useFormContext();

  const returnField = useWatch({ control: methods.control, name: "return" });

  const handleSetAngsuran = debounce(() => {
    const amount = methods.watch("loan_value_recomendation");
    const period = methods.watch("time_recomendation_periode_value");
    const periodType = methods.watch("time_recomendation_periode_type");

    if (amount == 1000000 && period === "24" && periodType === "Minggu") {
      methods.setValue("installment_value", 45500);
      methods.setValue(
        "installment_spell",
        `${startCase(toLower(angkaTerbilangJs(45500)))} Rupiah`
      );

      return;
    }

    if (amount == 1000000 && period === "36" && periodType === "Minggu") {
      methods.setValue("installment_value", 31625);
      methods.setValue(
        "installment_spell",
        `${startCase(toLower(angkaTerbilangJs(31625)))} Rupiah`
      );

      return;
    }

    if (amount == 500000 && period === "24" && periodType === "Minggu") {
      methods.setValue("installment_value", 22750);
      methods.setValue(
        "installment_spell",
        `${startCase(toLower(angkaTerbilangJs(22750)))} Rupiah`
      );

      return;
    }

    if (amount == 500000 && period === "36" && periodType === "Minggu") {
      methods.setValue("installment_value", 15850);
      methods.setValue(
        "installment_spell",
        `${startCase(toLower(angkaTerbilangJs(15850)))} Rupiah`
      );

      return;
    }
  }, 500);

  useEffect(() => {
    handleSetAngsuran();
  }, [methods, handleSetAngsuran]);

  return (
    <CardForm label="Rekomendasi Sementara Desk Analis">
      <div className="space-y-5">
        <Controller
          control={methods.control}
          name="loan_value_recomendation"
          render={({ field, fieldState }) => (
            <div>
              <div className="flex gap-6">
                <label
                  htmlFor="loan-value-500000"
                  className="flex gap-3 items-center"
                >
                  <input
                    type="radio"
                    id="loan-value-500000"
                    value={500000}
                    {...methods.register("loan_value_recomendation")}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      methods.setValue(
                        "loan_value_recomendation_spell",
                        `${startCase(
                          toLower(angkaTerbilangJs(e.target.value))
                        )} Rupiah`
                      );
                    }}
                    disabled={isView}
                  />
                  <p>{formatCurrency(500000)}</p>
                </label>

                <label
                  htmlFor="loan-value-1000000"
                  className="flex gap-3 items-center"
                >
                  <input
                    type="radio"
                    id="loan-value-1000000"
                    value={1000000}
                    {...methods.register("loan_value_recomendation")}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      methods.setValue(
                        "loan_value_recomendation_spell",
                        `${startCase(
                          toLower(angkaTerbilangJs(e.target.value))
                        )} Rupiah`
                      );
                    }}
                    disabled={isView}
                  />
                  <p>{formatCurrency(1000000)}</p>
                </label>
              </div>
              {fieldState.invalid && (
                <p className="text-xs text-red-600">
                  {fieldState.error.message}
                </p>
              )}
            </div>
          )}
        />

        <InputForm
          label="Terbilang"
          controllerName="loan_value_recomendation_spell"
          disabled
          className="w-full"
        />

        <div className="flex items-stretch gap-5">
          <AtomSelect
            label="Jangka Waktu"
            required
            controllerName="time_recomendation_periode_value"
            placeholder="Pilih"
            options={[
              { label: 24, value: "24" },
              { label: 36, value: "36" },
            ]}
            returnObject={false}
            menuPosition="fixed"
            disabled={isView}
          />
          <AtomSelect
            controllerName="time_recomendation_periode_type"
            placeholder="Minggu"
            options={[{ label: "Minggu", value: "Minggu" }]}
            wrapperClass="mt-7"
            returnObject={false}
            menuPosition="fixed"
            disabled={isView}
          />
        </div>

        <div className="flex gap-5">
          {/* <InputForm
            label="Nominal Angsuran"
            controllerName="installment_value"
            className="w-full"
            disabled
            isNumber
          /> */}
          <InputCurrencyForm
            label="Nominal Angsuran"
            name="installment_value"
            prefix='Rp '
            disabled
          />
          <InputForm
            label="Terbilang"
            controllerName="installment_spell"
            className="w-full"
            disabled
          />
        </div>

        <div className="flex items-stretch gap-5">
          <InputForm
            controllerName="installment_scheme"
            className="w-full"
            label="Skema Angsuran"
            required
            disabled={isView}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />

          <AtomSelect
            controllerName="installment_scheme_type"
            placeholder="Minggu/Bulan/Tahun/Panen Bayar"
            wrapperClass="mt-7"
            options={[
              { label: "Mingguan", value: "Mingguan" },
              { label: "Bulanan", value: "Bulanan" },
              { label: "Tahunan", value: "Tahunan" },
              { label: "Panen Bayar", value: "Panen Bayar" },
            ]}
            returnObject={false}
            menuPosition="fixed"
            disabled={isView}
          />
        </div>

        <InputFormRadio
          label={"Pengembalian"}
          labelClass={"w-full"}
          controllerName={`return`}
          usingGrid={true}
          wrapperFieldClass="w-full"
          gridClassName="grid grid-cols-2 gap-4 w-full"
          className="flex items-center justify-between flex-wrap gap-2"
          required={true}
          values={[
            {
              label: "Atur Jangka Waktu Pengembalian",
              value: "duration",
            },
            {
              label: "Sesuai Dengan Siklus Usaha",
              value: "business_cycle",
            },
          ]}
          disabled={isView}
        />

        {returnField === "duration" && (
          <div className="flex items-stretch gap-5">
            <InputForm
              label="Jangka Waktu Pengembalian"
              controllerName="time_return_periode_value"
              className="w-full"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              disabled={isView}
            />
            <AtomSelect
              controllerName="time_return_periode_type"
              wrapperClass="mt-7"
              placeholder="Minggu/Bulan/Tahun"
              options={[
                { label: "Minggu", value: "Minggu" },
                { label: "Bulan", value: "Bulan" },
                { label: "Tahun", value: "Tahun" },
              ]}
              returnObject={false}
              menuPosition="fixed"
              disabled={isView}
            />
          </div>
        )}

        <RichEditorForm
          name="notes_analyst"
          label="Catatan Analis"
          readOnly={isView}
        />
      </div>
    </CardForm>
  );
};

export default RequestForm;
