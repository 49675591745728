import React, { useMemo, useState } from "react";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { RiCloseLine, RiEyeLine, RiSearchLine } from "react-icons/ri";
import { Input, Spinner } from "components";
import { customStyles } from "components/atoms/Select";
import Table, { TableWrapper } from "components/atoms/Table";
import Button from "components/atoms/Button/Button";
import Badge from "components/atoms/Badge";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { camelCase, debounce, startCase } from "lodash";
import { getListFieldAnalis } from "services/fdb/field-analis";
import { Pagination } from "components/v2/shared/pagination";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";

const Financing = () => {
  const title = "Daftar Permohonan Perorangan dalam Kelompok";

  const [filters, setFilters] = useState({
    search: "",
    status: "",
    date: "",
    page: 1,
    limit: 10,
    paginate: true,
  });

  const { data, isLoading } = useQuery(
    ["GET LIST FIELD ANALIS", filters],
    ({ queryKey }) => {
      return getListFieldAnalis(queryKey[1]);
    }
  );

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: "/field-analyst",
          },
        ]}
      />

      <h1 className="text-gray-900 text-2xl font-semibold leading-loose">
        {title}
      </h1>

      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <GroupsFilter filters={filters} setFilters={setFilters} />
        {isLoading ? (
          <div className="flex items-center justify-center w-full h-full">
            <Spinner />
          </div>
        ) : (
          <ListTable
            data={data?.data?.data}
            filters={filters}
            setFilters={setFilters}
          />
        )}
      </div>
    </div>
  );
};

const GroupsFilter = ({ filters, setFilters }) => {
  const stateOptions = useMemo(
    () => [
      { value: "verified", label: "Disetujui" },
      { value: "Sedang Dikerjakan", label: "Sedang Dikerjakan" },
      { value: "Selesai ", label: "Selesai " },
    ],
    []
  );

  return (
    // <FormProvider {...methods}>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end">
      <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
        <RiSearchLine className="text-gray-400 text-xl" />

        <Input
          className="w-full bg-white border-none"
          onChange={debounce(
            (e) => setFilters((prev) => ({ ...prev, search: e.target.value })),
            1000
          )}
          placeholder="Cari"
        />
      </div>
      <Select
        className="w-full rounded-md h-10 absolute"
        options={stateOptions}
        placeholder="Status"
        styles={customStyles}
        isClearable
        onChange={debounce(
          (e) => setFilters((prev) => ({ ...prev, status: e?.value ?? "" })),
          1000
        )}
      />
      <div className="relative">
        <ReactDatePicker
          className={
            "date_picker w-full !outline-none !focus:border-primary-700 border h-10 px-3 focus:border-primary-700 rounded-md"
          }
          selected={filters.date}
          onChange={debounce(
            (date) =>
              setFilters((prev) => ({
                ...prev,
                page: 1,
                date: date,
              })),
            1000
          )}
          placeholderText={"dd/mm/yyyy"}
          showYearDropdown={true}
        />
        {filters.date !== "" && (
          <RiCloseLine
            className="absolute top-3 right-2 text-gray-400 hover:text-gray-600 cursor-pointer text-lg"
            onClick={() =>
              setFilters((prev) => ({ ...prev, page: 1, date: "" }))
            }
          />
        )}
      </div>
    </div>
  );
};

const stateColors = {
  1: "bluelight",
  2: "default",
  3: "success",
};

const categoryColors = {
  "Perhutanan Sosial": "error",
  "Non Perhutanan Sosial": "bluelight",
};

const ListTable = ({ data, filters, setFilters }) => {
  const navigate = useNavigate();

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "groupName",
        title: "Nama Kelompok",

        render: ({ item }) => {
          return item.group_profile.name;
        },
      },
      {
        key: "date",
        title: "Tgl Pengajuan",
        render: ({ item }) => {
          return dayjs(item.registration_date).format("DD/MM/YYYY");
        },
      },
      {
        key: "category",
        title: "Tipe Kelompok",
        render: ({ item }) => (
          <Badge color={categoryColors[item.type]} variant="outlined">
            {item.type}
          </Badge>
        ),
      },
      {
        key: "numberOfGroupSubmissions",
        title: "jumlah pengajuan kelompok",
        render: ({ item }) => {
          return item.applicant.length;
        },
      },
      // {
      //   key: "nameOfVerificator",
      //   title: "Nama Verificator",
      //   render: ({ item }) => {
      //     return item.pic_name;
      //   },
      // },
      {
        key: "numberOfMemberApproved",
        title: "Jml Anggota disetujui",
        render: ({ item }) => {
          return item.applicant.filter(
            (applicant) => applicant?.request?.field_status === "2"
          ).length;
        },
      },
      {
        key: "numberOfMemberRejected",
        title: "Jml Anggota ditolak",
        render: ({ item }) => {
          return item.applicant.filter(
            (applicant) => applicant?.request?.field_status === "3"
          ).length;
        },
      },
      {
        key: "verificationDate",
        title: "Tgl Diverifikasi",
        render: ({ item }) => {
          return dayjs(item.created_at).format("DD/MM/YYYY");
        },
      },
      {
        key: "state",
        title: "Status",
        render: ({ item }) => (
          <Badge color={stateColors[item.field_status]}>
            {startCase(camelCase(item.field_status_string))}
          </Badge>
        ),
      },
      // {
      //   key: "baFieldAnalysis",
      //   title: "Ba Field Analis",
      // },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <>
            {["waiting", "unreviewed"].includes(item.state) ? (
              <Button
                color="primary"
                label="Verifikasi"
                onClick={() => {
                  navigate(
                    `/field-analyst/list-member/${item.id}?tab=verification`
                  );
                }}
              />
            ) : (
              <Button
                variant="icon"
                label={<RiEyeLine className="text-lg" />}
                onClick={() => {
                  navigate(`/field-analyst/list-member/${item.id}`);
                }}
              />
            )}
          </>
        ),
      },
    ];
  }, [navigate]);

  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={data?.data} isLoading={false} />
      <Pagination
        page={data?.meta?.current_page}
        limit={filters.limit}
        total={data?.meta?.total}
        totalPage={data?.meta?.last_page}
        onLimitChange={(e) => setFilters((prev) => ({ ...prev, limit: e }))}
        onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
      />
    </TableWrapper>
  );
};

export default Financing;
