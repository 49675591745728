import groupsRoute from "./groups";
import deskAnalystRoute from "./deskAnalystRoute";
import requestListRoute from "./requestListRoute";
import beritaAcaraRoute from "./beritaAcaraRoute";
import requestDisburstmenRoute from "./requestDisburstmenRoute";
import fieldAnalystRoute from "./fieldAnalystRoute";
import offerNegotiationRoute from "./offerNegotiationRoute";
import risalahRoute from "./risalahRoute";
import Dashboard from "pages/Dashboard";
import committeRoute from "./committeRoute";
import AssignOffersList from "pages/AssignPelaksana/Offers";
import AssignOffersDetail from "pages/AssignPelaksana/Offers/detail";
import requestApplicationRoute from "./requestApplicationRoute";
import lembagaRoute from "./institutionRoute";

const routesName = [
  {
    routeType: "private",
    path: "/",
    element: Dashboard,
    exact: true,
    sidebar: true,
    key: "dashboard",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/dashboard",
    element: Dashboard,
    exact: true,
    sidebar: true,
    key: "dashboard",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/assign-offers",
    element: AssignOffersList,
    exact: true,
    sidebar: true,
    key: "assign_offer",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/assign-offers/:id",
    element: AssignOffersDetail,
    exact: true,
    sidebar: true,
    key: "assign_offer",
    type: "canUpdate"
  },
  // ==========================
  // Lembaga
  ...lembagaRoute,

  // ==========================
  // Groups
  ...groupsRoute,

  // ==========================
  // Desk Analyst
  ...deskAnalystRoute,

  // ==========================
  // Request List
  ...requestListRoute,

  // ==========================
  // Berita Acara
  ...beritaAcaraRoute,

  /// Request Disburstment
  ...requestDisburstmenRoute,

  // ==========================
  // Field Analyst
  ...fieldAnalystRoute,
  // ==========================
  // Offer Negotiation
  ...offerNegotiationRoute,

  // ==========================
  // Risalah
  ...risalahRoute,

  // ==========================
  // Committe
  ...committeRoute,

  // ==========================
  // Committe
  ...requestApplicationRoute,
];

export default routesName;
