const DataDefinition = ({ label, value }) => {
  return (
    <div className="flex flex-col gap-1">
      <p className="font-semibold">{label}</p>
      <p>{value}</p>
    </div>
  );
};

export default DataDefinition;
