import { useQuery } from "@tanstack/react-query";
import { Button, Input } from "components";
import Badge from "components/atoms/Badge";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import dayjs from "dayjs";
import { debounce, toLower } from "lodash";
import React, { useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { RiCloseLine, RiEyeLine, RiSearchLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import Table, { TableWrapper } from "components/atoms/Table";
import { customStyles } from "components/atoms/Select";
import Select from "react-select";
import { getListMinutes } from "services/institutions/minutes";
import { Pagination } from "components/v2/shared/pagination";

const stateOptions = [
  { value: "not_yet_review", label: "Belum Direview" },
  { value: "done", label: "Sudah Dilengkapi" },
];

const stateColors = {
  Baru: "warning",
  "Sedang Dikerjakan": "bluelight",
  Draft: "default",
  Ditolak: "error",
  Selesai: "success",
  "Sudah Dilengkapi": "success",
};

const categoryColors = {
  "Perhutanan Sosial": "error",
  "Non Perhutanan Sosial": "bluelight",
};

const InstitutionMinutes = () => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    paginate: true,
    page: 1,
    limit: 10,
    filter: {
      search: "",
      status: "",
      date: "",
    },
  });

  const { data, isLoading } = useQuery({
    queryKey: [
      "risalah-lembaga",
      {
        ...filter,
        // filter: {
        //   ...filter.filter,
        //   date:
        //     filter.filter.date !== ""
        //       ? dayjs(filter.filter.date).format("DD-MMMM-YYYY")
        //       : "",
        // },
      },
    ],
    queryFn: getListMinutes,
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => data?.data?.data,
  });

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "number",
        title: "Nomor Risalah",
      },
      {
        key: "publication_at",
        title: "Tanggal Terbit Risalah",
        render: ({ item }) =>
          item?.publication_at
            ? dayjs(item?.publication_at).format("DD MMMM YYYY")
            : "-",
      },
      {
        key: "name",
        title: "Nama Lembaga",
      },
      {
        key: "distributor_institution_string",
        title: "Tipe Lembaga",
      },
      {
        key: "request_created_at",
        title: "Tanggal Permohonan",
        render: ({ item }) => {
          return item?.request_created_at
            ? dayjs(item.date).format("DD MMMM YYYY")
            : "-";
        },
      },
      {
        key: "status_string",
        title: "Status Risalah",
        render: ({ item }) => (
          <Badge color={stateColors[item.status_string]}>
            {item.status_string}
          </Badge>
        ),
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <Button
            theme="primary"
            label={
              ["baru", "new"].includes(toLower(item.status)) ? (
                "Verifikasi"
              ) : (
                <RiEyeLine className="text-lg" />
              )
            }
            onClick={() => {
              navigate(`/institutions/minutes/${item.id}`, { state: item });
            }}
          />
        ),
      },
    ];
  }, [navigate]);

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: "Risalah Lembaga Penyalur",
            path: "/institutions/minutes",
          },
        ]}
      />

      <h1 className="text-gray-900 text-2xl font-semibold leading-loose">
        Risalah Lembaga Penyalur
      </h1>

      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end">
          <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
            <RiSearchLine className="text-gray-400 text-xl" />
            <Input
              className="w-full bg-white border-none"
              onChange={debounce(
                (e) =>
                  setFilter((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      search: e.target.value,
                    },
                  })),
                1000
              )}
              placeholder="Cari"
            />
          </div>
          <Select
            className="w-full rounded-md h-10 absolute"
            options={stateOptions}
            placeholder="Status"
            styles={customStyles}
            isClearable
            onChange={debounce(
              (e) =>
                setFilter((prev) => ({
                  ...prev,
                  page: 1,
                  filter: {
                    ...prev.filter,
                    status: e?.value ?? "",
                  },
                })),
              1000
            )}
          />
          <div className="relative">
            <ReactDatePicker
              className={
                "date_picker w-full !outline-none !focus:border-primary-700 border h-10 px-3 focus:border-primary-700 rounded-md"
              }
              selected={filter.filter.date}
              onChange={debounce(
                (date) =>
                  setFilter((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      date: date,
                    },
                  })),
                1000
              )}
              placeholderText={"dd/mm/yyyy"}
              showYearDropdown={true}
            />
            {filter.filter.date !== "" && (
              <RiCloseLine
                className="absolute top-3 right-2 text-gray-400 hover:text-gray-600 cursor-pointer text-lg"
                onClick={() =>
                  setFilter((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      date: "",
                    },
                  }))
                }
              />
            )}
          </div>
        </div>
        <TableWrapper className="mt-4">
          <Table
            headers={headers}
            items={data?.data || []}
            isLoading={isLoading}
          />
          <Pagination
            page={data?.meta?.current_page || 1}
            limit={filter.limit || 10}
            total={data?.meta?.total || 0}
            totalPage={data?.meta?.last_page || 1}
            onLimitChange={(e) => setFilter((prev) => ({ ...prev, limit: e }))}
            onPageChange={(e) => setFilter((prev) => ({ ...prev, page: e }))}
          />
        </TableWrapper>
      </div>
    </div>
  );
};

export default InstitutionMinutes;
