import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import AtomSelect from "../../../atoms/Select";
import { getProvinceFDBService } from "services/fdb/region";

const SelectFDBProvince = React.forwardRef((props, ref) => {
  const { className, ...restProps } = props;

  const [options, setOptions] = useState([]);

  const { data } = useQuery({
    queryKey: ["province-fdb"],
    queryFn: async () => {
      const res = await getProvinceFDBService({
        limit: 50,
        order_by: "name",
        order_dir: "asc"
      });

      return res?.data?.data?.data || [];
    },
    retry: false,
  });

  useEffect(() => {
    setOptions([]);
    data?.map((entry) => {
      setOptions((state) => [
        ...state,
        {
          value: entry?.id,
          label: entry?.name,
        },
      ]);
    });
  }, [data]);

  return (
    <AtomSelect
      options={options}
      className={["basic-single mt-2 flex-1", className].join(" ")}
      {...restProps}
    />
  );
});

export default SelectFDBProvince;
