import { IDENTITY_CONFIG, METADATA_OIDC } from "contants/Auth";
import { UserManager, Log, WebStorageStateStore } from "oidc-client";
import { StorageAuthStateStore } from "../app/storageAuthStateStore";
import store from "../app/store";
import { setRequestHeader } from "utils/auth";
import { logoutUser, storeUser } from "redux/authSlice";

export default class AuthService {
    UserManager;
    
    constructor() {
        this.UserManager = new UserManager({
            ...IDENTITY_CONFIG,
            userStore: new StorageAuthStateStore(),
            stateStore: new WebStorageStateStore(),
            metadata: {
                ...METADATA_OIDC
            }
        });
  
        // Logger
        Log.logger = console;
        Log.level = Log.DEBUG;

        this.UserManager.events.addUserLoaded((user) => {
            if (user?.profile) {
              store.dispatch(storeUser(user?.profile));
            }
            setRequestHeader(user?.access_token);
            if (window.location.href.indexOf("signin-oidc") !== -1) {
              window.location.replace(localStorage.getItem("redirectUri"));
            }
          });
    }

    signinRedirectCallback = () => {
        this.UserManager.signinRedirectCallback().then(
            () => {
              window.history.replaceState(
                {},
                window.document.title,
                window.location.origin
              );
              //this.navigateToScreen();
              window.location = localStorage.getItem("redirectUri");
            },
            (err) => {
              console.error(err);
            //   alert('Error caught in signinRedirectCallback()');
            }
          );
    };

    signinRedirect = () => {
        localStorage.setItem("redirectUri", window.location.pathname);
        this.UserManager.signinRedirect({});
    };

    navigateToScreen = () => {
        window.location.replace(localStorage.getItem("redirectUri"));
    };

    isAuthenticated = () => {
        const user = store.getState().oidc.oidc_user;

        return !!user;
    };

    getUser = () => {
      const user = store.getState().oidc.oidc_user;
  
      return user;
    };
    
    signinSilent = () => {
        this.UserManager.signinSilent()
            .then((user) => {
                console.log("signed in", user);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback();
    };

    logout = () => {
        this.UserManager.clearStaleState();
        this.UserManager.removeUser();
        this.UserManager.signoutRedirect();
        store.dispatch(logoutUser());
    };

    signoutRedirectCallback = () => {

        this.UserManager.removeUser();
        this.UserManager.clearStaleState();
        this.UserManager.signoutRedirectCallback().then(() => {
            localStorage.clear();
            window.location.replace('/');
            store.dispatch(logoutUser());
        });

    };   
}
