import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { Button, Input } from "components";
import { customStyles } from "components/atoms/Select";
import Table, { TableWrapper } from "components/atoms/Table";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { Pagination } from "components/v2/shared/pagination";
import { capitalize, debounce } from "lodash";
import { enqueueSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { RiCircleFill, RiEyeLine, RiSearchLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import Select from "react-select";
import { getAgreements, putAgreement } from "services/fdb/agreement";

const dummyData = [
  {
    no: 1,
    agreement_number: "-",
    group_name: "Kelompok A",
    status: "baru",
  },
  {
    no: 2,
    agreement_number: "-",
    group_name: "Kelompok A",
    status: "feedback",
  },
  {
    no: 3,
    agreement_number: "-",
    group_name: "Kelompok A",
    status: "ditolak",
  },
  {
    no: 4,
    agreement_number: "-",
    group_name: "Kelompok A",
    status: "disetujui",
  },
  {
    no: 5,
    agreement_number: "-",
    group_name: "Kelompok A",
    status: "review",
  },
];

const badgeStyle = {
  baru: "bg-[#ECFDF3] text-primary-700",
  terima: "bg-[#EFF8FF] text-[#175CD3]",
  ditolak: "bg-red-50 text-red-700",
  feedback: "bg-orange-50 text-orange-700",
  review: "bg-gray-50 text-gray-700",
};

const statusOptions = [
  {
    value: "baru",
    label: "Baru",
  },
  // {
  //   value: "review",
  //   label: "Review",
  // },
  {
    value: "feedback",
    label: "Feedback",
  },
  {
    value: "ditolak",
    label: "Ditolak",
  },
  {
    value: "disetujui",
    label: "Disetujui",
  },
];

const Agreement = () => {
  const breadcrumbs = useMemo(
    () => [
      {
        label: "Perjanjian",
        path: "/agreement",
      },
    ],
    []
  );

  // ===============================

  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    filter: {
      search: "",
      status: "",
    },
  });

  const { data, isLoading } = useQuery(
    ["GET_LIST_AGREEMENT", filters],
    getAgreements,
    {
      select: (data) => data?.data?.data,
    }
  );

  const queryClient = useQueryClient();
  const mutateAgreement = useMutation({ mutationFn: putAgreement });

  // ===============================

  const headers = useMemo(
    () => [
      {
        key: "index",
        title: "No",
        render: ({ index }) => {
          const no =
            parseInt(data?.meta?.current_page) *
              parseInt(data?.meta?.per_page) -
            parseInt(data?.meta?.per_page) +
            index +
            1;

          // const no = index + 1;

          return no;
        },
      },
      // {
      //   key: "agreement_number",
      //   title: "Nomor Perjanjian",
      // },
      {
        key: "group_name",
        title: "Nama Kelompok",
      },
      {
        key: "status",
        title: "Status",
        render: ({ item }) => {
          return (
            <div
              className={clsx(
                "flex items-center text-xs gap-1 px-[6px] py-0.5 rounded-full font-medium",
                badgeStyle[item?.status ?? "baru"]
              )}
            >
              <RiCircleFill size={8} />
              {capitalize(item?.status ?? "baru")}
            </div>
          );
        },
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) =>
          item.status === "disetujui" ? (
            <div className="flex items-center gap-[10px]">
              <Button
                label="Terima"
                size="xs"
                className="bg-blue-600 hover:bg-blue-700 text-white text-xs"
                onClick={() => {
                  mutateAgreement.mutate(
                    {
                      id: item?.id,
                      data: { notes: "", status: "terima" },
                    },
                    {
                      onSuccess: (res) => {
                        enqueueSnackbar({
                          variant: "success",
                          message: "Berhasil terima perjanjian",
                        });
                        queryClient.invalidateQueries([
                          "GET_LIST_AGREEMENT",
                          filters,
                        ]);
                      },
                    }
                  );
                }}
              />
              <Button
                label="Review"
                size="xs"
                className="bg-primary-600 hover:bg-primary-700 text-white text-xs"
                onClick={() => navigate(`${item?.id}/review`)}
              />
            </div>
          ) : (
            <Button
              variant="icon"
              label={<RiEyeLine className="text-lg" />}
              onClick={() => {
                navigate(`${item.id}/review`);
              }}
            />
          ),
      },
    ],
    [navigate, data?.meta, mutateAgreement, queryClient, filters]
  );

  return (
    <div className="space-y-6">
      <BreadCrumbs routes={breadcrumbs} />

      <div className="flex justify-between">
        <h1 className="text-gray-900 text-lg font-semibold leading-loose">
          Daftar Perjanjian
        </h1>
      </div>

      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-end">
          <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
            <RiSearchLine className="text-gray-400 text-xl" />

            <Input
              className="w-full bg-white border-none"
              onChange={debounce(
                (e) =>
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      search: e.target.value,
                    },
                  })),
                1000
              )}
              placeholder="Cari"
            />
          </div>
          <Select
            className="w-full rounded-md h-10 absolute"
            options={statusOptions}
            placeholder="Status"
            styles={customStyles}
            isClearable
            onChange={debounce(
              (e) =>
                setFilters((prev) => ({
                  ...prev,
                  page: 1,
                  filter: {
                    ...prev.filter,
                    status: e?.value ?? "",
                  },
                })),
              1000
            )}
          />
        </div>
        <TableWrapper className="mt-4">
          <Table
            headers={headers}
            items={data?.data ?? []}
            isLoading={isLoading}
            // items={data?.data ?? []}
          />
          <Pagination
            page={data?.meta?.current_page}
            limit={filters.limit}
            total={data?.meta?.total ?? 1}
            totalPage={data?.meta?.last_page}
            onLimitChange={(e) =>
              setFilters((prev) => ({ ...prev, limit: e, page: 1 }))
            }
            onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
          />
        </TableWrapper>
      </div>
    </div>
  );
};

export default Agreement;
