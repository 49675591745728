import { useEffect, useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useFormContext, useWatch } from "react-hook-form";
import {
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiMapPin2Fill,
} from "react-icons/ri";

import AccordionButton from "components/atoms/AccordionButton";
import CardForm from "components/molecules/CardForm";
import UploadedFileAsync from "components/molecules/UploadFileAsync";
import { Button, InputForm, InputFormRadio, InputFormUpload } from "components";

import SelectFDBProvince from "components/organisms/inputs/SelectFDBProvince";
import SelectFDBCity from "components/organisms/inputs/SelectFDBCity";
import SelectFDBDistrict from "components/organisms/inputs/SelectFDBDistrict";
import SelectFDBVillage from "components/organisms/inputs/SelectFDBVillage";

import { formatCurrency } from "helpers";
import { useMutation } from "@tanstack/react-query";
import { fileUpload, getFile } from "services/fileService";
import GeotagLocationButton from "components/atoms/Button/GeotagLocationButton";

const getFileFromServiceCB = async (id) => {
  const response = await getFile(id);
  return response.data.data;
};

const ControlledBusinessProspects = ({ data }) => {
  const methods = useFormContext();
  const [accordionState, setAccordionState] = useState({
    business_prospect_condition: true,
    income_history_prospect_condition: true,
    tab_business_prospect_condition: true,
  });

  const toggleAccordion = (key) => () => {
    setAccordionState({
      ...accordionState,
      [key]: !accordionState?.[key],
    });
  };
  const { control } = useFormContext();

  const provinceId = useWatch({
    control,
    name: "prospect_financed_province_id",
  });
  const cityId = useWatch({
    control,
    name: "prospect_financed_city_id",
  });
  const districtId = useWatch({
    control,
    name: "prospect_financed_district_id",
  });

  const fileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      if (file?.controllerName === "prospect_debtor_house_file_temp") {
        methods.register("prospect_debtor_house_file");
        methods.setValue("prospect_debtor_house_file", response.data.data.id);
      }
    } catch (error) {
      throw error;
    }
  });

  const groupType = useMemo(() => {
    let result = "deferred";
    if (
      data?.request?.service_type?.toLowerCase() === "hasil hutan bukan kayu"
    ) {
      result = "non_timber_forest";
    } else if (
      data?.request?.service_type?.toLowerCase() === "komoditas non kehutanan"
    ) {
      result = "non_forest_commodities";
    }
    return result;
  }, [data?.request]);

  const prospect_verification = useWatch({
    control: methods.control,
    name: "prospect_verification",
  });

  const { prospect_debtor_house_file } = data?.request?.field_analysts || {
    prospect_debtor_house_file: null,
  };

  useEffect(() => {
    const promises = [];
    if (prospect_debtor_house_file && prospect_debtor_house_file !== "string") {
      promises.push(
        getFileFromServiceCB(prospect_debtor_house_file).then((data) => {
          return {
            formId: "prospect_debtor_house_file_temp",
            type: data?.mimeType,
            ...data,
          };
        })
      );
    }
    if (promises?.length > 0) {
      Promise.all(promises).then((values) => {
        values.map((x) => {
          methods.resetField(x.formId, { defaultValue: x });
        });
      });
    }
  }, []);

  const prospectMaintenancePatternField = useWatch({
    control: methods.control,
    name: "prospect_maintenance_pattern",
  });

  const prospectSalesPatternField = useWatch({
    control: methods.control,
    name: "prospect_sales_pattern",
  });
  const prospectIncomeNeededField = useWatch({
    control: methods.control,
    name: "prospect_income_needed",
  });

  return (
    <CardForm label="Prospek Usaha Yang Dibiayai">
      <AnimatePresence>
        <AccordionButton
          key={"accordion-business-prospect-btn"}
          label={"Permohonan Usaha Yang Dibiayai"}
          isOpen={accordionState?.business_prospect_condition}
          onClick={toggleAccordion("business_prospect_condition")}
        />

        {accordionState?.business_prospect_condition && (
          <motion.div
            key={"accordion-business-prospect-content"}
            className=" gap-4 space-y-4 "
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            }}
            exit={{ opacity: 0 }}
          >
            <div className="flex gap-4 w-full">
              <div className="w-full">
                <div className="font-semibold">Jenis Usaha</div>
                {groupType === "non_timber_forest" ? (
                  <div>
                    {data?.request?.[groupType]?.hhbk_commodities ?? "-"}
                  </div>
                ) : groupType === "non_forest_commodities" ? (
                  <div>{data?.request?.[groupType]?.bussiness_type ?? "-"}</div>
                ) : (
                  <div>
                    {data?.request?.[groupType]?.business_type?.name ?? "-"}
                  </div>
                )}
              </div>
              <div className="w-full">
                <div className="font-semibold">Komoditas Usaha</div>
                {groupType === "non_timber_forest" ? (
                  <div>
                    {data?.request?.[groupType]?.manage_activity_type ?? "-"}
                  </div>
                ) : (
                  <div>
                    {data?.request?.[groupType]?.bussiness_commodities ?? "-"}
                  </div>
                )}
              </div>
              <div className="w-full">
                <div className="font-semibold">Lama Usaha</div>
                <div>{`${
                  data?.request?.[groupType]?.bussiness_duration ?? ""
                } ${
                  data?.request?.[groupType]?.bussiness_duration_type ?? ""
                }`}</div>
              </div>
            </div>
            <div className="flex gap-4 w-full">
              <div className="w-full">
                <div className="font-semibold">Nominal Permohonan</div>
                <div>
                  {data?.request?.[groupType]?.application_fee
                    ? formatCurrency(
                        data?.request?.[groupType]?.application_fee
                      )
                    : "-"}
                </div>
              </div>
              <div className="w-full">
                <div className="font-semibold">Terbilang</div>
                <div>
                  {data?.request?.[groupType]?.application_fee_spel ?? "-"}
                </div>
              </div>
              <div className="w-full">
                <div className="font-semibold">Jangka Waktu</div>
                <div>
                  {data?.request?.[groupType]?.year_duration
                    ? `${data?.request?.[groupType]?.year_duration} Tahun`
                    : "-"}
                </div>
              </div>
              <div className="w-full">
                <div className="font-semibold">Skema Pembiayaan</div>
                <div>{data?.request?.[groupType]?.finance_scheme ?? "-"}</div>
              </div>
            </div>
            <div className="flex gap-4 w-full">
              {/* <div className="w-full">
                <div className="font-semibold">Rencana Jaminan</div>
                <div>
                  Lorem ipsum dolor sit amet consectetur. Turpis tellus vel
                  dolor feugiat sem lacus ultrices diam. Cras phasellus rutrum
                  laoreet orci. Non sit eu purus donec neque purus etiam eu.
                  Egestas leo id quam ut potenti auctor praesent turpis. Et
                  pretium porta et pellentesque eleifend amet morbi feugiat.
                </div>
              </div> */}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        <AccordionButton
          key={"accordion-income-history-prospect-btn"}
          label={"Riwayat pendapatan dari usaha yang dibiayai"}
          isOpen={accordionState?.income_history_prospect_condition}
          onClick={toggleAccordion("income_history_prospect_condition")}
        />

        {accordionState?.income_history_prospect_condition && (
          <motion.div
            key={"accordion-income-history-prospect-content"}
            className=" gap-4 space-y-4 "
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            }}
            exit={{ opacity: 0 }}
          >
            <div className="flex gap-4 w-full">
              <div className="w-full">
                {groupType === "non_timber_forest" ? (
                  <>
                    <div className="font-semibold">
                      Sumber Bahan Baku Hhbk Dan Lokasi On Farm/Off Farm
                    </div>
                    <div>{data?.request?.[groupType]?.material ?? "-"}</div>
                  </>
                ) : groupType === "non_forest_commodities" ? (
                  <>
                    <div className="font-semibold">
                      Produktivitas/Hasil Panen Terakhir
                    </div>
                    <div>{data?.request?.[groupType]?.material ?? "-"}</div>
                  </>
                ) : (
                  <>
                    <div className="font-semibold">
                      Produktivitas/Hasil Panen Terakhir
                    </div>
                    <div>
                      {data?.request?.[groupType]?.last_productivity ?? "-"}
                    </div>
                  </>
                )}
              </div>
              <div className="w-full">
                <div className="font-semibold">Harga (Penjualan Terakhir)</div>
                <div>Rp 1,000,000</div>
              </div>
              <div className="w-full">
                <div className="font-semibold">
                  Luas Lahan Yang digarap (m2)
                </div>
                <div>
                  {
                    data?.submission_application?.group_profile
                      ?.total_member_apply_area
                  }
                </div>
              </div>
            </div>
            <div className="flex gap-4 w-full">
              <div className="w-full">
                <div className="font-semibold">Omzet Usaha</div>
                <div>
                  {data?.request?.[groupType]?.revenue
                    ? formatCurrency(data?.request?.[groupType]?.revenue)
                    : "-"}
                </div>
              </div>
              <div className="w-full">
                <div className="font-semibold">HPP</div>
                <div>
                  {data?.request?.[groupType]?.cost_price
                    ? formatCurrency(data?.request?.[groupType]?.cost_price)
                    : "-"}
                </div>
              </div>
              <div className="w-full">
                <div className="font-semibold">Pendapatan Bersih</div>
                <div>
                  {data?.request?.[groupType]?.net_income
                    ? formatCurrency(data?.request?.[groupType]?.net_income)
                    : "-"}
                </div>
              </div>
            </div>
            <div className="flex gap-4 w-full">
              <div className="w-full">
                <div className="font-semibold">Tujuan Pemasaran</div>
                <div>{data?.request?.[groupType]?.market_target ?? "-"}</div>
              </div>
              {groupType === "deferred" && (
                <div className="w-full">
                  <div className="font-semibold">Tipe Usaha</div>
                  <div>
                    {data?.request?.[groupType]?.bussiness_manage_type ?? "-"}
                  </div>
                </div>
              )}
              <div className="w-full">
                <div className="font-semibold">Mitra Usaha (jika ada)</div>
                <div>
                  {data?.group_profile_member?.bussiness_partner ? (
                    JSON.parse(data?.group_profile_member.bussiness_partner)
                      ?.length > 0 ? (
                      <ul className="list-disc py-2 space-y-2 ">
                        {JSON.parse(
                          data?.group_profile_member.bussiness_partner
                        )?.map((x, key) => (
                          <li key={key}>{x}</li>
                        ))}
                      </ul>
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
            <div className="flex gap-4 w-2/3">
              <div className="w-full">
                <div className="font-semibold">Siklus Usaha</div>
                <div>{`${data?.request?.[groupType]?.bussiness_cycle ?? ""} ${
                  data?.request?.[groupType]?.bussiness_cycle_unit ?? ""
                }`}</div>
              </div>
              <div className="w-full">
                <div className="font-semibold">
                  Kuantitas Komoditas yg Sudah Ada
                </div>
                <div>Lorem ipsum dolor sit amet</div>
              </div>
            </div>
            <div className="w-full">
              <div className="font-semibold">Tujuan Pengajuan Pinjaman</div>
              <div>
                {data?.request?.[groupType]?.purpose_loan_application ?? "-"}
              </div>
            </div>
            <div className="flex gap-4 w-full">
              <div className="w-full">
                <div className="font-semibold">
                  Penjelasan Tujuan Pengajuan Pinjaman{" "}
                </div>
                {data?.request?.[groupType]?.purpose_loan_application_desc ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        data?.request?.[groupType]
                          ?.purpose_loan_application_desc,
                    }}
                  ></div>
                ) : null}
              </div>
            </div>
            {data?.request?.[groupType]?.income_statement_file ? (
              <div className="grid grid-cols-2 gap-4">
                <UploadedFileAsync
                  id={data?.request?.[groupType]?.income_statement_file}
                  name="Laporan Laba Rugi"
                />
              </div>
            ) : null}
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        <AccordionButton
          key={"accordion-tab-business-prospect-btn"}
          label={"Tabulasi Prospek usaha yang dibiayai"}
          isOpen={accordionState?.tab_business_prospect_condition}
          onClick={toggleAccordion("tab_business_prospect_condition")}
        />

        {accordionState?.tab_business_prospect_condition && (
          <motion.div
            key={"accordion-tab-business-prospect-content"}
            className=" gap-4 space-y-4 "
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            }}
            exit={{ opacity: 0 }}
          >
            <div className="flex gap-4">
              <InputFormRadio
                required
                label={
                  "1. Apakah Prospek Usaha DIbiayai Memiliki Pola Pemeliharaan?"
                }
                controllerName={"prospect_maintenance_pattern"}
                values={[
                  {
                    label: <>Ya</>,
                    value: true,
                  },
                  {
                    label: <>Tidak</>,
                    value: false,
                  },
                ]}
              />
            </div>
            {prospectMaintenancePatternField !== "" &&
              prospectMaintenancePatternField === true && (
                <div className="grid grid-cols-2 gap-4">
                  <InputForm
                    controllerName={"prospect_purchased_price"}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                    label={"Harga Beli"}
                    placeholder={"Harga Beli"}
                    isNumber={true}
                    prefix="Rp"
                    onKeyPress={(event) => {
                      const ASCIICode = event.which
                        ? event.which
                        : event.keyCode;
                      if (
                        ASCIICode === 44 ||
                        (ASCIICode >= 48 && ASCIICode <= 57)
                      )
                        return;
                      event.preventDefault();
                    }}
                  />
                  <InputForm
                    controllerName={"prospect_maintenance_price"}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                    label={"Biaya Pemeliharaan"}
                    placeholder={"Biaya Pemeliharaan"}
                    isNumber={true}
                    prefix="Rp"
                    onKeyPress={(event) => {
                      const ASCIICode = event.which
                        ? event.which
                        : event.keyCode;
                      if (
                        ASCIICode === 44 ||
                        (ASCIICode >= 48 && ASCIICode <= 57)
                      )
                        return;
                      event.preventDefault();
                    }}
                  />
                  <InputForm
                    controllerName={"prospect_maintenance_income"}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                    label={"Pendapatan Pemeliharaan"}
                    placeholder={"Pendapatan Pemeliharaan"}
                    isNumber={true}
                    prefix="Rp"
                    onKeyPress={(event) => {
                      const ASCIICode = event.which
                        ? event.which
                        : event.keyCode;
                      if (
                        ASCIICode === 44 ||
                        (ASCIICode >= 48 && ASCIICode <= 57)
                      )
                        return;
                      event.preventDefault();
                    }}
                  />
                  <InputForm
                    controllerName={"prospect_ready_purchased"}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                    label={"Harga Siap Jual"}
                    placeholder={"Harga Siap Jual"}
                    isNumber={true}
                    prefix="Rp"
                    onKeyPress={(event) => {
                      const ASCIICode = event.which
                        ? event.which
                        : event.keyCode;
                      if (
                        ASCIICode === 44 ||
                        (ASCIICode >= 48 && ASCIICode <= 57)
                      )
                        return;
                      event.preventDefault();
                    }}
                  />
                </div>
              )}
            <InputFormRadio
              required
              label={
                "2. Apakah Prospek Usaha DIbiayai Memiliki Pola Penjualan?"
              }
              controllerName={"prospect_sales_pattern"}
              values={[
                {
                  label: <>Ya</>,
                  value: true,
                },
                {
                  label: <>Tidak</>,
                  value: false,
                },
              ]}
            />
            {prospectSalesPatternField !== "" &&
              prospectSalesPatternField === true && (
                <div className="grid grid-cols-2 gap-4">
                  <InputForm
                    controllerName={"prospect_sales_capital"}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                    label={"Modal"}
                    placeholder={"Modal"}
                    isNumber={true}
                    prefix="Rp"
                    onKeyPress={(event) => {
                      const ASCIICode = event.which
                        ? event.which
                        : event.keyCode;
                      if (
                        ASCIICode === 44 ||
                        (ASCIICode >= 48 && ASCIICode <= 57)
                      )
                        return;
                      event.preventDefault();
                    }}
                  />
                  <InputForm
                    controllerName={"prospect_sales_cost"}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                    label={"Biaya Penjualan"}
                    placeholder={"Biaya Penjualan"}
                    isNumber={true}
                    prefix="Rp"
                    onKeyPress={(event) => {
                      const ASCIICode = event.which
                        ? event.which
                        : event.keyCode;
                      if (
                        ASCIICode === 44 ||
                        (ASCIICode >= 48 && ASCIICode <= 57)
                      )
                        return;
                      event.preventDefault();
                    }}
                  />
                  <InputForm
                    controllerName={"prospect_selling_price"}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                    label={"Harga Jual"}
                    placeholder={"Harga Jual"}
                    isNumber={true}
                    prefix="Rp"
                    onKeyPress={(event) => {
                      const ASCIICode = event.which
                        ? event.which
                        : event.keyCode;
                      if (
                        ASCIICode === 44 ||
                        (ASCIICode >= 48 && ASCIICode <= 57)
                      )
                        return;
                      event.preventDefault();
                    }}
                  />
                  <InputForm
                    controllerName={"prospect_selling_income"}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                    label={"Pendapatan Penjualan"}
                    placeholder={"Pendapatan Penjualan"}
                    isNumber={true}
                    prefix="Rp"
                    onKeyPress={(event) => {
                      const ASCIICode = event.which
                        ? event.which
                        : event.keyCode;
                      if (
                        ASCIICode === 44 ||
                        (ASCIICode >= 48 && ASCIICode <= 57)
                      )
                        return;
                      event.preventDefault();
                    }}
                  />
                  <InputForm
                    controllerName={"prospect_percentage_revenue"}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                    label={"Persentase Pendapatan Netto"}
                    placeholder={"Persentase Pendapatan Netto"}
                    isNumber={true}
                    onKeyPress={(event) => {
                      const ASCIICode = event.which
                        ? event.which
                        : event.keyCode;
                      if (
                        ASCIICode === 44 ||
                        (ASCIICode >= 48 && ASCIICode <= 57)
                      )
                        return;
                      event.preventDefault();
                    }}
                  />
                </div>
              )}
            <InputFormRadio
              required
              label={
                "3. Apakah Data Perkiraan Penghasilan Dari Pengembangan Usaha Wajar?"
              }
              controllerName={"prospect_normal_income"}
              values={[
                {
                  label: <>Ya</>,
                  value: true,
                },
                {
                  label: <>Tidak</>,
                  value: false,
                },
              ]}
            />
            <InputFormRadio
              required
              label={
                "4. Kegiatan Usaha Yang Dibiayai Menghasilkan Keuntungan Bagi Calon Debitur?"
              }
              controllerName={"prospect_make_profit"}
              values={[
                {
                  label: <>Ya</>,
                  value: true,
                },
                {
                  label: <>Tidak</>,
                  value: false,
                },
              ]}
            />
            <div className="grid grid-cols-2 gap-4">
              <InputForm
                controllerName={"prospect_net_income_one_siclus"}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={
                  "5. Berapa kisaran pendapatan Nett dalam satu siklus usaha?"
                }
                placeholder={"Kisaran Pendapatan Nett"}
                isNumber={true}
                prefix="Rp"
                onKeyPress={(event) => {
                  const ASCIICode = event.which ? event.which : event.keyCode;
                  if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                    return;
                  event.preventDefault();
                }}
              />
              <InputForm
                controllerName={"prospect_income_siclus"}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={
                  "6. Berapa Proyeksi pendapatan dari usaha yang dibiayai selama siklus pinjaman?"
                }
                placeholder={"Proyeksi Pendapatan"}
                isNumber={true}
                prefix="Rp"
                onKeyPress={(event) => {
                  const ASCIICode = event.which ? event.which : event.keyCode;
                  if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                    return;
                  event.preventDefault();
                }}
              />
            </div>
            <InputFormRadio
              required
              label={
                "7. Apakah Penyesuaian nilai pinjaman berdasarkan kemampuan bayar Diperlukan?"
              }
              controllerName={"prospect_income_needed"}
              values={[
                {
                  label: <>Ya</>,
                  value: true,
                },
                {
                  label: <>Tidak</>,
                  value: false,
                },
              ]}
            />
            {prospectIncomeNeededField !== "" &&
              prospectIncomeNeededField === true && (
                <div className="grid grid-cols-2 gap-4">
                  <InputForm
                    controllerName={"prospect_loan_ability_payment"}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                    label={"Berapa Nilai Penyesuaiannya"}
                    placeholder={"Nilai Penyesuaian"}
                    isNumber={true}
                    prefix="Rp"
                    onKeyPress={(event) => {
                      const ASCIICode = event.which
                        ? event.which
                        : event.keyCode;
                      if (
                        ASCIICode === 44 ||
                        (ASCIICode >= 48 && ASCIICode <= 57)
                      )
                        return;
                      event.preventDefault();
                    }}
                  />
                </div>
              )}
            <p className="font-semibold text-lg">Alamat Usaha Yang Dibiayai</p>
            <div className="grid grid-cols-2 gap-4">
              <SelectFDBProvince
                controllerName={"prospect_financed_province_id"}
                className="basic-single mt-2 flex-1"
                label="Provinsi"
                required={true}
                returnObject={false}
              />
              <SelectFDBCity
                controllerName={"prospect_financed_city_id"}
                className="basic-single mt-2 flex-1"
                label="Kota/Kabupaten"
                required={true}
                provinceId={provinceId}
                returnObject={false}
              />
              <SelectFDBDistrict
                controllerName={"prospect_financed_district_id"}
                className="basic-single mt-2 flex-1"
                label="Kecamatan"
                required={true}
                cityId={cityId}
                returnObject={false}
              />
              <SelectFDBVillage
                controllerName={"prospect_financed_village_id"}
                className="basic-single mt-2 flex-1"
                label="Kelurahan/Desa"
                required={true}
                districtId={districtId}
                returnObject={false}
              />
            </div>
            <InputForm
              controllerName="prospect_financed_address"
              label="Alamat Lengkap Usaha"
              placeholder="Alamat Lengkap Usaha"
              required={true}
              wrapperClass="col-span-2"
              className="rounded-md w-full px-4 py-3 border"
              textArea
            />
            <div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-1">
                <div className="">
                  <span className="font-medium">Geo Location</span>
                  <GeotagLocationButton
                    label="Geotag Usaha"
                    icon={<RiMapPin2Fill className={"w-5 h-5 shrink-0"} />}
                    className={"!bg-primary-700 !text-white"}
                    onSuccess={(val) => {
                      methods.setValue(
                        "prospect_financed_longitude",
                        val.longitude
                      );
                      methods.setValue(
                        "prospect_financed_latitude",
                        val.latitude
                      );
                    }}
                  />
                </div>
                <div className="mb-4">
                  <InputFormUpload
                    uploadFile={fileMutation}
                    controllerName={`prospect_debtor_house_file_temp`}
                    label={"Unggah Foto Usaha Debitur"}
                  />
                </div>
                <InputForm
                  controllerName={"prospect_financed_longitude"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={"Longitude"}
                  placeholder={"Longitude"}
                />
                <InputForm
                  controllerName={"prospect_financed_latitude"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={"Latitude"}
                  placeholder={"Latitude"}
                />
              </div>
            </div>
            <div className="border rounded-md px-4 py-3">
              <InputFormRadio
                label={"Hasil Verifikasi"}
                controllerName={`prospect_verification`}
                className="flex items-center justify-between gap-4"
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Kesimpulan Verivikasi
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Data Belum Sesuai/ Ada Kesalahan
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
              {prospect_verification !== "" &&
                prospect_verification === false && (
                  <InputForm
                    controllerName={"prospect_verification_desk"}
                    textArea={true}
                    label={"Jelaskan kesalahan/ ketidak sesuaian datanya"}
                    className={"w-full rounded-md border p-3"}
                    wrapperClass={"mt-5"}
                  />
                )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </CardForm>
  );
};

export default ControlledBusinessProspects;
