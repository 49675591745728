import * as yup from "yup";
const validationSchema = (service) =>
  yup.object().shape({
    service: yup.bool().nullable().notRequired(),
    committee_minutes_number: yup
      .string()
      .required("No Berita Acara wajib diisi"),
    meeting_circular_minutes_number: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .test(
        "required",
        "No Surat Edaran Rapat Risalah wajib diisi",
        (value, ctx) => {
          if (!value) return false;
          else if (value !== null && value.value && value.label)
            return !!value.value && !!value.label;
        }
      )
      .nullable(),
    minutes_id: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .test("required", "No Risalah wajib diisi", (value, ctx) => {
        if (!value) return false;
        else if (value !== null && value.value && value.label)
          return !!value.value && !!value.label;
      })
      .nullable(),
    group_name: yup.string().required("Nama Kelompok wajib diisi"),
    legal_basis: yup.string().required("Dasar Hukum wajib diisi"),
    provision_service: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .test("required", "Jenis Layanan wajib diisi", (value, ctx) => {
        if (!service) return true;
        if (!value) return false;
        else if (value !== null && value.value && value.label)
          return !!value.value && !!value.label;
      })
      .nullable(),
    fdb_distribution_scheme: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .test("required", "Skema Penyaluran FDB wajib diisi", (value, ctx) => {
        if (!value) return false;
        else if (value !== null && value.value && value.label)
          return !!value.value && !!value.label;
      })
      .nullable(),
    grace_period: yup.string().required("Grace Period wajib diisi"),
    grace_period_time_unit_value: yup.string().when("grace_period", {
      is: (val) => val === "GRACE_PERIOD_CUSTOMIZATION",
      then: () =>
        yup.string().required("Satuan Waktu Grace Period wajib diisi"),
      otherwise: () => yup.string().notRequired(),
    }),
    fdb_financing_term_value: yup
      .string()
      .required("Jangka Waktu Pembiayaan FDB wajib diisi"),
    percentage_service_rate_per_year: yup
      .string()
      .notRequired()
      .when("service", ([val], schema) => {
        if (service) {
          return schema.required("Persentase Pertahun wajib diisi");
        }

        return schema;
      }),

    service_rates_application_method: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .test("required", "Metode Pemberlakuan wajib diisi", (value, ctx) => {
        if (!value) return false;
        else if (value !== null && value.value && value.label)
          return !!value.value && !!value.label;
      })
      .nullable(),
    service_rate_calculation_method: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .test(
        "required",
        "Metode Perhitungan Tarif wajib diisi",
        (value, ctx) => {
          if (!value) return false;
          else if (value !== null && value.value && value.label)
            return !!value.value && !!value.label;
        }
      )
      .nullable(),
    fdb_distribution: yup.array().of(
      yup.object().shape({
        provision_id: yup
          .object()
          .shape({
            value: yup.string().required(),
            label: yup.string().required(),
          })
          .test("required", "Penyaluran FDB wajib diisi", (value, ctx) => {
            if (!value) return false;
            else if (value !== null && value.value && value.label)
              return !!value.value && !!value.label;
          })
          .nullable(),
        sub_provisions: yup.array().of(
          yup.object().shape({
            sub_provision: yup
              .object()
              .shape({
                value: yup.string().required(),
                label: yup.string().required(),
              })
              .test(
                "required",
                "Sub Penyaluran FDB wajib diisi",
                (value, ctx) => {
                  if (!value) return false;
                  else if (value !== null && value.value && value.label)
                    return !!value.value && !!value.label;
                }
              )
              .nullable(),
            sub_sub_provisions: yup.array().of(
              yup.object().shape({
                sub_sub_provision: yup
                  .object()
                  .shape({
                    value: yup.string().required(),
                    label: yup.string().required(),
                  })
                  .test(
                    "required",
                    "Sub Sub Penyaluran FDB wajib diisi",
                    (value, ctx) => {
                      if (!value) return false;
                      else if (value !== null && value.value && value.label)
                        return !!value.value && !!value.label;
                    }
                  )
                  .nullable(),
              })
            ),
          })
        ),
      })
    ),
    fdb_return: yup.array().of(
      yup.object().shape({
        provision_id: yup
          .object()
          .shape({
            value: yup.string().required(),
            label: yup.string().required(),
          })
          .test("required", "Pengembalian FDB wajib diisi", (value, ctx) => {
            if (!value) return false;
            else if (value !== null && value.value && value.label)
              return !!value.value && !!value.label;
          })
          .nullable(),
        sub_provisions: yup.array().of(
          yup.object().shape({
            sub_provision: yup
              .object()
              .shape({
                value: yup.string().required(),
                label: yup.string().required(),
              })
              .test(
                "required",
                "Sub Pengembalian FDB wajib diisi",
                (value, ctx) => {
                  if (!value) return false;
                  else if (value !== null && value.value && value.label)
                    return !!value.value && !!value.label;
                }
              )
              .nullable(),
          })
        ),
      })
    ),
    positive_covenants: yup.array().of(
      yup.object().shape({
        provision_id: yup
          .object()
          .shape({
            value: yup.string().required(),
            label: yup.string().required(),
          })
          .test("required", "Positive Covenants wajib diisi", (value, ctx) => {
            if (!value) return false;
            else if (value !== null && value.value && value.label)
              return !!value.value && !!value.label;
          })
          .nullable(),
      })
    ),
    negative_covenants: yup.array().of(
      yup.object().shape({
        provision_id: yup
          .object()
          .shape({
            value: yup.string().required(),
            label: yup.string().required(),
          })
          .test("required", "Negative Covenants wajib diisi", (value, ctx) => {
            if (!value) return false;
            else if (value !== null && value.value && value.label)
              return !!value.value && !!value.label;
          })
          .nullable(),
      })
    ),
  });

export default validationSchema;
