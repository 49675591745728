import React, { useState, useMemo, useEffect } from "react";
import Pagination from "components/atoms/Pagination";

const TablePagination = ({
  totalPage,
  page: _page = 1,
  itemsPerPage: _itemsPerPage = 10,
  perPageOptions = [5, 10, 25, 50],
  totalItems = 0,
  onChange,
  onItemsPerPageChange,
  hideLabel,
}) => {
  const [page, setPage] = useState(_page);
  const [itemsPerPage, setItemsPerPage] = useState(_itemsPerPage);
  const start = useMemo(() => {
    return (page - 1) * itemsPerPage + 1;
  }, [page, itemsPerPage]);
  const end = useMemo(() => {
    const end = page * itemsPerPage;
    return end < totalItems ? end : totalItems;
  }, [page, itemsPerPage, totalItems]);

  useEffect(() => {
    if (onChange) {
      onChange(page);
    }
  }, [onChange, page]);

  useEffect(() => {
    if (onItemsPerPageChange) {
      onItemsPerPageChange(itemsPerPage);
    }
  }, [onItemsPerPageChange, itemsPerPage]);

  return (
    <footer className="flex items-center justify-between px-4 py-3 rounded-b-lg">
      <div>
        <span className="text-sm text-gray-600">Tampilkan Data</span>
        <select
          className="mx-3 text-sm font-semibold border px-2 py-2 rounded-lg"
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(e.target.value);
          }}
        >
          {perPageOptions.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </select>
        <span className="text-sm text-gray-600">
          {start}-{end} data dari {totalItems} data.
        </span>
      </div>
      <Pagination
        page={page}
        totalPage={totalPage}
        onChange={(page) => {
          setPage(page);
        }}
        hideLabel={hideLabel}
      />
    </footer>
  );
};

export default TablePagination;
