import React, { useState } from "react";
import { Sidebar, Header } from "components";

const BasicLayout = ({ children, sidebar }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      {sidebar ? (
        <div className="flex relative">
          <Sidebar
            open={isOpen}
            onMenuClick={() => {
              setIsOpen(!isOpen);
            }}
          />
          <div
            className={[
              "flex-grow shrink-0",
              isOpen ? "max-w-full lg:max-w-[calc(100%-312px)]" : "max-w-full",
            ].join(" ")}
          >
            <Header
              open={isOpen}
              onMenuClick={() => {
                setIsOpen(!isOpen);
              }}
            />
            <main className="min-h-[calc(100vh-100px)] px-8 py-6 bg-gray-50 w-full">
              {children}
            </main>
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default BasicLayout;
