import React, { Fragment, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";
import { BiPlus, BiTrash } from "react-icons/bi";
import { camelCase, isEmpty, isEqual, startCase, toLower } from "lodash";
import angkaTerbilangJs from "@develoka/angka-terbilang-js";

import CardForm from "components/molecules/CardForm";
import UploadedFileAsync from "components/molecules/UploadFileAsync";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import Button from "components/atoms/Button/Button";
import {
  BottomFormAction,
  InputForm,
  InputFormRadio,
  InputFormUpload,
} from "components";
import AtomDatePicker from "components/atoms/Datepicker";
import AtomSelect from "components/atoms/Select";
import RichEditorForm from "components/molecules/RichEditorForm";

import { fileUpload, getFile } from "services/fileService";

import { validationSchemeDirection } from "./validation-schema";
import {
  getDetailAgencyCommitteeMinutesDirection,
  postDetailAgencyCommitteeMinutesDirection,
} from "services/institutions/ba-committee";
import { formatCurrency } from "helpers";
import { enqueueSnackbar } from "notistack";

const TechnicalCommittee = ({ data }) => {
  return (
    <CardForm label="Berita Acara Komite Teknis">
      <div className="space-y-4">
        <div className="bg-[#E1F1D6] my-5 p-3 w-full">
          <p className="font-bold text-[#01A24A]">Berita Acara Komite Teknis</p>
        </div>
        <div className="grid grid-cols-4 gap-4">
          <div>
            <div className="font-semibold">No Berita Acara Komite Teknis</div>
            <div>{data?.tech?.committee_minutes_number}</div>
          </div>
          <div>
            <div className="font-semibold">No Surat Edaran Rapat Risalah</div>
            <div>{data?.tech?.meeting_circular_number_minutes}</div>
          </div>
          <div>
            <div className="font-semibold">No Risalah</div>
            <div>{data?.tech?.risalah_number}</div>
          </div>
          <div>
            <div className="font-semibold">Nama Lembaga</div>
            <div>{data?.tech?.agency_name}</div>
          </div>
          <div className="grid col-span-4">
            <UploadedFileAsync
              id={data?.tech?.tech_file}
              name="Berita Acara Komite Teknis"
            />
          </div>
        </div>
        <div className="bg-[#E1F1D6] my-5 p-3 w-full">
          <p className="font-bold text-[#01A24A]">
            Rekomendasi Ketentuan Pembiayaan Komite Teknis
          </p>
        </div>
        <div className="grid grid-cols-4 gap-4">
          <div className="grid col-span-4 grid-cols-2">
            <div>
              <div className="font-semibold">Jenis Layanan</div>
              <div>{data?.recommend?.feb_distributor_institution}</div>
            </div>
            <div>
              <div className="font-semibold">Skema Penyaluran</div>
              <div>{data?.recommend?.fdb_distribution_scheme}</div>
            </div>
          </div>
          <div className="col-span-4">
            <div>
              <div className="font-semibold">Penggunaan / Tujuan FDB</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.recommend?.fdb_objectives,
                }}
              ></div>
            </div>
          </div>
          <div className="grid col-span-4 grid-cols-2">
            <div>
              <div className="font-semibold">Jumlah FDB</div>
              <div>{formatCurrency(data?.recommend?.fdb_value)}</div>
            </div>
            <div>
              <div className="font-semibold">Terbilang</div>
              <div>
                {startCase(
                  toLower(angkaTerbilangJs(data?.recommend?.fdb_value))
                )}
              </div>
            </div>
          </div>
          <div className="grid col-span-4 grid-cols-3">
            <div>
              <div className="font-semibold">Tarif Layanan Pertahun</div>
              <div>
                {data?.recommend?.percentage_service_rate_per_year
                  ? `${data?.recommend?.percentage_service_rate_per_year}%`
                  : "-"}
              </div>
            </div>
            <div>
              <div className="font-semibold">Metode Pemberlakuan</div>
              <div>
                {startCase(
                  toLower(data?.recommend?.service_rates_application_method)
                )}
              </div>
            </div>
            <div>
              <div className="font-semibold">Metode Perhitungan Tarif</div>
              <div>
                {startCase(
                  toLower(data?.recommend?.service_rate_calculation_method)
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="font-semibold">Jangka Waktu</div>
            <div>
              {data?.recommend?.term_value && data?.recommend?.term_type
                ? `${data?.recommend?.term_value} ${data?.recommend?.term_type}`
                : "-"}
            </div>
          </div>
          <div>
            <div className="font-semibold">Grace Period</div>
            <div>
              {data?.recommend?.grace_period_term_value &&
                data?.recommend?.grace_period_term_type
                ? `${data?.recommend?.grace_period_term_value} ${data?.recommend?.grace_period_term_type}`
                : "-"}
            </div>
          </div>
          <div>
            <div className="font-semibold">Jangka Waktu Perpanjangan</div>
            <div>
              {data?.recommend?.extend_value && data?.recommend?.extend_type
                ? `${data?.recommend?.extend_value} ${data?.recommend?.extend_type}`
                : "-"}
            </div>
          </div>
          <div>
            <div className="font-semibold">Kesepakatan Kedua Belah Pihak?</div>
            <div>{data?.recommend?.agreed_by_both ? "Ya" : "Tidak"}</div>
          </div>
          <div>
            <div className="font-semibold">Tahap Pencairan</div>
            <div>{data?.recommend?.distribution_phase}</div>
          </div>
          {data?.recommend?.distribution?.map((el) => (
            <div>
              <div className="font-semibold">Tahap {el?.phase}</div>
              <div>{formatCurrency(el?.value)}</div>
            </div>
          ))}
          <div className="col-span-4">
            <div>
              <div className="font-semibold">Jaminan</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.recommend?.collateral,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </CardForm>
  );
};

const Provisions = () => {
  const { setValue, control, formState } = useFormContext();
  const { errors } = formState;

  const withGracePeriodField = useWatch({
    control,
    name: "with_grace_period",
  });
  const canBeExtended = useWatch({
    control,
    name: "can_be_extended",
  });
  const disbursementStage = useWatch({
    control,
    name: "distribution_stage",
  });

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "distribution",
  });

  return (
    <div className="space-y-5">
      <div className="flex gap-4">
        <InputForm
          required={true}
          label="Jumlah FDB"
          controllerName="fdb_value"
          isNumber={true}
          prefix="Rp"
          onKeyPress={(event) => {
            const ASCIICode = event.which ? event.which : event.keyCode;
            if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
              return;
            event.preventDefault();
          }}
          className="w-full"
          onChangeInput={(event) => {
            setValue(
              "fdb_loan_spell",
              `${startCase(
                camelCase(angkaTerbilangJs(Number(event.target.value)))
              )} Rupiah`
            );
          }}
        />
        <InputForm
          label="Terbilang"
          controllerName="fdb_loan_spell"
          disabled
          className="w-full"
        />
      </div>
      <div className="grid grid-cols-3 gap-4">
        <p className="font-semibold col-span-3">Tarif Layanan</p>
        <InputForm
          label={"Persentase Pertahun"}
          required={true}
          controllerName={"percentage_service_rate_per_year"}
          className="w-full rounded-md border p-3 "
          rightIcon="%"
          onKeyPress={(event) => {
            const ASCIICode = event.which ? event.which : event.keyCode;
            if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
              return;
            event.preventDefault();
          }}
        />
        <AtomSelect
          label={"Metode Pemberlakuan"}
          wrapperClass={"flex flex-col justify-end"}
          controllerName={`service_rates_application_method`}
          options={[
            { value: "FIXED", label: "Fixed" },
            { value: "FLOATING", label: "Floating" },
          ]}
          className="basic-single w-full"
          required={true}
          placeholder="Metode Pemberlakuan"
          returnObject={false}
        />
        <AtomSelect
          label={"Metode Perhitungan Tarif"}
          wrapperClass={"flex flex-col justify-end"}
          controllerName={`service_rate_calculation_method`}
          options={[
            { value: "EFFECTIVE", label: "Efektif" },
            { value: "FLAT", label: "Flat" },
          ]}
          className="basic-single w-full"
          required={true}
          placeholder="Metode Perhitungan Tarif"
          returnObject={false}
        />
      </div>
      <div className="w-full grid grid-cols-2 gap-4">
        <p className="font-semibold col-span-3">Jangka Waktu</p>
        <InputForm
          label={"Jangka Waktu"}
          required={true}
          controllerName={"term_value"}
          className="w-full rounded-md border p-3 "
          onKeyPress={(event) => {
            const ASCIICode = event.which ? event.which : event.keyCode;
            if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
              return;
            event.preventDefault();
          }}
        />
        <AtomSelect
          label={"Satuan Waktu"}
          wrapperClass={"flex flex-col justify-end"}
          controllerName={`term_type`}
          options={[
            { value: "Tahun", label: "Tahun" },
            { value: "Bulan", label: "Bulan" },
            { value: "Hari", label: "Hari" },
          ]}
          className="basic-single w-full"
          required={true}
          returnObject={false}
        />
      </div>
      <InputFormRadio
        label={"Dengan Grace Period?"}
        controllerName={"with_grace_period"}
        values={[
          {
            label: (
              <>
                <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                Ya
              </>
            ),
            value: true,
          },
          {
            label: (
              <>
                <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                Tidak
              </>
            ),
            value: false,
          },
        ]}
      />
      {withGracePeriodField === true && (
        <div className="flex gap-4">
          <InputForm
            label={"Jangka Waktu Grace Period"}
            required={true}
            controllerName={"grace_period_term_value"}
            className="w-full rounded-md border p-3 "
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
          />
          <AtomSelect
            label={"Satuan Waktu Grace Period"}
            wrapperClass={"flex flex-col justify-end"}
            controllerName={`grace_period_term_type`}
            options={[
              { value: "Tahun", label: "Tahun" },
              { value: "Bulan", label: "Bulan" },
              { value: "Hari", label: "Hari" },
            ]}
            className="basic-single w-full"
            required={true}
            returnObject={false}
          />
        </div>
      )}
      <InputFormRadio
        label={"Jangka Waktu Diperpanjang?"}
        controllerName={"can_be_extended"}
        values={[
          {
            label: (
              <>
                <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                Ya
              </>
            ),
            value: true,
          },
          {
            label: (
              <>
                <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                Tidak
              </>
            ),
            value: false,
          },
        ]}
      />
      {canBeExtended === true && (
        <>
          {/* <div className="flex gap-4">
            <InputForm
              label={"Waktu Perpanjangan"}
              required={true}
              controllerName={"extend_value"}
              className="w-full rounded-md border p-3 "
              onKeyPress={(event) => {
                const ASCIICode = event.which ? event.which : event.keyCode;
                if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                  return;
                event.preventDefault();
              }}
            />
            <AtomSelect
              label={"Satuan Waktu"}
              wrapperClass={"flex flex-col justify-end"}
              controllerName={`extend_type`}
              options={[
                { value: "Tahun", label: "Tahun" },
                { value: "Bulan", label: "Bulan" },
                { value: "Hari", label: "Hari" },
              ]}
              className="basic-single w-full"
              required={true}
              returnObject={false}
            />
          </div> */}
          <InputFormRadio
            label={
              "Jangka Waktu Dapat Diperpanjang Sesuai Dengan Kesepakatan Dua Belah Pihak?"
            }
            controllerName={"agreed_by_both"}
            values={[
              {
                label: (
                  <>
                    <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                    Ya
                  </>
                ),
                value: true,
              },
              {
                label: (
                  <>
                    <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                    Tidak
                  </>
                ),
                value: false,
              },
            ]}
          />
        </>
      )}

      <InputFormRadio
        label={"Tahapan Penyaluran"}
        controllerName={"distribution_stage"}
        values={[
          {
            label: "Sekaligus",
            value: "once",
          },
          {
            label: "Secara Bertahap",
            value: "phase",
          },
        ]}
      />
      {disbursementStage === "phase" && (
        <Fragment>
          {fields.map((field, idx) => (
            <div className="flex space-x-4 items-end" key={field.id}>
              <InputForm
                label={`Total Dana yang Disalurkan pada Tahap Ke-${idx + 1}`}
                required={true}
                controllerName={`distribution.${idx}.value`}
                className="w-full rounded-md border p-3"
                isNumber={true}
                prefix="Rp"
                onKeyPress={(event) => {
                  const ASCIICode = event.which ? event.which : event.keyCode;
                  if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                    return;
                  event.preventDefault();
                }}
              />
              {idx > 0 && (
                <Button
                  type="button"
                  onClick={() => remove(idx)}
                  label={<BiTrash className="text-white" />}
                  className={`!p-3 rounded-lg !bg-error-600 flex-none ${!isEmpty(errors) &&
                    !isEmpty(errors?.["distribution"]?.[idx]?.value)
                    ? "!mb-[26px]"
                    : ""
                    }`}
                />
              )}
            </div>
          ))}
          <hr />
          <div className="flex justify-end">
            <Button
              type="button"
              onClick={() => {
                append({
                  phase: fields.length + 1,
                  value: undefined,
                });
                setValue("distribution_phase", fields.length + 1);
              }}
              className="p-3 rounded-lg flex-none"
              label={
                <div className="flex space-x-2 items-center">
                  <BiPlus className="text-black" />
                  Tahap Penyaluran
                </div>
              }
            />
          </div>
        </Fragment>
      )}
      <RichEditorForm
        className="quill-6"
        name={`collateral`}
        label="Jaminan"
        required={true}
      />
    </div>
  );
};

const getFileFromServiceCB = async (id) => {
  const response = await getFile(id);
  return response.data.data;
};

const CreateCommitteeDirector = () => {
  const prevDataRef = useRef();
  const navigate = useNavigate();
  const params = useParams();
  const methods = useForm({
    resolver: yupResolver(validationSchemeDirection),
    defaultValues: {
      //   minutes_id: null,
      //   meeting_circular_minutes_number: null,
      distribution: [{ phase: 1, value: undefined }],
    },
  });

  const queryClient = useQueryClient();
  const mutateUpdateBADirection = useMutation({
    mutationFn: postDetailAgencyCommitteeMinutesDirection,
  });
  const { data } = useQuery(
    ["GET_DETAIL_BA_DIRECTION", params?.id],
    getDetailAgencyCommitteeMinutesDirection,
    {
      enabled: !!params?.id,
      select: (data) => data?.data?.data,
    }
  );

  const revisionFileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      methods.register("committee_minutes_file_direction");
      methods.setValue(
        "committee_minutes_file_direction",
        response.data.data.id
      );
    } catch (error) {
      throw error;
    }
  });

  // const onDraft = () => {
  //   const values = methods.getValues();
  //   console.log("values: ", values);
  // };

  const onBack = () => {
    navigate(-1);
  };

  const onFormSubmit = () => {
    const formData = methods.getValues();

    if (
      formData?.use_tech_file === true ||
      formData?.distribution_stage === "once"
    ) {
      methods.setValue(`distribution.0.phase`, 1);
      methods.setValue(
        `distribution.0.value`,
        data?.tech_committee?.recommend?.fdb_value
      );
      // console.log(methods.getValues("distribution.0.value"));
    }

    methods.handleSubmit((values) => {
      let mapData = {
        ...values,
        percentage_service_rate_per_year:
          values?.percentage_service_rate_per_year
            ? Number(values?.percentage_service_rate_per_year.replace(",", "."))
            : null,
        committee_minutes_date_direction: values?.committee_minutes_date_direction
          ? new Date(values?.committee_minutes_date_direction)
          : null,
      };

      delete mapData?.committee_minutes_file_direction_temp;

      mutateUpdateBADirection.mutate(
        {
          id: params?.id,
          data: mapData,
          params: { is_draft: false },
        },
        {
          onSuccess: () => {
            enqueueSnackbar({
              variant: "success",
              message: "Berhasil Buat Berita Acara Komite Teknis",
            });
            queryClient.invalidateQueries();
            navigate(-1);
          },
          onError: () => {
            enqueueSnackbar({
              variant: "error",
              message: "Gagal Buat Berita Acara Komite Teknis",
            });
          },
        }
      );
    })();
  };

  const isUseTechnical = useWatch({
    control: methods.control,
    name: "use_tech_file",
  });

  useEffect(() => {
    if (!isEmpty(data)) {
      const promises = [Promise.resolve()];
      const {
        committee_minutes_date_direction,
        committee_minutes_file_direction: fileBA,
        ...rest
      } = data || {
        fileBA: null,
      };

      if (fileBA && fileBA !== "string") {
        promises.push(
          getFileFromServiceCB(fileBA).then((data) => {
            return {
              formId: "committee_minutes_file_direction_temp",
              type: data?.mimeType,
              ...data,
            };
          })
        );
      }

      if (promises?.length > 0) {
        Promise.all(promises).then((values) => {
          const obj = {
            fileBA,
            committee_minutes_date_direction: committee_minutes_date_direction? new Date(committee_minutes_date_direction) : new Date(),
            ...rest,
          };

          values.map((x) => {
            if (!isEmpty(x)) {
              obj[x.formId] = x;
            }
          });

          methods.reset(obj);
        });
      }
    }
  }, [data, methods]);

  return (
    <div className="mb-40">
      <FormProvider {...methods}>
        <BreadCrumbs
          routes={[
            {
              label: "Berita Acara Lembaga Penyalur Komite Teknis",
              path: "/institutions/director-committee",
            },
            {
              label: "Buat Berita Acara Komite Teknis",
              path: "/institutions/director-committee/create",
            },
          ]}
        />
        <TechnicalCommittee data={data?.tech_committee} />
        <CardForm label="Berita Acara Komite Direksi">
          <div className="grid grid-cols-2 gap-4">
            <InputForm
              label="No Berita Acara Komite Direksi"
              required={true}
              controllerName={"committee_minutes_number_direction"}
              className={"rounded-md w-full"}
            />
            <AtomDatePicker
              controllerName={"committee_minutes_date_direction"}
              label="Tanggal Terbit Berita Acara Komite Direksi"
              placeholder="dd/mm/yyyy"
              datepickerOptions={{
                dateFormat: "dd/MM/yyyy",
              }}
              showErrorLabel
            />
          </div>
          <InputFormUpload
            controllerName={`committee_minutes_file_direction_temp`}
            fileType="PDF, Docx"
            label={"Upload Berita Acara Komite Direksi"}
            uploadFile={revisionFileMutation}
            maxSize={10 * 1024 * 1024}
          />
        </CardForm>
        <CardForm label="Ketentuan Pembiayaan">
          <div className="space-y-4">
            <InputFormRadio
              label={"Gunakan Ketentuan Komite Teknis?"}
              controllerName={"use_tech_file"}
              values={[
                {
                  label: (
                    <>
                      <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                      Ya
                    </>
                  ),
                  value: true,
                },
                {
                  label: (
                    <>
                      <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                      Tidak
                    </>
                  ),
                  value: false,
                },
              ]}
            />
            {isUseTechnical === false && <Provisions />}
          </div>
        </CardForm>
        <CardForm label="Notes/Change Log">
          <InputForm
            label="&nbsp;"
            controllerName={"notes"}
            className={"rounded-md w-full"}
            textArea={true}
            rows={5}
          />
        </CardForm>
        <BottomFormAction
          backButtonAction={onBack}
          submitActionButton={onFormSubmit}
          disableDrafButton={false}
          // drafButtonAction={onDraft}
          disableButtonSubmit={false}
          hideDraft={true}
          lastStep={true}
          className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
          submitButtonProps={{ type: "submit" }}
        />
      </FormProvider>
    </div>
  );
};

export default CreateCommitteeDirector;
