import React from "react";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { MemberInGroupDetailNPS } from "./NonPerhutananSosial";
import { MemberInGroupDetailPS } from "./PerhutananSosial";

const CandidateDebitorDetail = () => {
  const title = "Daftar Registrasi Perorangan dalam Kelompok";

  const { id } = useParams();

  const [searchParams] = useSearchParams();

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: "/groups",
          },
          {
            label: "Detail",
            path: `/groups/${id}`,
          },
        ]}
      />

      {searchParams.get("group_type") === "Non Perhutanan Sosial" ? (
        <MemberInGroupDetailNPS />
      ) : (
        <MemberInGroupDetailPS />
      )}
    </div>
  );
};

export default CandidateDebitorDetail;
