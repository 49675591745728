import {
  SAVE_DRAFT_DATA,
  RESET_DRAFT_DATA,
  UPDATE_DRAFT_DATA,
} from "../actions";

const initialState = {
  data: { general_information: {}, legal_foundations: {}, working_area: {} },
};

export const profileUmumReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DRAFT_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case UPDATE_DRAFT_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    case RESET_DRAFT_DATA:
      return {
        ...state,
        data: {
          general_information: {},
          legal_foundations: {},
          working_area: {},
        },
      };
    default:
      return state;
  }
};
