import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, InputForm, InputFormRadio } from "components";
import {
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
} from "components/atoms/Modal";
import { getErrorMessage } from "helpers";
import { isBoolean, isEmpty } from "lodash";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router";
import { postVerificationIncomeInfo } from "services/fdb/field-analis";

import tabulation_button from "assets/icons/tabulation.svg";
import tabulation_button_filled from "assets/icons/tabulation-filled.svg";

import { validationSchema } from "./validation-schema";
import StoreFormSection from "./StoreFormSection";
import AgroForestryFormSection from "./AgroForestryFormSection";
import AddressFormSection from "./AddressFormSection";

const VerificationIncomeInfoModal = ({
  status,
  data,
  defaultValue,
  ...rest
}) => {
  const queryClient = useQueryClient();
  const { applicantId } = useParams();
  const [open, setOpen] = useState(false);

  const ButtonRendered = useMemo(() => {
    if (status) {
      return (
        <Button
          variant="icon"
          className="!py-0 !px-0"
          label={<img src={tabulation_button_filled} alt="tabulation-button" />}
        />
      );
    }
    return (
      <Button
        variant="icon"
        className="!py-0 !px-0"
        label={<img src={tabulation_button} alt="tabulation-filled" />}
      />
    );
  }, [status]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      bussiness_cycle_unit: "year",
    },
    mode: "onChange",
  });

  const onSubmit = (payload) => {
    const dataToSend = {
      ...payload,
      bussiness_cycle_unit: payload?.bussiness_cycle_unit || "year",
      bussiness_cycle: payload?.bussiness_cycle
        ? Number(payload?.bussiness_cycle)
        : 0,
      selling_price: payload?.selling_price
        ? Number(payload?.selling_price)
        : 0,
      hpp: payload?.hpp ? Number(payload?.hpp) : 0,
      income: payload?.income ? Number(payload?.income) : 0,
      maintenance_purchase_price: payload?.maintenance_purchase_price
        ? Number(payload?.maintenance_purchase_price)
        : 0,
      maintenance_selling_price: payload?.maintenance_selling_price
        ? Number(payload?.maintenance_selling_price)
        : 0,
      maintenance_cost: payload?.maintenance_cost
        ? Number(payload?.maintenance_cost)
        : 0,
      maintenance_revenue: payload?.maintenance_revenue
        ? Number(payload?.maintenance_revenue)
        : 0,
      sales_modal: payload?.sales_modal ? Number(payload?.sales_modal) : 0,
      sales_selling_price: payload?.sales_selling_price
        ? Number(payload?.sales_selling_price)
        : 0,
      sales_expenses: payload?.sales_expenses
        ? Number(payload?.sales_expenses)
        : 0,
      sales_revenue: payload?.sales_revenue
        ? Number(payload?.sales_revenue)
        : 0,
      business_longitude: payload?.business_longitude?.toString() || "",
      business_latitude: payload?.business_latitude?.toString() || "",
      maintenance_pattern: payload?.maintenance_pattern || false,
      sales_pattern: payload?.sales_pattern || false,
    };
    delete dataToSend.business_photo_file_temp;
    postVerification.mutate(dataToSend);
  };

  const postVerification = useMutation({
    mutationKey: ["post-verification-income-info", data.id],
    mutationFn: (payload) => {
      const res = postVerificationIncomeInfo(data.id, "deferred", payload);
      return res;
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries([
        "field-analyst-applicant-income-info",
        applicantId,
      ]);
      enqueueSnackbar("Data berhasil disimpan", { variant: "success" });
      methods.reset();
      setOpen(false);
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data?.field_analyst_tabulation)) {
      const temp = {};
      if (data?.field_analyst_tabulation?.income) {
        temp["income"] =
          data?.field_analyst_tabulation?.income;
      }
      if (data?.field_analyst_tabulation?.hpp) {
        temp["hpp"] =
          data?.field_analyst_tabulation?.hpp;
      }
      if (data?.field_analyst_tabulation?.selling_price) {
        temp["selling_price"] =
          data?.field_analyst_tabulation?.selling_price;
      }
      if (data?.field_analyst_tabulation?.income_per_month) {
        temp["income_per_month"] =
          data?.field_analyst_tabulation?.income_per_month;
      }
      if (data?.field_analyst_tabulation?.business_address) {
        temp["business_address"] =
          data?.field_analyst_tabulation?.business_address;
      }
      if (data?.field_analyst_tabulation?.business_city_id) {
        temp["business_city_id"] =
          data?.field_analyst_tabulation?.business_city_id;
      }
      if (data?.field_analyst_tabulation?.business_district_id) {
        temp["business_district_id"] =
          data?.field_analyst_tabulation?.business_district_id;
      }
      if (data?.field_analyst_tabulation?.business_latitude) {
        temp["business_latitude"] =
          data?.field_analyst_tabulation?.business_latitude;
      }
      if (data?.field_analyst_tabulation?.business_longitude) {
        temp["business_longitude"] =
          data?.field_analyst_tabulation?.business_longitude;
      }
      if (data?.field_analyst_tabulation?.business_photo_file) {
        temp["business_photo_file"] =
          data?.field_analyst_tabulation?.business_photo_file;
      }
      if (data?.field_analyst_tabulation?.business_province_id) {
        temp["business_province_id"] =
          data?.field_analyst_tabulation?.business_province_id;
      }
      if (data?.field_analyst_tabulation?.business_village_id) {
        temp["business_village_id"] =
          data?.field_analyst_tabulation?.business_village_id;
      }
      if (data?.field_analyst_tabulation?.bussiness_cycle) {
        temp["bussiness_cycle"] =
          data?.field_analyst_tabulation?.bussiness_cycle;
      }
      if (data?.field_analyst_tabulation?.bussiness_cycle_unit) {
        temp["bussiness_cycle_unit"] =
          data?.field_analyst_tabulation?.bussiness_cycle_unit;
      }
      if (data?.field_analyst_tabulation?.maintenance_cost) {
        temp["maintenance_cost"] =
          data?.field_analyst_tabulation?.maintenance_cost;
      }
      if (isBoolean(data?.field_analyst_tabulation?.maintenance_pattern)) {
        temp["maintenance_pattern"] =
          data?.field_analyst_tabulation?.maintenance_pattern;
      }
      if (data?.field_analyst_tabulation?.maintenance_purchase_price) {
        temp["maintenance_purchase_price"] =
          data?.field_analyst_tabulation?.maintenance_purchase_price;
      }
      if (data?.field_analyst_tabulation?.maintenance_revenue) {
        temp["maintenance_revenue"] =
          data?.field_analyst_tabulation?.maintenance_revenue;
      }
      if (data?.field_analyst_tabulation?.maintenance_selling_price) {
        temp["maintenance_selling_price"] =
          data?.field_analyst_tabulation?.maintenance_selling_price;
      }
      if (data?.field_analyst_tabulation?.non_funded_business_category) {
        temp["non_funded_business_category"] =
          data?.field_analyst_tabulation?.non_funded_business_category;
      }
      if (data?.field_analyst_tabulation?.sales_expenses) {
        temp["sales_expenses"] = data?.field_analyst_tabulation?.sales_expenses;
      }
      if (data?.field_analyst_tabulation?.sales_modal) {
        temp["sales_modal"] = data?.field_analyst_tabulation?.sales_modal;
      }
      if (isBoolean(data?.field_analyst_tabulation?.sales_pattern)) {
        temp["sales_pattern"] = data?.field_analyst_tabulation?.sales_pattern;
      }
      if (data?.field_analyst_tabulation?.sales_revenue) {
        temp["sales_revenue"] = data?.field_analyst_tabulation?.sales_revenue;
      }
      if (data?.field_analyst_tabulation?.sales_selling_price) {
        temp["sales_selling_price"] =
          data?.field_analyst_tabulation?.sales_selling_price;
      }
      methods.reset(temp);
    }
  }, [data, methods]);

  const modalCallback = (val) => {
    if (val === false) {
      methods.reset();
    }
    setOpen(val);
  };

  const non_funded_business_category = methods.watch(
    "non_funded_business_category"
  );

  return (
    <Modal
      trigger={ButtonRendered}
      contentClassName="!max-w-5xl z-[99] !overflow-y-scroll"
      isOpen={open}
      onOpenChange={modalCallback}
      manual
    >
      <ModalHeader
        noBorder
        hideClose={false}
        title={
          <div className="text-lg font-semibold text-[#101828]">
            Tabulasi Usaha Lainnya Selain yang Dibiayai
          </div>
        }
      />
      <ModalBody>
        <FormProvider {...methods}>
          <div className="space-y-4 mb-6">
            <div>
              <div className="flex gap-4">
                <InputFormRadio
                  usingGrid
                  gridClassName="grid grid-cols-2 gap-4 mt-2"
                  label="Kategori Usaha Lainnya Selain yang Dibiayai"
                  controllerName={"non_funded_business_category"}
                  required
                  values={[
                    {
                      label: "Karyawan/Perangkat Desa/Tukang Bangunan",
                      value: "employee",
                    },
                    {
                      label: "Warung",
                      value: "store",
                    },
                    {
                      label: "Wanagembala",
                      value: "herdsman",
                    },
                    {
                      label: "Wanatani/Minatani",
                      value: "agroforestry",
                    },
                  ]}
                />
              </div>
            </div>
            {non_funded_business_category === "employee" ? (
              <>
                <InputForm
                  controllerName={`income_per_month`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label="Berapa Pendapatan per Bulan"
                  placeholder="Pendapatan per Bulan"
                  prefix="Rp"
                  required
                  isNumber={true}
                  onKeyPress={(event) => {
                    const ASCIICode = event.which ? event.which : event.keyCode;
                    if (
                      ASCIICode === 44 ||
                      (ASCIICode >= 48 && ASCIICode <= 57)
                    )
                      return;
                    event.preventDefault();
                  }}
                />
              </>
            ) : null}
            {non_funded_business_category === "store" ? (
              <StoreFormSection />
            ) : null}
            {non_funded_business_category === "herdsman" ||
            non_funded_business_category === "agroforestry" ? (
              <AgroForestryFormSection />
            ) : null}
            <AddressFormSection data={data} />
            <div className="grid grid-cols-2 gap-4 pt-6">
              <ModalClose>
                <Button
                  label="Batal"
                  theme="secondary"
                  block
                  className="w-full"
                />
              </ModalClose>
              <Button
                label={"Submit"}
                type="button"
                theme="primary"
                block
                className="w-full"
                onClick={() => methods.handleSubmit(onSubmit)()}
              />
            </div>
          </div>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default VerificationIncomeInfoModal;
