import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";
import { find } from "lodash";
import * as yup from "yup";

export const validationSchemeTech = yup.object({
  committee_minutes_number: yup
    .string()
    .required()
    .label("No Berita Acara Komite Teknis wajib diisi"),
  committee_minutes_date: yup
    .string()
    .required()
    .label("Tanggal Terbit Berita Acara"),
  risalah_id: yup.string().required().label(""),
  tech_file_temp: yup
    .mixed()
    .test(
      "cid_img",
      ({ label }) =>
        validationErrorMsg.mixed.required.replace("${path}", label),
      (value, context) => {
        const allowedTypes = [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        if (value) {
          const data = find(allowedTypes, (at) => value.type.includes(at));
          if (!data) {
            return context.createError({
              message: validationCustomErrorMsg.isFiletype("PDF, DOCX")({
                label: "Berita Acara Komite Teknis",
              }),
            });
          }
          if (value.size > 10 * 1024 * 1024) {
            return context.createError({
              message: validationCustomErrorMsg.isFileSize("10MB")({
                label: "Berita Acara Komite Teknis",
              }),
            });
          }
          return true;
        }
        // otherwise, return false to render the field required
        return false;
      }
    )
    .label("Berita Acara Komite Teknis"),
  feb_distributor_institution: yup
    .string()
    .required()
    .label("Jenis Layanan FDB"),
  fdb_distribution_scheme: yup
    .string()
    .required()
    .label("Skema Penyaluran FDB"),
  fdb_objectives: yup.string().required().label("Tujuan Penggunaan"),
  fdb_value: yup
    .number()
    .required()
    .transform((val) => (isNaN(val) ? undefined : val))
    .label("Jumlah FDB"),
  percentage_service_rate_per_year: yup
    .string()
    .required()
    // .transform((val) => (isNaN(val) ? undefined : val))
    .label("Persentase Pertahun"),
  service_rates_application_method: yup
    .string()
    .required()
    .label("Metode Pemberlakuan"),
  service_rate_calculation_method: yup
    .string()
    .required()
    .label("Metode Perhitungan Tarif"),
  term_value: yup
    .number()
    .required()
    .transform((val) => (isNaN(val) ? undefined : val))
    .label("Jangka Waktu"),
  term_type: yup.string().required().label("Satuan Waktu"),
  with_grace_period: yup
    .bool()
    .transform((val) => (typeof val === "boolean" ? val : null))
    .label("Opsi Ini"),
  grace_period_term_value: yup
    .number()
    .notRequired()
    .transform((val) => (isNaN(val) ? undefined : val))
    .when("with_grace_period", ([val], scheme) => {
      if (val) {
        return scheme.required();
      }

      return scheme;
    })
    .label("Jangka Waktu Grace Period"),
  grace_period_term_type: yup
    .string()
    .notRequired()
    .when("with_grace_period", ([val], scheme) => {
      if (val) {
        return scheme.required();
      }

      return scheme;
    })
    .label("Jangka Waktu Grace Period"),
  can_be_extended: yup
    .bool()
    .transform((val) => (typeof val === "boolean" ? val : null))
    .label("Opsi Ini"),
  agreed_by_both: yup
    .bool()
    .transform((val) => (typeof val === "boolean" ? val : null))
    .notRequired()
    .when("can_be_extended", ([val], scheme) => {
      if (val) {
        return scheme.required();
      }

      return scheme;
    })
    .label("Opsi Ini"),
  // extend_value: yup
  //   .number()
  //   .notRequired()
  //   .transform((val) => (isNaN(val) ? undefined : val))
  //   .when("can_be_extended", ([val], scheme) => {
  //     if (val) {
  //       return scheme.required();
  //     }

  //     return scheme;
  //   })
  //   .label("Waktu Perpanjangan"),
  // extend_type: yup
  //   .string()
  //   .notRequired()
  //   .when("can_be_extended", ([val], scheme) => {
  //     if (val) {
  //       return scheme.required();
  //     }

  //     return scheme;
  //   })
  //   .label("Satuan Waktu"),
  distribution_phase: yup.string().required().label("Tahapan Penyaluran"),
  distribution_stage: yup
    .number()
    .transform((val) => (isNaN(val) ? undefined : val))
    .notRequired()
    .when("distribution_phase", ([val], scheme) => {
      if (val === "phase") {
        return scheme.required();
      }
      return scheme;
    })
    .label(),
  distribution: yup
    .array(
      yup.object({
        phase: yup
          .number()
          .transform((val) => (isNaN(val) ? undefined : val))
          .notRequired()
          .label(""),
        value: yup
          .number()
          .transform((val) => (isNaN(val) ? undefined : val))
          .required()
          .label("Total Dana yang Disalurkan"),
      })
    )
    .notRequired()
    .when("distribution_phase", ([val], scheme) => {
      if (val === "phase") {
        return scheme.required();
      }

      return scheme;
    }),
  collateral: yup.string().required().label("Jaminan"),
});
