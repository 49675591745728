import Financing from "pages/FieldAnalyst";
import ListMember from "pages/FieldAnalyst/ListMember";
import FinancingDetail from "pages/FieldAnalyst/ListMember/Detail";
import DetailPS from "pages/FieldAnalyst/ListMember/DetailPS";

import AssignFieldAnalyst from "pages/AssignPelaksana/FieldAnalyst";
import AssignFieldAnalystDetail from "pages/AssignPelaksana/FieldAnalyst/detail";

export default [
  {
    routeType: "private",
    path: "/assign-field-analyst",
    element: AssignFieldAnalyst,
    exact: true,
    sidebar: true,
    key: "assign_field_analyst",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/assign-field-analyst/:id",
    element: AssignFieldAnalystDetail,
    exact: true,
    sidebar: true,
    key: "assign_field_analyst",
    type: "canUpdate"
  },
  {
    routeType: "private",
    path: "/field-analyst",
    element: Financing,
    exact: true,
    sidebar: true,
    key: "field_analyst",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/field-analyst/list-member/:id",
    element: ListMember,
    exact: true,
    sidebar: true,
    key: "field_analyst",
    type: "canUpdate"
  },
  {
    routeType: "private",
    path: "/field-analyst/list-member/:memberId/:applicantId",
    element: FinancingDetail,
    exact: true,
    sidebar: true,
    key: "field_analyst",
    type: "canUpdate"
  },
  {
    routeType: "private",
    path: "/field-analyst/perhutanan-sosial/:memberId/:applicantId",
    element: DetailPS,
    exact: true,
    sidebar: true,
    key: "field_analyst",
    type: "canUpdate"
  },
];
