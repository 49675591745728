import { useQuery } from "@tanstack/react-query";
import { Button, Input, Spinner } from "components";
import Badge from "components/atoms/Badge";
import { customStyles } from "components/atoms/Select";
import Table, { TableWrapper } from "components/atoms/Table";
import TablePagination from "components/atoms/TablePagination";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import dayjs from "dayjs";
import { formatCurrency } from "helpers";
import { debounce } from "lodash";
import React, { useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { FormProvider, useForm } from "react-hook-form";
import { RiEyeLine, RiSearchLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router";
import Select from "react-select";
import { getDeskAnalystPelaksanaDetail } from "services/fdb/desk-analyst";

const MemberInGroupDetail = () => {
  const { id } = useParams();
  // For List Table
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [filters, setFilters] = useState({
    filter: {
      search: undefined,
      status: undefined,
      date: undefined,
    },
    paginate: true,
  });

  const methods = useForm({
    // resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      search: "",
      status: "",
      date: "",
    },
  });

  const params = useParams();

  const { data, isLoading } = useQuery(
    [
      "GET DESK ANALIS PELAKSANA DETAIL",
      { id: params.id, params: { ...filters, page, limit } },
    ],
    ({ queryKey }) => getDeskAnalystPelaksanaDetail(queryKey[1]),
    {
      // enabled: !!params.id,
      cacheTime: 0,
      onSuccess: ({ data }) => {},
    }
  );

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: "Perorangan dalam Kelompok",
            path: "/member-in-group",
          },
          {
            label: "Daftar Anggota",
            path: `/member-in-group/${id}`,
          },
        ]}
      />
      <ProfileSection data={data?.data?.data} />
      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <ListFilter
          methods={methods}
          filters={filters}
          setFilters={setFilters}
        />
        {isLoading ? (
          <div className="flex items-center justify-center w-full h-full">
            <Spinner />
          </div>
        ) : (
          <ListTable
            id={id}
            totalItems={totalItems}
            totalPage={totalPage}
            setLimit={setLimit}
            setPage={setPage}
            data={data?.data?.data?.applicant}
          />
        )}
      </div>
      {/* <div className="sticky bottom-4 flex justify-between w-full p-5 bg-white border rounded-md">
        <Button
          className="p-4 border rounded-lg"
          label={
            <div className="flex items-center gap-2">
              <RiArrowLeftLine />
              Batal
            </div>
          }
          onClick={() => navigate(-1)}
        />
        <ModalConfirm type={"approve"} />
      </div> */}
    </div>
  );
};

const ListFilter = ({ methods, filters, setFilters }) => {
  const stateOptions = useMemo(
    () => [
      { value: "Baru", label: "Baru" },
      { value: "Sedang Dikerjakan", label: "Sedang Dikerjakan" },
      { value: "Selesai ", label: "Selesai " },
    ],
    []
  );

  return (
    <FormProvider {...methods}>
      <form className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end">
        <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
          <RiSearchLine className="text-gray-400 text-xl" />

          <Input
            className="w-full bg-white border-none"
            onChange={debounce(
              (e) =>
                setFilters((prev) => ({
                  ...prev,
                  filter: {
                    ...prev.filter,
                    search: e.target.value,
                  },
                })),
              1000
            )}
            placeholder="Cari"
          />
        </div>
        <Select
          className="w-full rounded-md h-10 absolute"
          options={stateOptions}
          placeholder="Status"
          styles={customStyles}
          onChange={debounce(
            (e) =>
              setFilters((prev) => ({
                ...prev,
                filter: {
                  ...prev.filter,
                  status: e.value,
                },
              })),
            1000
          )}
        />
        <ReactDatePicker
          className={
            "date_picker w-full !outline-none !focus:border-primary-700 border h-10 px-3 focus:border-primary-700 rounded-md"
          }
          selected={filters.date}
          onChange={debounce(
            (date) =>
              setFilters((prev) => ({
                ...prev,
                filter: {
                  ...prev.filter,
                  date: date,
                },
              })),
            1000
          )}
          placeholderText={"dd/mm/yyyy"}
          showYearDropdown={true}
        />
      </form>
    </FormProvider>
  );
};

const stateColors = {
  1: "warning",
  3: "error",
  2: "success",
};

const ListTable = ({ totalPage, totalItems, setPage, setLimit, id, data }) => {
  const navigate = useNavigate();

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "name_debtor",
        title: "Nama",
      },
      {
        key: "request.request_date",
        title: "Tgl Permohonan",
        render: ({ item }) => {
          return item?.request?.request_date
            ? dayjs(item.request.request_date).format("DD MMMM YYYY")
            : "-";
        },
      },
      {
        key: "state",
        title: "Status",
        render: ({ item }) =>
          item?.request?.desk_status ? (
            <Badge color={stateColors[Number(item?.request?.desk_status || 1)]}>
              {item?.request?.desk_status_string ?? "-"}
            </Badge>
          ) : (
            "-"
          ),
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => {
          if (Number(item?.request?.desk_status || 1) > 1) {
            return (
              <Button
                variant="icon"
                label={<RiEyeLine className="text-lg" />}
                onClick={() => {
                  const groupType = item.service_type
                    ? "Non Perhutanan Sosial"
                    : "Perhutanan Sosial";
                  navigate(
                    `/member-in-group/${id}/debitor/${
                      item.id
                    }?status=${encodeURIComponent(
                      Number(item?.request?.desk_status || 1)
                    )}&group_type=${groupType}`
                  );
                }}
              />
            );
          } else {
            return (
              <Button
                theme="primary"
                label="Verifikasi"
                onClick={() => {
                  const groupType = item.service_type
                    ? "Non Perhutanan Sosial"
                    : "Perhutanan Sosial";
                  navigate(
                    `/member-in-group/${id}/debitor/${item.id}?group_type=${groupType}`
                  );
                }}
              />
            );
          }
        },
      },
    ];
  }, [navigate, id]);

  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={data} isLoading={false} />
      <TablePagination
        totalPage={totalPage}
        totalItems={totalItems}
        onChange={setPage}
        onItemsPerPageChange={setLimit}
      />
    </TableWrapper>
  );
};

const ProfileSection = ({ data }) => {
  return (
    <CardForm label="Profile Kelompok">
      <div className="grid grid-cols-4">
        <div>
          <p className="font-semibold">Nama Kelompok</p>
          <p>{data?.group_profile?.name}</p>
        </div>
        <div>
          <p className="font-semibold">Tanggal Pengajuan Anggota</p>
          <p>{dayjs(data?.registration_date).format("DD MMMM YYYY")}</p>
        </div>
        <div>
          <p className="font-semibold">Total Anggota Diajukan</p>
          <p>{data?.total_member} Orang</p>
        </div>
        <div>
          <p className="font-semibold">Total Nilai Permohonan</p>
          <p className="p-2 bg-[#EEFFF4] border-[#01A24A] border rounded-md text-[#01A24A] font-bold">
            {formatCurrency(data?.total_application_value)}
          </p>
        </div>
      </div>
    </CardForm>
  );
};

export default MemberInGroupDetail;
