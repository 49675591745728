import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { schemaStep1, schemaStep2 } from "../validation-schema";
import {
  getDeskAnalystPelaksanaDetailMember,
  postDeskAnalystPelaksanaDetail,
} from "services/fdb/desk-analyst";
import { enqueueSnackbar } from "notistack";
import { BottomFormAction, Button, Spinner } from "components";
import { RiCloseCircleLine, RiFile2Line } from "react-icons/ri";
import { TabsList, TabsRoot, TabsTrigger } from "components/atoms/Tabs";
import RequestForm from "./RequestForm";
import CandidateDebitorData from "./CandidatDebtorData";

export const MemberInGroupDetailNPS = () => {
  const [activeTab, setActiveTab] = useState("profile");
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const params = useParams();

  const methods = useForm({
    resolver:
      activeTab === "profile"
        ? yupResolver(schemaStep1)
        : yupResolver(schemaStep2),
    defaultValues: {
      installment_scheme_type: "Tahunan",
      time_recomendation_periode_type: "Tahun",
      time_return_periode_type: "Tahun",
    },
  });

  const { data, isLoading } = useQuery(
    ["GET DETAIL DESK ANALIS PELAKSANA MEMBER", params.debitorId],
    ({ queryKey }) => getDeskAnalystPelaksanaDetailMember(queryKey[1]),
    {
      enabled: !!params.debitorId,
      onSuccess: ({ data }) => {
        const tempValues =
          data?.data?.group_profile_member?.applicant_member?.request
            ?.desk_analysts;
        if (tempValues) {
          if (tempValues?.desk_analyst_termins?.length > 0) {
            tempValues["disburstment_phase_items"] =
              tempValues?.desk_analyst_termins
                ?.sort((a, b) => a.phase - b.phase)
                .map((x) => ({
                  id: x?.id,
                  value: x.value,
                }));
            delete tempValues?.desk_analyst_termins;
          }
          methods.reset({
            ...tempValues,
          });
        }
      },
    }
  );

  const mutateVerification = useMutation(
    ["VERIFICATION DEBITUR"],
    ({ id, payload, params }) => {
      postDeskAnalystPelaksanaDetail(id, payload, params);
    }
  );

  const handleBackButton = () => {
    if (activeTab === "profile") {
      navigate(-1);
    }

    if (activeTab === "form") {
      setActiveTab("profile");
    }
  };

  const handleDraftButton = () => {
    methods.handleSubmit((values) => {
      if (activeTab === "profile") {
        setActiveTab("form");

        return;
      }

      const disburstmentPhase =
        values.disburstment === "stage"
          ? values.disburstment_phase_items.map((data, index) => ({
              ...data,
              phase: index + 1,
              value: data.value,
            }))
          : [
              {
                phase: 1,
                value: values.fixed_loan_value_recomendation,
              },
            ];

      const mapPayload = {
        ...values,
        debitur_verification_reason: Boolean(values?.debitur_verification)
          ? null
          : values?.debitur_verification_reason,
        couple_verification_reason: Boolean(values?.couple_verification)
          ? null
          : values?.couple_verification_reason,
        address_domicile_verification_reason: Boolean(
          values?.address_domicile_verification
        )
          ? null
          : values?.address_domicile_verification_reason,
        tabulation_verification_reason: Boolean(values?.tabulation_verification)
          ? null
          : values?.tabulation_verification_reason,
        household_verification_reason: Boolean(values?.household_verification)
          ? null
          : values?.household_verification_reason,
        collateral_tabulation_verification_reason: Boolean(
          values?.collateral_tabulation_verification
        )
          ? null
          : values?.collateral_tabulation_verification_reason,
        land_ownership_doc_verification_reason: Boolean(
          values?.land_ownership_doc_verification
        )
          ? null
          : values?.land_ownership_doc_verification_reason,
        disburstment_phase_items: disburstmentPhase,
        disburstment_phase: disburstmentPhase.length,
        verification_status: false,
      };

      mutateVerification.mutate(
        {
          id: params.debitorId,
          payload: mapPayload,
          params: { is_non_forestry: true },
        },
        {
          onSuccess: (res) => {
            enqueueSnackbar({
              variant: "success",
              message: (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontWeight: "bold" }}>
                    Data berhasil disimpan!
                  </span>
                  <span>Data tidak akan dilanjutkan ke Field Analyst</span>
                </div>
              ),
            });
            setTimeout(() => {
              navigate(`/member-in-group/${params.id}`);
            }, 1000);
          },
          onError: (err) =>
            enqueueSnackbar({
              variant: "error",
              message: err,
            }),
        }
      );
    })();
  };

  const handleSubmit = () => {
    methods.handleSubmit((values) => {
      if (activeTab === "profile") {
        setActiveTab("form");

        return;
      }

      const disburstmentPhase =
        values.disburstment === "stage"
          ? values.disburstment_phase_items.map((data, index) => ({
              ...data,
              phase: index + 1,
              value: data.value,
            }))
          : [
              {
                phase: 1,
                value: values.fixed_loan_value_recomendation,
              },
            ];

      const mapPayload = {
        ...values,
        debitur_verification_reason: Boolean(values?.debitur_verification)
          ? null
          : values?.debitur_verification_reason,
        couple_verification_reason: Boolean(values?.couple_verification)
          ? null
          : values?.couple_verification_reason,
        address_domicile_verification_reason: Boolean(
          values?.address_domicile_verification
        )
          ? null
          : values?.address_domicile_verification_reason,
        tabulation_verification_reason: Boolean(values?.tabulation_verification)
          ? null
          : values?.tabulation_verification_reason,
        household_verification_reason: Boolean(values?.household_verification)
          ? null
          : values?.household_verification_reason,
        collateral_tabulation_verification_reason: Boolean(
          values?.collateral_tabulation_verification
        )
          ? null
          : values?.collateral_tabulation_verification_reason,
        land_ownership_doc_verification_reason: Boolean(
          values?.land_ownership_doc_verification
        )
          ? null
          : values?.land_ownership_doc_verification_reason,
        disburstment_phase_items: disburstmentPhase,
        disburstment_phase: disburstmentPhase.length,
        verification_status: true,
      };

      mutateVerification.mutate(
        {
          id: params.debitorId,
          payload: mapPayload,
          params: { is_non_forestry: true },
        },
        {
          onSuccess: (res) => {
            enqueueSnackbar({
              variant: "success",
              message: (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontWeight: "bold" }}>
                    Data berhasil disimpan!
                  </span>
                  <span>Data yang Anda masukkan sudah berhasil dikirim</span>
                </div>
              ),
            });
            setTimeout(() => {
              navigate(`/member-in-group/${params.id}`);
            }, 1000);
          },
          onError: (err) =>
            enqueueSnackbar({
              variant: "error",
              message: "Terjadi Kesalahan.",
            }),
        }
      );
    })();
  };

  return isLoading ? (
    <div className="flex justify-center p-4">
      <Spinner theme={"primary"} />
    </div>
  ) : (
    <>
      <div className="w-full flex justify-end">
        <Button
          theme="secondary"
          variant="icon"
          label={
            <>
              <RiFile2Line className="text-lg" />
              Log Aktivitas
            </>
          }
        />
      </div>

      <FormProvider {...methods}>
        <TabsRoot value={activeTab} onValueChange={setActiveTab}>
          <TabsList>
            <TabsTrigger value="profile">Data Calon Debitur</TabsTrigger>
            <TabsTrigger value="form">Formulir Permohonan</TabsTrigger>
          </TabsList>
          <CandidateDebitorData
            // initialValues={profileData}
            data={data?.data?.data}
            type={id === 1 ? "persos" : "non-persos"}
          />
          <RequestForm
            type={id === 1 ? "persos" : "non-persos"}
            data={data?.data?.data}
          />
        </TabsRoot>
      </FormProvider>

      <BottomFormAction
        backButtonAction={handleBackButton}
        submitActionButton={handleSubmit}
        disableDrafButton={false}
        lastStep={activeTab !== "profile"}
        hideSubmit={activeTab !== "profile" && !!status}
        hideDraft={activeTab === "profile" || !!status}
        draftButtonProps={{
          label: (
            <>
              <RiCloseCircleLine size={22} />
              <p>Tolak Pengajuan</p>
            </>
          ),
          className:
            "bg-red-600 text-white flex gap-2 items-center font-medium",
        }}
        submitButtonProps={{
          label:
            activeTab === "profile" ? "Selanjutnya" : "Kirim ke Field Analis",
        }}
        drafButtonAction={handleDraftButton}
        disableButtonSubmit={false}
        // hideDraft={true}
        className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
      />
    </>
  );
};
