import React, { useEffect } from "react";
import { TabsContent } from "components/atoms/Tabs";
import CardForm from "components/molecules/CardForm";
import {
  BottomFormAction,
  InputForm,
  InputFormRadio,
  InputFormUpload,
} from "components";
import { useMutation } from "@tanstack/react-query";
import { fileUpload, getFile } from "services/fileService";
import { FormProvider, useForm } from "react-hook-form";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";
import { find, isEmpty } from "lodash";
import { useSearchParams } from "react-router-dom";

const getFileFromServiceCB = async (id) => {
  const response = await getFile(id);
  return response.data.data;
};

const validationSchema = yup.object().shape({
  desk_number: yup.string().required().label("Nomor Berita Acara Desk Analis"),
  file_temp: yup
    .mixed()
    .test(
      "cid_img",
      ({ label }) =>
        validationErrorMsg.mixed.required.replace("${path}", label),
      (value, context) => {
        const allowedTypes = [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        if (value) {
          const data = find(allowedTypes, (at) => value.type.includes(at));
          if (!data) {
            return context.createError({
              message: validationCustomErrorMsg.isFiletype("PDF, DOCX")({
                label: "Dokumen Berita Acara Desk Analis",
              }),
            });
          }
          if (value.size > 10 * 1024 * 1024) {
            return context.createError({
              message: validationCustomErrorMsg.isFileSize("10MB")({
                label: "Dokumen Berita Acara Desk Analis",
              }),
            });
          }
          return true;
        }
        // otherwise, return false to render the field required
        return false;
      }
    )
    .label("Dokumen Berita Acara Desk Analis"),
  agency_identity: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi Ini"),
  is_two_years_experience: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi Ini"),
  is_have_portfolio: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi Ini"),
  is_npf_lower_five_percent: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi Ini"),
  is_audit: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi Ini"),
  is_debtor_access: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi Ini"),
  is_it_system: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi Ini"),
  is_collateral: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi Ini"),
  conclusion: yup.string().required().label("Kesimpulan"),
});

const Recommendation = ({ onBack, onSubmit, data }) => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { register, setValue } = methods;

  const [searchParams] = useSearchParams();

  const fileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      if (file?.controllerName === "file_temp") {
        register("file");
        setValue("file", response.data.data.id);
      }
    } catch (error) {
      throw error;
    }
  });

  const handleBack = () => {
    const values = methods.getValues();
    onBack(values);
  };

  const handleSubmit = (payload) => {
    onSubmit(payload);
  };

  const handleDraftButton = () => {
    console.log("draft");
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      const promises = [];
      const { file, ...rest } = data || { file: null };

      if (file && file !== "string") {
        promises.push(
          getFileFromServiceCB(file).then((data) => {
            return {
              formId: "file_temp",
              type: data?.mimeType,
              ...data,
            };
          })
        );
      } else {
        promises.push(Promise.resolve(null));
      }
      if (promises?.length > 0) {
        Promise.all(promises).then((values) => {
          const obj = { ...rest };
          values.map((x) => {
            if (!isEmpty(x)) {
              obj[x.formId] = x;
            }
          });
          methods.reset(obj);
        });
      }
    }
  }, [data]);

  return (
    <TabsContent value="recommendation" className="mb-40">
      <FormProvider {...methods}>
        <div className="space-y-5">
          <CardForm label="Penilaian Lembaga Penyalur">
            <div className="space-y-4">
              <InputForm
                label="No Berita Acara Desk Analis"
                required={true}
                controllerName={"desk_number"}
                className={"rounded-md w-full"}
              />
              <InputFormUpload
                uploadFile={fileMutation}
                fileType="PDF, Docx"
                controllerName="file_temp"
                maxSize={10 * 1024 * 1024}
                className="inset-0"
                label="Upload Berita Acara Desk Analis LP"
              />
            </div>
          </CardForm>
          <CardForm label="Permohonan Pembiayaan Lembaga Penyalur">
            <div className="flex gap-4">
              <InputFormRadio
                label={"1. Identitas Lembaga Penyalur"}
                controllerName={"agency_identity"}
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Data Terverifikasi
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Tidak Terverifikasi
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
            </div>
            <div className="flex gap-4">
              <InputFormRadio
                label={
                  "2. Pengalaman Dalam Pembiayaan Lingkungan Hidup / Kehutanan"
                }
                controllerName={"is_two_years_experience"}
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Berpengalaman Lebih dari 2 Tahun
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Berpengalaman Kurang dari 2 Tahun
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
              <InputFormRadio
                label="&nbsp;"
                controllerName={"is_have_portfolio"}
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Memiliki Portofolio
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Tidak Memiliki Portofolio
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
            </div>
            <div className="flex gap-4">
              <InputFormRadio
                label={"3. Sehat dan Berkinerja Baik"}
                is_audit
                controllerName={"is_audit"}
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Hasil Audit Wajar
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Hasil Audit Tidak Wajar
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
              <InputFormRadio
                label="&nbsp;"
                controllerName={"is_npf_lower_five_percent"}
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Tingkat NPF di bawah 5%
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Tingkat NPF di atas 5%
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
            </div>
            <div className="flex gap-4">
              <InputFormRadio
                label={"4. Akses Debitur"}
                controllerName={"is_debtor_access"}
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Mempunyai Akses Langsung
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Tidak Mempunyai Akses Langsung
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
            </div>
            <div className="flex gap-4">
              <InputFormRadio
                label={"5. Sistem IT"}
                controllerName={"is_it_system"}
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Dapat Diintegrasikan
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Tidak Dapat Diintegrasikan
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
            </div>
            <div className="flex gap-4">
              <InputFormRadio
                label={"6. Jaminan"}
                controllerName={"is_collateral"}
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Jaminan Lebih dari 100% dari Nilai FDB Disetujui
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Jaminan di bawah 100% dari Nilai FDB Disetujui
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
            </div>
            <InputForm
              controllerName={"conclusion"}
              textArea={true}
              label={"Berikan Kesimpulan"}
              className={"w-full rounded-md border p-3"}
            />
          </CardForm>
        </div>
        <BottomFormAction
          backButtonAction={handleBack}
          submitActionButton={() => methods.handleSubmit(handleSubmit)()}
          disableDrafButton={false}
          hideSubmit={searchParams.get("status")}
          hideDraft={true}
          submitButtonProps={{
            type: "submit",
            label: "Submit",
          }}
          drafButtonAction={handleDraftButton}
          disableButtonSubmit={false}
          className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
        />
      </FormProvider>
    </TabsContent>
  );
};

export default Recommendation;
