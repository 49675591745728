import React, { useState, useMemo } from "react";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { TabsRoot, TabsList, TabsTrigger } from "components/atoms/Tabs";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import { Spinner } from "components";
import ApplicationDetailContent from "./ApplicationDetailContent";
import PaperWorkContent from "./PaperWorkContent";
import { getApplicationDetail } from "services/fdb/field-analis";
import { useSearchParams } from "react-router-dom";

const ListMember = () => {
  const title = "Daftar Registrasi Perorangan dalam Kelompok";
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const [activeTab, setActiveTab] = useState(searchParams?.get("tab") || "paper-work");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    search: undefined,
    date: undefined,
    status: undefined,
  });

  const { data, isLoading } = useQuery({
    queryKey: ["field-analyst-application-detail", id, page, limit, filter],
    queryFn: async () => {
      const { data } = await getApplicationDetail(id, { page, limit, filter, paginate: true });
      return data;
    },
    onSuccess: (data) => {
      if (data?.applicant?.data?.length > 0) {
        setTotalItems(data?.applicant?.meta?.total);
        setTotalPage(data?.applicant?.meta?.last_page);
      }
    },
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    retry: false,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  const profileData = useMemo(() => {
    const { submission, applicant } = data || {
      submission: null,
      applicant: {},
    };
    const { total_application_value, group_profile, total_member, type } = submission || {
      total_application_value: null,
      group_profile: null,
      total_member: null,
    };
    return {
      applicant: applicant?.data,
      group_profile,
      total_application_value,
      total_member,
      groupType: type
    };
  }, [data]);
  const portfolioData = useMemo(() => {
    if (data?.submission?.group_profile) {
      return data?.submission?.group_profile;
    }
    return {};
  }, [data]);

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: "/field-analyst",
          },
          {
            label: "Daftar Anggota",
            path: `/field-analyst/list-member/${id}`,
          },
        ]}
      />

      {isLoading ? (
        <div className="flex justify-center p-4">
          <Spinner theme={"primary"} />
        </div>
      ) : (
        <TabsRoot value={activeTab} onValueChange={setActiveTab}>
          <TabsList>
            <TabsTrigger value="paper-work">
              Kertas Kerja FA Kelompok
            </TabsTrigger>
            <TabsTrigger value="application-detail">
              Detail Permohonan
            </TabsTrigger>
          </TabsList>
          <PaperWorkContent
            initialValues={portfolioData}
            type={id === 1 ? "persos" : "non-persos"}
            afterSubmit={() => setActiveTab("application-detail")}
          />
          <ApplicationDetailContent
            setFilter={setFilter}
            setPage={setPage}
            setLimit={setLimit}
            totalItems={totalItems}
            totalPage={totalPage}
            initialValues={profileData}
            type={id === 1 ? "persos" : "non-persos"}
            memberId={id}
            onBack={() => setActiveTab("paper-work")}
          />
        </TabsRoot>
      )}
    </div>
  );
};

export default ListMember;
