import React, { useCallback, useState } from "react";
import ReactSelect from "react-select";
import { useFormContext, Controller } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { getListCommitteeMinutes } from "services/institutions/offers";

const SelectDirectorMinutes = ({ placeholder = "BA Komite", ...props }) => {
  const { name, customOnChange } = props;
  const { control } = useFormContext();

  const [isOpenAgenda, setOpenAgenda] = useState(false);
  const [agendaOptions, setAgendaOptions] = useState([]);
  
  const { status } = useQuery({
    queryKey: ["agency-offer-committee-minutes"],
    queryFn: async () => {
      try {
        const { data } = await getListCommitteeMinutes();
        if (data?.data?.length > 0) {
          return data?.data;
        }
        return {};
      } catch (error) {
        console.log('error: ', error);
      }
    },
    enabled: isOpenAgenda,
    onSuccess: (datas) => {
      if (datas?.length > 0) {
        setAgendaOptions((curr) => {
          const newDatas = datas.map((x) => ({
            ...x,
            value: x.commite_minute_id,
            label: x.risalah_number,
          }));
          return [...curr, ...newDatas];
        });
      }
    },
  });

  const onMenuOpen = useCallback(() => setOpenAgenda(true), []);

  const onMenuClose = useCallback(() => {
    setOpenAgenda(false);
    setAgendaOptions([]);
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => (
        <div className="w-full">
          <ReactSelect
            {...props}
            options={agendaOptions}
            isLoading={status === "loading"}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            placeholder={placeholder}
            value={value}
            onChange={(val) => {
              onChange(val);
              if (customOnChange) customOnChange(val);
            }}
            onBlur={onBlur}
            styles={{
              control: (provided, state) => ({
                ...provided,
                borderColor: invalid
                  ? "#F04438"
                  : state.isFocused
                  ? "#01A24A"
                  : "#EAECF0",
                ":hover": {
                  ...provided[":hover"],
                  borderColor: invalid
                    ? "#F04438"
                    : state.isFocused
                    ? "#01A24A"
                    : "#EAECF0",
                },
                boxShadow: "unset",
                "&:hover": {
                  outline: "unset",
                },
                "&:focus": {
                  border: "1px solid #01A24A",
                },
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                padding: "0.25rem 1rem",
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? "#01A24A" : "white",
                color: state.isSelected ? "white" : "black",
                "&:hover": {
                  backgroundColor: state.isSelected ? "#01A24A" : "#F3F4F6",
                  color: state.isSelected ? "white" : "black",
                },
                zIndex: 9999,
              }),
              multiValue: (provided, state) => ({
                ...provided,
                backgroundColor: "#01A24A",
                color: "white",
              }),
              multiValueLabel: (provided, state) => ({
                ...provided,
                color: "white",
              }),
              multiValueRemove: (provided, state) => ({
                ...provided,
                color: "white",
                "&:hover": {
                  backgroundColor: "#01A24A",
                  color: "white",
                },
              }),
            }}
            menuPortalTarget={document.body}
          />
          {invalid && (
            <div className="text-xs text-error-600 mt-2">{error?.message}</div>
          )}
        </div>
      )}
    />
  );
};

export default React.memo(SelectDirectorMinutes);
