import { Button, Input } from "components";
import Badge from "components/atoms/Badge";
import { customStyles } from "components/atoms/Select";
import Table, { TableWrapper } from "components/atoms/Table";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import dayjs from "dayjs";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  RiCircleFill,
  RiCloseLine,
  RiEyeLine,
  RiFileExcel2Line,
  RiSearchLine,
} from "react-icons/ri";
import { useNavigate, useParams } from "react-router";
import { FaFile } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
import {
  getDisburstments,
  getDisburstmentsDirectorate,
} from "services/fdb/disbursement";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import { Pagination } from "components/v2/shared/pagination";
import clsx from "clsx";
import { getFile } from "services/fileService";
import { Modal, ModalBody, ModalHeader } from "components/atoms/Modal";

const MemberInGroupDisburstment = () => {
  const title = "Perorangan dalam Kelompok";
  const params = useParams();

  const [filters, setFilters] = useState({
    paginate: true,
    page: 1,
    limit: 10,
    filter: {
      search: "",
      status: "",
      date_start: "",
      date_end: "",
    },
  });

  // ! PELAKSANA
  const { data, isLoading } = useQuery(
    ["GET_DISBURSEMENT_LIST".filters],
    getDisburstments,
    {
      select: (data) => data?.data?.data,
      enabled: params?.disbursmentType === "review",
    }
  );

  // ! DIREKTORAT
  const { data: dataDirectorate, isLoading: isLoadingDirectorate } = useQuery(
    ["GET_DISBURSEMENT_DIRECTORATE_LIST".filters],
    getDisburstmentsDirectorate,
    {
      select: (data) => data?.data?.data,
      enabled: params?.disbursmentType === "agreement",
    }
  );

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: "Permohonan Pencairan",
            path: "/member-in-group-disburstment",
          },
          {
            label: title,
            path: "/member-in-group-disburstment",
          },
        ]}
      />

      <h1 className="text-gray-900 text-2xl font-semibold leading-loose">
        {title}
      </h1>

      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <ListFilter filters={filters} setFilters={setFilters} />
        <ListTable
          data={data}
          isLoading={isLoading}
          filters={filters}
          setFilters={setFilters}
          dataDirectorate={dataDirectorate}
          isLoadingDirectorate={isLoadingDirectorate}
        />
      </div>
    </div>
  );
};

const ListFilter = ({ filters, setFilters }) => {
  const stateOptions = useMemo(
    () => [
      { value: "Disetujui", label: "Disetujui" },
      { value: "Belum Review ", label: "Belum Review " },
    ],
    []
  );

  return (
    <form className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end">
      <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
        <RiSearchLine className="text-gray-400 text-xl" />

        <Input
          className="w-full bg-white border-none"
          onChange={debounce(
            (e) =>
              setFilters((prev) => ({
                ...prev,
                page: 1,
                filter: {
                  ...prev.filter,
                  search: e.target.value,
                },
              })),
            1000
          )}
          placeholder="Cari"
        />
      </div>
      <Select
        className="w-full rounded-md h-10 absolute"
        options={stateOptions}
        placeholder="Status"
        styles={customStyles}
        isClearable
        onChange={debounce(
          (e) =>
            setFilters((prev) => ({
              ...prev,
              page: 1,
              filter: {
                ...prev.filter,
                status: e.value,
              },
            })),
          1000
        )}
      />
      <div className="relative">
        <ReactDatePicker
          className={
            "date_picker w-full !outline-none !focus:border-primary-700 border h-10 px-3 focus:border-primary-700 rounded-md"
          }
          selected={filters.date}
          onChange={debounce(
            (date) =>
              setFilters((prev) => ({
                ...prev,
                page: 1,
                date: date,
              })),
            1000
          )}
          placeholderText={"dd/mm/yyyy"}
          showYearDropdown={true}
        />
        {filters.date !== "" && (
          <RiCloseLine
            className="absolute top-3 right-2 text-gray-400 hover:text-gray-600 cursor-pointer text-lg"
            onClick={() =>
              setFilters((prev) => ({ ...prev, page: 1, date: "" }))
            }
          />
        )}
      </div>
    </form>
  );
};

const stateColors = {
  // "Belum Review": "warning",
  // Baru: "bluelight",
  // Draft: "default",
  // Ditolak: "error",
  // Disetujui: "success",
  // Terverifikasi: "success",
  "Belum Direview": "bg-[#FFFAEB] text-[#B54708]",
  "Direview Direktur Penyaluran Dana": "bg-[#ECFDF3] text-[#027A48]",
  Revisi: "bg-[#ECFDF3] text-[#027A48]",
  "Ditolak Direktur Penyaluran Dana": "bg-[#FEF3F2] text-[#B42318]",
  Disetujui: "bg-[#ECFDF3] text-[#027A48]",
  Tersalurkan: "bg-[#ECFDF3] text-[#027A48]",
};

const ListTable = ({
  data,
  isLoading,
  filters,
  setFilters,
  dataDirectorate,
  isLoadingDirectorate,
}) => {
  const navigate = useNavigate();
  const { disbursmentType } = useParams();

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No Perjanjian",
        render: ({ index }) => {
          const no =
            parseInt(data?.meta?.current_page) *
              parseInt(data?.meta?.per_page) -
            parseInt(data?.meta?.per_page) +
            index +
            1;
          return no;
        },
      },
      {
        key: "group_name",
        title: "Nama Kelompok",
      },
      {
        key: "registered",
        title: "Tanggal Pengajuan",
        render: ({ item }) => {
          return item.registered
            ? dayjs(item.registered).format("DD MMMM YYYY")
            : "-";
        },
      },
      {
        key: "total_applicant",
        title: "Jumlah Anggota Diajukan",
        render: ({ item }) => {
          return item.total_applicant ? `${item.total_applicant} Orang` : "-";
        },
      },
      {
        key: "phase",
        title: "Tahap",
        render: ({ item }) => <Badge color="default">{item.phase}</Badge>,
      },
      {
        key: "status_string",
        title: "Status Rapat",
        render: ({ item }) => (
          <div
            className={clsx(
              "py-0.5 px-2 text-sm font-medium rounded-full flex gap-2 items-center",
              stateColors[item.status_string]
            )}
          >
            <RiCircleFill size={8} />
            {item.status_string}
          </div>
        ),
      },
      {
        key: "news",
        title: "File Maker",
        render: ({ item }) => {
          return (
            (item?.status_string === "Disetujui" ||
              item?.status_string === "Tersalurkan") && (
              <RiFileExcel2Line
                size="18"
                onClick={() => {
                  // const fileTemp = [
                  //   item?.selected_file_maker_mass?.url,
                  //   item?.selected_file_maker?.url,
                  // ];

                  // fileTemp.map((el) => {
                  //   return window.open(el);
                  // });
                  setFileModal((prev) => !prev);
                  setSelectedRow(item);
                }}
              />
            )
          );
        },
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <>
            {["Belum Review"].includes(item.state) &&
            disbursmentType === "review" ? (
              <Button
                theme="primary"
                label="Review"
                onClick={() => {
                  navigate(
                    `/member-in-group-disburstment/${disbursmentType}/${item.id}?status=${item?.status_string}`
                  );
                }}
              />
            ) : (
              <Button
                variant="icon"
                label={<RiEyeLine className="text-lg" />}
                onClick={() => {
                  navigate(
                    `/member-in-group-disburstment/${disbursmentType}/${item.id}?status=${item?.status_string}`
                  );
                }}
              />
            )}
          </>
        ),
      },
    ];
  }, [navigate, disbursmentType, data?.meta]);

  const headerDirectorat = useMemo(() => {
    return [
      {
        key: "index",
        title: "No Perjanjian",
        render: ({ index }) => {
          const no =
            parseInt(data?.meta?.current_page) *
              parseInt(data?.meta?.per_page) -
            parseInt(data?.meta?.per_page) +
            index +
            1;
          return no;
        },
      },
      {
        key: "group_name",
        title: "Nama Kelompok",
      },
      {
        key: "registered",
        title: "Tanggal Pengajuan",
        render: ({ item }) => {
          return item.registered
            ? dayjs(item.registered).format("DD MMMM YYYY")
            : "-";
        },
      },
      {
        key: "total_applicant",
        title: "Jumlah Anggota Diajukan",
        render: ({ item }) => {
          return item.total_applicant ? `${item.total_applicant} Orang` : "-";
        },
      },
      {
        key: "phase",
        title: "Tahap",
        render: ({ item }) => <Badge color="default">{item.phase}</Badge>,
      },
      {
        key: "status_string",
        title: "Status Rapat",
        render: ({ item }) => (
          <div
            className={clsx(
              "py-0.5 px-2 text-sm font-medium rounded-full flex gap-2 items-center",
              stateColors[item.status_string]
            )}
          >
            <RiCircleFill size={8} />
            {item.status_string}
          </div>
        ),
      },
      // {
      //   key: "news",
      //   title: "Berita Acara",
      //   render: () => <FaFile size="18" />,
      // },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <>
            {["Belum Review"].includes(item.state) &&
            disbursmentType === "review" ? (
              <Button
                theme="primary"
                label="Review"
                onClick={() => {
                  navigate(
                    `/member-in-group-disburstment/${disbursmentType}/${item.id}?status=${item?.status_string}`
                  );
                }}
              />
            ) : (
              <Button
                variant="icon"
                label={<RiEyeLine className="text-lg" />}
                onClick={() => {
                  navigate(
                    `/member-in-group-disburstment/${disbursmentType}/${item.id}?status=${item?.status_string}`
                  );
                }}
              />
            )}
          </>
        ),
      },
    ];
  }, [navigate, disbursmentType, data?.meta]);

  const mountingFile = useCallback(async () => {
    if (disbursmentType !== "review" || typeof data?.data === "undefined") {
      return;
    }

    for (let row of data?.data) {
      if (
        (typeof row?.file_maker === "undefined" &&
          typeof row?.file_maker_mass === "undefined") ||
        (row?.file_maker === null && row?.file_maker_mass === null)
      )
        continue;

      const responseFileMaker = await getFile(row?.file_maker);
      const responseFileMakerMass = await getFile(row?.file_maker_mass);

      row.selected_file_maker = responseFileMaker?.data?.data;
      row.selected_file_maker_mass = responseFileMakerMass?.data?.data;
    }
  }, [disbursmentType, data]);

  useEffect(() => {
    mountingFile();
  }, [mountingFile]);

  const [fileModal, setFileModal] = useState();
  const [selectedRow, setSelectedRow] = useState();

  return (
    <>
      <Modal
        manual
        isOpen={fileModal}
        onOpenChange={() => {
          setFileModal((prev) => !prev);
        }}
        contentClassName="!w-[1184px] !max-w-[1184px] !z-[51]"
      >
        <>
          <ModalHeader hideClose>Daftar Maker</ModalHeader>
          <ModalBody className="space-y-5">
            {selectedRow?.selected_file_maker &&
            selectedRow?.selected_file_maker_mass ? (
              <div className="flex flex-col gap-2">
                <button
                  className="flex gap-2 p-3 rounded-md items-center hover:bg-primary-50 hover:border-primary-600 border-2"
                  onClick={() =>
                    window.open(selectedRow?.selected_file_maker?.url, "_blank")
                  }
                >
                  <RiFileExcel2Line size={32} />
                  <p className="text-base font-medium">Converter</p>
                </button>
                <button
                  className="flex gap-2 p-3 rounded-md items-center hover:bg-primary-50 hover:border-primary-600 border-2"
                  onClick={() =>
                    window.open(
                      selectedRow?.selected_file_maker_mass?.url,
                      "_blank"
                    )
                  }
                >
                  <RiFileExcel2Line size={32} />
                  <p className="text-base font-medium">Converter Mass</p>
                </button>
              </div>
            ) : (
              <>File Maker Tidak Ditemukan</>
            )}
          </ModalBody>
        </>
      </Modal>
      <TableWrapper className="mt-4">
        <Table
          headers={disbursmentType === "review" ? headers : headerDirectorat}
          items={
            disbursmentType === "review"
              ? data?.data ?? []
              : dataDirectorate?.data ?? []
          }
          isLoading={
            disbursmentType === "review" ? isLoading : isLoadingDirectorate
          }
        />
        <Pagination
          page={data?.meta?.current_page ?? 1}
          limit={data?.meta?.per_page ?? 10}
          total={data?.meta?.total ?? 1}
          totalPage={data?.meta?.last_page ?? 1}
          onLimitChange={(e) =>
            setFilters((prev) => ({
              ...prev,
              page: 1,
              limit: e,
            }))
          }
          onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
        />
      </TableWrapper>
    </>
  );
};

export default MemberInGroupDisburstment;
