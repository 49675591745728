import { InputFormRadio } from "components";
import CardForm from "components/molecules/CardForm";

const VerificationResult = ({ isView }) => (
  <CardForm label="Hasil Verifikasi">
    <div className="flex-col flex gap-4 ">
      <InputFormRadio
        label={
          "Apakah nama penerima FDB telah menandatangani perjanjian FDB dan pengikatan jaminan yang paling kurang dibuktikan dengan cover note dari Notaris?"
        }
        labelClass={"w-full"}
        controllerName={`verification_aggreement`}
        usingGrid={true}
        gridClassName="grid grid-cols-2 gap-4 w-full"
        className="flex items-center justify-between gap-4 flex-wrap"
        disabled={isView}
        values={[
          {
            label: "Terverifikasi",
            value: true,
          },
          {
            label: "Tidak Terverifikasi",
            value: false,
          },
        ]}
      />
      <InputFormRadio
        label={
          "Apakah jumlah yang dimohonkan sesuai Dengan jumlah Yang tertera pada tahapan berdasarkan keputusan persetujuan?"
        }
        labelClass={"w-full"}
        controllerName={`verification_application`}
        usingGrid={true}
        gridClassName="grid grid-cols-2 gap-4 w-full"
        className="flex items-center justify-between gap-4 flex-wrap"
        disabled={isView}
        values={[
          {
            label: "Terverifikasi",
            value: true,
          },
          {
            label: "Tidak Terverifikasi",
            value: false,
          },
        ]}
      />
      <InputFormRadio
        label={
          "Apakah saldo penyaluran (sd tahap yang dimohonkan) tidak melebihi saldo komitmen pembiayaan (sd tahapan tersebut)?"
        }
        labelClass={"w-full"}
        controllerName={`verification_saldo`}
        usingGrid={true}
        gridClassName="grid grid-cols-2 gap-4 w-full"
        className="flex items-center justify-between gap-4 flex-wrap"
        disabled={isView}
        values={[
          {
            label: "Terverifikasi",
            value: true,
          },
          {
            label: "Tidak Terverifikasi",
            value: false,
          },
        ]}
      />
      <InputFormRadio
        label={
          "Apakah data pada konsep Surat Perintah Pemindahbukuan FDB telah sesuai dengan perjanjian dan data ATAPP?"
        }
        labelClass={"w-full"}
        controllerName={`verification_atapp`}
        usingGrid={true}
        gridClassName="grid grid-cols-2 gap-4 w-full"
        className="flex items-center justify-between gap-4 flex-wrap"
        disabled={isView}
        values={[
          {
            label: "Terverifikasi",
            value: true,
          },
          {
            label: "Tidak Terverifikasi",
            value: false,
          },
        ]}
      />
    </div>
  </CardForm>
);

export default VerificationResult;
