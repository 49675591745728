import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getListAgencyCommitteeMinutes = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-committee-minutes`,
    { params: queryKey[1] }
  );
};

export const postDetailAgencyCommitteeMinutesTech = ({ data, params }) => {
  return axiosInstance.patch(
    `${general_config_v2.BASE_URL}/v1/cms/agency-committee-minutes/tech`,
    data,
    { params }
  );
};

export const getDetailAgencyCommitteeMinutesTech = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-committee-minutes/tech/${queryKey[1]}`
  );
};

export const postDetailAgencyCommitteeMinutesDirection = ({
  id,
  data,
  params,
}) => {
  return axiosInstance.patch(
    `${general_config_v2.BASE_URL}/v1/cms/agency-committee-minutes/direction/${id}`,
    data,
    { params }
  );
};

export const getDetailAgencyCommitteeMinutesDirection = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-committee-minutes/direction/${queryKey[1]}`
  );
};
