import React, { useMemo, useState, useCallback, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CardForm from "components/molecules/CardForm";
import { useNavigate } from "react-router";
import { TabsContent } from "components/atoms/Tabs";
import { BottomFormAction, Button } from "components";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { debounce } from "lodash";
import { AnimatePresence, motion } from "framer-motion";
import AccordionButton from "components/atoms/AccordionButton";
import { RiEyeLine } from "react-icons/ri";
import Table, { TableWrapper } from "components/atoms/Table";
import { enqueueSnackbar } from "notistack";
import { useMutation } from "@tanstack/react-query";
import { verificationSelfAssesment } from "services/fdb/field-analis";

const SelfAssesmentContent = ({
  initialValues,
  type,
  onBack,
  onNext,
  hideDraft,
}) => {
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  // For Group Table
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    search: undefined,
    date: undefined,
    status: undefined,
  });
  // Dummy Data
  const data = useMemo(() => {
    return [{}];
  }, []);
  // End For Group Table
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      search: yup.string(),
      state: yup.object(),
      date: yup.string(),
    });
  }, []);
  // For Group Filter

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      search: "",
      state: "",
      date: "",
    },
  });
  const handleSubmitFilter = debounce(() => {
    console.log(methods.getValues());
    setFilter({
      search:
        methods.getValues("search") !== ""
          ? methods.getValues("search")
          : undefined,
      date:
        methods.getValues("date") !== ""
          ? methods.getValues("date")
          : undefined,
      status:
        methods.getValues("state") !== ""
          ? methods.getValues("state").value
          : undefined,
    });
  }, 1000);
  // End For Group Filter

  useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(handleSubmitFilter)
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  const onFormSubmit = useCallback(() => {
    //
  }, []);

  const onDraft = useCallback(() => {
    //
  }, []);
  const [accordionState, setAccordionState] = useState({
    business_condition: true,
    other_business_condition: true,
  });

  const toggleAccordion = (key) => () => {
    setAccordionState({
      ...accordionState,
      [key]: !accordionState?.[key],
    });
  };

  const verificationSelfAssesmentMutation = useMutation({
    mutationFn: verificationSelfAssesment,
  });

  const handleSubmit = () => {
    if (initialValues !== null) {
      const validate1 = initialValues.questions1.every(
        (e) => e.verification !== null
      );
      const validate2 = initialValues.questions2.every(
        (e) => e.verification !== null
      );

      if (!validate1 || !validate2) {
        enqueueSnackbar({
          variant: "error",
          message: "Semua data wajib di verikasi.",
        });
        window.scroll({ top: 0, behavior: "smooth" });
        return;
      }

      const payload = {};

      for (let question1 of initialValues.questions1) {
        payload[`${question1.verification_key}`] = question1.verification;
      }

      for (let question2 of initialValues.questions2) {
        payload[`${question2.verification_key}`] = question2.verification;
      }

      verificationSelfAssesmentMutation.mutate({
        id: initialValues.id,
        data: payload,
      });
    }
    onNext();
  };

  return (
    <FormProvider key="self-assesment-content" {...methods}>
      <TabsContent value="self-assesment" className="mb-40">
        <CardForm label="Self Assesment">
          <AnimatePresence>
            <AccordionButton
              key={"accordion-address-domicile-btn"}
              label={"Kondisi Usaha Dibiayai"}
              isOpen={accordionState?.business_condition}
              onClick={toggleAccordion("business_condition")}
            />

            {accordionState?.business_condition && (
              <motion.div
                key={"accordion-address-domicile-content"}
                className="lg:grid lg:grid-cols-1 gap-4 space-y-4 "
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                <BusinessCondition
                  data={initialValues?.questions1 ?? []}
                  isLoading={false}
                  totalItems={totalItems}
                  totalPage={totalPage}
                  setLimit={setLimit}
                  setPage={setPage}
                  // memberId={memberId}
                />
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            <AccordionButton
              key={"accordion-other-business-btn"}
              label={"Usaha Lain Selain Yang Dibiayai Serta Pengeluaran"}
              isOpen={accordionState?.other_business_condition}
              onClick={toggleAccordion("other_business_condition")}
            />

            {accordionState?.other_business_condition && (
              <motion.div
                key={"accordion-other-business-content"}
                className="lg:grid lg:grid-cols-1 gap-4 space-y-4 "
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                <OtherBusinessTable
                  data={initialValues?.questions2 ?? []}
                  isLoading={false}
                  totalItems={totalItems}
                  totalPage={totalPage}
                  setLimit={setLimit}
                  setPage={setPage}
                  // memberId={memberId}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </CardForm>

        <BottomFormAction
          submitButtonProps={{
            type: "button",
          }}
          draftButtonProps={{
            type: "button",
          }}
          backButtonAction={() => onBack(methods.getValues())}
          submitActionButton={handleSubmit}
          disableDrafButton={false}
          drafButtonAction={onDraft}
          disableButtonSubmit={false}
          hideDraft={hideDraft}
          className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
        />
      </TabsContent>
    </FormProvider>
  );
};
const stateColors = {
  "Menunggu Verifikasi": "warning",
  "On Progress": "bluelight",
  Draft: "default",
  Ditolak: "error",
  Disetujui: "success",
  Terverifikasi: "success",
};

const BusinessCondition = ({
  data,
  isLoading,
  totalPage,
  totalItems,
  setPage,
  setLimit,
  memberId,
}) => {
  const navigate = useNavigate();

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "judulPertanyaan",
        title: "Pertanyaan",
      },
      {
        key: "key",
        title: "Jawaban",
      },
      // {
      //   key: "state",
      //   title: "Bobot Nilai",
      // },
      {
        key: "radioValue",
        title: "Sesuai",
        render: ({ item }) => (
          <div className="radio-buttons">
            <input
              type="radio"
              id={`radioOption1-${item.id}`}
              name={`radioOptions-${item.id}`}
              defaultChecked={item.verification === true}
              // value="option1"
              onChange={(e) => {
                if (e.target.checked) {
                  item.verification = true;
                }
              }}
            />
          </div>
        ),
      },
      {
        key: "radioValue",
        title: "Tidak Sesuai",
        render: ({ item, data }) => (
          <div className="radio-buttons">
            <input
              type="radio"
              id={`radioOption2-${item.id}`}
              name={`radioOptions-${item.id}`}
              // value="option2"
              defaultChecked={item.verification === false}
              onChange={(e) => {
                if (e.target.checked) {
                  item.verification = false;
                }
              }}
            />
          </div>
        ),
      },
    ];
  }, []);

  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={data ?? []} isLoading={isLoading} />
    </TableWrapper>
  );
};
const OtherBusinessTable = ({
  data,
  isLoading,
  totalPage,
  totalItems,
  setPage,
  setLimit,
  memberId,
}) => {
  const navigate = useNavigate();

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "judulPertanyaan",
        title: "Pertanyaan",
      },
      {
        key: "key",
        title: "Jawaban",
      },
      // {
      //   key: "state",
      //   title: "Bobot Nilai",
      // },
      {
        key: "radioValue",
        title: "Sesuai",
        render: ({ item }) => (
          <div className="radio-buttons">
            <input
              type="radio"
              id={`radioOption1-${item.id}`}
              name={`radioOptions-${item.id}`}
              // value="option1"
              defaultChecked={item.verification === true}
              onChange={(e) => {
                if (e.target.checked) {
                  item.verification = true;
                }
              }}
            />
          </div>
        ),
      },
      {
        key: "radioValue",
        title: "Tidak Sesuai",
        render: ({ item }) => (
          <div className="radio-buttons">
            <input
              type="radio"
              id={`radioOption2-${item.id}`}
              name={`radioOptions-${item.id}`}
              // value="option2"
              defaultChecked={item.verification === false}
              onChange={(e) => {
                if (e.target.checked) {
                  item.verification = false;
                }
              }}
            />
          </div>
        ),
      },
    ];
  }, [navigate]);

  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={data ?? []} isLoading={isLoading} />
    </TableWrapper>
  );
};
export default SelfAssesmentContent;
