import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";
import dayjs from "dayjs";

export const getAssignFieldAnalyst = (params) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/assign-field-analyst`,
    {
      params: {
        ...params,
        paginate: true,
      },
    }
  );
};

export const getAssignFieldAnalystDetail = (id) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/assign-field-analyst/${id}`
  );
};

export const putAssignFieldAnalystDetail = (id, payload) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/assign-field-analyst/${id}`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getListFieldAnalis = (parameters) => {
  const date =
    parameters.date !== "" ? dayjs(parameters.date).format("YYYY-MM-DD") : "";

  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/field-analyst`,
    {
      params: {
        ...parameters,
        date: date,
      },
    }
  );
};

export const getApplicationDetail = async (id, params) => {
  const { data } = await axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/field-analyst/${id}`,
    {
      params,
    }
  );
  return data;
};

export const getApplicantTallySheet = async (id, params) => {
  const { data } = await axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/field-analyst/applicant/tally-sheet/${id}`,
    {
      params,
    }
  );
  return data;
};

export const getApplicantCollateral = async (id, params) => {
  const { data } = await axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/field-analyst/applicant/collaterals/${id}`,
    {
      params,
    }
  );
  return data;
};

export const getApplicantIncomeInfo = async (id, params) => {
  const { data } = await axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/field-analyst/applicant/income-info/${id}`,
    {
      params,
    }
  );
  return data;
};

export const getDebtorDetail = async (id) => {
  const { data } = await axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/field-analyst/member/${id}`
  );
  return data;
};

export const postWorkingPaper = async (id, payload) => {
  const { is_draft, ...rest } = payload;
  const { data } = await axiosInstance.patch(
    `${general_config_v2.BASE_URL}/v1/cms/field-analyst/submit-fa-working-paper/${id}?is_draft=${is_draft}`,
    rest
  );
  return data;
};

export const postVerificationIncomeInfo = async (id, type, payload) => {
  const { ...rest } = payload;
  const { data } = await axiosInstance.patch(
    `${general_config_v2.BASE_URL}/v1/cms/field-analyst/verification-income-info/${id}`,
    rest,
    {
      params: {
        type,
      },
    }
  );
  return data;
};

export const postVerificationTallySheet = async (id, request_type, payload) => {
  const { ...rest } = payload;
  const { data } = await axiosInstance.patch(
    `${general_config_v2.BASE_URL}/v1/cms/field-analyst/verification-tally-sheet/${id}`,
    rest,
    {
      params: {
        request_type,
      },
    }
  );
  return data;
};

export const postVerificationCollateral = async (id, request_type, payload) => {
  const { ...rest } = payload;
  const { data } = await axiosInstance.patch(
    `${general_config_v2.BASE_URL}/v1/cms/field-analyst/verification-collateral/${id}`,
    rest,
    {
      params: {
        request_type,
      },
    }
  );
  return data;
};

export const verifiedFieldAnalyst = async (id, payload) => {
  const { is_draft, ...rest } = payload;
  const { data } = await axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/field-analyst/member/verification/${id}`,
    rest,
    {
      params: {
        is_draft,
      },
    }
  );
  return data;
};

export const verifiedFieldAnalystForestry = async (id, payload) => {
  const { is_draft, ...rest } = payload;
  const { data } = await axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/field-analyst/member/verification-forestry/${id}`,
    rest,
    {
      params: {
        is_draft,
      },
    }
  );
  return data;
};

export const verificationSelfAssesment = async ({ id, data }) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/field-analyst/member/verification-self-assesment/${id}`,
    data
  );
};
