import React, { useMemo, useCallback, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CardForm from "components/molecules/CardForm";
import { useNavigate, useParams } from "react-router";
import { TabsContent } from "components/atoms/Tabs";
import { InputForm, InputFormRadio, BottomFormAction } from "components";
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import RichEditorForm from "components/molecules/RichEditorForm";
import SelectFrekuensi from "components/organisms/inputs/SelectFrekuensi";
import AtomDatePicker from "components/atoms/Datepicker";
import { useMutation } from "@tanstack/react-query";
import { postWorkingPaper } from "services/fdb/field-analis";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";

export const PaperWorkContent = ({ initialValues, type, afterSubmit }) => {
  const navigate = useNavigate();
  const { id: fieldAnalystId } = useParams();

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      stakeholders: yup
        .string()
        .required("Stakeholder terkait yang menyetujui wajib diisi"),
      other_group_track_records: yup
        .string()
        .required("Verifikasi track recod lain dari kelompok wajib diisi"),
      other_group_notes: yup
        .string()
        .required("Catatan hasil pengamatan kelompok lainnya wajib diisi"),
      is_monitoring_activities: yup
        .bool()
        .transform((originalValue) =>
          originalValue === "" ? undefined : originalValue
        )
        .required("Kegiatan pendampingan yang telah dilakukan wajib diisi"),
      monitoring_institution: yup
        .string()
        .notRequired()
        .when("is_monitoring_activities", ([val], schema) => {
          if (val === true) {
            return schema.required("Instansi Pendamping wajib diisi");
          }
          return schema;
        }),
      evidence_mentor: yup
        .string()
        .notRequired()
        .when("is_monitoring_activities", ([val], schema) => {
          if (val === true) {
            return schema.required(
              "Bukti Dukungan Dari Pendamping wajib diisi"
            );
          }

          return schema;
        }),
      frequency: yup.string().required("Frekuensi wajib diisi"),
      monitoring_activities_carrie_out: yup
        .string()
        .required("Kegiatan Pendampingan yang telah Dilaksanakan wajib diisi"),
      other_notes: yup.string().required("Catatan Lainnya wajib diisi"),
      group_member_distribution: yup
        .string()
        .required("Persebaran Lahan Dalam Kelompok wajib diisi"),
      group_member_distribution_desk: yup
        .string()
        .required("Deskripsi persebaran lahan anggota kelompok wajib diisi"),
      speakers: yup.string().required("Nama Narasumber wajib diisi"),
      conclusion: yup.string().required("Kesimpulan wajib diisi"),
      created_at_place: yup.string().required("Dibuat pada wajib diisi"),
    });
  }, []);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      created_at: new Date(),
    },
  });

  useEffect(() => {
    const tempValues = initialValues?.field_analysts_fa_working_papers || {};
    methods.reset({
      ...tempValues,
      created_at: tempValues?.created_at
        ? new Date(tempValues?.created_at)
        : new Date(),
    });
    return;
  }, [methods, initialValues]);

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const submitWorkingPaper = useMutation((payload) =>
    postWorkingPaper(fieldAnalystId, payload)
  );

  const onFormSubmit = useCallback(
    (payload) => {
      const tempPayload = {
        ...payload,
        is_draft: false,
      };
      delete tempPayload.created_at;
      submitWorkingPaper.mutate(tempPayload, {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ fontWeight: "bold" }}>
                  Data berhasil disimpan!
                </span>
                <span>Data yang Anda masukkan berhasil dikirim</span>
              </div>
            ),
          });
          if (afterSubmit) afterSubmit();
        },
        onError: (error) => {
          enqueueSnackbar({
            message: getErrorMessage(error),
            variant: "error",
          });
        },
      });
    },
    [afterSubmit]
  );

  const onDraft = useCallback(() => {
    const values = methods.getValues();
    const tempPayload = {
      ...values,
      is_draft: true,
    };
    delete tempPayload.created_at;
    submitWorkingPaper.mutate(tempPayload, {
      onSuccess: (res) => {
        enqueueSnackbar({
          variant: "success",
          message: (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontWeight: "bold" }}>
                Data berhasil disimpan!
              </span>
              <span>Data yang Anda masukkan berhasil dikirim</span>
            </div>
          ),
        });
        if (afterSubmit) afterSubmit();
      },
      onError: (error) => {
        enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        });
      },
    });
  }, [afterSubmit]);

  return (
    <FormProvider {...methods}>
      <TabsContent value="paper-work" className="mb-40">
        <InformationCard type={type} data={initialValues} />
        <MentoringActivity />
        <DistributionOfLand />
        <SourcePerson />
        <Conclusions />

        <BottomFormAction
          backButtonAction={onBack}
          submitActionButton={() => methods.handleSubmit(onFormSubmit)()}
          submitButtonProps={{
            label: "Submit",
          }}
          disableDrafButton={false}
          drafButtonAction={onDraft}
          disableButtonSubmit={false}
          className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
        />
      </TabsContent>
    </FormProvider>
  );
};

const InformationCard = ({ type, data }) => {
  return (
    <CardForm label="Kelompok Terverifikasi Keberadaannya">
      <div className="flex flex-col gap-2">
        <InputForm
          controllerName="stakeholders"
          label="Stakeholder terkait yang menyetujui"
          placeholder="Placeholder"
          className="rounded-md"
        />
        <h1 className="text-md font-semibold mt-2">
          Kegiatan yang telah dilakukan kelompok:
        </h1>
        {data?.activities?.length > 0 ? (
          <div className="px-5 ">
            <ul className="list-disc py-2 space-y-2 ">
              {data?.activities?.map((act, idx) => (
                <li key={act.id}>{act.description}</li>
              ))}
            </ul>
          </div>
        ) : null}
        <div className="space-y-4">
          <RichEditorForm
            key={`other_group_track_records`}
            name={`other_group_track_records`}
            label={"Verifikasi track recod lain dari kelompok"}
            placeholder={"Verifikasi track recod lain dari kelompok"}
            required
            theme={"snow"}
          />
          <RichEditorForm
            key={`other_group_notes`}
            name={`other_group_notes`}
            label={"Catatan hasil pengamatan kelompok lainnya"}
            placeholder={"Catatan hasil pengamatan kelompok lainnya"}
            required
            theme={"snow"}
          />
        </div>
      </div>
    </CardForm>
  );
};

const MentoringActivity = () => {
  const methods = useFormContext();

  const isMonitoringField = useWatch({
    control: methods.control,
    name: "is_monitoring_activities",
  });

  return (
    <CardForm label="Kegiatan Pendampingan">
      <div className="flex gap-4">
        <div className="flex-1 pt-2">
          <InputFormRadio
            label={"Apakah ada kegiatan pendampingan yang telah dilakukan?"}
            controllerName={"is_monitoring_activities"}
            values={[
              {
                label: "Ya",
                value: true,
              },
              {
                label: "Tidak",
                value: false,
              },
            ]}
          />
        </div>
      </div>

      {typeof isMonitoringField !== "undefined" &&
        isMonitoringField === true && (
          <div className="grid grid-cols-2 gap-5">
            <InputForm
              controllerName="monitoring_institution"
              label={`Instansi Pendamping`}
              placeholder={`Instansi Pendamping`}
              required={true}
              className="rounded-md w-full px-4 py-3 border"
            />
            <InputForm
              controllerName="evidence_mentor"
              label={`Bukti Dukungan Dari Pendamping`}
              placeholder={`Bukti Dukungan Dari Pendamping`}
              required={true}
              className="rounded-md w-full px-4 py-3 border"
            />
          </div>
        )}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <InputForm
          controllerName="frequency"
          label={`Frekuensi`}
          placeholder={`Frekuensi`}
          required={true}
          className="rounded-md w-full px-4 py-3 border"
        />
        {/* <SelectFrekuensi
          controllerName={"frequency"}
          label={"Frekuensi"}
          placeholder={"Frekuensi"}
          required={true}
          returnObject={false}
        /> */}
        <InputForm
          controllerName="monitoring_activities_carrie_out"
          label="Kegiatan Pendampingan yang telah Dilaksanakan"
          placeholder="Kegiatan Pendampingan yang telah Dilaksanakan"
          required={true}
          className="rounded-md w-full px-4 py-3 border"
          textArea
        />
      </div>
      <RichEditorForm
        key={`other_notes`}
        name={`other_notes`}
        label={"Catatan Lainnya"}
        placeholder={"Catatan Lainnya"}
        required
        theme={"snow"}
      />
    </CardForm>
  );
};
const DistributionOfLand = () => {
  return (
    <CardForm label="Persebaran Lahan Dalam Kelompok">
      <div className="flex gap-4">
        <div className="flex-1 pt-2">
          <InputFormRadio
            label=""
            controllerName={"group_member_distribution"}
            values={[
              {
                label:
                  "Lahan/usaha kelompok kompak untuk kontrol dan pengelolaan bersama",
                value: "compact",
              },
              {
                label:
                  "Lahan/usaha kelompok terpisah, sulit dikontrol dan dikelola bersama",
                value: "seperate",
              },
            ]}
          />
        </div>
      </div>
      <RichEditorForm
        key={`group_member_distribution_desk`}
        name={`group_member_distribution_desk`}
        label={"Deskripsi persebaran lahan anggota kelompok "}
        placeholder={"Deskripsi persebaran lahan anggota kelompok "}
        required
        theme={"snow"}
      />
    </CardForm>
  );
};

const SourcePerson = () => {
  return (
    <CardForm label="Narasumber">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <InputForm
          controllerName="speakers"
          label={`Nama Narasumber`}
          placeholder={`Nama Narasumber`}
          wrapperClass={"col-span-2"}
          required={true}
          className="rounded-md w-full px-4 py-3 border"
        />
        <AtomDatePicker
          datepickerOptions={{
            maxDate: new Date(),
          }}
          controllerName="created_at"
          label="Tanggal Dibuat"
          placeholder="Tanggal Dibuat"
          required={true}
          format={"dd/MM/yyyy"}
          disabled
        />
        <InputForm
          controllerName="created_at_place"
          label={`Dibuat Pada`}
          placeholder={`Dibuat Pada`}
          required={true}
          className="rounded-md w-full px-4 py-3 border"
        />
      </div>
    </CardForm>
  );
};

const Conclusions = () => {
  return (
    <CardForm label="Kesimpulan">
      <RichEditorForm
        key={`conclusion`}
        name={`conclusion`}
        label={"Kesimpulan"}
        placeholder={"Kesimpulan"}
        required
        theme={"snow"}
      />
    </CardForm>
  );
};

export default PaperWorkContent;
