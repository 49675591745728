/* eslint-disable no-template-curly-in-string */
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { BottomFormAction, InputForm, InputFormUpload } from "components";
import AtomDatePicker from "components/atoms/Datepicker";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import Button from "components/atoms/Button";
import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";
import { find } from "lodash";
import { useCallback, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RiDownload2Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import { fileUpload } from "services/fileService";
import * as yup from "yup";
import withFileService from "hocs/with-file-service";
import SelectCommitteeMinutes from "./SelectCommitteeMinutes";
import { createOffer } from "services/fdb/offers";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import dayjs from "dayjs";

const OfferNegotiationAdd = withFileService((props) => {
  const { getFileFromService } = props;

  const navigate = useNavigate();
  const title = "Daftar Penawaran - Perorangan dalam Kelompok";
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      letter_number: yup.string().required().label("Nomor Surat"),
      trait: yup.string().required().label("Sifat"),
      offer_financing_terms: yup.string().required().label("Hal"),
      group_profile_id_temp: yup.string().required().label("Kelompok"),
      committee_minutes_id: yup
        .object()
        .shape({
          value: yup.string().required(),
          label: yup.string().required(),
        })
        .test("required", "BA Komite wajib diisi", (value, ctx) => {
          if (!value) return false;
          else if (value !== null && value.value && value.label)
            return !!value.value && !!value.label;
        })
        .nullable(),
      date_start: yup.string().required().label("Tanggal Pelaksana Penawaran"),
      date_end: yup.string().required().label("Tanggal Penawaran Berakhir"),
      fileTemp: yup
        .mixed()
        .test(
          "cid_img",
          ({ label }) =>
            validationErrorMsg.mixed.required.replace("${path}", label),
          (value, context) => {
            const allowedTypes = [
              "application/pdf",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ];
            if (value) {
              const data = find(allowedTypes, (at) => value.type.includes(at));
              if (!data) {
                return context.createError({
                  message: validationCustomErrorMsg.isFiletype("PDF, DOCX")({
                    label: "Dokumen Surat Penawaran",
                  }),
                });
              }
              if (value.size > 10 * 1024 * 1024) {
                return context.createError({
                  message: validationCustomErrorMsg.isFileSize("10MB")({
                    label: "Dokumen Surat Penawaran",
                  }),
                });
              }
              return true;
            }
            // otherwise, return false to render the field required
            return false;
          }
        )
        .label("Dokumen Surat Penawaran"),
    });
  }, []);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      committee_minutes_id: null,
    },
  });

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const mutation = useMutation({
    mutationFn: (params) => {
      return createOffer(params);
    },
  });

  const onFormSubmit = useCallback(
    (payload) => {
      const dataToSend = {
        ...payload,
        committee_minutes_id: payload?.committee_minutes_id?.value,
        date_start: dayjs(payload.date_start).format("YYYY-MM-DD"),
        date_end: dayjs(payload.date_end).format("YYYY-MM-DD"),
        letter_template: "template",
      };
      delete dataToSend.fileTemp;
      delete dataToSend.group_profile_id_temp;

      mutation.mutate(dataToSend, {
        onSuccess: () => {
          enqueueSnackbar({
            variant: "success",
            message: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ fontWeight: "bold" }}>
                  Data berhasil disimpan!
                </span>
                <span>Data yang Anda masukkan berhasil dikirim</span>
              </div>
            ),
          });
          navigate("/offer-negotiation");
        },
        onError: (error) => {
          enqueueSnackbar({
            message: getErrorMessage(error),
            variant: "error",
          });
        },
      });
    },
    [mutation, navigate]
  );

  const revisionFileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      methods.register("file");
      methods.setValue("file", response.data.data.id);
    } catch (error) {
      throw error;
    }
  });

  const onDownloadTemplate = () => {
    getFileFromService(process.env.REACT_APP_OFFER_TEMPLATE_ID, {
      onSuccess: (data) => {
        const downloadLink = window.document.createElement("a");
        downloadLink.href = data.url;
        downloadLink.download = "Template_Surat_Penawaran.docx";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: "/offer-negotiation",
          },
          {
            label: "Tambah Penawaran",
            path: "/offer-negotiation/add",
          },
        ]}
      />
      <FormProvider {...methods}>
        <CardForm label="Penawaran">
          <div className="flex justify-between gap-5">
            <InputForm
              controllerName={"letter_number"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Nomor Surat"}
              placeholder={"Nomor Surat"}
              required
            />

            <InputForm
              controllerName={"trait"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Sifat"}
              placeholder={"Sifat"}
              required
            />
          </div>
          <div className="flex justify-between gap-5">
            <InputForm
              controllerName={"offer_financing_terms"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Hal"}
              placeholder={"Hal"}
              required
            />
          </div>
          <div className="flex justify-between gap-5">
            <div className="form-control w-full flex flex-col justify-end">
              <label className="label flex gap-1 font-semibold text-[14px] mb-2">
                <span className={`label-text`}>Berdasarkan BA Komite</span>
                <span className="text-[#F04438]">*</span>
              </label>
              <SelectCommitteeMinutes
                name="committee_minutes_id"
                customOnChange={(values) => {
                  methods.setValue("group_profile_id", values.group_profile_id);
                  methods.setValue(
                    "group_profile_id_temp",
                    values?.group_profile?.name
                  );
                }}
              />
            </div>
            <InputForm
              controllerName={"group_profile_id"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Ditujukan Untuk Kelompok"}
              placeholder={"Ditujukan Untuk Kelompok"}
              disabled
              hidden
            />
            <InputForm
              controllerName={"group_profile_id_temp"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Ditujukan Untuk Kelompok"}
              placeholder={"Ditujukan Untuk Kelompok"}
              required
              disabled
            />
          </div>
          <div className="flex justify-between gap-5">
            <AtomDatePicker
              controllerName={"date_start"}
              label="Tanggal Pelaksanaan Penawaran"
              placeholder="Tanggal Pelaksanaan Penawaran"
              datepickerOptions={{
                dateFormat: "dd/MM/yyyy",
              }}
              showErrorLabel
              required
            />
            <AtomDatePicker
              controllerName={"date_end"}
              label="Tanggal Penawaran Berakhir"
              placeholder="Tanggal Penawaran Berakhir"
              datepickerOptions={{
                dateFormat: "dd/MM/yyyy",
              }}
              showErrorLabel
              required
            />
          </div>
        </CardForm>
        <CardForm
          label="Surat Penawaran"
          rightSlot={() => {
            return (
              <Button
                className="p-4 border rounded-xl bg-white shadow-md"
                label={
                  <div className="flex items-center gap-2">
                    <RiDownload2Line size={22} />
                    Template Surat Penawaran
                  </div>
                }
                onClick={onDownloadTemplate}
              />
            );
          }}
        >
          <InputFormUpload
            controllerName={`fileTemp`}
            fileType="PDF, Docx"
            label={"Upload Surat Penawaran"}
            uploadFile={revisionFileMutation}
            maxSize={10 * 1024 * 1024}
          />
        </CardForm>
        <BottomFormAction
          backButtonAction={onBack}
          submitActionButton={() => methods.handleSubmit(onFormSubmit)()}
          submitButtonProps={{
            type: "submit",
            hideIcon: true,
          }}
          disableButtonSubmit={false}
          hideDraft={true}
          lastStep={true}
          className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
        />
      </FormProvider>
    </div>
  );
});

export default OfferNegotiationAdd;
