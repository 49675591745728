import { useState } from "react";
import {
  RiArrowDropDownLine,
  RiArrowDropUpLine,
  RiDeleteBin6Line,
} from "react-icons/ri";
import { motion as m } from "framer-motion";

const CardForm = ({
  children,
  label = "",
  buttonCollapse = false,
  childerForm = false,
  deleteOption = false,
  customDeleteFunction = false,
  rightSlot,
}) => {
  const [formCollapse, setFormCollapse] = useState(true);

  const collapse = (data) => {
    setFormCollapse(data);
  };

  return (
    <m.div
      layout
      className={`flex rounded-lg mt-5 ${
        childerForm ? "" : "bg-[#F9FCF6] border border-t-[#00BD52] border-t-8 "
      } `}
    >
      <div className={`flex flex-col w-full `}>
        <div className={`flex ${childerForm ? "bg-[#E1F1D6] rounded-lg" : ""}`}>
          <div className="flex-1 p-4 place-self-center">
            <span className={childerForm ? "text-[#01A24A]" : "font-[700]"}>
              {label}
            </span>
          </div>

          {rightSlot && <div className="mt-1.5 mr-2">{rightSlot()}</div>}

          {buttonCollapse ? (
            <div
              className={`flex-1 ${
                deleteOption ? "flex" : ""
              } text-right p-2 place-self-center`}
            >
              {deleteOption ? (
                <div className="flex-1 text-right p-1 mr-2">
                  <button
                    className="w-11 h-11 p-3 bg-[#D92D20] text-white rounded-md"
                    onClick={customDeleteFunction}
                  >
                    <RiDeleteBin6Line />
                  </button>
                </div>
              ) : (
                ""
              )}
              <button onClick={() => collapse(!formCollapse)}>
                <span className="text-[#01A24A] flex items-center">
                  {formCollapse ? "Tutup" : "Buka"}
                  {formCollapse ? (
                    <RiArrowDropUpLine />
                  ) : (
                    <RiArrowDropDownLine />
                  )}
                </span>
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        {childerForm ? "" : <div className="border"></div>}
        <div className="p-4 flex flex-col gap-4">
          {buttonCollapse ? (formCollapse ? children : "") : children}
        </div>
      </div>
    </m.div>
  );
};

export default CardForm;
