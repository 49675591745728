import axiosInstance from "app/interceptors";
import { general_config_v2 } from "app/config";

export const getUserDetail = async (userId, signal) => {
  const { data } = await axiosInstance.get(
    `${general_config_v2.BASE_URL_USER_MANAGEMENT}/v1/User/internal/${userId}`,
    { signal }
  );
  return data;
};
