import Table, { TableWrapper } from "components/atoms/Table";
import TablePagination from "components/atoms/TablePagination";
import UploadedFile from "components/molecules/UploadedFile";
import { useMemo } from "react";

export const PlantCondition = ({
  isLoading,
  totalPage,
  totalItems,
  setPage,
  setLimit,
  memberId,
}) => {

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "groupName",
        title: "Pertanyaan",
      },
      {
        key: "answer",
        title: "Jawaban",
      },
      {
        key: "state",
        title: "Bobot Nilai",
      },
      {
        key: "radioValue",
        title: "Sesuai",
        render: ({ item }) => (
          <div className="radio-buttons">
            <input
              type="radio"
              id={`radioOption1-${item.id}`}
              name={`radioOptions-${item.id}`}
              value="option1"
              // handle radio button logic here
            />
          </div>
        ),
      },
      {
        key: "radioValue",
        title: "Tidak Sesuai",
        render: ({ item }) => (
          <div className="radio-buttons">
            <input
              type="radio"
              id={`radioOption2-${item.id}`}
              name={`radioOptions-${item.id}`}
              value="option2"
              // handle radio button logic here
            />
          </div>
        ),
      },
    ];
  }, []);

  // Dummy Data
  const items = useMemo(() => {
    return [
      {
        id: 1,
        groupName: "Pohon yang dijaminkan masih tersedia/belum ditebang",
        answer: "Ya",
        state: "10",
        radioValue: "option1",
      },
      {
        id: 2,
        groupName: "Status lahan tidak berubah",
        answer: "Ya",
        state: "10",
        radioValue: "option2",
      },
      {
        id: 3,
        groupName: "Kondisi pohon masih baik",
        answer: "Ya",
        state: "10",
        radioValue: "option3",
      },
    ];
  }, []);

  return (
    <>
      <TableWrapper>
        <Table headers={headers} items={items} isLoading={isLoading} />
        <TablePagination
          totalPage={totalPage}
          totalItems={totalItems}
          onChange={setPage}
          onItemsPerPageChange={setLimit}
        />
      </TableWrapper>
      <div className="grid grid-cols-2">
        <UploadedFile
          filename="Foto tanaman tunda tebang dengan nomor pohon terlihat (menggunakan timestamp)"
          size="100 MB"
        />
      </div>
      <div className="flex justify-between">
        <p className="font-semibold">Total Nilai Kondisi Tanaman</p>
        <div className="px-8 py-2 bg-gray-200 rounded">
          <p>1000</p>
        </div>
      </div>
    </>
  );
};

export default PlantCondition;
