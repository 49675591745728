import BreadCrumbs from "components/molecules/Breadcrumbs";
import React, { Fragment, useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import {
  BottomFormAction,
  InputForm,
  InputFormUpload,
  Spinner,
} from "components";
import { useLocation, useNavigate, useParams } from "react-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import InstitutionProfile from "./InstitutionProfile";
import Debtors from "./Debtors";
import Requests from "./Requests";
import Minutes from "./Minutes";
import Recommendation from "./Recommendation";
import CardForm from "components/molecules/CardForm";
import { fileUpload, getFile } from "services/fileService";
import validationSchema from "./validation-schema";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getDetailMinutes,
  postDetailMinutes,
} from "services/institutions/minutes";
import { isEmpty, startCase, toLower } from "lodash";
import { getErrorMessage } from "helpers";
import { postExpressedOpinionApprove } from "services/institutions/expressed-opinion";
import { useSearchParams } from "react-router-dom";
import angkaTerbilangJs from "@develoka/angka-terbilang-js";

const getFileFromServiceCB = async (id) => {
  const response = await getFile(id);
  return response.data.data;
};

const InstitutionMinutesDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      // rights: [{ right: "" }],
      locations: [
        "Jawa Barat",
        "Jawa Timur",
        "Jawa Tengah",
        "Kalimantan Selatan",
      ],
      assurances: [
        "Surat Berharga (BPKB)",
        "Emas",
        "Kendaraan Bermotor",
        "Rumah",
      ],
    },
  });

  const [filters, setFilters] = useState({
    page_potention: 1,
    limit_potention: 10,
  });

  const postDetailMinutesCB = useMutation((payload) => {
    const { is_draft, agency_risalah_id, ...rest } = payload;
    return postDetailMinutes({ is_draft, agency_risalah_id }, rest);
  });
  const { data, isLoading } = useQuery(
    ["GET_DETAIL_MINUTES", id, filters],
    getDetailMinutes,
    {
      select: (data) => data?.data?.data,
    }
  );

  const location = useLocation();

  const [searchParams] = useSearchParams();
  const isView =
    searchParams.get("mode") !== null || location?.state?.status > 1;

  const queryClient = useQueryClient();

  const handleBack = () => navigate(-1);

  const handleDraft = () => {
    const values = methods.getValues();
    console.log("values: ", values);
  };

  const handleSubmit = (payload) => {
    console.log("bodyToSend", payload);
    const data = {
      ...payload,
      created: new Date(payload?.created),
      is_draft: false,
      agency_risalah_id: id,
    };
    // if (data?.file_temp) delete data?.file_temp;
    // if (data?.format_potention_file_temp)
    //   delete data?.format_potention_file_temp;
    // if (data?.collateral_meet_recruitment)
    //   delete data?.collateral_meet_recruitment;

    if (data?.minutes_file_temp) delete data?.minutes_file_temp;
    if (data?.support_file_temp) delete data?.support_file_temp;

    postDetailMinutesCB.mutate(data, {
      onSuccess: (res) => {
        enqueueSnackbar({
          variant: "success",
          message: (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontWeight: "bold" }}>
                Data berhasil disimpan!
              </span>
              <span>Data yang Anda masukkan berhasil dikirim</span>
            </div>
          ),
        });
        navigate(-1);
      },
      onError: (error) => {
        enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        });
      },
    });
  };

  const fileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      if (file?.controllerName === "support_file_temp") {
        methods.register("support_file");
        methods.setValue("support_file", response.data.data.id);
      }
    } catch (error) {
      throw error;
    }
  });

  useEffect(() => {
    if (!isEmpty(data)) {
      const promises = [Promise.resolve()];
      const {
        support_file: supportFile,
        risalah_agency,
        recomendation,
        notes,
        support_file,
      } = data || {
        file: null,
      };
      const { file, created, ...restRisalahAgency } = risalah_agency || {
        file: null,
      };

      if (supportFile && supportFile !== "string") {
        promises.push(
          getFileFromServiceCB(supportFile).then((data) => {
            return {
              formId: "support_file_temp",
              type: data?.mimeType,
              ...data,
            };
          })
        );
      }
      if (file && file !== "string") {
        promises.push(
          getFileFromServiceCB(risalah_agency.file).then((data) => {
            return {
              formId: "minutes_file_temp",
              type: data?.mimeType,
              ...data,
            };
          })
        );
      }

      if (promises?.length > 0) {
        Promise.all(promises).then((values) => {
          const obj = {
            ...recomendation,
            ...restRisalahAgency,
            notes,
            support_file,
            created: new Date(created),
            fdb_loan_spell: recomendation.total_fdb
              ? startCase(toLower(angkaTerbilangJs(recomendation.total_fdb)))
              : undefined,
          };

          values.map((x) => {
            if (!isEmpty(x)) {
              obj[x.formId] = x;
            }
          });

          methods.reset(obj);
        });
      }
    }
  }, [data, methods]);

  const mutateExpressedOpinionApprove = useMutation({
    mutationFn: postExpressedOpinionApprove,
  });

  const handleApproveExpressedOpinion = () => {
    mutateExpressedOpinionApprove.mutate(
      {
        id: data?.request_id,
        data: {},
        params: {
          is_approve: true,
        },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Berhasil submit pendapat",
          });
          queryClient.invalidateQueries();
          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({
            variant: "error",
            message: "Gagal submit pendapat",
          });
        },
      }
    );
  };

  const handleRejectExpressedOpinion = () => {
    mutateExpressedOpinionApprove.mutate(
      {
        id: data?.request_id,
        data: {},
        params: {
          is_approve: false,
        },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Berhasil submit pendapat",
          });
          queryClient.invalidateQueries();
          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({
            variant: "error",
            message: "Gagal submit pendapat",
          });
        },
      }
    );
  };

  return (
    <div className="space-y-6 mb-40">
      <BreadCrumbs
        routes={[
          {
            label: "Daftar Risalah",
            path: "/institutions/minutes",
          },
          {
            label: "Detail",
            path: `/institutions/minutes/${id}`,
          },
        ]}
      />
      {isLoading ? (
        <div className="flex justify-center p-4">
          <Spinner theme={"primary"} />
        </div>
      ) : (
        <Fragment>
          <FormProvider {...methods}>
            <div className="space-y-5">
              <InstitutionProfile data={data} />
              <Requests data={data} />
              <Debtors
                data={data?.application}
                changePagePotention={(e) =>
                  setFilters((prev) => ({
                    filter: {
                      ...prev.filter,
                      page_potention: e,
                    },
                  }))
                }
                changeLimitPotention={(e) =>
                  setFilters((prev) => ({
                    filter: {
                      ...prev.filter,
                      limit_potention: e,
                    },
                  }))
                }
                filterTable={filters.filter}
              />
              <Minutes data={data} />
              <Recommendation data={data?.recomendation} />
              <CardForm label="Upload Dokumen Pendukung Risalah">
                <InputFormUpload
                  uploadFile={fileMutation}
                  controllerName={`support_file_temp`}
                  label="&nbsp;"
                  required={true}
                  fileType="PDF, Docx"
                  disabled={isView}
                />
              </CardForm>
              <CardForm label="Catatan Tambahan">
                <InputForm
                  required={true}
                  controllerName={"notes"}
                  className={"rounded-md w-full"}
                  textArea
                  rows={5}
                />
              </CardForm>

              {searchParams.get("review") ? (
                <BottomFormAction
                  backButtonAction={handleBack}
                  submitActionButton={handleApproveExpressedOpinion}
                  disableDrafButton={false}
                  hideSubmit={searchParams.get("mode") === "view"}
                  hideDraft={searchParams.get("mode") === "view"}
                  submitButtonProps={{
                    type: "submit",
                    label: "Terima",
                  }}
                  draftButtonProps={{
                    label: "Tolak",
                    className: "bg-red-500 text-white",
                  }}
                  drafButtonAction={handleRejectExpressedOpinion}
                  disableButtonSubmit={false}
                  className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
                />
              ) : (
                <BottomFormAction
                  backButtonAction={handleBack}
                  submitActionButton={() =>
                    methods.handleSubmit(handleSubmit)()
                  }
                  disableDrafButton={false}
                  hideSubmit={isView}
                  hideDraft={true}
                  submitButtonProps={{
                    type: "submit",
                    label: "Submit",
                  }}
                  drafButtonAction={handleDraft}
                  disableButtonSubmit={false}
                  // hideDraft={true}
                  className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
                />
              )}
            </div>
          </FormProvider>
        </Fragment>
      )}
    </div>
  );
};

export default InstitutionMinutesDetail;
