import BreadCrumbs from "components/molecules/Breadcrumbs";

import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";
import GroupScore from "./Section/GroupScore";
import RequestDetail from "./Section/RequestDetail";
import DebiturScore from "./Section/DebiturScore";
import Executors from "./Section/Executors";
import DetailGroup from "./Section/DetailGroup";
import UploadDocs from "./Section/UploadDocs";
import AdditionalNotes from "./Section/AdditionalNotes";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getDetailRisalah, submitRisalahData } from "services/fdb/risalah";
import { Button } from "components";
import { RiArrowLeftLine, RiSave2Line } from "react-icons/ri";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";

const submitRisalahSchema = yup.object({
  assessment_file: yup.string().required().label("Assessment file"),
  notes: yup.string().required().label("Catatan Tambahan"),
});

const RisalahDetail = () => {
  const { id, idRisalah } = useParams();

  const location = useLocation();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const isPendapatKomite = location.pathname.includes("pendapat-komite");

  const [filters, setFilters] = useState({
    filter: {
      page_desk: 1,
      limit_desk: 5,
      page_field: 1,
      limit_field: 5,
      page_applicant: 1,
      limit_applicant: 10,
    },
  });

  const breadcrumbs = useMemo(
    () =>
      isPendapatKomite
        ? [
            {
              label: "Agenda Rapat",
              path: "/",
            },
            {
              label: "Review Agenda Rapat",
              path: `/committee/pendapat-komite/${id}`,
            },
            {
              label: "Detail Risalah",
              path: `/committee/pendapat-komite/${id}/risalah/${idRisalah}`,
            },
          ]
        : [
            {
              label: "Risalah",
              path: "/risalah",
            },
            {
              label: "Detail",
              path: `/risalah/${id}`,
            },
          ],
    [id, idRisalah, isPendapatKomite]
  );

  const { data, isLoading } = useQuery(
    ["GET_DETAIL_RISALAH", isPendapatKomite ? idRisalah : id, filters],
    getDetailRisalah,
    {
      enabled: isPendapatKomite ? !!idRisalah : !!id,
      select: (data) => data?.data?.data?.data,
    }
  );

  const mutateSubmitRisalah = useMutation(["SUBMIT_RISALAH"], (data) =>
    submitRisalahData(id, data)
  );

  const methods = useForm({
    resolver: yupResolver(submitRisalahSchema),
  });

  const handleDraft = () => {
    const data = methods.getValues();

    mutateSubmitRisalah.mutate(
      {
        data: data,
        params: { is_draft: true },
      },
      {
        onSuccess: () => {
          enqueueSnackbar({
            variant: "success",
            message: "Berasil simpan sebagai draft",
          });
          navigate(-1);
        },
        onError: () => {
          enqueueSnackbar({
            variant: "error",
            message: "Gagal simpan sebagai draft",
          });
        },
      }
    );
  };

  const onSubmit = (data) => {
    mutateSubmitRisalah.mutate(
      {
        data: data,
        params: { is_draft: false },
      },
      {
        onSuccess: () => {
          enqueueSnackbar({
            variant: "success",
            message: "Berasil simpan risalah",
          });
          navigate(-1);
        },
        onError: () => {
          enqueueSnackbar({
            variant: "error",
            message: "Gagal simpan risalah",
          });
        },
      }
    );
  };

  useEffect(() => {
    if (data) {
      methods.reset({
        assessment_file: data?.risalah?.assessment_file,
        notes: data?.risalah?.notes,
      });
    }
  }, [data, methods]);

  return (
    <div className="space-y-6">
      <BreadCrumbs routes={breadcrumbs} />
      <FormProvider {...methods}>
        <RequestDetail data={data?.risalah} />
        <Executors
          isLoading={isLoading}
          data={data?.pic}
          filters={filters}
          setFilters={setFilters}
        />
        <DetailGroup data={data?.group_profile} />
        <GroupScore data={data?.group_profile_point} />
        <DebiturScore
          id={id}
          isLoading={isLoading}
          data={data?.applicant}
          risalah={data?.risalah}
          filters={filters}
          setFilters={setFilters}
          allData={data}
        />

        {!isPendapatKomite ? (
          <>
            <UploadDocs />
            <AdditionalNotes />
          </>
        ) : null}

        <div className="sticky bottom-4 flex justify-between w-full p-5 bg-white border rounded-md">
          <Button
            className="p-4 border rounded-lg"
            type="button"
            label={
              <div className="flex items-center gap-2 text-sm font-medium">
                <RiArrowLeftLine />
                {isPendapatKomite ? "Kembali" : "Batal"}
              </div>
            }
            onClick={() => navigate(-1)}
          />
          {!isPendapatKomite ? (
            <div className="flex gap-3">
              <Button
                className="p-4 border rounded-lg"
                type="button"
                label={
                  <div className="flex items-center gap-2 text-sm font-medium">
                    <RiSave2Line />
                    Simpan & Draft
                  </div>
                }
                onClick={handleDraft}
              />

              <Button
                className="p-4 border rounded-lg bg-primary-700 text-white hover:bg-primary-600"
                type="button"
                label={
                  <div className="flex items-center gap-2 text-sm font-medium">
                    Simpan
                  </div>
                }
                onClick={methods.handleSubmit(onSubmit)}
              />
            </div>
          ) : null}

          {/* <ModalConfirm type={"approve"} /> */}
        </div>
      </FormProvider>
    </div>
  );
};

export default RisalahDetail;
