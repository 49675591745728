import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getActivityCategory = (UserId) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/activity-categories`,
    {
      headers: {
        Authorization: `Bearer ${UserId}`,
      },
    }
  );
};
