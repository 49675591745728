import angkaTerbilangJs from "@develoka/angka-terbilang-js";
import { InputForm } from "components";
import MultiSelect from "components/atoms/MultiSelect";
import CardForm from "components/molecules/CardForm";
import UploadedFileAsync from "components/molecules/UploadFileAsync";
import { formatCurrency } from "helpers";
import { startCase, toLower } from "lodash";
import React from "react";

const options1 = [
  { value: "Jawa Barat", label: "Jawa Barat" },
  { value: "Jawa Timur", label: "Jawa Timur" },
  { value: "Jawa Tengah", label: "Jawa Tengah" },
  { value: "Kalimantan Selatan", label: "Kalimantan Selatan" },
];
const options2 = [
  { value: "Surat Berharga (BPKB)", label: "Surat Berharga (BPKB)" },
  { value: "Emas", label: "Emas" },
  { value: "Kendaraan Bermotor", label: "Kendaraan Bermotor" },
  { value: "Rumah", label: "Rumah" },
];

const Requests = ({ data }) => {
  return (
    <CardForm label="Permohonan Pembiayaan Lembaga Penyalur">
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-3 grid grid-cols-2">
          <div>
            <div className="font-semibold">Nilai Permohonan</div>
            <div>{formatCurrency(data?.application?.request?.value)}</div>
          </div>
          <div>
            <div className="font-semibold">Terbilang</div>
            <div>
              {startCase(
                toLower(angkaTerbilangJs(data?.application?.request?.value))
              )}
            </div>
          </div>
        </div>

        <div className="col-span-2">
          <div className="font-semibold">
            Rencana Jangka Waktu Pembiayaan yang Akan Disalurkan
          </div>
          <div>{data?.application?.request?.term_plan}</div>
        </div>
        <div>
          <div className="font-semibold">Garce Period</div>
          <div>
            {data?.application?.request?.grace_period.includes("null")
              ? "-"
              : data?.application?.request?.grace_period}
          </div>
        </div>
        <div className="col-span-3">
          <div className="font-semibold">Rencana Penyaluran FDB</div>
          <div>
            <UploadedFileAsync id={data?.application?.plan_file} />
          </div>
        </div>

        {/* <div className="col-span-3">
          <div className="font-semibold">
            Jenis Usaha Kehutanan Dan/Atau Investasi Lingkungan yang akan
            Dibiayai (Penjelasan Singkat)
          </div>
          <div>-</div>
        </div>
        <div className="col-span-3">
          <div className="font-semibold">
            Proyeksi Manfaat Penyaluran FDB, Termasuk Penyerapan Tenaga Kerja
          </div>
          <div>-</div>
        </div>
        <div className="col-span-3">
          <MultiSelect
            label="Lokasi Calon Penerima FDB"
            controllerName="locations"
            options={options1}
          />
        </div>
        <div className="col-span-3">
          <MultiSelect
            label="Jaminan/Agunan yang Disyaratkan kepada Calon Penerima FDB"
            controllerName="assurances"
            options={options2}
          />
        </div> */}
      </div>
    </CardForm>
  );
};

export default Requests;
