import React, { useCallback, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CardForm from "components/molecules/CardForm";
import { useNavigate, useParams } from "react-router";
import Button from "components/atoms/Button/Button";
import {
  BottomFormAction,
  InputForm,
  InputFormRadio,
  Spinner,
} from "components";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import {
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiInformationLine,
} from "react-icons/ri";
import { CardPrimary } from "components/atoms/Card";
import DebtorContent from "./DebtorContent";
import FundedBussinessProfile from "./FundedBussinessProfile";
import OfferingContent from "./OfferingContent";
import {
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
} from "components/atoms/Modal";
import { useSearchParams } from "react-router-dom";
import { formatCurrency, getErrorMessage } from "helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { putOfferNegotiation } from "services/fdb/offers";
import { enqueueSnackbar } from "notistack";

const validationSchema = (isPS) =>
  yup.object().shape({
    disbursement_verification: yup
      .bool()
      .transform((originalValue) =>
        originalValue === "" ? undefined : originalValue
      )
      .required()
      .when("test", (_, schema) => {
        if (isPS) {
          return schema.notRequired();
        }

        return schema;
      })
      .label("Hasil Verifikasi"),
    loan_period_verification: yup
      .bool()
      .transform((originalValue) =>
        originalValue === "" ? undefined : originalValue
      )
      .required()
      .label("Hasil Verifikasi"),
    application_fee_verification: yup
      .bool()
      .transform((originalValue) =>
        originalValue === "" ? undefined : originalValue
      )
      .required()
      .label("Hasil Verifikasi"),

    disbursement_verification_desc: yup
      .string()
      .when("disbursement_verification", ([value], schema) => {
        if (value === false) {
          return schema.required().label("Alasan Penolakan");
        }
        return schema.notRequired();
      }),
    loan_period_verification_desc: yup
      .string()
      .when("loan_period_verification", ([value], schema) => {
        if (value === false) {
          return schema.required().label("Alasan Penolakan");
        }
        return schema.notRequired();
      }),
    application_fee_verification_desc: yup
      .string()
      .when("application_fee_verification", ([value], schema) => {
        if (value === false) {
          return schema.required().label("Alasan Penolakan");
        }
        return schema.notRequired();
      }),
  });

export const NegotiationDetail = ({ initialValues }) => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { applicantId, id } = useParams();

  const typeForm = searchParams.get("type");
  const statusParam = searchParams.get("status");

  const [isOpen, setOpen] = useState(false);
  const [payload, setPayload] = useState(null);

  const methods = useForm({
    resolver: yupResolver(validationSchema(initialValues?.debtor?.is_forestry)),
    mode: "onChange",
    defaultValues: {
      application_fee_verification: undefined,
      application_fee_verification_desc: null,
      loan_period_verification: undefined,
      loan_period_verification_desc: null,
      disbursement_verification: undefined,
      disbursement_verification_desc: null,
    },
  });

  useEffect(() => {
    if (initialValues?.negotiations) {
      methods.reset({
        ...initialValues?.negotiations,
        negotiation_application: formatCurrency(
          initialValues?.negotiations?.negotiation_application
        ),
        recommendation_application: formatCurrency(
          initialValues?.negotiations?.recommendation_application
        ),
        recommendation_disbursement_value:
          initialValues?.negotiations?.recommendation_disbursement_value?.map(
            (x) => ({
              ...x,
              value: formatCurrency(x.value),
            })
          ),
        negotiation_disbursement_value:
          initialValues?.negotiations?.negotiation_disbursement_value?.map(
            (x) => ({
              ...x,
              value: formatCurrency(x.value),
            })
          ),
      });
    }
    return;
  }, [methods, initialValues]);

  const mutation = useMutation({
    mutationFn: (params) => {
      return putOfferNegotiation(applicantId, params);
    },
  });

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onFormSubmit = useCallback((payload) => {
    const {
      application_fee_verification,
      application_fee_verification_desc,
      loan_period_verification,
      loan_period_verification_desc,
      disbursement_verification,
      disbursement_verification_desc,
    } = payload;

    const dataToSend = {
      application_fee_verification,
      application_fee_verification_desc,
      loan_period_verification,
      loan_period_verification_desc,
      disbursement_verification,
      disbursement_verification_desc,
    };
    setPayload(dataToSend);
    setOpen(true);
  }, []);

  const verification = () => {
    if (payload) {
      mutation.mutate(payload, {
        onSuccess: () => {
          enqueueSnackbar({
            variant: "success",
            message: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ fontWeight: "bold" }}>
                  Data berhasil disimpan!
                </span>
                <span>Data yang Anda masukkan berhasil dikirim</span>
              </div>
            ),
          });
          queryClient.invalidateQueries(["offer-negotiation-detail"]);
          queryClient.invalidateQueries(["offers-detail"]);
          navigate(`/offer-negotiation/list-member/${id}`);
        },
        onError: (error) => {
          enqueueSnackbar({
            message: getErrorMessage(error),
            variant: "error",
          });
        },
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <DebtorContent data={initialValues?.debtor} />
      <FundedBussinessProfile data={initialValues?.business} />
      <OfferingContent data={initialValues?.offers} />
      {statusParam !== "null" && (
        <Negotiation
          data={initialValues?.negotiations}
          isForestry={initialValues?.debtor?.is_forestry}
        />
      )}
      <BottomFormAction
        backButtonAction={onBack}
        submitButtonProps={{
          label: "Finalisasi Negosiasi",
          type: "submit",
        }}
        submitActionButton={() => methods.handleSubmit(onFormSubmit)()}
        hideDraft={true}
        hideSubmit={
          statusParam !== "ON_REVIEW" && statusParam !== "NEGOTIATION"
        }
        disableButtonSubmit={false}
        className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
      />
      <ConfirmationModal
        isOpen={isOpen}
        setOpen={setOpen}
        isLoading={mutation.status === "loading"}
        onSubmit={verification}
      />
    </FormProvider>
  );
};

const RecommendationDisbursment = ({ name, isRecommendation = false }) => {
  const { control } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: name,
  });
  return fields.map((x, key) => (
    <InputForm
      key={x.id}
      controllerName={`${name}[${key}].value`}
      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
      label={`Nilai Pencairan Tahap ${key + 1} yang ${
        isRecommendation ? "Ditawarkan" : "Diajukan"
      }`}
      placeholder={""}
      disabled
    />
  ));
};

const Negotiation = ({ isForestry }) => {
  const { control } = useFormContext();

  const loan_period_verification = useWatch({
    control,
    name: "loan_period_verification",
  });

  const disbursement_verification = useWatch({
    control,
    name: "disbursement_verification",
  });

  const application_fee_verification = useWatch({
    control,
    name: "application_fee_verification",
  });

  return (
    <CardForm label="Negosiasi">
      <CardPrimary label={"Nilai Permohonan"}>
        <div className="grid grid-cols-2 lg:grid-cols-2 gap-6">
          <InputForm
            controllerName={"recommendation_application"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={"Nilai Permohonan Yang Ditawarkan"}
            placeholder={""}
            disabled
          />
          <InputForm
            controllerName={"negotiation_application"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={"Nilai Permohonan Yang Diajukan"}
            placeholder={""}
            disabled
          />
          <div className="border rounded-md px-4 py-3 col-span-2">
            <InputFormRadio
              label={"Hasil Verifikasi"}
              controllerName={`application_fee_verification`}
              className="flex items-center justify-between gap-4"
              values={[
                {
                  label: (
                    <>
                      <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                      Data Terverifikasi
                    </>
                  ),
                  value: true,
                },
                {
                  label: (
                    <>
                      <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                      Data Belum Sesuai/ Ada Kesalahan
                    </>
                  ),
                  value: false,
                },
              ]}
            />
            {application_fee_verification !== "" &&
              application_fee_verification === false && (
                <InputForm
                  controllerName={`application_fee_verification_desc`}
                  className={"w-full rounded-md border p-3"}
                  wrapperClass="mt-5"
                  label={"Jelaskan Penolakannya"}
                  placeholder={"Jelaskan Penolakannya"}
                  textArea={true}
                />
              )}
          </div>
        </div>
      </CardPrimary>
      <CardPrimary label={"Jangka Waktu Pinjaman"}>
        <div className="grid grid-cols-2 lg:grid-cols-2 gap-6">
          <div className="col-span-2">
            <InputForm
              controllerName={"recommendation_loan_period"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Jangka Waktu Pinjaman Ditawarkan"}
              placeholder={""}
              disabled
            />
          </div>
          <InputForm
            controllerName={"negotiation_loan_period_value"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={"Jangka Waktu Pinjaman Diajukan"}
            placeholder={""}
            disabled
          />
          <InputForm
            controllerName={"negotiation_loan_period_type"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={
              <span className="text-transparent">
                Jangka Waktu Pinjaman Diajukan
              </span>
            }
            placeholder={""}
            disabled
          />
          <div className="border rounded-md px-4 py-3 col-span-2">
            <InputFormRadio
              label={"Hasil Verifikasi"}
              controllerName={`loan_period_verification`}
              className="flex items-center justify-between gap-4"
              values={[
                {
                  label: (
                    <>
                      <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                      Data Terverifikasi
                    </>
                  ),
                  value: true,
                },
                {
                  label: (
                    <>
                      <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                      Data Belum Sesuai/ Ada Kesalahan
                    </>
                  ),
                  value: false,
                },
              ]}
            />
            {loan_period_verification !== "" &&
              loan_period_verification === false && (
                <InputForm
                  controllerName={`loan_period_verification_desc`}
                  className={"w-full rounded-md border p-3"}
                  wrapperClass="mt-5"
                  label={"Jelaskan Penolakannya"}
                  placeholder={"Jelaskan Penolakannya"}
                  textArea={true}
                />
              )}
          </div>
        </div>
      </CardPrimary>
      {!isForestry && (
        <CardPrimary label={"Tahapan Pencairan"}>
          <div className="grid grid-cols-2 lg:grid-cols-2 gap-6">
            <div className="col-span-2">
              <InputForm
                controllerName={"recommendation_disbursement_phase"}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={"Tahapan Pencairan yang Ditawarkan"}
                placeholder={""}
                disabled
              />
            </div>
            <RecommendationDisbursment
              name="recommendation_disbursement_value"
              isRecommendation={true}
            />
            <InputForm
              controllerName={"negotiation_disbursement_phase"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Tahapan Pencairan Diajukan"}
              placeholder={""}
              disabled
            />

            <RecommendationDisbursment
              name="negotiation_disbursement_value"
              isRecommendation={false}
            />
            <div className="border rounded-md px-4 py-3 col-span-2">
              <InputFormRadio
                label={"Hasil Verifikasi"}
                controllerName={`disbursement_verification`}
                className="flex items-center justify-between gap-4"
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Data Terverifikasi
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Data Belum Sesuai/ Ada Kesalahan
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
              {disbursement_verification !== "" &&
                disbursement_verification === false && (
                  <InputForm
                    controllerName={`disbursement_verification_desc`}
                    className={"w-full rounded-md border p-3"}
                    wrapperClass="mt-5"
                    label={"Jelaskan Penolakannya"}
                    placeholder={"Jelaskan Penolakannya"}
                    textArea={true}
                  />
                )}
            </div>
          </div>
        </CardPrimary>
      )}
    </CardForm>
  );
};

const ConfirmationModal = ({
  isOpen,
  setOpen,
  isLoading = false,
  onSubmit,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      setOpen={setOpen}
      manual
      onOpenChange={() => setOpen(false)}
    >
      <ModalHeader
        noBorder
        hideClose
        title={
          <div className="w-12 h-12 rounded-full flex items-center justify-center text-orange-700 bg-orange-100 border-4 border-orange-50">
            <RiInformationLine className="text-base" size={24} />
          </div>
        }
      />
      <ModalBody className="space-y-4">
        <p className="text-lg font-semibold">Kirim Persetujuan</p>
        <p className="text text-[#475467]">
          Anda yakin akan mengirimkan persetujuan verifikasi data Negosiasi ini?
        </p>
        <div className="flex items-center gap-4">
          <ModalClose>
            <Button label="Tidak" block disabled={isLoading} />
          </ModalClose>
          <Button
            type="button"
            onClick={onSubmit}
            label={
              isLoading ? (
                <>
                  <Spinner /> Loading...
                </>
              ) : (
                "Ya"
              )
            }
            color="primary"
            block
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default NegotiationDetail;
