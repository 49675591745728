import React, { Fragment } from "react";
import { InputForm, InputFormRadio } from "components";
import { customStyles } from "components/atoms/Select";
import Select from "react-select";
import { useFormContext, useWatch } from "react-hook-form";

const UnitOptions = [
  { value: "Minggu", label: "Minggu" },
  { value: "Bulan", label: "Bulan" },
  { value: "Tahun", label: "Tahun" },
];
const AgroForestryFormSection = () => {
  const methods = useFormContext();
  const bussiness_cycle_unit = useWatch({
    control: methods.control,
    name: "bussiness_cycle_unit",
  });

  const maintenance_pattern = useWatch({
    control: methods.control,
    name: "maintenance_pattern"
  });

  const sales_pattern = useWatch({
    control: methods.control,
    name: "sales_pattern"
  });

  return (
    <Fragment>
      <div className="grid grid-cols-2 gap-4">
        <InputForm
          label={"Siklus Usaha"}
          required={true}
          controllerName={"bussiness_cycle"}
          className="w-full rounded-md border p-3"
          placeholder="Siklus Usaha"
          onKeyPress={(event) => {
            const ASCIICode = event.which ? event.which : event.keyCode;
            if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
              return;
            event.preventDefault();
          }}
        />
        <div className="flex flex-col gap-1 items-center w-full">
          <span className="text-transparent">-</span>
          <Select
            styles={customStyles}
            className="w-full"
            options={UnitOptions}
            value={UnitOptions.find((x) => x.value === bussiness_cycle_unit)}
            onChange={(e) => {
              methods.setValue("bussiness_cycle_unit", e.value);
            }}
          />
        </div>
      </div>
      <p className="font-bold text-[#01A24A]">Pemeliharaan</p>
      <div>
        <div className="flex gap-4">
          <InputFormRadio
            label={"Apakah Usaha Ini Memiliki Pola Pemeliharaan?"}
            controllerName={"maintenance_pattern"}
            values={[
              {
                label: "Ya",
                value: true,
              },
              {
                label: "Tidak",
                value: false,
              },
            ]}
          />
        </div>
      </div>
      {maintenance_pattern === true && (
        <div className="grid grid-cols-2 gap-4">
          <InputForm
            controllerName={`maintenance_purchase_price`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label="Harga Beli"
            placeholder="Harga Beli"
            prefix="Rp"
            required
            isNumber={true}
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
          />
          <InputForm
            controllerName={`maintenance_cost`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label="Biaya Pemeliharaan"
            placeholder="Biaya Pemeliharaan"
            prefix="Rp"
            required
            isNumber={true}
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
          />
          <InputForm
            controllerName={`maintenance_revenue`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label="Pendapatan Pemeliharaan"
            placeholder="Pendapatan Pemeliharaan"
            prefix="Rp"
            required
            isNumber={true}
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
          />
          <InputForm
            controllerName={`maintenance_selling_price`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label="Harga Siap Jual"
            placeholder="Harga Siap Jual"
            prefix="Rp"
            required
            isNumber={true}
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
          />
        </div>
      )}
      <p className="font-bold text-[#01A24A]">Penjualan</p>
      <div>
        <div className="flex gap-4">
          <InputFormRadio
            label={"Apakah Usaha Ini Memiliki Pola Penjualan?"}
            controllerName={"sales_pattern"}
            values={[
              {
                label: "Ya",
                value: true,
              },
              {
                label: "Tidak",
                value: false,
              },
            ]}
          />
        </div>
      </div>
      {sales_pattern && (
        <div className="grid grid-cols-2 gap-4">
          <InputForm
            controllerName={`sales_modal`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label="Modal"
            placeholder="Modal"
            prefix="Rp"
            required
            isNumber={true}
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
          />
          <InputForm
            controllerName={`sales_expenses`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label="Biaya Penjualan"
            placeholder="Biaya Penjualan"
            prefix="Rp"
            required
            isNumber={true}
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
          />
          <InputForm
            controllerName={`sales_selling_price`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label="Harga Jual"
            placeholder="Harga Jual"
            prefix="Rp"
            isNumber={true}
            required
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
          />
          <InputForm
            controllerName={`sales_revenue`}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label="Pendapatan Penjualan"
            placeholder="Pendapatan Penjualan"
            prefix="Rp"
            required
            isNumber={true}
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
          />
        </div>
      )}
    </Fragment>
  );
};

export default AgroForestryFormSection;
