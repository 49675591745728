import Button from "components/atoms/Button/Button";
import { RiFileLine } from "react-icons/ri";
import { useNavigate } from "react-router";

const UploadedFile = ({ filename, size, routes }) => {
  const navigate = useNavigate();
  return (
    <div className="rounded-lg px-3 py-2 flex items-start gap-4 border bg-white">
      <div className="w-8 h-8 rounded-full flex items-center justify-center text-primary-700 bg-primary-100 border-4 border-primary-50">
        <RiFileLine className="text-base" />
      </div>
      <div>
        <p className="font-medium text-sm mb-2">{filename}</p>
        <p className="font-medium text-sm">{size}</p>
        <a
          href={routes}
          target="_blank"
          rel="noreferrer"
          className="hover:underline !px-0 text-primary-600 hover:text-primary-700 focus:ring-primary-600 focus:ring-offset-primary-600 py-3 h-[44px] text-sm font-semibold"
        >
          Lihat Dokumen
        </a>
      </div>
    </div>
  );
};

export default UploadedFile;
