/* eslint-disable no-template-curly-in-string */
import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";
import { find } from "lodash";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  // TABULATION LAND
  tabulation_land_real_document_temp: Yup.mixed()
    .test(
      "cid_img",
      ({ label }) =>
        validationErrorMsg.mixed.required.replace("${path}", label),
      (value, context) => {
        const allowedTypes = ["jpg", "png", "jpeg"];
        if (value) {
          const data = find(allowedTypes, (at) => value.type.includes(at));
          if (!data) {
            return context.createError({
              message: validationCustomErrorMsg.isFiletype(
                allowedTypes.join(", ")
              )({ label: "Foto Pemohon Dengan Dokumen Asli" }),
            });
          }
          if (value.size > 10 * 1024 * 1024) {
            return context.createError({
              message: validationCustomErrorMsg.isFileSize("10MB")({
                label: "Foto Pemohon Dengan Dokumen Asli",
              }),
            });
          }

          return true;
        }

        // otherwise, return false to render the field required
        return false;
      }
    )
    .required()
    .label("Foto Pemohon Dengan Dokumen Asli"),
  tabulation_land_verification: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Hasil Verifikasi"),
  tabulation_land_history: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .when("tabulation_land_legality", ([value], schema) => {
      if (value === true) {
        return schema.required().label("Opsi Ini");
      }
      return schema.notRequired();
    }),
  tabulation_land_legality: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi Ini"),
  tabulation_land_have_dispute_free: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .when("tabulation_land_legality", ([value], schema) => {
      if (value === true) {
        return schema.required().label("Opsi Ini");
      }
      return schema.notRequired();
    }),
  tabulation_land_have_selling: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .when("tabulation_land_legality", ([value], schema) => {
      if (value === true) {
        return schema.required().label("Opsi Ini");
      }
      return schema.notRequired();
    }),
  tabulation_land_have_grant: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .when("tabulation_land_legality", ([value], schema) => {
      if (value === true) {
        return schema.required().label("Opsi Ini");
      }
      return schema.notRequired();
    }),
  tabulation_land_no_percil: Yup.string().required().label("Nomor Persil"),
  tabulation_land_width_area: Yup.string().required().label("Luas"),
  tabulation_land_verification_desc: Yup.string().when(
    "tabulation_land_verification",
    ([value], schema) => {
      if (value === false) {
        return schema.required().label("Kesalahan/Ketidak sesuaian data");
      }
      return schema.notRequired();
    }
  ),

  // PROSPECT
  prospect_verification: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Hasil Verifikasi"),
  prospect_verification_desk: Yup.string().when(
    "tabulation_land_verification",
    ([value], schema) => {
      if (value === false) {
        return schema.required().label("Kesalahan/Ketidak sesuaian data");
      }
      return schema.notRequired();
    }
  ),
  prospect_maintenance_pattern: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi Ini"),
  prospect_sales_pattern: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi Ini"),
  prospect_make_profit: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi Ini"),
  prospect_normal_income: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi Ini"),
  prospect_income_needed: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi Ini"),
  prospect_financed_province_id: Yup.string().required().label("Provinsi"),
  prospect_financed_city_id: Yup.string().required().label("Kota/Kabupaten"),
  prospect_financed_district_id: Yup.string().required().label("Kecamatan"),
  prospect_financed_village_id: Yup.string().required().label("Nomor Persil"),
  prospect_financed_address: Yup.string().required().label("Kelurahan/Desa"),
  prospect_debtor_house_file_temp: Yup.mixed()
    .test(
      "cid_img",
      ({ label }) =>
        validationErrorMsg.mixed.required.replace("${path}", label),
      (value, context) => {
        const allowedTypes = ["jpg", "png", "jpeg"];
        if (value) {
          const data = find(allowedTypes, (at) => value.type.includes(at));
          if (!data) {
            return context.createError({
              message: validationCustomErrorMsg.isFiletype(
                allowedTypes.join(", ")
              )({ label: "Foto Usaha Debitur" }),
            });
          }
          if (value.size > 10 * 1024 * 1024) {
            return context.createError({
              message: validationCustomErrorMsg.isFileSize("10MB")({
                label: "Foto Usaha Debitur",
              }),
            });
          }

          return true;
        }

        // otherwise, return false to render the field required
        return false;
      }
    )
    .required()
    .label("Foto Usaha Debitur"),

  // TABULATION PAYABILITY
  tabulation_payability_verification: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Hasil Verifikasi"),
  tabulation_payability_verification_desk: Yup.string().when(
    "tabulation_payability_verification",
    ([value], schema) => {
      if (value === false) {
        return schema.required().label("Kesalahan/Ketidak sesuaian data");
      }
      return schema.notRequired();
    }
  ),
  tabulation_payability_monthly_outcome: Yup.string()
    .required()
    .label("Perkiraan Pengeluaran Harian"),
  tabulation_payability_monthly_income: Yup.string()
    .required()
    .label("Perkiraan Penghasilan"),
  tabulation_payability_monthly_net_income: Yup.string()
    .required()
    .label("Pendapatan Net Per Bulan"),

  collateral_tabulation_conclusion_verification: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Kesimpulan Verifikasi"),
  collateral_tabulation_conclusion_verification_desc: Yup.string().when(
    "collateral_tabulation_conclusion_verification",
    ([value], schema) => {
      if (value === false) {
        return schema.required().label("Kesalahan/Ketidak sesuaian data");
      }
      return schema.notRequired();
    }
  ),
});
