import React, { useMemo, useState } from "react";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/atoms/Button/Button";
import { RiArrowLeftLine, RiMapPinLine } from "react-icons/ri";
import { CardPrimary } from "components/atoms/Card";
import DataDefinition from "components/atoms/DataDefinition";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalClose,
} from "components/atoms/Modal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AtomSelect from "components/atoms/Select";
import { RiInformationLine } from "react-icons/ri";
import { InputForm, Spinner } from "components";
import { FormProvider, useForm } from "react-hook-form";
import { updateGroupApplicationVerificationMember } from "services";
import { useMutation, useQuery } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import dayjs from "dayjs";
import { formatCurrency } from "helpers";
import { getVerificatorApplicantDetail } from "services/fdb/verificator";
import { getMasterSocialArea } from "services/masterData/socialArea";
import { useSelector } from "react-redux";
import UploadedFileAsync from "components/molecules/UploadFileAsync";

const DebtorDetail = () => {
  const title = "Daftar Registrasi Perorangan dalam Kelompok";
  const navigate = useNavigate();
  const { id, debtorId } = useParams();
  const [data, setData] = useState({});

  const userId = useSelector((state) => state.oidc.oidc_user.access_token);

  const {
    data: dataApplicant,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["group-application-verification-member-detail", debtorId],
    queryFn: async () => {
      return (await getVerificatorApplicantDetail(debtorId)).data;
    },
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    select: (data) => data.data,
  });

  const { data: socialArea } = useQuery({
    queryKey: ["master-social-area-fdb"],
    queryFn: async () => {
      const res = await getMasterSocialArea(userId);
      const activities = res?.data?.data?.data || [];
      const formattedData = activities.map((activity) => ({
        value: activity.id,
        label: activity.name,
      }));
      if (formattedData.length <= 0) {
        formattedData.push({
          value: null,
          label: null,
        });
      }
      return formattedData;
    },
    retry: 1,
  });

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: "/groups",
          },
          {
            label: "Detail",
            path: `/groups/${id}`,
          },
          {
            label: "Detail Calon Debitur",
            path: `/groups/${id}/debtor-detail/${debtorId}`,
          },
        ]}
      />

      <Button
        variant="link"
        color="primary"
        label={
          <>
            <RiArrowLeftLine className="text-xl" />
            Kembali Ke Daftar Debitur
          </>
        }
        onClick={() => navigate(-1)}
      />

      <CardForm label="Lampirkan Dokumen Pendukung">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {dataApplicant?.group_profile_member?.document.map((item) => {
            return <UploadedFileAsync id={item?.file} name={item?.desc} />;
          })}
        </div>
      </CardForm>

      <CardForm label="Detail Debitur" className="space-y-6">
        <CardPrimary label="Data Calon debitur">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
            <DataDefinition
              label="Nama Lengkap sesuai KTP"
              value={dataApplicant?.group_profile_member.name}
            />
            <DataDefinition
              label="Nomor NIK"
              value={dataApplicant?.group_profile_member.identity_number}
            />
            <DataDefinition
              label="Tempat Lahir"
              value={dataApplicant?.group_profile_member.place_of_birth}
            />
            <DataDefinition
              label="Tanggal Lahir"
              value={dayjs(
                dataApplicant?.group_profile_member.date_of_birth
              ).format("DD MMMM YYYY")}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mt-4">
            <DataDefinition
              label="No Kartu Keluarga"
              value={
                dataApplicant?.group_profile_member.family_card_number ?? "-"
              }
            />
            <DataDefinition
              label="NPWP"
              value={dataApplicant?.group_profile_member.npwp ?? "-"}
            />
            <DataDefinition
              label="Pekerjaan Utama"
              value={dataApplicant?.group_profile_member.main_job ?? "-"}
            />
          </div>
        </CardPrimary>

        <CardPrimary label="Data Pasangan">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
            <DataDefinition
              label="Nama Lengkap sesuai KTP"
              value={dataApplicant?.group_profile_member.couple_name}
            />
            <DataDefinition
              label="Nomor NIK"
              value={dataApplicant?.group_profile_member.couple_identity_number}
            />
            <DataDefinition
              label="Tempat Lahir"
              value={dataApplicant?.group_profile_member.couple_place_of_birth}
            />
            <DataDefinition
              label="Tanggal Lahir"
              value={
                dataApplicant?.group_profile_member?.couple_date_of_birth
                  ? dayjs(
                      dataApplicant?.group_profile_member?.couple_date_of_birth
                    ).format("DD MMMM YYYY")
                  : ""
              }
            />
          </div>
        </CardPrimary>

        <CardPrimary label="Alamat">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
            <DataDefinition
              label="Provinsi"
              value={dataApplicant?.group_profile_member?.province?.name ?? "-"}
            />
            <DataDefinition
              label="Kota/Kabupaten"
              value={dataApplicant?.group_profile_member?.city?.name ?? "-"}
            />
            <DataDefinition
              label="Kecamatan"
              value={dataApplicant?.group_profile_member?.district?.name ?? "-"}
            />
            <div></div>
            <DataDefinition
              label="Kelurahan/Desa"
              value={dataApplicant?.group_profile_member?.village?.name ?? "-"}
            />
            <DataDefinition
              label="RT"
              value={dataApplicant?.group_profile_member.rt ?? "-"}
            />
            <DataDefinition
              label="RW"
              value={dataApplicant?.group_profile_member.rw ?? "-"}
            />
            <div></div>
            <DataDefinition
              label="Alamat"
              value={dataApplicant?.group_profile_member.address ?? "-"}
            />
          </div>
        </CardPrimary>

        <CardPrimary label="Alamat Domisili">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
            <DataDefinition
              label="Provinsi"
              value={
                dataApplicant?.group_profile_member?.domicile_province?.name ??
                "-"
              }
            />
            <DataDefinition
              label="Kota/Kabupaten"
              value={
                dataApplicant?.group_profile_member?.domicile_city?.name ?? "-"
              }
            />
            <DataDefinition
              label="Kecamatan"
              value={
                dataApplicant?.group_profile_member?.domicile_district?.name ??
                "-"
              }
            />
            <DataDefinition
              label="Kelurahan/Desa"
              value={
                dataApplicant?.group_profile_member?.domicile_village?.name ??
                "-"
              }
            />
            <DataDefinition
              label="RT"
              value={dataApplicant?.group_profile_member.domicile_rt ?? "-"}
            />
            <DataDefinition
              label="RW"
              value={dataApplicant?.group_profile_member.domicile_rw ?? "-"}
            />
            <DataDefinition
              label="Berdomisili Sejak Tahun"
              value={
                dayjs(
                  dataApplicant?.group_profile_member.domicile_since_year
                ).format("DD MMMM YYYY") ?? "-"
              }
            />
            <DataDefinition
              label="Geolocation"
              value={
                <Button
                  label={
                    <>
                      <RiMapPinLine className="text-xl text-primary-700" />
                      Google Map
                    </>
                  }
                  onClick={() => {
                    window.open(
                      `https://www.google.com/maps/@${data.domicile_latitude},${data.domicile_longitude},15z`,
                      "_blank",
                      "noopener noreferrer"
                    );
                  }}
                />
              }
            />
            <DataDefinition
              label="Alamat"
              value={dataApplicant?.group_profile_member.domicile_address}
            />
          </div>
        </CardPrimary>

        <CardPrimary label="Informasi Keuangan">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {dataApplicant?.group_profile_member?.income_info.map((item) => (
              <>
                <DataDefinition
                  label="Perkiraan Pendapatan"
                  value={item?.income ? formatCurrency(item.income) : "-"}
                />
                <DataDefinition
                  label="Siklus Pendapatan"
                  value={
                    (item.income_cycle ?? "") +
                    " " +
                    (item.income_cycle_unit ?? "")
                  }
                />
              </>
            ))}
            <div></div>
            <DataDefinition
              label="Pengeluaran Rutin Per Bulan"
              value={
                dataApplicant?.group_profile_member.routine_outcome_per_month
                  ? formatCurrency(
                      dataApplicant?.group_profile_member
                        .routine_outcome_per_month
                    )
                  : "-"
              }
            />
            <DataDefinition
              label="Pengeluaran Terbesar"
              value={
                dataApplicant?.group_profile_member.biggest_outcome
                  ? formatCurrency(
                      dataApplicant?.group_profile_member.biggest_outcome
                    )
                  : "-"
              }
            />
            {console.log(dataApplicant)}
            <DataDefinition
              label="Penggunaan Pengeluaran Terbesar"
              value={
                dataApplicant?.group_profile_member.use_largest_outcome
                  ? dataApplicant?.group_profile_member.use_largest_outcome
                  : "-"
              }
            />
          </div>
        </CardPrimary>

        <CardPrimary label="Informasi Riwayat Pinjaman"></CardPrimary>
      </CardForm>

      {!dataApplicant?.is_approved && (
        <div className="border rounded-lg px-4 py-3 flex justify-end gap-3">
          <ModalApproval
            id={debtorId}
            groupId={id}
            type="reject"
            socialArea={socialArea}
          />
          <ModalApproval id={debtorId} groupId={id} type="approve" />
        </div>
      )}
    </div>
  );
};

const useApprove = ({ id, onSuccess, onError }) => {
  const { mutate, isLoading } = useMutation({
    mutationFn: async (payload) => {
      return await updateGroupApplicationVerificationMember(
        id,
        payload,
        payload.rejected_reason ? "rejected" : "approved"
      );
    },
    onSuccess,
    onError,
  });

  return { mutate, isLoading };
};

const ModalApproval = ({ id, type, socialArea, groupId }) => {
  const navigate = useNavigate();
  const { mutate, isLoading } = useApprove({
    id,
    onSuccess: () => {
      if (type === "reject") {
        enqueueSnackbar({
          variant: "success",
          message: (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontWeight: "bold" }}>Data berhasil ditolak!</span>
              <span>Pengajuan dana berhasil ditolak</span>
            </div>
          ),
        });
      } else {
        enqueueSnackbar({
          variant: "success",
          message: (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontWeight: "bold" }}>
                Data berhasil disimpan!
              </span>
              <span>Data yang Anda masukkan berhasil dikirim</span>
            </div>
          ),
        });
      }
      navigate(-1);
    },
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
  });

  const validationSchema = useMemo(() => {
    if (type === "reject") {
      return yup.object().shape({
        rejected_category: yup.string().required("Harus diisi"),
        rejected_reason: yup.string().required("Harus diisi"),
      });
    }
    return yup.object().shape({});
  }, [type]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {},
  });

  const { handleSubmit } = methods;
  const onSubmit = (data) => {
    mutate(data);
  };

  return (
    <Modal
      trigger={
        type === "approve" ? (
          <Button label="Setujui" color="primary" />
        ) : (
          <Button label="Tolak" color="error" />
        )
      }
    >
      {type === "approve" && (
        <ModalHeader
          noBorder
          hideClose
          title={
            <div className="w-12 h-12 rounded-full flex items-center justify-center text-orange-700 bg-orange-100 border-4 border-orange-50">
              <RiInformationLine className="text-base" size={24} />
            </div>
          }
        />
      )}
      {type === "reject" && <ModalHeader title="Tolak" />}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="space-y-4">
            {type === "approve" && (
              <p className="text-lg font-semibold">
                Anda yakin akan mengirimkan persetujuan verifikasi data
                registrasi ini ?
              </p>
            )}
            {type === "reject" && (
              <>
                <AtomSelect
                  controllerName={`rejected_category`}
                  wrapperClass={"!w-auto min-w-[100px]"}
                  className="basic-single"
                  options={socialArea}
                  label="Fungsi Kawasan & Skema PS "
                  placeholder="Fungsi Kawasan & Skema PS "
                  returnObject={false}
                />
                <InputForm
                  controllerName={`rejected_reason`}
                  label="Alasan Penolakan"
                  placeholder="Alasan Penolakan"
                  className="rounded-md w-full px-4 py-3 border"
                  textArea
                />
              </>
            )}
            <div className="flex items-center gap-4">
              <ModalClose>
                <Button label="Batal" block disabled={isLoading} />
              </ModalClose>
              <Button
                label={
                  isLoading ? (
                    <>
                      <Spinner /> Loading...
                    </>
                  ) : type === "approve" ? (
                    "Ya"
                  ) : (
                    "Submit"
                  )
                }
                color="primary"
                block
              />
            </div>
          </ModalBody>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default DebtorDetail;
