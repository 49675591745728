import React, { useEffect, useMemo, useState } from "react";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { RiAddLine, RiEyeLine, RiSearchLine } from "react-icons/ri";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { InputForm } from "components";
import AtomSelect from "components/atoms/Select";
import AtomDatePicker from "components/atoms/Datepicker";
import Table, { TableWrapper } from "components/atoms/Table";
import TablePagination from "components/atoms/TablePagination";
import Button from "components/atoms/Button/Button";
import Badge from "components/atoms/Badge";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { getListOffers } from "services/fdb/offers";
import dayjs from "dayjs";
import { debounce } from "lodash";

const title = "Daftar Penawaran - Perorangan dalam Kelompok";

const OFFER_STATUS = {
  DONE: {
    text: "Selesai",
    color: "success",
  },
  NEGOTIATION: {
    text: "Negosiasi",
    color: "error",
  },
  ON_REVIEW: {
    text: "Sedang Direview",
    color: "warning",
  },
  DEBITUR_REVIEW: {
    text: "Review Debitur",
    color: "bluelight",
  },
};
const OfferNegotiation = ({ accessRight }) => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    search: undefined,
    date: undefined,
    status: undefined,
  });

  // For Group Table
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const { data, status } = useQuery({
    queryKey: ["offers-list", page, limit, filter],
    queryFn: async () => {
      return (await getListOffers({ page, limit, filter })).data;
    },
    onSuccess: ({ data }) => {
      setTotalPage(data?.meta?.last_page);
      setTotalItems(data?.meta?.total);
    },
    refetchOnWindowFocus: false,
  });

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      search: yup.string(),
      // state: yup.object() || yup.string(),
      date: yup.string(),
    });
  }, []);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      search: "",
      state: "",
      date: "",
    },
  });
  const handleSubmitFilter = debounce(() => {
    setFilter({
      search:
        methods.getValues("search") !== ""
          ? methods.getValues("search")
          : undefined,
      date:
        methods.getValues("date") !== ""
          ? methods.getValues("date")
          : undefined,
      status:
        methods.getValues("state") !== ""
          ? methods.getValues("state").value
          : undefined,
    });
  }, 1000);

  useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(handleSubmitFilter)
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: "/offer-negotiation",
          },
        ]}
      />
      <div className="flex w-full justify-between">
        <div>
          <h1 className="text-gray-900 text-2xl font-semibold leading-loose">
            {title}
          </h1>
        </div>
        {accessRight?.canCreate && (
          <Button
            theme="primary"
            variant="icon"
            label={
              <div className="flex items-center gap-2">
                <RiAddLine className="text-lg" />
                Buat Penawaran
              </div>
            }
            onClick={() => {
              navigate("/offer-negotiation/add");
            }}
          />
        )}
      </div>
      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <OfferFilter methods={methods} />
        <OfferNegotiationTable
          data={data?.data?.data}
          isLoading={status === "loading"}
          totalItems={totalItems}
          totalPage={totalPage}
          setLimit={setLimit}
          setPage={setPage}
        />
      </div>
    </div>
  );
};

const OfferFilter = ({ methods }) => {
  const stateOptions = useMemo(
    () => [
      { value: "Baru", label: "Baru" },
      { value: "Sedang Dikerjakan", label: "Sedang Dikerjakan" },
      { value: "Selesai ", label: "Selesai " },
    ],
    []
  );

  return (
    <FormProvider {...methods}>
      <form className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end">
        <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
          <RiSearchLine className="text-gray-400 text-xl" />
          <InputForm
            controllerName="search"
            placeholder="Cari"
            className={`!pl-0 w-full rounded-md border-none`}
          />
        </div>
        <AtomSelect
          controllerName={`state`}
          className={`w-full rounded-md h-10 absolute`}
          options={stateOptions}
          placeholder={"Status"}
          required={true}
        />
        <AtomDatePicker
          controllerName={"date"}
          label=""
          placeholder="dd/mm/yyyy"
          datepickerOptions={{
            dateFormat: "dd/MM/yyyy",
          }}
          showErrorLabel
        />
      </form>
    </FormProvider>
  );
};

const OfferNegotiationTable = ({
  data,
  isLoading,
  totalPage,
  totalItems,
  setPage,
  setLimit,
}) => {
  const navigate = useNavigate();

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "letter_number",
        title: "Nomor Surat Penawaran",
      },
      {
        key: "group_profile_name",
        title: "Nama Kelompok",
        render: ({ item }) => {
          return item?.group_profile_name ?? "-";
        },
      },
      {
        key: "date_start",
        title: "Tanggal Pelaksanaan Penawaran",
        render: ({ item }) => {
          return dayjs(item.date_start).format("DD/MM/YYYY");
        },
      },
      {
        key: "date_end",
        title: "Batas Akhir Penawaran",
        render: ({ item }) => {
          return dayjs(item.date_end).format("DD/MM/YYYY");
        },
      },
      {
        key: "members",
        title: "Jumlah Anggota",
      },
      {
        key: "members_accepted",
        title: "Anggota Setuju",
      },
      {
        key: "members_negotiating",
        title: "Anggota Negoisasi",
      },
      {
        key: "members_rejected",
        title: "Anggota Menolak",
      },
      {
        key: "state",
        title: "Status",
        render: ({ item }) => (
          <Badge color={OFFER_STATUS[item?.status ?? "DEBITUR_REVIEW"].color}>
            {OFFER_STATUS[item?.status ?? "DEBITUR_REVIEW"].text}
          </Badge>
        ),
      },
      // {
      //   key: "beritaAcara",
      //   title: "Berita Acara",
      // },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => {
          return (
            <>
              {["NEGOTIATION"].includes(item.status) ? (
                <Button
                  color="primary"
                  label="Lanjutkan Review"
                  onClick={() => {
                    navigate(
                      `/offer-negotiation/list-member/${item.id}?status=${item.status}`
                    );
                  }}
                />
              ) : (
                <Button
                  variant="icon"
                  label={<RiEyeLine className="text-lg" />}
                  onClick={() => {
                    navigate(
                      `/offer-negotiation/list-member/${item.id}?status=${item.status}`
                    );
                  }}
                />
              )}
            </>
          );
        },
      },
    ];
  }, [navigate]);

  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={data} isLoading={isLoading} />
      <TablePagination
        totalPage={totalPage}
        totalItems={totalItems}
        onChange={setPage}
        onItemsPerPageChange={setLimit}
      />
    </TableWrapper>
  );
};

export default OfferNegotiation;
