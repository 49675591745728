import { Button, Input, InputFormUpload } from "components";
import Badge from "components/atoms/Badge";
import {
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
} from "components/atoms/Modal";
import { customStyles } from "components/atoms/Select";
import Table, { TableWrapper } from "components/atoms/Table";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import UploadedFile from "components/molecules/UploadedFile";
import dayjs from "dayjs";
import { debounce, toLower } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";

import {
  RiArrowLeftLine,
  RiCloseLine,
  RiEyeLine,
  RiInformationLine,
  RiSearchLine,
} from "react-icons/ri";
import { useNavigate, useParams } from "react-router";

import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getDisburstmentsDetail,
  getDisburstmentsDirectorateDetail,
  putDisburstmentsDetail,
  putDisburstmentsDirectorate,
} from "services/fdb/disbursement";
import { Pagination } from "components/v2/shared/pagination";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import * as yup from "yup";

import FileInfoField from "components/atoms/FileInfoField";
import withFileService from "hocs/with-file-service";
import { humanizeFileSize } from "helpers";
import { fileUpload } from "services/fileService";
import { enqueueSnackbar } from "notistack";
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
} from "react-icons/io";
import { useSearchParams } from "react-router-dom";
import UploadedFileAsync from "components/molecules/UploadFileAsync";

const formScheme = yup.object({
  sprint_file: yup
    .string()
    .required()
    .label("File sprint yang Sudah di tandatangani"),
});

const MemberInGroupDisburstmentDetail = () => {
  const { id, disbursmentType } = useParams();
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    filter: {
      search: "",
      status: "",
      date: "",
    },
  });

  const queryClient = useQueryClient();
  const { data } = useQuery(
    ["GET_DISBURSEMENT_DETAIL", id, filters],
    getDisburstmentsDetail,
    {
      select: (data) => data?.data?.data,
      enabled: disbursmentType === "review",
    }
  );
  const { data: dataDirectorate } = useQuery(
    ["GET_DISBURSEMENT_DETAIL", id, filters],
    getDisburstmentsDirectorateDetail,
    {
      select: (data) => data?.data?.data,
      enabled: disbursmentType === "agreement",
    }
  );
  const mutateUpdatePencairan = useMutation({
    mutationFn: putDisburstmentsDetail,
  });
  const mutateUpdatePencairanDirectorate = useMutation({
    mutationFn: putDisburstmentsDirectorate,
  });

  const [searchParams] = useSearchParams();

  const isView =
    (disbursmentType === "review" &&
      searchParams.get("status") !== "Belum Direview") ||
    (disbursmentType === "agreement" &&
      searchParams.get("status") !== "Direview Direktur Penyaluran Dana");

  const methods = useForm({ resolver: yupResolver(formScheme) });

  const onSubmit = (data) => {
    const payload = {
      id: id,
      data,
    };

    mutateUpdatePencairan.mutate(payload, {
      onSuccess: (res) => {
        enqueueSnackbar({
          variant: "success",
          message: "Pencairan berhasil disubmit",
        });
        queryClient.invalidateQueries();
        navigate(-1);
      },
      onError: (err) => {
        enqueueSnackbar({
          variant: "error",
          message: "Pencairan gagal disubmit",
        });
      },
    });
  };

  const onSubmitDirectorate = (status) => {
    mutateUpdatePencairanDirectorate.mutate(
      {
        id: id,
        params: { is_approve: status },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Data yang Anda masukan sudah berhasil dikirim.",
          });
          queryClient.invalidateQueries();
          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({
            variant: "error",
            message: "Data yang Anda masukan gagal dikirim.",
          });
        },
      }
    );
  };

  useEffect(() => {
    if (data) {
      methods.reset({
        sprint_file: data?.sprint_file,
      });
    }
  }, [data, methods]);

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: "Permohonan Pencairan",
            path: "/member-in-group-disburstment",
          },
          {
            label: "Perorangan dalam Kelompok",
            path: "/member-in-group-disburstment",
          },
          {
            label: "Daftar Anggota",
            path: `/member-in-group-disburstment/${id}`,
          },
        ]}
      />
      <ProfileSection
        data={disbursmentType === "review" ? data : dataDirectorate}
      />
      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <ListFilter filters={filters} setFilters={setFilters} />
        <ListTable
          data={data?.applicant}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
      <FormProvider {...methods}>
        <UploadSignedForm />
      </FormProvider>

      {disbursmentType === "review" ? (
        <div className="sticky bottom-4 flex justify-between w-full p-5 bg-white border rounded-md">
          <Button
            className="p-4 border rounded-lg"
            type="button"
            label={
              <div className="flex items-center gap-2 text-sm font-medium">
                <RiArrowLeftLine />
                Kembali
              </div>
            }
            onClick={() => navigate(-1)}
          />
          {!isView && (
            <>
              {data?.applicant?.data.every(
                (val) => val.status_string !== "Belum Direview"
              ) && (
                <ModalConfirm
                  type="approve"
                  onSubmit={methods.handleSubmit(onSubmit)}
                />
              )}
            </>
          )}
        </div>
      ) : (
        <div className="sticky bottom-4 flex justify-between w-full p-5 bg-white border rounded-md">
          <Button
            className="p-4 border rounded-lg"
            type="button"
            label={
              <div className="flex items-center gap-2 text-sm font-medium">
                <RiArrowLeftLine />
                Kembali
              </div>
            }
            onClick={() => navigate(-1)}
          />

          {!isView && (
            <div className="flex gap-2">
              <ModalConfirm
                type="reject"
                onSubmit={() => onSubmitDirectorate(false)}
              />
              <ModalConfirm
                type="approve"
                onSubmit={() => onSubmitDirectorate(true)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const ListFilter = ({ filters, setFilters }) => {
  const stateOptions = useMemo(
    () => [
      { value: "Terverifikasi", label: "Terverifikasi" },
      { value: "Ditolak ", label: "Ditolak " },
    ],
    []
  );

  return (
    <form className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end">
      <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
        <RiSearchLine className="text-gray-400 text-xl" />

        <Input
          className="w-full bg-white border-none"
          onChange={debounce(
            (e) =>
              setFilters((prev) => ({
                ...prev,
                page: 1,
                filter: {
                  ...prev.filter,
                  search: e.target.value,
                },
              })),
            1000
          )}
          placeholder="Cari"
        />
      </div>
      <Select
        className="w-full rounded-md h-10 absolute"
        options={stateOptions}
        placeholder="Status"
        styles={customStyles}
        isClearable
        onChange={debounce(
          (e) =>
            setFilters((prev) => ({
              ...prev,
              page: 1,
              filter: {
                ...prev.filter,
                status: e.value,
              },
            })),
          1000
        )}
      />
      <div className="relative">
        <ReactDatePicker
          className={
            "date_picker w-full !outline-none !focus:border-primary-700 border h-10 px-3 focus:border-primary-700 rounded-md"
          }
          selected={filters.filter.date}
          onChange={debounce(
            (date) =>
              setFilters((prev) => ({
                ...prev,
                page: 1,
                date: date,
              })),
            1000
          )}
          placeholderText={"dd/mm/yyyy"}
          showYearDropdown={true}
        />
        {filters.filter.date !== "" && (
          <RiCloseLine
            className="absolute top-3 right-2 text-gray-400 hover:text-gray-600 cursor-pointer text-lg"
            onClick={() =>
              setFilters((prev) => ({ ...prev, page: 1, date: "" }))
            }
          />
        )}
      </div>
    </form>
  );
};

const stateColors = {
  "Belum Direview": "warning",
  Baru: "bluelight",
  Draft: "default",
  Disetujui: "success",
  Ditolak: "error",
  Terverifikasi: "success",
};

const ListTable = ({ data, filters, setFilters }) => {
  const navigate = useNavigate();
  const { id, disbursmentType } = useParams();

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "name",
        title: "Nama",
      },

      {
        key: "status_string",
        title: "Status",
        render: ({ item }) => (
          <Badge color={stateColors[item.status_string]}>
            {item.status_string}
          </Badge>
        ),
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <>
            {["belum direview"].includes(toLower(item.status_string)) ? (
              <Button
                theme="primary"
                label="Verifikasi"
                onClick={() => {
                  navigate(
                    `/member-in-group-disburstment/${disbursmentType}/${id}/member/${item.id}?status=${item?.status_string}`
                  );
                }}
              />
            ) : (
              <Button
                variant="icon"
                label={<RiEyeLine className="text-lg" />}
                onClick={() => {
                  navigate(
                    `/member-in-group-disburstment/${disbursmentType}/${id}/member/${item.id}?status=${item?.status_string}`
                  );
                }}
              />
            )}
          </>
        ),
      },
    ];
  }, [navigate, id, disbursmentType]);

  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={data?.data} isLoading={false} />
      <Pagination
        page={data?.meta?.current_page ?? 1}
        limit={data?.meta?.per_page ?? 10}
        total={data?.meta?.total ?? 1}
        totalPage={data?.meta?.last_page ?? 1}
        onLimitChange={(e) =>
          setFilters((prev) => ({
            ...prev,
            page: 1,
            limit: e,
          }))
        }
        onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
      />
    </TableWrapper>
  );
};

const ProfileSection = ({ data }) => {
  return (
    <CardForm label="Detail Permohonan">
      <div className="grid grid-cols-4">
        <div>
          <p className="font-semibold">Nama Kelompok</p>
          <p>{data?.group_name}</p>
        </div>
        <div>
          <p className="font-semibold">Nama Ketua</p>
          <p>{data?.leader_name}</p>
        </div>
        <div>
          <p className="font-semibold">
            Tanggal Pengajuan Permohonan Pencairan
          </p>
          <p>{dayjs(data?.created_at).format("DD MMMM YYYY")}</p>
        </div>
        <div>
          <p className="font-semibold">Total Anggota Diajukan</p>
          <p>
            {data?.total_applicant ? `${data?.total_applicant} Orang` : "-"}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <div>
          <UploadedFileAsync id={data?.file} />
        </div>
      </div>
    </CardForm>
  );
};

const UploadSignedForm = withFileService(
  ({ getFileFromService, uploadFile }) => {
    const params = useParams();
    const methods = useFormContext();

    const sprintField = useWatch({
      control: methods.control,
      name: "sprint_file",
    });

    const [sprintFile, setSprintFile] = useState();

    const sprintFileMutation = useMutation(async (file) => {
      try {
        const response = await fileUpload(file);
        setSprintFile(response.data.data);
        methods.setValue("sprint_file", response.data.data.id);
      } catch (error) {
        throw error;
      }
    });

    useEffect(() => {
      if (
        typeof sprintField === "undefined" ||
        typeof sprintField === "object" ||
        sprintField === ""
      )
        return;
      getFileFromService(sprintField, {
        onSuccess: (res) => {
          setSprintFile(res);
        },
        onError: (err) => {},
      });
    }, [sprintField, getFileFromService]);

    return (
      <CardForm label="Upload Sprint yang Sudah di Tandatangani">
        <div className="w-full">
          {sprintFile ? (
            <FileInfoField
              title={sprintFile?.name}
              desc={humanizeFileSize(sprintFile?.size)}
              showIcon
              onClose={
                params?.agreementType === "review"
                  ? () => {
                      setSprintFile(undefined);
                      methods.setValue("sprint_file", null);
                    }
                  : null
              }
              showDownload
              onDownloadClick={() => window.open(sprintFile.url, "_blank")}
              labelDownload="Lihat Dokumen"
            />
          ) : (
            <InputFormUpload
              controllerName={`sprint_file`}
              label={"Upload Sprint yang Sudah di Tandatangani"}
              uploadFile={sprintFileMutation}
            />
          )}
        </div>
      </CardForm>
    );
  }
);

const ModalConfirm = ({ type, onSubmit }) => {
  const params = useParams();

  return (
    <Modal
      trigger={
        type === "approve" ? (
          <Button
            theme="primary"
            className="p-4 border rounded-lg"
            label={
              params.disbursmentType === "review" ? (
                <div className="flex items-center gap-2">Submit</div>
              ) : (
                <div className="flex items-center gap-2">
                  <IoMdCheckmarkCircleOutline />
                  Terima Verifkasi
                </div>
              )
            }
          />
        ) : (
          <Button
            theme={"error"}
            className="p-4 border rounded-lg"
            label={
              <div className="flex items-center gap-2">
                <IoMdCloseCircleOutline />
                Tolak Verifkasi
              </div>
            }
          />
        )
      }
    >
      <ModalHeader
        noBorder
        hideClose
        title={
          <div className="w-12 h-12 rounded-full flex items-center justify-center text-orange-700 bg-orange-100 border-4 border-orange-50">
            <RiInformationLine className="text-base" size={24} />
          </div>
        }
      />
      <ModalBody className="space-y-4">
        <p className="text-lg font-semibold">
          {type === "approve" ? "Kirim Persetujuan" : "Tolak Persetujuan"}
        </p>
        {type === "approve" ? (
          <div className="text-primary-25">
            Anda yakin akan mengirimkan persetujuan verifikasi data untuk
            Pencairan Ini?, Data Ini Akan Dikirimkan Ke Direktur Penyaluran
            Untuk Di Review Setelah Anda Menyetujuinya
          </div>
        ) : (
          <div className="text-primary-25">
            Anda yakin akan menolak persetujuan verifikasi data untuk Pencairan
            Ini?, Data Ini Tidak Akan Dikirimkan Ke Direktur Penyaluran Untuk Di
            Review Setelah Anda Menyetujuinya
          </div>
        )}
        <div className="grid grid-cols-2 gap-4 !mt-6">
          <ModalClose>
            <Button label="Tidak" theme="secondary" block className="w-full" />
          </ModalClose>
          <Button
            label={"Ya"}
            theme="primary"
            block
            className="w-full"
            onClick={onSubmit}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MemberInGroupDisburstmentDetail;
