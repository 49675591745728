import { useQuery } from "@tanstack/react-query";
import { Input } from "components";
import { ModalBody, ModalHeader } from "components/atoms/Modal";
import Table, { TableWrapper } from "components/atoms/Table";
import { Pagination } from "components/v2/shared/pagination";
import { debounce } from "lodash";
import { useMemo, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { getListRisalahByMeetingAgendaId } from "services/fdb/committee";

export const RisalahModal = ({ meetingId }) => {
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    filter: {
      search: "",
    },
  });

  const { data } = useQuery(
    ["GET_LIST_RISALAH_BY_MEETING_ID", meetingId, filters],
    getListRisalahByMeetingAgendaId,
    { enabled: !!meetingId, select: (data) => data?.data?.data }
  );

  const handleSearch = debounce((e) => {
    setFilters((prev) => ({
      ...prev,
      filter: { ...prev.filter, search: e.target.value },
    }));
  }, 500);

  const headers = useMemo(
    () => [
      {
        key: "index",
        title: "No",
        render: ({ index }) => {
          // const no =
          //   parseInt(data?.meta?.current_page) *
          //     parseInt(data?.meta?.per_page) -
          //   parseInt(data?.meta?.per_page) +
          //   index +
          //   1;

          const no = index + 1;

          return no;
        },
      },
      {
        key: "number",
        title: "Nama Risalah",
      },
      {
        key: "group_name",
        title: "Nama Kelompok",
      },
      {
        key: "total_applicant",
        title: "Jumlah Debitur",
        render: ({ item }) => (
          <p>{item.total_applicant ? `${item.total_applicant} Orang` : "-"}</p>
        ),
      },
      {
        key: "total_rejected",
        title: "Debitur Ditolak",
        render: ({ item }) => (
          <p>{item.total_rejected ? `${item.total_rejected} Orang` : "-"}</p>
        ),
      },
      {
        key: "total_approved",
        title: "Debitur Diterima",
        render: ({ item }) => (
          <p>{item.total_approved ? `${item.total_approved} Orang` : "-"}</p>
        ),
      },
      {
        key: "committee_decision",
        title: "Keputusan Komite",
        render: ({ item }) => <p>{item.committee_decision ?? "-"}</p>,
      },
    ],
    []
  );

  return (
    <>
      <ModalHeader hideClose>Daftar Risalah</ModalHeader>
      <ModalBody className="space-y-5">
        <div className="relative">
          <Input placeholder="Cari" className="!px-5" onChange={handleSearch} />
          <RiSearchLine
            size={20}
            className="absolute top-[10px] left-2 text-gray-400"
          />
        </div>

        <TableWrapper>
          <Table headers={headers} items={data?.data ?? []} />
          <Pagination
            page={data?.meta?.current_page ?? 1}
            limit={filters.limit}
            total={data?.meta?.total ?? 0}
            totalPage={data?.meta?.last_page ?? 1}
            onLimitChange={(e) => setFilters((prev) => ({ ...prev, limit: e }))}
            onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
          />
        </TableWrapper>
      </ModalBody>
    </>
  );
};
