import BreadCrumbs from "components/molecules/Breadcrumbs";
import React, { Fragment, useState } from "react";

import { enqueueSnackbar } from "notistack";
import { Spinner } from "components";
import { useNavigate, useParams } from "react-router";

import { TabsList, TabsRoot, TabsTrigger } from "components/atoms/Tabs";
import { useMutation, useQuery } from "@tanstack/react-query";

import RequestInformation from "../../DeskAnalyst/Detail/RequestInformation";
import Interview from "./Interview";
import FieldResult from "./FieldResult";
import {
  getDetailFieldAnalyst,
  postDetailFieldAnalyst,
} from "services/institutions/field-analyst";
import { getErrorMessage } from "helpers";

const DetailFieldAnalyst = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [activeTab, setActiveTab] = useState("information");
  const [bodyToSend, setBodyToSend] = useState({
    fieldTrip: {},
    interview: {},
    fieldResult: {},
  });

  // const isLoading = false;
  //   const { data, isLoading } = useQuery(
  //     ["GET DETAIL DESK ANALIS PELAKSANA MEMBER", id],
  //     ({ queryKey }) => getDeskAnalystPelaksanaDetailMember(queryKey[1]),
  //   );

  const postDetailFieldAnalystCB = useMutation((payload) => {
    const { is_draft, agency_field_analyst_id, ...rest } = payload;
    return postDetailFieldAnalyst({ is_draft, agency_field_analyst_id }, rest);
  });

  const [filters, setFilters] = useState({
    filter: {
      page_potention: 1,
      limit_potention: 10,
    },
  });

  const { data, isLoading } = useQuery(
    ["GET_DETAIL_FIELD_ANALYST", id, filters],
    getDetailFieldAnalyst,
    { select: (data) => data?.data?.data }
  );

  const handleBackButton = (values) => {
    if (activeTab === "information") {
      navigate(-1);
    }

    if (activeTab === "interview") {
      setBodyToSend((curr) => ({
        ...curr,
        interview: { ...curr.interview, ...values },
      }));
      setActiveTab("information");
    }

    if (activeTab === "field-result") {
      setBodyToSend((curr) => ({
        ...curr,
        fieldResult: { ...curr.fieldResult, ...values },
      }));
      setActiveTab("interview");
    }
  };

  const handleSubmit = (payload) => {
    if (activeTab === "information") {
      setBodyToSend((curr) => ({
        ...curr,
        fieldTrip: { ...curr.fieldTrip, ...payload },
      }));
      setActiveTab("interview");
      return;
    } else if (activeTab === "interview") {
      setBodyToSend((curr) => ({
        ...curr,
        interview: { ...curr.interview, ...payload },
      }));
      setActiveTab("field-result");
      return;
    } else {
      setBodyToSend((curr) => ({
        ...curr,
        fieldResult: { ...curr.fieldResult, ...payload },
      }));
      const data = {
        ...bodyToSend?.information,
        ...payload,
        ...bodyToSend?.interview,
        field_date: new Date(payload?.field_date),
        is_draft: false,
        agency_field_analyst_id: id,
      };
      if (data?.file_temp) delete data?.file_temp;
      if (data?.format_potention_file_temp)
        delete data?.format_potention_file_temp;
      if (data?.collateral_meet_recruitment)
        delete data?.collateral_meet_recruitment;
      // if (data?.interview_file_temp)
      // data.interview_file_temp = data?.interview_file_temp?.id;

      postDetailFieldAnalystCB.mutate(data, {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ fontWeight: "bold" }}>
                  Data berhasil disimpan!
                </span>
                <span>Data yang Anda masukkan berhasil dikirim</span>
              </div>
            ),
          });
          navigate(`/institutions/field-analyst`);
        },
        onError: (error) => {
          enqueueSnackbar({
            message: getErrorMessage(error),
            variant: "error",
          });
        },
      });
    }
  };

  const initialInterview = {
    questions1: [
      {
        id: 1,
        question: "Bagaimana siklus usaha dari penjualan pemohon?",
        answer: "lorem ipsum dolor sit amet",
        verification: true,
        verification_key: "other_sales_business_cycle_verification",
      },
    ],
    questions2: [
      {
        id: 2,
        question: "Bagaimana siklus usaha dari penjualan pemohon?",
        answer: "lorem ipsum dolor sit amet",
        verification: true,
        verification_key: "other_sales_business_cycle_verification",
      },
    ],
    questions3: [
      {
        id: 3,
        question: "Bagaimana siklus usaha dari penjualan pemohon?",
        answer: "lorem ipsum dolor sit amet",
        verification: true,
        verification_key: "other_sales_business_cycle_verification",
      },
    ],
    questions4: [
      {
        id: 4,
        question: "Bagaimana siklus usaha dari penjualan pemohon?",
        answer: "lorem ipsum dolor sit amet",
        verification: true,
        verification_key: "other_sales_business_cycle_verification",
      },
    ],
    questions5: [
      {
        id: 5,
        question: "Bagaimana siklus usaha dari penjualan pemohon?",
        answer: "lorem ipsum dolor sit amet",
        verification: true,
        verification_key: "other_sales_business_cycle_verification",
      },
    ],
  };

  return (
    <div className="space-y-6 mb-40">
      <BreadCrumbs
        routes={[
          {
            label: "Permohonan Lembaga Penyalur",
            path: "/institutions/field-analyst",
          },
          {
            label: "Detail",
            path: `/institutions/field-analyst/${id}`,
          },
        ]}
      />
      {isLoading ? (
        <div className="flex justify-center p-4">
          <Spinner theme={"primary"} />
        </div>
      ) : (
        <Fragment>
          <TabsRoot value={activeTab} onValueChange={setActiveTab}>
            <TabsList>
              <TabsTrigger value="information">Kunjungan Lapangan</TabsTrigger>
              <TabsTrigger value="interview">Wawancara</TabsTrigger>
              <TabsTrigger value="field-result">Hasil Field</TabsTrigger>
            </TabsList>
            <RequestInformation
              data={data}
              onBack={handleBackButton}
              onSubmit={handleSubmit}
              changePagePotention={(e) =>
                setFilters((prev) => ({
                  filter: {
                    ...prev,
                    page_potention: e,
                  },
                }))
              }
              changeLimitPotention={(e) =>
                setFilters((prev) => ({
                  filter: {
                    ...prev,
                    limit_potention: e,
                  },
                }))
              }
              filterTable={filters.filter}
            />
            <Interview
              data={data?.recomendation}
              onBack={handleBackButton}
              onSubmit={handleSubmit}
              initialValues={initialInterview}
            />
            <FieldResult
              data={data?.recomendation}
              onBack={handleBackButton}
              onSubmit={handleSubmit}
            />
          </TabsRoot>
        </Fragment>
      )}
    </div>
  );
};

export default DetailFieldAnalyst;
