import { InputFormUpload } from "components";
import CardForm from "components/molecules/CardForm";
import UploadedFileAsync from "components/molecules/UploadFileAsync";
import withFileService from "hocs/with-file-service";
import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

const UploadDocs = withFileService(({ uploadFile }) => {
  const methods = useFormContext();

  const fileField = useWatch({
    control: methods.control,
    name: "assessment_file",
  });

  useEffect(() => {
    if (fileField instanceof File) {
      uploadFile(fileField, {
        onSuccess: (res) => {
          methods.setValue("assessment_file", res.data.data.id);
        },
        onError: (err) => console.log(err),
      });
      return;
    }
  }, [fileField]);

  return (
    <>
      <CardForm label="Upload Dokumen Pendukung">
        {/* <p className="font-semibold">Upload Dokumen Pendukung</p> */}
        {typeof fileField === "string" ? (
          <UploadedFileAsync id={fileField} />
        ) : (
          <InputFormUpload
            controllerName={`assessment_file`}
            label={"Upload Dokumen Pendukung"}
            uploadFile={() => {}}
          />
        )}
      </CardForm>
    </>
  );
});

export default UploadDocs;
