import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { Controller, useFormContext } from "react-hook-form";
import { RiCalendarLine, RiCloseLine } from "react-icons/ri";

const ExampleCustomInput = forwardRef(
  (
    {
      onClick,
      value,
      className,
      asText,
      asTextClass,
      placeholder,
      rangeYear,
      getValues,
      setValue,
      disabled,
    },
    ref
  ) => {
    return asText ? (
      <div
        className={[
          "min-h-[2.5rem] py-2 overflow-hidden text-ellipsis",
          asTextClass,
        ].join(" ")}
      >
        {value}
      </div>
    ) : (
      <div className="relative">
        <input
          className={`input w-full text-left bg-white p-2 mt-2 rounded-lg border ${className} disabled:bg-[#f4f5f2] disabled:text-gray-400`}
          ref={ref}
          onClick={onClick}
          disabled={disabled}
          placeholder={placeholder}
          value={
            rangeYear && value !== ""
              ? `${new Date(value).getFullYear() - 2}-${new Date(
                  value
                ).getFullYear()}`
              : value
          }
        />
        {value && !disabled ? (
          <>
            <RiCloseLine
              type="button"
              className="absolute top-[55%] right-3 -translate-y-1/2 text-gray-400 h-6 w-6"
              onClick={() => setValue("date", "")}
            >
              Clear
            </RiCloseLine>
          </>
        ) : (
          <RiCalendarLine className="absolute top-[55%] right-3 -translate-y-1/2 text-gray-400 h-6 w-6" />
        )}
      </div>
    );
  }
);

function range(start, end, step) {
  const result = [];
  for (let i = start; i <= end; i += step) {
    result.push(i);
  }
  return result;
}

function getYear(date) {
  return date.getFullYear();
}

const AtomDatePicker = ({
  controllerName,
  startDate = new Date(),
  setStartDate,
  isError = false,
  label = "label",
  required = false,
  showErrorLabel = false,
  datepickerOptions = {},
  className,
  append,
  prepend,
  placeholder,
  wrapperClass,
  asText = false,
  isRangeDatePicker = false,
  asTextClass,
  selectsRange,
  newDate,
  format = null,
  rangeYear = false,
  customPicker = false,
  disabled,
  defaultValue,
  onChangeDate,
  ...props
}) => {
  const [customStartDate, setCustomStartDate] = React.useState("");
  const { control, getValues, setValue } = useFormContext();
  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <Controller
      name={controllerName}
      control={control}
      rules={{ required }}
      defaultValue={defaultValue ?? undefined}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => {
        const datePickerCommonStyles = `${
          className || ""
        } focus:!outline-none ${
          invalid ? "!border-[#F04438]" : "focus:!border-primary-700"
        }`;
        return (
          <div className={["form-control w-full", wrapperClass].join(" ")}>
            <label className="label font-semibold text-sm ">
              <span className={`label-text`}>{label}</span>
              {required && <span className="text-[#F04438]">*</span>}
            </label>
            <div className={`relative`}>
              {prepend}

              {!customPicker ? (
                <>
                  {isRangeDatePicker ? (
                    <DatePicker
                      wrapperClassName={`date_picker w-full ${datePickerCommonStyles}`}
                      className={datePickerCommonStyles}
                      onChange={(date) => {
                        onChange(date);
                        if (onChangeDate) onChangeDate(date);
                      }}
                      customInput={
                        <ExampleCustomInput
                          asText={asText}
                          asTextClass={asTextClass}
                          disabled={disabled}
                        />
                      }
                      placeholderText={placeholder}
                      disabled={asText || disabled}
                      {...datepickerOptions}
                      selected={value?.[0]}
                      startDate={value?.[0]}
                      endDate={value?.[1]}
                      selectsRange
                    />
                  ) : (
                    <DatePicker
                      selected={
                        newDate
                          ? value === "" ||
                            value === undefined ||
                            value === null
                            ? undefined
                            : new Date(value)
                          : value
                      }
                      wrapperClassName={`date_picker w-full ${datePickerCommonStyles}`}
                      className={datePickerCommonStyles}
                      onChange={(date) => {
                        onChange(date);
                        if (onChangeDate) onChangeDate(date);
                      }}
                      customInput={
                        <ExampleCustomInput
                          asText={asText}
                          asTextClass={asTextClass}
                          rangeYear={rangeYear}
                          getValues={getValues}
                          setValue={setValue}
                          disabled={disabled}
                        />
                      }
                      placeholderText={placeholder}
                      disabled={asText || disabled}
                      dateFormat={format || "MM/dd/yyyy"}
                      showYearPicker={format === "yyyy"}
                      showYearDropdown={true}
                      {...datepickerOptions}
                    />
                  )}
                </>
              ) : (
                <DatePicker
                  className={`date_picker w-full ${datePickerCommonStyles}`}
                  wrapperClassName={`date_picker w-full ${datePickerCommonStyles}`}
                  customInput={
                    <ExampleCustomInput
                      asText={asText}
                      asTextClass={asTextClass}
                      rangeYear={rangeYear}
                      disabled={disabled}
                    />
                  }
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      className="w-full"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        {"<"}
                      </button>
                      <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => changeYear(value)}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        value={months[date.getMonth()]}
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        {">"}
                      </button>
                    </div>
                  )}
                  selected={
                    newDate
                      ? value === "" || value === undefined || value === null
                        ? undefined
                        : new Date(value)
                      : value
                  }
                  onChange={(date) => {
                    onChange(date);
                    if (onChangeDate) onChangeDate(date);
                  }}
                  disabled={asText || disabled}
                />
              )}

              {append}
            </div>
            {invalid && showErrorLabel && (
              <label className="label">
                <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
                  {error?.message}
                </div>
              </label>
            )}
          </div>
        );
      }}
    />
  );
};

export default AtomDatePicker;
