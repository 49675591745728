/* eslint-disable no-template-curly-in-string */
import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";
import { find } from "lodash";
import * as yup from "yup";

export const validationSchemaExecutor = yup.object({
  submission_month: yup
    .bool()
    .transform((val) => (typeof val === "boolean" ? val : undefined))
    .required()
    .label("Opsi Ini"),
  request_letter: yup
    .bool()
    .transform((val) => (typeof val === "boolean" ? val : undefined))
    .required()
    .label("Opsi Ini"),
  nominative_recipients: yup
    .bool()
    .transform((val) => (typeof val === "boolean" ? val : undefined))
    .required()
    .label("Opsi Ini"),
  first_disbursement: yup
    .bool()
    .transform((val) => (typeof val === "boolean" ? val : undefined))
    .required()
    .label("Opsi Ini"),
  fdb_loan_disbursement: yup
    .bool()
    .transform((val) => (typeof val === "boolean" ? val : undefined))
    .required()
    .label("Opsi Ini"),
  approved_amount: yup
    .bool()
    .transform((val) => (typeof val === "boolean" ? val : undefined))
    .required()
    .label("Opsi Ini"),
  disbursement_balance: yup
    .bool()
    .transform((val) => (typeof val === "boolean" ? val : undefined))
    .required()
    .label("Opsi Ini"),
  nominative_list_request: yup
    .bool()
    .transform((val) => (typeof val === "boolean" ? val : undefined))
    .required()
    .label("Opsi Ini"),
  is_continue: yup
    .bool()
    .transform((val) => (typeof val === "boolean" ? val : undefined))
    .required()
    .label("Opsi Ini"),
  notes: yup.string().required().label("Catatan Tambahan"),
  sprint_file_temp: yup
    .mixed()
    .test(
      "cid_img",
      ({ label }) =>
        validationErrorMsg.mixed.required.replace("${path}", label),
      (value, context) => {
        const allowedTypes = [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        if (value) {
          const data = find(allowedTypes, (at) => value.type.includes(at));
          if (!data) {
            return context.createError({
              message: validationCustomErrorMsg.isFiletype("PDF, DOCX")({
                label: "Dokumen Pendukung Risalah",
              }),
            });
          }
          if (value.size > 10 * 1024 * 1024) {
            return context.createError({
              message: validationCustomErrorMsg.isFileSize("10MB")({
                label: "Dokumen Pendukung Risalah",
              }),
            });
          }
          return true;
        }
        // otherwise, return false to render the field required
        return false;
      }
    )
    .label("Dokumen Pendukung Risalah"),
});
