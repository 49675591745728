import dayjs from "dayjs";
import { getFile } from "services/fileService";

export const API = async ({ params, service }, callback) => {
  try {
    const response = await service(params);
    if (response?.data?.meta?.success) {
      callback?.(true, response?.data);
    } else {
      callback?.(false, response?.data);
    }
    return response;
  } catch (error) {
    callback?.(false, error?.response);
    throw error;
  }
};

export const getErrorMessage = (error) => {
  let message =
    error?.response?.data?.data?.message ??
    error?.response?.data?.meta?.message ??
    error?.response?.data?.message ??
    error?.response?.message ??
    error?.message ??
    "Something Went Wrong";
  return message;
};

export const paginate = (array, page_size, page_number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

export function humanizeFileSize(bytes, fractionDigit = 1) {
  const divider = 1024;

  if (bytes < divider) return `${bytes} B`;

  const units = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  let unitIdx = -1;
  let size = bytes;

  do {
    size = size / divider;
    ++unitIdx;
  } while (size > divider && unitIdx < units.length - 1);

  return `${size.toFixed(fractionDigit)} ${units[unitIdx]}`;
}

export const formatCurrency = (amount, fractionDigits = undefined) => {
  try {
    if (isNaN(amount)) {
      return "";
    }

    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      maximumFractionDigits: fractionDigits,
    }).format(amount);
  } catch (err) {
    return "";
  }
};

export const formatNumber = (amount, fractionDigits = undefined) => {
  try {
    return new Intl.NumberFormat("id-ID", {
      maximumFractionDigits: fractionDigits,
    }).format(amount);
  } catch (err) {
    return "";
  }
};

export function convertCurrencyToFloat(
  formattedCurrency,
  locale = "id-ID",
  currency = "IDR"
) {
  const instance = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  });

  // we need to determine the currency, group and decimal applied by formatting a reference value to get the parts.
  const referenceVal = instance.formatToParts(1000000.245);
  const currencyVal = referenceVal.find((e) => e.type === "currency");
  const groupVal = referenceVal.find((e) => e.type === "group");
  const decimalVal = referenceVal.find((e) => e.type === "decimal");

  // remove currency and group from target value and change the decimal to `.`
  const stage1 = formattedCurrency.replaceAll(currencyVal?.value, "");
  const stage2 = stage1.replaceAll(groupVal?.value, "");
  const stage3 = stage2.replaceAll(decimalVal?.value, ".");

  return stage3;
}

/**
 * Convert a currency string to a number.
 *
 * @example unmask('Rp 1.000.000,00') // 1000000.00
 */
export function currencyToNumber(value) {
  return Number(String(value).replace(/[Rp\s?\.]/g, ""));
}

/**
 * Convert a number to a currency string.
 *
 * @example mask(1000000.00) // 'Rp 1.000.000,00'
 */
export function numberToCurrency(val) {
  if (!val) return "";

  const numericString = String(currencyToNumber(val));
  const sub =
    3 -
    (numericString.includes(".")
      ? numericString.length - numericString.indexOf(".")
      : 0);

  return formatCurrency(numericString).slice(0, sub ? -sub : undefined);
}

export const formattedDate = (date) => {
  return dayjs(date).format("DD MMMM YYYY");
};

export const downloadFile = async (id, filename) => {
  try {
    const { data } = await getFile(id);

    const downloadLink = window.document.createElement("a");
    downloadLink.target = "_blank";
    downloadLink.href = data?.data?.url;
    downloadLink.download = filename ?? data?.data?.name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  } catch (error) {
    console.log("error: ", error);
  }
};
