import React, { useCallback, useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { TabsContent } from "components/atoms/Tabs";
import { BottomFormAction } from "components";
import { FormProvider, useForm } from "react-hook-form";
import LandDocuments from "./NPS/LandDocuments";
import DataAssurance from "./NPS/DataAssurance";
import ControlledBusinessProspects from "./NPS/ControlledBusinessProspect";
import OtherBusiness from "./NPS/OtherBusiness";
import { validationSchema } from "./NPS/validation-schema";
import { validationSchemaPS } from "./PS/validation-schema-ps";
import Bussiness from "./PS/Bussiness";
import Prospect from "./PS/Prospect";
import Request from "./PS/Request";

export const ApplicationFormContent = ({
  initialValues,
  type,
  onBack,
  onNext,
  onSaveDraft,
  hideDraft,
}) => {
  const groupType = useMemo(() => {
    return (
      initialValues?.submission_application?.type?.toLowerCase() ||
      "non perhutanan sosial"
    );
  }, [initialValues]);

  const methods = useForm({
    resolver: yupResolver(
      groupType === "non perhutanan sosial"
        ? validationSchema
        : validationSchemaPS
    ),
    mode: "onChange",
  });

  useEffect(() => {
    if (initialValues?.defaultValues) {
      methods.reset(initialValues?.defaultValues);
    }
  }, [methods, initialValues?.defaultValues]);

  const onFormSubmit = useCallback(
    (payload) => {
      onNext(payload);
    },
    [onNext]
  );

  const onDraft = useCallback(() => {
    onSaveDraft(methods.getValues());
  }, [methods, onSaveDraft]);

  const serviceType = useMemo(() => {
    let result = "deferred";
    if (initialValues?.request?.service_type?.toLowerCase() === "hasil hutan bukan kayu") {
      result = "non_timber_forest";
    } else if (initialValues?.request?.service_type?.toLowerCase() === "komoditas non kehutanan") {
      result = "non_forest_commodities";
    }
    return result;
  }, [initialValues]);

  return (
    <FormProvider key="request-form-content" {...methods}>
      <form
        key="request-form-content"
        id="request-form-content"
        name="request-form-content"
      >
        <TabsContent value="application-form" className="mb-40">
          {groupType === "non perhutanan sosial" && (
            <>
              <LandDocuments data={initialValues} type={type} />
              <ControlledBusinessProspects data={initialValues} />
              <OtherBusiness data={initialValues} />
              <DataAssurance
                type={type}
                data={initialValues?.request?.deferred || initialValues?.request?.non_timber_forest || initialValues?.request?.non_forest_commodities}
                serviceType={serviceType}
              />
            </>
          )}
          {groupType === "perhutanan sosial" && (
            <>
              <Bussiness data={initialValues} />
              <Prospect data={initialValues} />
              <Request data={initialValues} />
            </>
          )}
          <BottomFormAction
            backButtonAction={() => onBack(methods.getValues())}
            submitButtonProps={{
              type: "button",
            }}
            draftButtonProps={{
              type: "button",
            }}
            submitActionButton={() => methods.handleSubmit(onFormSubmit)()}
            disableDrafButton={false}
            drafButtonAction={onDraft}
            disableButtonSubmit={false}
            hideDraft={hideDraft}
            className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
          />
        </TabsContent>
      </form>
    </FormProvider>
  );
};

export default ApplicationFormContent;
