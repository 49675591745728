import { useQuery } from "@tanstack/react-query";
import { Button, Input, Spinner } from "components";
import Badge from "components/atoms/Badge";
import Table, { TableWrapper } from "components/atoms/Table";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import dayjs from "dayjs";
import { debounce, toLower } from "lodash";
import React, { useMemo, useState } from "react";
import { RiCloseLine, RiEyeLine, RiSearchLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { getDeskAnalystPelaksana } from "services/fdb/desk-analyst";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import { customStyles } from "components/atoms/Select";
import { Pagination } from "components/v2/shared/pagination";

const MemberInGroup = () => {
  const title = "Perorangan dalam Kelompok";

  const [filters, setFilters] = useState({
    search: "",
    status: "",
    date: "",
    page: 1,
    limit: 10,
    paginate: true,
  });

  const { data, status } = useQuery(
    ["GET DESK ANALIS PELAKSANA", filters],
    ({ queryKey }) => {
      return getDeskAnalystPelaksana(queryKey[1]);
    }
  );

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: "/member-in-group",
          },
        ]}
      />

      <h1 className="text-gray-900 text-2xl font-semibold leading-loose">
        {title}
      </h1>

      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <ListFilter filters={filters} setFilters={setFilters} />
        {status === "loading" ? (
          <div className="flex items-center justify-center w-full h-full">
            <Spinner />
          </div>
        ) : (
          <ListTable
            data={data?.data?.data}
            filters={filters}
            setFilters={setFilters}
          />
        )}
      </div>
    </div>
  );
};

const ListFilter = ({ filters, setFilters }) => {
  const stateOptions = useMemo(
    () => [
      { value: "1", label: "Baru" },
      { value: "2", label: "Sedang Dikerjakan" },
      { value: "3 ", label: "Selesai " },
    ],
    []
  );

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end">
      <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
        <RiSearchLine className="text-gray-400 text-xl" />

        <Input
          className="w-full bg-white border-none"
          onChange={debounce(
            (e) => setFilters((prev) => ({ ...prev, search: e.target.value })),
            1000
          )}
          placeholder="Cari"
        />
      </div>
      <Select
        className="w-full rounded-md h-10 absolute"
        options={stateOptions}
        placeholder="Status"
        styles={customStyles}
        isClearable
        onChange={debounce(
          (e) => setFilters((prev) => ({ ...prev, status: e?.value ?? "" })),
          1000
        )}
      />
      <div className="relative">
        <ReactDatePicker
          className={
            "date_picker w-full !outline-none !focus:border-primary-700 border h-10 px-3 focus:border-primary-700 rounded-md"
          }
          selected={filters.date}
          onChange={debounce(
            (date) =>
              setFilters((prev) => ({
                ...prev,
                page: 1,
                date: date,
              })),
            1000
          )}
          placeholderText={"dd/mm/yyyy"}
          showYearDropdown={true}
        />
        {filters.date !== "" && (
          <RiCloseLine
            className="absolute top-3 right-2 text-gray-400 hover:text-gray-600 cursor-pointer text-lg"
            onClick={() =>
              setFilters((prev) => ({ ...prev, page: 1, date: "" }))
            }
          />
        )}
      </div>
    </div>
  );
};

const stateColors = {
  "On Progress": "warning",
  Baru: "bluelight",
  Draft: "default",
  Ditolak: "error",
  Selesai: "success",
  verified: "success",
};

const categoryColors = {
  "Perhutanan Sosial": "error",
  "Non Perhutanan Sosial": "bluelight",
};

const ListTable = ({ data, filters, setFilters }) => {
  const navigate = useNavigate();

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => {
          const no =
            parseInt(data?.meta?.current_page) *
              parseInt(data?.meta?.per_page) -
            parseInt(data?.meta?.per_page) +
            index +
            1;

          return no;
        },
      },
      {
        key: "name",
        title: "Nama Kelompok",
        render: ({ item }) => <p>{item.group_profile.name}</p>,
      },
      {
        key: "date",
        title: "Tgl Registrasi",
        render: ({ item }) => {
          return dayjs(item?.registration_date).format("DD MMMM YYYY");
        },
      },
      {
        key: "type",
        title: "Tipe Kelompok",
        render: ({ item }) => (
          <Badge color={categoryColors[item.type]} variant="outlined">
            {item.type}
          </Badge>
        ),
      },

      {
        key: "status",
        title: "Status",
        render: ({ item }) => {
          return (
            <Badge color={stateColors[item.desk_status_string]}>
              {item.desk_status_string}
            </Badge>
          );
        },
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <Button
            variant="icon"
            label={<RiEyeLine className="text-lg" />}
            onClick={() => {
              navigate(`/member-in-group/${item.id}`);
            }}
          />
        ),
      },
    ];
  }, [navigate, data?.meta]);

  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={data?.data ?? []} />
      <Pagination
        page={data?.meta?.current_page}
        limit={filters.limit}
        total={data?.meta?.total}
        totalPage={data?.meta?.last_page}
        onLimitChange={(e) => setFilters((prev) => ({ ...prev, limit: e }))}
        onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
      />
    </TableWrapper>
  );
};

export default MemberInGroup;
