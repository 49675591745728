import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getListMeetingAgenda = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-committee-meeting-agenda`,
    { params: queryKey[1] }
  );
};

export const getDetailMeetingAgenda = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-committee-meeting-agenda/${queryKey[1]}`
  );
};

export const postMeetingAgenda = (data) => {
  return axiosInstance.post(
    `${general_config_v2.BASE_URL}/v1/cms/agency-committee-meeting-agenda`,
    data
  );
};
