import CardForm from "components/molecules/CardForm";
import UploadedFileAsync from "components/molecules/UploadFileAsync";
import dayjs from "dayjs";

import Badge from "components/atoms/Badge";
import { formatCurrency } from "helpers";

const DebtorContent = ({ data }) => {
  return (
    <CardForm label="Detail Calon Debitur">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div>
          <div className="font-semibold">Nomor NIK</div>
          <div>{data?.identity_number ?? "-"}</div>
        </div>
        <div>
          <div className="font-semibold">Nama Lengkap Sesuai KTP</div>
          <div>{data?.name ?? "-"}</div>
        </div>
        <div>
          <div className="font-semibold">Tempat Lahir</div>
          <div>{data?.place_of_birth ?? "-"}</div>
        </div>
        <div>
          <div className="font-semibold">Tanggal Lahir</div>
          <div>
            {data?.date_of_birth
              ? dayjs(data?.date_of_birth).format("DD MMMM YYYY")
              : "-"}
          </div>
        </div>
        <div>
          <div className="font-semibold">Usia Debitur Saat Permohonan</div>
          <div>{data?.debtor_age ? `${data?.debtor_age} Tahun` : "-"}</div>
        </div>
        <div>
          <div className="font-semibold">Masuk Usia Produktif</div>
          <div>
            {data?.productive_age ? (
              <Badge color="success">Masuk Usia Proktif</Badge>
            ) : (
              <Badge color="error">Tidak Masuk Usia Produktif</Badge>
            )}
          </div>
        </div>
        <div>
          <div className="font-semibold">Nomor Keluarga</div>
          <div>{data?.family_card_number ?? "-"}</div>
        </div>
        <div>
          <div className="font-semibold">Pekerjaan Utama</div>
          <div>{data?.main_job ?? "-"}</div>
        </div>
        {data?.phase ? (
          <div>
            <div className="font-semibold">{`Tahap ${data?.phase}`}</div>
            <div>{data?.value ? formatCurrency(data?.value) : "-"}</div>
          </div>
        ) : null}
      </div>
      <div className="grid grid-cols-2 gap-4">
        {data?.document &&
          [
            {
              desc: "KTP",
              file: data?.identity_file,
            },
            {
              desc: "Kartu Keluarga",
              file: data?.family_card_file,
            },
            ...data?.document,
          ].map((x, key) => (
            <UploadedFileAsync
              key={`debtor-document-${key}`}
              name={x?.desc}
              id={x?.file}
            />
          ))}
      </div>
    </CardForm>
  );
};

export default DebtorContent;
