import React, { useState, useEffect } from "react";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { TabsRoot, TabsList, TabsTrigger } from "components/atoms/Tabs";
import PortfolioTabContent from "./PortfolioTabContent";
import ProfileTabContent from "./ProfileTabContent";
import RequestTabContent from "./RequestTabContent";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { Spinner } from "components";
import { getVerificatorDetail } from "services/fdb/verificator";
import { useSearchParams } from "react-router-dom";

const GroupsDetail = () => {
  const title = "Daftar Registrasi Perorangan dalam Kelompok";
  const [activeTab, setActiveTab] = useState("profile");
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [verificationData, setVerificationData] = useState({});

  const { data, isLoading } = useQuery({
    queryKey: ["group-application-verification-detail", id],
    queryFn: async () => {
      const { data } = await getVerificatorDetail(id);
      return data;
    },
    onSuccess: (data) => {
      if (data) {
        const profile = data?.data?.group_profile;
        const activitiesArr = profile?.activities.map((item) => {
          return {
            group_profile_activitie_id: item?.id,
            data: {
              activity_category_id: item?.activity_category_id,
              description: item?.description,
            },
          };
        });
        const partnersArr = profile?.partners.map((item) => {
          return {
            group_profile_partner_id: item?.id,
            data: {
              name: item?.name,
            },
          };
        });
        const financeArr = profile?.finance_businesses.map((item) => {
          return {
            group_profile_finance_business_id: item?.id,
            data: {
              finance_business_category_id: item?.finance_business_category_id,
              description: item?.description,
            },
          };
        });
        setVerificationData({
          verification: {
            is_verified_group: profile?.is_verified_group,
            is_verified_address: profile?.is_verified_address,
            is_verified_group_manage: profile?.is_verified_group_manage,
            is_verified_portfolio: profile?.is_verified_portfolio,
            verified_group_desc: profile?.verified_group_desc,
            verified_address_desc: profile?.verified_address_desc,
            verified_group_manage_desc: profile?.verified_group_manage_desc,
            verified_portfolio_desc: profile?.verified_portfolio_desc,
          },
          data_updates: [
            {
              group_profile_id: profile?.id,
              data: {
                name: profile?.name,
                sk_number: profile?.sk_number,
                kups_name: profile?.kups_name,
                kups_number: profile?.kups_number,
                founding_date: new Date(profile?.founding_date),
                social_area_function_id: profile?.social_area_function_id,
                agreement_access: profile?.agreement_access,
                end_year_of_agreement_access: new Date(
                  profile?.end_year_of_agreement_access
                ),
                // profile?.end_year_of_agreement_access
                province_id: profile?.province_id,
                city_id: profile?.city_id,
                district_id: profile?.district_id,
                village_id: profile?.village_id,
                address: profile?.address,
                leader_name: profile?.leader_name,
                leader_identity: profile?.leader_identity,
                leader_phone: profile?.leader_phone,
                leader_gender: profile?.leader_gender,
                secretary_name: profile?.secretary_name,
                secretary_phone: profile?.secretary_phone,
                secretary_gender: profile?.secretary_gender,
                treasurer_name: profile?.treasurer_name,
                treasurer_phone: profile?.treasurer_phone,
                treasurer_gender: profile?.treasurer_gender,
                companion_affiliate_id: profile?.companion_affiliate_id,
                companion_name: profile?.companion_name,
                companion_phone: profile?.companion_phone,
                companion_gender: profile?.companion_gender,
                total_member_apply: profile?.total_member_apply,
                total_member_apply_area: profile?.total_member_apply_area,
                total_member_apply_persil: profile?.total_member_apply_persil,
                total_member_not_apply: profile?.total_member_not_apply,
                total_member_not_apply_area:
                  profile?.total_member_not_apply_area,
                total_member_not_apply_persil:
                  profile?.total_member_not_apply_persil,
              },
            },
          ],
          activities: activitiesArr,
          partners: partnersArr,
          finance_businesses: financeArr,
        });
      }
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setActiveTab(searchParams.get("tab") || "profile");
  }, [searchParams]);

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: "/groups",
          },
          {
            label: "Detail",
            path: `/groups/${id}`,
          },
        ]}
      />

      {isLoading ? (
        <div className="flex justify-center p-4">
          <Spinner theme={"primary"} />
        </div>
      ) : (
        <TabsRoot value={activeTab} onValueChange={setActiveTab}>
          <TabsList>
            <TabsTrigger value="profile">Profil Umum Kelompok</TabsTrigger>
            <TabsTrigger value="portfolio">Portofolio Kelompok</TabsTrigger>
            <TabsTrigger value="requests">
              Daftar Pengajuan Permohonan
            </TabsTrigger>
          </TabsList>
          <ProfileTabContent
            data={data?.data}
            verificationData={verificationData}
            type={
              data?.data?.type === "Perhutanan Sosial" ? "persos" : "non-persos"
            }
          />
          <PortfolioTabContent
            data={data?.data}
            verificationData={verificationData}
            type={
              data?.data?.type === "Perhutanan Sosial" ? "persos" : "non-persos"
            }
          />
          <RequestTabContent
            applicant={data?.applicant}
            data={data?.data}
            type={
              data?.data?.type === "Perhutanan Sosial" ? "persos" : "non-persos"
            }
            verificationData={verificationData}
          />
        </TabsRoot>
      )}
    </div>
  );
};

export default GroupsDetail;
