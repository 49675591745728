/* eslint-disable eqeqeq */
import CardForm from "components/molecules/CardForm";
import { LayoutGroup } from "framer-motion";
import React, { useEffect, useState } from "react";

import { RiAddLine, RiEditLine } from "react-icons/ri";

import { CardPrimary } from "components/atoms/Card";
import BasicModal from "components/molecules/Modal/BasicModal";
import { createPortal } from "react-dom";
import { InputForm, InputFormRadio } from "components";
import Button from "components/atoms/Button/Button";
import RichEditorForm from "components/molecules/RichEditorForm";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";

import * as yup from "yup";
import Select from "react-select";
import AtomSelect, {
  customErrorStyle,
  customStyles,
} from "components/atoms/Select";
import { yupResolver } from "@hookform/resolvers/yup";
import { BiTrash } from "react-icons/bi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getDetailRisalahMemberSamplingPS,
  updateDeskRecommendation,
  updateDeskRecommendationPS,
} from "services/fdb/risalah";
import { useLocation, useParams } from "react-router";
import { enqueueSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";
import { debounce, startCase, toLower } from "lodash";
import angkaTerbilangJs from "@develoka/angka-terbilang-js";
import { formatCurrency } from "helpers";

const Recommendation = ({ data }) => {
  const [recommendationModal, setRecommendationModal] = useState({
    open: false,
    type: "Non Perhutanan Sosial",
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();

  const isPendapatKomite = location.pathname.includes("pendapat-komite");

  return (
    <LayoutGroup>
      <CardForm
        label="Rekomendasi"
        rightSlot={() =>
          !isPendapatKomite ? (
            <Button
              label={
                <>
                  <RiEditLine />
                  Ubah
                </>
              }
              onClick={() => {
                if (searchParams.get("group_type") === "Perhutanan Sosial") {
                  setRecommendationModal({
                    open: true,
                    type: "Perhutanan Sosial",
                  });

                  return;
                }

                setRecommendationModal({
                  open: true,
                  type: "Non Perhutanan Sosial",
                });
              }}
            />
          ) : null
        }
      >
        <LoanRates
          recommendationModal={recommendationModal}
          setRecommendationModal={setRecommendationModal}
          data={data}
        />
        <FinancingReturn data={data} />
      </CardForm>
    </LayoutGroup>
  );
};

const LoanRates = ({ recommendationModal, setRecommendationModal, data }) => {
  return (
    <>
      {recommendationModal.type === "Non Perhutanan Sosial" ? (
        <RecommendationModalNPS
          recommendationModal={recommendationModal}
          setRecommendationModal={setRecommendationModal}
          data={data}
        />
      ) : (
        <RecommendationModalPS
          recommendationModal={recommendationModal}
          setRecommendationModal={setRecommendationModal}
          data={data}
        />
      )}

      <CardPrimary label="Pinjaman & Tarif">
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 space-y-3 items-baseline place-content-start">
          <div>
            <p className="font-semibold">Nilai Maksimal Pinjaman</p>
            <p>RP. {Math.floor(data?.loan_recomendation).toLocaleString()}</p>
          </div>
          <div>
            <p className="font-semibold">Jangka Waktu</p>
            <p>{data?.loan_perode}</p>
          </div>
          <div>
            <p className="font-semibold">Tarif</p>
            <p>{data?.percentace}</p>
          </div>
        </div>
      </CardPrimary>
    </>
  );
};

const updateDeskReccomendationSchema = yup.object({
  loan_value_recomendation: yup
    .number()
    .nullable()
    .transform((val) => (val === Number(val) ? val : null))
    .required()
    .label("Rekomendasi Nilai Pinjaman"),
  fixed_loan_value_recomendation: yup
    .number()
    .nullable()
    .transform((val) => (val === Number(val) ? val : null))
    .label("Rekomendasi Nilai Pinjaman"),
  time_recomendation_periode_value: yup
    .number()
    .nullable()
    .transform((val) => (val === Number(val) ? val : null))
    .required()
    .label("Rekomendasi Jangka Waktu"),
  time_recomendation_periode_type: yup
    .string()
    .required()
    .label("Rekomendasi Jangka Waktu"),
  installment_scheme_type: yup.string().required().label("Skema Angsuran"),
  disburstment: yup.string().required().label("Pencairan"),
  disburstment_phase: yup.number().when("disburstment", ([value], schema) => {
    if (value === "stage") {
      return schema
        .nullable()
        .transform((val) => (val === Number(val) ? val : null))
        .required()
        .label("Tahap Pencairan");
    }

    return schema;
  }),
  disburstment_phase_items: yup.array(
    yup.object({
      id: yup.string(),
      phase: yup
        .number()
        .nullable()
        .transform((val) => (val === Number(val) ? val : null)),
      value: yup
        .number()
        .transform((val) => (val === Number(val) ? val : null))
        .when("disburstment", ([value], schema) => {
          if (value === "stage") {
            return schema.required();
          }
        })
        .label("Tahap Pencairan"),
    })
  ),
  return: yup.string().required().label("Pengembalian"),
  time_return_periode_value: yup.string().when("return", ([value], schema) => {
    if (value === "duration") {
      return schema.required().label("Jangka Waktu Pengembalian");
    }
    return schema;
  }),
  time_return_periode_type: yup.string().when("return", ([value], schema) => {
    if (value === "duration") {
      return schema.required().label("Jangka Waktu Pengembalian");
    }
  }),
  notes_analyst: yup.string().required().label("Catatan Analis"),
});

const periodTypeHandle = (val) => {
  switch (val) {
    case "day":
      return "Hari";
    case "month":
      return "Bulan";
    case "year":
      return "Tahun";
    default:
      return val;
  }
};

const RecommendationModalNPS = ({
  recommendationModal,
  setRecommendationModal,
  data,
}) => {
  const { desk_analyst_id } = data ?? {};

  const mutateRecommendation = useMutation({
    mutationFn: (data) => updateDeskRecommendation(desk_analyst_id, data),
  });

  const queryClient = useQueryClient();

  const methods = useForm({
    resolver: yupResolver(updateDeskReccomendationSchema),
    mode: "onChange",
    defaultValues: {
      disburstment: data?.disbursement,
      disburstment_phase: 1,
      disburstment_phase_items: [
        {
          id: undefined,
          phase: 1,
          value: undefined,
        },
      ],
    },
  });

  useEffect(() => {
    if (data) {
      const loanPeriod = data?.loan_perode.split(" ");

      methods.reset({
        loan_value_recomendation: data?.loan_recomendation,
        time_recomendation_periode_value: loanPeriod[0],
        time_recomendation_periode_type: loanPeriod[1],
        time_return_periode_value: loanPeriod[0],
        time_return_periode_type: loanPeriod[1],
        disburstment: data?.disbursement,
        disburstment_phase: data?.termins?.length ?? [],
        disburstment_phase_items: data?.termins,
        installment_scheme: data?.installment_scheme,
        installment_scheme_type: data?.installment_scheme_type,
        return: data?.return,
        notes_analyst: data?.notes,
      });
    }
  }, [data, methods, data?.loan_value_recomendation]);

  const disburstmentField = useWatch({
    control: methods.control,
    name: "disburstment",
  });

  const loadValueReccomendationField = useWatch({
    control: methods.control,
    name: "loan_value_recomendation",
  });

  const returnField = useWatch({ control: methods.control, name: "return" });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "disburstment_phase_items",
  });

  const onSubmit = (data) => {
    mutateRecommendation.mutate(data, {
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: "Berhasil update rekomendasi",
        });
        queryClient.invalidateQueries();
        setRecommendationModal((prev) => !prev);
      },
      onError: () => {
        enqueueSnackbar({
          variant: "error",
          message: "Gagal update rekomendasi",
        });
      },
    });
  };

  useEffect(() => {
    methods.setValue(
      "fixed_loan_value_recomendation",
      typeof loadValueReccomendationField !== "undefined"
        ? Math.round(loadValueReccomendationField / 10000) * 10000
        : undefined
    );
  }, [loadValueReccomendationField, methods]);

  return (
    <FormProvider {...methods}>
      {createPortal(
        <BasicModal
          title={"Rekomendasi Sementara"}
          open={recommendationModal.open}
          onClose={() => {
            setRecommendationModal((prev) => ({ ...prev, open: false }));
            methods.reset();
          }}
          onSubmit={methods.handleSubmit(onSubmit)}
          className="space-y-2 mb-4"
        >
          <div className="p-6 grid grid-cols-2 gap-5">
            <InputForm
              label="1. Rekomendasi Nilai Pinjaman"
              controllerName="loan_value_recomendation"
              placeholder="Rp"
              required
              className="w-full"
              prefix="Rp"
              isNumber
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            <InputForm
              label="2. Rekomendasi Nilai Pinjaman (Pembulatan)"
              controllerName="fixed_loan_value_recomendation"
              placeholder="Rp"
              required
              className="w-full"
              disabled
            />

            <InputForm
              label="3. Rekomendasi Jangka Waktu"
              controllerName="time_recomendation_periode_value"
              required
              className="w-full"
            />
            <Controller
              control={methods.control}
              name="time_recomendation_periode_type"
              render={({ field, fieldState }) => (
                <div className="flex flex-col gap-2">
                  <label className="text-sm font-semibold text-transparent">
                    -
                  </label>
                  <Select
                    menuPosition="fixed"
                    styles={
                      fieldState.invalid ? customErrorStyle : customStyles
                    }
                    defaultValue={[
                      { value: "Tahun", label: "Tahun" },
                      { value: "Bulan", label: "Bulan" },
                      { value: "Hari", label: "Hari" },
                    ].find((el) => el.value === periodTypeHandle(field.value))}
                    className="w-full"
                    options={[
                      { value: "Tahun", label: "Tahun" },
                      { value: "Bulan", label: "Bulan" },
                      { value: "Hari", label: "Hari" },
                    ]}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                  />
                  {fieldState.invalid && (
                    <span className="text-xs text-error-600">
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              )}
            />

            <InputForm
              label="4. Skema Angsuran"
              controllerName="installment_scheme"
              required
              className="w-full"
            />
            <Controller
              control={methods.control}
              name="installment_scheme_type"
              render={({ field, fieldState }) => (
                <div className="flex flex-col gap-2 mt-5">
                  <label className="text-sm font-semibold"></label>
                  <Select
                    menuPosition="fixed"
                    styles={
                      fieldState.invalid ? customErrorStyle : customStyles
                    }
                    className="w-full"
                    options={[
                      { value: "Mingguan", label: "Mingguan" },
                      { value: "Bulanan", label: "Bulanan" },
                      { value: "Tahunan", label: "Tahunan" },
                      { value: "Panen Bayar", label: "Panen Bayar" },
                    ]}
                    defaultValue={[
                      { value: "Mingguan", label: "Mingguan" },
                      { value: "Bulanan", label: "Bulanan" },
                      { value: "Tahunan", label: "Tahunan" },
                      { value: "Panen Bayar", label: "Panen Bayar" },
                    ].find((el) => el.value === field.value)}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                  />
                  {fieldState.invalid && (
                    <span className="text-xs text-error-600">
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              )}
            />

            <div className="col-span-2">
              <InputFormRadio
                label="5. Pencairan"
                controllerName="disburstment"
                values={[
                  {
                    label: <>Bertahap</>,
                    value: "stage",
                  },
                  {
                    label: <>Sekaligus</>,
                    value: "at_once",
                  },
                ]}
              />
            </div>

            {disburstmentField === "stage" && (
              <div className="col-span-2 flex flex-col gap-5">
                {fields.map((field, index) => (
                  <div className="flex gap-3 justify-stretch">
                    <InputForm
                      key={field.id}
                      label={`Tahap Pencairan Ke ${index + 1}`}
                      controllerName={`disburstment_phase_items.${index}.value`}
                      className="w-full"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    {index > 0 && (
                      <Button
                        type="button"
                        onClick={() => remove(index)}
                        className="p-3 rounded-lg !bg-error-600 flex-none mt-[26px]"
                        label={<BiTrash className="text-white" />}
                      />
                    )}
                  </div>
                ))}

                <div className="w-full justify-end flex">
                  <Button
                    type="button"
                    label={
                      <>
                        <RiAddLine />
                        Tambah Tahapan
                      </>
                    }
                    onClick={() => {
                      methods.setValue("disburstment_phase", fields.length + 1);
                      append({
                        id: "",
                        phase: fields.length + 1,
                        value: undefined,
                      });
                    }}
                  />
                </div>
              </div>
            )}

            <div className="col-span-2">
              <InputFormRadio
                label={"6. Pengembalian"}
                labelClass={"w-full"}
                controllerName={`return`}
                usingGrid={true}
                wrapperFieldClass="w-full"
                gridClassName="grid grid-cols-2 gap-4 w-full"
                className="flex items-center justify-between flex-wrap gap-2"
                required={true}
                values={[
                  {
                    label: "Atur Jangka Waktu Pengembalian",
                    value: "duration",
                  },
                  {
                    label: "Sesuai Dengan Siklus Usaha",
                    value: "business_cycle",
                  },
                ]}
              />
            </div>

            {returnField === "duration" && (
              <>
                <InputForm
                  label="Jangka Waktu Pengembalian"
                  controllerName="time_return_periode_value"
                  required
                  className="w-full"
                />
                <Controller
                  control={methods.control}
                  name="time_return_periode_type"
                  render={({ field, fieldState }) => {
                    return (
                      <div className="flex flex-col gap-2">
                        <label className="text-sm font-semibold text-transparent">
                          -
                        </label>
                        <Select
                          menuPosition="fixed"
                          styles={customStyles}
                          defaultValue={[
                            { value: "Tahun", label: "Tahun" },
                            { value: "Bulan", label: "Bulan" },
                            { value: "Hari", label: "Hari" },
                          ].find(
                            (el) => el.value === periodTypeHandle(field.value)
                          )}
                          className="w-full"
                          options={[
                            { value: "Tahun", label: "Tahun" },
                            { value: "Bulan", label: "Bulan" },
                            { value: "Hari", label: "Hari" },
                          ]}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                        />
                        {fieldState.invalid && (
                          <span className="text-xs text-error-600">
                            {fieldState.error.message}
                          </span>
                        )}
                      </div>
                    );
                  }}
                />
              </>
            )}

            <div className="col-span-2">
              <RichEditorForm
                name={`notes_analyst`}
                label={"Catatan Analisis"}
              />
            </div>
          </div>
        </BasicModal>,
        document.body
      )}
    </FormProvider>
  );
};

const updateDeskRecommendationSchemaPS = yup.object({
  field_analyst: yup.object({
    application_value: yup
      .number()
      .nullable()
      .transform((val) => (isNaN(val) ? null : val))
      .label("Nilai Rekomendasi"),
    application_spell: yup.string().nullable().notRequired(),
    period_value: yup.string().required().label("Jangka Waktu"),
    period_type: yup.string().required().label("Jangka Waktu"),
    installment_value: yup
      .number()
      .nullable()
      .notRequired()
      .transform((val) => (isNaN(val) ? null : val)),
    installment_spell: yup.string().notRequired(),
  }),
  desk_analyst: yup.object({
    installment_scheme: yup.string().required().label("Skema Angsuran"),
    installment_scheme_type: yup.string().required().label("Skema Angsuran"),
    return: yup.string().required().label("Pengembalian"),
    time_return_periode_value: yup
      .number()
      .transform((val) => (isNaN(val) ? null : val))
      .nullable()
      .notRequired()
      .when("return", ([val], schema) => {
        if (val === "duration") {
          return schema.required().label("Jangka Waktu Angsuran");
        }

        return schema;
      }),
    time_return_periode_type: yup
      .string()
      .notRequired()
      .when("return", ([val], schema) => {
        if (val === "duration") {
          return schema.required().label("Jangka Waktu Angsuran");
        }

        return schema;
      }),
    notes_risalah: yup.string().required().label("Catatan Analis"),
  }),
});

const RecommendationModalPS = ({
  recommendationModal,
  setRecommendationModal,
}) => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const { data: sampling } = useQuery({
    queryKey: ["GET_DETAIL_SAMPLING_PS", params.id],
    queryFn: getDetailRisalahMemberSamplingPS,
    enabled:
      !!params.id && searchParams.get("group_type") === "Perhutanan Sosial",
    select: (data) => data?.data?.data,
  });

  const methods = useForm({
    resolver: yupResolver(updateDeskRecommendationSchemaPS),
    mode: "onChange",
  });

  const returnField = useWatch({
    control: methods.control,
    name: "desk_analyst.return",
  });

  const queryClient = useQueryClient();
  const mutateUpdateRisalah = useMutation({
    mutationFn: updateDeskRecommendationPS,
  });

  const onSubmit = (data) => {
    mutateUpdateRisalah.mutate(
      { id: params.id, data },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Risalah berhasil diubah",
          });
          queryClient.invalidateQueries();
          setRecommendationModal((prev) => ({ ...prev, open: false }));
        },
        onError: (err) => {
          enqueueSnackbar({
            variant: "error",
            message: "Risalah gagal diubah",
          });
        },
      }
    );
  };

  const handleSetAngsuran = debounce(() => {
    const amount = methods.watch("field_analyst.application_value");
    const period = methods.watch("field_analyst.period_value");
    const periodType = methods.watch("field_analyst.period_type");

    if (amount == 1000000 && period === "24" && periodType === "Minggu") {
      methods.setValue("field_analyst.installment_value", 45500);
      methods.setValue(
        "field_analyst.installment_spell",
        `${startCase(toLower(angkaTerbilangJs(45500)))} Rupiah`
      );

      return;
    }

    if (amount == 1000000 && period === "36" && periodType === "Minggu") {
      methods.setValue("field_analyst.installment_value", 31625);
      methods.setValue(
        "field_analyst.installment_spell",
        `${startCase(toLower(angkaTerbilangJs(31625)))} Rupiah`
      );

      return;
    }

    if (amount == 500000 && period === "24" && periodType === "Minggu") {
      methods.setValue("field_analyst.installment_value", 22750);
      methods.setValue(
        "field_analyst.installment_spell",
        `${startCase(toLower(angkaTerbilangJs(22750)))} Rupiah`
      );

      return;
    }

    if (amount == 500000 && period === "36" && periodType === "Minggu") {
      methods.setValue("field_analyst.installment_value", 15850);
      methods.setValue(
        "field_analyst.installment_spell",
        `${startCase(toLower(angkaTerbilangJs(15850)))} Rupiah`
      );

      return;
    }
  }, 500);

  useEffect(() => {
    handleSetAngsuran();
  }, [methods, handleSetAngsuran]);

  useEffect(() => {
    if (sampling) {
      methods.reset({
        field_analyst: {
          application_value: sampling?.application_value,
          application_spell: sampling?.application_spell,
          period_value: sampling?.period_value,
          period_type: sampling?.period_type,
          installment_value: sampling?.installment_value,
          installment_spell: sampling?.installment_spell,
        },
        desk_analyst: {
          installment_scheme: sampling?.installment_scheme,
          installment_scheme_type: sampling?.installment_scheme_type,
          return: sampling?.return,
          time_return_periode_value: sampling?.time_recomendation_periode_value,
          time_return_periode_type: sampling?.time_recomendation_periode_type,
          notes_risalah: sampling?.notes_risalah,
        },
      });
    }
  }, [sampling, methods]);

  return (
    <FormProvider {...methods}>
      {createPortal(
        <BasicModal
          title={"Rekomendasi Sementara"}
          open={recommendationModal.open}
          onClose={() => {
            setRecommendationModal((prev) => ({ ...prev, open: false }));
            methods.reset();
          }}
          onSubmit={methods.handleSubmit(onSubmit)}
          className="space-y-2 mb-4"
        >
          <div className="space-y-5 p-6">
            <Controller
              control={methods.control}
              name="field_analyst.application_value"
              render={({ field, fieldState }) => (
                <div>
                  <div className="flex gap-6">
                    <label
                      htmlFor="loan-value-500000"
                      className="flex gap-3 items-center"
                    >
                      <input
                        type="radio"
                        id="loan-value-500000"
                        defaultChecked={field.value === 500000}
                        value={500000}
                        {...methods.register("field_analyst.application_value")}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          methods.setValue(
                            "field_analyst.application_spell",
                            `${startCase(
                              toLower(angkaTerbilangJs(e.target.value))
                            )} Rupiah`
                          );
                        }}
                      />
                      <p>{formatCurrency(500000)}</p>
                    </label>

                    <label
                      htmlFor="loan-value-1000000"
                      className="flex gap-3 items-center"
                    >
                      <input
                        type="radio"
                        id="loan-value-1000000"
                        value={1000000}
                        defaultChecked={field.value === 1000000}
                        {...methods.register("field_analyst.application_value")}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          methods.setValue(
                            "field_analyst.application_spell",
                            `${startCase(
                              toLower(angkaTerbilangJs(e.target.value))
                            )} Rupiah`
                          );
                        }}
                      />
                      <p>{formatCurrency(1000000)}</p>
                    </label>
                  </div>
                  {fieldState.invalid && (
                    <p className="text-xs text-red-600">
                      {fieldState.error.message}
                    </p>
                  )}
                </div>
              )}
            />

            <InputForm
              label="Terbilang"
              controllerName="field_analyst.application_spell"
              disabled
              className="w-full"
            />

            <div className="flex items-stretch gap-5">
              <AtomSelect
                label="Jangka Waktu"
                required
                controllerName="field_analyst.period_value"
                placeholder="Pilih"
                options={[
                  { label: 24, value: "24" },
                  { label: 36, value: "36" },
                ]}
                returnObject={false}
                menuPosition="fixed"
              />
              <AtomSelect
                label=""
                controllerName="field_analyst.period_type"
                placeholder="Minggu"
                options={[
                  { label: "Minggu", value: "Minggu" },
                  // { label: "Bulan", value: "Bulan" },
                  // { label: "Tahun", value: "Tahun" },
                ]}
                wrapperClass="mt-7"
                returnObject={false}
                menuPosition="fixed"
              />
            </div>

            <div className="flex gap-5">
              <InputForm
                label="Nominal Angsuran"
                controllerName="field_analyst.installment_value"
                className="w-full"
                disabled
              />
              <InputForm
                label="Terbilang"
                controllerName="field_analyst.installment_spell"
                className="w-full"
                disabled
              />
            </div>

            <div className="flex items-stretch gap-5">
              <InputForm
                label="Skema Angsuran"
                required
                controllerName="desk_analyst.installment_scheme"
                className="w-full"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />

              <AtomSelect
                controllerName="desk_analyst.installment_scheme_type"
                placeholder=""
                wrapperClass="mt-7"
                options={[
                  { label: "Mingguan", value: "Mingguan" },
                  { label: "Bulanan", value: "Bulanan" },
                  { label: "Tahunan", value: "Tahunan" },
                  { label: "Panen Bayar", value: "Panen Bayar" },
                ]}
                returnObject={false}
                menuPosition="fixed"
              />
            </div>

            <InputFormRadio
              label={"Pengembalian"}
              labelClass={"w-full"}
              controllerName={`desk_analyst.return`}
              usingGrid={true}
              wrapperFieldClass="w-full"
              gridClassName="grid grid-cols-2 gap-4 w-full"
              className="flex items-center justify-between flex-wrap gap-2"
              required={true}
              values={[
                {
                  label: "Atur Jangka Waktu Pengembalian",
                  value: "duration",
                },
                {
                  label: "Sesuai Dengan Siklus Usaha",
                  value: "business_cycle",
                },
              ]}
            />

            {returnField === "duration" && (
              <div className="flex items-stretch gap-5">
                <InputForm
                  label="Jangka Waktu Pengembalian"
                  controllerName="desk_analyst.time_return_periode_value"
                  className="w-full"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <AtomSelect
                  controllerName="desk_analyst.time_return_periode_type"
                  wrapperClass="mt-7"
                  placeholder="Minggu/Bulan/Tahun"
                  options={[
                    { label: "Minggu", value: "Minggu" },
                    { label: "Bulan", value: "Bulan" },
                    { label: "Tahun", value: "Tahun" },
                  ]}
                  returnObject={false}
                  menuPosition="fixed"
                />
              </div>
            )}

            <RichEditorForm
              name="desk_analyst.notes_risalah"
              label="Catatan Analis"
            />
          </div>
        </BasicModal>,
        document.body
      )}
    </FormProvider>
  );
};

const FinancingReturn = ({ data }) => {
  const handleStatusPencairan = (status) => {
    switch (status) {
      case "stage":
        return "Bertahap";
      default:
        return "";
    }
  };

  const handleStatusReturning = (status) => {
    if (status === "duration") {
      return "Atur Jangka Waktu Pengembalian";
    }

    return "Sesuai Dengan Siklus Usaha";
  };

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <CardPrimary label="Pembiayaan & Pengembalian">
        <div className="flex flex-col gap-5">
          <div className="grid grid-cols-4 gap-5">
            {searchParams.get("group_type") === "Non Perhutanan Sosial" && (
              <>
                <div className="flex flex-col gap-2">
                  <h4 className="text-sm font-semibold">Pencairan</h4>
                  <p className="">
                    {handleStatusPencairan(data?.disbursement)}
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <h4 className="text-sm font-semibold">Tahap Pencairan</h4>
                  <p className="">{data?.disbursement_phase}</p>
                </div>
              </>
            )}

            {data?.termins?.map((termin, index) => (
              <div>
                <p className="font-semibold">Tahap {index + 1}</p>
                <p>RP. {Math.floor(termin?.value).toLocaleString()}</p>
              </div>
            ))}
          </div>
          {/* <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 space-y-3">
          <div>
            <p className="font-semibold">Tahap Pembiayaan</p>
            <p>{data?.disbursement_phase}</p>
          </div>
          {data?.termins?.map((termin, index) => (
            <div>
              <p className="font-semibold">Tahap {index + 1}</p>
              <p>RP. {Math.floor(termin?.value).toLocaleString()}</p>
            </div>
          ))}
          <div>
            <p className="font-semibold">Pengembalian</p>
            <p>{data?.disbursement}</p>
          </div>
        </div> */}
          <div className="grid grid-cols-2 gap-5">
            <div className="flex flex-col gap-2">
              <h4 className="text-sm font-semibold">Pengembalian</h4>
              <p className="">{handleStatusReturning(data?.return)}</p>
            </div>
            <div className="flex flex-col gap-2">
              <h4 className="text-sm font-semibold">Catatan Analis</h4>
              <p
                className=""
                dangerouslySetInnerHTML={{ __html: data?.notes }}
              ></p>
            </div>
          </div>
        </div>
      </CardPrimary>
    </>
  );
};

export default Recommendation;
