import { Button } from "components";
import Table, { TableWrapper } from "components/atoms/Table";
import CardForm from "components/molecules/CardForm";
import UploadedFileAsync from "components/molecules/UploadFileAsync";
import { Pagination } from "components/v2/shared/pagination";
import dayjs from "dayjs";
import { formatCurrency } from "helpers";
import { useCallback, useEffect, useMemo } from "react";
import { RiEyeLine } from "react-icons/ri";
import { getFile } from "services/fileService";

const DistributionInformation = ({
  data,
  handleChangePageNorminative,
  handleChangeLimitNorminative,
  handleChangePageCollateral,
  handleChangeLimitColloateral,
}) => {
  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "no",
        render: ({ index }) => {
          const no =
            parseInt(data?.request_detail?.norminative?.meta?.current_page) *
              parseInt(data?.request_detail?.norminative?.meta?.per_page) -
            parseInt(data?.request_detail?.norminative?.meta?.per_page) +
            index +
            1;

          return no;
        },
      },
      {
        key: "debtor_name",
        title: "Nama Debitur",
      },
      {
        key: "debtor_identity_number",
        title: "NIK",
      },
      {
        key: "debtor_age",
        title: "Umur",
      },
      {
        key: "debtor_address",
        title: "alamat",
        render: ({ item }) => (
          <div className="w-[200px] text-clip whitespace-normal">
            {item?.debtor_address}
          </div>
        ),
      },
      {
        key: "va_number",
        title: "Nomor VA",
        render: ({ item }) => item?.va_number ?? "-",
      },
      {
        key: "purpose_using_fdb",
        title: "Tujuan penggunaan fdb",
      },
      {
        key: "application_value",
        title: "Nilai Pembayaran",
        render: ({ item }) => formatCurrency(item?.application_value),
      },
      {
        key: "loan_term",
        title: "Jangka Waktu",
        render: ({ item }) => `${item?.loan_term} Tahun`,
      },
    ];
  }, [data?.request_detail?.norminative?.meta]);

  const headersCollateral = useMemo(() => {
    return [
      {
        key: "index",
        title: "no",
        render: ({ index }) => {
          const no =
            parseInt(
              data?.request_detail?.collateral?.list?.meta?.current_page
            ) *
              parseInt(data?.request_detail?.collateral?.list?.meta?.per_page) -
            parseInt(data?.request_detail?.collateral?.list?.meta?.per_page) +
            index +
            1;
          return no;
        },
      },
      {
        key: "type",
        title: "Bentuk Jaminan",
      },
      {
        key: "value",
        title: "Nilai Jaminan",
        render: ({ item }) => formatCurrency(item?.value),
      },
      {
        key: "action",
        title: "aksi",
        render: ({ item }) => {
          return (
            <Button
              variant="icon"
              label={<RiEyeLine className="text-lg" />}
              onClick={() => {
                window.open(item?.selected_file?.url, "_blank");
              }}
            />
          );
        },
      },
    ];
  }, [data?.request_detail?.collateral?.list?.meta]);

  const collateralValue = data?.request_detail?.collateral?.list?.data?.reduce(
    (acc, curr) => acc + Number(curr?.value),
    0
  );

  const handleGetFileCollateral = useCallback(async () => {
    if (data?.request_detail?.collateral?.list?.data?.length < 1) return;

    for (let collateral of data?.request_detail?.collateral?.list?.data) {
      const response = await getFile(collateral?.file);

      collateral.selected_file = response?.data?.data;
    }
  }, [data?.request_detail?.collateral?.list?.data]);

  useEffect(() => {
    handleGetFileCollateral();
  }, [handleGetFileCollateral]);

  return (
    <CardForm label="Data Lembaga Penyalur">
      <div className="space-y-5">
        <div className="space-y-4">
          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">Detail Permohonan</p>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div className="space-y-1">
              <div className="font-semibold">Nama Lembaga</div>
              <div className="max-w-[105px] md:max-w-[200px] overflow-hidden text-ellipsis">{data?.request_detail?.agency_name ?? "-"}</div>
            </div>
            <div className="space-y-1">
              <div className="font-semibold">Tanggal Permohonan Pencairan</div>
              <div className="max-w-[105px] md:max-w-[200px] overflow-hidden text-ellipsis">
                {data?.request_detail?.disbursemnet_date
                  ? dayjs(data?.request_detail?.disbursemnet_date).format(
                      "DD-MMMM-YYYY"
                    )
                  : "-"}
              </div>
            </div>
            <div className="space-y-1">
              <div className="font-semibold">Nomor Perjanjian</div>
              <div className="max-w-[105px] md:max-w-[200px] overflow-hidden text-ellipsis">{data?.request_detail?.agreement_number ?? "-"}</div>
            </div>
            <div className="space-y-1">
              <div className="font-semibold">Tanggal Perjanjian</div>
              <div className="max-w-[105px] md:max-w-[200px] overflow-hidden text-ellipsis">
                {data?.request_detail?.agreement_date
                  ? dayjs(data?.request_detail?.agreement_date).format(
                      "DD-MMMM-YYYY"
                    )
                  : "-"}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
            <div className="space-y-1">
              <div className="font-semibold">Total Nilai Permohonan</div>
              <div className="max-w-[105px] md:max-w-[200px] overflow-hidden text-ellipsis">{formatCurrency(data?.request_detail?.value) ?? "-"}</div>
            </div>
            <div className="space-y-1">
              <div className="font-semibold">Tahap Penyaluran</div>
              <div className="max-w-[105px] md:max-w-[200px] overflow-hidden text-ellipsis">Tahap {data?.request_detail?.phase ?? "-"}</div>
            </div>
            <div className="space-y-1">
              <div className="font-semibold">Nilai Disalurakan Tahap ini</div>
              <div className="max-w-[105px] md:max-w-[200px] overflow-hidden text-ellipsis">
                {formatCurrency(data?.request_detail?.value_this_phase) ?? "-"}
              </div>
            </div>
            <div className="space-y-1">
              <div className="font-semibold">Total Yang dicairkan</div>
              <div className="max-w-[105px] md:max-w-[200px] overflow-hidden text-ellipsis">
                {formatCurrency(data?.request_detail?.disbursement_total) ??
                  "-"}
              </div>
            </div>
            <div className="space-y-1">
              <div className="font-semibold">Sisa Saldo Penyaluran</div>
              <div className="max-w-[105px] md:max-w-[200px] overflow-hidden text-ellipsis">
                {formatCurrency(data?.request_detail?.ceiling_remaining) ?? "-"}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <UploadedFileAsync
              id={data?.request_detail?.agreement_doc}
              name="Dokumen Perjanjian"
            />
            {/* <UploadedFileAsync
              id={data?.general?.contact_person?.contact_person_npwp_file}
              name="Dokumen fidusida"
            />
            <UploadedFileAsync
              id={data?.general?.contact_person?.contact_person_npwp_file}
              name="Akad Debitur"
            /> */}
          </div>

          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">Daftar Norminative</p>
          </div>

          <TableWrapper>
            <Table
              headers={headers}
              items={data?.request_detail?.norminative?.data}
              isLoading={false}
            />

            <Pagination
              page={
                Number(data?.request_detail?.norminative?.meta?.current_page) ??
                1
              }
              limit={
                Number(data?.request_detail?.norminative?.meta?.per_page) ?? 10
              }
              total={
                Number(data?.request_detail?.norminative?.meta?.total) ?? 1
              }
              totalPage={
                Number(data?.request_detail?.norminative?.meta?.last_page) ?? 1
              }
              onPageChange={handleChangePageNorminative}
              onLimitChange={handleChangeLimitNorminative}
            />
          </TableWrapper>

          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">Jaminan Tambahan</p>
          </div>

          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-semibold text-md text-[#01A24A]">
              Total Jaminan Tambahan Yang Diajukan
            </p>
            <p className="text-[#01A24A] text-sm">
              {formatCurrency(collateralValue)}
            </p>
          </div>

          <TableWrapper>
            <Table
              headers={headersCollateral}
              items={data?.request_detail?.collateral?.list?.data}
              isLoading={false}
            />

            <Pagination
              page={
                Number(
                  data?.request_detail?.collateral?.list?.meta?.current_page
                ) ?? 1
              }
              limit={
                Number(
                  data?.request_detail?.collateral?.list?.meta?.per_page
                ) ?? 10
              }
              total={
                Number(data?.request_detail?.collateral?.list?.meta?.total) ?? 1
              }
              totalPage={
                Number(
                  data?.request_detail?.collateral?.list?.meta?.last_page
                ) ?? 1
              }
              onPageChange={handleChangePageCollateral}
              onLimitChange={handleChangeLimitColloateral}
            />
          </TableWrapper>
        </div>
      </div>
    </CardForm>
  );
};

export default DistributionInformation;
