import { useQuery } from "@tanstack/react-query";
import { Button, Input } from "components";
import Badge from "components/atoms/Badge";
import { customStyles } from "components/atoms/Select";
import Table, { TableWrapper } from "components/atoms/Table";

import BreadCrumbs from "components/molecules/Breadcrumbs";
import { Pagination } from "components/v2/shared/pagination";
import dayjs from "dayjs";
import { debounce, toLower } from "lodash";
import React, { useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import {
  RiCloseLine,
  RiEyeLine,
  RiFolder2Line,
  RiSearchLine,
} from "react-icons/ri";
import { useNavigate } from "react-router";
import Select from "react-select";
import { getAssignDisbursementList } from "services/fdb/disbursement";

const title = "Assign Pelaksana Pencairan";
const AssignDisbursmentsList = () => {
  const [filters, setFilters] = useState({
    paginate: true,
    page: 1,
    limit: 10,
    filter: {
      search: "",
      status: "",
      date_start: "",
      date_end: "",
    },
  });

  // ==================================================

  const { data, isLoading } = useQuery(
    [
      "GET_ASSIGN_DISBURSEMENT",
      {
        ...filters,
        filter: {
          ...filters.filter,
          date_start:
            filters.filter.date_start !== ""
              ? dayjs(filters.filter.date_start).format("YYYY-MM-DD")
              : "",
          date_end:
            filters.filter.date_end !== ""
              ? dayjs(filters.filter.date_end).format("YYYY-MM-DD")
              : "",
        },
      },
    ],
    getAssignDisbursementList,
    {
      select: (data) => data?.data?.data,
    }
  );

  // ==================================================

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: "/assign-disbursments",
          },
        ]}
      />

      <h1 className="text-gray-900 text-2xl font-semibold leading-loose">
        {title}
      </h1>

      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <ListFilter filters={filters} setFilters={setFilters} />
        <ListTable
          data={data}
          isLoading={isLoading}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
    </div>
  );
};

const ListFilter = ({ filters, setFilters }) => {
  const stateOptions = useMemo(
    () => [
      { value: "1", label: "Baru" },
      { value: "2", label: "Sedang Dikerjakan" },
    ],
    []
  );

  return (
    <form className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end">
      <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
        <RiSearchLine className="text-gray-400 text-xl" />

        <Input
          className="w-full bg-white border-none"
          onChange={debounce(
            (e) =>
              setFilters((prev) => ({
                ...prev,
                page: 1,
                filter: {
                  ...prev.filter,
                  search: e.target.value,
                },
              })),
            1000
          )}
          placeholder="Cari"
        />
      </div>
      <Select
        className="w-full rounded-md h-10 absolute"
        options={stateOptions}
        placeholder="Status"
        styles={customStyles}
        isClearable
        onChange={debounce(
          (e) =>
            setFilters((prev) => ({
              ...prev,
              page: 1,
              filter: {
                ...prev.filter,
                status: e?.value ?? "",
              },
            })),
          1000
        )}
      />
      <div className="relative">
        <ReactDatePicker
          className={
            "date_picker w-full !outline-none !focus:border-primary-700 border h-10 px-3 focus:border-primary-700 rounded-md"
          }
          selected={filters.filter.date_start}
          onChange={debounce(
            (date) =>
              setFilters((prev) => ({
                ...prev,
                page: 1,
                filter: {
                  ...prev.filter,
                  date_start: date,
                  date_end: date,
                },
              })),
            1000
          )}
          placeholderText={"dd/mm/yyyy"}
          showYearDropdown={true}
        />
        {filters.filter.date_start !== "" && (
          <RiCloseLine
            className="absolute top-3 right-2 text-gray-400 hover:text-gray-600 cursor-pointer text-lg"
            onClick={() =>
              setFilters((prev) => ({
                ...prev,
                page: 1,
                filter: { ...prev.filter, date_start: "", date_end: "" },
              }))
            }
          />
        )}
      </div>
    </form>
  );
};

const stateColors = {
  Baru: "warning",
  "Sedang Dikerjakan": "bluelight",
  Draft: "default",
  Ditolak: "error",
  Selesai: "success",
};

const categoryColors = {
  "Perhutanan Sosial": "error",
  "Non Perhutanan Sosial": "bluelight",
};

const ListTable = ({ data, isLoading, filters, setFilters }) => {
  const navigate = useNavigate();

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "group_name",
        title: "Nama Kelompok",
      },
      {
        key: "date",
        title: "Tgl Pengajuan",
        render: ({ item }) => {
          return dayjs(item.date).format("DD MMMM YYYY");
        },
      },
      {
        key: "group_name",
        title: "Tipe Kelompok",
        render: ({ item }) => (
          <Badge color={categoryColors[item.group_type]} variant="outlined">
            {item.group_type}
          </Badge>
        ),
      },
      {
        key: "total_member",
        title: "Jml Pengajuan Anggota",
        render: ({ item }) => {
          return item.total_applicant ? `${item?.total_applicant} Orang` : "-";
        },
      },
      {
        key: "state",
        title: "Status",
        render: ({ item }) => (
          <Badge color={stateColors[item.status_string]}>
            {item.status_string}
          </Badge>
        ),
      },
      // {
      //   key: "news",
      //   title: "Berita Acara",
      //   render: ({ item }) => (
      //     <div className="flex justify-center items-center w-full">
      //       <RiFolder2Line className="text-xl cursor-pointer" />
      //     </div>
      //   ),
      // },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <>
            {["baru", "new"].includes(toLower(item.status_string)) ? (
              <Button
                theme="primary"
                label="Assign"
                onClick={() => {
                  navigate(
                    `/assign-disbursments/${item.id}?status=${item?.status_string}`
                  );
                }}
              />
            ) : (
              <Button
                variant="icon"
                label={<RiEyeLine className="text-lg" />}
                onClick={() => {
                  navigate(
                    `/assign-disbursments/${item.id}??status=${item?.status_string}`
                  );
                }}
              />
            )}
          </>
        ),
      },
    ];
  }, [navigate]);

  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={data?.data ?? []} isLoading={isLoading} />
      <Pagination
        page={data?.meta?.current_page ?? 1}
        limit={data?.meta?.per_page ?? 10}
        total={data?.meta?.total ?? 1}
        totalPage={data?.meta?.last_page ?? 1}
        onLimitChange={(e) =>
          setFilters((prev) => ({
            ...prev,
            page: 1,
            limit: e,
          }))
        }
        onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
      />
    </TableWrapper>
  );
};

export default AssignDisbursmentsList;
