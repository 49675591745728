const config = {
  BASE_URL:
    process.env.REACT_APP_BASE_URL || "https://bpldh-api.groot.id/api/fdb",
};

export const config_daprog = {
  BASE_URL:
    process.env.REACT_APP_BASE_URL ||
    "https://bpldh-api-v2.groot.id/api/dana-program",
};

export const general_config = {
  BASE_URL:
    process.env.REACT_APP_BASE_URL || "https://bpldh-api-v2.groot.id/api",
};

export const general_config_v2 = {
  BASE_URL:
    process.env.REACT_APP_BASE_URL + "-v2" ||
    "https://bpldh-api-v2.groot.id/api/fdb-v2",
  BASE_URL_USER_MANAGEMENT: process.env.REACT_APP_BASE_URL_USER_MANAGEMENT || 'https://bpldh-api-v2.groot.id/api/user-management',

  ID_DIVISI: process.env.REACT_APP_ID_DIVISI || "26f32f37-ad5c-47f7-8fd0-505744fd8238",
  ID_HIERARCY: process.env.REACT_APP_ID_HIERARCY || "270cc184-50af-49e8-a40b-1d15e937ab61",
};

export const config_master = {
  BASE_URL:
    process.env.REACT_APP_BASE_URL ||
    "https://bpldh-api-v2.groot.id/api/master",
};

export const AUTOSAVE_DURATION = parseFloat(
  process.env.REACT_APP_AUTOSAVE_DURATION || 5 * 60 * 1000
); // in ms. DEFAULT: 5 min
export const MAX_DOC_UPLOAD = parseFloat(
  process.env.REACT_APP_MAX_DOC_UPLOAD || 10 * 1024 * 1024
); // in bytes. DEFAULT: 10MB
export const MAX_IMG_UPLOAD = parseFloat(
  process.env.REACT_APP_MAX_IMG_UPLOAD || 10 * 1024 * 1024
); // in bytes. DEFAULT: 10MB
export const DOC_UPLOAD_EXT =
  process.env.REACT_APP_DOC_UPLOAD_EXT?.trim()?.split(",") || [
    "jpg",
    "png",
    "jpeg",
    "pdf",
    "docx",
  ];
export const IMG_UPLOAD_EXT =
  process.env.REACT_APP_IMG_UPLOAD_EXT?.trim()?.split(",") || [
    "jpg",
    "png",
    "jpeg",
  ];

export default config;
