import { useQuery } from "@tanstack/react-query";
import { Button, Input } from "components";
import Badge from "components/atoms/Badge";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import dayjs from "dayjs";
import { debounce } from "lodash";
import React, { useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { RiCloseLine, RiEyeLine, RiSearchLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import Table, { TableWrapper } from "components/atoms/Table";
import { customStyles } from "components/atoms/Select";
import Select from "react-select";
import { Pagination } from "components/v2/shared/pagination";
import { getListFieldAnalyst } from "services/institutions/field-analyst";

const stateOptions = [
  { value: "new", label: "Baru" },
  { value: "on_progress", label: "Sedang Dikerjakan" },
  { value: "done", label: "Selesai" },
];

const stateColors = {
  1: "bluelight",
  2: "error",
  3: "success",
};

const InstitutionsFieldAnalyst = () => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    filter: {
      search: undefined,
      status: undefined,
      date: undefined,
    },
    page: 1,
    limit: 10,
  });

  const { data, isLoading } = useQuery({
    queryKey: [
      "field-analyst-lembaga",
      {
        ...filter,
        filter: {
          ...filter.filter,
        },
      },
    ],
    queryFn: getListFieldAnalyst,
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => data?.data?.data,
  });

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "name",
        title: "Nama Lembaga",
      },
      {
        key: "agency_type",
        title: "Jenis Lembaga",
      },
      {
        key: "date",
        title: "Tanggal Permohonan",
        render: ({ item }) => {
          return dayjs(item.request_date).format("DD MMMM YYYY");
        },
      },
      {
        key: "date",
        title: "Tanggal Assign",
        render: ({ item }) => {
          return dayjs(item.assign_date).format("DD MMMM YYYY");
        },
      },
      {
        key: "date",
        title: "Tanggal Selesai",
        render: ({ item }) => {
          return item.done_date
            ? dayjs(item.done_date).format("DD MMMM YYYY")
            : "-";
        },
      },
      {
        key: "status",
        title: "Status",
        render: ({ item }) => (
          <Badge color={stateColors[item.status]}>{item.status_string}</Badge>
        ),
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) =>
          item?.status === "1" ? (
            <Button
              theme="primary"
              label="Verifikasi"
              onClick={() => {
                navigate(
                  `/institutions/field-analyst/${item.id}?status=${item?.status}`
                );
              }}
            />
          ) : (
            <Button
              variant="icon"
              label={<RiEyeLine className="text-lg" />}
              onClick={() => {
                navigate(
                  `/institutions/field-analyst/${item.id}?status=${item?.status}`
                );
              }}
            />
          ),
      },
    ];
  }, [navigate]);

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: "Permohonan Lembaga Penyalur",
            path: "/institutions/field-analyst",
          },
        ]}
      />

      <h1 className="text-gray-900 text-2xl font-semibold leading-loose">
        Permohonan Lembaga Penyalur
      </h1>

      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end">
          <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
            <RiSearchLine className="text-gray-400 text-xl" />
            <Input
              className="w-full bg-white border-none"
              onChange={debounce(
                (e) =>
                  setFilter((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      search: e.target.value,
                    },
                  })),
                1000
              )}
              placeholder="Cari"
            />
          </div>
          <Select
            className="w-full rounded-md h-10 absolute"
            options={stateOptions}
            placeholder="Status"
            styles={customStyles}
            isClearable
            onChange={debounce(
              (e) =>
                setFilter((prev) => ({
                  ...prev,
                  page: 1,
                  filter: {
                    ...prev.filter,
                    status: e?.value ?? undefined,
                  },
                })),
              1000
            )}
          />
          <div className="relative">
            <ReactDatePicker
              className={
                "date_picker w-full !outline-none !focus:border-primary-700 border h-10 px-3 focus:border-primary-700 rounded-md"
              }
              selected={filter.filter?.date ?? null}
              onChange={debounce(
                (date) =>
                  setFilter((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      date,
                    },
                  })),
                1000
              )}
              placeholderText={"dd/mm/yyyy"}
              showYearDropdown={true}
            />
            {filter?.date?.date && (
              <RiCloseLine
                className="absolute top-3 right-2 text-gray-400 hover:text-gray-600 cursor-pointer text-lg"
                onClick={() =>
                  setFilter((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      date: undefined,
                    },
                  }))
                }
              />
            )}
          </div>
        </div>
        <TableWrapper className="mt-4">
          <Table
            headers={headers}
            items={data?.data || []}
            isLoading={isLoading}
          />
          <Pagination
            page={data?.meta?.current_page || 1}
            limit={filter.limit || 10}
            total={data?.meta?.total || 0}
            totalPage={data?.meta?.last_page || 1}
            onLimitChange={(e) => setFilter((prev) => ({ ...prev, limit: e }))}
            onPageChange={(e) => setFilter((prev) => ({ ...prev, page: e }))}
          />
        </TableWrapper>
      </div>
    </div>
  );
};

export default InstitutionsFieldAnalyst;
