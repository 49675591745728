import React, { useMemo, useState, useCallback, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CardForm from "components/molecules/CardForm";
import { useNavigate } from "react-router";
import { TabsContent } from "components/atoms/Tabs";
import {
  BottomFormAction,
  Button,
  InputForm,
  InputFormRadio,
  InputFormUpload,
} from "components";
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { AnimatePresence, motion } from "framer-motion";
import AccordionButton from "components/atoms/AccordionButton";
import UploadedFile from "components/molecules/UploadedFile";
import {
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiMapPin2Fill,
} from "react-icons/ri";
import dayjs from "dayjs";
import UploadedFileAsync from "components/molecules/UploadFileAsync";
import { useMutation } from "@tanstack/react-query";
import { fileUpload } from "services/fileService";
import GeotagLocationButton from "components/atoms/Button/GeotagLocationButton";
import { find } from "lodash";
import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";

import { getFile } from "services/fileService";
import { formatCurrency } from "helpers";

const calculateDiffer = (dateTime) => {
  const now = dayjs();
  const birthDate = dayjs(dateTime);

  const differYear = now.diff(birthDate, "years");
  if (differYear < 1) {
    const differMonth = now.diff(birthDate, "months");
    if (differMonth < 1) {
      const differDay = now.diff(birthDate, "days");
      if (differDay < 1) {
        const differHours = now.diff(birthDate, "hours");
        return `${differHours} Jam`;
      } else {
        return `${differDay} Hari`;
      }
    } else {
      return `${differMonth} Bulan`;
    }
  } else {
    return `${differYear} Tahun`;
  }
};

const calculateAge = (dateTime) => {
  const now = dayjs();
  const birthDate = dayjs(dateTime);

  const years = now.diff(birthDate, "year");

  return years;
};

const getFileFromService = async (id) => {
  const response = await getFile(id);
  return response.data.data;
};

export const PotentialDebtorContent = ({
  initialValues,
  type = "non-persos",
  onNext,
  onSaveDraft,
  hideDraft,
}) => {
  const navigate = useNavigate();

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      credit_history_status_social_assistance: yup
        .bool()
        .transform((originalValue) =>
          originalValue === "" ? undefined : originalValue
        )
        .when([type], ([value], schema) => {
          if (value === "persos") {
            return schema.required().label("Bukan Penerima Bansos (Kemensos)");
          }
          return schema.notRequired();
        }),
      credit_history_status_sikp: yup
        .bool()
        .transform((originalValue) =>
          originalValue === "" ? undefined : originalValue
        )
        .when([type], ([value], schema) => {
          if (value === "persos") {
            return schema
              .required()
              .label("Pengecekan SIKP Tidak Memiliki Pinjaman");
          }
          return schema.notRequired();
        }),
      credit_history_status_bi_checking: yup
        .bool()
        .transform((originalValue) =>
          originalValue === "" ? undefined : originalValue
        )
        .when([type], ([value], schema) => {
          if (value === "persos") {
            return schema
              .required()
              .label(
                "Pengecekan BI Checking/SUK/Perindo Tidak Memiliki Catatan Kredit Buruk"
              );
          }
          return schema.notRequired();
        }),
      income_info_verification: yup
        .bool()
        .transform((originalValue) =>
          originalValue === "" ? undefined : originalValue
        )
        .when([type], ([value], schema) => {
          if (value === "persos") {
            return schema.required().label("Kesimpulan Verifikasi");
          }
          return schema.notRequired();
        }),
      income_info_verification_desk: yup
        .string()
        .when("income_info_verification", ([value], schema) => {
          if (value === false && type === "persos") {
            return schema.required().label("Kesalahan/Ketidak sesuaian data");
          }
          return schema.notRequired();
        }),
      debtor_verification: yup
        .bool()
        .transform((originalValue) =>
          originalValue === "" ? undefined : originalValue
        )
        .required()
        .label("Kesimpulan Verifikasi"),
      domicile_verification: yup
        .bool()
        .transform((originalValue) =>
          originalValue === "" ? undefined : originalValue
        )
        .required()
        .label("Kesimpulan Verifikasi"),
      couple_verification: yup
        .bool()
        .transform((originalValue) =>
          originalValue === "" ? undefined : originalValue
        )
        .required()
        .label("Kesimpulan Verifikasi"),
      couple_verification_desk: yup
        .string()
        .when("couple_verification", ([value], schema) => {
          if (value === false) {
            return schema.required().label("Kesalahan/Ketidak sesuaian data");
          }
          return schema.notRequired();
        }),
      domicile_verification_desk: yup
        .string()
        .when("domicile_verification", ([value], schema) => {
          if (value === false) {
            return schema.required().label("Kesalahan/Ketidak sesuaian data");
          }
          return schema.notRequired();
        }),

      debtor_verification_identity_file_temp: yup
        .mixed()
        .test(
          "cid_img",
          ({ label }) =>
            validationErrorMsg.mixed.required.replace("${path}", label),
          (value, context) => {
            const allowedTypes = ["jpg", "png", "jpeg"];
            if (value) {
              const data = find(allowedTypes, (at) => value.type.includes(at));
              if (!data) {
                return context.createError({
                  message: validationCustomErrorMsg.isFiletype(
                    allowedTypes.join(", ")
                  )({ label: "Foto Pemohon Dengan Dokumen Asli (KTP)" }),
                });
              }
              if (value.size > 10 * 1024 * 1024) {
                return context.createError({
                  message: validationCustomErrorMsg.isFileSize("10MB")({
                    label: "Foto Pemohon Dengan Dokumen Asli (KTP)",
                  }),
                });
              }

              return true;
            }

            // otherwise, return false to render the field required
            return false;
          }
        )
        .required()
        .label("Foto Pemohon Dengan Dokumen Asli (KTP)"),

      debtor_verification_identity_family_file_temp: yup
        .mixed()
        .test(
          "cid_img",
          ({ label }) =>
            validationErrorMsg.mixed.required.replace("${path}", label),
          (value, context) => {
            const allowedTypes = ["jpg", "png", "jpeg"];
            if (value) {
              const data = find(allowedTypes, (at) => value.type.includes(at));
              if (!data) {
                return context.createError({
                  message: validationCustomErrorMsg.isFiletype(
                    allowedTypes.join(", ")
                  )({
                    label: "Foto Pemohon Dengan Dokumen Asli (Kartu Keluarga)",
                  }),
                });
              }
              if (value.size > 10 * 1024 * 1024) {
                return context.createError({
                  message: validationCustomErrorMsg.isFileSize("10MB")({
                    label: "Foto Pemohon Dengan Dokumen Asli (Kartu Keluarga)",
                  }),
                });
              }

              return true;
            }

            // otherwise, return false to render the field required
            return false;
          }
        )
        .required()
        .label("Foto Pemohon Dengan Dokumen Asli (Kartu Keluarga)"),

      debtor_verification_signature_file_temp: yup
        .mixed()
        .test(
          "cid_img",
          ({ label }) =>
            validationErrorMsg.mixed.required.replace("${path}", label),
          (value, context) => {
            if (context?.parent?.debtor_verification === false) {
              const allowedTypes = ["jpg", "png", "jpeg"];
              if (value) {
                const data = find(allowedTypes, (at) =>
                  value.type.includes(at)
                );
                if (!data) {
                  return context.createError({
                    message: validationCustomErrorMsg.isFiletype(
                      allowedTypes.join(", ")
                    )({ label: "Surat Pernyataan Beda Tanda Tangan" }),
                  });
                }
                if (value.size > 10 * 1024 * 1024) {
                  return context.createError({
                    message: validationCustomErrorMsg.isFileSize("10MB")({
                      label: "Surat Pernyataan Beda Tanda Tangan",
                    }),
                  });
                }

                return true;
              }
              return false;
            }
            return true;
          }
        )
        .label("Surat Pernyataan Beda Tanda Tangan"),
    });
  }, [type]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (initialValues?.defaultValues) {
      methods.reset(initialValues?.defaultValues);
    }
  }, [methods, initialValues?.defaultValues]);

  useEffect(() => {
    if (initialValues?.request?.field_analysts) {
      const promises = [];
      if (
        initialValues?.request?.field_analysts
          ?.debtor_verification_signature_file &&
        initialValues?.request?.field_analysts
          ?.debtor_verification_signature_file !== "string"
      ) {
        promises.push(
          getFileFromService(
            initialValues?.request?.field_analysts
              ?.debtor_verification_signature_file
          ).then((data) => {
            return {
              formId: "debtor_verification_signature_file_temp",
              type: data?.mimeType,
              ...data,
            };
          })
        );
      }
      if (
        initialValues?.request?.field_analysts
          ?.debtor_verification_identity_file &&
        initialValues?.request?.field_analysts
          ?.debtor_verification_identity_file !== "string"
      ) {
        promises.push(
          getFileFromService(
            initialValues?.request?.field_analysts
              ?.debtor_verification_identity_file
          ).then((data) => {
            return {
              formId: "debtor_verification_identity_file_temp",
              type: data?.mimeType,
              ...data,
            };
          })
        );
      }
      if (
        initialValues?.request?.field_analysts
          ?.domicile_verification_latlong_file &&
        initialValues?.request?.field_analysts
          ?.domicile_verification_latlong_file !== "string"
      ) {
        promises.push(
          getFileFromService(
            initialValues?.request?.field_analysts
              ?.domicile_verification_latlong_file
          ).then((data) => {
            return {
              formId: "domicile_verification_latlong_file_temp",
              type: data?.mimeType,
              ...data,
            };
          })
        );
      }
      if (
        initialValues?.request?.field_analysts
          ?.debtor_verification_identity_family_file &&
        initialValues?.request?.field_analysts
          ?.debtor_verification_identity_family_file !== "string"
      ) {
        promises.push(
          getFileFromService(
            initialValues?.request?.field_analysts
              ?.debtor_verification_identity_family_file
          ).then((data) => {
            return {
              formId: "debtor_verification_identity_family_file_temp",
              type: data?.mimeType,
              ...data,
            };
          })
        );
      }
      if (promises.length > 0) {
        Promise.all(promises).then((values) => {
          values.map((x) => {
            methods.resetField(x.formId, { defaultValue: x });
          });
        });
      }
    }
  }, [methods, initialValues?.request?.field_analysts]);

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onFormSubmit = useCallback(
    (payload) => {
      onNext(payload);
    },
    [onNext]
  );

  const onDraft = useCallback(() => {
    onSaveDraft(methods.getValues());
  }, [methods, onSaveDraft]);

  return (
    <FormProvider key="potential-debtor-content" {...methods}>
      <form
        key="potential-debtor-content"
        name="potential-debtor-content"
        id="potential-debtor-content"
      >
        <TabsContent value="potential-debtor" className="mb-40">
          <DeskAnalystNote data={initialValues} />
          <DetailPotentialDebtor data={initialValues} />
          <Address data={initialValues} />
          <CoupleData data={initialValues} />
          {type === "persos" && (
            <>
              <FinanceInfo data={initialValues} />
              <CheckHistory />
            </>
          )}
          <BottomFormAction
            submitButtonProps={{
              type: "button",
            }}
            draftButtonProps={{
              type: "button",
            }}
            backButtonAction={onBack}
            submitActionButton={() => methods.handleSubmit(onFormSubmit)()}
            disableDrafButton={false}
            drafButtonAction={onDraft}
            hideDraft={hideDraft}
            disableButtonSubmit={false}
            className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
          />
        </TabsContent>
      </form>
    </FormProvider>
  );
};

const DeskAnalystNote = ({ data }) => {
  const { request } = data;
  return (
    <CardForm label="Catatan Desk Analyst">
      <div>
        {/* <div className="font-semibold mb-3">Catatan Analis</div> */}
        {request?.desk_analysts?.notes_analyst ? (
          <div
            dangerouslySetInnerHTML={{
              __html: request?.desk_analysts?.notes_analyst,
            }}
          ></div>
        ) : null}
      </div>
    </CardForm>
  );
};

const DetailPotentialDebtor = ({ data: tempData }) => {
  const methods = useFormContext();

  const data = tempData?.group_profile_member || {};
  const documents = useMemo(() => {
    let docs = [];
    if (data?.identity_file) {
      docs.push({ file: data?.identity_file, desc: "Dokumen KTP" });
    }
    if (data?.family_card_file) {
      docs.push({
        file: data?.family_card_file,
        desc: "Dokumen Kartu Keluarga",
      });
    }
    if (data?.document?.length > 0) {
      docs = [...docs, ...data?.document];
    }
    return docs;
  }, [data?.identity_file, data?.family_card_file, data?.document]);

  const debtor_verification = useWatch({
    control: methods.control,
    name: "debtor_verification",
  });

  const fileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      if (file?.controllerName === "debtor_verification_identity_file_temp") {
        methods.register("debtor_verification_identity_file");
        methods.setValue(
          "debtor_verification_identity_file",
          response.data.data.id
        );
      } else if (
        file?.controllerName === "debtor_verification_identity_family_file_temp"
      ) {
        methods.register("debtor_verification_identity_family_file");
        methods.setValue(
          "debtor_verification_identity_family_file",
          response.data.data.id
        );
      } else if (
        file?.controllerName === "debtor_verification_signature_file_temp"
      ) {
        methods.register("debtor_verification_signature_file");
        methods.setValue(
          "debtor_verification_signature_file",
          response.data.data.id
        );
      }
    } catch (error) {
      throw error;
    }
  });

  return (
    <CardForm label="Detail Calon Debitur">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div>
          <div className="font-semibold">Nomor NIK</div>
          <div>{data?.identity_number ?? "-"}</div>
        </div>
        <div>
          <div className="font-semibold">Nama Lengkap Sesuai KTP</div>
          <div>{data?.name ?? "-"}</div>
        </div>
        <div>
          <div className="font-semibold">Tempat Lahir</div>
          <div>{data?.place_of_birth ?? "-"}</div>
        </div>
        <div>
          <div className="font-semibold">Tanggal Lahir</div>
          <div>
            {data?.date_of_birth
              ? dayjs(data?.date_of_birth).format("DD MMMM YYYY")
              : "-"}
          </div>
        </div>
        <div>
          <div className="font-semibold">Usia Debitur Saat Permohonan</div>
          <div>{calculateDiffer(data?.date_of_birth)}</div>
        </div>
        <div>
          <div className="font-semibold">Masuk Usia Produktif</div>
          {calculateAge(data?.date_of_birth) >= 20 &&
          calculateAge(data?.date_of_birth) <= 65
            ? "Masuk Usia Proktif"
            : "Tidak Masuk Usia Produktif"}
        </div>
        <div>
          <div className="font-semibold">Nomor Kartu Keluarga</div>
          <div>{data?.family_card_number ?? "-"}</div>
        </div>
        <div>
          <div className="font-semibold">Pekerjaan Utama</div>
          <div>{data?.main_job ?? "-"}</div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        {documents?.map((doc, key) => (
          <UploadedFileAsync
            key={`${doc.id}-${key}`}
            id={doc.file}
            name={doc.desc}
          />
        ))}
      </div>
      <div className="border rounded-md px-4 py-3 space-y-4">
        <InputFormRadio
          label={"Kesimpulan Verifikasi"}
          controllerName={`debtor_verification`}
          className="flex items-center justify-between gap-4"
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Data Terverifikasi
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Data Belum Sesuai/ Ada Kesalahan
                </>
              ),
              value: false,
            },
          ]}
        />
        {debtor_verification !== "" && debtor_verification === false && (
          <div className="space-y-4">
            <UploadedFile
              filename="KCTT (Kartu Contoh Tanda Tangan)"
              size="100 MB"
              routes="/field-analyst"
            />
            <div className="space-y-4">
              <InputFormUpload
                uploadFile={fileMutation}
                controllerName={`debtor_verification_signature_file_temp`}
                label={"Surat Pernyataan Beda Tanda Tangan"}
              />
            </div>
          </div>
        )}
        <div className="flex space-x-5">
          <div className="w-1/2 space-y-4">
            <InputFormUpload
              required
              uploadFile={fileMutation}
              controllerName={`debtor_verification_identity_file_temp`}
              label={"Unggah Foto Pemohon dengan Dokumen Asli (KTP)"}
            />
          </div>
          <div className="w-1/2 space-y-4">
            <InputFormUpload
              required
              uploadFile={fileMutation}
              controllerName={`debtor_verification_identity_family_file_temp`}
              label={"Unggah Foto Pemohon dengan Dokumen Asli (Kartu Keluarga)"}
            />
          </div>
        </div>
      </div>
    </CardForm>
  );
};
const Address = ({ data: tempData }) => {
  const methods = useFormContext();
  const [isOpenCorrection, setOpenCorrection] = useState(false);

  const data = tempData?.group_profile_member || {};
  const [accordionState, setAccordionState] = useState({
    addres_ktp_condition: true,
    address_domicile: true,
  });

  const toggleAccordion = (key) => () => {
    setAccordionState({
      ...accordionState,
      [key]: !accordionState?.[key],
    });
  };

  const domicile_verification = useWatch({
    control: methods.control,
    name: "domicile_verification",
  });

  const fileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      methods.register("domicile_verification_latlong_file");
      methods.setValue(
        "domicile_verification_latlong_file",
        response.data.data.id
      );
    } catch (error) {
      throw error;
    }
  });

  return (
    <CardForm label="Alamat">
      <AnimatePresence>
        <AccordionButton
          key={"accordion-address-domicile-btn"}
          label={"Alamat KTP"}
          isOpen={accordionState?.address_domicile}
          onClick={toggleAccordion("address_domicile")}
        />

        {accordionState?.address_domicile && (
          <motion.div
            key={"accordion-address-domicile-content"}
            className="lg:grid lg:grid-cols-4 gap-4"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            }}
            exit={{ opacity: 0 }}
          >
            <div>
              <div className="font-semibold">Provinsi </div>
              <div>{data?.province?.name ?? "-"}</div>
            </div>
            <div>
              <div className="font-semibold">Kota / Kabupaten</div>
              <div>{data?.city?.name ?? "-"}</div>
            </div>
            <div>
              <div className="font-semibold">Kecamatan</div>
              <div>{data?.district?.name ?? "-"}</div>
            </div>
            <div>
              <div className="font-semibold">Kelurahan / Desa</div>
              <div>{data?.village?.name ?? "-"}</div>
            </div>
            <div className="col-span-4">
              <div className="font-semibold">Alamat</div>
              <div>{data?.address ?? "-"}</div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        <AccordionButton
          key={"accordion-address-domicile-btn"}
          label={"Alamat Domisili"}
          isOpen={accordionState?.addres_ktp_condition}
          onClick={toggleAccordion("addres_ktp_condition")}
        />

        {accordionState?.addres_ktp_condition && (
          <motion.div
            key={"accordion-address-domicile-content"}
            className="lg:grid lg:grid-cols-4 gap-4"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            }}
            exit={{ opacity: 0 }}
          >
            <div>
              <div className="font-semibold">Provinsi </div>
              <div>{data?.domicile_province?.name ?? "-"}</div>
            </div>
            <div>
              <div className="font-semibold">Kota / Kabupaten</div>
              <div>{data?.domicile_city?.name ?? "-"}</div>
            </div>
            <div>
              <div className="font-semibold">Kecamatan</div>
              <div>{data?.domicile_district?.name ?? "-"}</div>
            </div>
            <div>
              <div className="font-semibold">Kelurahan / Desa</div>
              <div>{data?.domicile_village?.name ?? "-"}</div>
            </div>
            <div>
              <div className="font-semibold">RT</div>
              <div>{data?.domicile_rt ?? "-"}</div>
            </div>
            <div>
              <div className="font-semibold">RW</div>
              <div>{data?.domicile_rw ?? "-"}</div>
            </div>
            <div>
              <div className="font-semibold">Berdomisili Sejak</div>
              <div>
                {data?.domicile_since_year
                  ? dayjs(data?.domicile_since_year).format("DD MMMM YYYY")
                  : "-"}
              </div>
            </div>
            <div>
              <div className="font-semibold">Lama Domisili</div>
              <div>{calculateDiffer(data?.domicile_since_year)}</div>
            </div>
            <div className="col-span-4">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                <div>
                  <div className="font-semibold">Longitude</div>
                  <div>{data?.longitude ?? "-"}</div>
                </div>
                <div>
                  <div className="font-semibold">Latitude</div>
                  <div>{data?.latitude ?? "-"}</div>
                </div>
                <div>
                  <div className="font-semibold">Geo Location</div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-1">
                    <Button
                      type={"button"}
                      className="bg-[#00BD52] text-white py-4  rounded-lg font-semibold text-[12px]"
                      label={
                        <div className="flex items-center gap-2 font-[14px]">
                          <RiMapPin2Fill />
                          Geotag Alamat
                        </div>
                      }
                      onClick={() => {
                        console.log(data)
                        window.open(
                          `https://www.google.com/maps?q=${data?.latitude}, ${data?.longitude}&z=4`,
                          "_blank",
                          "noopener noreferrer"
                        );
                      }}
                      // onClick={onContinueClick(entry)}
                    />

                    <Button
                      type={"button"}
                      className=" text-[#00BD52] py-4  rounded-lg font-semibold text-[12px]"
                      label={
                        <div className="flex items-center gap-2 font-[14px]">
                          Koreksi Geolocation
                        </div>
                      }
                      onClick={() => setOpenCorrection((curr) => !curr)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="font-semibold">Alamat</div>
              <div>{data?.domicile_address ?? "-"}</div>
            </div>
            {isOpenCorrection && (
              <div className="border space-y-5 rounded-md px-4 py-3 col-span-4">
                <div className="font-semibold text-lg">Koreksi Geolocation</div>
                <InputFormUpload
                  uploadFile={fileMutation}
                  controllerName={`domicile_verification_latlong_file_temp`}
                  label={"Upload Foto"}
                />
                <div className="flex space-x-5 items-end">
                  <div className="w-1/3 space-y-3">
                    <InputForm
                      label="Longitude"
                      controllerName="domicile_verification_longitude"
                      className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
                    />
                  </div>
                  <div className="w-1/3 space-y-3">
                    <InputForm
                      label="Latitude"
                      controllerName="domicile_verification_latitude"
                      className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
                    />
                  </div>
                  <div className="w-1/3">
                    <span className="text-transparent">-</span>
                    <GeotagLocationButton
                      label="Set Ulang"
                      icon={<RiMapPin2Fill className={"w-5 h-5 shrink-0"} />}
                      className={"!bg-primary-700 !text-white"}
                      onSuccess={(val) => {
                        methods.setValue(
                          "domicile_verification_longitude",
                          val.longitude
                        );
                        methods.setValue(
                          "domicile_verification_latitude",
                          val.latitude
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="border rounded-md px-4 py-3 col-span-4">
              <InputFormRadio
                label={"Kesimpulan Verifikasi"}
                controllerName={`domicile_verification`}
                className="flex items-center justify-between gap-4"
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Data Terverifikasi
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Data Belum Sesuai/ Ada Kesalahan
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
              {domicile_verification !== "" &&
                domicile_verification === false && (
                  <InputForm
                    controllerName={"domicile_verification_desk"}
                    textArea={true}
                    label={"Jelaskan kesalahan/ ketidak sesuaian datanya"}
                    className={"w-full rounded-md border p-3"}
                    wrapperClass={"mt-5"}
                  />
                )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </CardForm>
  );
};

const CoupleData = ({ data: tempData }) => {
  const methods = useFormContext();

  const data = tempData?.group_profile_member || {};
  const [accordionState, setAccordionState] = useState({
    addres_ktp_condition: true,
  });

  const toggleAccordion = (key) => () => {
    setAccordionState({
      ...accordionState,
      [key]: !accordionState?.[key],
    });
  };

  const couple_verification = useWatch({
    control: methods.control,
    name: "couple_verification",
  });

  return (
    <CardForm label="Data Pasangan">
      <AnimatePresence>
        <AccordionButton
          key={"accordion-address-domicile-btn"}
          label={"Alamat Domisili"}
          isOpen={accordionState?.addres_ktp_condition}
          onClick={toggleAccordion("addres_ktp_condition")}
        />

        {accordionState?.addres_ktp_condition && (
          <motion.div
            key={"accordion-address-domicile-content"}
            className="lg:grid lg:grid-cols-4 gap-4"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            }}
            exit={{ opacity: 0 }}
          >
            <div>
              <div className="font-semibold">Nomor NIK</div>
              <div>{data?.couple_identity_number ?? "-"}</div>
            </div>
            <div>
              <div className="font-semibold">Nama Lengkap Sesuai KTP</div>
              <div>{data?.couple_name ?? "-"}</div>
            </div>
            <div>
              <div className="font-semibold">Tempat Lahir</div>
              <div>{data?.couple_place_of_birth ?? "-"}</div>
            </div>
            <div>
              <div className="font-semibold">Tanggal Lahir</div>
              <div>
                {data?.couple_date_of_birth
                  ? dayjs(data?.couple_date_of_birth).format("DD MMMM YYYY")
                  : "-"}
              </div>
            </div>
            <div className="col-span-2">
              {data?.couple_identity_file && (
                <UploadedFileAsync
                  id={data?.couple_identity_file}
                  name="Dokumen KTP Pasangan"
                />
              )}
            </div>
            <div className="border rounded-md px-4 py-3 col-span-4">
              <InputFormRadio
                label={"Kesimpulan Verifikasi"}
                controllerName={`couple_verification`}
                className="flex items-center justify-between gap-4"
                values={[
                  {
                    label: (
                      <>
                        <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                        Data Terverifikasi
                      </>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <>
                        <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                        Data Belum Sesuai/ Ada Kesalahan
                      </>
                    ),
                    value: false,
                  },
                ]}
              />
              {couple_verification !== "" && couple_verification === false && (
                <InputForm
                  controllerName={"couple_verification_desk"}
                  textArea={true}
                  label={"Jelaskan kesalahan/ ketidak sesuaian datanya"}
                  className={"w-full rounded-md border p-3"}
                  wrapperClass={"mt-5"}
                />
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </CardForm>
  );
};

const FinanceInfo = ({ data }) => {
  const methods = useFormContext();

  const income_info_verification = useWatch({
    control: methods.control,
    name: "income_info_verification",
  });

  const isPayable = useMemo(() => {
    const income = data?.group_profile_member?.income_info?.[0]?.income || 0;
    const net_income = data?.group_profile_member?.business_net_income || 0;
    const outcome = data?.group_profile_member?.routine_outcome_per_month || 0;
    const result = 0.7 * (income * 12 + net_income * 12 - outcome * 12);
    return result ? formatCurrency(result) : "-";
  }, [data]);

  return (
    <CardForm label="Informasi Keuangan">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div>
          <div className="font-semibold">Perkiraan Pendapatan Per Bulan</div>
          <div>
            {data?.group_profile_member?.income_info?.[0]?.income
              ? formatCurrency(
                  data?.group_profile_member?.income_info?.[0]?.income
                )
              : "-"}
          </div>
        </div>
        <div>
          <div className="font-semibold">Pengeluaran Rutin per Bulan</div>
          <div>
            {data?.group_profile_member?.routine_outcome_per_month
              ? formatCurrency(
                  data?.group_profile_member?.routine_outcome_per_month
                )
              : "-"}
          </div>
        </div>
        <div>
          <div className="font-semibold">Penggunaan Pengeluaran Terbesar</div>
          <div>{data?.group_profile_member?.use_largest_outcome || "-"}</div>
        </div>
        <div>
          <div className="font-semibold">Berkemampuan Bayar</div>
          <div>{isPayable || "-"}</div>
        </div>
        <div className="border rounded-md px-4 py-3 col-span-3">
          <InputFormRadio
            label={"Kesimpulan Verifikasi"}
            controllerName={`income_info_verification`}
            className="flex items-center justify-between gap-4"
            values={[
              {
                label: (
                  <>
                    <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                    Data Terverifikasi
                  </>
                ),
                value: true,
              },
              {
                label: (
                  <>
                    <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                    Data Belum Sesuai/ Ada Kesalahan
                  </>
                ),
                value: false,
              },
            ]}
          />
          {income_info_verification !== "" &&
            income_info_verification === false && (
              <InputForm
                controllerName={"income_info_verification_desk"}
                textArea={true}
                label={"Jelaskan kesalahan/ ketidak sesuaian datanya"}
                className={"w-full rounded-md border p-3"}
                wrapperClass={"mt-5"}
              />
            )}
        </div>
      </div>
    </CardForm>
  );
};

const CheckHistory = () => {
  return (
    <CardForm label="Pengecekan Riwayat kredit & Bantuan Sosial">
      <div className="flex-col flex gap-4 ">
        <InputFormRadio
          label={"1. Bukan Penerima Bansos (Kemensos)"}
          labelClass={"w-full"}
          controllerName={`credit_history_status_social_assistance`}
          wrapperFieldClass="w-full"
          usingGrid={true}
          gridClassName="grid grid-cols-2 gap-4 w-full"
          className="flex items-center justify-between gap-4 flex-wrap"
          required
          values={[
            {
              label: "Iya",
              value: true,
            },
            {
              label: "Tidak",
              value: false,
            },
          ]}
        />
        <InputFormRadio
          label={"2. Pengecekan SIKP Tidak Memiliki Pinjaman"}
          labelClass={"w-full"}
          controllerName={`credit_history_status_sikp`}
          usingGrid={true}
          gridClassName="grid grid-cols-2 gap-4 w-full"
          wrapperFieldClass="w-full"
          required
          className="flex items-center justify-between gap-4 flex-wrap"
          values={[
            {
              label: "Iya",
              value: true,
            },
            {
              label: "Tidak",
              value: false,
            },
          ]}
        />
        <InputFormRadio
          label={
            "3. Pengecekan BI Checking/SUK/Perindo Tidak Memiliki Catatan Kredit Buruk"
          }
          labelClass={"w-full"}
          controllerName={`credit_history_status_bi_checking`}
          usingGrid={true}
          wrapperFieldClass="w-full"
          gridClassName="grid grid-cols-2 gap-4 w-full"
          className="flex items-center justify-between gap-4 flex-wrap"
          required
          values={[
            {
              label: "Iya",
              value: true,
            },
            {
              label: "Tidak",
              value: false,
            },
          ]}
        />
      </div>
    </CardForm>
  );
};

export default PotentialDebtorContent;
