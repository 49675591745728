import { useCallback, useEffect, useMemo, useState } from "react";
import {
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiEyeLine,
  RiMapPin2Fill,
} from "react-icons/ri";
import { AnimatePresence, motion } from "framer-motion";

import withFileService from "hocs/with-file-service";

import { Button, InputForm, InputFormRadio, InputFormUpload } from "components";
import Table, { TableWrapper } from "components/atoms/Table";
import CardForm from "components/molecules/CardForm";
import AccordionButton from "components/atoms/AccordionButton";
import GeotagLocationButton from "components/atoms/Button/GeotagLocationButton";
import { useFormContext, useWatch } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { fileUpload, getFile } from "services/fileService";

const getFileFromServiceCB = async (id) => {
  const response = await getFile(id);
  return response.data.data;
};

const LandDocuments = withFileService(({ type, data, getFileFromService }) => {
  const methods = useFormContext();
  const [accordionState, setAccordionState] = useState({
    business_prospect_condition: true,
  });
  const toggleAccordion = (key) => () => {
    setAccordionState({
      ...accordionState,
      [key]: !accordionState?.[key],
    });
  };

  const groupType = useMemo(() => {
    let result = "deferred";
    if (data?.request?.service_type?.toLowerCase() === "hasil hutan bukan kayu") {
      result = "non_timber_forest";
    } else if (data?.request?.service_type?.toLowerCase() === "komoditas non kehutanan") {
      result = "non_forest_commodities";
    }
    return result;
  }, [data?.request]);

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "name",
        title: "Nama Dokumen",
      },
      {
        key: "desc",
        title: "Deskripsi",
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => {
          return (
            <>
              <Button
                theme="primary"
                variant="icon"
                className="flex items-center"
                label={
                  <>
                    <RiEyeLine className="text-lg mr-3" />
                    Lihat Dokumen
                  </>
                }
                onClick={() => {
                  window.open(item.selected_file.url, "_blank").focus();
                }}
              />
            </>
          );
        },
      },
    ];
  }, []);

  const getAllFile = useCallback(() => {
    if (
      !data?.request?.[groupType]?.document ||
      data?.request?.[groupType]?.document?.length === 0
    )
      return;

    for (let doc of data?.request?.[groupType]?.document) {
      if (
        doc.file === "" ||
        typeof doc.file === "undefined" ||
        doc.selected_file
      ) {
        continue;
      }

      getFileFromService(doc.file, {
        onSuccess: (res) => {
          doc.selected_file = res;
        },
        onError: (err) => console.log(err),
      });
    }
  }, [groupType, data?.request?.[groupType]?.document, getFileFromService]);

  useEffect(() => {
    getAllFile();
  }, [getAllFile]);

  const fileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      if (file?.controllerName === "tabulation_land_real_document_temp") {
        methods.register("tabulation_land_real_document");
        methods.setValue(
          "tabulation_land_real_document",
          response.data.data.id
        );
      }
    } catch (error) {
      throw error;
    }
  });

  const tabulation_land_verification = useWatch({
    control: methods.control,
    name: "tabulation_land_verification",
  });

  const { tabulation_land_real_document } = data?.request?.field_analysts || {
    tabulation_land_real_documen: null,
  };

  useEffect(() => {
    const promises = [];
    if (
      tabulation_land_real_document &&
      tabulation_land_real_document !== "string"
    ) {
      promises.push(
        getFileFromServiceCB(tabulation_land_real_document).then((data) => {
          return {
            formId: "tabulation_land_real_document_temp",
            type: data?.mimeType,
            ...data,
          };
        })
      );
    }
    if (promises?.length > 0) {
      Promise.all(promises).then((values) => {
        values.map((x) => {
          methods.resetField(x.formId, { defaultValue: x });
        });
      });
    }
  }, [tabulation_land_real_document]);

  const tabulationLandLegalityField = useWatch({
    control: methods.control,
    name: "tabulation_land_legality",
  });

  return (
    <CardForm label="Verifikasi Penguasaan Lahan">
      <div className="space-y-5">
        <TableWrapper className="w-full">
          <Table
            headers={headers}
            items={data?.request?.[groupType]?.document ?? []}
            isLoading={false}
          />
        </TableWrapper>
        <AnimatePresence>
          <AccordionButton
            key={"accordion-business-prospect-btn"}
            label={"Tabulasi Penguasaan Lahan"}
            isOpen={accordionState?.business_prospect_condition}
            onClick={toggleAccordion("business_prospect_condition")}
          />

          {accordionState?.business_prospect_condition && (
            <motion.div
              key={"accordion-business-prospect-content"}
              className=" gap-4 space-y-4 "
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              exit={{ opacity: 0 }}
            >
              <InputFormUpload
                uploadFile={fileMutation}
                controllerName={`tabulation_land_real_document_temp`}
                label={"Unggah Foto Pemohon dengan Dokumen Asli"}
              />
              <div className="flex gap-4">
                <InputFormRadio
                  required
                  label={
                    "1. Apakah Nama Pemohon dengan Nama pada Dokumen Legalitas Penguasaan Sesuai?"
                  }
                  controllerName={"tabulation_land_legality"}
                  values={[
                    {
                      label: "Ya",
                      value: true,
                    },
                    {
                      label: "Tidak",
                      value: false,
                    },
                  ]}
                />
              </div>
              {tabulationLandLegalityField !== "" &&
                tabulationLandLegalityField === true && (
                  <div className="pl-4 space-y-4">
                    <div className="flex gap-4">
                      <InputFormRadio
                        required
                        label={
                          "1.1. Apakah Ada Surat Keterangan Riwayat Tanah?"
                        }
                        controllerName={"tabulation_land_history"}
                        values={[
                          {
                            label: "Ya",
                            value: true,
                          },
                          {
                            label: "Tidak",
                            value: false,
                          },
                        ]}
                      />
                    </div>
                    <div className="flex gap-4">
                      <InputFormRadio
                        required
                        label={
                          "1.2. Apakah Ada Surat Keterangan Bebas Sengketa?"
                        }
                        controllerName={"tabulation_land_have_dispute_free"}
                        values={[
                          {
                            label: "Ya",
                            value: true,
                          },
                          {
                            label: "Tidak",
                            value: false,
                          },
                        ]}
                      />
                    </div>
                    <div className="flex gap-4">
                      <InputFormRadio
                        required
                        label={"1.3. Apakah Ada Surat Pernyataan Jual Beli?"}
                        controllerName={"tabulation_land_have_selling"}
                        values={[
                          {
                            label: "Ya",
                            value: true,
                          },
                          {
                            label: "Tidak",
                            value: false,
                          },
                        ]}
                      />
                    </div>
                    <div className="flex gap-4">
                      <InputFormRadio
                        required
                        label={"1.4. Apakah Ada Surat Keterangan Hibah?"}
                        controllerName={"tabulation_land_have_grant"}
                        values={[
                          {
                            label: "Ya",
                            value: true,
                          },
                          {
                            label: "Tidak",
                            value: false,
                          },
                        ]}
                      />
                    </div>
                  </div>
                )}

              <InputForm
                controllerName="tabulation_land_no_percil"
                label="2. Nomor Persil"
                placeholder="Nomor Persil"
                required={true}
                wrapperClass="col-span-2"
                className="rounded-md w-full px-4 py-3 border"
              />
              <InputForm
                controllerName="tabulation_land_width_area"
                label="3. Luas"
                placeholder="Luas"
                required={true}
                wrapperClass="col-span-2"
                className="rounded-md w-full px-4 py-3 border"
                isNumber={true}
                onKeyPress={(event) => {
                  const ASCIICode = event.which ? event.which : event.keyCode;
                  if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                    return;
                  event.preventDefault();
                }}
                rightIcon="Meter"
              />
              <div className="flex space-x-4 items-end">
                <div className="w-1/3">
                  <InputForm
                    label="Longitude"
                    controllerName="tabulation_land_longitude"
                    className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
                  />
                </div>
                <div className="w-1/3">
                  <InputForm
                    label="Latitude"
                    controllerName="tabulation_land_latitude"
                    className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
                  />
                </div>
                <div className="flex justify-start w-1/3">
                  <span className="text-transparent">-</span>
                  <GeotagLocationButton
                    label="Geotag Lahan"
                    icon={<RiMapPin2Fill className={"w-5 h-5 shrink-0"} />}
                    className={"!bg-primary-700 !text-white"}
                    onSuccess={(val) => {
                      methods.setValue(
                        "tabulation_land_longitude",
                        val.longitude
                      );
                      methods.setValue(
                        "tabulation_land_latitude",
                        val.latitude
                      );
                    }}
                  />
                </div>
              </div>
              <div className="border rounded-md px-4 py-3 col-span-4">
                <InputFormRadio
                  label={"Hasil Verifikasi"}
                  controllerName={`tabulation_land_verification`}
                  className="flex items-center justify-between gap-4"
                  values={[
                    {
                      label: (
                        <>
                          <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                          Data Terverifikasi
                        </>
                      ),
                      value: true,
                    },
                    {
                      label: (
                        <>
                          <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                          Data Belum Sesuai/ Ada Kesalahan
                        </>
                      ),
                      value: false,
                    },
                  ]}
                />
                {tabulation_land_verification !== "" &&
                  tabulation_land_verification === false && (
                    <InputForm
                      controllerName={"tabulation_land_verification_desc"}
                      textArea={true}
                      label={"Jelaskan kesalahan/ ketidak sesuaian datanya"}
                      className={"w-full rounded-md border p-3"}
                      wrapperClass={"mt-5"}
                    />
                  )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </CardForm>
  );
});

export default LandDocuments;
