import React, { useCallback, useState } from "react";
import ReactSelect from "react-select";
import { useFormContext, Controller } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { getCommitteeMeetingAgendaDetail } from "services/fdb/committee";

let NEXT_PAGE = null;

const SelectMinutes = ({
  placeholder = "No Risalah",
  meeting_circular_minutes_number,
  ...props
}) => {
  const { name, customOnChange } = props;
  const { control } = useFormContext();

  const [isOpenMinutes, setOpenMinutes] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [minutesOptions, setMinutesOptions] = useState([]);

  const { id: meetingId } = meeting_circular_minutes_number || { id: null };
  const { status } = useQuery(
    ["minutes", meetingId, { is_committee: true }],
    getCommitteeMeetingAgendaDetail,
    {
      enabled: isOpenMinutes && !!meetingId,
      onSuccess: (datas) => {
        if (datas?.minutes?.length) {
          setMinutesOptions((curr) => {
            const newDatas = datas?.minutes.map((x) => ({
              ...x,
              value: x.risalah_id,
              label: x.number,
            }));
            return [...curr, ...newDatas];
          });
        }
      },
      select: (data) => data?.data?.data,
    }
  );

  const onMenuOpen = useCallback(() => setOpenMinutes(true), []);

  const onMenuClose = useCallback(() => {
    setOpenMinutes(false);
    setMinutesOptions([]);
    setCurrPage(1);
    NEXT_PAGE = null;
  }, []);

  const getMoreOptions = useCallback(() => {
    if (NEXT_PAGE) setCurrPage(NEXT_PAGE);
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => (
        <div className="w-full">
          <ReactSelect
            {...props}
            options={minutesOptions}
            isLoading={status === "loading"}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            onMenuScrollToBottom={getMoreOptions}
            placeholder={placeholder}
            value={value}
            onChange={(val) => {
              onChange(val);
              if (customOnChange) customOnChange(val);
            }}
            onBlur={onBlur}
            styles={{
              control: (provided, state) => ({
                ...provided,
                borderColor: invalid
                  ? "#F04438"
                  : state.isFocused
                  ? "#01A24A"
                  : "#EAECF0",
                ":hover": {
                  ...provided[":hover"],
                  borderColor: invalid
                    ? "#F04438"
                    : state.isFocused
                    ? "#01A24A"
                    : "#EAECF0",
                },
                boxShadow: "unset",
                "&:hover": {
                  outline: "unset",
                },
                "&:focus": {
                  border: "1px solid #01A24A",
                },
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                padding: "0.25rem 1rem",
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? "#01A24A" : "white",
                color: state.isSelected ? "white" : "black",
                "&:hover": {
                  backgroundColor: state.isSelected ? "#01A24A" : "#F3F4F6",
                  color: state.isSelected ? "white" : "black",
                },
                zIndex: 9999,
              }),
              multiValue: (provided, state) => ({
                ...provided,
                backgroundColor: "#01A24A",
                color: "white",
              }),
              multiValueLabel: (provided, state) => ({
                ...provided,
                color: "white",
              }),
              multiValueRemove: (provided, state) => ({
                ...provided,
                color: "white",
                "&:hover": {
                  backgroundColor: "#01A24A",
                  color: "white",
                },
              }),
            }}
            menuPortalTarget={document.body}
          />
          {invalid && (
            <div className="text-xs text-error-600 mt-2">{error?.message}</div>
          )}
        </div>
      )}
    />
  );
};

export default React.memo(SelectMinutes);
