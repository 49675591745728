import { useMutation, useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { Button, InputForm, InputFormUpload } from "components";
import Table, { TableWrapper } from "components/atoms/Table";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import { Pagination } from "components/v2/shared/pagination";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { RiCheckLine, RiCircleFill, RiCloseLine } from "react-icons/ri";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getDetailApprovalOffers } from "services/fdb/offers";

import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";
import { find } from "lodash";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { fileUpload } from "services/fileService";
import { formatCurrency } from "helpers";
import { updateOfferApplicationApproval } from "services/fdb/committee";
import { enqueueSnackbar } from "notistack";

const FDB_SCHEME = {
  DIRECT: "Langsung",
  INDIRECT: "Tidak Langsung",
};

const GRACE_PERIOD = {
  ADJUSTED_TO_THE_BUSINESS_CYCLE: "Disesuaikan Dengan Siklus Usaha",
  GRACE_PERIOD_CUSTOMIZATION: "Kustomisasi Grace Period",
};

const PERIOD_UNIT = {
  YEAR: "Tahun",
  MONTH: "Bulan",
  DAY: "Hari",
};

const validationSchema = Yup.object().shape({
  document_temp: Yup.mixed()
    .test(
      "cid_img",
      ({ label }) =>
        validationErrorMsg.mixed.required.replace("${path}", label),
      (value, context) => {
        const allowedTypes = ["pdf"];
        if (value) {
          const data = find(allowedTypes, (at) => value.type.includes(at));
          if (!data) {
            return context.createError({
              message: validationCustomErrorMsg.isFiletype(
                allowedTypes.join(", ")
              )({ label: "Keputusan Direktur Utama" }),
            });
          }
          if (value.size > 10 * 1024 * 1024) {
            return context.createError({
              message: validationCustomErrorMsg.isFileSize("10MB")({
                label: "Keputusan Direktur Utama",
              }),
            });
          }

          return true;
        }

        // otherwise, return false to render the field required
        return false;
      }
    )
    .required()
    .label("Keputusan Direktur Utama"),

  about: Yup.string().required().label("Tentang"),
  director_decision_number: Yup.string()
    .required()
    .label("No Keputusan Direktur Utama"),
  meeting_circular_number_minutes: Yup.string()
    .required()
    .label("No Berita Acara"),
});

const CommitteeMinutesApprovalDDDetail = () => {
  const [searchParams] = useSearchParams();
  const { id } = useParams();

  const { data: approvalDetail, isLoading } = useQuery({
    queryKey: ["offer-application-approval-detail", id],
    queryFn: async ({ queryKey }) => {
      const { data } = await getDetailApprovalOffers(queryKey[1]);
      return data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const breadcrumbs = useMemo(
    () => [
      {
        label: "Approval Permohonan Pembiayaan",
        path: "/committee/approval",
      },
      {
        label:
          searchParams.get("type") === "verifikasi" ? "Verifikasi" : "Detail",
        path: "",
      },
    ],
    [searchParams]
  );

  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const mutateOfferApproval = useMutation({
    mutationFn: updateOfferApplicationApproval,
  });

  const onApprove = (payload) => {
    delete payload?.group_profile_name;
    delete payload?.meeting_circular_number_minutes;
    delete payload?.document_temp;

    mutateOfferApproval.mutate(
      {
        id,
        payload,
        params: {
          is_approve: true,
        },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar({ variant: "success", message: "Berhasil diterima" });
          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({ variant: "error", message: "Terjadi kesalahan" });
        },
      }
    );
  };

  const onReject = (payload) => {
    delete payload?.group_profile_name;
    delete payload?.meeting_circular_number_minutes;
    delete payload?.document_temp;

    mutateOfferApproval.mutate(
      {
        id,
        payload,
        params: {
          is_approve: false,
        },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar({ variant: "success", message: "Berhasil ditolak" });
          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({ variant: "error", message: "Terjadi kesalahan" });
        },
      }
    );
  };

  useEffect(() => {
    if (approvalDetail) {
      methods.reset({
        group_profile_name: approvalDetail?.group_profile_name,
        meeting_circular_number_minutes:
          approvalDetail?.meeting_circular_number_minutes,
      });
    }
  }, [approvalDetail, methods]);

  return (
    <div className="space-y-6">
      <BreadCrumbs routes={breadcrumbs} />
      <FormProvider {...methods}>
        <PresidentDirectorsDecision data={approvalDetail} />
        <CommitteeDecision data={approvalDetail?.directorate ?? []} />
        <CommitteeMinutes data={approvalDetail?.committee_minutes ?? {}} />
        <ListDebtors data={approvalDetail?.debtor ?? []} />

        {searchParams.get("type") === "verifikasi" && (
          <div className="sticky bottom-4 flex justify-center gap-3 w-full p-5 bg-white border rounded-md  text-error-600">
            <Button
              className="p-4 border rounded-lg w-[300px] flex justify-center"
              type="button"
              label={
                <div className="flex items-center gap-2 text-sm font-semibold">
                  <RiCloseLine size={16} strokeWidth={1.5} />
                  Tolak
                </div>
              }
              onClick={() => methods.handleSubmit(onReject)()}
            />

            <Button
              className="p-4 border rounded-lg w-[300px] flex justify-center bg-primary-600 hover:bg-primary-700 text-white"
              type="button"
              label={
                <div className="flex items-center gap-2 text-sm font-semibold">
                  <RiCheckLine size={16} strokeWidth={1.5} />
                  Terima
                </div>
              }
              onClick={() => methods.handleSubmit(onApprove)()}
            />
          </div>
        )}
      </FormProvider>
    </div>
  );
};

const PresidentDirectorsDecision = ({ data }) => {
  const { register, setValue } = useFormContext();
  // useEffect(() => {
  //   const temp = {};
  //   if (data?.group_profile_name)
  //     temp["group_profile_name"] = data?.group_profile_name;
  //   if (data?.meeting_circular_number_minutes)
  //     temp["meeting_circular_number_minutes"] =
  //       data?.meeting_circular_number_minutes;

  //   if (data?.committee_minutes_id)
  //     temp["committee_minutes_id"] = data?.committee_minutes_id;
  //   if (data?.group_profile_id)
  //     temp["group_profil_id"] = data?.group_profile_id;
  //   if (data?.meeting_agenda_id)
  //     temp["committee_meeting_agenda_id"] = data?.meeting_agenda_id;

  //   reset(temp);
  // }, [data, reset]);

  const fileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      register("file");
      setValue("file", response.data.data.id);
      // if (file?.controllerName === "document_temp") {
      // }
    } catch (error) {
      throw error;
    }
  });

  return (
    <CardForm label="Keputusan Direktur Utama">
      <div className="flex flex-col gap-5">
        <div className="grid grid-cols-2 gap-5">
          <InputForm
            controllerName="meeting_circular_number_minutes"
            label="No Berita Acara"
            disabled
            className="w-full"
            required
          />
          <InputForm
            controllerName="group_profile_name"
            label="Nama Kelompok"
            disabled
            className="w-full"
          />
        </div>

        <InputForm
          controllerName="director_decision_number"
          label="No Keputusan Direktur Utama"
          className="w-full"
          required
        />

        <InputForm
          className="w-full p-3 border"
          controllerName="about"
          label="Tentang"
          required
          textArea
        />

        <InputFormUpload
          uploadFile={fileMutation}
          fileType="PDF"
          controllerName="document_temp"
          maxSize={10 * 1024 * 1024}
          className="inset-0"
          label="Unggah Keputusan Direktur Utama"
        />
      </div>
    </CardForm>
  );
};

const dummyDecission = [
  {
    no: 1,
    name: "John Doe",
    position: "Direktur Penyaluran Dana",
    position_committee: "Ketua merangkap anggota",
    attendance_status: "Hadir",
    agreement: "Setuju",
    notes: "Catatan",
  },
  {
    no: 2,
    name: "John Doe",
    position: "Kepala Divisi Penyaluran Pinjaman, Direktorat Penyaluran Dana",
    position_committee: "Sekretaris merangkap anggota",
    attendance_status: "Hadir",
    agreement: "Setuju",
    notes: "Catatan",
  },
  {
    no: 3,
    name: "John Doe",
    position:
      "Kepala Divisi Anggaran dan Akuntansi, Direktorat Keuangan Umum dan Sistem Informasi",
    position_committee: "Anggota",
    attendance_status: "Hadir",
    agreement: "Setuju",
    notes: "Catatan",
  },
  {
    no: 4,
    name: "John Doe",
    position:
      "Kepala Divisi Penghimpunan dan Pengembangan Layanan, Direktorat Penghimpunan dan Pengembangan Dana",
    position_committee: "Anggota",
    attendance_status: "Hadir",
    agreement: "Setuju",
    notes: "Catatan",
  },
  {
    no: 5,
    name: "John Doe",
    position:
      "Kepala Divisi Monitoring, Evaluasi, dan Pembinaan, Direktorat Penyaluran Dana",
    position_committee: "Anggota",
    attendance_status: "Tidak Hadir",
    agreement: "-",
    notes: "Catatan",
  },
  {
    no: 6,
    name: "John Doe",
    position: "Kepala Divisi Perjanjian, Direktorat Hukum dan Manajemen Risiko",
    position_committee: "Anggota",
    attendance_status: "Hadir",
    agreement: "Tidak Setuju",
    notes: "Catatan",
  },
  {
    no: 7,
    name: "John Doe",
    position:
      "Kepala Divisi ManajemenRisiko, Direktorat Hukum dan Manajemen Risiko",
    position_committee: "Ketua merangkap anggota",
    attendance_status: "Hadir",
    agreement: "Setuju",
    notes: "Catatan",
  },
];

const badgeAttendance = {
  true: "border-primary-600 bg-primary-50 text-primary-600",
  false: "border-error-600 bg-error-50 text-error-600",
};

const badgeAggrement = {
  true: "bg-primary-50 text-primary-600",
  false: "bg-error-50 text-error-600",
};

const CommitteeDecision = ({ data }) => {
  const headers = useMemo(
    () => [
      {
        key: "no",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "name",
        title: "Nama",
      },
      {
        key: "position",
        title: "Jabatan",
        render: ({ item }) => <div className="">{item.position}</div>,
      },
      {
        key: "committee_position",
        title: "Kedudukan Dalam Komite",
      },
      {
        key: "is_attend",
        title: "Status Kehadiran",
        render: ({ item }) => (
          <div
            className={clsx(
              "border-2 px-2 py-[2px] text-xs font-medium rounded-full",
              badgeAttendance[item.is_attend]
            )}
          >
            {item.is_attend ? "Hadir" : "Tidak Hadir"}
          </div>
        ),
      },
      {
        key: "is_approved",
        title: "Persetujuan",
        render: ({ item }) => (
          <div
            className={clsx(
              " px-2 py-[2px] text-xs font-medium rounded-full",
              badgeAggrement[item.is_approved]
            )}
          >
            {item.is_approved ? "Setuju" : "Tidak Setuju"}
          </div>
        ),
      },
      {
        key: "notes",
        title: "Catatan Persetujuan",
      },
    ],
    []
  );

  return (
    <CardForm label="Keputusan Komite">
      <TableWrapper>
        <Table headers={headers} items={data} />
      </TableWrapper>
    </CardForm>
  );
};

const CommitteeMinutes = ({ data }) => {
  return (
    <CardForm label="Berita Acara Komite">
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Jenis Layanan FDB</h5>
          <p>{data?.provision_service ?? "-"}</p>
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Skema Penyaluran FDB</h5>
          <p>
            {data?.fdb_distribution_scheme
              ? FDB_SCHEME[data?.fdb_distribution_scheme]
              : "-"}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Tujuan FDB</h5>
          {data?.fdb_objectives ? (
            <div
              dangerouslySetInnerHTML={{ __html: data?.fdb_objectives }}
            ></div>
          ) : (
            <p>-</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Grace Period</h5>
          <p>{data?.grace_period ? GRACE_PERIOD[data?.grace_period] : "-"}</p>
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Jangka Waktu Pinjaman FDB</h5>
          {data?.fdb_loan_term ? (
            <div
              dangerouslySetInnerHTML={{ __html: data?.fdb_loan_term }}
            ></div>
          ) : (
            <p>-</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Jangka Waktu Pembiayaan FDB</h5>
          <p>
            {data?.fdb_financing_term_value
              ? `${data?.fdb_financing_term_value} ${
                  PERIOD_UNIT[data?.fdb_financing_term_period || "YEAR"]
                }`
              : "-"}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Jaminan</h5>
          {data?.guarantee ? (
            <div dangerouslySetInnerHTML={{ __html: data?.guarantee }}></div>
          ) : (
            <p>-</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Tarif layanan</h5>
          <p>
            {data?.percentage_service_rate_per_year
              ? `${data?.percentage_service_rate_per_year} %`
              : "-"}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Penyaluran FDB</h5>
          {data?.fdb_distribution?.length > 0 ? (
            <ul className="list-decimal list-inside">
              {data?.fdb_distribution
                ?.sort((a, b) => {
                  return a.orderNumber - b.orderNumber;
                })
                .map((x, key) => {
                  return (
                    <>
                      <li key={key}>{x.value}</li>
                      {x?.sub?.length > 0 ? (
                        <ul className="list-[lower-alpha] list-inside pl-3">
                          {x?.sub
                            ?.sort((a, b) => {
                              return a.orderNumber - b.orderNumber;
                            })
                            .map((sub, idx) => (
                              <>
                                <li key={`sub-${idx}`}>{sub.value}</li>
                                {sub?.sub_sub?.length > 0 ? (
                                  <ul className="list-[lower-alpha] list-inside pl-3">
                                    {sub?.sub_sub
                                      ?.sort((a, b) => {
                                        return a.orderNumber - b.orderNumber;
                                      })
                                      .map((ss, i) => (
                                        <li key={`sub_sub-${i}`}>
                                          {ss?.value}
                                        </li>
                                      ))}
                                  </ul>
                                ) : null}
                              </>
                            ))}
                        </ul>
                      ) : null}
                    </>
                  );
                })}
            </ul>
          ) : (
            <p>-</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Pengembalian FDB</h5>
          {data?.fdb_return?.length > 0 ? (
            <ul className="list-decimal list-inside">
              {data?.fdb_return
                ?.sort((a, b) => {
                  return a.orderNumber - b.orderNumber;
                })
                .map((x, key) => {
                  return (
                    <>
                      <li key={key}>{x.value}</li>
                      {x?.sub?.length > 0 ? (
                        <ul className="list-[lower-alpha] list-inside pl-3">
                          {x?.sub
                            ?.sort((a, b) => {
                              return a.orderNumber - b.orderNumber;
                            })
                            .map((sub, idx) => (
                              <li key={`sub-${idx}`}>{sub.value}</li>
                            ))}
                        </ul>
                      ) : null}
                    </>
                  );
                })}
            </ul>
          ) : (
            <p>-</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">Objek yang dibiayai</h5>
          {data?.the_object_being_financed ? (
            <div
              dangerouslySetInnerHTML={{
                __html: data?.the_object_being_financed,
              }}
            ></div>
          ) : (
            <p>-</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">
            Hal-hal yang harus dilakukan (Positive Covenants)
          </h5>
          {data?.positive_covenants?.length > 0 ? (
            <ul className="list-decimal list-inside">
              {data?.positive_covenants
                ?.sort((a, b) => {
                  return a.orderNumber - b.orderNumber;
                })
                .map((x, key) => (
                  <li key={key}>{x.value}</li>
                ))}
            </ul>
          ) : (
            <p>-</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-semibold">
            Hal-hal yang tidak boleh dilaksanakan (Negative Covenants)
          </h5>
          {data?.negative_covenants?.length > 0 ? (
            <ul className="list-decimal list-inside">
              {data?.negative_covenants
                ?.sort((a, b) => {
                  return a.orderNumber - b.orderNumber;
                })
                .map((x, key) => (
                  <li key={key}>{x.value}</li>
                ))}
            </ul>
          ) : (
            <p>-</p>
          )}
        </div>
      </div>
    </CardForm>
  );
};

const badgeReturn = {
  Revolving: "bg-primary-50 text-primary-600",
  "Non - Revolving": "bg-error-50 text-error-600",
};

const badgeDisbursement = {
  at_once: "Sekaligus",
  stage: "Bertahap",
};

const ListDebtors = ({ data }) => {
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });

  const headers = useMemo(
    () => [
      {
        key: "index",
        title: "No",
        render: ({ index }) => {
          // const no =
          //   parseInt(data?.meta?.current_page) *
          //     parseInt(data?.meta?.per_page) -
          //   parseInt(data?.meta?.per_page) +
          //   index +
          //   1;

          const no = index + 1;

          return no;
        },
      },
      {
        key: "group_profile_name",
        title: "KTHR",
      },
      {
        key: "name",
        title: "Nama Pemohon",
      },
      {
        key: "business_type",
        title: "Jenis Usaha Yang dibiayai",
        // render: ({ item }) => {
        //   return (
        //     <div
        //       className={clsx(
        //         "flex items-center text-xs gap-1 px-[6px] py-0.5 rounded-full font-medium",
        //         badgeStyle[item.status]
        //       )}
        //     >
        //       <RiCircleFill size={8} />
        //       {capitalize(item.status)}
        //     </div>
        //   );
        // },
      },
      {
        key: "loan_value",
        title: "Nilai Pembiayaan",
        render: ({ item }) => {
          return formatCurrency(item.loan_value);
        },
      },
      {
        key: "disbursement",
        title: "Penyaluran Pembiayaan Pinjaman",
        render: ({ item }) => {
          return (
            <span
              className={clsx(
                "py-0.5 px-2 rounded-full font-medium text-xs",
                badgeDisbursement[item.disbursement] === "Sekaligus" &&
                  "bg-primary-50 text-primary-600",
                badgeDisbursement[item.disbursement] === "Bertahap" &&
                  "bg-error-50 text-error-600"
              )}
            >
              {badgeDisbursement[item.disbursement]}
            </span>
          );
        },
      },
      {
        key: "grace_period",
        title: "Grace Period",
        render: ({ item }) =>
          item?.grace_period.includes("null") ? "-" : item?.grace_period,
      },
      {
        key: "installment",
        title: "Skema Pengembalian",
        render: ({ item }) => (
          <div
            className={clsx(
              "px-2 py-[2px] text-xs font-medium flex gap-1 items-center",
              badgeReturn[item.installment]
            )}
          >
            <RiCircleFill size={8} />
            {item.installment}
          </div>
        ),
      },
    ],
    []
  );

  return (
    <CardForm label="Daftar Debitur">
      <TableWrapper className="mt-4">
        <Table
          headers={headers}
          items={data ?? []}
          // isLoading={isLoading}
        />
        {/* <Pagination
          page={1}
          limit={filters.limit}
          total={0}
          totalPage={1}
          onLimitChange={(e) => setFilters((prev) => ({ ...prev, limit: e }))}
          onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
        /> */}
      </TableWrapper>
    </CardForm>
  );
};

export default CommitteeMinutesApprovalDDDetail;
