import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, InputForm, InputFormRadio, InputFormUpload } from "components";
import {
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
} from "components/atoms/Modal";
import { formatCurrency, getErrorMessage } from "helpers";
import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";
import { find, isEmpty } from "lodash";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiEyeLine,
} from "react-icons/ri";
import { useParams } from "react-router";
import { fileUpload } from "services/fileService";
import { postVerificationCollateral } from "services/fdb/field-analis";
import * as Yup from "yup";
import withFileService from "hocs/with-file-service";

const VerificationCollateralModal = withFileService(
  ({ status, data, serviceType, ...rest }) => {
    const { getFileFromService } = rest;

    const queryClient = useQueryClient();
    const { applicantId } = useParams();

    const [open, setOpen] = React.useState(false);

    const ButtonRendered = useMemo(() => {
      if (status === "1") {
        return (
          <Button type="button" label="Verifikasi Jaminan" theme="primary" />
        );
      }
      return (
        <Button
          type="button"
          variant="icon"
          label={<RiEyeLine className="text-lg" />}
        />
      );
    }, [status]);

    const validationSchema = useMemo(
      () =>
        Yup.object().shape({
          verification: Yup.bool()
            .transform((originalValue) =>
              originalValue === "" ? undefined : originalValue
            )
            .required()
            .label("Hasil Verifikasi"),
          fileTemp: Yup.mixed()
            .test(
              "cid_img",
              ({ label }) =>
                validationErrorMsg.mixed.required.replace("${path}", label),
              (value, context) => {
                const allowedTypes = ["jpg", "png", "jpeg"];

                if (status === "1") {
                  // if has value, validate the file
                  if (value) {
                    const data = find(allowedTypes, (at) =>
                      value.type.includes(at)
                    );
                    if (!data) {
                      return context.createError({
                        message: validationCustomErrorMsg.isFiletype(
                          allowedTypes.join(", ")
                        )({ label: "Foto" }),
                      });
                    }
                    if (value.size > 10 * 1024 * 1024) {
                      return context.createError({
                        message: validationCustomErrorMsg.isFileSize("10MB")({
                          label: "Foto",
                        }),
                      });
                    }

                    return true;
                  }

                  // otherwise, return false to render the field required
                  return false;
                }
                return true;
              }
            )
            .when([], ([value], schema) => {
              if (status === "1") {
                return schema.required().label("Foto");
              }
              return schema.notRequired();
            }),
          fileRevisionTemp: Yup.mixed()
            .test(
              "cid_img",
              ({ label }) =>
                validationErrorMsg.mixed.required.replace("${path}", label),
              (value, context) => {
                const allowedTypes = ["jpg", "png", "jpeg"];
                if (status === "3") {
                  // if has value, validate the file
                  if (value) {
                    const data = find(allowedTypes, (at) =>
                      value.type.includes(at)
                    );
                    if (!data) {
                      return context.createError({
                        message: validationCustomErrorMsg.isFiletype(
                          allowedTypes.join(", ")
                        )({ label: "Foto" }),
                      });
                    }
                    if (value.size > 10 * 1024 * 1024) {
                      return context.createError({
                        message: validationCustomErrorMsg.isFileSize("10MB")({
                          label: "Foto",
                        }),
                      });
                    }

                    return true;
                  } else {
                    // otherwise, return false to render the field required
                    return false;
                  }
                }
                return true;
              }
            )
            .when("verification", ([value], schema) => {
              if (status === "3" || verification === false) {
                return schema.required().label("Foto");
              }
              return schema.notRequired();
            }),
        }),
      [status]
    );

    const methods = useForm({
      resolver: yupResolver(validationSchema),
      mode: "onChange",
    });

    const typeOfService = useMemo(() => {
      let result = "deferred";
      if (serviceType === "non_timber_forest") {
        result = "timber";
      } else if (serviceType === "non_forest_commodities") {
        result = "commodity";
      }
      return result;
    }, [serviceType]);

    const propToGet = useMemo(() => {
      let object = "deferred_field_analyst_colleteral";
      if (typeOfService === "timber") {
        object = "timber_field_analyst_collateral";
      } else if (typeOfService === "commodity") {
        object = "collaterals";
      }
      return object;
    }, [typeOfService]);

    const onSubmit = (payload) => {
      const dataToSend = {
        ...payload,
        fee: data?.collateral_fee ?? 0,
        file: payload?.file || data?.[`${propToGet}`]?.file,
        type: typeOfService,
        revision_fee: payload?.revision_fee ?? 0,
        revision_type: typeOfService,
      };
      delete dataToSend.fileTemp;
      delete dataToSend.fileRevisionTemp;
      postVerification.mutate(dataToSend);
    };

    const fileMutation = useMutation(async (file) => {
      try {
        const response = await fileUpload(file);
        methods.register("file");
        methods.setValue("file", response.data.data.id);
      } catch (error) {
        throw error;
      }
    });

    const revisionFileMutation = useMutation(async (file) => {
      try {
        const response = await fileUpload(file);
        methods.register("revision_file");
        methods.setValue("revision_file", response.data.data.id);
      } catch (error) {
        throw error;
      }
    });

    const postVerification = useMutation({
      mutationKey: ["post-verification-collaterla", data.id],
      mutationFn: (payload) => {
        const res = postVerificationCollateral(data.id, typeOfService, payload);
        return res;
      },
      onSuccess: (res) => {
        queryClient.invalidateQueries([
          "field-analyst-applicant-collateral",
          applicantId,
        ]);
        methods.reset();
        enqueueSnackbar("Data berhasil disimpan", { variant: "success" });
        setOpen(false);
      },
      onError: (error) => {
        enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        });
      },
    });

    useEffect(() => {
      if (!isEmpty(data)) {
        const temp = {};

        if (!isEmpty(data?.[`${propToGet}`])) {
          temp["verification"] = data?.[`${propToGet}`]?.verification;
          temp["revision_fee"] = data?.[`${propToGet}`]?.revision_fee;
        }
        methods.reset(temp);
        if (
          data?.[`${propToGet}`]?.file &&
          data?.[`${propToGet}`]?.file !== "string"
        ) {
          getFileFromService(data?.[`${propToGet}`]?.file, {
            onSuccess: (data) => {
              methods.setValue("fileTemp", {
                type: data?.mimeType,
                ...data,
              });
            },
            onError: (error) => {
              console.log(error);
            },
          });
        }
        if (
          data?.[`${propToGet}`]?.revision_file &&
          data?.[`${propToGet}`]?.revision_file !== "string"
        ) {
          getFileFromService(data?.[`${propToGet}`]?.revision_file, {
            onSuccess: (data) => {
              methods.setValue("fileRevisionTemp", {
                type: data?.mimeType,
                ...data,
              });
            },
            onError: (error) => {
              console.log(error);
            },
          });
        }
      }
    }, [data, propToGet]);

    const modalCallback = (val) => {
      if (val === false) {
        methods.reset();
      }
      setOpen(val);
    };

    const verification = methods.watch("verification");

    return (
      <Modal
        trigger={ButtonRendered}
        contentClassName="!max-w-5xl z-[99] !overflow-y-scroll"
        isOpen={open}
        onOpenChange={modalCallback}
        manual
      >
        <ModalHeader
          noBorder
          hideClose={false}
          title={
            <div className="text-lg font-semibold text-[#101828]">
              Verifikasi Jaminan Lainnya
            </div>
          }
        />
        <ModalBody>
          <FormProvider {...methods}>
            <div className="space-y-4 mb-6">
              <div className="flex space-x-5">
                <div className="w-1/2 space-y-3">
                  <div className="font-semibold text-gray-800">
                    Bentuk Jaminan
                  </div>
                  <div className="text-gray-900">
                    {data?.collateral_type ?? "-"}
                  </div>
                </div>
                <div className="w-1/2 space-y-3">
                  <div className="font-semibold text-gray-800">
                    Nilai Jaminan
                  </div>
                  <div className="text-gray-900">
                    {data?.collateral_fee
                      ? formatCurrency(data?.collateral_fee)
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="space-y-4">
                <InputFormUpload
                  controllerName={`fileTemp`}
                  label={"Unggah Foto"}
                  uploadFile={fileMutation}
                />
              </div>
              <div className="border rounded-md px-4 py-3">
                <InputFormRadio
                  label={"Hasil Verifikasi"}
                  controllerName={`verification`}
                  className="flex items-center justify-between gap-4"
                  values={[
                    {
                      label: (
                        <>
                          <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                          Data Terverifikasi
                        </>
                      ),
                      value: true,
                    },
                    {
                      label: (
                        <>
                          <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                          Data Belum Sesuai/ Ada Kesalahan
                        </>
                      ),
                      value: false,
                    },
                  ]}
                />
                {verification !== "" && verification === false && (
                  <>
                    <hr className="my-4" />
                    <div className="space-y-4">
                      <hr className="my-5 " />
                      <p className="font-bold text-[#01A24A]">
                        Revisi Hasil Sampling
                      </p>
                      <div className="flex space-x-5">
                        <div className="w-1/2 space-y-3">
                          <div className="font-semibold text-gray-800">
                            Bentuk Jaminan
                          </div>
                          <div className="text-gray-900">
                            {data?.collateral_type ?? "-"}
                          </div>
                        </div>
                        <div className="w-1/2 space-y-3">
                          <div className="font-semibold text-gray-800">
                            Nilai Jaminan
                          </div>
                          <div className="text-gray-900">
                            <InputForm
                              controllerName={`revision_fee`}
                              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                              label=""
                              placeholder="Nilai Jaminan"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="space-y-4">
                        <InputFormUpload
                          controllerName={`fileRevisionTemp`}
                          label={"Unggah Foto"}
                          uploadFile={revisionFileMutation}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="grid grid-cols-2 gap-4 pt-6">
                <ModalClose>
                  <Button
                    label="Batal"
                    theme="secondary"
                    block
                    className="w-full"
                  />
                </ModalClose>
                <Button
                  label={"Submit"}
                  theme="primary"
                  block
                  className="w-full"
                  onClick={() => methods.handleSubmit(onSubmit)()}
                />
              </div>
            </div>
          </FormProvider>
        </ModalBody>
      </Modal>
    );
  }
);

export default VerificationCollateralModal;
