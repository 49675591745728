import { useQuery } from "@tanstack/react-query";
import { Button, Input } from "components";
import Badge from "components/atoms/Badge";
import Table, { TableWrapper } from "components/atoms/Table";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { Pagination } from "components/v2/shared/pagination";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { RiCloseLine, RiEyeLine, RiSearchLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { getAgencyOfferApprovalList } from "services/institutions/offer-approvals";

const statusColors = {
  "Belum Review": "warning",
  Disetujui: "success",
  Baru: "warning",
  "Sedang Dikerjakan": "bluelight",
  Draft: "default",
  Ditolak: "error",
  Selesai: "success",
  "Sudah Dilengkapi": "success",
};

const ApprovalMinutes = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    paginate: true,
    filter: {
      search: undefined,
      date: "",
    },
  });

  const { data: approvalDatas, isLoading } = useQuery({
    queryKey: [
      "agency-offer-approval-list",
      {
        ...filters,
        filter: {
          date: filters?.filter?.date
            ? dayjs(filters?.filter?.date).format("YYYY-MM-DD")
            : "",
        },
      },
    ],
    queryFn: getAgencyOfferApprovalList,
    select: (data) => data?.data?.data,
  });

  const headers = useMemo(
    () => [
      {
        key: "committee_minutes_number",
        title: "No Berita Acara",
      },

      {
        key: "agency_name",
        title: "Nama Lembaga",
      },
      {
        key: "status_string",
        title: "Status",
        render: ({ item }) => (
          <Badge color={statusColors[item.status_string]}>
            {item.status_string}
          </Badge>
        ),
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <>
            {item?.status === "1" ? (
              <Button
                theme="primary"
                label="Verifikasi"
                onClick={() => {
                  navigate(`${item.id}?status=${item?.status}`);
                }}
              />
            ) : (
              <Button
                theme="icon"
                label={<RiEyeLine className="text-lg" />}
                onClick={() => {
                  navigate(`${item.id}?status=${item?.status}`);
                }}
              />
            )}
          </>
        ),
      },
    ],
    [navigate]
  );

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: "Approval Berita Acara",
            path: "",
          },
        ]}
      />

      <h1 className="text-gray-900 text-2xl font-semibold leading-loose">
        Approval Berita Acara
      </h1>

      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-end">
          <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
            <RiSearchLine className="text-gray-400 text-xl" />
            <Input
              className="w-full bg-white border-none"
              onChange={debounce(
                (e) =>
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      search: e.target.value,
                    },
                  })),
                1000
              )}
              placeholder="Cari"
            />
          </div>
          <div className="relative">
            <ReactDatePicker
              className={
                "date_picker w-full !outline-none !focus:border-primary-700 border h-10 px-3 focus:border-primary-700 rounded-md"
              }
              selected={filters.filter.date}
              onChange={debounce(
                (date) =>
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      date: date,
                    },
                  })),
                1000
              )}
              placeholderText={"dd/mm/yyyy"}
              showYearDropdown={true}
            />
            {filters.filter.date !== "" && (
              <RiCloseLine
                className="absolute top-3 right-2 text-gray-400 hover:text-gray-600 cursor-pointer text-lg"
                onClick={() =>
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      date: "",
                    },
                  }))
                }
              />
            )}
          </div>
        </div>
        <TableWrapper className="mt-4">
          <Table
            headers={headers}
            items={approvalDatas?.data || []}
            isLoading={isLoading}
          />
          <Pagination
            page={approvalDatas?.meta?.current_page || 1}
            limit={filters.limit || 10}
            total={approvalDatas?.meta?.total || 0}
            totalPage={approvalDatas?.meta?.last_page || 1}
            onLimitChange={(e) => setFilters((prev) => ({ ...prev, limit: e }))}
            onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
          />
        </TableWrapper>
      </div>
    </div>
  );
};

export default ApprovalMinutes;
