import MemberInGroupDisburstment from "pages/RequestDisburstment";
import MemberInGroupDisburstmentDetail from "pages/RequestDisburstment/Detail";
import MemberDisburstmentDetail from "pages/RequestDisburstment/MemberDetail";

import AssignDisbursment from "pages/AssignPelaksana/Disbursments";
import AssignDisbursmentDetail from "pages/AssignPelaksana/Disbursments/detail";
import InstitutionsRequestDisbursmentReview from "pages/RequestDisburstment/Intitutions";
import DetailReviewDisbursment from "pages/RequestDisburstment/Intitutions/Detail";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    routeType: "private",
    path: "/member-in-group-disburstment/:disbursmentType",
    element: MemberInGroupDisburstment,
    exact: true,
    sidebar: true,
    key: "group_disburstment",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/member-in-group-disburstment/:disbursmentType/:id",
    element: MemberInGroupDisburstmentDetail,
    exact: true,
    sidebar: true,
    key: "group_disburstment",
    type: "canUpdate"
  },
  {
    routeType: "private",
    path: "/member-in-group-disburstment/:disbursmentType/:id/member/:memberId",
    element: MemberDisburstmentDetail,
    exact: true,
    sidebar: true,
    key: "group_disburstment",
    type: "canUpdate"
  },
  {
    routeType: "private",
    path: "/institutions/disburstment-review",
    element: InstitutionsRequestDisbursmentReview,
    exact: true,
    sidebar: true,
  },
  {
    routeType: "private",
    path: "/institutions/disburstment-agreement",
    element: InstitutionsRequestDisbursmentReview,
    exact: true,
    sidebar: true,
  },
  {
    routeType: "private",
    path: "/institutions/disburstment-review/:id",
    element: DetailReviewDisbursment,
    exact: true,
    sidebar: true,
  },
  {
    routeType: "private",
    path: "/institutions/disburstment-agreement/:id",
    element: DetailReviewDisbursment,
    exact: true,
    sidebar: true,
  },
  {
    routeType: "private",
    path: "/assign-disbursments",
    element: AssignDisbursment,
    exact: true,
    sidebar: true,
    key: "assign_disbursment",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/assign-disbursments/:id",
    element: AssignDisbursmentDetail,
    exact: true,
    sidebar: true,
    key: "assign_disbursment",
    type: "canUpdate"
  }
];
