import { Button } from "components";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { CgClose } from "react-icons/cg";
import { FiAlertCircle } from "react-icons/fi";
import graphic from "../../../../assets/confirmation_graphic.svg";

const BasicModal = ({
  open,
  onClose,
  onSubmit,
  cancelLabel,
  confirmLabel,
  children,
  className = "",
  title,
  hideButton = false
}) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <div className="flex items-center justify-center fixed inset-0 w-screen h-screen bg-black/70 z-[99]">
          <motion.div
            key="modal-confirmation"
            className="bg-white rounded-xl min-w-[686px] max-w-[960px] overflow-hidden"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
          >
            <div class="relative bg-white rounded-lg shadow  ">
              <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                <h3 class="text-xl font-semibold text-gray-900">{title}</h3>
                <button
                  type="button"
                  class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                  data-modal-hide="default-modal"
                  onClick={() => onClose(false)}
                >
                  <svg
                    class="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span class="sr-only">Close modal</span>
                </button>
              </div>
            <div className="max-h-[600px] overflow-y-auto">
              {children}

            </div>
            {!hideButton && (
              <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b space-x-4">
                <Button
                  type="button"
                  className="text-gray-700 border border-gray-300 bg-white hover:bg-gray-300 flex-1 py-2 rounded"
                  onClick={() => onClose(false)}
                  label={cancelLabel || "Batal"}
                />
                <Button
                  type="button"
                  className="text-white border  border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1 py-2 rounded"
                  onClick={onSubmit}
                  label={confirmLabel || "Submit"}
                />
              </div>
            )}
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default BasicModal;
