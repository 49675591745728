import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getAssignOffers = (params) => {
  return axiosInstance.get(`${general_config_v2.BASE_URL}/v1/cms/assign-bid-executor`, {
    params: {
      ...params,
      paginate: true,
    },
  });
};

export const getAssignOffersDetail = (id) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/assign-bid-executor/${id}`
  );
};

export const putAssignOffersDetail = (id, payload) => {
  return axiosInstance.post(
    `${general_config_v2.BASE_URL}/v1/cms/assign-bid-executor/${id}`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getListOffers = (params) => {
  return axiosInstance.get(`${general_config_v2.BASE_URL}/v1/cms/offer`, {
    params: {
      ...params,
      paginate: true,
    },
  });
};

export const getOfferDetail = (id, params) => {
  return axiosInstance.get(`${general_config_v2.BASE_URL}/v1/cms/offer/${id}`, {
    params,
  });
};

export const createOffer = (payload) => {
  return axiosInstance.post(`${general_config_v2.BASE_URL}/v1/cms/offer`, payload);
};

export const putOfferDetail = (id) => {
  return axiosInstance.put(`${general_config_v2.BASE_URL}/v1/cms/offer/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getOfferNegotiation = (id) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/offer/negotiation/${id}`
  );
};

export const putOfferNegotiation = (id, payload) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/offer/negotiation/${id}`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getListApprovalOffers = (params) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/offer-application-approval`,
    {
      params,
    }
  );
};

export const getDetailApprovalOffers = (id, params) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/offer-application-approval/${id}`,
    {
      params,
    }
  );
};

export const createApprovalOffer = (payload) => {
  return axiosInstance.post(`${general_config_v2.BASE_URL}/v1/cms/offer-application-approval`, payload);
};

export const getListAgencyOfferApprovals = (params) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-offer-approvals`,
    {
      params,
    }
  );
};

export const updateAgencyOfferApprovals = ({id, payload , params}) => {
  return axiosInstance.patch(
    `${general_config_v2.BASE_URL}/v1/cms/agency-offer-approvals`,
    payload,
    {
      params,
    }
  );
};

