import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getListAssignFieldAnalyst = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-field-analyst/assign`,
    { params: queryKey[1] }
  );
};

export const getDetailAssignFieldAnalyst = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-field-analyst/assign/${queryKey[1]}`
  );
};

export const assignFieldAnalyst = (id, data) => {
  return axiosInstance.patch(
    `${general_config_v2.BASE_URL}/v1/cms/agency-field-analyst/assign`,
    data,
    {
      params: {
        agency_field_analyst_id: id,
      },
    }
  );
};

export const getListFieldAnalyst = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-field-analyst`,
    { params: queryKey[1] }
  );
};

export const getDetailFieldAnalyst = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-field-analyst/${queryKey[1]}`,
    { params: queryKey[2] }
  );
};

export const postDetailFieldAnalyst = (params, data) => {
  return axiosInstance.patch(
    `${general_config_v2.BASE_URL}/v1/cms/agency-field-analyst`,
    data,
    { params }
  );
};