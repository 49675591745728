import React, { useEffect } from "react";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import { FormProvider, useForm } from "react-hook-form";
import VerificationResult from "./VerificationResult";
import { Button, InputForm, InputFormRadio } from "components";
import { useNavigate, useParams } from "react-router";
import SelfAsessment from "./SelfAssesment";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getDisburstmentsDetailMember,
  putDisburstmentsDetailMember,
} from "services/fdb/disbursement";
import dayjs from "dayjs";
import UploadedFileAsync from "components/molecules/UploadFileAsync";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RiArrowLeftLine } from "react-icons/ri";
import { enqueueSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";

const formScheme = yup.object({
  verification_aggreement: yup
    .bool()
    .required()
    .transform((val) => (val === "" ? undefined : val))
    .label("Field"),
  verification_application: yup
    .bool()
    .required()
    .transform((val) => (val === "" ? undefined : val))
    .label("Field"),
  verification_saldo: yup
    .bool()
    .required()
    .transform((val) => (val === "" ? undefined : val))
    .label("Field"),
  verification_atapp: yup
    .bool()
    .required()
    .transform((val) => (val === "" ? undefined : val))
    .label("Field"),
  is_disbursement: yup
    .bool()
    .required()
    .transform((val) => (val === "" ? undefined : val))
    .label("Field"),
  notes: yup.string().notRequired().label("Catatan"),
});

const MemberDisburstmentDetail = () => {
  const navigate = useNavigate();
  const { id, memberId, disbursmentType } = useParams();

  const [searchParams] = useSearchParams();

  const isView = searchParams.get("status") !== "Belum Direview";

  const methods = useForm({
    resolver: yupResolver(formScheme),
    mode: "onChange",
  });

  const queryClient = useQueryClient();
  const { data } = useQuery(
    ["GET_DISBURSEMENT_DETAIL_MEMBER", memberId],
    getDisburstmentsDetailMember,
    {
      select: (data) => data?.data?.data,
    }
  );
  const mutateUpdateMember = useMutation({
    mutationFn: putDisburstmentsDetailMember,
    onSuccess: (res) => {
      enqueueSnackbar({
        variant: "success",
        message: "Berhasil verifikasi debitur",
      });
      queryClient.invalidateQueries();
      navigate(-1);
    },
    onError: (err) => {
      enqueueSnackbar({
        variant: "error",
        message: "Gagal verifikasi debitur",
      });
    },
  });

  useEffect(() => {
    if (data) {
      methods.reset({
        ...data?.verification_result,
        is_disbursement: data?.is_disbursement,
        notes: data?.notes,
      });
    }
  }, [data, methods]);

  const onSubmit = (data) => {
    const payload = {
      id: memberId,
      data,
    };

    mutateUpdateMember.mutate(payload);
  };

  return (
    <div className="space-y-6 mb-40">
      <BreadCrumbs
        routes={[
          {
            label: "Permohonan Pencairan",
            path: "/member-in-group-disburstment",
          },
          {
            label: "Perorangan dalam Kelompok",
            path: "/member-in-group-disburstment",
          },
          {
            label: "Daftar Anggota",
            path: `/member-in-group-disburstment/${id}`,
          },
          {
            label: "Detail",
            path: `/member-in-group-disburstment/${id}/member/${memberId}`,
          },
        ]}
      />

      <FormProvider {...methods}>
        <CardForm label="Detail Permohonan Pencairan">
          <>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
              <div>
                <p className="font-semibold">Nomor Perjanjian</p>
                <p>{data?.disbursement_detail?.agreement_number}</p>
              </div>
              <div>
                <p className="font-semibold">Nama Debitur</p>
                <p>{data?.disbursement_detail?.debtor_name}</p>
              </div>
              <div>
                <p className="font-semibold">Tempat Lahir</p>
                <p>{data?.disbursement_detail?.place_of_birth}</p>
              </div>
              <div>
                <p className="font-semibold">Nilai Permohonan</p>
                <p>
                  {data?.disbursement_detail?.application_value
                    ? `Rp. ${Math.floor(
                        data?.disbursement_detail?.application_value
                      ).toLocaleString()}`
                    : "-"}
                </p>
              </div>
              <div>
                <p className="font-semibold">Tgl Permohonan</p>
                <p>
                  {data?.disbursement_detail?.register_date
                    ? dayjs(data?.disbursement_detail?.register_date).format(
                        "DD MMMM YYYY"
                      )
                    : "-"}
                </p>
              </div>
              <div>
                <p className="font-semibold">NAP</p>
                <p>{data?.disbursement_detail?.nap}</p>
              </div>
              <div>
                <p className="font-semibold">Tahapan</p>
                <p>
                  {data?.disbursement_detail?.total_phase
                    ? `${data?.disbursement_detail?.total_phase} Tahap`
                    : "-"}
                </p>
              </div>
              {data?.disbursement_detail?.all_fee.map((el, idx) => (
                <div>
                  <p className="font-semibold">Tahap {idx + 1}</p>
                  <p>{`Rp ${Math.floor(el.value).toLocaleString()}`}</p>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div>
                <UploadedFileAsync
                  id={data?.disbursement_detail?.agreement_doc}
                />
              </div>
            </div>
          </>
        </CardForm>

        {id === 2 && <SelfAsessment />}
        <VerificationResult isView={isView} />

        <CardForm label="Simpulan">
          <div className="grid grid-cols-1 gap-6">
            <InputFormRadio
              labelClass={"w-full"}
              controllerName={`is_disbursement`}
              usingGrid={true}
              gridClassName="grid grid-cols-2 gap-4 w-full"
              className="flex items-center justify-between gap-4 flex-wrap"
              disabled={isView}
              values={[
                {
                  label: "Bisa dilanjutkan untuk proses penyaluran",
                  value: true,
                },
                {
                  label: "Tidak bisa dilanjutkan untuk proses penyaluran",
                  value: false,
                },
              ]}
            />
            <InputForm
              disabled={isView}
              className="w-full px-4 py-2 rounded-md border"
              controllerName="notes"
              textArea
              label="Catatan Tambahan"
            />
          </div>
        </CardForm>
      </FormProvider>
      <div className="sticky bottom-4 flex justify-between w-full p-5 bg-white border rounded-md">
        <Button
          className="p-4 border rounded-lg"
          type="button"
          label={
            <div className="flex items-center gap-2 text-sm font-medium">
              <RiArrowLeftLine />
              Kembali
            </div>
          }
          onClick={() => navigate(-1)}
        />

        {!isView && (
          <Button
            className="p-4 border rounded-lg bg-primary-600 hover:bg-primary-700 text-white"
            type="button"
            label={
              <div className="flex items-center gap-2 text-sm font-medium">
                Simpan
              </div>
            }
            onClick={methods.handleSubmit(onSubmit)}
          />
        )}
      </div>
    </div>
  );
};

export default MemberDisburstmentDetail;
