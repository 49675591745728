import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getListMinutes = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-risalah`,
    { params: queryKey[1] }
  );
};

export const getDetailMinutes = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-risalah/${queryKey[1]}`,
    { params: queryKey[2] }
  );
};

export const postDetailMinutes = (params, data) => {
  return axiosInstance.patch(
    `${general_config_v2.BASE_URL}/v1/cms/agency-risalah`,
    data,
    { params }
  );
};
