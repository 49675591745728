import React, { useEffect, useState } from "react";
import style from './index.module.css';

const ProgressBar = React.forwardRef(({className, theme, progress, ...rest}, ref) => {
  const [themed, setThemed] = useState({});
  
  useEffect(() => {
    let span;
    
    switch(theme) {
      case 'success': {
        span = 'bg-success-600';
        break;
      }
      case 'error': {
        span = 'bg-error-600'
        break;
      }
      case 'warning': {
        span = 'bg-warning-600'
        break;
      }
      case 'primary-maincolor': {
        span = 'bg-primary-maincolor-600'
        break;
      }
      default: break;
    }
    
    setThemed( {
      span,
    })
  }, [theme]);
  
  const progressAmt = -(100 - (progress || 0));
  
  return (
    <div ref={ref} className={[style.progress, className].join(' ')}>
      <span className={themed.span} style={{transform: `translateX(${progressAmt}%)`}}/>
    </div>
  );
});

ProgressBar.propTypes = {};

export default ProgressBar;