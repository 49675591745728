import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  BottomFormAction,
  Button,
  InputForm,
  InputFormRadio,
} from "components";
import { customErrorStyle, customStyles } from "components/atoms/Select";
import Table, { TableWrapper } from "components/atoms/Table";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { RiAddLine, RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import Select from "react-select";
import {
  createCommitteeMeetingAgenda,
  getUserByDivisiHierarchy,
  masterSKComite,
} from "services/fdb/committee";
import { getAllRisalah } from "services/fdb/risalah";
import { postMeetingAgenda } from "services/institutions/meeting-agenda";
import { getListMinutes } from "services/institutions/minutes";

import * as yup from "yup";

const committeeAgendaSchema = yup.object({
  meeting_circular_number_minutes: yup
    .string()
    .required()
    .label("No Surat Edaran Rapat Risalah"),
  topic_discussion: yup.string().required().label("Topik Pembahasan"),
  implementation_date: yup.string().required().label("Tanggal"),
  implementation_time: yup.string().required().label("Waktu"),
  implementation_place: yup.string().required().label("Tempat"),
  directorates_type: yup.string().required().label("Daftar Direktorat"),
  // committee_decree: yup.string().required().label(""),
  committee_decree: yup
    .string()
    .when("directorates_type", ([value], schema) => {
      if (value === "BASED_ON_COMMITTEE_DECREE") {
        schema.required().label("SK Komite");
      }

      return schema;
    }),
  minutes: yup.array(
    yup.object({
      risalah_id: yup.string().required().label("Nomor Risalah"),
      minutes_number: yup.string(),
    })
  ),
  risalahs: yup.array(
    yup.object({
      risalah_id: yup.string().required().label("Nomor Risalah"),
      minutes_number: yup.string(),
    })
  ),
  directorates: yup.array(
    yup.object({
      directorate_id: yup.string().notRequired(),
      name: yup.string(),
      position: yup.string(),
      committee_position: yup.string(),
    })
  ),
  notes: yup.string().required().label("Catatan"),
});

const MeetingAgendaForm = ({ fdbType }) => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  // =====================

  const breadcrumbs = useMemo(
    () => [
      {
        label: "Agenda Rapat",
        path: "/committee/meeting-agenda",
      },
      {
        label: "Form",
        path: "/committee/meeting-agenda/form",
      },
    ],
    []
  );

  // ===========================

  const methods = useForm({
    resolver: yupResolver(committeeAgendaSchema),
    mode: "onChange",
    defaultValues: {
      minutes: [{ risalah_id: "", minutes_number: "" }],
      committee_decree: "Komite Penerima Fasilitas Dana Bergulir Peroangan",
      directorates: [
        {
          directorate_id: null,
          name: "vacancy",
          position: "Direktur Penyaluran Dana",
          committee_position: "Ketua merangkap anggota",
        },
        {
          directorate_id: null,
          name: "vacancy",
          position:
            "Kepala Divisi Penyaluran Pinjaman, Direktorat Penyaluran Dana",
          committee_position: "Sekretaris merangkap anggota",
        },
        {
          directorate_id: null,
          name: "vacancy",
          position:
            "Kepala Divisi Anggaran dan Akuntansi, Direktorat Keuangan Umum dan Sistem Informasi",
          committee_position: "Anggota",
        },
        {
          directorate_id: null,
          name: "vacancy",
          position:
            "Kepala Divisi Penghimpunan dan Pengembangan Layanan, Direktorat Penghimpunan dan Pengembangan Dana",
          committee_position: "Anggota",
        },
        {
          directorate_id: null,
          name: "vacancy",
          position:
            "Kepala Divisi Monitoring, Evaluasi, dan Pembinaan, Direktorat Penyaluran Dana",
          committee_position: "Anggota",
        },
        {
          directorate_id: null,
          name: "vacancy",
          position:
            "Kepala Divisi Perjanjian, Direktorat Hukum dan Manajemen Risiko",
          committee_position: "Anggota",
        },
        {
          directorate_id: null,
          name: "vacancy",
          position:
            "Kepala Divisi Manajemen Risiko, Direktorat Hukum dan Manajemen Risiko",
          committee_position: "Anggota",
        },
      ],
    },
  });

  // ==========================

  const createCommiteeMeetingAgenda = useMutation({
    mutationFn: createCommitteeMeetingAgenda,
  });
  const createCommitteeMeetingAgendaInstitution = useMutation({
    mutationFn: postMeetingAgenda,
  });

  const handleBack = () => {
    navigate(-1);
  };

  const onSubmit = (data) => {
    if (fdbType === "lembaga") {
      const payload = {
        ...data,
        implementation_date: dayjs(data.implementation_date).format(
          "YYYY-MM-DD"
        ),
        implementation_time: dayjs(data.implementation_time).format(
          "YYYY-MM-DD HH:mm"
        ),
        risalahs: data?.minutes?.map((el) => ({
          risalah_id: el.risalah_id,
          risalah_number: el.minutes_number,
        })),
      };

      delete payload?.minutes;

      createCommitteeMeetingAgendaInstitution.mutate(payload, {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Committee agenda rapat berhasil di buat",
          });

          queryClient.invalidateQueries();
          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({
            variant: "error",
            message: "Committee agenda rapat gagal di buat",
          });
        },
      });

      return;
    }

    const payload = {
      ...data,
      implementation_date: dayjs(data.implementation_date).format("YYYY-MM-DD"),
      implementation_time: dayjs(data.implementation_time).format(
        "YYYY-MM-DD HH:mm"
      ),
    };

    createCommiteeMeetingAgenda.mutate(payload, {
      onSuccess: (res) => {
        enqueueSnackbar({
          variant: "success",
          message: "Committee agenda rapat berhasil di buat",
        });

        queryClient.invalidateQueries();
        navigate(-1);
      },
      onError: (err) => {
        enqueueSnackbar({
          variant: "error",
          message: "Committee agenda rapat gagal di buat",
        });
      },
    });
  };

  console.log(methods.formState.errors);

  return (
    <div className="space-y-6">
      <BreadCrumbs routes={breadcrumbs} />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CardForm label="Tambah Agenda Rapat">
            <div className="flex flex-col gap-6">
              <InputForm
                label="No Surat Edaran Rapat Risalah"
                controllerName="meeting_circular_number_minutes"
                className="w-full"
              />
              <hr />
              <DiscussionTopic fdbType={fdbType} />
              <hr />
              <ImplementationDate />
              <hr />
              <Directorates />
            </div>
          </CardForm>

          <div className="h-24"></div>

          <BottomFormAction
            backButtonAction={handleBack}
            submitActionButton={methods.handleSubmit(onSubmit)}
            disableDrafButton={false}
            lastStep={true}
            hideDraft
            disableButtonSubmit={false}
            className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
          />
        </form>
      </FormProvider>
    </div>
  );
};

const DiscussionTopic = ({ fdbType }) => {
  const methods = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "minutes",
  });

  const { data } = useQuery(
    ["GET_LIST_RISALAH"],
    () => getAllRisalah({ page: 1, limit: 100, filter: { is_agenda: true } }),
    {
      select: (data) => data?.data?.data?.data,
      enabled: fdbType === "perorangan",
    }
  );

  const { data: dataInstitution } = useQuery(
    ["GET_LIST_RISALAH_INSTITUTION"],
    getListMinutes,
    {
      select: (data) => data?.data?.data?.data,
      enabled: fdbType === "lembaga",
    }
  );

  return (
    <div className="flex flex-col gap-5">
      <h3 className="text-lg font-semibold">Pembahasan</h3>

      <InputForm
        label="Topik Pembahasan"
        controllerName="topic_discussion"
        className="w-full"
      />

      {fields.map((field, index) => (
        <Controller
          key={field.id}
          control={methods.control}
          name={`minutes.${index}`}
          render={({ field, fieldState }) => (
            <div className="flex flex-col gap-2">
              <h5 className="text-sm font-semibold">Nomor Risalah</h5>
              <div className="flex gap-5">
                {fdbType === "lembaga" && (
                  <Select
                    styles={
                      fieldState.invalid ? customErrorStyle : customStyles
                    }
                    className="w-full"
                    options={dataInstitution?.map((risalah) => ({
                      label: risalah.number,
                      value: {
                        risalah_id: risalah?.id,
                        minutes_number: risalah?.number,
                      },
                    }))}
                    onChange={({ value }) => field.onChange(value)}
                  />
                )}

                {fdbType === "perorangan" && (
                  <Select
                    styles={
                      fieldState.invalid ? customErrorStyle : customStyles
                    }
                    className="w-full"
                    options={data?.map((risalah) => ({
                      label: risalah.number,
                      value: {
                        risalah_id: risalah?.id,
                        minutes_number: risalah?.number,
                      },
                    }))}
                    onChange={({ value }) => field.onChange(value)}
                  />
                )}

                {index > 0 ? (
                  <Button
                    label={<RiDeleteBinLine size={20} />}
                    theme="error"
                    type="button"
                    onClick={() => {
                      remove(index);
                    }}
                    className="w-10 h-10 !p-0 justify-center items-center flex"
                  />
                ) : null}
              </div>
              {fieldState.invalid && (
                <span className="text-xs text-error-600 mt-1 label-text-alt text-error text-[#F04438]">
                  {fieldState.error.message ??
                    fieldState.error.risalah_id.message}
                </span>
              )}
            </div>
          )}
        />
      ))}
      <div className="flex justify-end">
        <Button
          className="bg-white hover:bg-slate-50 border"
          label={
            <div className="flex items-center gap-2 text-sm font-semibold">
              <RiAddLine size={20} />
              Tambah Risalah
            </div>
          }
          type="button"
          onClick={() => append({ minutes_id: "", minutes_number: "" })}
        />
      </div>
    </div>
  );
};

const ImplementationDate = () => {
  const methods = useFormContext();

  return (
    <div className="flex flex-col gap-5">
      <h3 className="text-lg font-semibold">Tanggal Pelaksanaan</h3>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        <Controller
          control={methods.control}
          name="implementation_date"
          render={({ field, fieldState }) => (
            <div className="flex flex-col w-full gap-2">
              <label htmlFor="" className="text-sm font-semibold">
                Tanggal
              </label>
              <ReactDatePicker
                className="h-11 w-full border border-gray-200 outline-none px-4 text-sm"
                minDate={new Date()}
                placeholderText="Pilih Tanggal"
                selected={field.value}
                portalId="root-portal"
                dateFormat="dd MMMM yyyy"
                locale="id-ID"
                onChange={(val) => {
                  field.onChange(val);
                  methods.setValue("implementation_time", undefined);
                }}
              />
              {fieldState.invalid && (
                <span className="text-xs text-error-600 mt-1 label-text-alt text-error text-[#F04438]">
                  {fieldState.error.message ??
                    fieldState.error.minutes_id.message}
                </span>
              )}
            </div>
          )}
        />
        {/* <InputForm
          label="Hari"
          controllerName="implementation_day"
          className="w-full"
          disabled
        /> */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-semibold">Hari</label>
          <input
            className="w-full border border-gray-200 h-11 rounded px-4 text-sm"
            value={
              methods.watch("implementation_date")
                ? dayjs(methods.watch("implementation_date")).format("dddd")
                : undefined
            }
            disabled
          />
        </div>
        <Controller
          control={methods.control}
          name="implementation_time"
          render={({ field, fieldState }) => (
            <div className="flex flex-col w-full gap-2">
              <label htmlFor="" className="text-sm font-semibold">
                Waktu
              </label>
              <ReactDatePicker
                className="h-11 w-full border border-gray-200 outline-none px-4 text-sm"
                minDate={new Date()}
                placeholderText="Pilih Waktu"
                selected={
                  field.value
                    ? field.value
                    : methods.watch("implementation_date")
                }
                portalId="root-portal"
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm aa"
                locale="id-ID"
                onChange={(val) => {
                  field.onChange(val);
                }}
              />
              {fieldState.invalid && (
                <span className="text-xs text-error-600 mt-1 label-text-alt text-error text-[#F04438]">
                  {fieldState.error.message ??
                    fieldState.error.minutes_id.message}
                </span>
              )}
            </div>
          )}
        />
        <InputForm
          label="Tempat"
          controllerName="implementation_place"
          className="w-full"
        />
      </div>
    </div>
  );
};

const Directorates = () => {
  const methods = useFormContext();
  const [commiteOptions, setCommiteOptions] = useState([]);
  const [directoratsCommite, setDirectoratsCommite] = useState([]);

  const { fields } = useFieldArray({
    control: methods.control,
    name: "directorates",
  });

  const DirectoratesTypeField = useWatch({
    control: methods.control,
    name: "directorates_type",
  });

  const { data: direkturPenyaluranDana } = useQuery(
    ["GET_DIREKTUR_PENYALURAN_DANA"],
    () =>
      getUserByDivisiHierarchy(
        undefined,
        "2f4b65fc-e960-4abb-ae1e-647154619741"
      ),
    { select: (data) => data?.data?.data }
  );

  const { data: kadivPenyaluranPinjaman } = useQuery(
    ["GET_KADIV_PENYALURAN_PINJAMAN"],
    () =>
      getUserByDivisiHierarchy(
        "26f32f37-ad5c-47f7-8fd0-505744fd8238",
        "759aa8ca-df7d-400b-ba8f-26db02a7994e"
      ),
    { select: (data) => data?.data?.data }
  );

  const { data: kadivAnggaranAkuntansi } = useQuery(
    ["GET_KADIV_ANGGARAN_DAN_AKUNTANSI"],
    () =>
      getUserByDivisiHierarchy(
        "98bb5832-26de-430b-9caa-c551fc785cc1",
        "759aa8ca-df7d-400b-ba8f-26db02a7994e"
      ),
    { select: (data) => data?.data?.data }
  );

  const { data: kadivPenghimpunanPengembanganPelayanan } = useQuery(
    ["GET_KADIV_PENGHIMPUNAN_DAN_PENGEMBANGAN_PELAYANAN"],
    () =>
      getUserByDivisiHierarchy(
        "6d412778-ca5b-422d-b3b5-b8399f4ef130",
        "759aa8ca-df7d-400b-ba8f-26db02a7994e"
      ),
    { select: (data) => data?.data?.data }
  );

  const { data: kadivMonitoringEvaluasiPembinaan } = useQuery(
    ["GET_KADIV_MONITORING_EVALUASI_PEMBINAAN"],
    () =>
      getUserByDivisiHierarchy(
        "446cb4f1-f372-4090-a25a-c541de14ea2f",
        "759aa8ca-df7d-400b-ba8f-26db02a7994e"
      ),
    { select: (data) => data?.data?.data }
  );

  const { data: kadivPerjanjian } = useQuery(
    ["GET_KADIV_PERJANJIAN"],
    () =>
      getUserByDivisiHierarchy(
        "2e68eaf2-8514-4a92-b9df-5fb23a15a81a",
        "759aa8ca-df7d-400b-ba8f-26db02a7994e"
      ),
    { select: (data) => data?.data?.data }
  );

  const { data: kadivManajemenRisiko } = useQuery(
    ["GET_KADIV_MANAJEMEN_RISIKO"],
    () =>
      getUserByDivisiHierarchy(
        "a8f52919-e35e-4a93-897e-a0f11a096a1c",
        "759aa8ca-df7d-400b-ba8f-26db02a7994e"
      ),
    { select: (data) => data?.data?.data }
  );

  const { data: skCommite } = useQuery(
    ["GET_COMMITTEE"],
    () => masterSKComite({page: 1, limit: 10}),
    { select: (data) => data?.data?.data }
  )

  useEffect(() => {
    if (skCommite?.data) {
      const handleOptions = skCommite.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
      setCommiteOptions(handleOptions);
    }
  }, [skCommite]);

  useEffect(() => {
    if(directoratsCommite.length){
      methods.setValue('directorates', directoratsCommite)
    }
  }, [directoratsCommite])

  const optionsMapping = useMemo(
    () => ({
      Direktur: (direkturPenyaluranDana ?? [])?.map((data) => ({
        label: data.name,
        value: data,
      })),
      "Penyaluran Pinjaman": (kadivPenyaluranPinjaman ?? [])?.map((data) => ({
        label: data.name,
        value: data,
      })),
      "Anggaran dan Akuntansi": (kadivAnggaranAkuntansi ?? [])?.map((data) => ({
        label: data.name,
        value: data,
      })),
      "Penghimpunan dan Pengembangan Layanan": (
        kadivPenghimpunanPengembanganPelayanan ?? []
      ).map((data) => ({
        label: data.name,
        value: data,
      })),
      "Monitoring, Evaluasi, dan Pembinaan": (
        kadivMonitoringEvaluasiPembinaan ?? []
      ).map((data) => ({
        label: data.name,
        value: data,
      })),
      Perjanjian: (kadivPerjanjian ?? []).map((data) => ({
        label: data.name,
        value: data,
      })),
      "Manajemen Risiko": (kadivManajemenRisiko ?? []).map((data) => ({
        label: data.name,
        value: data,
      })),
    }),
    [
      direkturPenyaluranDana,
      kadivPenyaluranPinjaman,
      kadivAnggaranAkuntansi,
      kadivPenghimpunanPengembanganPelayanan,
      kadivMonitoringEvaluasiPembinaan,
      kadivPerjanjian,
      kadivManajemenRisiko,
    ]
  );

  const handleSelectOptions = useCallback(
    (key) => {
      for (const optionKey in optionsMapping) {
        if (key.includes(optionKey)) {
          return optionsMapping[optionKey];
        }
      }
    },
    [optionsMapping]
  );

  const headers = useMemo(
    () => [
      {
        key: "index",
        title: "No",
        render: ({ index }) => {
          // const no =
          //   parseInt(data?.meta?.current_page) *
          //     parseInt(data?.meta?.per_page) -
          //   parseInt(data?.meta?.per_page) +
          //   index +
          //   1;

          const no = index + 1;

          return no;
        },
      },
      {
        key: "name",
        title: "Nama",
        render: ({ item, index }) => (
          <div className="w-[314px]">
            {DirectoratesTypeField === "CUSTOM" ? (
              <Select
                options={handleSelectOptions(item.position)}
                styles={customStyles}
                menuPosition="fixed"
                onChange={(value) => {
                  methods.setValue(
                    `directorates.${index}.directorate_id`,
                    value?.value?.idUser
                  );
                  methods.setValue(
                    `directorates.${index}.name`,
                    value?.value?.name
                  );
                }}
              />
            ) : (
              item.name
            )}
          </div>
        ),
      },
      {
        key: "position",
        title: "Jabatan",
        render: ({ item }) => (
          <div className="whitespace-normal">{item.position}</div>
        ),
      },
      {
        key: "committee_position",
        title: "Kedudukan Dalam Komite",
        render: ({ item }) => (
          <div className="w-[314px]">{item.committee_position}</div>
        ),
      },
    ],
    [DirectoratesTypeField, handleSelectOptions, methods]
  );

  const handleSkCommite = (data) => {
    if (data) {
      const findCommiteItem = skCommite?.data?.find((item) => item.id === data)
      methods.setValue("committee_decree", findCommiteItem.name);
      setDirectoratsCommite(findCommiteItem.directorates)
      
    }
  }

  console.log('directorates', methods.watch('directorates'))

  return (
    <div className="flex flex-col gap-5">
      <h3 className="text-lg font-semibold">Daftar Direktorat</h3>
      <InputFormRadio
        controllerName="directorates_type"
        values={[
          {
            label: "Berdasarkan SK Komite",
            value: "BASED_ON_COMMITTEE_DECREE",
          },
          {
            label: "Kustomisasi Pemilihan Direktorat ",
            value: "CUSTOM",
          },
        ]}
      />
      {DirectoratesTypeField === "BASED_ON_COMMITTEE_DECREE" ? (
        <Controller
          control={methods.control}
          name="committee_decree"
          render={({ field, fieldState }) => (
            <div className="flex flex-col gap-2">
              <h5 className="text-sm font-semibold">SK Komite</h5>
              <div className="flex gap-5">
                <Select styles={customStyles} options={commiteOptions} className="w-full" onChange={(selectedOption) => field.onChange(selectedOption.value)} />
                <Button
                  label="Pilih Komite"
                  className="bg-primary-700 text-white text-sm !w-max whitespace-nowrap"
                  onClick={() => handleSkCommite(field.value)}
                />
              </div>
              {fieldState.invalid && <span>{fieldState.error.message}</span>}
            </div>
          )}
        />
      ) : null}

      {DirectoratesTypeField !== "" &&
      typeof DirectoratesTypeField !== "undefined" ? (
        <TableWrapper>
          <Table headers={headers} items={directoratsCommite.length ? directoratsCommite : fields} />
        </TableWrapper>
      ) : null}

      <InputForm
        label="Catatan"
        controllerName="notes"
        textArea
        className="w-full border border-gray-200 rounded-md h-28 p-3"
      />
    </div>
  );
};

export default MeetingAgendaForm;
