import { Button, Input } from "components";
import Table, { TableWrapper } from "components/atoms/Table";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { Pagination } from "components/v2/shared/pagination";
import { debounce } from "lodash";
import { useMemo, useState } from "react";
import { RiCloseLine, RiSearchLine } from "react-icons/ri";
import ReactDatePicker from "react-datepicker";
import { useQuery } from "@tanstack/react-query";
import { getListPendapatKomite } from "services/fdb/committee";
import cn from "clsx";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Modal } from "components/atoms/Modal";
import { getListExpressedOpinion } from "services/institutions/expressed-opinion";

const title = "Agenda Rapat";

const PendapatKomite = ({ fdbType }) => {
  const navigate = useNavigate();

  const [risalahModal, setRisalahModal] = useState();
  const [selectedRisalah, setSelectedRisalah] = useState();
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    filter: {
      search: "",
      committee_meeting_date: "",
    },
  });

  const [filtersInstitution, setFIltersInstitution] = useState({
    paginate: true,
    page: 1,
    limit: 10,
    filter: {
      search: "",
      date: "",
    },
  });

  const { data, isLoading } = useQuery(
    ["GET_LIST_PENDAPAT_KOMITE", filters],
    getListPendapatKomite,
    { select: (data) => data?.data?.data, enabled: fdbType === "perorangan" }
  );

  const { data: dataInstitution, isLoading: isLoadingInstitution } = useQuery(
    ["GET_LIST_EXPRESSED_OPINION_LEMBAGA", filtersInstitution],
    getListExpressedOpinion,
    { select: (data) => data?.data?.data, enabled: fdbType === "lembaga" }
  );

  const headers = useMemo(
    () => [
      {
        key: "index",
        title: "No",
        render: ({ index }) => {
          const meta = data?.meta ?? dataInstitution?.meta;

          const no =
            parseInt(meta?.current_page) * parseInt(meta?.per_page) -
            parseInt(meta?.per_page) +
            index +
            1;

          return no;
        },
      },
      {
        key: "topic_discussion",
        title: "Topik Rapat",
        render: ({ item }) => <p>{item.topic_discussion}</p>,
      },
      {
        key: "implementation_date",
        title: "Jadwal Rapat",
        render: ({ item }) => (
          <p>
            {item.implementation_time
              ? dayjs(item.implementation_time).subtract(7, 'hour').format(
                "dddd, DD MMMM YYYY ; HH:mm A"
              )
              : "-"}
          </p>
        ),
      },
      {
        key: "implementation_place",
        title: "Tempat",
        render: ({ item }) => <p>{item.implementation_place}</p>,
      },
      // {
      //   key: "notes",
      //   title: "Catatan",
      //   render: ({ item }) => (
      //     <span className={cn("py-0.5 px-2 rounded-full text-xs font-medium")}>
      //       {item.notes ?? "-"}
      //     </span>
      //   ),
      // },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <Button
            label={
              item?.is_agenda_review || item?.is_attend
                ? "Review Risalah"
                : "Review Agenda"
            }
            className="bg-primary-600 text-white text-xs font-semibold"
            onClick={() => {
              if (item?.is_agenda_review || item?.is_attend) {
                setRisalahModal((prev) => !prev);
                setSelectedRisalah(item.risalah ?? item?.risalah);
              } else {
                navigate({ pathname: item.id, search: "?review=agenda" });
              }
            }}
          />
        ),
      },
    ],
    [data?.meta, dataInstitution?.meta, navigate]
  );

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: "/committee/pendapat-komite",
          },
        ]}
      />

      <div className="flex justify-between">
        <h1 className="text-gray-900 text-2xl font-semibold leading-loose">
          Daftar Agenda Rapat
        </h1>
      </div>

      {fdbType === "perorangan" && (
        <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-end">
            <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
              <RiSearchLine className="text-gray-400 text-xl" />

              <Input
                className="w-full bg-white border-none"
                onChange={debounce(
                  (e) =>
                    setFilters((prev) => ({
                      ...prev,
                      page: 1,
                      filter: {
                        ...prev.filter,
                        search: e.target.value,
                      },
                    })),
                  1000
                )}
                placeholder="Cari"
              />
            </div>
            <div className="relative">
              <ReactDatePicker
                className={
                  "date_picker w-full !outline-none !focus:border-primary-700 border h-10 px-3 focus:border-primary-700 rounded-md"
                }
                selected={filters.filter.committee_meeting_date}
                onChange={debounce(
                  (date) =>
                    setFilters((prev) => ({
                      ...prev,
                      page: 1,
                      filter: {
                        ...prev.filter,
                        committee_meeting_date: date,
                      },
                    })),
                  1000
                )}
                // minDate={new Date()}
                placeholderText={"dd/mm/yyyy"}
                showYearDropdown={true}
              />
              {filters.filter.committee_meeting_date !== "" && (
                <RiCloseLine
                  className="absolute top-3 right-2 text-gray-400 hover:text-gray-600 cursor-pointer text-lg"
                  onClick={() =>
                    setFilters((prev) => ({
                      ...prev,
                      page: 1,
                      filter: { ...prev, committee_meeting_date: "" },
                    }))
                  }
                />
              )}
            </div>
          </div>
          <TableWrapper className="mt-4">
            <Table
              headers={headers}
              items={data?.data ?? []}
              // items={dummyData}
              isLoading={isLoading}
            />
            <Pagination
              page={data?.meta?.current_page ?? 1}
              limit={filters.limit}
              total={data?.meta?.total ?? 0}
              totalPage={data?.meta?.last_page ?? 1}
              onLimitChange={(e) =>
                setFilters((prev) => ({ ...prev, limit: e }))
              }
              onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
            />
          </TableWrapper>
        </div>
      )}

      {fdbType === "lembaga" && (
        <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-end">
            <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
              <RiSearchLine className="text-gray-400 text-xl" />

              <Input
                className="w-full bg-white border-none"
                onChange={debounce(
                  (e) =>
                    setFIltersInstitution((prev) => ({
                      ...prev,
                      page: 1,
                      filter: {
                        ...prev.filter,
                        search: e.target.value,
                      },
                    })),
                  1000
                )}
                placeholder="Cari"
              />
            </div>
            <div className="relative">
              <ReactDatePicker
                className={
                  "date_picker w-full !outline-none !focus:border-primary-700 border h-10 px-3 focus:border-primary-700 rounded-md"
                }
                selected={filters.filter.date}
                onChange={debounce(
                  (date) =>
                    setFIltersInstitution((prev) => ({
                      ...prev,
                      page: 1,
                      filter: {
                        ...prev.filter,
                        date: date,
                      },
                    })),
                  1000
                )}
                // minDate={new Date()}
                placeholderText={"dd/mm/yyyy"}
                showYearDropdown={true}
              />
              {filters.filter.date !== "" && (
                <RiCloseLine
                  className="absolute top-3 right-2 text-gray-400 hover:text-gray-600 cursor-pointer text-lg"
                  onClick={() =>
                    setFIltersInstitution((prev) => ({
                      ...prev,
                      page: 1,
                      filter: { ...prev, date: "" },
                    }))
                  }
                />
              )}
            </div>
          </div>
          <TableWrapper className="mt-4">
            <Table
              headers={headers}
              items={dataInstitution?.data ?? []}
              // items={dummyData}
              isLoading={isLoadingInstitution}
            />
            <Pagination
              page={dataInstitution?.meta?.current_page ?? 1}
              limit={filters.limit}
              total={dataInstitution?.meta?.total ?? 0}
              totalPage={dataInstitution?.meta?.last_page ?? 1}
              onLimitChange={(e) =>
                setFIltersInstitution((prev) => ({ ...prev, limit: e }))
              }
              onPageChange={(e) =>
                setFIltersInstitution((prev) => ({ ...prev, page: e }))
              }
            />
          </TableWrapper>
        </div>
      )}

      <Modal
        manual
        isOpen={risalahModal}
        onOpenChange={() => {
          setRisalahModal((prev) => !prev);
          setSelectedRisalah(undefined);
        }}
        contentClassName="!w-[600px] !max-w-[1184px] !z-[51]"
      >
        <div className="flex flex-col gap-5">
          <div className="flex flex-col p-5 gap-3">
            <h5 className="text-lg font-semibold">List Risalah</h5>

            {selectedRisalah?.map((risalah) => (
              <div
                className="flex flex-col p-4 border-2 rounded-md hover:border-primary-600 cursor-pointer"
                onClick={() => {
                  if (fdbType === "perorangan") {
                    const groupType =
                      risalah.service !== "null"
                        ? "Non Perhutanan Sosial"
                        : "Perhutanan Sosial";
                    navigate({
                      pathname: `risalah/${risalah.id}`,
                      search: `?group_type=${groupType}&review=risalah`,
                    });

                    return;
                  }

                  const mode = risalah?.is_expressed_review ? "view" : "edit";

                  navigate(
                    `/institutions/minutes/${risalah.risalah_id}?review=risalah&mode=${mode}`
                  );
                }}
              >
                <p className="font-medium">
                  {risalah.number ?? risalah?.risalah_number}
                </p>
                <p className="text-sm">
                  {risalah.service !== "null"
                    ? risalah.service
                    : "Perhutanan Sosial"}
                </p>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PendapatKomite;
