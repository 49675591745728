import CardForm from "components/molecules/CardForm";

const FDB_SCHEME = {
  DIRECT: "Langsung",
  INDIRECT: "Tidak Langsung",
};

const GRACE_PERIOD = {
  ADJUSTED_TO_THE_BUSINESS_CYCLE: "Disesuaikan Dengan Siklus Usaha",
  GRACE_PERIOD_CUSTOMIZATION: "Kustomisasi Grace Period",
};

const PERIOD_UNIT = {
  YEAR: "Tahun",
  MONTH: "Bulan",
  DAY: "Hari",
};

const OfferingContent = ({ data }) => {
  return (
    <CardForm label="Penawaran">
      <ul>
        <li>
          <div className="font-semibold">Jenis Layanan FDB </div>
          <p>{data?.provision_service ?? "-"}</p>
        </li>
      </ul>
      <ul>
        <li>
          <div className="font-semibold">Skema Penyaluran FDB </div>
          <p>
            {data?.fdb_distribution_scheme
              ? FDB_SCHEME[data?.fdb_distribution_scheme]
              : "-"}
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <div className="font-semibold">Tujuan FDB </div>
          {data?.fdb_objectives ? (
            <div
              dangerouslySetInnerHTML={{ __html: data?.fdb_objectives }}
            ></div>
          ) : (
            <p>-</p>
          )}
        </li>
      </ul>
      <ul>
        <li>
          <div className="font-semibold">Grace Period </div>
          <p>{data?.grace_period ? GRACE_PERIOD[data?.grace_period] : "-"}</p>
        </li>
      </ul>
      <ul>
        <li>
          <div className="font-semibold">Jangka Waktu Pinjaman FDB </div>
          {data?.fdb_loan_term ? (
            <div
              dangerouslySetInnerHTML={{ __html: data?.fdb_loan_term }}
            ></div>
          ) : (
            <p>-</p>
          )}
        </li>
      </ul>
      <ul>
        <li>
          <div className="font-semibold">Jangka Waktu Pembiayaan FDB </div>
          <p>
            {data?.fdb_financing_term_value
              ? `${data?.fdb_financing_term_value} ${
                  PERIOD_UNIT[data?.fdb_financing_term_period || "YEAR"]
                }`
              : "-"}
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <div className="font-semibold">Jaminan </div>
          {data?.guarantee ? (
            <div dangerouslySetInnerHTML={{ __html: data?.guarantee }}></div>
          ) : (
            <p>-</p>
          )}
        </li>
      </ul>
      <ul>
        <li>
          <div className="font-semibold">Tarif Layanan </div>
          <p>
            {data?.percentage_service_rate_per_year
              ? `${data?.percentage_service_rate_per_year} %`
              : "-"}
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <div className="font-semibold">Penyaluran FDB </div>
          {data?.fdb_distribution?.length > 0 ? (
            <ul className="list-decimal list-inside">
              {data?.fdb_distribution
                ?.sort((a, b) => {
                  return a.orderNumber - b.orderNumber;
                })
                .map((x, key) => {
                  return (
                    <>
                      <li key={key}>{x.value}</li>
                      {x?.sub?.length > 0 ? (
                        <ul className="list-[lower-alpha] list-inside pl-3">
                          {x?.sub
                            ?.sort((a, b) => {
                              return a.orderNumber - b.orderNumber;
                            })
                            .map((sub, idx) => (
                              <>
                                <li key={`sub-${idx}`}>{sub.value}</li>
                                {sub?.sub_sub?.length > 0 ? (
                                  <ul className="list-[lower-alpha] list-inside pl-3">
                                    {sub?.sub_sub
                                      ?.sort((a, b) => {
                                        return a.orderNumber - b.orderNumber;
                                      })
                                      .map((ss, i) => (
                                        <li key={`sub_sub-${i}`}>
                                          {ss?.value}
                                        </li>
                                      ))}
                                  </ul>
                                ) : null}
                              </>
                            ))}
                        </ul>
                      ) : null}
                    </>
                  );
                })}
            </ul>
          ) : (
            <p>-</p>
          )}
        </li>
      </ul>
      <ul>
        <li>
          <div className="font-semibold">Pengembalian FDB </div>
          {data?.fdb_return?.length > 0 ? (
            <ul className="list-decimal list-inside">
              {data?.fdb_return
                ?.sort((a, b) => {
                  return a.orderNumber - b.orderNumber;
                })
                .map((x, key) => {
                  return (
                    <>
                      <li key={key}>{x.value}</li>
                      {x?.sub?.length > 0 ? (
                        <ul className="list-[lower-alpha] list-inside pl-3">
                          {x?.sub
                            ?.sort((a, b) => {
                              return a.orderNumber - b.orderNumber;
                            })
                            .map((sub, idx) => (
                              <li key={`sub-${idx}`}>{sub.value}</li>
                            ))}
                        </ul>
                      ) : null}
                    </>
                  );
                })}
            </ul>
          ) : (
            <p>-</p>
          )}
        </li>
      </ul>
      <ul>
        <li>
          <div className="font-semibold">Objek Yang Dibiayai </div>
          {data?.the_object_being_financed ? (
            <div
              dangerouslySetInnerHTML={{
                __html: data?.the_object_being_financed,
              }}
            ></div>
          ) : (
            <p>-</p>
          )}
        </li>
      </ul>
      <ul>
        <li>
          <div className="font-semibold">
            Hal-hal yang Harus Dilakukan (Positive Covenants){" "}
          </div>
          {data?.positive_covenants?.length > 0 ? (
            <ul className="list-decimal list-inside">
              {data?.positive_covenants
                ?.sort((a, b) => {
                  return a.orderNumber - b.orderNumber;
                })
                .map((x, key) => (
                  <li key={key}>{x.value}</li>
                ))}
            </ul>
          ) : (
            <p>-</p>
          )}
        </li>
      </ul>
      <ul>
        <li>
          <div className="font-semibold">
            Hal-hal yang Tidak Boleh Dilaksanakan (Negative Covenants){" "}
          </div>
          {data?.negative_covenants?.length > 0 ? (
            <ul className="list-decimal list-inside">
              {data?.negative_covenants
                ?.sort((a, b) => {
                  return a.orderNumber - b.orderNumber;
                })
                .map((x, key) => (
                  <li key={key}>{x.value}</li>
                ))}
            </ul>
          ) : (
            <p>-</p>
          )}
        </li>
      </ul>
    </CardForm>
  );
};

export default OfferingContent;
