import React from "react";
import CardForm from "components/molecules/CardForm";
import { InputForm, InputFormRadio } from "components";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";
import { useFormContext, useWatch } from "react-hook-form";
import { formatCurrency } from "helpers";
import { startCase, toLower } from "lodash";
import angkaTerbilangJs from "@develoka/angka-terbilang-js";
import { useSearchParams } from "react-router-dom";

const FinancingStructure = ({ data }) => {
  const methods = useFormContext();
  const [searchParams] = useSearchParams();

  const isNego = Number(searchParams?.get("status") || 0) === 3;

  const isVerified = useWatch({
    name: "structure_verify",
    control: methods.control,
  });
  return (
    <CardForm label="Struktur Pembiayaan">
      <div className="grid grid-cols-3 gap-4">
        <div className="bg-[#E1F1D6] p-3 w-full col-span-3">
          <p className="font-bold text-[#01A24A]">Negosiasi yang Ditawarkan</p>
        </div>
        <div className="grid grid-cols-2 gap-4 col-span-3">
          <div>
            <div className="font-semibold">Jumlah FDB</div>
            <div>
              {data?.filed?.fdb_value
                ? formatCurrency(data?.offered?.fdb_value)
                : "-"}
            </div>
          </div>
          <div>
            <div className="font-semibold">Terbilang</div>
            <div>
              {data?.offered?.fdb_value
                ? startCase(toLower(angkaTerbilangJs(data?.offered?.fdb_value)))
                : "-"}
            </div>
          </div>
        </div>
        <div>
          <div className="font-semibold">Tarif Layanan Pertahun</div>
          <div>{data?.offered?.percentage_service_rate_per_year ?? "-"}</div>
        </div>
        <div className="">
          <div className="font-semibold">Metode Pemberlakuan</div>
          <div>{data?.offered?.service_rate_calculation_method ?? "-"}</div>
        </div>
        <div className="">
          <div className="font-semibold">Metode Pemberlakuan Tarif</div>
          <div>{data?.offered?.service_rates_application_method ?? "-"}</div>
        </div>
        <div className="grid grid-cols-3 gap-4 col-span-3">
          <div>
            <div className="font-semibold">Jangka Waktu</div>
            <div>
              {data?.offered?.term_value
                ? `${data?.offered?.term_value} ${data?.offered?.term_type}`
                : "-"}
            </div>
          </div>
          <div>
            <div className="font-semibold">Grace Period</div>
            <div>
              {data?.offered?.grace_period_term_value
                ? `${data?.offered?.grace_period_term_value} ${data?.offered?.grace_period_term_type}`
                : "-"}
            </div>
          </div>
          {/* <div>
            <div className="font-semibold">Jangka Waktu Diperpanjang</div>
            <div>
              {data?.offered?.extend_value
                ? `${data?.offered?.extend_value} ${data?.offered?.extend_type}`
                : "-"}
            </div>
          </div> */}
          <div>
            <div className="font-semibold">Kesepakatan Kedua Belah Pihak</div>
            <div>{data?.offered?.agreed_by_both ? "Ya" : "Tidak"}</div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4 col-span-3">
          <div>
            <div className="font-semibold">Tahap Pencairan</div>
            <div>{data?.offered?.distribution?.length}</div>
          </div>
          {data?.offered?.distribution?.map((val, idx) => (
            <div key={val.id}>
              <div className="font-semibold">{`Tahap ${idx + 1}`}</div>
              <div>{val?.value ? formatCurrency(val?.value) : "-"}</div>
            </div>
          ))}
        </div>
        <div className="bg-[#E1F1D6] p-3 w-full col-span-3">
          <p className="font-bold text-[#01A24A]">Negosiasi yang Diajukan</p>
        </div>
        <div className="grid grid-cols-2 gap-4 col-span-3">
          <div>
            <div className="font-semibold">Jumlah FDB</div>
            <div>
              {data?.filed?.fdb_value
                ? formatCurrency(data?.filed?.fdb_value)
                : "-"}
            </div>
          </div>
          <div>
            <div className="font-semibold">Terbilang</div>
            <div>
              {data?.filed?.fdb_value
                ? startCase(toLower(angkaTerbilangJs(data?.filed?.fdb_value)))
                : "-"}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4 col-span-3">
          <div>
            <div className="font-semibold">Jangka Waktu</div>
            <div>
              {data?.filed?.term_value
                ? `${data?.filed?.term_value} ${data?.filed?.term_type}`
                : "-"}
            </div>
          </div>
          <div>
            <div className="font-semibold">Grace Period</div>
            <div>
              {data?.filed?.grace_period_term_value
                ? `${data?.filed?.grace_period_term_value} ${data?.filed?.grace_period_term_type}`
                : "-"}
            </div>
          </div>
          <div>
            <div className="font-semibold">Jangka Waktu Diperpanjang</div>
            <div>
              {data?.filed?.extend_value
                ? `${data?.filed?.extend_value} ${data?.filed?.extend_type}`
                : "-"}
            </div>
          </div>
          <div>
            <div className="font-semibold">Kesepakatan Kedua Belah Pihak</div>
            <div>{data?.filed?.agreed_by_both ? "Ya" : "Tidak"}</div>
          </div>
        </div>
        {isNego && (
          <div className="border rounded-md px-4 py-3 col-span-3">
            <InputFormRadio
              label={"Hasil Verifikasi"}
              controllerName={`structure_verify`}
              className="flex items-center justify-between gap-4"
              values={[
                {
                  label: (
                    <>
                      <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                      Data Terverifikasi
                    </>
                  ),
                  value: true,
                },
                {
                  label: (
                    <>
                      <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                      Data Belum Sesuai/ Ada Kesalahan
                    </>
                  ),
                  value: false,
                },
              ]}
            />
            {typeof isVerified !== "undefined" && isVerified === false && (
              <InputForm
                controllerName="structure_verify_desc"
                label="jelaskan kesalahan/ ketidak sesuaian datanya"
                placeholder="jelaskan kesalahan/ ketidak sesuaian datanya"
                required={true}
                wrapperClass="col-span-2 mt-3"
                className="rounded-md w-full px-4 py-3 border"
                textArea
              />
            )}
          </div>
        )}
      </div>
    </CardForm>
  );
};

export default FinancingStructure;
