import DeskAnalyst from "pages/AssignPelaksana/DeskAnalyst";
import DeskAnalystDetail from "pages/AssignPelaksana/DeskAnalyst/Detail";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    routeType: "private",
    path: "/desk-analyst",
    element: DeskAnalyst,
    exact: true,
    sidebar: true,
    key: "assign_desk_analyst",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/desk-analyst/:id",
    element: DeskAnalystDetail,
    exact: true,
    sidebar: true,
    key: "assign_desk_analyst",
    type: "canUpdate"
  },
];
