import { Button, InputForm } from "components";
import Badge from "components/atoms/Badge";
import Table, { TableWrapper } from "components/atoms/Table";
import CardForm from "components/molecules/CardForm";
import BasicModal from "components/molecules/Modal/BasicModal";
import { Pagination } from "components/v2/shared/pagination";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { createPortal } from "react-dom";
import graphic from "../../../../../assets/confirmation_graphic.svg";
import { RiMapPin2Fill } from "react-icons/ri";
import { useQuery } from "@tanstack/react-query";
import { getDetailRisalahMemberSampling } from "services/fdb/risalah";
import { useFormContext } from "react-hook-form";
import withFileService from "hocs/with-file-service";
import { useSearchParams } from "react-router-dom";

const stateColors = {
  "Belum Direview": "warning",
  Baru: "bluelight",
  Draft: "default",
  "Tidak Sesuai": "error",
  Sesuai: "success",
  Terverifikasi: "success",
};

const CollateralFinancialAssets = withFileService(
  ({
    isLoading,
    debitur,
    tallySheet,
    filters,
    setFilters,
    getFileFromService,
  }) => {
    const { setValue } = useFormContext();
    const [samplingModal, setSamplingModal] = useState(false);
    const [selectedSampling, setSelectedSampling] = useState(undefined);
    const [fileUrl, setFileUrl] = useState(null);

    const [searchParams] = useSearchParams();

    const { data } = useQuery(
      ["GET_DETAIL_SAMPLING", selectedSampling],
      getDetailRisalahMemberSampling,
      {
        enabled:
          !!selectedSampling &&
          searchParams.get("group_type") === "Non Perhutanan Sosial",
        select: (data) => data?.data?.data,
        onSuccess: (data) => {
          if (data?.file && data?.file !== "string") {
            getFileFromService(data?.file, {
              onSuccess: (data) => {
                setFileUrl(data?.url);
              },
              onError: (error) => {
                console.log(error);
              },
            });
          }
          setValue("tree_number", data?.tree_number);
          setValue("type", data?.type);
          setValue("area", data?.area);
        },
      }
    );

    const headers = useMemo(() => {
      return [
        {
          key: "index",
          title: "No",
          render: ({ index }) => index + 1,
        },
        {
          key: "type",
          title: "Jenis Pohon",
        },
        {
          key: "area",
          title: "Keliling (CM)",
        },
        {
          key: "condition",
          title: "Kondisi",
        },
        {
          key: "price",
          title: "Harga",
          render: ({ item }) => (
            <p>RP. {Math.floor(item.price).toLocaleString()}</p>
          ),
        },
        {
          key: "tabulation_status_string",
          title: "Status",
          render: ({ item }) => (
            <Badge color={stateColors[item.tabulation_status_string]}>
              {item.tabulation_status_string}
            </Badge>
          ),
        },
        {
          key: "action",
          title: "Aksi",
          alignment: "center",
          className: "sticky right-0 bg-white",
          render: ({ item }) => (
            <>
              <Button
                theme="primary"
                variant="icon"
                label={<>Lihat Sampling</>}
                onClick={() => {
                  setSelectedSampling(item.id);
                  setSamplingModal(true);
                }}
              />
            </>
          ),
        },
      ];
    }, []);

    return (
      <>
        {createPortal(
          <BasicModal
            title={"Lihat Sampling"}
            open={samplingModal}
            onClose={() => {
              setSamplingModal((prev) => !prev);
              setSelectedSampling(undefined);
              setValue("tree_number", "");
              setValue("type", "");
              setValue("area", "");
            }}
            className="space-y-2 mb-4"
            hideButton={true}
          >
            <div className="p-4 md:p-5 space-y-5">
              <div className=" my-1 py-2 w-full">
                <p className="font-bold text-[#01A24A]">Tallysheet Data Desk</p>
              </div>
              <InputForm
                label="Nomor Pohon"
                controllerName={"tree_number"}
                className={"rounded-md w-full"}
                disabled
              />
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <InputForm
                  label="Jenis"
                  controllerName={"type"}
                  className={"rounded-md w-full"}
                  disabled
                />
                <InputForm
                  label="Keliling (cm)"
                  controllerName={"area"}
                  className={"rounded-md w-full"}
                  disabled
                />
              </div>
              <div className=" my-1 py-2 w-full">
                <p className="font-bold text-[#01A24A]">
                  Hasil Sampling Field Analis
                </p>
              </div>
              <div className=" my-1 py-2 w-full">
                <p className="font-semibold">Foto Pohon</p>
                {fileUrl ? (
                  <img
                    src={fileUrl}
                    width="250"
                    height="250"
                    alt="Confirmation Graphic"
                  />
                ) : (
                  <div>-</div>
                )}
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-4">
                <div>
                  <p className="font-semibold">Nama Debitur</p>
                  <p>{debitur?.name}</p>
                </div>
                <div>
                  <p className="font-semibold">NIK</p>
                  <p>{debitur?.identity}</p>
                </div>
                <div>
                  <p className="font-semibold">Tanggal Lahir</p>
                  <p>{dayjs(debitur?.date_of_birth).format("DD MMMM YYYY")}</p>
                </div>
                <div>
                  <p className="font-semibold">Kebutuhan Pinjaman</p>
                  <p>RP. {Math.floor(debitur?.loan).toLocaleString()}</p>
                </div>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                <div>
                  <div className="font-semibold">Longitude</div>
                  <div>{data?.longitude}</div>
                </div>
                <div>
                  <div className="font-semibold">Latitude</div>
                  <div>{data?.latitude}</div>
                </div>
                <div>
                  <div className="font-semibold">Geo Location</div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-1">
                    <Button
                      type={"button"}
                      className="bg-[#00BD52] text-white py-4  rounded-lg font-semibold text-[12px]"
                      label={
                        <div className="flex items-center gap-1 font-[14px]">
                          <RiMapPin2Fill />
                          Google Maps
                        </div>
                      }
                      // onClick={onContinueClick(entry)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </BasicModal>,
          document.body
        )}

        <CardForm label="Jaminan Aset Kehutanan">
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
            <div>
              <p className="font-semibold">Nama Debitur</p>
              <p>{debitur?.name}</p>
            </div>
            <div>
              <p className="font-semibold">NIK</p>
              <p>{debitur?.identity}</p>
            </div>
            <div>
              <p className="font-semibold">Tanggal Lahir</p>
              {/* <p>20 Oktober 1972</p> */}
              <p>{dayjs(debitur?.date_of_birth).format("DD MMMM YYYY")}</p>
            </div>
            <div>
              <p className="font-semibold">Kebutuhan Pinjaman</p>
              <p>RP. {Math.floor(debitur?.loan).toLocaleString()}</p>
              {/* <p>RP. 50.000.000</p> */}
            </div>
            <div>
              <p className="font-semibold">Jangka Waktu Pinjaman</p>
              <p>{debitur?.period_loan}</p>
              {/* <p>2 Tahun</p> */}
            </div>
          </div>
          <TableWrapper className="mt-4">
            <Table
              headers={headers}
              items={tallySheet?.tally_sheets.data ?? []}
              isLoading={isLoading}
            />
            <Pagination
              page={tallySheet?.tally_sheets?.meta?.current_page ?? 1}
              limit={filters.limit}
              total={tallySheet?.tally_sheets?.meta?.total ?? 0}
              totalPage={tallySheet?.tally_sheets?.meta?.last_page ?? 1}
              onLimitChange={(e) =>
                setFilters((prev) => ({ ...prev, limit: e }))
              }
              onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
            />
            {/* <TablePagination
            totalPage={totalPage}
            totalItems={totalItems}
            onChange={setPage}
            onItemsPerPageChange={setLimit}
          /> */}
          </TableWrapper>
        </CardForm>
      </>
    );
  }
);

export default CollateralFinancialAssets;
