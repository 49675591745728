import React, { useMemo, useState, useCallback, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AtomSelect from "components/atoms/Select";
import Button from "components/atoms/Button/Button";
import CardForm from "components/molecules/CardForm";
import { useNavigate, useParams } from "react-router";
import { TabsContent } from "components/atoms/Tabs";
import {
  RiEditLine,
  RiCheckboxCircleFill,
  RiCloseCircleFill,
} from "react-icons/ri";
import { InputForm, InputFormRadio, BottomFormAction } from "components";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getActivityCategory } from "services/masterData/activityCategory";
import { useSelector } from "react-redux";
import { getMasterBussinessCategory } from "services/masterData/bussinessCategory";
import { useSearchParams } from "react-router-dom";
import { putVerificator } from "services/fdb/verificator";
// import { enqueueSnackbar } from "notistack";

const PortfolioTabContent = ({
  initialValues,
  type,
  data,
  verificationData,
}) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const userId = useSelector((state) => state?.oidc?.oidc_user?.access_token);
  const [isEdit, setIsEdit] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      is_verified_portfolio: yup
        .bool()
        .transform((originalValue) =>
          originalValue === "" ? undefined : originalValue
        )
        .required()
        .label("Kesimpulan Verifikasi"),
      verified_portfolio_desc: yup
        .string()
        .when("is_verified_portfolio", ([value], schema) => {
          if (value === false) {
            return schema.required().label("Kesalahan/Ketidak sesuaian data");
          }
          return schema.notRequired();
        }),
    });
  }, []);

  const postVerification = useMutation({
    mutationFn: (payload) => {
      const res = putVerificator(
        payload,
        data?.id,
        data?.group_profile_id,
        false
      );
      return res;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([
        "group-application-verification-detail",
        id,
      ]);
    },
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const onBack = useCallback(() => {
    const values = methods.getValues();
    const { activities, partners, finance_businesses, ...rest } = values;
    const payload = {
      verified_status: {
        ...verificationData.verification,
        ...rest,
        data_updates: [
          ...verificationData.data_updates,
          ...activities,
          ...partners,
          ...finance_businesses,
        ],
      },
    };

    if (data?.verificator_status === "not_yet_review") {
      postVerification.mutate(payload, {
        onSuccess: (res) => {
          setSearchParams({ tab: "profile" });
        },
      });

      return;
    }
    setSearchParams({ tab: "profile" });
  }, [
    verificationData,
    methods,
    setSearchParams,
    postVerification,
    data?.verificator_status,
  ]);

  const onFormSubmit = useCallback(
    (values) => {
      const { activities, partners, finance_businesses, ...rest } = values;
      const payload = {
        verified_status: {
          ...verificationData.verification,
          ...rest,
          data_updates: [
            ...verificationData.data_updates,
            ...activities,
            ...partners,
            ...finance_businesses,
          ],
        },
      };

      if (data?.verificator_status === "not_yet_review") {
        postVerification.mutate(payload, {
          onSuccess: (res) => {
            setSearchParams({ tab: "requests" });
          },
        });

        return;
      }
      setSearchParams({ tab: "requests" });
    },
    [
      verificationData,
      data?.verificator_status,
      postVerification,
      setSearchParams,
    ]
  );

  const onDraft = useCallback(() => {
    //
  }, []);

  const { fields: activities } = useFieldArray({
    control: methods.control,
    name: "activities",
  });
  const { fields: partners } = useFieldArray({
    control: methods.control,
    name: "partners",
  });
  const { fields: businessTypes } = useFieldArray({
    control: methods.control,
    name: "finance_businesses",
  });

  const { data: activityCategory } = useQuery({
    queryKey: ["master-category-fdb"],
    queryFn: async () => {
      const res = await getActivityCategory(userId);
      const activities = res?.data?.data?.data || [];
      const formattedData = activities.map((activity) => ({
        value: activity.id,
        label: activity.name,
      }));
      if (formattedData.length <= 0) {
        formattedData.push({
          value: null,
          label: null,
        });
      }
      return formattedData;
    },
    retry: 1,
  });

  const { data: bussinessCategory } = useQuery({
    queryKey: ["master-bussiness-category-fdb"],
    queryFn: async () => {
      const res = await getMasterBussinessCategory(userId);
      const activities = res?.data?.data?.data || [];
      const formattedData = activities.map((activity) => ({
        value: activity.id,
        label: activity.name,
      }));
      if (formattedData.length <= 0) {
        formattedData.push({
          value: null,
          label: null,
        });
      }
      return formattedData;
    },
    retry: 1,
  });

  const isVerified = useWatch({
    name: "is_verified_portfolio",
    control: methods.control,
  });

  useEffect(() => {
    if (data) {
      const profile = data?.group_profile;
      const activitiesArr = profile?.activities.map((item) => {
        return {
          group_profile_activitie_id: item?.id,
          data: {
            activity_category_id: item?.activity_category_id,
            description: item?.description,
          },
        };
      });
      const partnersArr = profile?.partners.map((item) => {
        return {
          group_profile_partner_id: item?.id,
          data: {
            name: item?.name,
          },
        };
      });
      const financeArr = profile?.finance_businesses.map((item) => {
        return {
          group_profile_finance_business_id: item?.id,
          data: {
            finance_business_category_id: item?.finance_business_category_id,
            description: item?.description,
          },
        };
      });
      methods.reset({
        is_verified_portfolio: profile?.is_verified_portfolio,
        verified_portfolio_desc: profile?.verified_portfolio_desc,
        activities: activitiesArr,
        partners: partnersArr,
        finance_businesses: financeArr,
        total_member: data?.group_profile?.total_member_not_apply,
        member_count: data?.group_profile?.total_member_apply,
        number_of_shares: data?.group_profile?.total_member_apply_persil,
        total_land_area: data?.group_profile?.total_member_apply_area,
      });
    }
  }, [data, methods]);

  return (
    <FormProvider {...methods}>
      <TabsContent value="portfolio" className="mb-40">
        <CardForm
          label="Portofolio Kelompok"
          rightSlot={() =>
            data?.status !== "verified" && (
              <Button
                label={
                  <>
                    <RiEditLine />
                    Ubah
                  </>
                }
                onClick={() => setIsEdit(!isEdit)}
              />
            )
          }
        >
          {type === "persos" && (
            <>
              <InputForm
                controllerName="total_member"
                label="Jumlah anggota KPS/KUPS (Termasuk yang tidak mengajukan pinjaman)"
                required={true}
                className="rounded-md w-full px-4 py-3 border"
                disabled={!isEdit}
              />
              <InputForm
                controllerName="member_count"
                label="Jumlah anggota KPS/KUPS yang mengajukan permohonan"
                required={true}
                className="rounded-md w-full px-4 py-3 border"
                disabled={!isEdit}
              />
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <InputForm
                  controllerName="number_of_shares"
                  label="Jumlah Andil Garapan"
                  required={true}
                  className="rounded-md w-full px-4 py-3 border"
                  disabled={!isEdit}
                />
                <InputForm
                  controllerName="total_land_area"
                  label="Total Luas Lahan"
                  required={true}
                  className="rounded-md w-full px-4 py-3 border"
                  disabled={!isEdit}
                />
              </div>
            </>
          )}

          {activities.map((item, index) => (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <AtomSelect
                controllerName={`activities[${index}].data.activity_category_id`}
                wrapperClass={"w-full"}
                className="basic-single w-full"
                options={activityCategory}
                label="Kegiatan - Kegiatan Kelompok yang telah dilaksanakan "
                placeholder="Pilih Kategori "
                returnObject={false}
                disabled={!isEdit}
              />
              <InputForm
                label="&nbsp;"
                controllerName={`activities[${index}].data.description`}
                className="rounded-md w-full px-4 py-3 border"
                disabled={!isEdit}
                textArea
              />
            </div>
          ))}

          {partners.map((item, index) => (
            <InputForm
              controllerName={`partners[${index}].data.name`}
              label="Mitra Usaha Kelompok (jika Ada)"
              placeholder="Mitra Usaha"
              className="rounded-md w-full px-4 py-3 border"
              disabled={!isEdit}
            />
          ))}

          {businessTypes.map((item, index) => (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <AtomSelect
                controllerName={`finance_businesses[${index}].data.finance_business_category_id`}
                wrapperClass={"w-full"}
                className="basic-single w-full"
                options={bussinessCategory}
                label="Gambaran umum jenis - jenis usaha anggota kelompok yang dibiayai"
                placeholder={"Pilih Kategori"}
                returnObject={false}
                disabled={!isEdit}
              />
              <InputForm
                label="&nbsp;"
                controllerName={`finance_businesses[${index}].data.description`}
                className="rounded-md w-full px-4 py-3 border"
                disabled={!isEdit}
                textArea
              />
            </div>
          ))}

          <div className="border rounded-md px-4 py-3">
            <InputFormRadio
              label={"Hasil Verifikasi"}
              controllerName={`is_verified_portfolio`}
              className="flex items-center justify-between gap-4"
              disabled={data?.verificator_status !== "not_yet_review"}
              values={[
                {
                  label: (
                    <>
                      <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                      Data Terverifikasi
                    </>
                  ),
                  value: true,
                },
                {
                  label: (
                    <>
                      <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                      Data Belum Sesuai/ Ada Kesalahan
                    </>
                  ),
                  value: false,
                },
              ]}
            />
            {typeof isVerified !== "undefined" && isVerified === false && (
              <InputForm
                controllerName="verified_portfolio_desc"
                label="jelaskan kesalahan/ ketidak sesuaian datanya"
                placeholder="jelaskan kesalahan/ ketidak sesuaian datanya"
                required={true}
                wrapperClass="col-span-2 mt-3"
                className="rounded-md w-full px-4 py-3 border"
                disabled={isEdit}
                textArea
              />
            )}
          </div>
        </CardForm>

        <BottomFormAction
          backButtonAction={onBack}
          submitButtonProps={{
            type: "submit",
          }}
          submitActionButton={() => methods.handleSubmit(onFormSubmit)()}
          disableDrafButton={false}
          drafButtonAction={onDraft}
          disableButtonSubmit={false}
          className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
          hideDraft={true}
        />
      </TabsContent>
    </FormProvider>
  );
};

export default PortfolioTabContent;
