import { useQuery } from "@tanstack/react-query";
import { Button, Input } from "components";
import Table, { TableWrapper } from "components/atoms/Table";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { Pagination } from "components/v2/shared/pagination";
import { debounce } from "lodash";
import { useMemo, useState } from "react";
import {
  RiAddLine,
  RiCloseLine,
  RiEyeLine,
  RiFile3Line,
  RiSearchLine,
} from "react-icons/ri";
import { getCommitteeMeetingAgenda } from "services/fdb/committee";
import cn from "clsx";
import ReactDatePicker from "react-datepicker";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { Modal } from "components/atoms/Modal";
import { RisalahModal } from "./Components/modal-risalah";
import { getListMeetingAgenda } from "services/institutions/meeting-agenda";
import { RisalahModalInstitution } from "./Components/modal-risalah-institution";

const title = "Agenda Rapat";

const badgeStyle = {
  "Sedang Berjalan": "bg-[#FFFAEB] text-[#B54708]",
  Selesai: "bg-[#EFF8FF] text-[#175CD3]",
};

const MeetingAgenda = ({ fdbType, accessRight }) => {
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    filter: {
      search: "",
      committee_meeting_date: "",
    },
  });

  const [filtersInstitution, setFiltersInstitution] = useState({
    paginate: true,
    page: 1,
    limit: 10,
    filter: {
      search: "",
      date: "",
    },
  });

  const [risalah, setRisalah] = useState();

  const [risalahModal, setRisalahModal] = useState(false);
  const [meetingId, setMeetingId] = useState();

  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    ["GET_MEETING_AGENDA", filters],
    ({ queryKey }) => getCommitteeMeetingAgenda(queryKey[1]),
    { select: (data) => data?.data?.data, enabled: fdbType === "perorangan" }
  );

  const { data: dataInstitution, isLoading: isLoadingInstitution } = useQuery(
    [
      "GET_MEETING_AGENDA_INSTITUION",
      {
        ...filtersInstitution,
        filter: {
          ...filtersInstitution.filter,
          date:
            filtersInstitution.filter.date !== ""
              ? dayjs(filtersInstitution.filter.date).format("YYYY-MM-DD")
              : "",
        },
      },
    ],
    getListMeetingAgenda,
    {
      select: (data) => data?.data?.data,
      enabled: fdbType === "lembaga",
    }
  );

  const headers = useMemo(
    () => [
      {
        key: "index",
        title: "No",
        render: ({ index }) => {
          const meta = data?.meta ?? dataInstitution?.meta;

          const no =
            parseInt(meta?.current_page) * parseInt(meta?.per_page) -
            parseInt(meta?.per_page) +
            index +
            1;

          return no;
        },
      },
      {
        key: "topic_discussion",
        title: "Topik Rapat",
        render: ({ item }) => <p>{item.topic_discussion}</p>,
      },
      {
        key: "implementation_date",
        title: "Jadwal Rapat",
        render: ({ item }) => (
          <p>
            {item.implementation_time
              ? dayjs(item?.implementation_time).subtract(7, "hours").format(
                "dddd, DD MMMM YYYY ; HH:mm A"
              )
              : "-"}
          </p>
        ),
      },
      {
        key: "implementation_place",
        title: "Tempat",
        render: ({ item }) => <p>{item.implementation_place}</p>,
      },
      {
        key: "name",
        title: "Risalah",
        render: ({ item }) => (
          <div
            className="text-sm font-medium flex items-center gap-3 group cursor-pointer"
            onClick={() => {
              setRisalah(item?.risalah);
              setMeetingId(item.id);
              setRisalahModal((prev) => !prev);
            }}
          >
            <div className="p-[10px] bg-primary-100 rounded-full text-primary-700">
              <RiFile3Line size={20} />
            </div>
            <p className="group-hover:underline">Lihat Daftar Risalah</p>
          </div>
        ),
      },
      // {
      //   key: "status",
      //   title: "Status Rapat",
      //   render: ({ item }) => (
      //     <span
      //       className={cn(
      //         "py-0.5 px-2 rounded-full text-xs font-medium",
      //         badgeStyle[item.status]
      //       )}
      //     >
      //       {item.status ?? "-"}
      //     </span>
      //   ),
      // },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <Button
            variant="icon"
            label={<RiEyeLine className="text-lg" />}
            onClick={() => {
              navigate(`${item.id}`);
            }}
          />
        ),
      },
    ],
    [data?.meta, navigate, dataInstitution?.meta]
  );

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: "/committee/meeting-agenda",
          },
        ]}
      />

      <div className="flex justify-between">
        <h1 className="text-gray-900 text-2xl font-semibold leading-loose">
          Daftar Agenda Rapat
        </h1>
        {accessRight?.canCreate && (
          <Button
            className="bg-primary-700 text-white font-semibold text-sm hover:bg-primary-600"
            label={
              <div className="flex items-center gap-2">
                <RiAddLine size={20} />
                <p>Tambah Agenda Rapat</p>
              </div>
            }
            onClick={() => navigate("form")}
          />
        )}
      </div>

      {fdbType === "perorangan" && (
        <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-end">
            <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
              <RiSearchLine className="text-gray-400 text-xl" />

              <Input
                className="w-full bg-white border-none"
                onChange={debounce((e) => {
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      search: e.target.value,
                    },
                  }));
                }, 1000)}
                placeholder="Cari"
              />
            </div>
            <div className="relative">
              <ReactDatePicker
                className={
                  "date_picker w-full !outline-none !focus:border-primary-700 border h-10 px-3 focus:border-primary-700 rounded-md"
                }
                selected={filters.filter.committee_meeting_date}
                onChange={debounce((date) => {
                  setFilters((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      committee_meeting_date: date,
                    },
                  }));
                }, 1000)}
                // minDate={new Date()}
                placeholderText={"dd/mm/yyyy"}
                showYearDropdown={true}
              />
              {filters.filter.committee_meeting_date !== "" && (
                <RiCloseLine
                  className="absolute top-3 right-2 text-gray-400 hover:text-gray-600 cursor-pointer text-lg"
                  onClick={() =>
                    setFilters((prev) => ({
                      ...prev,
                      page: 1,
                      filter: { ...prev, committee_meeting_date: "" },
                    }))
                  }
                />
              )}
            </div>
          </div>

          <TableWrapper className="mt-4">
            <Table
              headers={headers}
              items={data?.data ?? []}
              // items={dummyData}
              isLoading={isLoading}
            />
            <Pagination
              page={data?.meta?.current_page ?? 1}
              limit={filters.limit}
              total={data?.meta?.total ?? 0}
              totalPage={data?.meta?.last_page ?? 1}
              onLimitChange={(e) =>
                setFilters((prev) => ({ ...prev, limit: e }))
              }
              onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
            />
          </TableWrapper>
        </div>
      )}

      {fdbType === "lembaga" && (
        <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-end">
            <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
              <RiSearchLine className="text-gray-400 text-xl" />

              <Input
                className="w-full bg-white border-none"
                onChange={debounce((e) => {
                  setFiltersInstitution((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      search: e.target.value,
                    },
                  }));
                }, 1000)}
                placeholder="Cari"
              />
            </div>
            <div className="relative">
              <ReactDatePicker
                className={
                  "date_picker w-full !outline-none !focus:border-primary-700 border h-10 px-3 focus:border-primary-700 rounded-md"
                }
                selected={filtersInstitution.filter.date}
                onChange={debounce((date) => {
                  setFiltersInstitution((prev) => ({
                    ...prev,
                    page: 1,
                    filter: {
                      ...prev.filter,
                      date: date,
                    },
                  }));
                }, 1000)}
                placeholderText={"dd/mm/yyyy"}
                showYearDropdown={true}
              />
              {filtersInstitution.filter.date !== "" && (
                <RiCloseLine
                  className="absolute top-3 right-2 text-gray-400 hover:text-gray-600 cursor-pointer text-lg"
                  onClick={() =>
                    setFiltersInstitution((prev) => ({
                      ...prev,
                      page: 1,
                      filter: { ...prev, date: "" },
                    }))
                  }
                />
              )}
            </div>
          </div>

          <TableWrapper className="mt-4">
            <Table
              headers={headers}
              items={dataInstitution?.data ?? []}
              isLoading={isLoadingInstitution}
            />
            <Pagination
              page={dataInstitution?.meta?.current_page ?? 1}
              limit={filtersInstitution.limit}
              total={dataInstitution?.meta?.total ?? 0}
              totalPage={dataInstitution?.meta?.last_page ?? 1}
              onLimitChange={(e) =>
                setFiltersInstitution((prev) => ({ ...prev, limit: e }))
              }
              onPageChange={(e) =>
                setFiltersInstitution((prev) => ({ ...prev, page: e }))
              }
            />
          </TableWrapper>
        </div>
      )}

      <Modal
        manual
        isOpen={risalahModal}
        onOpenChange={() => {
          setRisalahModal((prev) => !prev);
          setMeetingId(undefined);
        }}
        contentClassName="!w-[1184px] !max-w-[1184px] !z-[51]"
      >
        {fdbType === "lembaga" ? (
          <RisalahModalInstitution risalah={risalah} />
        ) : (
          <RisalahModal meetingId={meetingId} />
        )}
      </Modal>
    </div>
  );
};

export default MeetingAgenda;
