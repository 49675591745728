/* eslint-disable no-template-curly-in-string */
import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";
import { find } from "lodash";
import * as Yup from "yup";

export const validationSchemaPS = Yup.object().shape({
  // BUSSINESS INFO
  business_infos: Yup.array().of(
    Yup.object().shape({
      net_income_one_siclus: Yup.string()
        .required()
        .label("Kisaran Pendapatan Nett Dalam Satu Siklus Usaha"),
      financed_province_id: Yup.string().required().label("Provinsi"),
      financed_city_id: Yup.string().required().label("Kota/Kabupaten"),
      financed_district_id: Yup.string().required().label("Kecamatan"),
      financed_village_id: Yup.string().required().label("Kelurahan/Desa"),
      financed_address: Yup.string().required().label("Alamat"),
      debtor_house_file_temp: Yup.mixed()
        .test(
          "cid_img",
          ({ label }) =>
            validationErrorMsg.mixed.required.replace("${path}", label),
          (value, context) => {
            const allowedTypes = ["jpg", "png", "jpeg"];
            if (value) {
              const data = find(allowedTypes, (at) => value.type.includes(at));
              if (!data) {
                return context.createError({
                  message: validationCustomErrorMsg.isFiletype(
                    allowedTypes.join(", ")
                  )({ label: "Foto Usaha Debitur" }),
                });
              }
              if (value.size > 10 * 1024 * 1024) {
                return context.createError({
                  message: validationCustomErrorMsg.isFileSize("10MB")({
                    label: "Foto Usaha Debitur",
                  }),
                });
              }

              return true;
            }

            // otherwise, return false to render the field required
            return false;
          }
        )
        .required()
        .label("Foto Usaha Debitur"),
    })
  ),

  // PROSPECT
  prospect_verification: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Hasil Verifikasi"),
  prospect_verification_desk: Yup.string().when(
    "prospect_verification",
    ([value], schema) => {
      if (value === false) {
        return schema.required().label("Kesalahan/Ketidak sesuaian data");
      }
      return schema.notRequired();
    }
  ),
  prospect_income_adjusted: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi Ini"),
  prospect_maintenance_pattern: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi Ini"),
  prospect_sales_pattern: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi Ini"),
  prospect_make_profit: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi Ini"),
  prospect_normal_income: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi Ini"),

  // REQUEST
  application_details: Yup.object().shape({
    application: Yup.object()
      .shape({
        application_value: Yup.string().notRequired(),
        application_spell: Yup.string().notRequired(),
        installment_value: Yup.string().notRequired(),
        installment_spell: Yup.string().notRequired(),
        period_value: Yup.string().notRequired(),
        period_type: Yup.string().notRequired(),
      })
      .nullable(),
    desk_analyst: Yup.object()
      .shape({
        application_value: Yup.string().notRequired(),
        application_spell: Yup.string().notRequired(),
        installment_value: Yup.string().notRequired(),
        installment_spell: Yup.string().notRequired(),
        period_value: Yup.string().notRequired(),
        period_type: Yup.string().notRequired(),
      })
      .nullable(),
    field_analyst: Yup.object()
      .shape({
        category: Yup.string().required().label("Opsi Ini"),
        application_value_radio: Yup.string().when(
          [],
          ([value], schema, ctx) => {
            if (ctx.parent.category === "field_analyst") {
              return schema.required().label("Opsi Ini");
            }
            return schema.notRequired();
          }
        ),
        application_value_temp: Yup.string().when(
          [],
          ([value], schema, ctx) => {
            if (
              ctx.parent.category === "field_analyst" &&
              ctx.parent.application_value_radio === "other"
            ) {
              return schema.required().label("Nominal Rekomendasi");
            }
            return schema.notRequired();
          }
        ),
        application_value: Yup.string().notRequired(),
        application_spell: Yup.string().when([], ([value], schema, ctx) => {
          if (ctx.parent.category === "field_analyst") {
            return schema.required().label("Terbilang");
          }
          return schema.notRequired();
        }),
        installment_value: Yup.string().when([], ([value], schema, ctx) => {
          if (ctx.parent.category === "field_analyst") {
            return schema.required().label("Nominal Angsuran");
          }
          return schema.notRequired();
        }),
        installment_spell: Yup.string().notRequired(),
        period_value: Yup.string().when([], ([value], schema, ctx) => {
          if (ctx.parent.category === "field_analyst") {
            return schema.required().label("Jangka Waktu");
          }
          return schema.notRequired();
        }),
        period_type: Yup.string().when([], ([value], schema, ctx) => {
          if (ctx.parent.category === "field_analyst") {
            return schema.required().label("Periode Jangka Waktu");
          }
          return schema.notRequired();
        }),
      })
      .nullable(),
  }),
});
