import React, { useMemo } from "react";
import Table, { TableWrapper } from "components/atoms/Table";
import { formatCurrency } from "helpers";
import Checkbox from "components/atoms/Checkbox";
import CardForm from "components/molecules/CardForm";
import { Pagination } from "components/v2/shared/pagination";

const Guarantee = ({
  data,
  requestData,
  changePageCollateral,
  changeLimitCollateral,
  filterTable,
}) => {
  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "type",
        title: "Jenis Jaminan",
      },
      {
        key: "form",
        title: "Bentuk Jaminan",
      },
      {
        key: "value",
        title: "Nilai Jaminan",
        render: ({ item }) => {
          return item?.value ? `${formatCurrency(item?.value)}` : "-";
        },
      },
    ];
  }, []);

  const getTotal = () => {
    if (!data) return;
    const totalValue = data?.data?.reduce(
      (acc, curr) => acc + Number(curr.value),
      0
    );

    return totalValue;
  };

  return (
    <CardForm label="Jaminan">
      <div className="space-y-5">
        <div className="flex space-x-5">
          <div className="w-full bg-[#F2F9EC] p-4 rounded-md border-l-4 border-[#6CB745] flex-1">
            <p className="font-bold">Total Jaminan Yang Diajukan</p>
            <p>{formatCurrency(getTotal())}</p>
          </div>
          <div className="flex-1">
            <div className="font-semibold">Memenuhi Persyaratan Jaminan</div>
            <Checkbox
              controllerName={`collateral_meet_recruitment`}
              label={() => <p>{"Terpenuhi sebanyak 125%"}</p>}
              checked={
                getTotal() >
                Number(requestData?.value) + Number(requestData.value) / 4
              }
              inputWrapperClass={
                "bg-[#EEFFF4] py-3 flex gap-4 items-start rounded-lg"
              }
            />
          </div>
        </div>
        <TableWrapper>
          <Table headers={headers} items={data?.data || []} isLoading={false} />
          <Pagination
            page={Number(data?.meta?.current_page || 1)}
            limit={Number(filterTable?.limit_collateral || 10)}
            total={Number(data?.meta?.total || 0)}
            totalPage={Number(data?.meta?.last_page || 1)}
            onLimitChange={(e) => changeLimitCollateral(e)}
            onPageChange={(e) => changePageCollateral(e)}
          />
        </TableWrapper>
      </div>
    </CardForm>
  );
};

export default Guarantee;
