import BreadCrumbs from "components/molecules/Breadcrumbs";

import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";
import Recommendation from "./Section/Recommendation";
import DetailDebtor from "./Section/DetailDebtor";
import CollateralFinancialAssets from "./Section/CollateralFinancialAssets";
import LandTenureDocuments from "./Section/LandTenureDocuments";
import FinancedBusiness from "./Section/FinancedBusiness";
import { Button } from "components";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getDetailRisalahMember } from "services/fdb/risalah";
import { useSearchParams } from "react-router-dom";
import {
  RiArrowLeftLine,
  RiCheckboxCircleLine,
  RiCloseCircleLine,
} from "react-icons/ri";
import { updateUnitRequestMemberRisalah } from "services/fdb/committee";
import { enqueueSnackbar } from "notistack";

const RisalahDebtorDetail = () => {
  const { id, idDebtor } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const isPendapatKomite = location.pathname.includes("pendapat-komite");

  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState({ page: 1, limit: 10 });

  const { data, isLoading } = useQuery(
    ["GET_DETAIL_RISALAH_MEMBER", isPendapatKomite ? idDebtor : id, filters],
    getDetailRisalahMember,
    {
      enabled: isPendapatKomite ? !!idDebtor : !!id,
      select: (data) => data?.data?.data?.data,
    }
  );

  const methods = useForm({
    mode: "onChange",
  });

  const mutateUpdateRisalahUnit = useMutation({
    mutationFn: ({ id, params }) => updateUnitRequestMemberRisalah(id, params),
  });

  const handleApprove = () => {
    mutateUpdateRisalahUnit.mutate(
      {
        id: data?.detail_debtor?.request_id,
        params: {
          is_approve: true,
        },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Pendapat komite debitur berhasil disubmit",
          });
          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({
            variant: "success",
            message: "Pendapat komite debitur gagal disubmit",
          });
        },
      }
    );
  };

  const handleReject = () => {
    mutateUpdateRisalahUnit.mutate(
      {
        id: data?.detail_debtor?.request_id,
        params: {
          is_approve: false,
        },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Pendapat komite debitur berhasil disubmit",
          });
          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({
            variant: "success",
            message: "Pendapat komite debitur gagal disubmit",
          });
        },
      }
    );
  };

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: "Risalah",
            path: "/risalah",
          },
          {
            label: "Detail",
            path: `/risalah/${id}`,
          },
        ]}
      />
      <FormProvider {...methods}>
        <DetailDebtor data={data?.detail_debtor} />
        <FinancedBusiness data={data?.business_loan_perpose} />
        {searchParams.get("group_type") === "Non Perhutanan Sosial" && (
          <>
            <CollateralFinancialAssets
              isLoading={isLoading}
              debitur={data?.detail_debtor}
              tallySheet={data?.tally_sheet}
              filters={filters}
              setFilters={setFilters}
            />
            <LandTenureDocuments data={data?.document} />
          </>
        )}
        <Recommendation data={data?.recomendation} />
        {/* <AdditionalNotes /> */}

        <div className="sticky bottom-4 flex justify-between w-full p-5 bg-white border rounded-md">
          <Button
            className="p-4 border rounded-lg"
            type="button"
            label={
              <div className="flex items-center gap-2 text-sm font-medium">
                <RiArrowLeftLine />
                {isPendapatKomite ? "Kembali" : "Batal"}
              </div>
            }
            onClick={() => navigate(-1)}
          />
          {isPendapatKomite && searchParams.get("mode") === "unit" && (
            <div className="flex gap-3">
              {data?.expressed_status === null && (
                <>
                  <Button
                    className="p-4 bg-error-600 hover:bg-error-700 text-white"
                    type="button"
                    label={
                      <div className="flex items-center gap-2 text-sm font-medium">
                        <RiCloseCircleLine />
                        Tolak
                      </div>
                    }
                    onClick={handleReject}
                  />

                  <Button
                    className="p-4 border rounded-lg bg-primary-600 text-white hover:bg-primary-700"
                    type="button"
                    label={
                      <div className="flex items-center gap-2 text-sm font-medium">
                        <RiCheckboxCircleLine />
                        Setujui
                      </div>
                    }
                    onClick={handleApprove}
                  />
                </>
              )}
            </div>
          )}

          {/* <ModalConfirm type={"approve"} /> */}
        </div>
      </FormProvider>
    </div>
  );
};

export default RisalahDebtorDetail;
