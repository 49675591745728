import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getAllRisalah = (params) => {
  return axiosInstance.get(`${general_config_v2.BASE_URL}/v1/cms/risalah`, {
    params,
  });
};

export const getDetailRisalah = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/risalah/${queryKey[1]}`,
    { params: queryKey[2] }
  );
};

export const getDetailRisalahMember = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/risalah/member/${queryKey[1]}`,
    { params: queryKey[2] }
  );
};

export const submitRisalahData = (id, { data, params }) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/risalah/${id}`,
    data,
    {
      params,
    }
  );
};

export const updateDeskRecommendation = (id, data) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/risalah/member/${id}`,
    data
  );
};

export const getDetailRisalahMemberSampling = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/risalah/member/sampling/${queryKey[1]}`
  );
};

export const getDetailRisalahMemberSamplingPS = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/risalah/member/sampling-forestry/${queryKey[1]}`
  );
};

export const updateDeskRecommendationPS = ({ id, data }) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/risalah/member/forestry/${id}`,
    data
  );
};
