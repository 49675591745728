import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { AnimatePresence, motion } from "framer-motion";
import { useFieldArray, useFormContext } from "react-hook-form";
import { RiAddLine, RiDeleteBin2Line } from "react-icons/ri";

import { Button } from "components";
import AccordionButton from "components/atoms/AccordionButton";
import AtomSelect from "components/atoms/Select";

import { getMasterProvisions } from "services/fdb/committee";

const AccordionForms = ({ type, name }) => {
  let label = "";
  switch (type) {
    case "FDB_DISTRIBUTION":
      label = "Penyaluran FDB";
      break;
    case "FDB_RETURN":
      label = "Pengembalian FDB";
      break;
    case "POSITIVE_COVENANTS":
      label = "Positive Covenants";
      break;
    case "NEGATIVE_COVENANTS":
      label = "Negative Covenants";
      break;
    default:
      break;
  }
  const [accordionDistribution, setAccordionDistribution] = useState(false);
  const [ accordionOptions, setAccordionOptions ] = useState([]);
  const methods = useFormContext();

  const { data: provisionsData } = useQuery({
    queryKey: [`MASTER_PROVISION_${type}`],
    queryFn: async () => {
      const { data } = await getMasterProvisions({ type });
      if (data?.data) {
        return data?.data;
      }
      return [];
    },
  });

  const { control } = methods;

  const {
    fields: distributionsFields,
    append: appendDistributions,
    remove: removeDistributions,
  } = useFieldArray({
    control,
    name,
  });

  useEffect(() => {
    if (provisionsData?.length) {
      const updatedOptions = provisionsData.map((item) => ({
        ...item,
        value: item.id,
        label: item.provision,
        select: false,
        selectIndex: -1,
      }));
      setAccordionOptions(updatedOptions);
    }
  }, [provisionsData]);

  const availableOptions = useMemo(() => 
    accordionOptions.filter(({ select }) => !select), 
    [accordionOptions]
  );

  return (
    <>
      <AccordionButton
        key={type}
        label={label}
        isOpen={accordionDistribution}
        onClick={() => setAccordionDistribution(!accordionDistribution)}
      />
      <AnimatePresence>
        {accordionDistribution && (
          <motion.div
            initial={{ opacity: 0, y: -15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -15 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="w-full bg-slate-100 p-5 rounded-md flex flex-col"
          >
            {distributionsFields.map((item, idx) => {
              return (
                <SubAccordionForm
                  key={item.id}
                  item={item}
                  name={`${name}.${idx}`}
                  originalName={name}
                  index={idx}
                  options={availableOptions}
                  label={label}
                  type={type}
                  removeDistributions={removeDistributions}
                  customChange={(value) => {
                    // Check if the value has changed before updating state
                    setAccordionOptions((prevState) => {
                      const updatedOptions = prevState.map((x) => {
                        if (x.selectIndex === idx) { // Check if the value is already selected
                          return { ...x, select: false, selectIndex: -1 };
                        }

                        if(x.value === value.value) { 
                          return { ...x, select: true, selectIndex: idx };
                        }

                        return x;
                      });

                      // Only update state if there is a change
                      if (JSON.stringify(updatedOptions) !== JSON.stringify(prevState)) {
                        return updatedOptions;
                      }
                      return prevState; // Return previous state if no change
                    });
                  }}
                  customDelete = {(value) => {
                    if (value) {
                      setAccordionOptions((prevState) => {
                        const updatedOptions = prevState.map((x) => {
                          if(x?.value === value?.id) {
                            return { ...x, select: false, selectIndex: -1 };
                          }
  
                          return x;
                        })

                        // Only update state if there is a change
                        if (JSON.stringify(updatedOptions) !== JSON.stringify(prevState)) {
                          return updatedOptions;
                        }
                        return prevState; // Return previous state if no change
                      })
                    }
                  }}
                />
              );
            })}
            <Button
              className={"mt-2 bg-white"}
              theme="secondary"
              variant="icon"
              label={
                <div className="flex items-center gap-2 whitespace-nowrap justify-center">
                  <RiAddLine />
                  {`Tambah ${label}`}
                </div>
              }
              onClick={() => {
                appendDistributions({
                  provision_id: null,
                  // sub_provisions: [{ sub_provision: null }],
                });
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const SubAccordionForm = ({
  name,
  item,
  index,
  options,
  originalName,
  label,
  type,
  removeDistributions,
  customChange,
  customDelete
}) => {
  const { control, watch, formState, setValue } = useFormContext();
  const {
    fields: subdistributionsFields,
    append: appendSubDistributions,
    remove: removeSubDistributions,
  } = useFieldArray({
    control,
    name: `${name}.sub_provisions`,
  });

  const provisions = watch(originalName);
  const formErrors = formState?.errors;
  return (
    <motion.div layout key={item.id} className="flex flex-col gap-4 flex-1">
      <motion.div layout="position" className="flex w-full items-end gap-4">
        <AtomSelect
          label={label}
          wrapperClass={"flex flex-col justify-end overflow-hidden"}
          controllerName={`${name}.provision_id`}
          options={options}
          className="basic-single w-full"
          required={true}
          placeholder={label}
          customOnChange={(value) => {
            if (type === "FDB_DISTRIBUTION" || type === "FDB_RETURN") {
              setValue(`${name}.sub_provisions`, []);
              if (value?.sub_provision?.length) {
                value?.sub_provision?.map((x) => {
                  appendSubDistributions({
                    sub_provision: null,
                  });
                });
              }
            }
            if (customChange){
              customChange(value)
            }
          }}
          customOnDelete={(value) => {
            if (customDelete) customDelete(value)
          }}
          menuPortalTarget={document.body}
        />
        {(type === "POSITIVE_COVENANTS" || type === "NEGATIVE_COVENANTS") &&
        index > 0 ? (
          <Button
            className={
              !isEmpty(formErrors) &&
              formErrors?.[`${originalName}`]?.[index]?.provision_id &&
              !isEmpty(formErrors?.[`${originalName}`]?.[index]?.provision_id)
                ? "mb-[26px]"
                : ""
            }
            variant="icon"
            label={<RiDeleteBin2Line />}
            theme="error"
            onClick={() => {
              removeDistributions(index);
            }}
          />
        ) : null}
        {type === "FDB_DISTRIBUTION" || type === "FDB_RETURN" ? (
          <React.Fragment>
            <div className="flex gap-2">
              <Button
                className={
                  !isEmpty(formErrors) &&
                  formErrors?.[`${originalName}`]?.[index]?.provision_id &&
                  !isEmpty(formErrors?.[`${originalName}`]?.[index]?.provision_id)
                    ? "mb-[26px]"
                    : ""
                }
                theme="primary"
                variant="icon"
                onClick={() => {
                  if (
                    subdistributionsFields.length <
                    provisions[index]?.provision_id?.sub_provision?.length
                  ) {
                    appendSubDistributions({
                      sub_provision: null,
                    });
                  }
                }}
                label={
                  <div className="flex items-center gap-2 whitespace-nowrap">
                    <RiAddLine />
                    Sub Penyaluran
                  </div>
                }
              />

              {
                index > 0 && (
                  <Button
                    // className={"mb-[26px]"}
                    theme="error"
                    variant="icon"
                    label={<RiDeleteBin2Line />}
                    onClick={() => {
                      removeDistributions(index);
                    }}
                  />
                )
              }
            </div>
          </React.Fragment>
        ) : null}
      </motion.div>
      {subdistributionsFields?.length > 0 && (
        <div className="w-full bg-white flex flex-col gap-4 p-5 border rounded-md">
          {subdistributionsFields.map((sub_item, idx) => {
            return (
              <>
                <div className="flex w-full items-end gap-4">
                  <AtomSelect
                    key={sub_item.id}
                    label={`Sub ${label}`}
                    wrapperClass={"flex flex-col justify-end overflow-hidden"}
                    controllerName={`${name}.sub_provisions.${idx}.sub_provision`}
                    options={provisions?.[
                      index
                    ]?.provision_id?.sub_provision?.map((x) => ({
                      ...x,
                      value: x.id,
                      label: x.sub_provision,
                    }))}
                    className="basic-single w-full"
                    required={true}
                    placeholder={`Sub ${label}`}
                    menuPortalTarget={document.body}
                    customOnChange={(value) => {
                      if (value?.sub_sub_provision?.length > 0) {
                        setValue(
                          `${name}.sub_provisions.${idx}.sub_sub_provisions`,
                          value.sub_sub_provision.map((x) => ({
                            sub_sub_provision_id: null,
                            options: value.sub_sub_provision.map((x) => ({ ...x, value: x.id, label: x.sub_sub_provision }))
                          }))
                        );
                      }
                    }}
                  />
                  <Button
                    className={`h-[43px] ${
                      !isEmpty(formErrors) &&
                      !isEmpty(
                        formErrors?.[`${originalName}`]?.[index]
                          ?.sub_provisions?.[idx]?.sub_provision
                      )
                        ? "mb-[26px]"
                        : ""
                    }`}
                    variant="icon"
                    label={<RiDeleteBin2Line />}
                    theme="error"
                    onClick={() => {
                      removeSubDistributions(idx);
                    }}
                  />
                </div>
                <SubSubAccordionForm
                  name={`${name}.sub_provisions.${idx}`}
                  parentIndex={index}
                  index={idx}
                  originalName={originalName}
                  label={label}
                />
              </>
            );
          })}
        </div>
      )}
      <hr />
    </motion.div>
  );
};

const SubSubAccordionForm = ({
  name,
  parentIndex,
  index,
  originalName,
  label,
}) => {
  const { control, formState, watch } = useFormContext();
  const {
    fields: subSubFields,
    remove: removeSubSubFields,
  } = useFieldArray({
    control,
    name: `${name}.sub_sub_provisions`,
  });
  
  const provisions = watch(originalName);
  const formErrors = formState?.errors;
  
  return subSubFields?.length > 0 ? (
    <div className="pl-3 space-y-4">
      {subSubFields.map((sub_item, idx) => {
        return (
          <div className="flex w-full items-end gap-4" key={sub_item.id}>
            <AtomSelect
              key={sub_item.id}
              label={`Sub Sub ${label}`}
              wrapperClass={"flex flex-col justify-end overflow-hidden"}
              controllerName={`${name}.sub_sub_provisions.${idx}.sub_sub_provision`}
              options={provisions?.[parentIndex]?.sub_provisions?.[index]?.sub_sub_provisions?.[idx]?.options ?? []}
              className="basic-single w-full"
              required={true}
              placeholder={`Sub Sub ${label}`}
              menuPortalTarget={document.body}
            />
            <Button
              className={`h-[43px] ${
                !isEmpty(formErrors) &&
                !isEmpty(
                  formErrors?.[`${originalName}`]?.[parentIndex]
                    ?.sub_provisions?.[index]?.sub_sub_provisions?.[idx]?.sub_sub_provision
                )
                  ? "mb-[26px]"
                  : ""
              }`}
              variant="icon"
              label={<RiDeleteBin2Line />}
              theme="error"
              onClick={() => {
                removeSubSubFields(idx);
              }}
            />
          </div>
        );
      })}
    </div>
  ) : null;
};

export default AccordionForms;
