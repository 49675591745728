import React, { Fragment, useEffect, useState } from "react";

import SelectFDBProvince from "components/organisms/inputs/SelectFDBProvince";
import SelectFDBCity from "components/organisms/inputs/SelectFDBCity";
import SelectFDBDistrict from "components/organisms/inputs/SelectFDBDistrict";
import SelectFDBVillage from "components/organisms/inputs/SelectFDBVillage";
import { useFormContext, useWatch } from "react-hook-form";
import { InputForm, InputFormUpload } from "components";
import GeotagLocationButton from "components/atoms/Button/GeotagLocationButton";
import { RiMapPin2Fill } from "react-icons/ri";
import { useMutation } from "@tanstack/react-query";
import { fileUpload } from "services/fileService";
import withFileService from "hocs/with-file-service";

const AddressFormSection = withFileService((props) => {
  const methods = useFormContext();
  const { getFileFromService, data } = props;

  const provinceId = useWatch({
    control: methods.control,
    name: "business_province_id",
  });
  const cityId = useWatch({
    control: methods.control,
    name: "business_city_id",
  });
  const districtId = useWatch({
    control: methods.control,
    name: "business_district_id",
  });

  const fileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      methods.register("business_photo_file");
      methods.setValue("business_photo_file", response.data.data.id);
    } catch (error) {
      throw error;
    }
  });

  useEffect(() => {
    if (
      data?.field_analyst_tabulation?.business_photo_file &&
      data?.field_analyst_tabulation?.business_photo_file !== "string"
    ) {
      getFileFromService(data?.field_analyst_tabulation?.business_photo_file, {
        onSuccess: (data) => {
          methods.resetField("business_photo_file_temp", {
            defaultValue: {
              type: data?.mimeType,
              ...data,
            },
          });
        },
        onError: (error) => {
          console.log(error);
        },
      });
    }
  }, [data?.field_analyst_tabulation?.business_photo_file]);

  return (
    <Fragment>
      <p className="font-bold text-[#01A24A]">
        Alamat Usaha Lainnya Selain yang Dibiayai
      </p>
      <div className="grid grid-cols-2 gap-4">
        <SelectFDBProvince
          controllerName={"business_province_id"}
          className="basic-single mt-2 flex-1"
          label="Provinsi"
          required={true}
          returnObject={false}
        />
        <SelectFDBCity
          controllerName={"business_city_id"}
          className="basic-single mt-2 flex-1"
          label="Kota/Kabupaten"
          required={true}
          provinceId={provinceId}
          returnObject={false}
        />
        <SelectFDBDistrict
          controllerName={"business_district_id"}
          className="basic-single mt-2 flex-1"
          label="Kecamatan"
          required={true}
          cityId={cityId}
          returnObject={false}
        />
        <SelectFDBVillage
          controllerName={"business_village_id"}
          className="basic-single mt-2 flex-1"
          label="Kelurahan/Desa"
          required={true}
          districtId={districtId}
          returnObject={false}
        />
      </div>
      <InputForm
        controllerName="business_address"
        label="Alamat Lengkap Usaha"
        placeholder="Alamat Lengkap Usaha"
        required={true}
        wrapperClass="col-span-2"
        className="rounded-md w-full px-4 py-3 border"
        textArea
      />
      <div className="space-y-5">
        <InputFormUpload
          controllerName={`business_photo_file_temp`}
          label={"Unggah Foto Usaha Debitur"}
          uploadFile={fileMutation}
        />
      </div>
      <div className="flex justify-start w-1/3">
        <GeotagLocationButton
          label="Geolocation"
          icon={<RiMapPin2Fill className={"w-5 h-5 shrink-0"} />}
          className={"!bg-primary-700 !text-white"}
          onSuccess={(val) => {
            methods.setValue("business_longitude", val.longitude);
            methods.setValue("business_latitude", val.latitude);
          }}
        />
      </div>
      <div className="flex space-x-5">
        <div className="w-1/2 space-y-3">
          <InputForm
            label="Longitude"
            controllerName="business_longitude"
            className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
          />
        </div>
        <div className="w-1/2 space-y-3">
          <InputForm
            label="Latitude"
            controllerName="business_latitude"
            className="py-2 px-4 mt-2 border w-full rounded-md flex-1"
          />
        </div>
      </div>
    </Fragment>
  );
});

export default AddressFormSection;
