import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getAgencyOfferApprovalList = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-offer-approvals`,
    { params: queryKey[1] }
  );
};

export const getAgencyOfferApprovalDetail = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-offer-approvals/${queryKey[1]}`,
    { params: queryKey[2] }
  );
};

export const submitAgencyOfferApproval = ({ data, params }) => {
  return axiosInstance.patch(
    `${general_config_v2.BASE_URL}/v1/cms/agency-offer-approvals`,
    data,
    { params }
  );
};
