const BADGE_DEFAULT_VARIANT = {
  default: 'bg-gray-100 text-gray-700',
  warning: 'bg-warning-100 text-warning-700',
  error: 'bg-error-100 text-error-700',
  success: 'bg-success-100 text-success-700',
  bluelight: 'bg-bluelight-100 text-bluelight-700',
};

const BADGE_OUTLINED_VARIANT = {
  default: 'border-[1.5px] border-gray-500 text-gray-700',
  warning: 'border-[1.5px] border-warning-500 text-warning-700',
  error: 'border-[1.5px] border-error-500 text-error-700',
  success: 'border-[1.5px] border-success-500 text-success-700',
  bluelight: 'border-[1.5px] border-bluelight-500 text-bluelight-700',
};

const BADGE_SIZES = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg',
};

const Badge = ({
  color = 'default',
  size = 'xs',
  className,
  children,
  variant = 'default',
}) => (
  <span
    className={[
      'px-2 py-1 rounded-full text-xs font-medium inline-flex gap-2 items-center justify-center whitespace-nowrap',
      variant === 'default' && BADGE_DEFAULT_VARIANT[color],
      variant === 'outlined' && BADGE_OUTLINED_VARIANT[color],
      BADGE_SIZES[size],
      className,
    ].join(' ')}
  >
    {children}
  </span>
);

export default Badge;
