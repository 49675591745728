import CardForm from "components/molecules/CardForm";
import UploadedFileAsync from "components/molecules/UploadFileAsync";
// import dayjs from "dayjs";

const InstitutionProfile = ({ data }) => {
  // const legality = data?.general?.legality;

  return (
    <CardForm label="Informasi Umum">
      <div className="space-y-5">
        <div className="space-y-4">
          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">Profil Lembaga</p>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <div className="font-semibold">Nama Lembaga</div>
              <div>{data?.general?.profile?.name}</div>
            </div>
            <div>
              <div className="font-semibold">Jenis Lembaga</div>
              <div>{data?.general?.profile?.agency_type}</div>
            </div>
            <div>
              <div className="font-semibold">Jenis Layanan</div>
              <div>{data?.general?.profile?.service_type}</div>
            </div>
            <div>
              <div className="font-semibold">Negara</div>
              <div>{data?.general?.profile?.country}</div>
            </div>
            <div>
              <div className="font-semibold">Provinsi</div>
              <div>{data?.general?.profile?.province}</div>
            </div>
            <div>
              <div className="font-semibold">Kabupaten / Kota</div>
              <div>{data?.general?.profile?.city}</div>
            </div>
            <div>
              <div className="font-semibold">Kecamatan</div>
              <div>{data?.general?.profile?.district}</div>
            </div>
            <div>
              <div className="font-semibold">Kelurahan</div>
              <div>{data?.general?.profile?.village}</div>
            </div>
            <div>
              <div className="font-semibold">Kode Pos</div>
              <div>{data?.general?.profile?.zip_code}</div>
            </div>
            <div className="col-span-2">
              <div className="font-semibold">Alamat Lengkap</div>
              <div>{data?.general?.profile?.address}</div>
            </div>
            <div className="grid col-span-2 grid-cols-2 gap-4">
              <div>
                <div className="font-semibold">Email</div>
                <div>{data?.general?.profile?.email}</div>
              </div>
              <div>
                <div className="font-semibold">No Telepon</div>
                <div>{data?.general?.profile?.phone ?? "-"}</div>
              </div>
            </div>
            {/* <div className="col-span-2">
              <div className="font-semibold">Latar Belakang</div>
              <div>-</div>
            </div>
            <div className="col-span-2">
              <div className="font-semibold">Visi Misi</div>
              <div>-</div>
            </div> */}
          </div>
        </div>
        {/* <div className="space-y-4">
          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">Legalitas Lembaga</p>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="font-semibold">Akta Pendirian & Perubahan</div>
              <div>{legality?.institution_legality_certificate ?? "-"}</div>
            </div>
            <div>
              <div className="font-semibold">
                Pengesahan Akta Pendirian & Perubahannya
              </div>
              <div>
                {legality?.institution_legality_date
                  ? dayjs(legality?.institution_legality_date).format(
                      "DD MMMM YYYY"
                    )
                  : "-"}
              </div>
            </div>
          </div> */}
          {/* <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="font-semibold">Akta Perubahan</div>
              <div>
                {legality?.institution_legality_certificate}
              </div>
            </div>
            <div>
              <div className="font-semibold">Pengesahan Akta Perubahan</div>
              <div>
                {legality?.institution_legality_certificate}
              </div>
            </div>
          </div> */}
          {/* <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="font-semibold">Nomor Induk Koperasi</div>
              <div>{legality?.institution_nik ?? "-"}</div>
            </div>
            <div>
              <div className="font-semibold">NPWP</div>
              <div>{legality?.institution_npwp ?? "-"}</div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="font-semibold">Nomor Induk Berusaha / TDP</div>
              <div>{legality?.institution_nib ?? "-"}</div>
            </div>
          </div>
        </div> */}
        <div className="space-y-4">
          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">
              Ikhtisar Anggota, SDM & Keuangan Lembaga
            </p>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <div className="font-semibold">Jumlah Anggota</div>
              <div>{data?.general?.resource?.total_member}</div>
            </div>
            <div>
              <div className="font-semibold">Jumlah Kantor / Cabang</div>
              <div>{data?.general?.resource?.total_branch}</div>
            </div>
            <div>
              <div className="font-semibold">Jumlah Pegawai</div>
              <div>{data?.general?.resource?.total_employee}</div>
            </div>
            {/* <div>
              <div className="font-semibold">Total Asset</div>
              <div>-</div>
            </div>
            <div>
              <div className="font-semibold">Total Modal Koperasi</div>
              <div>-</div>
            </div>
            <div>
              <div className="font-semibold">SHU / Laba</div>
              <div>-</div>
            </div>
            <div>
              <div className="font-semibold">Jumlah Pinjaman ke Anggota</div>
              <div>-</div>
            </div> */}
          </div>
        </div>
        <div className="space-y-4">
          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">Narahubung Lembaga</p>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <div className="font-semibold">Nama Narahubung</div>
              <div>{data?.general?.contact_person?.contact_person_name}</div>
            </div>
            <div>
              <div className="font-semibold">Jabatan</div>
              <div>
                {data?.general?.contact_person?.contact_person_position}
              </div>
            </div>
            <div>
              <div className="font-semibold">Email</div>
              <div>{data?.general?.contact_person?.contact_person_email}</div>
            </div>
            <div>
              <div className="font-semibold">No Telepon</div>
              <div>{data?.general?.contact_person?.contact_person_phone}</div>
            </div>
            <div>
              <div className="font-semibold">NIK</div>
              <div>
                {data?.general?.contact_person?.contact_person_identity_number}
              </div>
            </div>
            <div>
              <div className="font-semibold">No NPWP</div>
              <div>{data?.general?.contact_person?.contact_person_npwp}</div>
            </div>
            <div className="grid col-span-3 grid-cols-2 gap-4">
              <UploadedFileAsync
                id={
                  data?.general?.contact_person
                    ?.contact_person_identity_number_file
                }
                name="KTP Narahubung"
              />
              <UploadedFileAsync
                id={data?.general?.contact_person?.contact_person_npwp_file}
                name="NPWP Narahubung"
              />
            </div>
          </div>
        </div>
        <div className="space-y-4">
          <div className="bg-[#E1F1D6] my-5 p-3 w-full">
            <p className="font-bold text-[#01A24A]">
              Latar Belakang Menjadi Penyalur
            </p>
          </div>
          {/* <div>
            <div className="font-semibold">
              Hal Mendasari Untuk Menjadi Lembaga Penyalur
            </div>
            <div>-</div>
          </div> */}
          <UploadedFileAsync
            id={data?.general?.background_file}
            name="Surat Menjadi Lembaga Penyalur"
          />
        </div>
      </div>
    </CardForm>
  );
};

export default InstitutionProfile;
