import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import classNames from "clsx";

export const Pagination = ({
  page,
  limit,
  total,
  totalPage,
  onLimitChange,
  onPageChange,
}) => {
  return (
    <div className="flex">
      <footer className="flex items-center justify-between px-4 py-3 rounded-b-lg w-full">
        <div>
          <span className="text-sm text-gray-600">Tampilkan Data</span>
          <select
            className="mx-3 text-sm font-semibold border px-2 py-2 rounded-lg"
            value={limit}
            onChange={(e) => {
              onLimitChange(e.target.value);
            }}
          >
            {[5, 10, 25, 50].map((item, idx) => (
              <option value={item} key={idx}>
                {item}
              </option>
            ))}
          </select>
          <span className="text-sm text-gray-600">
            {(page - 1) * limit + 1}-
            {page * limit < total ? page * limit : total} data dari {total}{" "}
            data.
          </span>
        </div>
        <div className="flex">
          <button
            className="flex items-center gap-2 text-sm font-semibold text-[#344054] px-4 py-[10px] border border-gray-200 rounded-l-xl hover:bg-gray-100 disabled:bg-gray-50 disabled:cursor-not-allowed"
            disabled={page === 1}
            onClick={() => onPageChange(page - 1)}
          >
            <RiArrowLeftLine />
            <p>Previous</p>
          </button>
          {Array(totalPage)
            .fill()
            .map((_, i) => (
              <button
                key={i}
                className={classNames(
                  "flex items-center gap-2 text-sm font-semibold text-[#344054] px-4 py-[10px] border border-gray-200 cursor-pointer hover:bg-gray-100",
                  page === i + 1 && "bg-gray-100"
                )}
                onClick={() => onPageChange(i + 1)}
              >
                {i + 1}
              </button>
            ))}
          <button
            className="flex items-center gap-2 text-sm font-semibold text-[#344054] px-4 py-[10px] border border-gray-200 rounded-r-xl hover:bg-gray-100 disabled:bg-gray-50 disabled:cursor-not-allowed"
            disabled={page === totalPage}
            onClick={() => onPageChange(page + 1)}
          >
            <p>Next</p>
            <RiArrowRightLine />
          </button>
        </div>
      </footer>
    </div>
  );
};
