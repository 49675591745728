import React, { useMemo } from "react";
import Table, { TableWrapper } from "components/atoms/Table";
import CardForm from "components/molecules/CardForm";
import { formatCurrency } from "helpers";
import { Pagination } from "components/v2/shared/pagination";

const Debtors = ({
  data,
  changePagePotention,
  changeLimitPotention,
  filterTable,
}) => {
  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "debtor_name",
        title: "Nama Debitur",
      },
      {
        key: "debtor_identity_number",
        title: "NIK",
      },
      {
        key: "debtor_age",
        title: "Umur",
      },
      {
        key: "debtor_address",
        title: "Alamat",
      },
      {
        key: "purpose_using_fdb",
        title: "Tujuan Penggunaan FDB",
      },
      {
        key: "application_value",
        title: "Nilai Pembiayaan",
        render: ({ item }) =>
          item?.application_value
            ? formatCurrency(item?.application_value)
            : "-",
      },
      {
        key: "loan_term",
        title: "Jangka Waktu Pinjaman",
      },
      {
        key: "tree_type",
        title: "Tipe Pohon",
      },
      {
        key: "tree_total",
        title: "Total Pohon",
      },
    ];
  }, []);

  return (
    <CardForm label="Potensi Penerima FDB">
      <TableWrapper>
        <Table
          headers={headers}
          items={data?.potention?.data || []}
          isLoading={false}
        />
        <Pagination
          page={data?.potention?.meta?.current_page || 1}
          limit={filterTable?.limit_potention || 10}
          total={data?.potention?.meta?.total || 0}
          totalPage={data?.potention?.meta?.last_page || 1}
          onLimitChange={changeLimitPotention}
          onPageChange={changePagePotention}
        />
      </TableWrapper>
    </CardForm>
  );
};

export default Debtors;
