import React, { useMemo } from "react";
import Table, { TableWrapper } from "components/atoms/Table";
import CardForm from "components/molecules/CardForm";
import { formatCurrency } from "helpers";
import dayjs from "dayjs";

const FinancedBussiness = ({ data }) => {
  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "sector",
        title: "Sektor",
      },
      {
        key: "total_member",
        title: "Jumlah Anggota",
      },
      {
        key: "nominal",
        title: "Nominal O/S",
        render: ({ item }) =>
          item?.nominal ? `${formatCurrency(item.nominal)}` : "-",
      },
      {
        key: "year",
        title: "Per 31 Desember",
        render: ({ item }) => dayjs(item?.year).format("YYYY"),
      },
    ];
  }, []);

  return (
    <CardForm label="Sektor Usaha yang Dibiayai">
      <TableWrapper>
        <Table headers={headers} items={data || []} isLoading={false} />
      </TableWrapper>
    </CardForm>
  );
};

export default FinancedBussiness;
