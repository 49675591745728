import AccordionButton from "components/atoms/AccordionButton";
import CardForm from "components/molecules/CardForm";
import { AnimatePresence, LayoutGroup } from "framer-motion";
import React, { useState } from "react";
import { motion } from "framer-motion";
import dayjs from "dayjs";

const GroupScore = ({ data }) => {
  return (
    <LayoutGroup>
      <CardForm label="Penilaian Kelompok">
        <LegalityGroup data={data} />
        <VerifiedGroup data={data} />
        <EffectiveCompanion data={data} />
        <FieldSpreadness data={data} />
        <Conclusion data={data} />
      </CardForm>
    </LayoutGroup>
  );
};

// TODO: Belum jelas
const LegalityGroup = ({ data }) => {
  const [accordion, setAccordion] = useState(false);
  return (
    <>
      <AccordionButton
        key={"legality"}
        label={
          "Legalitas Kelompok/Izin Akses/Perjanjian Masih Berlaku Hingga Berakhir Masa Pinjaman"
        }
        isOpen={accordion}
        onClick={() => setAccordion(!accordion)}
      />
      <AnimatePresence mode="wait">
        {accordion && (
          <motion.div
            layout
            initial={{ opacity: 0, y: -15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="w-full p-5 rounded-md flex flex-col"
          >
            <div className="grid grid-cols-3">
              <div>
                <p className="font-semibold">Batas waktu pengukuhan kelompok</p>
                <p>{data?.confirmation_deadline}</p>
              </div>
              <div>
                <p className="font-semibold">
                  Batas waktu kepengurusan anggota
                </p>
                <p>{data?.management_deadline}</p>
              </div>
              <div>
                <p className="font-semibold">
                  Batas waktu legalitas akses kelompok/anggota terhadap lahan
                </p>
                {/* <p>3 tahun sejak tanggal 13/09/2023</p> */}
                <p>-</p>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const VerifiedGroup = ({ data }) => {
  const [accordion, setAccordion] = useState(false);
  return (
    <>
      <AccordionButton
        key={"legality"}
        label={"Kelompok Terverifikasi Keberadaannya"}
        isOpen={accordion}
        onClick={() => setAccordion(!accordion)}
      />
      <AnimatePresence mode="wait">
        {accordion && (
          <motion.div
            layout
            initial={{ opacity: 0, y: -15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="w-full p-5 rounded-md flex flex-col gap-4"
          >
            <div className="grid grid-cols-2">
              <div>
                <p className="font-semibold">Tanggal Kelompok didirikan</p>
                <p>{dayjs(data?.founding_date).format("DD/MM/YYYY")}</p>
              </div>
              <div>
                <p className="font-semibold">
                  Stakeholder terkait yang menyetujui
                </p>
                <p>{data?.stakeholder}</p>
              </div>
            </div>
            <div>
              <p className="font-semibold">
                Kegiatan kelompok yang telah dilaksanakan
              </p>
              <p>{data?.monitoring_activities_carrie_out}</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const EffectiveCompanion = ({ data }) => {
  const [accordion, setAccordion] = useState(false);
  return (
    <>
      <AccordionButton
        key={"legality"}
        label={"Pendampingan Berjalan Efektif "}
        isOpen={accordion}
        onClick={() => setAccordion(!accordion)}
      />
      <AnimatePresence mode="wait">
        {accordion && (
          <motion.div
            layout
            initial={{ opacity: 0, y: -15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="w-full p-5 rounded-md flex flex-col gap-4"
          >
            <div className="grid grid-cols-2">
              <div>
                <p className="font-semibold">
                  Pendamping dari instansi terpecaya
                </p>
                {/* <p>Penyuluh Kehutanan Wilayah II Kabupaten Sumedang</p> */}
                <p>{data?.monitoring_institution}</p>
              </div>
              <div>
                <p className="font-semibold">
                  Terdapat dukungan dari pendamping
                </p>
                <p>{data?.evidance_mentor}</p>
              </div>
            </div>
            <div>
              <p className="font-semibold">
                Kegiatan kelompok yang telah dilaksanakan
              </p>
              <p>{data?.monitoring_activities_carrie_out}</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const FieldSpreadness = ({ data }) => {
  const [accordion, setAccordion] = useState(false);
  return (
    <>
      <AccordionButton
        key={"legality"}
        label={
          "Persebaran Lahan Anggota Kelompok Relatif Kompak Sehingga Relatif Mudah Dikelola Bersama"
        }
        isOpen={accordion}
        onClick={() => setAccordion(!accordion)}
      />
      <AnimatePresence mode="wait">
        {accordion && (
          <motion.div
            layout
            initial={{ opacity: 0, y: -15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="w-full p-5 rounded-md flex flex-col gap-4"
          >
            <div>
              <p className="font-semibold">
                Deskripsi persebaran lahan anggota kelompok
              </p>
              <p
                dangerouslySetInnerHTML={{
                  __html: data?.group_member_distribution_desk,
                }}
              ></p>
            </div>
            <div>
              <p className="font-semibold">Catatan Lainnya</p>
              <p dangerouslySetInnerHTML={{ __html: data?.other_notes }}></p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const Conclusion = ({ data }) => {
  const [accordion, setAccordion] = useState(false);
  return (
    <>
      <AccordionButton
        key={"legality"}
        label={"Kesimpulan"}
        isOpen={accordion}
        onClick={() => setAccordion(!accordion)}
      />
      <AnimatePresence mode="wait">
        {accordion && (
          <motion.div
            layout
            initial={{ opacity: 0, y: -15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="w-full p-5 rounded-md flex flex-col gap-4"
          >
            <div>
              <p dangerouslySetInnerHTML={{ __html: data?.conclusion }}></p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default GroupScore;
