import React, { useMemo, useCallback, useState, useEffect } from "react";
import Button from "components/atoms/Button/Button";
import CardForm from "components/molecules/CardForm";
import { useNavigate, useParams } from "react-router";
import { TabsContent } from "components/atoms/Tabs";
import { BottomFormAction } from "components";
import Table, { TableWrapper } from "components/atoms/Table";
import TablePagination from "components/atoms/TablePagination";
import { RiEyeLine } from "react-icons/ri";
import Badge from "components/atoms/Badge";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import { putVerificator } from "services/fdb/verificator";
import { useSearchParams } from "react-router-dom";

const RequestTabContent = ({ data, applicant, verificationData }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const [approvedMember, setApprovedMember] = useState([]);
  const [rejectedMember, setRejectedMember] = useState([]);

  const onBack = useCallback(() => {
    setSearchParams({ tab: "portfolio" });
  }, []);

  useEffect(() => {
    if (applicant?.data?.length > 0) {
      const approved = applicant?.data
        .filter((x) => x.is_approved)
        .map((x) => x.id);
      const rejected = applicant?.data
        ?.filter((x) => !x.is_approved)
        .map((x) => x.id);
      setApprovedMember(approved);
      setRejectedMember(rejected);
    }
  }, [applicant]);

  const onFormSubmit = useCallback(() => {
    const payload = {
      verified_status: {
        ...verificationData.verification,
        data_updates: [
          ...verificationData.data_updates,
          ...verificationData.activities,
          ...verificationData.partners,
          ...verificationData.finance_businesses,
        ],
      },
      approve: approvedMember,
    };
    postVerification.mutate(payload);
  }, [approvedMember, rejectedMember, verificationData]);

  const postVerification = useMutation({
    mutationFn: (payload) => {
      const res = putVerificator(
        payload,
        data?.id,
        data?.group_profile_id,
        true
      );
      return res;
    },
    onSuccess: (res) => {
      enqueueSnackbar({
        variant: "success",
        message: (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontWeight: "bold" }}>
              Data berhasil diverifiksai!
            </span>
            <span>Data yang Anda masukkan berhasil dikirim</span>
          </div>
        ),
      });
      navigate("/groups");
    },
    onError: (error) => {
      enqueueSnackbar({
        message: getErrorMessage(error),
        variant: "error",
      });
    },
  });

  const onDraft = useCallback(() => {
    //
  }, []);
  return (
    <TabsContent value="requests" className="mb-40">
      <CardForm label="Daftar Pemohon Kelompok">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div>
            <div className="font-semibold">Tanggal Pengajuan Anggota</div>
            <div>{dayjs(data.registration_date).format("DD MMMM YYYY")}</div>
          </div>
          <div>
            <div className="font-semibold">Total Anggota Diajukan</div>
            <div>{data.total_member} Orang</div>
          </div>
        </div>

        <DebtorTable data={applicant} setApprovedMember={setApprovedMember} />
      </CardForm>

      <BottomFormAction
        backButtonAction={onBack}
        submitActionButton={onFormSubmit}
        disableDrafButton={false}
        drafButtonAction={onDraft}
        lastStep={true}
        hideDraft={true}
        hideSubmit={data.verificator_status !== "not_yet_review"}
        disableButtonSubmit={false}
        className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
      />
    </TabsContent>
  );
};

const stateColors = {
  "Permohonan Lama": "warning",
  "Permohonan Baru": "bluelight",
  Disetujui: "success",
  Ditolak: "error",
};

const DebtorTable = ({ data, setApprovedMember }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [page, setPage] = useState(data?.meta?.current_page ?? 1);
  const [limit, setLimit] = useState(data?.meta?.per_page ?? 10);
  const [totalPage, setTotalPage] = useState(data?.meta?.last_page);
  const [totalItems, setTotalItems] = useState(data?.meta?.total);

  const handleCheck = (item, event) => {
    if (event.target.checked) {
      setApprovedMember((old) => [...old, item.id]);
    } else {
      setApprovedMember((old) => {
        const filtered = old.filter((el) => {
          return el !== item.id;
        });
        return filtered;
      });
    }
  };

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "",
        render: ({ index, item }) => {
          return (
            <input
              type="checkbox"
              onClick={(event) => {
                handleCheck(item, event);
              }}
              defaultChecked={item.is_approved}
              disabled={item.is_approved || item.rejected_reason}
            />
          );
        },
      },
      {
        key: "name",
        title: "Nama Debitur",
      },
      {
        key: "nik",
        title: "NIK",
      },
      {
        key: "birthDate",
        title: "Tgl Lahir",
      },
      {
        key: "email",
        title: "Email",
        sortable: true,
      },
      {
        key: "phone",
        title: "No Telepon",
      },
      {
        key: "request_state",
        title: "Status Pemohon",
        render: ({ item }) => (
          <Badge color={stateColors[item.request_state]}>
            {item.request_state}
          </Badge>
        ),
      },
      {
        key: "verify_state",
        title: "Status Verifikasi",
        render: ({ item }) => (
          <Badge color={stateColors[item.verify_state]}>
            {item.verify_state}
          </Badge>
        ),
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <>
            <Button
              variant="icon"
              label={<RiEyeLine className="text-lg" />}
              onClick={() => {
                navigate(`/groups/${id}/debtor/${item.id}`);
              }}
            />
          </>
        ),
      },
    ];
  }, [navigate, id, handleCheck]);

  const items = useMemo(() => {
    if (!data?.data) return [];

    return data?.data?.map((item, index) => ({
      index,
      id: item.id,
      name: item.name_debtor,
      nik: item.identity_number,
      birthDate: dayjs(item.date_of_birth).format("DD/MM/YYYY"),
      email: item.email,
      is_approved: item.is_approved,
      is_old: item.is_old,
      rejected_reason: item.rejected_reason,
      phone: item.phone,
      request_state: item.is_old ? "Permohonan Lama" : "Permohonan Baru",
      verify_state: item.is_approved
        ? "Disetujui"
        : item?.rejected_reason
        ? "Ditolak"
        : "Menunggu Direview",
    }));
  }, [data]);

  return (
    <TableWrapper>
      <Table headers={headers} items={items} isLoading={false} />
      <TablePagination
        totalPage={totalPage}
        totalItems={totalItems}
        onChange={setPage}
        onItemsPerPageChange={setLimit}
      />
    </TableWrapper>
  );
};

export default RequestTabContent;
