import React, { useState, useEffect } from "react";

import Button from "components/atoms/Button/Button";
import CardForm from "components/molecules/CardForm";
import { TabsContent } from "components/atoms/Tabs";
import {
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiMapPinLine,
} from "react-icons/ri";
import { InputForm, InputFormRadio } from "components";
import { useFormContext } from "react-hook-form";
import UploadedFile from "components/molecules/UploadedFile";
import AccordionButton from "components/atoms/AccordionButton";
import { AnimatePresence, motion } from "framer-motion";
import dayjs from "dayjs";
import withFileService from "hocs/with-file-service";
import { formattedDate } from "helpers";
import { useSearchParams } from "react-router-dom";

const calculateDiffer = (dateTime) => {
  const now = dayjs();
  const birthDate = dayjs(dateTime);

  const differYear = now.diff(birthDate, "years");
  if (differYear < 1) {
    const differMonth = now.diff(birthDate, "months");
    if (differMonth < 1) {
      const differDay = now.diff(birthDate, "days");
      if (differDay < 1) {
        const differHours = now.diff(birthDate, "hours");
        return `${differHours} Jam`;
      } else {
        return `${differDay} Hari`;
      }
    } else {
      return `${differMonth} Bulan`;
    }
  } else {
    return `${differYear} Tahun`;
  }
};

const calculateAge = (dateTime) => {
  const now = dayjs();
  const sinceYear = dayjs(dateTime);

  const years = now.diff(sinceYear, "year");

  return years;
};

export const CandidateDebitorData = ({ initialValues, type, data }) => {
  const [searchParams] = useSearchParams();

  const isView = searchParams.get("status");

  return (
    <TabsContent value="profile" className="mb-40">
      <InformationCard type={type} data={data} isView={isView} />
      <DataPartner data={data} isView={isView} />
      <AddressCard data={data} isView={isView} />
      <CheckHistory isView={isView} />
    </TabsContent>
  );
};

const InformationCard = withFileService(({ type, data, isView, ...rest }) => {
  const [isEdit, setIsEdit] = useState(false);

  const { getFileFromService } = rest;

  const methods = useFormContext();

  const verificationResultInfo = methods.watch("debitur_verification");

  // const areaOptions = [
  //   {
  //     label: "Kawasan Hutan",
  //     value: "Kawasan Hutan",
  //   },
  // ];

  const [nikFile, setNIKFile] = useState();
  const [kkFile, setKKFile] = useState();

  useEffect(() => {
    if (nikFile) return;
    if (data?.group_profile_member?.identity_file) {
      getFileFromService(data.group_profile_member.identity_file, {
        onSuccess: (data) => {
          setNIKFile(data);
        },
        onError: (err) => console.log(err),
      });
    }

    if (data?.group_profile_member?.family_card_file) {
      getFileFromService(data.group_profile_member.family_card_file, {
        onSuccess: (data) => {
          setKKFile(data);
        },
        onError: (err) => console.log(err),
      });
    }
  }, [
    data?.group_profile_member?.family_card_file,
    data?.group_profile_member?.identity_file,
    nikFile,
    getFileFromService,
  ]);

  return (
    <CardForm label="Detail Calon Debitur">
      {type === "persos" ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">Persos</div>
      ) : (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div>
              <p className="font-semibold">Nomor NIK</p>
              <p>{data?.group_profile_member?.identity_number}</p>
            </div>
            <div>
              <p className="font-semibold">Nama Lengkap sesuai KTP</p>
              <p>{data?.group_profile_member?.name}</p>
            </div>
            <div>
              <p className="font-semibold">Tempat Lahir</p>
              <p>{data?.group_profile_member?.place_of_birth}</p>
            </div>
            <div>
              <p className="font-semibold">Tanggal Lahir</p>
              <p>{formattedDate(data?.group_profile_member?.date_of_birth)}</p>
            </div>
            <div>
              <p className="font-semibold">Usia Debitur Saat Permohonan</p>
              <p>
                {calculateDiffer(data?.group_profile_member?.date_of_birth)}
              </p>
            </div>
            <div>
              <p className="font-semibold">Masuk Usia Produktif</p>
              <p>
                {calculateAge(data?.group_profile_member?.date_of_birth) >=
                  20 &&
                calculateAge(data?.group_profile_member?.date_of_birth) <= 65
                  ? "Masuk Usia Proktif"
                  : "Tidak Masuk Usia Produktif"}
              </p>
            </div>
            <div>
              <p className="font-semibold">Nomor KK</p>
              <p>{data?.group_profile_member?.family_card_number}</p>
            </div>
            <div>
              <p className="font-semibold">Pekerjaan Utama</p>
              <p>{data?.group_profile_member?.main_job}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <UploadedFile
              filename={"KTP Calon Debitur"}
              size={`${parseFloat(parseInt(nikFile?.size) / 1024).toFixed(
                1
              )} KB`}
              routes={nikFile?.url}
            />
            <UploadedFile
              filename="KK Calon Debitur"
              size={`${parseFloat(parseInt(kkFile?.size) / 1024).toFixed(
                1
              )} KB`}
              routes={kkFile?.url}
            />
          </div>
        </>
      )}
      <div className="border rounded-md px-4 py-3">
        <InputFormRadio
          label={"Hasil Verifikasi"}
          controllerName={`debitur_verification`}
          className="flex items-center justify-between gap-4"
          disabled={isView}
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Data Terverifikasi
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Data Belum Sesuai/ Ada Kesalahan
                </>
              ),
              value: false,
            },
          ]}
        />
        {verificationResultInfo !== "" && verificationResultInfo === false && (
          <InputForm
            controllerName={"debitur_verification_reason"}
            textArea={true}
            label={"Jelaskan kesalahan/ ketidak sesuaian datanya"}
            className={"w-full rounded-md border p-3"}
            wrapperClass={"mt-5"}
            disabled={isView}
          />
        )}
      </div>
    </CardForm>
  );
});

const AddressCard = ({ data, isView }) => {
  const [accordionAddress, setAccordionAddress] = useState(false);
  const [accordionAddressCard, setAccordionAddressCard] = useState(false);

  const methods = useFormContext();

  const verificationResultAddress = methods.watch(
    "address_domicile_verification"
  );

  return (
    <CardForm label="Alamat">
      <div className=" flex flex-col">
        <AnimatePresence mode="popLayout">
          <AccordionButton
            key={"address-card-btn"}
            label={"Alamat KTP"}
            isOpen={accordionAddressCard}
            onClick={() => setAccordionAddressCard(!accordionAddressCard)}
          />
          {accordionAddressCard && (
            <motion.div
              key={"address-card-content"}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              exit={{ opacity: 0 }}
            >
              <div className="p-5 flex gap-4 flex-col">
                <div className="grid grid-cols-4">
                  <div>
                    <p className="font-semibold"> Provinsi</p>
                    <p>{data?.group_profile_member?.province?.name}</p>
                  </div>
                  <div>
                    <p className="font-semibold"> Kota/Kabupaten</p>
                    <p>{data?.group_profile_member?.city?.name}</p>
                  </div>
                  <div>
                    <p className="font-semibold">Kecamatan</p>
                    <p>{data?.group_profile_member?.district?.name}</p>
                  </div>
                  <div>
                    <p className="font-semibold">Kelurahan/Desa</p>
                    <p>{data?.group_profile_member?.village?.name}</p>
                  </div>
                </div>
                <div>
                  <p className="font-semibold">Alamat</p>
                  <p>{data?.group_profile_member?.address}</p>
                </div>
              </div>
            </motion.div>
          )}
          <AccordionButton
            key={"address-btn"}
            label={"Alamat Domisili"}
            className="mt-5"
            isOpen={accordionAddress}
            onClick={() => setAccordionAddress(!accordionAddress)}
          />
          {accordionAddress && (
            <motion.div
              key={"address-content"}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              exit={{ opacity: 0 }}
            >
              <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 p-5">
                <div>
                  <p className="font-semibold">Provinsi</p>
                  <p>{data?.group_profile_member?.domicile_province?.name}</p>
                </div>
                <div>
                  <p className="font-semibold">Kota/Kabupaten</p>
                  <p>{data?.group_profile_member?.domicile_city?.name}</p>
                </div>
                <div>
                  <p className="font-semibold">Kecamatan</p>
                  <p>{data?.group_profile_member?.domicile_district?.name}</p>
                </div>
                <div>
                  <p className="font-semibold">Kelurahan/Desa</p>
                  <p>{data?.group_profile_member?.domicile_village?.name}</p>
                </div>
                <div>
                  <p className="font-semibold">RT</p>
                  <p>{data?.group_profile_member?.domicile_rt}</p>
                </div>
                <div>
                  <p className="font-semibold">RW</p>
                  <p>{data?.group_profile_member?.domicile_rw}</p>
                </div>
                <div>
                  <p className="font-semibold">Berdomisili Sejak Tahun</p>
                  <p>
                    {dayjs(
                      data?.group_profile_member?.domicile_since_year
                    ).year()}
                  </p>
                </div>
                <div>
                  <p className="font-semibold">Lama Berdomisili</p>
                  <p>
                    {calculateDiffer(
                      data?.group_profile_member?.domicile_since_year
                    )}
                  </p>
                </div>
                <div>
                  <p className="font-semibold">Longitude</p>
                  <p>{data?.group_profile_member?.longitude}</p>
                </div>
                <div>
                  <p className="font-semibold">Latitude</p>
                  <p>{data?.group_profile_member?.latitude}</p>
                </div>
                <div>
                  <p className="font-semibold">Geolocation</p>
                  <Button
                    className={"!bg-[#00BD52]  text-white"}
                    label={
                      <>
                        <RiMapPinLine className="text-xl" />
                        Google Map
                      </>
                    }
                    onClick={() => {
                      window.open(
                        `https://www.google.com/maps?q=${data?.group_profile_member?.latitude}, ${data?.group_profile_member?.longitude}&z=4`,
                        "_blank",
                        "noopener noreferrer"
                      );
                    }}
                  />
                </div>
                <div className="col-span-4">
                  <p className="font-semibold">Alamat</p>
                  <p>{data?.group_profile_member?.domicile_address}</p>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="border rounded-md px-4 py-3">
        <InputFormRadio
          label={"Hasil Verifikasi"}
          controllerName={`address_domicile_verification`}
          className="flex items-center justify-between gap-4"
          disabled={isView}
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Data Terverifikasi
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Data Belum Sesuai/ Ada Kesalahan
                </>
              ),
              value: false,
            },
          ]}
        />
        {verificationResultAddress !== "" &&
          verificationResultAddress === false && (
            <InputForm
              controllerName={"address_domicile_verification_reason"}
              textArea={true}
              label={"Jelaskan kesalahan/ ketidak sesuaian datanya"}
              className={"w-full rounded-md border p-3"}
              disabled={isView}
              wrapperClass={"mt-5"}
            />
          )}
      </div>
    </CardForm>
  );
};

const DataPartner = withFileService(({ data, isView, ...rest }) => {
  const { getFileFromService } = rest;

  const methods = useFormContext();

  const verificationResultPartner = methods.watch("couple_verification");

  const [ktpFile, setKtpFile] = useState();

  useEffect(() => {
    if (ktpFile) return;
    if (!data.group_profile_member.couple_identity_file) return;
    getFileFromService(data.group_profile_member.couple_identity_file, {
      onSuccess: (res) => setKtpFile(res),
      onError: (err) => console.log(err),
    });
  }, []);

  return (
    <CardForm label="Data Pasangan">
      <div className="grid grid-cols-4">
        <div>
          <p className="font-semibold">Nomor NIK</p>
          <p>
            {data?.group_profile_member?.couple_identity_number
              ? data?.group_profile_member?.couple_identity_number
              : "-"}
          </p>
        </div>
        <div>
          <p className="font-semibold">Nama Lengkap sesuai KTP</p>
          {data?.group_profile_member?.couple_name
            ? data?.group_profile_member?.couple_name
            : "-"}
        </div>
        <div>
          <p className="font-semibold">Tempat Lahir</p>
          {data?.group_profile_member?.couple_place_of_birth
            ? data?.group_profile_member?.couple_place_of_birth
            : "-"}
        </div>
        <div>
          <p className="font-semibold">Tanggal Lahir</p>
          {data?.group_profile_member?.couple_date_of_birth
            ? formattedDate(data?.group_profile_member?.couple_date_of_birth)
            : "-"}
        </div>
      </div>
      <div className="grid grid-cols-2">
        {ktpFile && (
          <UploadedFile
            filename="KTP Pasangan"
            size={`${parseFloat(parseInt(ktpFile?.size) / 1024).toFixed(1)} KB`}
            routes={ktpFile?.url}
          />
        )}
      </div>
      <div className="border rounded-md px-4 py-3">
        <InputFormRadio
          label={"Hasil Verifikasi"}
          controllerName={`couple_verification`}
          className="flex items-center justify-between gap-4"
          disabled={isView}
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Data Terverifikasi
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Data Belum Sesuai/ Ada Kesalahan
                </>
              ),
              value: false,
            },
          ]}
        />
        {verificationResultPartner !== "" &&
          verificationResultPartner === false && (
            <InputForm
              controllerName={"couple_verification_reason"}
              textArea={true}
              label={"Jelaskan kesalahan/ ketidak sesuaian datanya"}
              className={"w-full rounded-md border p-3"}
              wrapperClass={"mt-5"}
              disabled={isView}
            />
          )}
      </div>
    </CardForm>
  );
});

const CheckHistory = ({ isView }) => {
  return (
    <CardForm label="Pengecekan Riwayat kredit & Bantuan Sosial">
      <div className="flex-col flex gap-4 ">
        <InputFormRadio
          label={"1. Bukan penerima Bansos (Kemensos)"}
          labelClass={"w-full"}
          controllerName={`credit_history_status_social_assistance`}
          wrapperFieldClass="w-full"
          usingGrid={true}
          gridClassName="grid grid-cols-2 gap-4 w-full"
          className="flex items-center justify-between gap-4 flex-wrap"
          disabled={isView}
          values={[
            {
              label: "Iya",
              value: true,
            },
            {
              label: "Tidak",
              value: false,
            },
          ]}
        />
        <InputFormRadio
          label={"2. Pengecekan SIKP tidak memiliki pinjaman"}
          labelClass={"w-full"}
          controllerName={`credit_history_status_sikp`}
          usingGrid={true}
          gridClassName="grid grid-cols-2 gap-4 w-full"
          wrapperFieldClass="w-full"
          className="flex items-center justify-between gap-4 flex-wrap"
          disabled={isView}
          values={[
            {
              label: "Iya",
              value: true,
            },
            {
              label: "Tidak",
              value: false,
            },
          ]}
        />
        <InputFormRadio
          label={
            "3. Pengecekan BI Checking/SUK/Perindo tidak memiliki catatan kredit buruk"
          }
          labelClass={"w-full"}
          controllerName={`credit_history_status_bi_checking`}
          usingGrid={true}
          wrapperFieldClass="w-full"
          gridClassName="grid grid-cols-2 gap-4 w-full"
          className="flex items-center justify-between gap-4 flex-wrap"
          disabled={isView}
          values={[
            {
              label: "Iya",
              value: true,
            },
            {
              label: "Tidak",
              value: false,
            },
          ]}
        />
      </div>
    </CardForm>
  );
};

export default CandidateDebitorData;
