import React, { useMemo, useCallback, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CardForm from "components/molecules/CardForm";
import { TabsContent } from "components/atoms/Tabs";
import {
  BottomFormAction,
  InputForm,
  InputFormRadio,
  InputFormUpload,
} from "components";
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import {
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiMapPin2Fill,
} from "react-icons/ri";
import { AnimatePresence, motion } from "framer-motion";
import AccordionButton from "components/atoms/AccordionButton";
import SelectFDBProvince from "components/organisms/inputs/SelectFDBProvince";
import SelectFDBCity from "components/organisms/inputs/SelectFDBCity";
import SelectFDBDistrict from "components/organisms/inputs/SelectFDBDistrict";
import SelectFDBVillage from "components/organisms/inputs/SelectFDBVillage";
import GeotagLocationButton from "components/atoms/Button/GeotagLocationButton";
import { useMutation } from "@tanstack/react-query";
import { fileUpload, getFile } from "services/fileService";
import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";
import { find } from "lodash";

const getFileFromServiceCB = async (id) => {
  const response = await getFile(id);
  return response.data.data;
};

export const VerificationRecapContent = ({
  initialValues,
  type = "non-persos",
  onBack,
  onNext,
  onSaveDraft,
  hideDraft,
}) => {
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      v_result_collateral_tree_percentage: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .max(100)
        .nullable()
        .label("Persentasi Hasil Sampling"),
      business_infos: yup.array().of(
        yup.object().shape({
          net_income_one_siclus: yup.string().when([], ([value], schema) => {
            if (type === "persos") {
              return schema
                .required()
                .label("Kisaran Pendapatan Nett Dalam Satu Siklus Usaha");
            }
            return schema.notRequired();
          }),
          financed_province_id: yup.string().when([], ([value], schema) => {
            if (type === "persos") {
              return schema.required().label("Provinsi");
            }
            return schema.notRequired();
          }),
          financed_city_id: yup.string().when([], ([value], schema) => {
            if (type === "persos") {
              return schema.required().label("Kota/Kabupaten");
            }
            return schema.notRequired();
          }),
          financed_district_id: yup.string().when([], ([value], schema) => {
            if (type === "persos") {
              return schema.required().label("Kecamatan");
            }
            return schema.notRequired();
          }),
          financed_village_id: yup.string().when([], ([value], schema) => {
            if (type === "persos") {
              return schema.required().label("Kelurahan/Desa");
            }
            return schema.notRequired();
          }),
          financed_address: yup.string().when([], ([value], schema) => {
            if (type === "persos") {
              return schema.required().label("Alamat");
            }
            return schema.notRequired();
          }),
          debtor_house_file_temp: yup
            .mixed()
            .test(
              "cid_img",
              ({ label }) =>
                validationErrorMsg.mixed.required.replace("${path}", label),
              (value, context) => {
                const allowedTypes = ["jpg", "png", "jpeg"];
                if (type === "persos") {
                  if (value) {
                    const data = find(allowedTypes, (at) =>
                      value.type.includes(at)
                    );
                    if (!data) {
                      return context.createError({
                        message: validationCustomErrorMsg.isFiletype(
                          allowedTypes.join(", ")
                        )({ label: "Foto Usaha Debitur" }),
                      });
                    }
                    if (value.size > 10 * 1024 * 1024) {
                      return context.createError({
                        message: validationCustomErrorMsg.isFileSize("10MB")({
                          label: "Foto Usaha Debitur",
                        }),
                      });
                    }

                    return true;
                  }

                  // otherwise, return false to render the field required
                  return false;
                }
                return true;
              }
            )
            .label("Foto Usaha Debitur"),
        })
      ),
      v_result_identity: yup
        .bool()
        .transform((originalValue) =>
          originalValue === "" ? undefined : originalValue
        )
        .required()
        .label("Opsi Ini"),
      v_result_financed_business: yup
        .bool()
        .transform((originalValue) =>
          originalValue === "" ? undefined : originalValue
        )
        .required()
        .label("Opsi Ini"),
      v_result_financed_business_prospect: yup
        .bool()
        .transform((originalValue) =>
          originalValue === "" ? undefined : originalValue
        )
        .required()
        .label("Opsi Ini"),
      v_result_collateral_tree: yup
        .bool()
        .transform((originalValue) =>
          originalValue === "" ? undefined : originalValue
        )
        .required()
        .label("Opsi Ini"),
      v_result_ownership_land: yup
        .bool()
        .transform((originalValue) =>
          originalValue === "" ? undefined : originalValue
        )
        .required()
        .label("Opsi Ini"),
    });
  }, [type]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (initialValues?.defaultValues) {
      methods.reset(initialValues?.defaultValues);
    }
  }, [methods, initialValues?.defaultValues]);

  const onFormSubmit = useCallback(
    (payload) => {
      onNext(payload);
    },
    [onNext]
  );

  const onDraft = useCallback(() => {
    onSaveDraft(methods.getValues());
  }, [methods, onSaveDraft]);

  return (
    <FormProvider key="verification-recap-content" {...methods}>
      <form
        key="verification-recap-content"
        id="verification-recap-content"
        name="verification-recap-content"
      >
        <TabsContent value="verification-recap" className="mb-40">
          <VerificationResult />
          {type === "persos" && (
            <Recommendation
              data={initialValues?.defaultValues?.business_infos}
            />
          )}
          <BottomFormAction
            backButtonAction={() => onBack(methods.getValues())}
            submitButtonProps={{
              label: "Finalisasi Verifikasi",
              type: "button",
            }}
            draftButtonProps={{
              type: "button",
            }}
            submitActionButton={() => methods.handleSubmit(onFormSubmit)()}
            disableDrafButton={false}
            drafButtonAction={onDraft}
            disableButtonSubmit={false}
            hideDraft={hideDraft}
            hideSubmit={initialValues?.status === "2"}
            className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
          />
        </TabsContent>
      </form>
    </FormProvider>
  );
};

const Recommendation = ({ data }) => {
  const [accordionState, setAccordionState] = useState({
    recommendation: true,
  });

  const toggleAccordion = (key) => () => {
    setAccordionState({
      ...accordionState,
      [key]: !accordionState?.[key],
    });
  };

  const methods = useFormContext();

  const provinceId = useWatch({
    control: methods.control,
    name: "business_infos[0].financed_province_id",
  });
  const cityId = useWatch({
    control: methods.control,
    name: "business_infos[0].financed_city_id",
  });
  const districtId = useWatch({
    control: methods.control,
    name: "business_infos[0].financed_district_id",
  });

  const fileMutation = useMutation(async (file) => {
    try {
      const response = await fileUpload(file);
      if (file?.controllerName === "business_infos[0].debtor_house_file_temp") {
        methods.register("business_infos[0].debtor_house_file");
        methods.setValue(
          "business_infos[0].debtor_house_file",
          response.data.data.id
        );
      }
    } catch (error) {
      throw error;
    }
  });

  const temp_business_infos = useMemo(() => {
    return data?.length > 0
      ? data?.find((x) => x.type === "recommendation")
      : {};
  }, [data]);

  useEffect(() => {
    const promises = [];
    if (
      temp_business_infos?.debtor_house_file &&
      temp_business_infos?.debtor_house_file !== "string"
    ) {
      promises.push(
        getFileFromServiceCB(temp_business_infos?.debtor_house_file).then(
          (data) => {
            return {
              formId: "business_infos[0].debtor_house_file_temp",
              type: data?.mimeType,
              ...data,
            };
          }
        )
      );
    }
    if (promises?.length > 0) {
      Promise.all(promises).then((values) => {
        values.map((x) => {
          methods.resetField(x.formId, { defaultValue: x });
        });
      });
    }
  }, [temp_business_infos]);

  return (
    <CardForm label="Rekomendasi">
      <AnimatePresence>
        <AccordionButton
          key={"accordion-tab-business-prospect-btn"}
          label={"Usaha Lain yang Dibiayai Serta Pengeluaran"}
          isOpen={accordionState?.recommendation}
          onClick={toggleAccordion("recommendation")}
        />

        {accordionState?.recommendation && (
          <motion.div
            key={"accordion-tab-recommendation"}
            className=" gap-4 space-y-4 "
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            }}
            exit={{ opacity: 0 }}
          >
            <p className="font-semibold text-lg text-primary-maincolor-700">
              Informasi Usaha
            </p>
            <InputForm
              controllerName={"business_infos[0].net_income_one_siclus"}
              className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
              label={"Kisaran Pendapatan Nett Dalam Satu Siklus Usaha"}
              placeholder={"Kisaran Pendapatan Nett Dalam Satu Siklus Usaha"}
              onKeyPress={(event) => {
                const ASCIICode = event.which ? event.which : event.keyCode;
                if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                  return;
                event.preventDefault();
              }}
            />
            <div className="grid grid-cols-2 gap-4">
              <SelectFDBProvince
                controllerName={"business_infos[0].financed_province_id"}
                className="basic-single mt-2 flex-1"
                label="Provinsi"
                required={true}
                returnObject={false}
              />
              <SelectFDBCity
                controllerName={"business_infos[0].financed_city_id"}
                className="basic-single mt-2 flex-1"
                label="Kota/Kabupaten"
                required={true}
                provinceId={provinceId}
                returnObject={false}
              />
              <SelectFDBDistrict
                controllerName={"business_infos[0].financed_district_id"}
                className="basic-single mt-2 flex-1"
                label="Kecamatan"
                required={true}
                cityId={cityId}
                returnObject={false}
              />
              <SelectFDBVillage
                controllerName={"business_infos[0].financed_village_id"}
                className="basic-single mt-2 flex-1"
                label="Kelurahan/Desa"
                required={true}
                districtId={districtId}
                returnObject={false}
              />
            </div>
            <InputForm
              controllerName="business_infos[0].financed_address"
              label="Alamat Lengkap Usaha"
              placeholder="Alamat Lengkap Usaha"
              required={true}
              wrapperClass="col-span-2"
              className="rounded-md w-full px-4 py-3 border"
              textArea
            />
            <div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <span className="font-medium">Geo Location</span>
                  <GeotagLocationButton
                    label="Geotag Usaha"
                    icon={<RiMapPin2Fill className={"w-5 h-5 shrink-0"} />}
                    className={"!bg-primary-700 !text-white w-fit"}
                    onSuccess={(val) => {
                      methods.setValue(
                        "business_infos[0].financed_longitude",
                        val.longitude
                      );
                      methods.setValue(
                        "business_infos[0].financed_latitude",
                        val.latitude
                      );
                    }}
                  />
                </div>
                <div className="mb-4">
                  <InputFormUpload
                    uploadFile={fileMutation}
                    controllerName={`business_infos[0].debtor_house_file_temp`}
                    label={"Unggah Foto Usaha Debitur"}
                  />
                </div>
                <InputForm
                  controllerName={"business_infos[0].financed_longitude"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={"Longitude"}
                  placeholder={"Longitude"}
                />
                <InputForm
                  controllerName={"business_infos[0].financed_latitude"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={"Latitude"}
                  placeholder={"Latitude"}
                />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </CardForm>
  );
};

const VerificationResult = () => {
  return (
    <CardForm label="Hasil Verifikasi">
      <div className="flex gap-4">
        <InputFormRadio
          label={"1. Identitas Pemohon"}
          controllerName={"v_result_identity"}
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Data Terverifikasi
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Tidak Terverifikasi
                </>
              ),
              value: false,
            },
          ]}
        />
      </div>
      <div className="flex gap-4">
        <InputFormRadio
          label={"2. Prospek Usaha Yang Dibiayai?"}
          controllerName={"v_result_financed_business"}
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Data Terverifikasi
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Tidak Terverifikasi
                </>
              ),
              value: false,
            },
          ]}
        />
        <InputFormRadio
          label={" "}
          className={"pt-[23px]"}
          controllerName={"v_result_financed_business_prospect"}
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Prospektif
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Tidak Prospektif
                </>
              ),
              value: false,
            },
          ]}
        />
      </div>
      <div className="flex gap-4">
        <InputFormRadio
          label={"3. Usaha Selain Yang Dibiayai"}
          controllerName={"v_result_collateral_tree"}
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Mempunyai Usaha Lain Untuk Menunjang Kemampuan Bayar
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Tidak Mempunyai Usaha Lain Untuk Menunjang Kemampuan Bayar
                </>
              ),
              value: false,
            },
          ]}
        />
      </div>

      <label className={`label text-[14px] font-semibold`}>
        4. Jaminan Pohon/Usaha Kehutanan
      </label>
      <InputForm
        controllerName={"v_result_collateral_tree_percentage"}
        className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
        label={"Persentasi Hasil Sampling"}
        placeholder={"Persentasi Hasil Sampling"}
        onKeyPress={(event) => {
          const ASCIICode = event.which ? event.which : event.keyCode;
          if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57)) return;
          event.preventDefault();
        }}
        rightIcon="%"
      />
      <div className="flex gap-4">
        <InputFormRadio
          label={""}
          controllerName={"v_result_ownership_land"}
          values={[
            {
              label: (
                <>
                  <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                  Penguasaan lahan Terverifikasi
                </>
              ),
              value: true,
            },
            {
              label: (
                <>
                  <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                  Tidak Terverifikasi
                </>
              ),
              value: false,
            },
          ]}
        />
      </div>
    </CardForm>
  );
};

export default VerificationRecapContent;
