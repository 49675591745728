import { Button } from "components";
import React, { Fragment, useRef, useState } from "react";

import { Controller, useFormContext } from "react-hook-form";
import { RiUploadCloud2Line, RiFile2Line } from "react-icons/ri";
import { humanizeFileSize } from "../../../helpers";
import { IoMdImage } from "react-icons/io";

const InputFormUpload = ({
  controllerName,
  className,
  label,
  required = false,
  textArea = false,
  trigger,
  maxSize = 100 * 1024 * 1024,
  fileType = "PNG, JPG, PDF, Docx",
  buttonLabel,
  isMulti = false,
  onUploadMultiple,
  uploadFile,
  generateUrl,
  setUploadedUrls,
  setControllerTarget,
  onModalSubmit = () => { },
  ...props
}) => {
  const refInput = useRef(controllerName);
  const [previewImage, setPreviewImage] = useState(null);
  const { control } = useFormContext();

  const triggerRef = () => {
    refInput.current.click();
  };

  // const isFileTypeValid = (uploadedFileType) => {
  //   const allowedFileTypes = fileType.split(',').map(type => type.trim().toLowerCase());

  //   if (allowedFileTypes.includes('pdf') && uploadedFileType === 'application/pdf') {
  //     return true;
  //   }

  //   if (allowedFileTypes.includes('docx') && uploadedFileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
  //     return true;
  //   }

  //   if (allowedFileTypes.some(type => type.startsWith('image/')) && uploadedFileType.startsWith('image/')) {
  //     return true;
  //   }

  //   return false;
  // };

  const handleSelectImage = async (event, key) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();

    // const isValid = isFileTypeValid(file.type)

    fileReader.addEventListener("load", () => {
      setPreviewImage(fileReader?.result);
    });
    fileReader.readAsDataURL(file);
    if (uploadFile && key) {
      try {
        let target = { file: file, controllerName };
        await uploadFile.mutateAsync(target);
      } catch (error) { }


    // if (isValid) {
    //   fileReader.addEventListener("load", () => {
    //     setPreviewImage(fileReader?.result);
    //   });
    //   fileReader.readAsDataURL(file);
    //   if (uploadFile && key) {
    //     try {
    //       let target = { file: file, controllerName };
    //       await uploadFile.mutateAsync(target);
    //     } catch (error) { }
    //   }
    // } else {
    //   setError(controllerName, {
    //     type: "custom",
    //     message: `File harus berupa ${fileType}`,
    //   });
    }
  };

  const maxFileSize = humanizeFileSize(maxSize, 0);

  const handleClick = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  return (
    <Controller
      name={controllerName}
      control={control}
      defaultValue={""}
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => {
        return (
          <>
            <div className="form-control w-full">
              <label className="label font-semibold text-[14px] ">
                <span className={`label-text`}>{label}</span>
                {required && <span className="text-[#F04438]">*</span>}
              </label>

              <Fragment>
                <input
                  type="file"
                  {...props}
                  ref={refInput}
                  onChange={(e) => {
                    handleSelectImage(e, "upload");
                    if (!isMulti) {
                      onChange(e?.target?.files[0]);
                      // const file = e?.target?.files[0];
                      // const isValid = isFileTypeValid(file.type)
                      // if (isValid) {
                      //   onChange(file)
                      //   clearErrors(controllerName)
                      // }
                    }
                  }}
                  className={"hidden"}
                />
                <Button
                  type="button"
                  className="w-full"
                  onClick={triggerRef}
                  label={
                    buttonLabel || (
                      <div
                        className={`w-full mt-2 bg-white text-center items-center border rounded-md ${value || previewImage ? "h-auto" : "h-32"
                          }`}
                      >
                        <div
                          className={`flex flex-col items-center h-full justify-center ${value || previewImage ? "p-4" : "py-2"
                            }`}
                        >
                          <>
                            {previewImage || value ? (
                              <>
                                {previewImage?.includes("image") ||
                                  value?.type?.includes("image") ? (
                                  <div
                                    className={
                                      "rounded border border-gray-300 p-4 flex w-full"
                                    }
                                  >
                                    <div
                                      className={
                                        "bg-[#EEFFF4] p-1 rounded-full self-start"
                                      }
                                    >
                                      <div
                                        className={
                                          "bg-[#D6FFE8] p-1 rounded-full flex-initial"
                                        }
                                      >
                                        <IoMdImage
                                          className={"text-[#00BD52] text-3xl"}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        "text-left ml-5 min-w-0 flex-auto"
                                      }
                                    >
                                      <div className="truncate">
                                        {value.name}
                                      </div>
                                      <div>
                                        {humanizeFileSize(value.size ?? 0)}
                                      </div>
                                      <div
                                        className="cursor-pointer hover:underline text-[#00BD52]"
                                        onClick={() =>
                                          handleClick(
                                            previewImage?.url
                                              ? previewImage?.url
                                              : previewImage
                                                ? previewImage
                                                : value?.url &&
                                                  typeof value?.url === "string"
                                                  ? `${value?.url}`
                                                  : previewImage?.url
                                          )
                                        }
                                      >
                                        Lihat berkas
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <div
                                      className={
                                        "rounded border border-gray-300 p-4 flex w-full"
                                      }
                                    >
                                      <div
                                        className={
                                          "bg-[#EEFFF4] p-1 rounded-full self-start"
                                        }
                                      >
                                        <div
                                          className={
                                            "bg-[#D6FFE8] p-1 rounded-full flex-initial"
                                          }
                                        >
                                          <RiFile2Line
                                            className={
                                              "text-[#00BD52] text-3xl"
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          "text-left ml-5 min-w-0 flex-auto"
                                        }
                                      >
                                        <div className="truncate">
                                          {value.name}
                                        </div>
                                        <div>
                                          {humanizeFileSize(value.size ?? 0)}
                                        </div>
                                        <div
                                          className="cursor-pointer hover:underline text-[#00BD52]"
                                          onClick={() =>
                                            handleClick(
                                              previewImage?.url
                                                ? previewImage?.url
                                                : previewImage
                                                  ? previewImage
                                                  : value?.url &&
                                                    typeof value?.url === "string"
                                                    ? `${value?.url}`
                                                    : previewImage?.url
                                            )
                                          }
                                        >
                                          Lihat berkas
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <RiUploadCloud2Line />
                                <div className="px-4">
                                  <span className="font-semibold text-[#01A24A]">
                                    Klik untuk upload{" "}
                                  </span>
                                  {/* <span className="font-normal text-[14px]">
                                    atau seret dan lepas kesini
                                  </span> */}
                                  <div className="font-normal text-[14px]">
                                    {fileType} up to {maxFileSize}
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        </div>
                      </div>
                    )
                  }
                />
                {invalid && (
                  <label className="label">
                    <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
                      {error?.message}
                    </div>
                  </label>
                )}
              </Fragment>
            </div>
          </>
        );
      }}
    />
  );
};

export default InputFormUpload;
