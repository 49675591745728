import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useFormContext, useWatch } from "react-hook-form";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";

import AccordionButton from "components/atoms/AccordionButton";
import CardForm from "components/molecules/CardForm";
import { InputForm, InputFormRadio } from "components";
import { formatCurrency } from "helpers";

const Prospect = ({ data }) => {
  const methods = useFormContext();

  const [accordionState, setAccordionState] = useState({
    business_prospect_condition: true,
    income_history_prospect_condition: true,
    tab_business_prospect_condition: true,
  });

  const toggleAccordion = (key) => () => {
    setAccordionState({
      ...accordionState,
      [key]: !accordionState?.[key],
    });
  };

  const prospect_verification = useWatch({
    control: methods.control,
    name: "prospect_verification",
  });

  const prospectMaintenancePatternField = useWatch({
    control: methods.control,
    name: "prospect_maintenance_pattern",
  });

  const prospectSalesPatternField = useWatch({
    control: methods.control,
    name: "prospect_sales_pattern",
  });

  const prospectIncomeNeededField = useWatch({
    control: methods.control,
    name: "prospect_income_adjusted",
  });

  const { group_profile_member } = data || {
    deferred: {},
    group_profile_member: {},
  };

  return (
    <CardForm label="Prospek Usaha Yang Dibiayai">
      <div className="space-y-5">
        <AnimatePresence>
          <div className="w-full">
            <div className="font-semibold">
              Rencana Penggunaan Modal Pinjaman
            </div>
            <div>
              {group_profile_member?.business_panning_application
                ? formatCurrency(
                    group_profile_member?.business_panning_application
                  )
                : "-"}
            </div>
          </div>
          <div className="flex gap-4 w-full">
            <div className="w-full">
              <div className="font-semibold">
                Perkiraan Omzet/Harga Jual/Pendapatan Kotor
              </div>
              <div>
                {group_profile_member?.business_revenue
                  ? formatCurrency(group_profile_member?.business_revenue)
                  : "-"}
              </div>
            </div>
            <div className="w-full">
              <div className="font-semibold">
                Perkiraan Biaya Produksi / HPP / Modal Yang Telah Dikeluarkan
              </div>
              <div>
                {group_profile_member?.business_cost_price
                  ? formatCurrency(group_profile_member?.business_cost_price)
                  : "-"}
              </div>
            </div>
          </div>
          <div className="flex gap-4 w-full">
            <div className="w-full">
              <div className="font-semibold">Siklus Usaha Produksi</div>
              <div>{`${group_profile_member?.business_cycle ?? ""} ${
                group_profile_member?.business_cycle_unit ?? ""
              }`}</div>
            </div>
            <div className="w-full">
              <div className="font-semibold">Perkiraan Pendapatan Bersih</div>
              <div>
                {group_profile_member?.business_net_income
                  ? formatCurrency(group_profile_member?.business_net_income)
                  : "-"}
              </div>
            </div>
          </div>
          <InputFormRadio
            required
            label={
              "Apakah Nilai Perkiraan Pendapatan Bersih Perlu Disesuaikan?"
            }
            controllerName={"prospect_income_adjusted"}
            values={[
              {
                label: <>Ya</>,
                value: true,
              },
              {
                label: <>Tidak</>,
                value: false,
              },
            ]}
          />
          {prospectIncomeNeededField !== "" &&
            prospectIncomeNeededField === true && (
              <div className="grid grid-cols-2 gap-4">
                <InputForm
                  controllerName={"prospect_loan_ability_payment"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label={"Berapa Nilai Penyesuaiannya"}
                  placeholder={"Nilai Penyesuaian"}
                  isNumber={true}
                  prefix="Rp"
                  onKeyPress={(event) => {
                    const ASCIICode = event.which ? event.which : event.keyCode;
                    if (
                      ASCIICode === 44 ||
                      (ASCIICode >= 48 && ASCIICode <= 57)
                    )
                      return;
                    event.preventDefault();
                  }}
                />
              </div>
            )}
          <AccordionButton
            key={"accordion-tab-business-prospect-btn"}
            label={"Tabulasi Prospek usaha yang dibiayai"}
            isOpen={accordionState?.tab_business_prospect_condition}
            onClick={toggleAccordion("tab_business_prospect_condition")}
          />

          {accordionState?.tab_business_prospect_condition && (
            <motion.div
              key={"accordion-tab-business-prospect-content"}
              className=" gap-4 space-y-4 "
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              exit={{ opacity: 0 }}
            >
              <div className="flex gap-4">
                <InputFormRadio
                  required
                  label={
                    "1. Apakah Prospek Usaha DIbiayai Memiliki Pola Pemeliharaan?"
                  }
                  controllerName={"prospect_maintenance_pattern"}
                  values={[
                    {
                      label: <>Ya</>,
                      value: true,
                    },
                    {
                      label: <>Tidak</>,
                      value: false,
                    },
                  ]}
                />
              </div>
              {prospectMaintenancePatternField !== "" &&
                prospectMaintenancePatternField === true && (
                  <div className="grid grid-cols-2 gap-4">
                    <InputForm
                      controllerName={"prospect_purchased_price"}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                      label={"Harga Beli"}
                      placeholder={"Harga Beli"}
                      isNumber={true}
                      prefix="Rp"
                      onKeyPress={(event) => {
                        const ASCIICode = event.which
                          ? event.which
                          : event.keyCode;
                        if (
                          ASCIICode === 44 ||
                          (ASCIICode >= 48 && ASCIICode <= 57)
                        )
                          return;
                        event.preventDefault();
                      }}
                    />
                    <InputForm
                      controllerName={"prospect_maintenance_price"}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                      label={"Biaya Pemeliharaan"}
                      placeholder={"Biaya Pemeliharaan"}
                      isNumber={true}
                      prefix="Rp"
                      onKeyPress={(event) => {
                        const ASCIICode = event.which
                          ? event.which
                          : event.keyCode;
                        if (
                          ASCIICode === 44 ||
                          (ASCIICode >= 48 && ASCIICode <= 57)
                        )
                          return;
                        event.preventDefault();
                      }}
                    />
                    <InputForm
                      controllerName={"prospect_maintenance_income"}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                      label={"Pendapatan Pemeliharaan"}
                      placeholder={"Pendapatan Pemeliharaan"}
                      isNumber={true}
                      prefix="Rp"
                      onKeyPress={(event) => {
                        const ASCIICode = event.which
                          ? event.which
                          : event.keyCode;
                        if (
                          ASCIICode === 44 ||
                          (ASCIICode >= 48 && ASCIICode <= 57)
                        )
                          return;
                        event.preventDefault();
                      }}
                    />
                    <InputForm
                      controllerName={"prospect_ready_purchased"}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                      label={"Harga Siap Jual"}
                      placeholder={"Harga Siap Jual"}
                      isNumber={true}
                      prefix="Rp"
                      onKeyPress={(event) => {
                        const ASCIICode = event.which
                          ? event.which
                          : event.keyCode;
                        if (
                          ASCIICode === 44 ||
                          (ASCIICode >= 48 && ASCIICode <= 57)
                        )
                          return;
                        event.preventDefault();
                      }}
                    />
                  </div>
                )}
              <InputFormRadio
                required
                label={
                  "2. Apakah Prospek Usaha DIbiayai Memiliki Pola Penjualan?"
                }
                controllerName={"prospect_sales_pattern"}
                values={[
                  {
                    label: <>Ya</>,
                    value: true,
                  },
                  {
                    label: <>Tidak</>,
                    value: false,
                  },
                ]}
              />
              {prospectSalesPatternField !== "" &&
                prospectSalesPatternField === true && (
                  <div className="grid grid-cols-2 gap-4">
                    <InputForm
                      controllerName={"prospect_sales_capital"}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                      label={"Modal"}
                      placeholder={"Modal"}
                      isNumber={true}
                      prefix="Rp"
                      onKeyPress={(event) => {
                        const ASCIICode = event.which
                          ? event.which
                          : event.keyCode;
                        if (
                          ASCIICode === 44 ||
                          (ASCIICode >= 48 && ASCIICode <= 57)
                        )
                          return;
                        event.preventDefault();
                      }}
                    />
                    <InputForm
                      controllerName={"prospect_sales_cost"}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                      label={"Biaya Penjualan"}
                      placeholder={"Biaya Penjualan"}
                      isNumber={true}
                      prefix="Rp"
                      onKeyPress={(event) => {
                        const ASCIICode = event.which
                          ? event.which
                          : event.keyCode;
                        if (
                          ASCIICode === 44 ||
                          (ASCIICode >= 48 && ASCIICode <= 57)
                        )
                          return;
                        event.preventDefault();
                      }}
                    />
                    <InputForm
                      controllerName={"prospect_selling_price"}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                      label={"Harga Jual"}
                      placeholder={"Harga Jual"}
                      isNumber={true}
                      prefix="Rp"
                      onKeyPress={(event) => {
                        const ASCIICode = event.which
                          ? event.which
                          : event.keyCode;
                        if (
                          ASCIICode === 44 ||
                          (ASCIICode >= 48 && ASCIICode <= 57)
                        )
                          return;
                        event.preventDefault();
                      }}
                    />
                    <InputForm
                      controllerName={"prospect_selling_income"}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                      label={"Pendapatan Penjualan"}
                      placeholder={"Pendapatan Penjualan"}
                      isNumber={true}
                      prefix="Rp"
                      onKeyPress={(event) => {
                        const ASCIICode = event.which
                          ? event.which
                          : event.keyCode;
                        if (
                          ASCIICode === 44 ||
                          (ASCIICode >= 48 && ASCIICode <= 57)
                        )
                          return;
                        event.preventDefault();
                      }}
                    />
                    <InputForm
                      controllerName={"prospect_percentage_revenue"}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                      label={"Persentase Pendapatan Netto"}
                      placeholder={"Persentase Pendapatan Netto"}
                      isNumber={true}
                      onKeyPress={(event) => {
                        const ASCIICode = event.which
                          ? event.which
                          : event.keyCode;
                        if (
                          ASCIICode === 44 ||
                          (ASCIICode >= 48 && ASCIICode <= 57)
                        )
                          return;
                        event.preventDefault();
                      }}
                    />
                  </div>
                )}
              <InputFormRadio
                required
                label={
                  "3. Apakah Data Perkiraan Penghasilan Dari Pengembangan Usaha Wajar?"
                }
                controllerName={"prospect_normal_income"}
                values={[
                  {
                    label: <>Ya</>,
                    value: true,
                  },
                  {
                    label: <>Tidak</>,
                    value: false,
                  },
                ]}
              />
              <InputFormRadio
                required
                label={
                  "4. Kegiatan Usaha Yang Dibiayai Menghasilkan Keuntungan Bagi Calon Debitur?"
                }
                controllerName={"prospect_make_profit"}
                values={[
                  {
                    label: <>Ya</>,
                    value: true,
                  },
                  {
                    label: <>Tidak</>,
                    value: false,
                  },
                ]}
              />
              <div className="border rounded-md px-4 py-3">
                <InputFormRadio
                  label={"Hasil Verifikasi"}
                  controllerName={`prospect_verification`}
                  className="flex items-center justify-between gap-4"
                  values={[
                    {
                      label: (
                        <>
                          <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                          Kesimpulan Verivikasi
                        </>
                      ),
                      value: true,
                    },
                    {
                      label: (
                        <>
                          <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                          Data Belum Sesuai/ Ada Kesalahan
                        </>
                      ),
                      value: false,
                    },
                  ]}
                />
                {prospect_verification !== "" &&
                  prospect_verification === false && (
                    <InputForm
                      controllerName={"prospect_verification_desk"}
                      textArea={true}
                      label={"Jelaskan kesalahan/ ketidak sesuaian datanya"}
                      className={"w-full rounded-md border p-3"}
                      wrapperClass={"mt-5"}
                    />
                  )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </CardForm>
  );
};

export default Prospect;
