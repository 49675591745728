import React, { useState, useMemo, useEffect, useCallback } from "react";
import BreadCrumbs from "components/molecules/Breadcrumbs";

import { useNavigate, useParams } from "react-router";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import { BottomFormAction, InputForm, Spinner } from "components";
import CardForm from "components/molecules/CardForm";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RiEyeLine, RiInformationLine, RiSearchLine } from "react-icons/ri";
import AtomSelect from "components/atoms/Select";
import { debounce } from "lodash";
import Badge from "components/atoms/Badge";
import Table, { TableWrapper } from "components/atoms/Table";
import TablePagination from "components/atoms/TablePagination";
import * as yup from "yup";
import Button from "components/atoms/Button/Button";
import { getOfferDetail, putOfferDetail } from "services/fdb/offers";
import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import {
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
} from "components/atoms/Modal";

const title = "Daftar Penawaran";

const OffersStatus = {
  APPROVE: {
    text: "Setuju",
    color: "success",
  },
  AGREE_WITH_NOTES: {
    text: "Setuju Dengan Catatan",
    color: "warning",
  },
  REJECT: {
    text: "Menolak",
    color: "error",
  },
  NOT_YET_OFFER: {
    text: "Belum Mengajukan",
    color: "bluelight",
  },
};

const OffersReviewStatus = {
  APPROVE: {
    text: "Disetujui",
    color: "success",
  },
  REJECT: {
    text: "Ditolak",
    color: "error",
  },
  NOT_YET_REVIEW: {
    text: "Belum Direview",
    color: "warning",
  },
};

const ListMember = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    search: undefined,
    status: undefined,
  });
  const [isOpen, setOpen] = useState(false);

  const { data, status } = useQuery({
    queryKey: ["offers-detail", id, page, limit, filter],
    queryFn: async () => {
      return (await getOfferDetail(id, { page, limit, filter })).data;
    },
    onSuccess: ({ data }) => {
      if (data?.offers_members?.data?.length) {
        setTotalPage(data?.offers_members?.meta?.last_page);
        setTotalItems(data?.offers_members?.meta?.total);
      }
    },
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      search: yup.string(),
      state: yup.string(),
    });
  }, []);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      search: "",
      state: "",
    },
  });

  const handleSubmitFilter = debounce(() => {
    setFilter({
      search:
        methods.getValues("search") !== ""
          ? methods.getValues("search")
          : undefined,
      status:
        methods.getValues("state") !== ""
          ? methods.getValues("state")
          : undefined,
    });
  }, 1000);

  useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(handleSubmitFilter)
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  const mutation = useMutation({
    mutationFn: () => {
      return putOfferDetail(id);
    },
  });

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const verification = () => {
    mutation.mutate(
      {},
      {
        onSuccess: () => {
          enqueueSnackbar({
            variant: "success",
            message: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ fontWeight: "bold" }}>
                  Data berhasil disimpan!
                </span>
                <span>Data yang Anda masukkan berhasil dikirim</span>
              </div>
            ),
          });
          setOpen((prev) => !prev);
          navigate(`/offer-negotiation`, { replace: true });
        },
        onError: (error) => {
          enqueueSnackbar({
            message: getErrorMessage(error),
            variant: "error",
          });
          setOpen((prev) => !prev);
        },
      }
    );
  };

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: "/offer-negotiation",
          },
          {
            label: "Daftar Anggota",
            path: `/offer-negotiation/list-member/${id}`,
          },
        ]}
      />

      {status === "loading" ? (
        <div className="flex justify-center p-4">
          <Spinner theme={"primary"} />
        </div>
      ) : (
        <>
          <CardForm label="Detail Permohonan">
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
              <div>
                <div className="font-semibold">Nama Kelompok</div>
                <div>{data?.data?.group_name ?? "-"}</div>
              </div>
              <div>
                <div className="font-semibold">Nama Ketua</div>
                <div>{data?.data?.leader_name ?? "-"}</div>
              </div>
              <div>
                <div className="font-semibold">Tanggal Pengajuan Anggota</div>
                <div>
                  {data?.data?.registration_date
                    ? dayjs(data?.data?.registration_date).format(
                        "DD MMMM YYYY"
                      )
                    : "-"}
                </div>
              </div>
              <div>
                <div className="font-semibold">Total Anggota Diajukan</div>
                <div>{data?.data?.registration_members ?? "-"}</div>
              </div>
            </div>
          </CardForm>
          <CardForm>
            <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
              <GroupsFilter methods={methods} />
              <GroupsTable
                data={data?.data?.offers_members?.data}
                isLoading={status === "loading"}
                totalItems={totalItems}
                totalPage={totalPage}
                setLimit={setLimit}
                setPage={setPage}
              />
            </div>
          </CardForm>
          <BottomFormAction
            backButtonAction={onBack}
            submitButtonProps={{
              label: "Submit",
              type: "button",
            }}
            submitActionButton={() => setOpen(true)}
            hideDraft={true}
            hideSubmit={
              !data?.data?.all_data_reviewed || data?.data?.status === "DONE"
            }
            disableButtonSubmit={false}
            className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
          />
          <ConfirmationModal
            isOpen={isOpen}
            setOpen={setOpen}
            isLoading={mutation.status === "loading"}
            onSubmit={verification}
          />
        </>
      )}
    </div>
  );
};

const GroupsFilter = ({ methods }) => {
  return (
    <FormProvider {...methods}>
      <form className="grid grid-cols-1 md:grid-cols-2 gap-4 items-end">
        <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
          <RiSearchLine className="text-gray-400 text-xl" />
          <InputForm
            controllerName="search"
            placeholder="Cari"
            className={`!pl-0 w-full rounded-md border-none`}
          />
        </div>
        <AtomSelect
          controllerName={`state`}
          className={`w-full rounded-md h-10 absolute`}
          options={Object.keys(OffersReviewStatus).map((x) => ({
            value: x,
            label: OffersReviewStatus[x].text,
          }))}
          placeholder={"Status"}
          required={true}
          returnObject={false}
        />
      </form>
    </FormProvider>
  );
};

const GroupsTable = ({
  data,
  isLoading,
  totalPage,
  totalItems,
  setPage,
  setLimit,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const statusParam = searchParams.get("status");

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "name",
        title: "Nama",
      },
      {
        key: "offeringResult",
        title: "Hasil Penawaran",
        render: ({ item }) => (
          <Badge color={OffersStatus[item.status_bid_result]?.color}>
            {OffersStatus[item.status_bid_result]?.text}
          </Badge>
        ),
      },
      {
        key: "state",
        title: "Status Review",
        render: ({ item }) => (
          <Badge color={OffersReviewStatus[item.status_review]?.color}>
            {OffersReviewStatus[item.status_review]?.text}
          </Badge>
        ),
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        render: ({ item }) => (
          <>
            {["NOT_YET_OFFER"].includes(item?.status_bid_result) ? (
              <></>
            ) : ["NOT_YET_REVIEW"].includes(item.status_review) &&
              ["APPROVE", "AGREE_WITH_NOTES", "REJECT"].includes(
                item.status_bid_result
              ) ? (
              <Button
                color="primary"
                label="Verifikasi"
                onClick={() => {
                  navigate(
                    `/offer-negotiation/list-member/${id}/${item.applicant_id}?status=${statusParam}`
                  );
                }}
              />
            ) : (
              <Button
                variant="icon"
                label={<RiEyeLine className="text-lg" />}
                onClick={() => {
                  navigate(
                    `/offer-negotiation/list-member/${id}/${item.applicant_id}?type=detail&status=${statusParam}`
                  );
                }}
              />
            )}
          </>
        ),
      },
    ];
  }, [navigate, id, statusParam]);

  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={data ?? []} isLoading={isLoading} />
      <TablePagination
        totalPage={totalPage}
        totalItems={totalItems}
        onChange={setPage}
        onItemsPerPageChange={setLimit}
      />
    </TableWrapper>
  );
};

const ConfirmationModal = ({
  isOpen,
  setOpen,
  isLoading = false,
  onSubmit,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      setOpen={setOpen}
      manual
      onOpenChange={() => setOpen(false)}
    >
      <ModalHeader
        noBorder
        hideClose
        title={
          <div className="w-12 h-12 rounded-full flex items-center justify-center text-orange-700 bg-orange-100 border-4 border-orange-50">
            <RiInformationLine className="text-base" size={24} />
          </div>
        }
      />
      <ModalBody className="space-y-4">
        <p className="text-lg font-semibold">Kirim Persetujuan</p>
        <p className="text text-[#475467]">
          Anda yakin akan mengirimkan persetujuan verifikasi data Negosiasi ini?
        </p>
        <div className="flex items-center gap-4">
          <ModalClose>
            <Button label="Tidak" block disabled={isLoading} />
          </ModalClose>
          <Button
            type="button"
            onClick={onSubmit}
            label={
              isLoading ? (
                <>
                  <Spinner /> Loading...
                </>
              ) : (
                "Ya"
              )
            }
            color="primary"
            block
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ListMember;
