import * as Tabs from '@radix-ui/react-tabs';

export const TabsRoot = ({ children, ...restProps }) => {
  return <Tabs.Root {...restProps}>{children}</Tabs.Root>;
};

export const TabsList = ({ children, ...restProps }) => {
  return (
    <Tabs.List
      className="border-b mb-4 flex items-center gap-2 overflow-x-auto"
      {...restProps}
    >
      {children}
    </Tabs.List>
  );
};

export const TabsTrigger = ({ children, ...restProps }) => {
  return (
    <Tabs.Trigger
      className="font-semibold whitespace-nowrap px-4 py-2 border-b-[3px] border-transparent transition duration-300 hover:bg-primary-50 hover:text-primary-700 data-[state=active]:text-primary-700 data-[state=active]:border-primary-700 data-[state=active]:bg-primary-50"
      {...restProps}
    >
      {children}
    </Tabs.Trigger>
  );
};

export const TabsContent = ({ children, ...restProps }) => {
  return (
    <Tabs.Content className="space-y-6" {...restProps}>
      {children}
    </Tabs.Content>
  );
};
