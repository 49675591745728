import * as yup from "yup";
import { find } from "lodash";

import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";

const validationSchema = yup.object().shape({
  number: yup.string().required().label("Nomor Risalah"),
  created: yup.string().required().label("Tanggal Terbit Risalah"),
  minutes_file_temp: yup
    .mixed()
    .test(
      "cid_img",
      ({ label }) =>
        validationErrorMsg.mixed.required.replace("${path}", label),
      (value, context) => {
        const allowedTypes = [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        if (value) {
          const data = find(allowedTypes, (at) => value.type.includes(at));
          if (!data) {
            return context.createError({
              message: validationCustomErrorMsg.isFiletype("PDF, DOCX")({
                label: "Dokumen Risalah Lembaga Penyalur",
              }),
            });
          }
          if (value.size > 10 * 1024 * 1024) {
            return context.createError({
              message: validationCustomErrorMsg.isFileSize("10MB")({
                label: "Dokumen Risalah Lembaga Penyalur",
              }),
            });
          }
          return true;
        }
        // otherwise, return false to render the field required
        return false;
      }
    )
    .label("Dokumen Risalah Lembaga Penyalur"),
  disbursement_scheme: yup.string().required().label("Skema Penyaluran FDB"),
  disbursement_purpose: yup.string().required("Tujuan Penggunaan wajib diisi"),
  total_fdb: yup.string().required("Jumlah FDB wajib diisi"),
  percentage_per_year: yup.string().required().label("Persentase Pertahun"),
  // enforcement_method: yup
  //   .object()
  //   .shape({
  //     value: yup.string().required(),
  //     label: yup.string().required(),
  //   })
  //   .test("required", "Metode Pemberlakuan wajib diisi", (value, ctx) => {
  //     if (!value) return false;
  //     else if (value !== null && value.value && value.label)
  //       return !!value.value && !!value.label;
  //   })
  //   .nullable(),
  enforcement_method: yup
    .string()
    .required()
    .label("Metode Pemberlakuan wajib diisi"),
  // calculation_method: yup
  //   .object()
  //   .shape({
  //     value: yup.string().required(),
  //     label: yup.string().required(),
  //   })
  //   .test(
  //     "required",
  //     "Metode Perhitungan Tarif wajib diisi",
  //     (value, ctx) => {
  //       if (!value) return false;
  //       else if (value !== null && value.value && value.label)
  //         return !!value.value && !!value.label;
  //     }
  //   )
  //   .nullable(),
  calculation_method: yup
    .string()
    .required()
    .label("Metode Perhitungan Tarif wajib diisi"),
  term_value: yup.string().required().label("Jangka Waktu"),
  term_type: yup.string().required().label("Satuan Waktu"),
  is_grace_period: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi ini"),
  grace_period_value: yup
    .string()
    .notRequired()
    .when("with_grace_period", ([value], schema) =>
      value ? schema.required() : schema
    )
    .label("Jangka Waktu"),
  grace_period_type: yup
    .string()
    .notRequired()
    .when("with_grace_period", ([value], schema) =>
      value ? schema.required() : schema
    )
    .label("Satuan Waktu"),
  is_extend: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .required()
    .label("Opsi ini"),
  // extend_value: yup
  //   .string()
  //   .notRequired()
  //   .when("is_extend", ([value], schema) =>
  //     value ? schema.required() : schema
  //   )
  //   .label("Jangka Waktu"),
  // extend_type: yup
  //   .string()
  //   .notRequired()
  //   .when("extend_time", ([value], schema) =>
  //     value ? schema.required() : schema
  //   )
  //   .label("Satuan Waktu"),
  extend_time_both: yup
    .bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .notRequired()
    .when("is_extend", ([value], schema) =>
      value ? schema.required() : schema
    )
    .label("Opsi ini"),
  collateral: yup.string().required().label("Jaminan"),
  agreement: yup.string().required().label("Perjanjian"),
  disbursement: yup.string().required().label("Pencairan"),
  installment: yup.string().required().label("Pembayaran Angsuran"),
  mandatory: yup.array().of(
    yup.string().required().label("Hak dan Tanggung Jawab Lembaga")
    // yup.string().shape({
    // })
  ),
  support_file_temp: yup
    .mixed()
    .test(
      "cid_img",
      ({ label }) =>
        validationErrorMsg.mixed.required.replace("${path}", label),
      (value, context) => {
        const allowedTypes = [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        if (value) {
          const data = find(allowedTypes, (at) => value.type.includes(at));
          if (!data) {
            return context.createError({
              message: validationCustomErrorMsg.isFiletype("PDF, DOCX")({
                label: "Dokumen Pendukung Risalah",
              }),
            });
          }
          if (value.size > 10 * 1024 * 1024) {
            return context.createError({
              message: validationCustomErrorMsg.isFileSize("10MB")({
                label: "Dokumen Pendukung Risalah",
              }),
            });
          }
          return true;
        }
        // otherwise, return false to render the field required
        return false;
      }
    )
    .label("Dokumen Pendukung Risalah"),
  notes: yup.string().required().label("Catatan Tambahan"),
});

export default validationSchema;
