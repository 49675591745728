import React, { useEffect, useState } from "react";
import {
  HorizontalMenu,
  HorizontalMenuLink,
} from "components/atoms/HorizontalMenu";
import {
  RiArrowLeftLine,
  RiGroupLine,
  RiFundsLine,
  RiTeamLine,
  RiHandCoinLine,
  RiDashboardLine,
} from "react-icons/ri";
import { BsPatchCheck } from "react-icons/bs";
import Logo from "assets/Logo.png";
import Button from "components/atoms/Button/Button";
import { motion } from "framer-motion";
import { useAccessRights } from "components/templates/AuthLayout";

const menus = [
  {
    title: "Dashboard",
    to: "/",
    id: "dashboard",
    icon: () => <RiDashboardLine className="mr-2" />,
  },
  {
    title: "Verifikator",
    to: "/groups",
    icon: () => <BsPatchCheck className="mr-2" />,
    children: [
      {
        title: "Penyaluran Langsung",
        to: "/groups",
        id: "groups_verification",
      },
      {
        title: "Penyaluran Tidak Langsung",
        to: "/institutions/verification",
        id: "institutions_verification",
      },
    ],
  },
  {
    title: "Penetapan Pelaksana",
    to: "/groups",
    icon: () => <RiGroupLine className="mr-2" />,
    children: [
      {
        title: "Penyaluran Tidak Langsung",
        children: [
          {
            title: "Pelaksana Desk Analis",
            to: "/institutions/assign-desk-analyst",
            id: "institutions_assign_desk_analyst",
          },
          {
            title: "Pelaksana Field Analis",
            to: "/institutions/assign-field-analyst",
            id: "institutions_assign_field_analyst",
          },
          {
            title: "Pelaksana Penawaran",
            to: "/institutions/assign-offer",
            id: "institutions_assign_offer",
          },
          {
            title: "Pelaksana Permohonan Pencairan ",
            to: "/institutions/assign-disbursment",
            id: "institutions_assign_disbursment",
          },
        ],
      },
      {
        title: "Penyaluran Langsung",
        children: [
          {
            title: "Pelaksana Desk Analis",
            to: "/desk-analyst",
            id: "assign_desk_analyst",
          },
          {
            title: "Pelaksana Field Analis",
            to: "/assign-field-analyst",
            id: "assign_field_analyst",
          },
          {
            title: "Pelaksana Penawaran",
            to: "/assign-offers",
            id: "assign_offer",
          },
          {
            title: "Pelaksana Pencairan",
            to: "/assign-disbursments",
            id: "assign_disbursment",
          },
        ],
      },
    ],
  },
  {
    title: "Permohonan Pembiayaan",
    to: "/requested-list",
    icon: () => <RiFundsLine className="mr-2" />,
    children: [
      {
        title: "Penyaluran Tidak Langsung",
        children: [
          {
            title: "Desk Analis",
            to: "/institutions/desk-analyst",
            id: "institutions_desk_analyst",
          },
          {
            title: "Field Analis",
            to: "/institutions/field-analyst",
            id: "institutions_field_analyst",
          },
          {
            title: "Risalah",
            to: "/institutions/minutes",
            id: "institutions_minutes",
          },
          {
            title: "Perjanjian",
            to: "/institutions/agreement",
            id: "institutions_aggreement",
          },
          {
            title: "Penawaran",
            to: "/institutions/offers",
            id: "institutions_offers",
          },
        ],
      },
      {
        title: "Penyaluran Langsung",
        children: [
          {
            title: "Desk Analis",
            to: "/member-in-group",
            id: "desk_analyst",
          },
          {
            title: "Field Analis",
            to: "/field-analyst",
            id: "field_analyst",
          },
          {
            title: "Risalah",
            to: "/risalah",
            id: "risalah",
          },
          {
            title: "Penawaran",
            to: "/offer-negotiation",
            id: "offer_negotiation",
          },
          {
            title: "Perjanjian",
            to: "/agreement",
            id: "agreement",
          },
        ],
      },
    ],
  },
  {
    title: "Komite",
    to: "/bast",
    icon: () => <RiTeamLine className="mr-2" />,
    children: [
      {
        title: "Agenda Rapat",
        to: "",
        children: [
          {
            title: "Penyaluran Langsung",
            to: "/committee/meeting-agenda",
            id: "committee_meeting_agenda",
          },
          {
            title: "Penyaluran Tidak Langsung",
            to: "/institutions/meeting-agenda",
            id: "institutions_meeting_agenda",
          },
        ],
      },
      {
        title: "Pendapat Komite",
        to: "",
        children: [
          {
            title: "Penyaluran Langsung",
            to: "/committee/pendapat-komite",
            id: "pendapat_komite",
          },
          {
            title: "Penyaluran Tidak Langsung",
            to: "/institutions/pendapat-komite",
            id: "institutions_pendapat_komite",
          },
        ],
      },
      {
        title: "Berita Acara Komite",
        to: "",
        children: [
          {
            title: "Penyaluran Langsung",
            to: "/member-in-group-ba",
            id: "member_in_group_ba",
          },
          {
            title: "Penyaluran Tidak Langsung",
            to: "/",
            children: [
              {
                title: "Komite Teknis",
                to: "/institutions/technical-committee",
                id: "institutions_technical_committee",
              },
              {
                title: "Komite Direksi",
                to: "/institutions/director-committee",
                id: "institutions_director_committee",
              },
            ],
          },
        ],
      },
      {
        title: "Persetujuan Permohonan Pembiayaan",
        to: "",
        children: [
          {
            title: "Penyaluran Langsung",
            to: "/committee/approval",
            id: "committee_approval",
          },
          {
            title: "Penyaluran Tidak Langsung",
            to: "/institutions/approval",
            id: "institutions_approval",
          },
        ],
      },
    ],
  },
  {
    title: "Permohonan Pencairan",
    to: "/groups",
    icon: () => <RiHandCoinLine className="mr-2" />,
    children: [
      {
        title: "Penyaluran Langsung",
        to: "/",
        children: [
          {
            title: "Review Pencairan",
            to: "/member-in-group-disburstment/review",
            id: "group_disburstment",
          },
          {
            title: "Persetujuan Pencairan",
            to: "/member-in-group-disburstment/agreement",
            id: "group_disburstment",
          },
        ],
      },
      {
        title: "Penyaluran Tidak Langsung",
        to: "/",
        children: [
          {
            title: "Review Pencairan",
            to: "/institutions-disbursement/review",
            id: "institutions_disbursement",
          },
          {
            title: "Persetujuan Pencairan",
            to: "/institutions-disbursement/agreement",
            id: "institutions_disbursement",
          },
        ],
      },
    ],
  },
];

const Sidebar = ({ open, onMenuClick }) => {
  const { userAccessRights } = useAccessRights();

  const [menuToShow, setMenuToShow] = useState([]);

  useEffect(() => {
    const temp = [...menus];

    const updateAccessAndVisibility = (array) => {
      array.forEach((element) => {
        // Check if the element has children and recurse
        if (element?.children?.length > 0) {
          updateAccessAndVisibility(element.children);

          // If any child has access, mark this element as having access
          if (element.children.some((child) => child.hasAccess)) {
            element["hasAccess"] = true;
          }

          // If any child should be shown, mark this element as showable
          if (element.children.some((child) => child.show)) {
            element["show"] = true;
          }
        } else {
          // Mark access if user has rights
          if (userAccessRights?.[element?.id]?.canView) {
            element["hasAccess"] = true;
          }

          // Mark element as showable if it has access
          if (element.hasAccess) {
            element["show"] = true;
          }
        }
      });
    };

    // Run the recursive function
    updateAccessAndVisibility(temp);

    setMenuToShow(temp);
  }, [userAccessRights]);

  const createSidebarMenu = (menu, key) => {
    if (menu?.children && menu?.children?.length > 0) {
      if (menu?.show || menu?.hasAccess) {
        return (
          <HorizontalMenu
            key={key}
            title={menu.title}
            icon={menu.icon}
            to={menu.to}
          >
            {menu.children.map((child, idx) => {
              return createSidebarMenu(child, `children-menu-${idx}`);
            })}
          </HorizontalMenu>
        );
      }
    } else {
      if (menu?.show || menu?.hasAccess) {
        return (
          <HorizontalMenuLink key={key} to={menu.to} icon={menu.icon}>
            {menu.title}
          </HorizontalMenuLink>
        );
      }
    }
  };
  return (
    <>
      <aside
        className={[
          "w-10/12 md:w-[312px] shrink-0 min-h-screen bg-gray-900 py-3 transform transition-all duration-300 z-50",
          open
            ? "fixed lg:sticky top-0 translate-x-0"
            : "fixed -translate-x-[120%]",
        ].join(" ")}
      >
        <Button
          className="absolute -right-5 top-10 !bg-gray-700 !border-gray-700 !text-gray-300 w-[44px] h-[44px] rounded-lg !p-0"
          label={
            <>
              <RiArrowLeftLine className="text-2xl" />
            </>
          }
          onClick={onMenuClick}
        />

        <div className="py-2 flex justify-center items-center mb-4">
          <img src={Logo} alt="Logo" />
        </div>

        <p className="flex justify-center text-xs py-3 bg-gray-700 text-gray-300 font-semibold mb-4">
          Kodiv Penyaluran
        </p>

        <div className="px-4">
          {menuToShow.map((menu, idx) => {
            return createSidebarMenu(menu, `menu-${idx}`);
          })}
        </div>
      </aside>

      {/* overlay */}
      {open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: "spring", duration: 0.5 }}
          className="fixed lg:hidden inset-0 bg-black bg-opacity-50 z-40"
          onClick={onMenuClick}
        />
      )}
    </>
  );
};

export default Sidebar;
