import Pagino from "pagino";
import React from "react";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import styles from "./index.module.css";

function TablePagination({
  page,
  totalPage,
  onChange,
  showFirst = false,
  showLast = false,
  showNext = true,
  showPrevious = true,
  hideLabel = false,
  wrapperClass
}) {
  const pagination = new Pagino({
    showFirst,
    showLast,
    showNext,
    showPrevious,
    page: page || 1,
    count: totalPage,
    boundaryCount: 1,
    siblingCount: 0,
    onChange: (page, count) => {
      if (typeof onChange === "function") {
        onChange(page);
      }
    }
  });
  
  const onClick = (key) => () => {
    switch (key) {
      case "first":
        pagination.first();
        break;
      case "last":
        pagination.last();
        break;
      case "previous":
        pagination.previous();
        break;
      case "next":
        pagination.next();
        break;
      case "end-ellipsis":
        break;
      default:
        pagination.setPage(parseFloat(key));
    }
  };
  
  return (
    <div className={[styles["tb-pagination"], wrapperClass || ""].join(" ")}>
      {
        pagination.getPages()?.map((e, index) => {
          if (e === "first") {
            return (<button key={e} disabled={page === 1} onClick={onClick(e)}>{e}</button>);
          } else if (e === "last") {
            return (<button key={e} disabled={page === totalPage} onClick={onClick(e)}>{e}</button>);
          } else if (e === "previous") {
            return (<button key={e} className={'space-x-2'} disabled={page === 1} onClick={onClick(e)}>
              <RiArrowLeftLine className={'inline-block align-middle w-5 h-5'}/>
              {!hideLabel && (
              <span className={'align-middle'}>{e}</span>
              )}
            </button>);
          } else if (e === "next") {
            return (<button key={e} className={'space-x-2'} disabled={page === totalPage} onClick={onClick(e)}>
              {!hideLabel && (
              <span className={'align-middle'}>{e}</span>
              )}
              <RiArrowRightLine className={'inline-block align-middle w-5 h-5'}/>
            </button>);
          } else if (["end-ellipsis", "start-ellipsis"].includes(e)) {
            return (<button key={e} disabled={page === e} onClick={onClick(e)}>...</button>);
          } else {
            return (<button key={e} disabled={page === e} onClick={onClick(e)}>{e}</button>);
          }
        })
      }
    </div>
  );
}

export default TablePagination;