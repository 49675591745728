import CardForm from "components/molecules/CardForm";
import { LayoutGroup } from "framer-motion";
import React from "react";

import { CardPrimary } from "components/atoms/Card";
import {
  RiCheckFill,
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiCloseFill,
} from "react-icons/ri";
import clsx from "clsx";

const FinancedBusiness = ({ data }) => {
  return (
    <LayoutGroup>
      <CardForm label="Usaha Yang Dibiayai">
        <FeasibilityAssessment data={data} />
        <FinancedBusinessProspects data={data} />
        <ReturnCapability data={data} />
      </CardForm>
    </LayoutGroup>
  );
};

const VerifiedValue = ({ value }) => {
  return (
    <p
      className={clsx(
        "p-4 border rounded-lg border-primary-600 bg-primary-50 font-medium text-sm flex gap-2 items-center",
        !value && "!bg-error-50 !border-error-600"
      )}
    >
      {value ? (
        <span className="text-primary-600">
          <RiCheckboxCircleFill size={20} />
        </span>
      ) : (
        <span className="text-error-600">
          <RiCloseCircleFill size={20} />
        </span>
      )}
      {value ? "YA" : "TIDAK"}
    </p>
  );
};

const FeasibilityAssessment = ({ data }) => {
  return (
    <>
      <CardPrimary label="Penilaian Kelayakan">
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5">
          <div className="flex flex-col gap-2">
            <p className="font-semibold">Terverifikasi keberadaannya</p>
            <VerifiedValue value={data?.existence_verified} />
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-semibold">Dalam usia produktif</p>
            {/* <p>{data?.productive_age ? "YA" : "Tidak"}</p> */}
            <VerifiedValue value={data?.productive_age} />
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-semibold">Menjadi anggota kelompok</p>
            {/* <p>{data?.group_member ? "YA" : "TIDAK"}</p> */}
            <VerifiedValue value={data?.group_member} />
          </div>
        </div>
      </CardPrimary>
    </>
  );
};

const FinancedBusinessProspects = ({ data }) => {
  return (
    <>
      <CardPrimary label="Prospek Usaha Yang Dibiayai">
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
          <div className="flex flex-col gap-2">
            <p className="font-semibold">Lama Usaha {">"} 2 Tahun</p>
            <VerifiedValue value={data?.two_year_business} />
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-semibold">Siklus Usaha</p>
            <p>{data?.business_cycle}</p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-semibold">Harga beli </p>
            <p>
              Rp. {Math.floor(data?.prospect_purchased_price).toLocaleString()}
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-semibold">Kebutuhan Modal</p>
            <p>Rp. {Math.floor(data?.capital_requirement).toLocaleString()}</p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-semibold">Penjualan/Omzet</p>
            <p>Rp. {Math.floor(data?.omzet).toLocaleString()}</p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-semibold">Pendapatan Net Selama Siklus Usaha</p>
            <p>Rp. {Math.floor(data?.net_income).toLocaleString()}</p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-semibold">Usaha Menghasilkan Keuntungan</p>
            <p>{data?.business_profit}</p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-semibold">
              Rekomendasi jangka waktu pembiayaan Sesuai siklus usaha
            </p>
            <p>{data?.recomend_application}</p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-semibold">Skema pembiayaan</p>
            <p>{data?.finance_scheme}</p>
          </div>
        </div>
      </CardPrimary>
    </>
  );
};

const ReturnCapability = ({ data }) => {
  return (
    <>
      <CardPrimary label="Kemampuan Pengembalian">
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
          <div>
            <p className="font-semibold">Jenis Usaha Yang Tidak Dibiayai</p>
            <p>{data?.non_funded_business_category}</p>
          </div>
          <div>
            <p className="font-semibold">
              Total Pendapatan Net Usaha Yang Tidak Di biayai
            </p>
            <p>Rp. {Math.floor(data?.total_income).toLocaleString()}</p>
          </div>
          <div>
            <p className="font-semibold">Total Seluruh Pendapatan</p>
            <p>Rp. {Math.floor(data?.total_all_income).toLocaleString()}</p>
          </div>
          <div>
            <p className="font-semibold">Perkiraan Pengeluaran Rutin/Tahun</p>
            <p>
              Rp. {Math.floor(data?.outcome_per_year).toLocaleString()}/Tahun
            </p>
          </div>
          <div>
            <p className="font-semibold">Maksimal Berkemampuan Bayar</p>
            {/* <p>Rp 40,000,000</p> */}
            <p>Rp. {Math.floor(data?.max_pay).toLocaleString()}</p>
          </div>
          <div>
            <p className="font-semibold">Rekomendasi Nilai Pinjaman</p>
            <p>
              Rp. {Math.floor(data?.loan_value_recomendation).toLocaleString()}
            </p>
          </div>
        </div>
      </CardPrimary>
    </>
  );
};

export default FinancedBusiness;
