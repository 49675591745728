/* eslint-disable no-template-curly-in-string */
import { validationCustomErrorMsg, validationErrorMsg } from "locale/yup_id";
import { find } from "lodash";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  non_funded_business_category: Yup.string().required().label("Kategori Usaha"),
  bussiness_cycle: Yup.string().when(
    "non_funded_business_category",
    ([value], schema) => {
      if (value === "store" || value === "herdsman" || value === "agroforestry")
        return schema.required().label("Siklus Usaha");

      return schema.notRequired();
    }
  ),
  income_per_month: Yup.string().when(
    "non_funded_business_category",
    ([value], schema) => {
      if (value === "employee") {
        return schema.required().label("Pendapatan Perbulan");
      }
      return schema.notRequired();
    }
  ),
  income: Yup.string().when(
    "non_funded_business_category",
    ([value], schema) => {
      if (value === "store") {
        return schema.required().label("Pendapatan");
      }
      return schema.notRequired();
    }
  ),
  selling_price: Yup.string().when(
    "non_funded_business_category",
    ([value], schema) => {
      if (value === "store") {
        return schema.required().label("Harga Penjualan");
      }
      return schema.notRequired();
    }
  ),
  hpp: Yup.string().when(
    "non_funded_business_category",
    ([value], schema) => {
      if (value === "store") {
        return schema.required().label("HPP");
      }
      return schema.notRequired();
    }
  ),
  business_longitude: Yup.string().required().label("Longitude"),
  business_province_id: Yup.string().required().label("Provinsi"),
  business_city_id: Yup.string().required().label("Kota/Kabupaten"),
  business_district_id: Yup.string().required().label("Kecamatan"),
  business_village_id: Yup.string().required().label("Kelurahan/Desa"),
  business_latitude: Yup.string().required().label("Latitude"),
  business_address: Yup.string().required().label("Alamat Lengkap Usaha"),
  business_photo_file_temp: Yup.mixed()
    .test(
      "cid_img",
      ({ label }) =>
        validationErrorMsg.mixed.required.replace("${path}", label),
      (value, context) => {
        const allowedTypes = ["jpg", "png", "jpeg"];
        if (value) {
          const data = find(allowedTypes, (at) => value.type.includes(at));
          if (!data) {
            return context.createError({
              message: validationCustomErrorMsg.isFiletype(
                allowedTypes.join(", ")
              )({ label: "Foto Usaha Debitur" }),
            });
          }
          if (value.size > 10 * 1024 * 1024) {
            return context.createError({
              message: validationCustomErrorMsg.isFileSize("10MB")({
                label: "Foto Usaha Debitur",
              }),
            });
          }

          return true;
        }

        // otherwise, return false to render the field required
        return false;
      }
    )
    .required()
    .label("Foto Usaha Debitur"),
  maintenance_pattern: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .when("non_funded_business_category", ([value], schema) => {
      if (value === "herdsman" || value === "agroforestry") {
        return schema.required().label("Pola Pemeliharaan");
      }
      return schema.notRequired();
    }),
  sales_pattern: Yup.bool()
    .transform((originalValue) =>
      originalValue === "" ? undefined : originalValue
    )
    .when("non_funded_business_category", ([value], schema) => {
      if (value === "herdsman" || value === "agroforestry") {
        return schema.required().label("Pola Penjualan");
      }
      return schema.notRequired();
    }),
  maintenance_purchase_price: Yup.string().when(
    "maintenance_pattern",
    ([value], schema) => {
      if (value === true) {
        return schema.required().label("Harga Beli");
      }
      return schema.notRequired();
    }
  ),
  maintenance_selling_price: Yup.string().when(
    "maintenance_pattern",
    ([value], schema) => {
      if (value === true) {
        return schema.required().label("Harga Siap Jual");
      }
      return schema.notRequired();
    }
  ),
  maintenance_cost: Yup.string().when(
    "maintenance_pattern",
    ([value], schema) => {
      if (value === true) {
        return schema.required().label("Biaya Pemeliharaan");
      }
      return schema.notRequired();
    }
  ),
  maintenance_revenue: Yup.string().when(
    "maintenance_pattern",
    ([value], schema) => {
      if (value === true) {
        return schema.required().label("Pendapatan Pemeliharaan");
      }
      return schema.notRequired();
    }
  ),
  sales_modal: Yup.string().when("sales_pattern", ([value], schema) => {
    if (value === true) {
      return schema.required().label("Modal");
    }
    return schema.notRequired();
  }),
  sales_selling_price: Yup.string().when("sales_pattern", ([value], schema) => {
    if (value === true) {
      return schema.required().label("Harga Jual");
    }
    return schema.notRequired();
  }),
  sales_expenses: Yup.string().when("sales_pattern", ([value], schema) => {
    if (value === true) {
      return schema.required().label("Biaya Penjualan");
    }
    return schema.notRequired();
  }),
  sales_revenue: Yup.string().when("sales_pattern", ([value], schema) => {
    if (value === true) {
      return schema.required().label("Pendapatan Penjualan");
    }
    return schema.notRequired();
  }),
});
