import React, { useRef } from "react";
import { useFormContext, Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import { motion as m } from "framer-motion";

const Editor = {
  modules: {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      [{ align: [] }],
      // ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  },
  formats: [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ],
};

const RichEditorForm = ({
  name,
  placeholder = "",
  label = "",
  required,
  className,
  hideToolbar = false,
  ...props
}) => {
  const { control } = useFormContext();
  const quillRef = useRef(null);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => {
        return (
          <m.div
            layout
            className={["form-control w-full space-y-1", className].join(" ")}
          >
            <label className="label font-semibold text-[14px]">
              <span className={`label-text`}>{label}</span>
              {required && <span className="text-[#F04438]">*</span>}
            </label>
            <ReactQuill
              theme="snow"
              {...props}
              ref={quillRef}
              placeholder={placeholder}
              value={value}
              modules={
                hideToolbar
                  ? { ...Editor.modules, toolbar: false }
                  : Editor.modules
              }
              formats={Editor.formats}
              onChange={(text) => {
                onChange(text);
              }}
              className={invalid ? "has-error" : ""}
            />
            {invalid && (
              <div className="text-xs text-error-600 mt-2">
                {error?.message}
              </div>
            )}
          </m.div>
        );
      }}
    />
  );
};

export default RichEditorForm;
