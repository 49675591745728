import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getAgreements = ({ queryKey }) => {
  return axiosInstance.get(`${general_config_v2.BASE_URL}/v1/cms/agreement`, {
    params: queryKey[1],
  });
};

export const getAgreementDetail = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agreement/${queryKey[1]}`
  );
};

export const putAgreement = ({ id, data }) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/agreement/update-status/${id}`,
    data
  );
};
