import clsx from "clsx";
import { InputForm } from "components";
import CardForm from "components/molecules/CardForm";
import { formatCurrency } from "helpers";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import angkaTerbilang from "@develoka/angka-terbilang-js";
import { camelCase, startCase } from "lodash";
import AtomSelect from "components/atoms/Select";
import { useEffect } from "react";

const UnitOptions = [
  { value: "Minggu", label: "Minggu" },
  { value: "Bulan", label: "Bulan" },
  { value: "Tahun", label: "Tahun" },
];

const InputRadio = ({ controllerName, values, customOnChange, disabled }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={controllerName}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => (
        <div className="space-y-2">
          <div className="flex space-x-4">
            {values.map((val, key) => (
              <label
                key={`${controllerName}-${val.value}`}
                className={`flex items-center gap-2 ${
                  value === val.value ? "font-semibold" : ""
                }`}
              >
                <input
                  type="radio"
                  name={controllerName}
                  onChange={() => {
                    onChange(val.value);
                    if (customOnChange) customOnChange(val.value);
                  }}
                  checked={value === val.value}
                  className="absolute opacity-0 w-0 h-0"
                  disabled={disabled}
                />
                <div
                  className="radio-custom"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "18px",
                    minHeight: "18px",
                    aspectRatio: 1,
                    flexShrink: 0,
                    borderRadius: "50%",
                    border: invalid
                      ? "2px solid #F04438"
                      : value === val.value
                      ? "2px solid #01A24A"
                      : "1px solid #D0D5DD",
                    backgroundColor: value === val.value ? "#FFFFFF" : "",
                  }}
                >
                  {value === val.value && (
                    <div
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor: "#01A24A",
                      }}
                    />
                  )}
                </div>
                {val.label}
              </label>
            ))}
          </div>
          {invalid && (
            <label className="label">
              <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
                {error?.message}
              </div>
            </label>
          )}
        </div>
      )}
    />
  );
};

const SectionForm = ({ label, prefix, request, desk_analyst }) => {
  const methods = useFormContext();
  const { control, setValue, resetField } = methods;

  const application_value_radio = useWatch({
    control,
    name: `${prefix}.application_value_radio`,
  });

  const application_value_temp = useWatch({
    control,
    name: `${prefix}.application_value_temp`,
  });

  const category = useWatch({
    control,
    name: `${prefix}.category`,
  });

  const onChangeCategory = (value) => {
    resetField("application_details.field_analyst.application_value", {
      defaultValue: "",
    });
    resetField("application_details.field_analyst.application_spell", {
      defaultValue: "",
    });
    resetField("application_details.field_analyst.installment_value", {
      defaultValue: "",
    });
    resetField("application_details.field_analyst.installment_spell", {
      defaultValue: "",
    });
    resetField("application_details.field_analyst.period_value", {
      defaultValue: "",
    });
    resetField("application_details.field_analyst.period_type", {
      defaultValue: "",
    });
    resetField("application_details.field_analyst.application_value_radio", {
      defaultValue: "",
    });
    resetField("application_details.field_analyst.application_value_temp", {
      defaultValue: "",
    });

    if (value === "application" && request) {
      setValue(
        "application_details.field_analyst.application_value",
        request?.application_value
      );
      setValue(
        "application_details.field_analyst.application_spell",
        request?.application_spell
      );
      setValue(
        "application_details.field_analyst.installment_value",
        request?.installment_value
      );
      setValue(
        "application_details.field_analyst.installment_spell",
        request?.installment_spell
      );
      setValue(
        "application_details.field_analyst.period_value",
        request?.period_value
      );
      setValue(
        "application_details.field_analyst.period_type",
        request?.period_type
      );
    } else if (value === "desk_analyst" && desk_analyst) {
      if (desk_analyst?.loan_value_recomendation) {
        setValue(
          "application_details.field_analyst.application_value",
          desk_analyst?.loan_value_recomendation
        );
      }
      if (desk_analyst?.loan_value_recomendation_spell) {
        setValue(
          "application_details.field_analyst.application_spell",
          desk_analyst?.loan_value_recomendation_spell
        );
      }
      if (desk_analyst?.installment_value) {
        setValue(
          "application_details.field_analyst.installment_value",
          desk_analyst?.installment_value
        );
      }
      if (desk_analyst?.installment_spell) {
        setValue(
          "application_details.field_analyst.installment_spell",
          desk_analyst?.installment_spell
        );
      }
      if (desk_analyst?.time_recomendation_periode_value) {
        setValue(
          "application_details.field_analyst.period_value",
          desk_analyst?.time_recomendation_periode_value
        );
      }
      if (desk_analyst?.time_recomendation_periode_type) {
        setValue(
          "application_details.field_analyst.period_type",
          desk_analyst?.time_recomendation_periode_type
        );
      }
    }
  };

  return (
    <div className="space-y-5">
      <p className="font-semibold text-lg text-primary-maincolor-700">
        {label}
      </p>
      {prefix === "application_details.field_analyst" && (
        <InputRadio
          controllerName={`${prefix}.category`}
          values={[
            {
              label: "Sesuai Permohonan",
              value: "application",
            },
            {
              label: "Sesuai Desk",
              value: "desk_analyst",
            },
            {
              label: "Rekomendasi Field Analyst",
              value: "field_analyst",
            },
          ]}
          customOnChange={onChangeCategory}
        />
      )}
      {prefix !== "application_details.field_analyst" && (
        <div className="flex space-x-5">
          <InputForm
            label={
              prefix === "application_details.desk_analyst"
                ? "Nominal Rekomendasi Desk Analyst"
                : "Nominal Pengajuan"
            }
            prefix="Rp"
            controllerName={`${prefix}.application_value`}
            disabled
            hidden={prefix === "application_details.field_analyst"}
            isNumber={true}
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
            className="w-full"
          />
          <InputForm
            label={"Terbilang"}
            controllerName={`${prefix}.application_spell`}
            disabled
            className={clsx("w-full rounded-md border p-3 !font-normal")}
          />
        </div>
      )}
      {prefix === "application_details.field_analyst" && (
        <>
          <InputRadio
            controllerName={`${prefix}.application_value_radio`}
            disabled={
              prefix !== "application_details.field_analyst" ||
              category !== "field_analyst"
            }
            customOnChange={(value) => {
              resetField(
                "application_details.field_analyst.application_value",
                { defaultValue: "" }
              );
              resetField(
                "application_details.field_analyst.application_spell",
                { defaultValue: "" }
              );

              if (value !== "other") {
                methods.setValue(`${prefix}.application_value`, value);
                methods.setValue(
                  `${prefix}.application_spell`,
                  `${startCase(
                    camelCase(angkaTerbilang(Number(value)))
                  )} Rupiah`
                );
              } else if (value === "other" && application_value_temp) {
                methods.setValue(
                  `${prefix}.application_value`,
                  application_value_temp
                );
                methods.setValue(
                  `${prefix}.application_spell`,
                  `${startCase(
                    camelCase(angkaTerbilang(Number(application_value_temp)))
                  )} Rupiah`
                );
              }
            }}
            values={[
              {
                label: <>{formatCurrency(500000)}</>,
                value: 500000,
              },
              {
                label: <>{formatCurrency(1000000)}</>,
                value: 1000000,
              },
              {
                label: (
                  <div className="flex gap-2 items-center">
                    Lainnya
                    <InputForm
                      label={""}
                      prefix="Rp"
                      controllerName={`${prefix}.application_value_temp`}
                      onChangeInput={(val) => {
                        methods.setValue(
                          `${prefix}.application_value`,
                          val.target.value
                        );
                        methods.setValue(
                          `${prefix}.application_spell`,
                          `${startCase(
                            camelCase(angkaTerbilang(Number(val.target.value)))
                          )} Rupiah`
                        );
                      }}
                      disabled={application_value_radio !== "other"}
                      className={clsx(
                        "w-full rounded-md border p-3 !font-normal",
                        {
                          disabled: application_value_radio !== "other",
                        }
                      )}
                      isNumber={true}
                      onKeyPress={(event) => {
                        const ASCIICode = event.which
                          ? event.which
                          : event.keyCode;
                        if (
                          ASCIICode === 44 ||
                          (ASCIICode >= 48 && ASCIICode <= 57)
                        )
                          return;
                        event.preventDefault();
                      }}
                    />
                  </div>
                ),
                value: "other",
              },
            ]}
          />
          <InputForm
            label={"Terbilang"}
            controllerName={`${prefix}.application_spell`}
            disabled
            className={clsx("w-full rounded-md border p-3 !font-normal")}
          />
        </>
      )}
      <div className="flex space-x-5">
        <div className="flex flex-col gap-1 w-full">
          <span className="label-text font-semibold text-[14px] mb-1">Jangka Waktu</span>
          <InputForm
            label={""}
            controllerName={`${prefix}.period_value`}
            disabled={
              prefix !== "application_details.field_analyst" ||
              category !== "field_analyst"
            }
            className={clsx("w-full rounded-md border p-3 !font-normal")}
            isNumber={true}
            onKeyPress={(event) => {
              const ASCIICode = event.which ? event.which : event.keyCode;
              if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
                return;
              event.preventDefault();
            }}
          />
        </div>
        <AtomSelect
          label={<div className="text-transparent">-</div>}
          controllerName={`${prefix}.period_type`}
          options={UnitOptions}
          returnObject={false}
          disabled={
            prefix !== "application_details.field_analyst" ||
            category !== "field_analyst"
          }
        />
      </div>
      <div className="flex space-x-5">
        <InputForm
          label={"Nominal Angsuran"}
          controllerName={`${prefix}.installment_value`}
          disabled={
            prefix !== "application_details.field_analyst" ||
            category !== "field_analyst"
          }
          className={clsx("w-full rounded-md border p-3 !font-normal")}
          prefix="Rp"
          isNumber={true}
          onKeyPress={(event) => {
            const ASCIICode = event.which ? event.which : event.keyCode;
            if (ASCIICode === 44 || (ASCIICode >= 48 && ASCIICode <= 57))
              return;
            event.preventDefault();
          }}
          onChangeInput={(val) => {
            if (!isNaN(Number(val.target.value))) {
              methods.setValue(
                `${prefix}.installment_spell`,
                `${startCase(
                  camelCase(angkaTerbilang(Number(val.target.value)))
                )} Rupiah`
              );
            }
          }}
        />
        <InputForm
          label={"Terbilang"}
          controllerName={`${prefix}.installment_spell`}
          disabled
          className={clsx("w-full rounded-md border p-3 !font-normal")}
        />
      </div>
    </div>
  );
};

const Request = ({ data }) => {
  const { setValue } = useFormContext();
  const { request } = data;

  useEffect(() => {
    if (request?.social_forestry_applications) {
      setValue(
        "application_details.application.application_value",
        request?.social_forestry_applications?.application_value
      );
      setValue(
        "application_details.application.application_spell",
        request?.social_forestry_applications?.application_spell
      );
      setValue(
        "application_details.application.installment_value",
        request?.social_forestry_applications?.installment_value
      );
      setValue(
        "application_details.application.installment_spell",
        request?.social_forestry_applications?.installment_spell
      );
      setValue(
        "application_details.application.period_value",
        request?.social_forestry_applications?.period_value
      );
      setValue(
        "application_details.application.period_type",
        request?.social_forestry_applications?.period_type
      );
    }
  }, [request?.social_forestry_applications, setValue]);

  useEffect(() => {
    if (request?.desk_analysts?.loan_value_recomendation) {
      setValue(
        "application_details.desk_analyst.application_value",
        request?.desk_analysts?.loan_value_recomendation
      );
    }
    if (request?.desk_analysts?.loan_value_recomendation_spell) {
      setValue(
        "application_details.desk_analyst.application_spell",
        request?.desk_analysts?.loan_value_recomendation_spell
      );
    }
    if (request?.desk_analysts?.installment_value) {
      setValue(
        "application_details.desk_analyst.installment_value",
        request?.desk_analysts?.installment_value
      );
    }
    if (request?.desk_analysts?.installment_spell) {
      setValue(
        "application_details.desk_analyst.installment_spell",
        request?.desk_analysts?.installment_spell
      );
    }
    if (request?.desk_analysts?.time_recomendation_periode_value) {
      setValue(
        "application_details.desk_analyst.period_value",
        request?.desk_analysts?.time_recomendation_periode_value
      );
    }
    if (request?.desk_analysts?.time_recomendation_periode_type) {
      setValue(
        "application_details.desk_analyst.period_type",
        request?.desk_analysts?.time_recomendation_periode_type
      );
    }
  }, [request?.desk_analysts, setValue]);

  useEffect(() => {
    if (request?.field_analysts?.application_details?.application_value) {
      setValue(
        "application_details.field_analyst.application_value",
        request?.field_analysts?.application_details?.application_value
      );
    }
    if (request?.field_analysts?.application_details?.type) {
      if (
        request?.field_analysts?.application_details?.type === "field_analyst"
      ) {
        if (
          request?.field_analysts?.application_details?.application_value !==
            500000 &&
          request?.field_analysts?.application_details?.application_value !==
            1000000
        ) {
          setValue(
            "application_details.field_analyst.application_value_radio",
            "other"
          );
          setValue(
            "application_details.field_analyst.application_value_temp",
            request?.field_analysts?.application_details?.application_value
          );
        } else {
          setValue(
            "application_details.field_analyst.application_value_radio",
            request?.field_analysts?.application_details?.application_value
          );
        }
        setValue(
          "application_details.field_analyst.category",
          request?.field_analysts?.application_details?.type
        );
      }
      setValue(
        "application_details.field_analyst.category",
        request?.field_analysts?.application_details?.type
      );
    }
    if (request?.field_analysts?.application_details?.application_spell) {
      setValue(
        "application_details.field_analyst.application_spell",
        request?.field_analysts?.application_details?.application_spell
      );
    }
    if (request?.field_analysts?.application_details?.installment_value) {
      setValue(
        "application_details.field_analyst.installment_value",
        request?.field_analysts?.application_details?.installment_value
      );
    }
    if (request?.field_analysts?.application_details?.installment_spell) {
      setValue(
        "application_details.field_analyst.installment_spell",
        request?.field_analysts?.application_details?.installment_spell
      );
    }
    if (request?.field_analysts?.application_details?.period_value) {
      setValue(
        "application_details.field_analyst.period_value",
        request?.field_analysts?.application_details?.period_value
      );
    }
    if (request?.field_analysts?.application_details?.period_type) {
      setValue(
        "application_details.field_analyst.period_type",
        request?.field_analysts?.application_details?.period_type
      );
    }
  }, [request?.field_analysts?.application_details, setValue]);

  return (
    <CardForm label="Detail Permohonan">
      <div className="space-y-5">
        <SectionForm
          label="Nominal Pengajuan"
          prefix={"application_details.application"}
        />
        <SectionForm
          label="Nominal Rekomendasi Desk Analyst"
          prefix={"application_details.desk_analyst"}
        />
        <SectionForm
          label="Nominal Penyesuaian Field Analyst"
          prefix={"application_details.field_analyst"}
          request={request?.social_forestry_applications}
          desk_analyst={request?.desk_analysts}
        />
      </div>
    </CardForm>
  );
};

export default Request;
