import { fileUpload, getFile } from "services/fileService";

const withFileService = (Component) => {
  return (props) => {
    const getFileFromService = async (id, { onSuccess, onError }) => {
      try {
        const response = await getFile(id);
        onSuccess(response.data.data);
      } catch (e) {
        onError(e);
      }
    };

    const uploadFile = async (file, { onSuccess, onError }) => {
      try {
        const formData = new FormData();

        formData.append("file", file);

        const response = await fileUpload(formData);
        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    };

    return (
      <Component
        getFileFromService={getFileFromService}
        uploadFile={uploadFile}
        {...props}
      />
    );
  };
};

export default withFileService;
