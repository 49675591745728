import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";

import { InputForm, InputFormRadio } from "components";
import CardForm from "components/molecules/CardForm";
import UploadedFileAsync from "components/molecules/UploadFileAsync";

const FinanceDocument = ({ data, showVerification = true }) => {
  const methods = useFormContext();

  const isVerified = useWatch({
    name: "financing_document",
    control: methods.control,
  });

  return (
    <CardForm label="Dokumen Keuangan">
      <UploadedFileAsync
        id={data?.documents}
        name="Laporan Keuangan 3 Tahun Terakhir"
      />
      {showVerification && (
        <div className="border rounded-md px-4 py-3">
          <InputFormRadio
            label={"Hasil Verifikasi"}
            controllerName={`financing_document`}
            className="flex items-center justify-between gap-4"
            values={[
              {
                label: (
                  <>
                    <RiCheckboxCircleFill className="text-green-500 text-lg shrink-0" />
                    Data Terverifikasi
                  </>
                ),
                value: true,
              },
              {
                label: (
                  <>
                    <RiCloseCircleFill className="text-red-500 text-lg shrink-0" />
                    Data Belum Sesuai/ Ada Kesalahan
                  </>
                ),
                value: false,
              },
            ]}
          />
          {typeof isVerified !== "undefined" && isVerified === false && (
            <InputForm
              controllerName="financing_document_desc"
              label="jelaskan kesalahan/ ketidak sesuaian datanya"
              placeholder="jelaskan kesalahan/ ketidak sesuaian datanya"
              required={true}
              wrapperClass="col-span-2 mt-3"
              className="rounded-md w-full px-4 py-3 border"
              textArea
            />
          )}
        </div>
      )}
    </CardForm>
  );
};

export default FinanceDocument;
