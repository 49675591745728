import React from "react";
import InstitutionProfile from "../../../Components/InstitutionProfile";
import OrganisationStructure from "../../../Components/OrganisationStructure";
import LegalityDocument from "../../../Components/LegalityDocument";
import FinanceDocument from "./FinanceDocument";
import RequestDebtor from "./RequestDebtor";
import Guarantee from "./Guarantee";
import { TabsContent } from "components/atoms/Tabs";
import CardForm from "components/molecules/CardForm";
import UploadedFileAsync from "components/molecules/UploadFileAsync";
import Products from "./Products";
import FinancedBussiness from "./FinancedBussiness";
import CapacityAnalysist from "./CapacityAnalysist";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { BottomFormAction } from "components";
import dayjs from "dayjs";

const validationSchema = yup.object({});

const RequestInformation = ({
  data,
  onBack,
  onSubmit,
  changePageCollateral,
  changeLimitCollateral,
  changePagePotention,
  changeLimitPotention,
  filterTable,
}) => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleBack = () => {
    const values = methods.getValues();
    onBack(values);
  };

  const handleDraftButton = () => {
    console.log("draft");
  };

  const handleSubmit = (payload) => {
    onSubmit(payload);
  };

  const renderClasification = (val) => {
    // TODO: WHEN TYPE FIXED
    // switch (val) {
    //   case "small_bussines":
    //     return "Usaha Kecil (rencana penyaluran FDB < Rp. 5 Miliar)";
    //   case "middle_bussines":
    //     return "Usaha Besar (rencana penyaluran FDB > Rp. 20 Miliar)";
    //   case "high_bussines":
    //     return "Usaha Menengah (rencana penyaluran FDB  Rp. 5 - 20 Miliar)";
    //   default:
    // }
    // if (type === "bank-ventura") {
    // }

    switch (val) {
      case "small_bussines":
        return "Bank Buku 1 (< 1 Triliun)";
      case "middle_bussines":
        return "Bank Buku 2 (1-5 Triliun)";
      case "high_bussines":
        return "Bank Buku 4 (>10 Triliun)";
      default:
        return "ank Buku 4 (>10 Triliun)";
    }
  };

  return (
    <TabsContent value="information" className="mb-40">
      <FormProvider {...methods}>
        <div className="space-y-6">
          <InstitutionProfile data={data?.general} showVerification={false} />
          <OrganisationStructure
            data={{
              documents: data?.organization_structure?.file || undefined,
            }}
            showVerification={false}
          />
          <LegalityDocument
            data={{ documents: data?.legality_documents }}
            showVerification={false}
          />
          <RequestDebtor
            data={data?.application}
            changePagePotention={changePagePotention}
            changeLimitPotention={changeLimitPotention}
            filterTable={filterTable}
          />
          <Guarantee
            data={data?.collaterals}
            requestData={data?.application?.request}
            changePageCollateral={changePageCollateral}
            changeLimitCollateral={changeLimitCollateral}
            filterTable={filterTable}
          />
          <FinanceDocument data={data?.finance_doc} />
          <CardForm label="Klasifikasi Finansial & SOP Pengelolaan Keuangan">
            <div className="space-y-4">
              <div className="bg-[#E1F1D6] my-5 p-3 w-full">
                <p className="font-bold text-[#01A24A]">
                  Klasifikasi Finansial
                </p>
              </div>
              {data?.clasification_financial?.clasification && (
                <p className="font-bold">
                  {renderClasification(
                    data?.clasification_financial?.clasification
                  )}
                  {/* {data?.clasification_financial?.clasification} */}
                </p>
              )}
              <div className="bg-[#E1F1D6] my-5 p-3 w-full">
                <p className="font-bold text-[#01A24A]">
                  SOP Pengelolaan Keuangan
                </p>
              </div>
              <UploadedFileAsync
                id={data?.clasification_financial?.oprational_file}
                name="Dokumen SOP Mengenai Mekanisme penyaluran dan pengelolaan keuangan, audit internal dan external,
              pengadaan barang dan jasa, pengelolaan aset Dijadikan Satu"
              />
            </div>
          </CardForm>
          <CardForm label="Pajak">
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className="font-semibold">Bendahara</div>
                  <div>{data?.tax?.mandatory}</div>
                </div>
                <div>
                  <div className="font-semibold">Tanggal Registrasi</div>
                  <div>
                    {data?.tax?.registration_date
                      ? dayjs(data?.tax?.registration_date).format(
                          "DD MMMM YYYY"
                        )
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className="font-semibold">NPWP</div>
                  <div>
                    {data?.general?.contact_person?.contact_person_npwp}
                  </div>
                </div>
                <UploadedFileAsync
                  id={data?.general?.contact_person?.contact_person_npwp_file}
                  name="NPWP"
                />
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <div className="font-semibold">
                    Nomor Surat Keterangan Terdaftar
                  </div>
                  <div>{data?.tax?.skt_number ?? "-"}</div>
                </div>
                <div>
                  <div className="font-semibold">
                    Tanggal Surat Keterangan Terdaftar
                  </div>
                  <div>
                    {data?.tax?.skt_date
                      ? dayjs(data?.tax?.skt_date).format("DD MMMM YYYY")
                      : "-"}
                  </div>
                </div>
                <UploadedFileAsync
                  id={data?.tax?.skt_file}
                  name="Surat Keterangan Terdaftar (SKT)"
                />
              </div>
              <div className="grid grid-cols-3 gap-4">
                {data?.tax?.evidance_payment_number && (
                  <div>
                    <div className="font-semibold">
                      Nomor Bukti Pelunasan Pajak Tahun Terakhir
                    </div>
                    <div>{data?.tax?.evidance_payment_number ?? "-"}</div>
                  </div>
                )}

                {data?.tax?.evidance_payment_date && (
                  <div>
                    <div className="font-semibold">
                      Tanggal Pelunasan Pajak Tahun Terakhir
                    </div>
                    <div>
                      {data?.tax?.evidance_payment_date
                        ? dayjs(data?.tax?.evidance_payment_date).format(
                            "DD MMMM YYYY"
                          )
                        : "-"}
                    </div>
                  </div>
                )}

                {data?.tax?.evidance_payment_file && (
                  <UploadedFileAsync
                    id={data?.tax?.evidance_payment_file}
                    name="Bukti Pelunasan Pajak Tahun Terakhir"
                  />
                )}
              </div>
              <div className="space-y-4">
                <div className="bg-[#E1F1D6] my-5 p-3 w-full">
                  <p className="font-bold text-[#01A24A]">
                    Dokumen Pajak Lainnya
                  </p>
                </div>
                <UploadedFileAsync
                  id={data?.tax?.skb_spt_file}
                  name="Dokumen Surat Keterangan Bebas Pajak (SKB) & ST dijadikan satu jika ada"
                />
              </div>
            </div>
          </CardForm>
          {data?.member_development && (
            <CardForm label="Perkembangan Anggota">
              <UploadedFileAsync
                id={data?.member_development}
                name="Perkembangan Anggota"
              />
            </CardForm>
          )}
          {data?.branch_network && (
            <CardForm label="Jaringan Cabang">
              <UploadedFileAsync
                id={data?.branch_network}
                name="Jaringan Cabang"
              />
            </CardForm>
          )}

          <Products
            data={data?.products}
            serviceType={data?.general?.profile?.service_type}
          />
          <FinancedBussiness data={data?.sectors} />
          <CapacityAnalysist data={data?.infomatics} />
        </div>
        <BottomFormAction
          backButtonAction={handleBack}
          submitActionButton={() => methods.handleSubmit(handleSubmit)()}
          disableDrafButton={false}
          hideSubmit={false}
          hideDraft={true}
          submitButtonProps={{
            type: "submit",
            label: "Selanjutnya",
          }}
          drafButtonAction={handleDraftButton}
          disableButtonSubmit={false}
          // hideDraft={true}
          className="z-30 left-[280px] max-w-[calc(100vw-280px)]"
        />
      </FormProvider>
    </TabsContent>
  );
};

export default RequestInformation;
