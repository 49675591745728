import clsx from "clsx";
import { customStyles } from "components/atoms/Select";
import Table, { TableWrapper } from "components/atoms/Table";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { Pagination } from "components/v2/shared/pagination";

import React, { useEffect, useMemo, useState } from "react";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import { BsClipboard2 } from "react-icons/bs";
import Select from "react-select";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  BarElement,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
} from "chart.js";
import { formatCurrency } from "helpers";
import { useQuery } from "@tanstack/react-query";
import { getAge, getCharts, getDebtor, getGender } from "services/fdb/dashboard";

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {

  const [debtorDataState, setDebtorDataState] = useState({});
  const [ageDataState, setAgeDataState] = useState({});
  const [genderDataState, setGenderDataState] = useState({});
  const [LPDataState, setLPDataState] = useState({});
  const [NPDataState, setNPDataState] = useState({});
  const [PSDataState, setPSDataState] = useState({});
  const [NPSDataState, setNPSDataState] = useState({});

  const breadcrumbs = useMemo(
    () => [
      {
        label: "Dashboard",
        path: "/",
      },
    ],
    []
  );

  const headers = useMemo(() => {
    return [
      {
        key: "committee_minutes_number",
        title: "Nama Kelompok / Lembaga",
      },
      {
        key: "name",
        title: "Nilai FDB",
      },
      {
        key: "date",
        title: "Total Debitur",
      },
    ];
  }, []);

  const periods = useMemo(
    () => [
      {
        label: "Tahunan",
        value: "tahun",
      },
      {
        label: "Triwulan",
        value: "triwulan",
      },
      {
        label: "Bulanan",
        value: "bulan",
      },
    ],
    []
  );

  const [selectedPeriod, setSelectedPeriod] = useState("tahun");

  const fundsData = {
    labels: ["Dana Masuk", "Total Penghimpunan Dana"],
    datasets: [
      {
        label: " ",
        data: [500000000, 3000000000],
        backgroundColor: ["#ececec", "#6495ed"],
      },
    ],
  };

  const fundsOptions = { plugins: { legend: { position: "right" } } };

  const {data: dashboardDebtor } = useQuery(
    ["GET_DEBTOR_DATA"],
    ({queryKey}) => getDebtor(queryKey[1]),
    {
      select: (data) => data?.data?.data
    }
  )
  
  const {data: dashboarAgeRange } = useQuery(
    ["GET_AGE_DATA"],
    ({queryKey}) => getAge(queryKey[1]),
    {
      select: (data) => data?.data?.data
    }
  )
  
  const {data: dashboarGender } = useQuery(
    ["GET_GENDER_DATA"],
    ({queryKey}) => getGender(queryKey[1]),
    {
      select: (data) => data?.data?.data
    }
  )
  
  const {data: dashboarLP} = useQuery(
    ["GET_LEMBAGA_PENYALUR_DATA", {serviceType: "lembaga_penyalur"}],
    ({queryKey}) => getCharts(queryKey[1]),
    {
      select: (data) => data?.data?.data
    }
  )
  
  const {data: dashboarNP} = useQuery(
    ["GET_NON_PERORANGAN_DATA", {serviceType: "non_perorangan"}],
    ({queryKey}) => getCharts(queryKey[1]),
    {
      select: (data) => data?.data?.data
    }
  )
  
  const {data: dashboarPS} = useQuery(
    ["GET_PERHUTANAN_SOSIAL_DATA", {serviceType: "perhutanan_sosial"}],
    ({queryKey}) => getCharts(queryKey[1]),
    {
      select: (data) => data?.data?.data
    }
  )
  
  const {data: dashboarNPS} = useQuery(
    ["GET_NON_PERHUTANAN_SOSIAL_DATA", {serviceType: "non_perhutanan_sosial"}],
    ({queryKey}) => getCharts(queryKey[1]),
    {
      select: (data) => data?.data?.data
    }
  )

  useEffect(() => {
    if(dashboardDebtor) setDebtorDataState(dashboardDebtor)
    if(dashboarAgeRange) setAgeDataState(dashboarAgeRange)
    if(dashboarGender) setGenderDataState(dashboarGender)
    if(dashboarLP) setLPDataState(dashboarLP)
    if(dashboarNP) setNPDataState(dashboarNP)
    if(dashboarPS) setPSDataState(dashboarPS)
    if(dashboarNPS) setNPSDataState(dashboarNPS)
  }, [dashboarAgeRange, dashboarGender, dashboarLP, dashboarNP, dashboarNPS, dashboarPS, dashboardDebtor])

  const debtorOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
    },
  };

  const debtorData = {
    labels: Object.keys(debtorDataState),
    datasets: [
      {
        label: " ",
        data: Object.values(debtorDataState),
        backgroundColor: ["blue", "orange", "green", "red"],
        barPercentage: 0.5,
        barThickness: 50,
        borderRadius: 10,
      },
    ],
  };

  console.log('age')

  const agesData = {
    labels: Object.keys(ageDataState),
    datasets: [
      {
        label: " ",
        data: Object.values(ageDataState),
        backgroundColor: ["blue", "red", "green", "orange"],
      },
    ],
  };

  const agesOptions = {
    plugins: {
      legend: {
        position: "right",
      },
    },
  };

  const genderData = {
    labels: Object.keys(genderDataState),
    datasets: [
      {
        label: "Total",
        data: Object.values(genderDataState),
        backgroundColor: ["blue", "red"],
      },
    ],
  };

  const genderOptios = {
    plugins: {
      legend: {
        position: "right",
      },
    },
  };

  const realizationData = {
    labels: [2019, 2020, 2021, 2022, 2023, 2024],
    datasets: [
      {
        label: "Target",
        data: [10, 20, 30, 25, 30, 10],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        yAxisID: "y",
        lineTension: 0.2,
      },
      {
        label: "Realisai",
        data: [8, 16, 25, 20, 22, 8],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        yAxisID: "y1",
        lineTension: 0.2,
      },
    ],
  };

  const realizationOptopns = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
      },
      y1: {
        type: "linear",
        display: false,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const statusData = {
    labels: [
      "Baru",
      "Desk Analyst",
      "Field Analyst",
      "Risalah",
      "Komite",
      "Penawaran",
      "Kontrak",
    ],
    datasets: [
      {
        label: " ",
        data: [30, 50, 55, 35, 80, 40, 20],
        backgroundColor: [
          "blue",
          "orange",
          "purple",
          "tomato",
          "red",
          "gray",
          "green",
        ],
        barPercentage: 0.5,
        barThickness: 50,
        borderRadius: 10,
      },
    ],
  };

  const statusOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
    },
  };


  const chartsData = (serviceType) => {

    let labels;
    let data;

    switch(serviceType) {
      case 'Perhutanan_Sosial':
        labels = Object.keys(PSDataState);
        data = Object.values(PSDataState);
        break;
      case 'Lembaga_Penyalur':
        labels = Object.keys(LPDataState);
        data = Object.values(LPDataState);
        break;
      case 'Non_Perhutanan_Sosial':
        labels = Object.keys(NPSDataState);
        data = Object.values(NPSDataState);
        break;
      case 'Non_Perorangan':
        labels = Object.keys(NPDataState);
        data = Object.values(NPDataState);
        break;
      default:
        labels = [];
        data = [];
    }

    return {
      labels,
      datasets: [
        {
          label: " ",
          data,
          backgroundColor: [
            "blue",
            "orange",
            "purple",
            "tomato",
            "red",
            "gray",
            "green",
          ],
        },
      ],
    };
  }

  const perhutananOptions = {
    plugins: {
      legend: {
        position: "right",
      },
    },
  };

  return (
    <section className="space-y-6">
      <BreadCrumbs routes={breadcrumbs} />

      <div className="flex justify-between items-center">
        <p className="text-lg font-semibold">Dashboard FDB</p>
        <div className="flex gap-3 w-1/2">
          <Select
            styles={customStyles}
            className="w-full"
            options={[]}
            classNames="w-10"
            placeholder="Bulan"
          />
          <Select
            styles={customStyles}
            className="w-full"
            options={[]}
            placeholder="Tahun"
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-5">
        {/* <div className="bg-white shadow-sm p-4 rounded-lg space-y-4">
          <h4 className="text-base font-semibold">Total Penghimpunan Dana</h4>
          <hr />
          <div className="flex flex-col gap-4">
            <div className="bg-primary-700 p-2 rounded-lg">
              <div className="bg-primary-600/60 py-2 px-6 rounded-md border border-white text-2xl font-semibold text-white">
                {formatCurrency(3000000000)}
              </div>
            </div>

            <div className="w-full h-[400px] flex justify-center bg-gray-50 rounded-xl">
              <Doughnut data={fundsData} options={fundsOptions} />
            </div>
          </div>
        </div> */}
        <div className="bg-white shadow-sm p-4 rounded-lg flex flex-col gap-5 col-span-2">
          <h4 className="text-base font-semibold">Debitur</h4>
          <hr />
          <div className="w-full flex justify-center items-end bg-gray-50 rounded-xl p-4 h-[400px]">
            <Bar options={debtorOptions} data={debtorData} />
          </div>
        </div>
        <div className="bg-white shadow-sm p-4 rounded-lg space-y-4">
          <h4 className="text-base font-semibold">
            Pie Chart Usia Penerima FDB
          </h4>
          <hr />
          <div className="p-4 bg-gray-50">
            <Pie data={agesData} options={agesOptions} />
          </div>
        </div>
        <div className="bg-white shadow-sm p-4 rounded-lg space-y-4">
          <h4 className="text-base font-semibold">
            Pie Chart Gender Penerima FDB
          </h4>
          <hr />
          <div className="p-4 bg-gray-50 rounded-lg">
            <Pie data={genderData} options={genderOptios} />
          </div>
        </div>
      </div>

      {/* <div className="bg-primary-600 rounded-lg pt-2">
        <div className="w-full bg-white shadow-sm rounded-b-lg flex flex-col space-y-5 py-4 ">
          <div className="p-4 border-b">
            <h4 className="text-base font-semibold">
              Peta Sebaran Penerima FDB
            </h4>
          </div>

          <div className="grid grid-cols-2 gap-5 px-4">
            <div className="bg-primary-600 rounded-lg shadow-sm shadow-slate-200">
              <div className="bg-white ml-1 rounded-l-sm p-3 flex flex-col gap-4">
                <div className="flex gap-2 items-center">
                  <div className="bg-primary-100 p-2 text-primary-700 text-sm rounded-full">
                    <BsClipboard2 />
                  </div>
                  <p className="text-sm">
                    Total Penerima FDB Provinsi Kalimantan Tengah
                  </p>
                </div>
                <div className="p-3 bg-slate-50 rounded-md">
                  <p className="text-lg font-semibold">175,548 Penerima FDB</p>
                </div>
              </div>
            </div>
            <div className="bg-primary-600 rounded-lg shadow-sm shadow-slate-200">
              <div className="bg-white ml-1 rounded-l-sm p-3 flex flex-col gap-4">
                <div className="flex gap-2 items-center">
                  <div className="bg-primary-100 p-2 text-primary-700 text-sm rounded-full">
                    <BsClipboard2 />
                  </div>
                  <p className="text-sm">
                    Total Nilai FDB Provinsi Kalimantan Tengah
                  </p>
                </div>
                <div className="p-3 bg-slate-50 rounded-md">
                  <p className="text-lg font-semibold">175,548 Penerima FDB</p>
                </div>
              </div>
            </div>
          </div>

          <div className="px-4">
            <Select
              styles={customStyles}
              className="w-full"
              options={[]}
              placeholder="Wilayah"
            />
          </div>

          <TableWrapper className="mt-4 mx-4">
            <Table
              headers={headers}
              items={[]}
              // isLoading={isLoading}
            />
            <Pagination
              page={1}
              limit={10}
              total={0}
              totalPage={1}
              // onLimitChange={(e) =>
              //   setFilter((prev) => ({ ...prev, limit: e }))
              // }
              // onPageChange={(e) => setFilter((prev) => ({ ...prev, page: e }))}
            />
          </TableWrapper>
        </div>
      </div> */}

      {/* <div className="bg-white p-4 shadow-sm rounded-lg flex flex-col gap-5">
        <h4 className="text-base font-semibold">
          Realisasi Penyaluran Debitur
        </h4>
        <div className="border-b flex">
          {periods.map((el) => (
            <div
              className={clsx(
                "px-3 py-2 text-sm font-medium cursor-pointer",
                selectedPeriod === el.value &&
                  "border-b-2 border-primary-700 bg-primary-50 text-primary-700 rounded-t-md"
              )}
              onClick={() => setSelectedPeriod(el.value)}
            >
              {el.label}
            </div>
          ))}
        </div>
        <div className="bg-gray-50 p-4 rounded-lg">
          <Line options={realizationOptopns} data={realizationData} />
        </div>
      </div> */}

      {/* <div className="bg-white p-4 shadow-sm rounded-lg flex flex-col gap-5">
        <div className="bg-primary-700 p-3 rounded-lg">
          <div className="bg-primary-600/80 py-2 px-6 rounded-md border border-white text-white flex flex-col gap-1">
            <p className="text-base font-medium">Total Permohonan</p>
            <h3 className="text-xl font-bold">120</h3>
          </div>
        </div>
        <h4 className="text-base font-semibold">
          Grafik Progres Status Permohonan Pembiayaan
        </h4>
        <div className="border-b flex">
          {periods.map((el) => (
            <div
              className={clsx(
                "px-3 py-2 text-sm font-medium cursor-pointer",
                selectedPeriod === el.value &&
                  "border-b-2 border-primary-700 bg-primary-50 text-primary-700 rounded-t-md"
              )}
              onClick={() => setSelectedPeriod(el.value)}
            >
              {el.label}
            </div>
          ))}
        </div>

        <div className="p-4 bg-gray-50 rounded-lg">
          <Bar options={statusOptions} data={statusData} />
        </div>
      </div> */}

      <div className="grid grid-cols-2 gap-5">
        <div className="bg-white shadow-sm p-4 rounded-lg space-y-4">
          <h4 className="text-base font-semibold">Perhutanan Sosial</h4>
          <hr />
          <div className="p-4 bg-gray-50 rounded-lg">
            <Pie data={chartsData('Perhutanan_Sosial')} options={perhutananOptions} />
          </div>
        </div>
        <div className="bg-white shadow-sm p-4 rounded-lg space-y-4">
          <h4 className="text-base font-semibold">Non Perhutanan Sosial</h4>
          <hr />
          <div className="p-4 bg-gray-50 rounded-lg">
            <Pie data={chartsData('Non_Perhutanan_Sosial')} options={perhutananOptions} />
          </div>
        </div>
        <div className="bg-white shadow-sm p-4 rounded-lg space-y-4">
          <h4 className="text-base font-semibold">Lembaga Penyalur</h4>
          <hr />
          <div className="p-4 bg-gray-50 rounded-lg">
            <Pie data={chartsData('Lembaga_Penyalur')} options={perhutananOptions} />
          </div>
        </div>
        <div className="bg-white shadow-sm p-4 rounded-lg space-y-4">
          <h4 className="text-base font-semibold">Non Lembaga</h4>
          <hr />
          <div className="p-4 bg-gray-50 rounded-lg">
            <Pie data={chartsData('Non_Perorangan')} options={perhutananOptions} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
