import {
  SAVE_ORGANIZATION_DRAFT_DATA,
  RESET_ORGANIZATION_DRAFT_DATA,
  UPDATE_ORGANIZATION_DRAFT_DATA,
} from "../actions";

const initialState = {
  data: {
    structure_organization: {},
    institutional_governance: {},
    institutional_plans: {},
    partnership: {},
    subgrant: {},
    competences: {},
  },
};

export const organizationProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_ORGANIZATION_DRAFT_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case UPDATE_ORGANIZATION_DRAFT_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    case RESET_ORGANIZATION_DRAFT_DATA:
      return {
        ...state,
        data: {
          structure_organization: {},
          institutional_governance: {},
          institutional_plans: {},
          partnership: {},
          subgrant: {},
          competences: {},
        },
      };
    default:
      return state;
  }
};
