import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getMasterBussinessCategory = () => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/finance-business-category`
  );
};
export const getBussinessCategory = (UserId) => {
  return axiosInstance.get(
    `${general_config_v2}/v1/finance-business-category/${UserId}`
  );
};
