import React, { useState, useMemo, useCallback } from "react";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { TabsRoot, TabsList, TabsTrigger } from "components/atoms/Tabs";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams } from "react-router";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";
import { Spinner } from "components";
import SelfAssesmentContent from "./SelfAssesmentContent";
import PotentialDebtorContent from "./PotentialDebtorContent";
import VerificationRecapContent from "./VerificationRecapContent";
import ApplicationFormContent from "./ApplicationFormContent";
import {
  getDebtorDetail,
  verifiedFieldAnalyst,
} from "services/fdb/field-analis";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetValue,
  setValueDebtor,
  setValueRequest,
  setValueVerification,
} from "redux/fieldAnalystSlice";

const title = "Daftar Registrasi Perorangan dalam Kelompok";

const FinancingDetail = () => {
  const dispatch = useDispatch();
  const { defaultValueForm } = useSelector(({ fieldAnalyst }) => fieldAnalyst);

  const { debtor, request: requestValue, verification } = defaultValueForm;
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("potential-debtor");
  const { applicantId, memberId } = useParams();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");

  const location = useLocation();
  const { pathname } = location;
  const segments = pathname.split("/");

  const { data, isLoading } = useQuery({
    queryKey: ["field-analyst-applicant-detail", applicantId],
    queryFn: async () => {
      const { data } = await getDebtorDetail(applicantId);
      return data;
    },
    onError: (error) => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    },
    retry: false,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  const debtorData = useMemo(() => {
    const { group_profile_member, request } = data || {
      group_profile_member: {},
      request: {},
    };
    const {
      debtor_verification,
      debtor_verification_signature_file,
      debtor_verification_identity_file,
      debtor_verification_identity_family_file,
      domicile_verification_latlong_file,
      domicile_verification,
      domicile_verification_desk,
      couple_verification,
      couple_verification_desk,
      domicile_verification_longitude,
      domicile_verification_latitude,
    } = request?.field_analysts || {};
    return {
      group_profile_member,
      request,
      defaultValues: {
        debtor_verification,
        debtor_verification_signature_file,
        debtor_verification_identity_file,
        debtor_verification_identity_family_file,
        domicile_verification_latlong_file,
        domicile_verification,
        domicile_verification_desk,
        couple_verification,
        couple_verification_desk,
        domicile_verification_longitude,
        domicile_verification_latitude,
        ...debtor,
      },
    };
  }, [data, debtor]);

  const applicationFormData = useMemo(() => {
    const { group_profile_member, request, submission_application } = data || {
      group_profile_member: {},
      request: {},
      submission_application: {},
    };
    const {
      prospect_maintenance_pattern,
      prospect_purchased_price,
      prospect_maintenance_price,
      prospect_maintenance_income,
      prospect_ready_purchased,
      prospect_sales_pattern,
      prospect_sales_capital,
      prospect_sales_cost,
      prospect_selling_price,
      prospect_selling_income,
      prospect_percentage_revenue,
      prospect_normal_income,
      prospect_make_profit,
      prospect_net_income_one_siclus,
      prospect_income_siclus,
      prospect_income_needed,
      prospect_loan_ability_payment,
      prospect_financed_province_id,
      prospect_financed_city_id,
      prospect_financed_district_id,
      prospect_financed_village_id,
      prospect_financed_address,
      prospect_debtor_house_file,
      prospect_financed_longitude,
      prospect_financed_latitude,
      prospect_verification,
      prospect_verification_desk,

      total_jaminan,
      collateral_meet_recruitment,
      collateral_condition_tree,
      collateral_numbering_tree,
      collateral_street_access,
      collateral_minimum_height,
      collateral_tabulation_notes_verificator,
      collateral_tabulation_notes_verificator_desc,
      collateral_tabulation_conclusion_verification,
      collateral_tabulation_conclusion_verification_desc,

      tabulation_land_real_document,
      tabulation_land_legality,
      tabulation_land_history,
      tabulation_land_have_dispute_free,
      tabulation_land_have_selling,
      tabulation_land_have_grant,
      tabulation_land_no_percil,
      tabulation_land_width_area,
      tabulation_land_longitude,
      tabulation_land_latitude,
      tabulation_land_verification,
      tabulation_land_verification_desc,

      tabulation_payability_monthly_income,
      tabulation_payability_monthly_outcome,
      tabulation_payability_monthly_net_income,
      tabulation_payability_verification,
      tabulation_payability_verification_desk,
    } = request?.field_analysts || {};
    return {
      group_profile_member,
      request,
      submission_application,
      defaultValues: {
        prospect_maintenance_pattern,
        prospect_purchased_price,
        prospect_maintenance_price,
        prospect_maintenance_income,
        prospect_ready_purchased,
        prospect_sales_pattern,
        prospect_sales_capital,
        prospect_sales_cost,
        prospect_selling_price,
        prospect_selling_income,
        prospect_percentage_revenue,
        prospect_normal_income,
        prospect_make_profit,
        prospect_net_income_one_siclus,
        prospect_income_siclus,
        prospect_income_needed,
        prospect_loan_ability_payment,
        prospect_financed_province_id,
        prospect_financed_city_id,
        prospect_financed_district_id,
        prospect_financed_village_id,
        prospect_financed_address,
        prospect_debtor_house_file,
        prospect_financed_longitude,
        prospect_financed_latitude,
        prospect_verification,
        prospect_verification_desk,

        total_jaminan,
        collateral_meet_recruitment,
        collateral_condition_tree,
        collateral_numbering_tree,
        collateral_street_access,
        collateral_minimum_height,
        collateral_tabulation_notes_verificator,
        collateral_tabulation_notes_verificator_desc,

        tabulation_land_real_document,
        tabulation_land_legality,
        tabulation_land_history,
        tabulation_land_have_dispute_free,
        tabulation_land_have_selling,
        tabulation_land_have_grant,
        tabulation_land_no_percil,
        tabulation_land_width_area,
        tabulation_land_longitude,
        tabulation_land_latitude,
        tabulation_land_verification,
        tabulation_land_verification_desc,

        tabulation_payability_monthly_income,
        tabulation_payability_monthly_outcome,
        tabulation_payability_monthly_net_income,
        tabulation_payability_verification,
        tabulation_payability_verification_desk,

        collateral_tabulation_conclusion_verification,
        collateral_tabulation_conclusion_verification_desc,
        ...requestValue,
      },
    };
  }, [data, requestValue]);

  const verificationData = useMemo(() => {
    const { request } = data || {
      request: {},
    };

    const {
      v_result_identity,
      v_result_financed_business,
      v_result_financed_business_prospect,
      v_result_collateral_tree,
      v_result_collateral_tree_percentage,
      v_result_ownership_land,
    } = request?.field_analysts || {};

    return {
      status: request?.field_status,
      defaultValues: {
        v_result_identity,
        v_result_financed_business,
        v_result_financed_business_prospect,
        v_result_collateral_tree,
        v_result_collateral_tree_percentage,
        v_result_ownership_land,
        ...verification,
      },
    };
  }, [data, verification]);

  const verifiedFieldAnalystCB = useMutation((payload) =>
    verifiedFieldAnalyst(applicantId, payload)
  );

  const onNextDetorData = useCallback(
    (value) => {
      dispatch(setValueDebtor(value));
      setActiveTab("self-assesment");
    },
    [dispatch]
  );

  const onNextRequestForm = useCallback(
    (value) => {
      dispatch(setValueRequest(value));
      setActiveTab("verification-recap");
    },
    [dispatch]
  );

  const onBackRequestForm = useCallback(
    (value) => {
      dispatch(setValueRequest(value));
      setActiveTab("self-assesment");
    },
    [dispatch]
  );

  const onBackVerification = useCallback(
    (value) => {
      dispatch(setValueVerification(value));
      setActiveTab("application-form");
    },
    [dispatch]
  );

  const onFinalSubmit = (value) => {
    const tempPayload = {
      ...verification,
      ...requestValue,
      ...debtor,
      ...value,
      is_draft: false,
      domicile_verification_latitude: `${value?.domicile_verification_latitude}`,
      domicile_verification_longitude: `${value?.domicile_verification_longitude}`,
      tabulation_land_longitude:
        value?.tabulation_land_longitude?.toString() || null,
      tabulation_land_latitude:
        value?.tabulation_land_latitude?.toString() || null,
      prospect_financed_longitude:
        value?.prospect_financed_longitude?.toString() || null,
      prospect_financed_latitude:
        value?.prospect_financed_latitude?.toString() || null,
    };
    verifiedFieldAnalystCB.mutate(tempPayload, {
      onSuccess: (res) => {
        dispatch(resetValue());
        enqueueSnackbar({
          variant: "success",
          message: (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontWeight: "bold" }}>
                Data berhasil disimpan!
              </span>
              <span>Data yang Anda masukkan berhasil dikirim</span>
            </div>
          ),
        });
        navigate(`/field-analyst/list-member/${memberId}`);
      },
      onError: (error) => {
        enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        });
      },
    });
  };

  const onSaveDraft = (value) => {
    const tempPayload = {
      ...verification,
      ...requestValue,
      ...debtor,
      ...value,
      is_draft: true,
      domicile_verification_latitude: `${value?.domicile_verification_latitude}`,
      domicile_verification_longitude: `${value?.domicile_verification_longitude}`,
      tabulation_land_longitude:
        value?.tabulation_land_longitude?.toString() || null,
      tabulation_land_latitude:
        value?.tabulation_land_latitude?.toString() || null,
      prospect_financed_longitude:
        value?.prospect_financed_longitude?.toString() || null,
      prospect_financed_latitude:
        value?.prospect_financed_latitude?.toString() || null,
    };
    verifiedFieldAnalystCB.mutate(tempPayload, {
      onSuccess: (res) => {
        dispatch(resetValue());
        enqueueSnackbar({
          variant: "success",
          message: (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontWeight: "bold" }}>
                Data berhasil disimpan!
              </span>
              <span>Data yang Anda masukkan berhasil dikirim</span>
            </div>
          ),
        });
        navigate(`/field-analyst/list-member/${memberId}`);
      },
      onError: (error) => {
        enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        });
      },
    });
  };

  const selfAssesmentData = useMemo(() => {
    const { request } = data ?? {};

    if (typeof request === "undefined" || !request?.self_assesment) return null;

    return {
      id: request?.self_assesment_id,
      questions1: [
        {
          id: 1,
          judulPertanyaan:
            "Apa usaha utama penghidupan sehari-hari dari calon pemohon?",
          key: request?.self_assesment?.financed_livelihood_prospective,
          verification:
            request?.self_assesment
              ?.financed_livelihood_prospective_verification,
          verification_key: "financed_livelihood_prospective_verification",
        },
        {
          id: 2,
          judulPertanyaan: "Apa tujuan dari penggunaan dana pinjaman tersebut?",
          key: request?.self_assesment?.financed_purpose_loan_funds,
          verification:
            request?.self_assesment?.financed_purpose_loan_funds_verification,
          verification_key: "financed_purpose_loan_funds_verification",
        },
        {
          id: 3,
          judulPertanyaan:
            "Sudah berapa lama usaha yang akan dibiayai sampai dengan saat ini? ",
          key: request?.self_assesment?.financed_business_funded,
          verification:
            request?.self_assesment?.financed_business_funded_verification,
          verification_key: "financed_business_funded_verification",
        },
        {
          id: 4,
          judulPertanyaan:
            "Berapa total perkiraan asset/modal yang sudah dimiliki saat ini?",
          key: request?.self_assesment?.financed_assest_currently,
          verification:
            request?.self_assesment?.financed_assest_currently_verification,
          verification_key: "financed_assest_currently_verification",
        },
        {
          id: 5,
          judulPertanyaan:
            "Apakah usahanya dikerjakan sendiri atau dengan orang lain?",
          key: request?.self_assesment?.financed_is_self_business,
          verification:
            request?.self_assesment?.financed_is_self_business_verification,
          verification_key: "financed_is_self_business_verification",
        },
        {
          id: 6,
          judulPertanyaan:
            "apabila dengan orang lain bagaimana mekanisme kerjanya?",
          key: request?.self_assesment?.financed_mechanism_work,
          verification:
            request?.self_assesment?.financed_mechanism_work_verification,
          verification_key: "financed_mechanism_work_verification",
        },
        {
          id: 7,
          judulPertanyaan: "Berapa penjualan/penghasilan dari usaha saat ini? ",
          key: request?.self_assesment?.financed_selling_income,
          verification:
            request?.self_assesment?.financed_selling_income_verification,
          verification_key: "financed_selling_income_verification",
        },
        {
          id: 8,
          judulPertanyaan:
            "Berapa harga beli/HPP/Modal yang dibutuhkan dari usaha saat ini?",
          key: request?.self_assesment?.financed_capital_price,
          verification:
            request?.self_assesment?.financed_capital_price_verification,
          verification_key: "financed_capital_price_verification",
        },
        {
          id: 9,
          judulPertanyaan:
            "Dimana saja cakupan wilayah usaha yang dijalankan saat ini?",
          key: request?.self_assesment?.financed_current_business_areas,
          verification:
            request?.self_assesment
              ?.financed_current_business_areas_verification,
          verification_key: "financed_current_business_areas_verification",
        },
        {
          id: 10,
          judulPertanyaan: "Bagaimana siklus usaha dari penjualan pemohon? ",
          key: request?.self_assesment?.financed_sales_business_cycle,
          verification:
            request?.self_assesment?.financed_sales_business_cycle_verification,
          verification_key: "financed_sales_business_cycle_verification",
        },
      ],
      questions2: [
        {
          id: 11,
          judulPertanyaan:
            "Apa jenis usaha lain yang menjadi sumber pendapatan lain (termasuk usaha pasangan)?",
          key: request?.self_assesment?.other_sources_income,
          verification:
            request?.self_assesment?.other_sources_income_verification,
          verification_key: "other_sources_income_verification",
        },
        {
          id: 12,
          judulPertanyaan:
            "Penghasilan didap1atkan per bulan / sesuai dengan siklus usaha? Berapa nominalnya?",
          key: request?.self_assesment?.other_obtained_income,
          verification:
            request?.self_assesment?.other_obtained_income_verification,
          verification_key: "other_obtained_income_verification",
        },
        {
          id: 13,
          judulPertanyaan: "Berapa penjualan/penghasilan dari usaha saat ini?",
          key: request?.self_assesment?.other_selling_income,
          verification:
            request?.self_assesment?.other_selling_income_verification,
          verification_key: "other_selling_income_verification",
        },
        {
          id: 14,
          judulPertanyaan:
            "Berapa harga beli/HPP/Modal yang dibutuhkan dari usaha saat ini?",
          key: request?.self_assesment?.other_capital_price,
          verification:
            request?.self_assesment?.other_capital_price_verification,
          verification_key: "other_capital_price_verification",
        },
        {
          id: 15,
          judulPertanyaan: "Bagaimana siklus usaha dari penjualan pemohon?",
          key: request?.self_assesment?.other_sales_business_cycle,
          verification:
            request?.self_assesment?.other_sales_business_cycle_verification,
          verification_key: "other_sales_business_cycle_verification",
        },
        {
          id: 16,
          judulPertanyaan:
            "Berapa perkiraan pengeluaran rutin setiap bulannya?",
          key: request?.self_assesment?.other_outcome_per_month,
          verification:
            request?.self_assesment?.other_outcome_per_month_verification,
          verification_key: "other_outcome_per_month_verification",
        },
      ],
    };
  }, [data]);

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: `/${segments[1]}`,
          },
          {
            label: "Daftar Anggota",
            path: `/${segments[1]}/${segments[2]}/${segments[3]}`,
            search: {
              ...Object.fromEntries(searchParams.entries()),
              tab: "application-detail"
            }
          },
          {
            label: "Detail",
            path: `/${segments[1]}/${segments[2]}/${segments[3]}/${applicantId}`,
          },
        ]}
      />

      {isLoading ? (
        <div className="flex justify-center p-4">
          <Spinner theme={"primary"} />
        </div>
      ) : (
        <TabsRoot value={activeTab} onValueChange={setActiveTab}>
          <TabsList>
            <TabsTrigger disabled={true} value="potential-debtor">
              Calon Debitur
            </TabsTrigger>
            <TabsTrigger disabled={true} value="self-assesment">
              Self Assesment
            </TabsTrigger>
            <TabsTrigger disabled={true} value="application-form">
              Formulir Permohonan
            </TabsTrigger>
            <TabsTrigger disabled={true} value="verification-recap">
              Rekap Hasil Verifikasi
            </TabsTrigger>
          </TabsList>
          <PotentialDebtorContent
            initialValues={debtorData}
            onNext={onNextDetorData}
            onSaveDraft={onSaveDraft}
            hideDraft={!!status}
          />
          <SelfAssesmentContent
            initialValues={selfAssesmentData}
            onBack={() => setActiveTab("potential-debtor")}
            onNext={() => setActiveTab("application-form")}
            hideDraft={!!status}
          />
          <ApplicationFormContent
            initialValues={applicationFormData}
            onBack={onBackRequestForm}
            onNext={onNextRequestForm}
            onSaveDraft={onSaveDraft}
            hideDraft={!!status}
          />
          <VerificationRecapContent
            initialValues={verificationData}
            onBack={onBackVerification}
            onNext={onFinalSubmit}
            onSaveDraft={onSaveDraft}
            hideDraft={!!status}
          />
        </TabsRoot>
      )}
    </div>
  );
};

export default FinancingDetail;
