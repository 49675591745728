import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";
import axios from "axios";

export const getGroupApplicationVerificationList = (params) => {
  return axios.get(`v1/group-application-verification/group`, { params });
};

export const getGroupApplicationVerificationDetail = (id, params) => {
  return axios.get(`v1/group-application-verification/group/detail/${id}`, {
    params,
  });
};

export const updateGroupApplicationVerification = (id, params) => {
  return axios.put(`v1/group-application-verification/${id}`, { params });
};

export const updateDraftGroupApplicationVerification = (id, params) => {
  return axios.put(`v1/group-application-verification/draft/${id}`, { params });
};

export const getGroupApplicationVerificationMembers = (id, params) => {
  return axios.get(`v1/group-application-verification/member/${id}`, {
    params,
  });
};

export const getGroupApplicationVerificationMemberDetail = (id, params) => {
  return axios.get(`v1/group-application-verification/member/detail/${id}`, {
    params,
  });
};

export const updateGroupApplicationVerificationMember = (id, params, type) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/verifikator/applicant/${type}/${id}`,
    params
  );
};
