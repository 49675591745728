import CardForm from "components/molecules/CardForm";
import dayjs from "dayjs";
import React from "react";

const DetailDebtor = ({ data }) => {
  return (
    <CardForm label="Detail Debitur">
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 space-y-3">
        <div>
          <p className="font-semibold">Nama Debitur</p>
          <p>{data?.name}</p>
        </div>
        <div>
          <p className="font-semibold">NIK</p>
          <p>{data?.identity}</p>
        </div>
        <div>
          <p className="font-semibold">Tanggal Lahir</p>
          <p>{dayjs(data?.date_of_birth).format("DD MMMM YYYY")}</p>
        </div>
        <div>
          <p className="font-semibold">Kebutuhan Pinjaman</p>
          <p>RP. {Math.floor(data?.loan).toLocaleString()}</p>
        </div>
        <div>
          <p className="font-semibold">Jangka Waktu Pinjaman</p>
          <p>{data?.period_loan}</p>
        </div>
      </div>
    </CardForm>
  );
};

export default DetailDebtor;
