import CardForm from "components/molecules/CardForm";
import React from "react";

const OfferAttachment = ({ data }) => {
  return (
    <CardForm label="Lampiran Penawaran Kerjasama">
      <div className="space-y-5">
        <div>
          <div className="font-semibold">
            Syarat Penandatanganan Perjanjian FDB
          </div>
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{
              __html: data?.fdb_syara_agreement ?? "-",
            }}
          ></div>
        </div>
        <div>
          <div className="font-semibold">Ketentuan Kerjasama</div>
          {data?.cooperation_terms?.length > 0 ? (
            <ul className="list-decimal list-inside">
              {data?.cooperation_terms?.map((x, key) => {
                return (
                  <>
                    <li key={key}>{x.value}</li>
                    {x?.sub?.length > 0 ? (
                      <ul className="list-[lower-alpha] list-inside pl-3">
                        {x?.sub?.map((sub, idx) => (
                          <li key={`sub-${idx}`}>{sub.value}</li>
                        ))}
                      </ul>
                    ) : null}
                  </>
                );
              })}
            </ul>
          ) : (
            <div>-</div>
          )}
        </div>
        <div>
          <div className="font-semibold">
            Ketentuan Pencairan dan Pengembalian FDB
          </div>
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{
              __html: data?.disbursement_terms ?? "-",
            }}
          ></div>
        </div>
        <div>
          <div className="font-semibold">Pelaporan</div>
          {data?.report?.length > 0 ? (
            <ul className="list-decimal list-inside">
              {data?.report?.map((x, key) => {
                return (
                  <>
                    <li key={key}>{x.value}</li>
                    {x?.sub?.length > 0 ? (
                      <ul className="list-[lower-alpha] list-inside pl-3">
                        {x?.sub?.map((sub, idx) => (
                          <li key={`sub-${idx}`}>{sub.value}</li>
                        ))}
                      </ul>
                    ) : null}
                  </>
                );
              })}
            </ul>
          ) : (
            <div>-</div>
          )}
        </div>
        <div>
          <div className="font-semibold">Ketentuan Monitoring dan Evaluasi</div>
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{
              __html: data?.monitoring_terms ?? "-",
            }}
          ></div>
        </div>
        <div>
          <div className="font-semibold">Ketentuan Denda dan Wanprestasi</div>
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{
              __html: data?.tax_terms ?? "-",
            }}
          ></div>
        </div>
        <div>
          <div className="font-semibold">
            Ketentuan Berakhirnya Perjanjian Kerjasama FDB
          </div>
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{
              __html: data?.end_agreement_terms ?? "-",
            }}
          ></div>
        </div>
        <div>
          <div className="font-semibold">
            Ketentuan Penyelamatan dan Penyelesaian FDB
          </div>
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{
              __html: data?.settlement_terms ?? "-",
            }}
          ></div>
        </div>
      </div>
    </CardForm>
  );
};

export default OfferAttachment;
