import Input from "components/atoms/Input";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { MaskInput, Mask } from "maska";
import { Controller, useFormContext } from "react-hook-form";
import { debounce } from "lodash";

const valueFormatter = (value) => {
  if (!value) return "";
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const valueParser = (value) => {
  if (!value) return "";
  return value.replace(/[$,]+/g, "").replace(/[$.]+/g, ",");
};

const InputForm = forwardRef(
  (
    {
      controllerName,
      className,
      label,
      wrapperClass,
      required = false,
      textArea = false,
      showErrorLabel = true,
      mask,
      maskaInputOptions,
      asText = false,
      asTextClass = "",
      showPrefix,
      prefix,
      disabled,
      onChangeInput,
      defaultValue,
      hidden = false,
      isNumber = false,
      ...props
    },
    ref
  ) => {
    const { control, setValue } = useFormContext();
    const inputRef = useRef();
    const maskValue = useRef();

    useEffect(() => {
      if (mask) {
        if (!asText) {
          new MaskInput(inputRef?.current || "[data-maska]", {
            mask,
            ...maskaInputOptions,
          });
        } else {
          maskValue.current = new Mask({
            mask,
            tokens: props?.["data-maska-tokens"] || undefined,
            ...maskaInputOptions,
          });
        }
      }
    }, [mask, asText, maskaInputOptions, props]);

    useImperativeHandle(ref, () => ({
      getInputRef: () => inputRef,
    }));

    return (
      <Controller
        name={controllerName}
        control={control}
        defaultValue={defaultValue ?? ""}
        render={({
          field: { onChange, value, onBlur },
          fieldState: { invalid, error },
        }) => {
          const defaultClass = className
            ? `${className} ${invalid ? "border-[#F04438]" : ""}`
            : `input w-full max-w-xs border ${
                invalid ? "input-error" : "input-bordered"
              } ${invalid ? "border-[#F04438]" : ""} `;
          return (
            <div
              className={`form-control w-full ${wrapperClass || ""} ${
                hidden && "hidden"
              }`}
            >
              {label && (
                <label className="flex gap-1 label font-semibold text-[14px] mb-2">
                  <span className={`label-text`}>{label}</span>
                  {required && <span className="text-[#F04438]">*</span>}
                </label>
              )}

              {asText ? (
                <div
                  className={[
                    "min-h-[2.5rem] py-2 overflow-hidden text-ellipsis whitespace-pre",
                    asTextClass,
                  ].join(" ")}
                >
                  {mask && maskValue.current
                    ? maskValue.current?.masked(value)
                    : value}
                </div>
              ) : undefined}

              {!asText && isNumber && !textArea && (
                <div
                  className={`relative border ${
                    props.disabled ? "bg-[#F4F5F2]" : "bg-white"
                  } ${defaultClass || ""}`}
                  style={{ padding: "0.25rem" }}
                >
                  {prefix && (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none -z-0">
                      {prefix}
                    </span>
                  )}
                  <input
                    ref={inputRef}
                    {...props}
                    className={`w-full focus:outline-none py-1 focus:border-primary-700 disabled:text-gray-400 ${
                      prefix ? "pl-9" : "pl-3"
                    }`}
                    style={{
                      paddingRight: props?.rightIcon ? "2.75rem" : "0",
                      zIndex: 2,
                    }}
                    value={valueFormatter(value)}
                    onChange={(event) => {
                      const value = valueParser(event.target.value);
                      onChange({
                        ...event,
                        target: {
                          ...event.target,
                          value,
                        },
                      });
                      if (onChangeInput) {
                        onChangeInput({
                          ...event,
                          target: {
                            ...event.target,
                            value,
                          },
                        });
                      }
                    }}
                  />
                  {props.rightIcon && (
                    <div className="absolute right-0 inset-y-0 flex items-center pr-3">
                      {props.rightIcon}
                    </div>
                  )}
                </div>
              )}

              {!asText && textArea ? (
                <textarea
                  ref={inputRef}
                  onChange={onChange}
                  disabled={disabled}
                  {...props}
                  value={value}
                  className={`focus:outline-none focus:border-primary-700 disabled:text-gray-400 ${
                    className ? className : "input w-full max-w-xs border"
                  } ${invalid ? "input-error" : "input-bordered"} ${
                    invalid ? "border-[#F04438]" : ""
                  }`}
                />
              ) : undefined}
              {!asText && !textArea && !isNumber && (
                <>
                  <Input
                    ref={inputRef}
                    {...props}
                    onChange={(e) => {
                      onChange(e);
                      if (onChangeInput) {
                        onChangeInput(e);
                      }
                    }}
                    value={value}
                    defaultValue={defaultValue}
                    data-maska={mask}
                    className={
                      className
                        ? `${className} ${invalid ? "border-[#F04438]" : ""}`
                        : `input w-full max-w-xs border ${
                            invalid ? "input-error" : "input-bordered"
                          } ${invalid ? "border-[#F04438]" : ""} `
                    }
                    prefix={prefix}
                    disabled={disabled}
                  />
                </>
              )}
              {invalid && showErrorLabel && (
                <label className="label">
                  <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
                    {error?.message}
                  </div>
                </label>
              )}
            </div>
          );
        }}
      />
    );
  }
);

export default InputForm;
