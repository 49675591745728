import React, { useMemo } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  defer,
  Navigate,
  useOutletContext,
} from "react-router-dom";
import { map } from "lodash";
import { useSelector } from "react-redux";

import route from "./route";

import { AuthLayout, BasicLayout } from "components";

import { AuthConsumer } from "providers/authProvider";
import { SigninCallback } from "../components/auth/signinCallback";
import { SilentRenew } from "../components/auth/silentRenew";
import Logout from "components/auth/logout";
import { SignoutCallback } from "components/auth/signoutCallback";

import My404Component from "pages/My404Component";
import My403Component from "pages/My403Component";
import { getUserDetail } from "services/user";

const ProtectedRoute = ({ children, routeKey, type, sidebar }) => {
  const { userAccessRights } = useOutletContext();

  return (
    <AuthConsumer>
      {({ isAuthenticated, signinRedirect }) => {
        if (isAuthenticated()) {
          // TODO User Management integration
          if (userAccessRights?.[routeKey]?.[type]) {
            return (
              <BasicLayout sidebar={sidebar}>
                {React.cloneElement(children, {
                  accessRight: userAccessRights?.[routeKey],
                })}
              </BasicLayout>
            );
          } else return <Navigate to="/unautorized" replace />;
        } else {
          return signinRedirect();
        }
      }}
    </AuthConsumer>
  );
};

const Routes = () => {
  const { user } = useSelector(({ auth }) => auth);

  const RouteElements = useMemo(() => {
    return createBrowserRouter([
      {
        path: "/signin-oidc",
        element: <SigninCallback />
      },
      {
        path: "/signout-oidc",
        element: <SignoutCallback />
      },
      {
        path: "/logout",
        element: <Logout />
      },
      {
        path: "silentrenew",
        element: <SilentRenew />
      },
      {
        element: <My404Component />,
        path: "*",
      },
      { element: <My403Component />, path: "/unautorized" },
      {
        element: <AuthLayout />,
        loader: async ({ signal }) => {
          try {
            if (user?.UserId) {
              const { data: userDetail } = await getUserDetail(
                user?.UserId,
                signal
              );
              if (
                userDetail?.userAccess &&
                Array.isArray(userDetail?.userAccess)
              ) {
                const index = userDetail?.userAccess?.findIndex(
                  (x) => x.moduleName?.toLowerCase() === "fdb"
                );
                if (index > -1) {
                  const userAccess =
                    userDetail?.userAccess?.[index]?.menuModules;
                  const userAccessRights = userAccess.reduce((obj, item) => {
                    return Object.assign(obj, {
                      [item.key]: { ...item },
                    });
                  }, {});
                  return defer({
                    userAccessRights,
                  });
                }
              }
              return defer({
                userAccessRights: null,
              });
            }
            return defer({
              userAccessRights: null,
            });
          } catch (error) {
            console.log("Error while getting user access rights ", error);
          }
        },
        children: map(route, (val, key) => {
          const Component = val.element;
          return {
            key: key,
            path: val.path,
            element: (
              <ProtectedRoute routeKey={val.key} type={val.type} sidebar={val.sidebar}>
                <Component />
              </ProtectedRoute>
            ),
          };
        }),
      }
    ])
  }, [user]);

  return <RouterProvider router={RouteElements} />;
};

export default Routes;
