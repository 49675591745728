import React, { useMemo, useState } from "react";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import { RiCloseLine, RiEyeLine, RiSearchLine } from "react-icons/ri";
import { Input, Spinner } from "components";
import { customStyles } from "components/atoms/Select";
import Table, { TableWrapper } from "components/atoms/Table";
import Button from "components/atoms/Button/Button";
import Badge from "components/atoms/Badge";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { debounce, startCase } from "lodash";
import { getVerificatorList } from "services/fdb/verificator";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import { Pagination } from "components/v2/shared/pagination";

const Groups = () => {
  const title = "Daftar Registrasi Perorangan dalam Kelompok";

  const [filters, setFilters] = useState({
    search: "",
    status: "",
    date: "",
    page: 1,
    limit: 10,
  });

  const { data, isLoading } = useQuery({
    queryKey: ["group-application-verification", { params: filters }],
    queryFn: async ({ queryKey }) => {
      return (await getVerificatorList(queryKey[1])).data;
    },
    onSuccess: (data) => {},
    refetchOnWindowFocus: false,
  });

  // Dummy Data
  // const data = useMemo(() => {
  //   return [{}];
  // }, []);
  // End For Group Table

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: "/groups",
          },
        ]}
      />

      <h1 className="text-gray-900 text-2xl font-semibold leading-loose">
        {title}
      </h1>

      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <GroupsFilter filters={filters} setFilters={setFilters} />
        {isLoading ? (
          <div className="flex items-center justify-center w-full h-full">
            <Spinner />
          </div>
        ) : (
          <GroupsTable
            data={data?.data}
            isLoading={false}
            setFilters={setFilters}
            filters={filters}
          />
        )}
      </div>
    </div>
  );
};

const GroupsFilter = ({ filters, setFilters }) => {
  const stateOptions = useMemo(
    () => [
      { value: "not_yet_review", label: "Waiting Verified" },
      { value: "approved", label: "Approved" },
      { value: "rejected ", label: "Rejected" },
    ],
    []
  );

  return (
    <form className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end">
      <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
        <RiSearchLine className="text-gray-400 text-xl" />

        <Input
          className="w-full bg-white border-none"
          onChange={debounce(
            (e) =>
              setFilters((prev) => ({
                ...prev,
                page: 1,
                search: e.target.value,
              })),
            1000
          )}
          placeholder="Cari"
        />
      </div>
      <Select
        className="w-full rounded-md h-10 absolute"
        options={stateOptions}
        placeholder="Status"
        styles={customStyles}
        isClearable
        onChange={debounce(
          (e) =>
            setFilters((prev) => ({
              ...prev,
              page: 1,
              status: e?.value ?? "",
            })),
          1000
        )}
      />
      <div className="relative">
        <ReactDatePicker
          className={
            "date_picker w-full !outline-none !focus:border-primary-700 border h-10 px-3 focus:border-primary-700 rounded-md"
          }
          selected={filters.date}
          onChange={debounce(
            (date) =>
              setFilters((prev) => ({
                ...prev,
                page: 1,
                date: date,
              })),
            1000
          )}
          placeholderText={"dd/mm/yyyy"}
          showYearDropdown={true}
        />
        {filters.date !== "" && (
          <RiCloseLine
            className="absolute top-3 right-2 text-gray-400 hover:text-gray-600 cursor-pointer text-lg"
            onClick={() =>
              setFilters((prev) => ({ ...prev, page: 1, date: "" }))
            }
          />
        )}
      </div>
    </form>
  );
};

const stateColors = {
  "Not Yet Review": "warning",
  "On Progress": "bluelight",
  Draft: "default",
  Rejected: "error",
  Approved: "success",
  Terverifikasi: "success",
};

const categoryColors = {
  "Perhutanan Sosial": "error",
  "Non Perhutanan Sosial": "bluelight",
};
const GroupsTable = ({ data, isLoading, filters, setFilters }) => {
  const navigate = useNavigate();

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => {
          const no =
            parseInt(data?.meta?.current_page) *
              parseInt(data?.meta?.per_page) -
            parseInt(data?.meta?.per_page) +
            index +
            1;

          return no;
        },
      },
      {
        key: "chairmanName",
        title: "Nama Ketua Kelompok",
      },
      {
        key: "name",
        title: "Nama Kelompok",
      },
      {
        key: "date",
        title: "Tgl Registrasi",
        render: ({ item }) => {
          return dayjs(item.date).format("DD/MM/YYYY");
        },
      },
      {
        key: "category",
        title: "Kategori FDB",
        render: ({ item }) => (
          <Badge color={categoryColors[item.category]} variant="outlined">
            {item.category}
          </Badge>
        ),
      },
      {
        key: "state",
        title: "Status",
        render: ({ item }) => (
          <Badge color={stateColors[item.state]}>{item.state}</Badge>
        ),
      },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <>
            {["waiting", "unreviewed"].includes(item.state) ? (
              <Button
                color="primary"
                label="Verifikasi"
                onClick={() => {
                  navigate(`/groups/${item.id}?tab=verification`);
                }}
              />
            ) : (
              <Button
                variant="icon"
                label={<RiEyeLine className="text-lg" />}
                onClick={() => {
                  navigate(`/groups/${item.id}`);
                }}
              />
            )}
          </>
        ),
      },
    ];
  }, [navigate, data?.meta]);

  const items = useMemo(() => {
    if (data?.data) {
      return (
        data.data.map((item) => ({
          id: item.id,
          chairmanName: item?.group_profile?.contact_person_name || "-",
          name: item.group_profile?.name || "-",
          date: item.created_at,
          category: item.type || "-",
          state: startCase(item.verificator_status),
        })) || []
      );
    }
    return [];
  }, [data?.data]);

  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={items} isLoading={isLoading} />
      <Pagination
        page={data?.meta?.current_page}
        limit={filters.limit}
        total={data?.meta?.total}
        totalPage={data?.meta?.last_page}
        onLimitChange={(e) => setFilters((prev) => ({ ...prev, limit: e }))}
        onPageChange={(e) => setFilters((prev) => ({ ...prev, page: e }))}
      />
    </TableWrapper>
  );
};

export default Groups;
