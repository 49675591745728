import Groups from "pages/Groups";
import GroupsDetail from "pages/Groups/Detail";
import GroupsDebtorDetail from "pages/Groups/DebtorDetail";


// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    routeType: "private",
    path: "/groups",
    element: Groups,
    exact: true,
    sidebar: true,
    key: "groups_verification",
    type: "canView"
  },
  {
    routeType: "private",
    path: "/groups/:id",
    element: GroupsDetail,
    exact: true,
    sidebar: true,
    key: "groups_verification",
    type: "canUpdate"
  },
  {
    routeType: "private",
    path: "/groups/:id/debtor/:debtorId",
    element: GroupsDebtorDetail,
    exact: true,
    sidebar: true,
    key: "groups_verification",
    type: "canUpdate"
  }
];
