import React, { useMemo } from "react";
import * as Collapsible from "@radix-ui/react-collapsible";
import { Link, useLocation } from "react-router-dom";
import { isEqual } from "lodash";

const { RiArrowDownSLine } = require("react-icons/ri");

export const HorizontalMenu = ({
  children,
  icon,
  title,
  to,
  titleClass,
  contentClass,
  defaultValue = false,
  isError = false,
}) => {
  const [open, setOpen] = React.useState(defaultValue);

  React.useEffect(() => {
    setOpen(defaultValue);
  }, [defaultValue]);

  return (
    <Collapsible.Root open={open} onOpenChange={setOpen}>
      <Collapsible.Trigger asChild>
        <div
          className={[
            "text-sm rounded-md text-white px-4 py-3 font-semibold flex justify-between items-center w-full my-2",
            open ? "bg-primary-700" : "hover:bg-primary-700",
          ].join(" ")}
        >
          <HorizontalMenuTitle to={to} icon={icon} title={title} />
          <RiArrowDownSLine
            className={`transform text-xl transition-transform ${
              open ? "rotate-180" : ""
            }`}
          />
        </div>
      </Collapsible.Trigger>
      <Collapsible.Content className={["", contentClass].join(" ")}>
        {children}
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

export const HorizontalMenuTitle = ({ icon, title, to }) => {
  return (
    <div className="flex items-center">
      {icon && <span className="text-lg">{icon()}</span>}
      {title}
    </div>
  );
};

export const HorizontalMenuLink = ({ to, icon, children }) => {
  const { pathname } = useLocation();
  const isActive = useMemo(() => {
    let pathnameSegment = pathname.split("/").filter((x) => x);
    pathnameSegment.length = 2;
    pathnameSegment = pathnameSegment.filter((x) => x);

    let linkSegment = to.split("/").filter((x) => x);
    linkSegment.length = 2;
    linkSegment = linkSegment.filter((x) => x);

    return isEqual(pathnameSegment, linkSegment);
  }, [pathname, to]);

  return (
    <Link
      to={to}
      className={`text-sm flex w-full my-2 items-center text-white font-semibold px-4 py-2 rounded-md hover:bg-[#013517] active:bg-[#013517] ${
        isActive && `bg-[#013517]`
      }`}
    >
      {to === "/" ? (
        <></>
      ) : (
        <div
          className={`w-2 h-2 rounded-full mr-4 ${
            isActive ? "bg-primary-700" : "bg-white"
          }`}
        ></div>
      )}

      <HorizontalMenuTitle icon={icon} title={children} />
    </Link>
  );
};

export default HorizontalMenu;
