import {
  SAVE_REGION_DRAFT_DATA,
  RESET_REGION_DRAFT_DATA,
  UPDATE_REGION_DRAFT_DATA,
  updateDraftData,
} from "../actions";

const initialState = {
  data: {
    nation: [],
    province: [],
    city: [],
    district: [],
    wards: [],
    posCode: [],
  },
};

export const regionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_REGION_DRAFT_DATA:
      return {
        ...state,
        data: action.payload,
      };

    case UPDATE_REGION_DRAFT_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case RESET_REGION_DRAFT_DATA:
      return {
        ...state,
        data: {
          nation: [],
          province: [],
          city: [],
          district: [],
          wards: [],
          posCode: [],
        },
      };
    default:
      return state;
  }
};
