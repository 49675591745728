import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";

// ? ASSIGN PELAKSANA
export const getAssignDisbursementList = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/assign-disbursement`,
    {
      params: queryKey[1],
    }
  );
};

export const createAssignDisbursement = ({ id, payload }) => {
  return axiosInstance.post(
    `${general_config_v2.BASE_URL}/v1/cms/assign-disbursement/${id}`,
    payload
  );
};

export const getAssignDisbursementDetail = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/assign-disbursement/${queryKey[1]}`
  );
};

export const getDisburstments = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/disbursement`,
    { params: queryKey[1] }
  );
};

export const getDisburstmentsDetail = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/disbursement/${queryKey[1]}`,
    { params: queryKey[2] }
  );
};

export const putDisburstmentsDetail = ({ id, data }) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/disbursement/${id}`,
    data
  );
};

export const getDisburstmentsDetailMember = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/disbursement-member/${queryKey[1]}`
  );
};

export const putDisburstmentsDetailMember = ({ id, data }) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/disbursement-member/${id}`,
    data
  );
};

export const getDisburstmentsDirectorate = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/disbursement-directorate`,
    { params: queryKey[1] }
  );
};

export const getDisburstmentsDirectorateDetail = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/disbursement-directorate/${queryKey[1]}`,
    { params: queryKey[2] }
  );
};

export const putDisburstmentsDirectorate = ({ id, params }) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/disbursement-directorate/${id}`,
    {},
    { params }
  );
};
