import React, { useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import AccordionButton from "components/atoms/AccordionButton";
import CardForm from "components/molecules/CardForm";
import PlantCondition from "./PlantCondition";
import ReceiverFDB from "./ReceiverFDB";
import BuisnessCondition from "./BuisnessCondition";

const SelfAsessment = () => {
  const [limit, setLimit] = useState(5);
  const [totalPage, setTotalPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);

  const [accordionState, setAccordionState] = useState({
    receiver_fdb: true,
    plant_condition: true,
    buisness_condition: true,
  });

  const toggleAccordion = (key) => () => {
    setAccordionState({
      ...accordionState,
      [key]: !accordionState?.[key],
    });
  };

  return (
    <CardForm label="Self Assessment Kinerja Debitur">
      <div className="grid grid-cols-1 gap-6">
        <AnimatePresence>
          <AccordionButton
            key={"accordion-address-domicile-btn"}
            label={"Keberadaan Penerima FDB"}
            isOpen={accordionState?.receiver_fdb}
            onClick={toggleAccordion("receiver_fdb")}
          />

          {accordionState?.receiver_fdb && (
            <motion.div
              key={"accordion-address-domicile-content"}
              className="lg:grid lg:grid-cols-1 gap-4 space-y-4 "
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              exit={{ opacity: 0 }}
            >
              <ReceiverFDB
                isLoading={false}
                totalItems={totalItems}
                totalPage={totalPage}
                setLimit={setLimit}
                setPage={setPage}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          <AccordionButton
            key={"accordion-address-domicile-btn"}
            label={"Kondisi Tanaman"}
            isOpen={accordionState?.plant_condition}
            onClick={toggleAccordion("plant_condition")}
          />

          {accordionState?.plant_condition && (
            <motion.div
              key={"accordion-address-domicile-content"}
              className="lg:grid lg:grid-cols-1 gap-4 space-y-4 "
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              exit={{ opacity: 0 }}
            >
              <PlantCondition
                isLoading={false}
                totalItems={totalItems}
                totalPage={totalPage}
                setLimit={setLimit}
                setPage={setPage}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          <AccordionButton
            key={"accordion-address-domicile-btn"}
            label={"Kondisi Kondisi Usaha"}
            isOpen={accordionState?.buisness_condition}
            onClick={toggleAccordion("buisness_condition")}
          />

          {accordionState?.buisness_condition && (
            <motion.div
              key={"accordion-address-domicile-content"}
              className="lg:grid lg:grid-cols-1 gap-4 space-y-4 "
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              exit={{ opacity: 0 }}
            >
              <BuisnessCondition
                isLoading={false}
                totalItems={totalItems}
                totalPage={totalPage}
                setLimit={setLimit}
                setPage={setPage}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <div className="w-full py-4 px-6 bg-primary-700 rounded-md">
          <div className="text-right">
            <p className="text-white">
              Total Skor
            </p>
            <p className="text-white text-xl font-semibold">
              1000
            </p>
          </div>
        </div>
      </div>
    </CardForm>
  );
};

export default SelfAsessment;
