import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getCompanionAffiliates = (userId) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/companion-affiliates`,
    {
      headers: { Authorization: `Bearer ${userId}` },
    }
  );
};
