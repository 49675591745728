import { useQuery } from "@tanstack/react-query";
import { Button, InputForm, Spinner } from "components";
import Badge from "components/atoms/Badge";
import AtomDatePicker from "components/atoms/Datepicker";
import AtomSelect from "components/atoms/Select";
import Table, { TableWrapper } from "components/atoms/Table";
import TablePagination from "components/atoms/TablePagination";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import dayjs from "dayjs";
import { debounce, toLower } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RiEyeLine, RiFolder2Line, RiSearchLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { getDeskAnalystList } from "services/fdb/desk-analyst";

const stateColors = {
  Baru: "warning",
  "Sedang Dikerjakan": "bluelight",
  Draft: "default",
  Ditolak: "error",
  Selesai: "success",
};

const categoryColors = {
  "Perhutanan Sosial": "error",
  "Non Perhutanan Sosial": "bluelight",
};

const DeskAnalyst = () => {
  const title = "Assign Desk Analyst";

  // For List Table
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    search: "",
    date: "",
    status: "",
  });

  const methods = useForm({
    // resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      search: "",
      state: "",
      date: "",
    },
  });

  const { data, isLoading } = useQuery({
    queryKey: ["application-desk-analyst-list", page, limit, filter],
    queryFn: async () => {
      const res = await getDeskAnalystList(
        page,
        limit,
        filter.search,
        filter.status,
        filter.date
      );
      return res.data.data || [];
    },
    onSuccess: (res) => {
      setTotalPage(res?.meta?.last_page);
      setTotalItems(res?.meta?.total);
      const items =
        res.data.length > 0
          ? res?.data?.map((item) => {
              return {
                id: item.id,
                chairmanName: item?.group_profile?.leader_name,
                name: item?.group_profile?.name,
                date: item?.registration_date,
                category: item?.type,
                state: item?.desk_status_string,
                total_member: item?.total_member,
              };
            })
          : [];
      setItems(items);
    },
    retry: 1,
  });

  const handleSubmitFilter = debounce(() => {
    setFilter({
      search:
        methods.getValues("search") !== ""
          ? methods.getValues("search")
          : undefined,
      date:
        methods.getValues("date") !== ""
          ? dayjs(methods.getValues("date")).format("YYYY-MM-DD")
          : undefined,
      status:
        methods.getValues("state") !== ""
          ? methods.getValues("state")
          : undefined,
    });
  }, 1000);
  // End For Group Filter

  // Use Eff for triggering handleSubmit due to onChange is not triggered when using non native component such as React Select || Other Third Party Comp.
  useEffect(() => {
    const subscription = methods.watch(
      methods.handleSubmit(handleSubmitFilter)
    );
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  return (
    <div className="space-y-6">
      <BreadCrumbs
        routes={[
          {
            label: title,
            path: "/desk-analyst",
          },
        ]}
      />

      <h1 className="text-gray-900 text-2xl font-semibold leading-loose">
        {title}
      </h1>

      <div className="bg-white shadow-sm border px-4 py-3 rounded-lg">
        <ListFilter methods={methods} />
        <ListTable
          items={items}
          isLoading={isLoading}
          totalItems={totalItems}
          totalPage={totalPage}
          setLimit={setLimit}
          setPage={setPage}
        />
      </div>
    </div>
  );
};

const ListFilter = ({ methods }) => {
  const stateOptions = useMemo(
    () => [
      { value: "1", label: "Baru" },
      { value: "2", label: "Sedang Dikerjakan" },
    ],
    []
  );

  return (
    <FormProvider {...methods}>
      <form className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end">
        <div className="flex items-center overflow-hidden h-10 border rounded-md pl-3">
          <RiSearchLine className="text-gray-400 text-xl" />
          <InputForm
            controllerName="search"
            placeholder="Cari"
            className={`!pl-0 w-full rounded-md border-none`}
          />
        </div>
        <AtomSelect
          controllerName={`state`}
          className={`w-full rounded-md h-10 absolute`}
          options={stateOptions}
          placeholder={"Status"}
          returnObject={false}
        />
        <AtomDatePicker
          controllerName={"date"}
          label=""
          placeholder="dd/mm/yyyy"
          datepickerOptions={{
            dateFormat: "dd/MM/yyyy",
          }}
          showErrorLabel
        />
      </form>
    </FormProvider>
  );
};

const ListTable = ({
  items,
  totalPage,
  totalItems,
  setPage,
  setLimit,
  isLoading = false,
}) => {
  const navigate = useNavigate();

  const headers = useMemo(() => {
    return [
      {
        key: "index",
        title: "No",
        render: ({ index }) => index + 1,
      },
      {
        key: "name",
        title: "Nama Kelompok",
      },
      {
        key: "date",
        title: "Tgl Pengajuan",
        render: ({ item }) => {
          return dayjs(item.date).format("DD MMMM YYYY");
        },
      },
      {
        key: "category",
        title: "Tipe Kelompok",
        render: ({ item }) => (
          <Badge color={categoryColors[item.category]} variant="outlined">
            {item.category}
          </Badge>
        ),
      },
      {
        key: "total_member",
        title: "Jml Pengajuan Anggota",
        render: ({ item }) => {
          return `${item?.total_member} Orang`;
        },
      },
      {
        key: "state",
        title: "Status",
        render: ({ item }) => (
          <Badge color={stateColors[item.state]}>{item.state}</Badge>
        ),
      },
      // {
      //   key: "news",
      //   title: "Berita Acara  Desk Analyst",
      //   render: ({ item }) => (
      //     <div className="flex justify-center items-center w-full">
      //       <RiFolder2Line className="text-xl cursor-pointer" />
      //     </div>
      //   ),
      // },
      {
        key: "action",
        title: "Aksi",
        alignment: "center",
        className: "sticky right-0 bg-white",
        render: ({ item }) => (
          <>
            {["baru", "new"].includes(toLower(item.state)) ? (
              <Button
                theme="primary"
                label="Assign"
                onClick={() => {
                  navigate(`/desk-analyst/${item.id}`);
                }}
              />
            ) : (
              <Button
                variant="icon"
                label={<RiEyeLine className="text-lg" />}
                onClick={() => {
                  navigate(`/desk-analyst/${item.id}`);
                }}
              />
            )}
          </>
        ),
      },
    ];
  }, [navigate]);

  return (
    <TableWrapper className="mt-4">
      <Table headers={headers} items={items} isLoading={isLoading} />
      <TablePagination
        totalPage={totalPage}
        totalItems={totalItems}
        onChange={setPage}
        onItemsPerPageChange={setLimit}
      />
    </TableWrapper>
  );
};

export default DeskAnalyst;
