import { InputFormRadio } from "components";
import CardForm from "components/molecules/CardForm";
import React from "react";

function ResultVerification({ isView }) {
  return (
    <CardForm label="Hasil Verifikasi">
      <div className="space-y-5">
        <div className="font-semibold px-4 space-y-4">
          <div className="text-sm">
            1. Apakah Lembaga Penyalur telah memenuhi persyaratan untuk dapat
            dilakukan penyaluran sesuai dengan penilaian, yaitu:
          </div>

          <div className="px-4 mt-2 space-y-4">
            <div className="space-y-1">
              <InputFormRadio
                disabled={isView}
                label={
                  "1.1. LP mengajukan permohonan pencairan maksimal pada bulan ke 24 (dua puluh empat) sejak penandatanganan Perjanjian"
                }
                controllerName={"submission_month"}
                values={[
                  {
                    label: <p className="font-normal"> Terverifikasi</p>,
                    value: true,
                  },
                  {
                    label: <p className="font-normal"> Tidak Terverifikasi</p>,
                    value: false,
                  },
                ]}
              />
            </div>
            <div className="space-y-1">
              <InputFormRadio
                disabled={isView}
                label={
                  "1.2. LP mengajukan surat permohonan pencairan yang telah ditandatangani Oleh pejabat LP yang berwenang dengan dilampiri daftar nominatif calon Penerima FDB yang akan Disalurkan"
                }
                controllerName={"request_letter"}
                values={[
                  {
                    label: <p className="font-normal"> Terverifikasi</p>,
                    value: true,
                  },
                  {
                    label: <p className="font-normal"> Tidak Terverifikasi</p>,
                    value: false,
                  },
                ]}
              />
            </div>
            <div className="space-y-1">
              <InputFormRadio
                disabled={isView}
                label={"1.3. Daftar nominatif calon penerima FDB."}
                controllerName={"nominative_recipients"}
                values={[
                  {
                    label: <p className="font-normal"> Terverifikasi</p>,
                    value: true,
                  },
                  {
                    label: <p className="font-normal"> Tidak Terverifikasi</p>,
                    value: false,
                  },
                ]}
              />
            </div>
            <div className="space-y-1">
              <InputFormRadio
                disabled={isView}
                label={
                  "1.4. Khusus untuk permohonan pencairan pertama, dilampiri dengan Salinan akta pengikatan jaminan fidusia yang paling kurang dibuktikan dengan cover note dari Notaris"
                }
                controllerName={"first_disbursement"}
                values={[
                  {
                    label: <p className="font-normal"> Terverifikasi</p>,
                    value: true,
                  },
                  {
                    label: <p className="font-normal"> Tidak Terverifikasi</p>,
                    value: false,
                  },
                ]}
              />
            </div>
            <div className="space-y-1">
              <InputFormRadio
                disabled={isView}
                label={
                  "1.5. Pencairan pmjaman FDB dilakukan setelah Perjanjian Pembiayaan FDB secara 3 (tiga) pihak antara PARA PIHAk dan Penerima FDB ditandatangani."
                }
                controllerName={"fdb_loan_disbursement"}
                values={[
                  {
                    label: <p className="font-normal"> Terverifikasi</p>,
                    value: true,
                  },
                  {
                    label: <p className="font-normal"> Tidak Terverifikasi</p>,
                    value: false,
                  },
                ]}
              />
            </div>
          </div>

          <InputFormRadio
            disabled={isView}
            label={
              "2. Apakah jumlah yang dimohonkan sesuai Dengan jumlah Yang tertera pada tahapan berdasarkan keputusan persetujuan?"
            }
            controllerName={"approved_amount"}
            values={[
              {
                label: <p className="font-normal"> Terverifikasi</p>,
                value: true,
              },
              {
                label: <p className="font-normal"> Tidak Terverifikasi</p>,
                value: false,
              },
            ]}
          />

          <InputFormRadio
            disabled={isView}
            label={
              "3. Apakah saldo penyaluran (sd tahap yang dimohonkan) tidak melebihi saldo komitmen pembiayaan (sd tahapan tersebut)?"
            }
            controllerName={"disbursement_balance"}
            values={[
              {
                label: <p className="font-normal"> Terverifikasi</p>,
                value: true,
              },
              {
                label: <p className="font-normal"> Tidak Terverifikasi</p>,
                value: false,
              },
            ]}
          />

          <InputFormRadio
            disabled={isView}
            label={
              "4. Apakah data pada konsep Surat Perintah Pemindahbukuan FDB telah sesuai dengan daftar nominatif dalarn surat permohonan?"
            }
            controllerName={"nominative_list_request"}
            values={[
              {
                label: <p className="font-normal"> Terverifikasi</p>,
                value: true,
              },
              {
                label: <p className="font-normal"> Tidak Terverifikasi</p>,
                value: false,
              },
            ]}
          />
        </div>
      </div>
    </CardForm>
  );
}

export default ResultVerification;
