import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";

export const getListExpressedOpinion = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-expressed-opinion`,
    { params: queryKey[1] }
  );
};

export const getDetailExpressedOpinion = ({ queryKey }) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/agency-expressed-opinion/${queryKey[1]}`,
    { params: queryKey[2] }
  );
};

export const postExpressedOpinionAttend = ({ id, params, data }) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/agency-expressed-opinion/attend/${id}`,
    data,
    { params }
  );
};

export const postExpressedOpinionApprove = ({ id, params, data }) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/agency-expressed-opinion/approve/${id}`,
    data,
    { params }
  );
};
