import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, InputForm } from "components";
import {
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
} from "components/atoms/Modal";
import Table, { TableWrapper } from "components/atoms/Table";
import CardForm from "components/molecules/CardForm";
import UploadedFileAsync from "components/molecules/UploadFileAsync";
import { formatCurrency } from "helpers";
import { enqueueSnackbar } from "notistack";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import {
  getDetailAgreement,
  postAgreement,
} from "services/institutions/agreement";

import * as yup from "yup";

const feedbackSchema = yup.object({
  notes: yup.string().required().label("Feedback"),
});

const AgreementInstitutionDetail = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { data } = useQuery(
    ["GET_DETAIL_AGREEMENT", params?.id],
    getDetailAgreement,
    { select: (data) => data?.data?.data }
  );
  const mutateAgreement = useMutation({ mutationFn: postAgreement });

  const methods = useForm({ resolver: yupResolver(feedbackSchema) });

  const onFeedback = ({ notes }) => {
    mutateAgreement.mutate(
      {
        id: params?.id,
        data: {
          note: notes,
          status: "Feedback",
        },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Berhasil feedback perjanjian",
          });
          navigate(-1);
        },
      }
    );
  };

  const onAccept = () => {
    mutateAgreement.mutate(
      {
        id: params?.id,
        data: {
          notes: "",
          status: "Disetujui",
        },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Berhasil terima perjanjian",
          });
          navigate(-1);
        },
      }
    );
  };

  return (
    <section className="space-y-5">
      <CardForm label="Profil Lembaga">
        <div className="grid grid-cols-3 gap-5">
          <div className="flex flex-col gap-2">
            <h5 className="font-semibold text-base">Nama Lembaga</h5>
            <p>{data?.profile?.name}</p>
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="font-semibold text-base">Jenis Lembaga</h5>
            <p>{data?.profile?.agency_type}</p>
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="font-semibold text-base">Jenis Layanan</h5>
            <p>{data?.profile?.service_type ?? "-"}</p>
          </div>

          <div className="flex flex-col gap-2">
            <h5 className="font-semibold text-base">No NPWP Lembaga</h5>
            <p>{data?.profile?.npwp ?? "-"}</p>
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="font-semibold text-base">Email</h5>
            <p>{data?.profile?.email}</p>
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="font-semibold text-base">No Telepon</h5>
            <p>{data?.profile?.phone}</p>
          </div>

          <div className="flex flex-col gap-2">
            <h5 className="font-semibold text-base">Negara</h5>
            <p>{data?.profile?.country}</p>
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="font-semibold text-base">Provinsi</h5>
            <p>{data?.profile?.province}</p>
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="font-semibold text-base">Kabupaten/Kota</h5>
            <p>{data?.profile?.city}</p>
          </div>

          <div className="flex flex-col gap-2">
            <h5 className="font-semibold text-base">Kecamatan</h5>
            <p>{data?.profile?.district}</p>
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="font-semibold text-base">Kelurahan</h5>
            <p>{data?.profile?.village}</p>
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="font-semibold text-base">Kode Pos</h5>
            <p>{data?.profile?.zip_code}</p>
          </div>

          <div className="flex flex-col gap-2 col-span-3">
            <h5 className="font-semibold text-base">Alamat</h5>
            <p>{data?.profile?.address}</p>
          </div>
        </div>
      </CardForm>

      <CardForm label="Informasi Lembaga">
        <div className="flex flex-col gap-6">
          {/* <div className="flex flex-col gap-2">
            <h5 className="font-semibold text-base">Latar Belakang</h5>
            <p>
              Lorem ipsum dolor sit amet consectetur. Odio eget accumsan
              tincidunt tortor. Sed tempor a blandit viverra a vulputate
              tristique nunc nec. Facilisi id amet lacus iaculis purus porttitor
              amet libero vel. Quam volutpat volutpat tellus aliquet a egestas
              ac ut.
              -
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="font-semibold text-base">Visi Misi</h5>
            <p>
              Lorem ipsum dolor sit amet consectetur. Odio eget accumsan
              tincidunt tortor. Sed tempor a blandit viverra a vulputate
              tristique nunc nec. Facilisi id amet lacus iaculis purus porttitor
              amet libero vel. Quam volutpat volutpat tellus aliquet a egestas
              ac ut.
              -
            </p>
          </div> */}

          <div className="grid grid-cols-3 gap-5">
            <div className="flex flex-col gap-2">
              <h5 className="font-semibold text-base">Jumlah Anggota</h5>
              <p>{data?.information?.total_member}</p>
            </div>
            <div className="flex flex-col gap-2">
              <h5 className="font-semibold text-base">Jumlah Cabang</h5>
              <p>{data?.information?.total_branch}</p>
            </div>
            <div className="flex flex-col gap-2">
              <h5 className="font-semibold text-base">Jumlah Pegawai</h5>
              <p>{data?.information?.total_employee}</p>
            </div>
          </div>
        </div>
      </CardForm>

      <GroupMemberApplication data={data?.application} />
      <Agreement data={data?.doc_agreement_draft_template} />

      <div className="sticky bottom-4 flex justify-between w-full p-5 bg-white border rounded-md">
        <Button
          className="p-4 border rounded-lg"
          type="button"
          label={
            <div className="flex items-center gap-2 text-sm font-medium">
              Kembali
            </div>
          }
          onClick={() => navigate(-1)}
        />
        <div className="flex gap-3">
          <Modal
            trigger={
              <Button
                className="p-4 bg-blue-600 hover:bg-blue-700 text-white"
                type="button"
                label={
                  <div className="flex items-center gap-2 text-sm font-medium">
                    Beri Feedback
                  </div>
                }
              />
            }
            contentClassName="!w-[686px] !max-w-[686px] !z-[51]"
          >
            <ModalHeader>Feedback</ModalHeader>
            <ModalBody className="space-y-5">
              <FormProvider {...methods}>
                <InputForm
                  className="w-full p-3 border"
                  controllerName="notes"
                  label="Alasan"
                  required
                  textArea
                />

                <hr />
                <div className="flex gap-3">
                  <ModalClose>
                    <Button
                      label="Batal"
                      className="flex flex-grow border justify-center font-semibold text-sm"
                    />
                  </ModalClose>
                  <Button
                    label="Submit"
                    className="flex flex-grow bg-primary-700 hover:bg-primary-600 text-white justify-center font-semibold text-sm"
                    onClick={methods.handleSubmit(onFeedback)}
                  />
                </div>
              </FormProvider>
            </ModalBody>
          </Modal>

          <Button
            className="p-4 border rounded-lg bg-primary-600 text-white hover:bg-primary-700"
            type="button"
            label={
              <div className="flex items-center gap-2 text-sm font-medium">
                Terima
              </div>
            }
            onClick={onAccept}
          />
        </div>
      </div>
    </section>
  );
};

const GroupMemberApplication = ({ data }) => {
  const headers = useMemo(
    () => [
      {
        key: "index",
        title: "No",
        render: ({ index }) => {
          // const no =
          //   parseInt(data?.meta?.current_page) *
          //     parseInt(data?.meta?.per_page) -
          //   parseInt(data?.meta?.per_page) +
          //   index +
          //   1;

          const no = index + 1;

          return no;
        },
      },
      // {
      //   key: "group_name",
      //   title: "KTHR",
      // },
      {
        key: "debtor_name",
        title: "Nama Pemohon",
      },
      {
        key: "purpose_using_fdb",
        title: "Jenis Usaha Yang dibiayai",
        // render: ({ item }) => {
        //   return (
        //     <div
        //       className={clsx(
        //         "flex items-center text-xs gap-1 px-[6px] py-0.5 rounded-full font-medium",
        //         badgeStyle[item.status]
        //       )}
        //     >
        //       <RiCircleFill size={8} />
        //       {capitalize(item.status)}
        //     </div>
        //   );
        // },
      },
      {
        key: "application_value",
        title: "Nilai Pembiayaan",
        render: ({ item }) => formatCurrency(item?.application_value),
      },
      // {
      //   key: "distribution_scheme",
      //   title: "Penyaluran Pembiayaan Pinjaman",
      //   render: ({ item }) => {
      //     if (item.distribution_scheme === "at_once") {
      //       return "Sekaligus";
      //     }

      //     return "Bertahap";
      //   },
      // },
      // {
      //   key: "grace_periode",
      //   title: "Grace Period",
      //   render: ({ item }) => {
      //     if (item.grace_periode.includes("null")) {
      //       return "-";
      //     }

      //     return item.grace_periode;
      //   },
      // },
      // {
      //   key: "return_scheme",
      //   title: "Skema Pengembalian",
      //   render: ({ item }) => item?.return_scheme ?? "-",
      // },
      // {
      //   key: "action",
      //   title: "Aksi",
      //   alignment: "center",
      //   className: "sticky right-0 bg-white",
      //   render: ({ item }) => (
      //     <Button
      //       variant="icon"
      //       label={<RiEyeLine className="text-lg" />}
      //       onClick={() => {
      //         // navigate(`${item.id}`);
      //       }}
      //     />
      //   ),
      // },
    ],
    []
  );

  return (
    <CardForm label="Permohonan Lembaga">
      <TableWrapper className="mt-4">
        <Table
          headers={headers}
          // items={data?.data ?? []}
          items={data?.data ?? []}
          // isLoading={isLoading}
        />
      </TableWrapper>
    </CardForm>
  );
};

const Agreement = ({ data }) => {
  return (
    <CardForm label="Perjanjian">
      <UploadedFileAsync
        // key={e.id}
        // id={data[index]?.doc_agreement_draft_template[0].id}
        id={data}
        name="Dokumen Perjanjian"
      />
    </CardForm>
  );
};

export default AgreementInstitutionDetail;
