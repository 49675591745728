import { general_config_v2 } from "app/config";
import axiosInstance from "app/interceptors";
import dayjs from "dayjs";

export const getVerificatorList = ({ params }) => {
  const date =
    params.date !== "" ? dayjs(params.date).format("YYYY-MM-DD") : "";

  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/verifikator/submission?page=${
      params.page
    }&limit=${params.limit}&filter%5Bsearch%5D=${
      params.search
    }&filter%5Bstatus%5D=${params.status}&filter%5Bdate%5D=${date}`
  );
};

export const getVerificatorDetail = (userId) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/verifikator/submission/${userId}`
  );
};

export const getVerificatorApplicantDetail = (userId) => {
  return axiosInstance.get(
    `${general_config_v2.BASE_URL}/v1/cms/verifikator/applicant-detail/${userId}`
  );
};

export const putVerificator = (payload, submissionId, groupId, verificator_submit) => {
  return axiosInstance.put(
    `${general_config_v2.BASE_URL}/v1/cms/verifikator/applicant-approval?submission_application_id=${submissionId}&group_profile_id=${groupId}&verificator_submit=${verificator_submit}`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
